
import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import DatePickerWrapper from '../../../components/Datepicker/DatePickerWrapper';
import Input from '../Input';

const DatePicker = forwardRef(({
  label, isStatic, value, onBlur, onChange, ...props
}, ref) => (
  <DatePickerWrapper sx={props.sx}>
    <ReactDatePicker
      autoComplete="off"
      ref={ref}
      selected={value}
      onBlur={onBlur}
      onChange={onChange}
      customInput={(<Input label={label} />)}
      {...props}
    />
  </DatePickerWrapper>
));

export default DatePicker;

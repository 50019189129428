import React, { useState } from 'react';
import Card from '@mui/material/Card';
import EditableTable from '../../../components/EditableTable';
import {
  CardContent
} from '@mui/material';
import FileSaver from 'file-saver';
import {
  useGetUserInvoiceRequestsQuery, useGetPurposesQuery,
  useDeleteUserInvoiceMutation, useDownloadUserInvoiceMutation, useGetCurrencyListQuery,
  useGetProofOfPaymentMutation,
} from '../../../store/session';
import { toast } from 'react-hot-toast';
import { getOptions } from '../../../utils/getOptions';
import {
  cardContent, UserInvoicesContainer,
} from '../styles';
import { columnsMyInvoices } from './columnsMyInvoices';
import { STATUSES } from '../../../constans/invoiceRequest';
import InfoModal from '../../../components/InfoModal';

const MyInvoicesTab = () => {
  const [isModalReasonOpen, setIsModalReasonOpen] = useState(false);
  const [reasonText, setReasonText] = useState('');
  const { data: currencyList } = useGetCurrencyListQuery();
  const [downloadUserInvoice] = useDownloadUserInvoiceMutation();
  const { data: purpose } = useGetPurposesQuery();
  const purposeOptions = getOptions(purpose);
  const currencyOptions = getOptions(currencyList);

  const handleOpenReasonModal = (text) => {
    setIsModalReasonOpen(true);
    setReasonText(text);
  };

  const [getProofOfPayment, { isLoading: FileLoading }] = useGetProofOfPaymentMutation();

  const handlePreviewPoP = async (id) => {
    const toastId = toast.loading('Loading...');

    await getProofOfPayment(id).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleDownload = async({ id, document_patch, file_name }) => {
    const toastId = toast.loading('Downloading...');

    const res = await downloadUserInvoice(id);

    const fileName = (document_patch || file_name)?.replace( /\\/g, '/' ).replace( /.*\//, '' );

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    FileSaver.saveAs(res.data, fileName);

    toast.success('Success!', {
      id: toastId,
    });
  };

  return (
    <Card>
      <CardContent sx={{ ...cardContent, ...UserInvoicesContainer }}>
        <EditableTable
          handleDownload={handleDownload}
          useHook={useGetUserInvoiceRequestsQuery}
          useHookDelete={useDeleteUserInvoiceMutation}
          purposeOptions={purposeOptions}
          getRowHeight={() => 'auto'}
          currencyOptions={currencyOptions}
          columns={columnsMyInvoices}
          onOpenReasonModal={handleOpenReasonModal}
          openProofOfPayment={handlePreviewPoP}
          getRowClassName={(params) => {
            const status = STATUSES.find(item => item.value === params.row.payment_status);

            return `row-${status?.tag}`;
          }}
          sortByType="DESC"
          sortByActive="created_at"
        />
      </CardContent>
      <InfoModal open={isModalReasonOpen} onClose={() => { setIsModalReasonOpen(false); }} reasonText={reasonText} />
    </Card>
  );
};

export default MyInvoicesTab;

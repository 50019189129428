import { Box, Typography } from '@mui/material';
import { HeaderName, StyledDelete, StyledPencil } from '../../../components/EditableTable/styles';
import Input from '../../../components/Input/Input';

export const columns = ({
  filters,
  handleFilter,
  handleOpenDeleteModal,
  handleUpdate
}) => ([
  {
    flex: 0.2,
    field: 'company_name',
    maxWidth: 280,
    minWidth: 150,
    headerName: 'Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Name</Typography>
        <Input
          size="small"
          placeholder="Name"
          defaultValue={filters.company_name || ''}
          onChange={(e) => {
            handleFilter(e.target.value, 'company_name');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
          // whiteSpace: 'break-spaces',
        }}
      >
        {row.company_name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'company_address',
    // maxWidth: 450,
    minWidth: 170,
    headerName: 'Address',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Address</Typography>
        <Input
          size="small"
          placeholder="Address"
          defaultValue={filters.company_address || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'company_address');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
          // whiteSpace: 'break-spaces',
        }}
      >
        {row.company_address}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'company_shortname',
    // maxWidth: 250,
    minWidth: 150,
    headerName: 'Shortname',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Shortname</Typography>
        <Input
          size="small"
          placeholder="Shortname"
          defaultValue={filters.company_short_name || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'company_short_name');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
      >
        {row.company_shortname}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'company_phone',
    // maxWidth: 280,
    minWidth: 220,
    headerName: 'Phone',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Phone</Typography>
        <Input
          size="small"
          placeholder="Phone"
          defaultValue={filters.company_phone || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'company_phone');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
      >
        {row.company_phone}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'company_email',
    maxWidth: 450,
    minWidth: 300,
    headerName: 'Sita',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Email</Typography>
        <Input
          size="small"
          placeholder="Email"
          defaultValue={filters.company_email || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'company_email');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
      >
        {row.company_email}
      </Typography>
    ),
  },
  {
    // width: 100,
    maxWidth: 300,
    minWidth: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <StyledPencil onClick={() => { handleUpdate(row.id); }} />
        {' '}
        <StyledDelete onClick={() => { handleOpenDeleteModal(row.id); }} />
        {' '}
      </>
    ),
  },
]);
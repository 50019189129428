export const menuSx = {
  '& .MuiPaper-root': {
    border: 1,
    borderColor: 'primary.greyLight',
    p: '20px',
    borderRadius: '5px',
  },
  '& .MuiList-root': {
    p: 0,
    m: 0,
  }
};
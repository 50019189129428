import React from 'react';
import {
  Box, Divider, Typography,
} from '@mui/material';
import InvoiceCard from '../../../../OrdersDashboard/components/forms/approved/invoices/InvoiceCard';
import InvoiceTableHeader from '../../../../OrdersDashboard/components/forms/approved/invoices/InvoiceTableHeader';
import { useGetPaymentRequestsQuery } from '../../../../../../store/slices/logisticsSlice';

const PartInvoiceTab = ({ orderId, variant }) => {
  const { data: partData } = useGetPaymentRequestsQuery({ orderId, payment_type: 'part' });
  const { data: deliveryData } = useGetPaymentRequestsQuery({ orderId, payment_type: 'delivery' });

  return (
    <Box p={3}>
      {(variant === 'part' ? partData : deliveryData) && Object.values(variant === 'part' ? partData : deliveryData)?.length ? (
        <>
          <InvoiceTableHeader />
          {Object.values(variant === 'part' ? partData : deliveryData)?.map((element) => (
            <InvoiceCard key={element.id} invoice={element} />
          ))}
          <Divider sx={{ pt: '10px' }} />
        </>
      ) : (
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center'
        }}
        >
          <Typography variant="h5" noWrap>
            No related invoices
          </Typography>
          <Typography variant="subtitle2" noWrap>
            Please add new invoice.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PartInvoiceTab;

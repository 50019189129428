import {
  array,
  number, object, string
} from 'yup';

export const defaultValues = {
  part_type: 'part_delivery',
  priority: 2,
  part_id: null,
  maintenance_invoice_part_id: 0,
  aircraft_reservation: 0,
  current_part: [{
    id: 0,
    part_name: '',
    part_number: '',
    quantity: 0,
    serial_number: '',
  }],
  address: '',
  description: '',
  from: '',
  address_from: '',
  to: '',
  address_to: '',
  carrierVis: false,
  carrier: 'Caddy New',
  installedOrder: [{
    id: '',
    storage_zone: '',
    storage_number: '',
    part_type: '',
    part_number: '',
    part_name: '',
  }],
};

export const schema = object().shape({
  part_type: string()
    .required('${label} cannot be blank.')
    .label('Part Type'),
  priority: number().required('${label} cannot be blank.').label('Priority'),
  part_id: string().when('part_type', {
    is: (val) => val !== 'other',
    then: string().required('${label} cannot be blank.'),
    otherwise: string().nullable()
  }).label('Part ID'),
  maintenance_invoice_part_id: number().when('part_type', {
    is: (val) => val === 'exchange',
    then: number().min(1, '${label} cannot be blank.').required('${label} cannot be blank.')
  }).label('Order ID'),
  order_id: string(),
  aircraft_reservation: number().when('part_type', {
    is: (val) => ((val !== 'other') && (val !== 'overhaul') && (val !== 'repair') && (val !== 'exchange')),
    then: number('${label} cannot be blank.').required('${label} cannot be blank.'),
    otherwise: number().nullable()
  }).label('Aircraft Reservation'),
  current_part: array(object().shape({
    id: number(),
    part_name: string(),
    part_number: string(),
    part_state: number(),
    quantity: number().when('id', {
      is: (val) => !!val,
      then: number().min(1).required('${label} cannot be blank.')
    }).label('Quantity'),
    serial_number: string(),
    storage: string()
  })),
  address: string().nullable(),
  description: string().when('part_type', {
    is: (val) => val === 'other',
    then: string().required('${label} cannot be blank.')
  }).label('Description'),
  address_from: string().when('part_type', {
    is: (val) => ((val !== 'overhaul') && (val !== 'repair')),
    then: string('${label} cannot be blank.').required('${label} cannot be blank.')
  }).label('Address From'),
  address_to: string().when('part_type', {
    is: (val) => ((val !== 'overhaul') && (val !== 'repair')),
    then: string('${label} cannot be blank.').required('${label} cannot be blank.')
  }).label('Address To'),
  carrier: string().when(['part_type', 'carrierVis'], {
    is: ([partType, isCarrier]) => ((partType === 'part_delivery') && isCarrier),
    then: string('${label} cannot be blank.').required('${label} cannot be blank.')
  }).label('Carrier'),
});
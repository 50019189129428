import moment from 'moment';

export const DEFAULT_YEAR = moment().format('YYYY');
export const DEFAULT_MONTH = [moment().format('MMM')];
export const MONTH_SHORT = moment.monthsShort();
export const YEARS = [
  moment().subtract( 1, 'years').format('YYYY'),
  moment().format('YYYY'),
  moment().add( 1, 'years').format('YYYY'),
];

export const POSITIONING = 'POSITIONING';
export const TRAINING = 'Training';
export const STAND_BY = 'STANDBY';
export const RESERVE = 'RESERVED';

export const FPD_TYPES = [POSITIONING, TRAINING, STAND_BY, RESERVE];

export const FPD_COLORS = {
  [POSITIONING]: '#e1e176',
  [TRAINING]: '#e1e176',
  [STAND_BY]: 'rgba(76, 78, 100, 0.12)',
  [RESERVE]: 'rgb(121 186 247 / 80%)',
};

import {
  Box, Card, Divider, Typography
} from '@mui/material';
import React from 'react';
import { headerBtn } from '../../../../Invoices/style';
import DocsTableHeader from './DocsTableHeader';
import DocsTableRow from './DocsTableRow';

const OrderDocs = (props) => {
  const { invoices = [] } = props;
  const isPart = invoices?.filter((inv) => (inv?.type === 'part'));
  const isDelivery = invoices?.filter((inv) => (inv?.type === 'delivery'));
  const isExchange = invoices?.filter((inv) => (inv?.type === 'exchange'));
  const isOverhaul = invoices?.filter((inv) => (inv?.type === 'overhaul'));
  const isRepair = invoices?.filter((inv) => (inv?.type === 'repair'));

  return (
    <Card
      sx={{ padding: '1rem' }}
    >
      <Box sx={headerBtn}>
        <Box>
          <Typography variant="h5" noWrap>
            Docs
          </Typography>
        </Box>
      </Box>

      <Divider />

      {isPart?.length ? (
        <Box>
          <Box sx={{ margin: '10px 0' }}>
            <Typography variant="h6" noWrap sx={{ fontWeight: '600' }}>
              Part Invoices Table
            </Typography>
          </Box>
          <Box>
            <DocsTableHeader />
            {isPart?.map((element) => (
              <DocsTableRow key={element.id} invoice={element} />
            ))}
            <Divider sx={{ pt: '10px' }} />
          </Box>
        </Box>
      ) : <></>}

      {isDelivery?.length ? (
        <Box>
          <Box sx={{ margin: '10px 0' }}>
            <Typography variant="h6" noWrap sx={{ fontWeight: '600' }}>
              Delivery Invoices Table
            </Typography>
          </Box>
          <Box>
            <DocsTableHeader />
            {isDelivery?.map((element) => (
              <DocsTableRow key={element.id} invoice={element} />
            ))}
            <Divider sx={{ pt: '10px' }} />
          </Box>
        </Box>
      ) : <></>}
      {isExchange?.length ? (
        <Box>
          <Box sx={{ margin: '10px 0' }}>
            <Typography variant="h6" noWrap sx={{ fontWeight: '600' }}>
              Exchange Invoices Table
            </Typography>
          </Box>
          <Box>
            <DocsTableHeader />
            {isExchange?.map((element) => (
              <DocsTableRow key={element.id} invoice={element} />
            ))}
            <Divider sx={{ pt: '10px' }} />
          </Box>
        </Box>
      ) : <></>}
      {isOverhaul?.length ? (
        <Box>
          <Box sx={{ margin: '10px 0' }}>
            <Typography variant="h6" noWrap sx={{ fontWeight: '600' }}>
              Overhaul Invoices Table
            </Typography>
          </Box>
          <Box>
            <DocsTableHeader />
            {isOverhaul?.map((element) => (
              <DocsTableRow key={element.id} invoice={element} />
            ))}
            <Divider sx={{ pt: '10px' }} />
          </Box>
        </Box>
      ) : <></>}
      {isRepair?.length ? (
        <Box>
          <Box sx={{ margin: '10px 0' }}>
            <Typography variant="h6" noWrap sx={{ fontWeight: '600' }}>
              Repair Invoices Table
            </Typography>
          </Box>
          <Box>
            <DocsTableHeader />
            {isRepair?.map((element) => (
              <DocsTableRow key={element.id} invoice={element} />
            ))}
            <Divider sx={{ pt: '10px' }} />
          </Box>
        </Box>
      ) : <></>}
    </Card>
  );
};

export default OrderDocs;
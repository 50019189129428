import { mixed, number, object } from 'yup';

export const defaultValues = {
  supplier_id: 0,
  upload_file: null
};

export const schema = object().shape({
  supplier_id: number().required().label('Supplier ID'),
  upload_file: mixed().required('File is required!')
});
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody, CircularProgress,
} from '@mui/material';

const FdpForm = (props) => {
  const { fdpResult, isFdpLoading } = props;

  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader title="Flight Duty Period" />
      <CardContent sx={{ textAlign: 'center' }}>
        {!isFdpLoading
          ? (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">FDP #</TableCell>
                    <TableCell align="center">Max FDP</TableCell>
                    <TableCell align="center">Current FDP</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fdpResult?.map(({ num, maxFdp, currentFdp }) => (
                    <TableRow key={num}>
                      <TableCell align="center">{num}</TableCell>
                      <TableCell align="center">{maxFdp}</TableCell>
                      <TableCell align="center">{currentFdp}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
          : (
            <CircularProgress sx={{
              marginTop: '2rem',
            }}
            />
          )}
      </CardContent>
    </Card>
  );
};

export default FdpForm;

import moment from 'moment';

/**
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 * @returns {number}
 */
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  if ((Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2)) > 0) {
    return Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2)) * 6378 * 0.539956 * 1.08;
  }

  return (Math.PI - Math.acos(Math.abs(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2)))) * 6378 * 0.539956 * 1.08;
};

/**
 * @param distance
 * @returns {number}
 */
// eslint-disable-next-line consistent-return
export const calculateTime = (distance) => {
  if (distance < 160) {
    return distance / 200 + 0.25;
  } if (distance < 170) {
    return distance / 210 + 0.25;
  } if (distance < 190) {
    return distance / 220 + 0.25;
  } if (distance < 200) {
    return distance / 230 + 0.25;
  } if (distance >= 200) {
    return distance / 240 + 0.25;
  }
};

/**
 * @param flightTime
 * @param departureTime
 * @returns {Date}
 */
export const formatFlightTime = (flightTime, departureTime) => {
  const art = moment(departureTime).format('X');
  const hours = Math.floor(flightTime);
  const minutes = Math.floor((flightTime - hours) * 60);

  // idk why we need this round ops. But they said that this is a true time
  const calcUnix = Number(art) + hours * 3600 + minutes * 60;
  const round = Math.floor(calcUnix / 600) * 600 + 600;

  return moment.unix(round).toDate();
};

/**
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 * @param departureTime
 * @returns {number}
 */
export const calculateFlightTime = (lat1, lon1, lat2, lon2, departureTime) => {
  const distance = calculateDistance(lat1, lon1, lat2, lon2);
  const flightTime = calculateTime(distance);

  return formatFlightTime(flightTime, departureTime);
};

/**
 * @param plane
 * @param flightTime
 * @returns {number}
 */
export const calculateQkg = (plane, flightTime) => {
  let qkg;
  if (flightTime <= 0.5) {
    qkg = plane.aircraft_first_hour_fuel * 0.5;
  } else {
    qkg = (flightTime - 0.5) * plane.aircraft_taxi_fuel + plane.aircraft_first_hour_fuel * 0.5;
  }

  // if (qkg > plane.aircraft_max_fuel) {
  //   toast.error('OVERLOAD. TOO MUCH FUEL');
  // }

  return qkg;
};

/**
 * @param plane
 * @param qkg
 * @returns {number}
 */
export const calculateMaxPayload = (plane, qkg) => Math.ceil(plane.aircraft_mtow - plane.aircraft_oew - (qkg + 400) * 1.05) - 1;

export const calculateTow = (plane, qkg, payload, maxPl) => {
  const tow = Math.ceil(plane.aircraft_oew + (qkg + 400) * 1.05 + +payload);

  // if (tow > plane.aircraft_mtow) {
  //   toast.error(`OVERLOAD. FUEL STOP NEEDED. MAX PAYLOAD: ${maxPl}`);
  // }

  return tow;
};

/**
 * @param airportFrom
 * @param airportTo
 * @param plane
 * @param departureTime
 * @param loaded
 * @param consumption
 * @param payload
 * @returns {{arr_fees: *, distance: number, dep_fees: *, flight_time: Date, qkg: number, tow: number, maxPl: number, fuelPrice: number, legFuelPrice: number}}
 */
export const getLegValues = (airportFrom, airportTo, plane, departureTime, loaded, consumption, payload = 0) => {
  const distance = calculateDistance(
    airportFrom?.airport_lat,
    airportFrom?.airport_lon,
    airportTo?.airport_lat,
    airportTo?.airport_lon,
  );

  const fuelPrice = airportFrom.fuel_price ?? 0;

  const flightTime = calculateTime(distance);

  const arrivingTime = formatFlightTime(flightTime, departureTime);

  // Requested Fuel
  const qkg = calculateQkg(plane, flightTime);

  // Max Payload
  const maxPl = calculateMaxPayload(plane, qkg);

  // TOW
  const tow = calculateTow(plane, qkg, payload, maxPl);

  let hours = Math.floor(flightTime);
  let minutes = Math.floor((flightTime - hours) * 60);
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }
  const z = `${minutes}w`;
  if (z.length < 3) {
    minutes = `0${minutes}`;
  }
  const legFuelPrice = fuelPrice * (hours + minutes / 60) * consumption;

  return {
    dep_fees: loaded ? airportFrom?.fees_loaded : airportFrom?.fees_empty,
    arr_fees: loaded ? airportTo?.fees_loaded : airportTo?.fees_empty,
    distance,
    arrivingTime,
    flight_time: `${hours}:${minutes}`,
    tow: Math.ceil(tow * 1000) / 1000,
    qkg: Math.ceil(Math.ceil(qkg) * 1000) / 1000,
    maxPl: Math.ceil(maxPl * 1000) / 1000,
    fuelPrice,
    legFuelPrice: parseFloat(legFuelPrice.toFixed(4)),
  };
};

export const sumStrTime = (time1, time2) => {
  const time_1 = time1.split(':');
  const time_2 = time2.split(':');
  const time_1_seconds = parseFloat(time_1[0]) + parseFloat(time_1[1] / 60);
  const time_2_seconds = parseFloat(time_2[0]) + parseFloat(time_2[1] / 60);
  const sumTimeInSeconds = time_1_seconds + time_2_seconds;

  let hours = Math.floor(sumTimeInSeconds);
  let minutes = Math.floor((sumTimeInSeconds - hours) * 60);
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }
  const z = `${minutes}w`;
  if (z.length < 3) {
    minutes = `0${minutes}`;
  }

  return `${hours}:${minutes}`;
};

/**
 * @param conditions
 * @param sumFees
 * @param sumDist
 * @param sumTime
 * @param sumFuelPrice
 * @returns {*}
 */
export const getPrice = (conditions, sumFees, sumDist, sumTime, sumFuelPrice) => {
  const total = Math.ceil((sumTime * conditions.acmi) + (sumDist * conditions.nav) + sumFuelPrice + sumFees) + conditions.other;

  return Math.ceil(total / 100) * 100; // Round the total price up to the nearest hundred
};
// "3000" - conditions.fuel: db: fleets.fleet_cond_fuel
export const getPriceOld = (conditions, sumFees, sumDist, sumTime, sumQkg) => Math.ceil(((sumTime * conditions.acmi + sumDist * conditions.nav + conditions.legsCount * 50 + (sumQkg / 1000) * 3000 + sumFees) / 100) * 100) + conditions.other;

export const columns = [
  {
    name: 'date'
  },
  {
    name: 'Aircraft'
  },
  {
    name: 'Airport'
  },
  {
    name: 'Price'
  },
  {
    name: 'Amount,l'
  },
  {
    name: 'Amount,g'
  },
  {
    name: 'Calc Sum,USD'
  },
  {
    name: 'Sum,USD'
  },
  {
    name: 'Invoice'
  },
  {
    name: 'Payment Date'
  },
  {
    name: 'Balance,USD'
  },
];

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import AccordionBase from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import {
  accordionSx, cardContainer, summarySx, typeSx
} from './styles';

const Accordion = ({ title, children, isPanel }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={cardContainer(isPanel)}>
      <Box sx={typeSx} onClick={handleChange}>
        {title}
      </Box>
      <AccordionBase expanded={expanded} sx={accordionSx}>
        <AccordionSummary
          sx={summarySx}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        />

        <AccordionDetails>
          {children}
        </AccordionDetails>
      </AccordionBase>
    </Box>
  ); };

export default Accordion;

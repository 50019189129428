import React from 'react';
import EditableTable from '../../../components/EditableTable';
import { useGetUserDocumentsListQuery } from '../../../store/session';
import { columnsSigns } from './columnsSigns';
import Card from '@mui/material/Card';
import {
  useDownloadReportMutation,
  useGetSignsTableQuery, useSignReportMutation
} from '../../../store/slices/TrainingModule/trainingReportSlice';
import { toast } from 'react-hot-toast';

const SignsTab = () => {
  const [downloadReport] = useDownloadReportMutation();
  const [signDoc] = useSignReportMutation();

  const handlePreview = async (id) => {
    const toastId = toast.loading('Loading...');

    const res = await downloadReport({ id });

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    const fileUrl = URL.createObjectURL(res.data);
    window.open(fileUrl, '_blank', 'noreferrer');

    toast.success('Done.', {
      id: toastId,
    });
  };

  const handleSignDoc = async (uuid) => {
    const toastId = toast.loading('Loading...');

    const res = await signDoc({ report_uuid: uuid });

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });
  };

  return (
    <Card>
      <EditableTable
        handlePreview={handlePreview}
        handleSignDoc={handleSignDoc}
        getRowHeight={() => 'auto'}
        useHook={useGetSignsTableQuery}
        columns={columnsSigns}
        sortByType="DESC"
        sortByActive="sent_at"
      />
    </Card>
  );
};

export default SignsTab;

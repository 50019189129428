import React from 'react';
import { Card, CardContent } from '@mui/material';
import CompanyTable from './CompanyTable';

const Company = () => (
  <Card sx={{ mt: 3 }}>
    <CardContent>
      <CompanyTable title="Manage Clients" />
    </CardContent>
  </Card>
);

export default Company;

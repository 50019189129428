import React from 'react';
import TypeCard from '../TypeCard';
import Grid from '@mui/material/Grid';
import Select from '../../../../form/components/Select';
import FormGroup from '../../../../form/components/FormGroup';
import DatePicker from '../../../../form/components/DatePicker';
import { Controller } from 'react-hook-form';
import {
  useGetOdsStatusesQuery, useGetOdsPositionTypesQuery, useGetAircraftListQuery
} from '../../../../store/session';
import { ODS_POST_OPTIONS } from '../../../../constans/crew';
import { getOptions } from '../../../../utils/getOptions';
import { getTimeDiffHm } from '../../../../utils/getTime';
import { selectOptionFormat } from '../../../../utils/number';
import FieldError from '../../../../form/components/FieldError';
import Input from '../../../../form/components/Input';
import moment from 'moment';
import { validateDatePickerValue } from '../../../../utils/setDateValue';

const Flight = ({
  control, pilotNameOptions, trigger, setCrewPost, setValue, watch, errors,
}) => {
  const { data: odsStatuses } = useGetOdsStatusesQuery();
  const { data: odsPosition } = useGetOdsPositionTypesQuery();
  const { data: AircraftList } = useGetAircraftListQuery();

  const odsStatusesOptions = getOptions(odsStatuses);
  const odsPositionOptions = getOptions(odsPosition);
  const formattedAircraftList = selectOptionFormat(AircraftList, 'aircraft_registration');

  const start = watch('start');
  const finish = watch('finish');

  const filterPassedTime = (time) => {
    const startDate = moment(start).toDate();
    const endDate = moment(time).toDate();

    return startDate.getTime() < endDate.getTime();
  };

  return (
    <TypeCard title="POSITIONING ODS" variant="sky">
      <Grid container spacing={7} rowSpacing={2}>
        <Grid item xs={12} lg={3}>
          <FormGroup label="POSITION" hasError={!!errors.crew_post}>
            <Controller
              render={({ field }) => (
                <Select
                  options={ODS_POST_OPTIONS}
                  placeholder="POSITION"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setCrewPost(e.target.value);
                    setValue('crew_id', '');
                    trigger('crew_id');
                  }}
                />
              )}
              control={control}
              name="crew_post"
            />

            <FieldError error={errors.crew_post} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="PILOT NAME" hasError={!!errors.crew_post}>
            <Controller
              render={({ field }) => (
                <Select
                  options={pilotNameOptions}
                  disabled={!pilotNameOptions?.length}
                  placeholder="PILOT NAME"
                  {...field}
                />
              )}
              control={control}
              name="crew_id"
            />

            <FieldError error={errors.crew_id} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="AIRCRAFT" hasError={!!errors.aircraft_id}>
            <Controller
              render={({ field }) => (
                <Select
                  options={formattedAircraftList}
                  placeholder="AIRCRAFT"
                  {...field}
                />
              )}
              control={control}
              name="aircraft_id"
            />

            <FieldError error={errors.aircraft_id} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="STATUS" hasError={!!errors.ods_status}>
            <Controller
              render={({ field }) => (
                <Select
                  options={odsStatusesOptions}
                  placeholder="STATUS"
                  {...field}
                />
              )}
              control={control}
              name="ods_status"
            />

            <FieldError error={errors.ods_status} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="TYPE OF POSITIONING" hasError={!!errors.position_type}>
            <Controller
              render={({ field }) => (
                <Select
                  options={odsPositionOptions}
                  placeholder="TYPE OF POSITIONING"
                  {...field}
                />
              )}
              control={control}
              name="position_type"
            />

            <FieldError error={errors.position_type} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="POSITIONING START" hasError={!!errors.start}>
            <Controller
              render={({ field: { onChange, ...req } }) => (
                <DatePicker
                  {...req}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD H:M"
                  dateFormat="yyyy-MM-dd HH:mm"
                  maxDate={finish ? moment(finish).toDate() : null}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => {
                    onChange(e);
                    trigger('start');
                  }}
                  value={validateDatePickerValue(req.value)}
                />
              )}
              control={control}
              name="start"
            />

            <FieldError error={errors.start} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="POSITIONING FINISH" hasError={!!errors.finish}>
            <Controller
              render={({ field: { onChange, ...req } }) => (
                <DatePicker
                  {...req}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD H:M"
                  dateFormat="yyyy-MM-dd HH:mm"
                  minDate={start ? moment(start).toDate() : null}
                  filterTime={filterPassedTime}
                  showMonthDropdown
                  showYearDropdown
                  onChange={onChange}
                  value={validateDatePickerValue(req.value)}
                />
              )}
              control={control}
              name="finish"
            />

            <FieldError error={errors.finish} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="TOTAL POSITIONING TIME" hasError={!!errors.total_positioning_time}>
            <Controller
              render={({ field }) => (
                <Input
                  placeholder="TOTAL POSITIONING TIME"
                  InputProps={{
                    readOnly: true,
                  }}
                  {...field}
                  value={getTimeDiffHm(start, finish)}
                  defaultValue={getTimeDiffHm(start, finish)}
                />
              )}
              control={control}
              name="total_positioning_time"
            />

            <FieldError error={errors.total_positioning_time} />
          </FormGroup>
        </Grid>
      </Grid>
    </TypeCard>
  );
};

export default Flight;

export const columns = [
  {
    id: 'airport',
    label: 'Airport',
  },
  {
    id: 'supplier',
    label: 'Supplier',
  },
  {
    id: 'Price_USG_USD',
    label: 'Price, USG/USD',
  },
  {
    id: 'uplift',
    label: 'Uplift, l',
  },
  {
    id: 'cost_USD',
    label: 'Cost, USD',
  },
  {
    id: 'invoice_num',
    label: 'Invoice Num',
  },
  {
    id: 'invoice_date',
    label: 'Invoice Date',
  },
];

export const defaultValues = {
  crew_id: '',
  crew_post: '',
  aircraft_type: '',
  ods_status: '',
  training_type: '',
  type: 5,
  start: null,
  finish: null,
};

import React from 'react';
import Box from '@mui/material/Box';
import DropDownMenu from '../../../../../components/DropDownMenu';
import { dropdownMenuSx, itemSx } from './styles';
import { useCopyFlightMutation } from '../../../../../store/session';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const ActionDropDown = ({
  open,
  onClose,
  anchorEl,
}) => {
  const params = useParams();
  const [copyFlight] = useCopyFlightMutation();

  const handleCopyFlights = async () => {
    onClose();
    const toastId = toast.loading('Loading...');
    await copyFlight(params.id).then((res) => {
      if (!res?.error) {
        toast.success('Flight was created successfully!', {
          id: toastId
        });
        window.open(`${process.env.REACT_APP_URL}flights/${res.data.id}`);
      } else {
        toast.error('Something went wrong.', {
          id: toastId
        });
      }
    });
  };

  return (
    <DropDownMenu
      sx={dropdownMenuSx}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
    >
      <Box>
        <Box onClick={handleCopyFlights} sx={itemSx}>Copy Flight</Box>
      </Box>
    </DropDownMenu>
  );
};

export default ActionDropDown;

import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHeadBase from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  boldBorderLeft, boldBorderRight, borderRight, cellSx 
} from '../styles';
import { tableHeadCellSx } from './styles';

const TableHead = ({ data }) => (
  <TableHeadBase>
    <TableRow>
      <TableCell />
      <TableCell />

      {data?.map(item => (
        <TableCell
          align="center"
          colSpan={item.items.length}
          sx={{
            ...cellSx, ...boldBorderRight, ...boldBorderLeft, ...tableHeadCellSx 
          }}
        >
          {item.title}
        </TableCell>
      ))}
    </TableRow>

    <TableRow>
      <TableCell />
      <TableCell />
              
      {data?.map(item => (
        item.items.length ? item?.items?.map((subItem, i) => (
          <TableCell
            align="center" 
            sx={{
              ...cellSx,
              ...borderRight,
              ...tableHeadCellSx,
              ...(i === 0 && boldBorderLeft ),
              ...(i === item.items.length - 1 && boldBorderRight)
            }}
          >
            {subItem.title}
          </TableCell>
        )) : <TableCell />
      ))}
    </TableRow>
  </TableHeadBase>
);

export default TableHead;
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import Button from '../../../components/Button';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import TextEdit from '../../../components/EditableTable/TextEdit';
import {
  TYPE_INPUT,
  TYPE_SELECT,
  TYPE_SELECT_DATE,
} from '../../../components/EditableTable/constants';
import {
  HeaderName,
  StyledDelete,
  odsId,
} from '../../../components/EditableTable/styles';
import LinkButton from '../../../components/LinkButton';
import { OdsActionDocumentModel } from '../../../utils/constants/portalFiles';
import { GreenButton, TextWrap } from '../../Logistics/style';
import FiltrationDate from "../../../components/EditableTable/FiltrationDate";

export const columns = ({
  handleFilter,
  filters,
  handleOpen,
  aircraftOptions,
  odsAtaOptions,
  handleOpenDeleteModal,
  isLoadingAll,
  handleFileDrawer
}) => [
  {
    flex: 0.2,
    field: 'aircraft_registration',
    minWidth: 180,
    headerName: 'Reg',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography style={HeaderName}>Reg</Typography>
        <FiltrationSelect
          options={aircraftOptions}
          defaultValue={filters.aircraft_id || ''}
          handleFilter={handleFilter}
          placeholder="Reg"
          value="aircraft_id"
        />
      </Box>
    ),
    renderCell: ({ row }) => {
      const res = aircraftOptions?.find((e) => e.value === row.aircraft_id);

      return <Typography>{res?.label}</Typography>;
    },
  },
  {
    flex: 0.2,
    field: 'index',
    minWidth: 180,
    headerName: 'action',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography style={HeaderName}>Ods</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.index || ''}
          value="index"
          placeholder="Ods"
          type="number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        {isLoadingAll ? (
          <Typography sx={odsId}>
            #
            {row.index}
          </Typography>
        ) : (
          <LinkButton path={`/directories/ods/update/${row.actions_ods_id}`}>
            <Typography sx={odsId}>
              #
              {row.index}
            </Typography>
          </LinkButton>
        )}
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'ods_defect',
    minWidth: 200,
    headerName: 'ods_defect',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography style={HeaderName}>Defect</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.ods_defect || ''}
          value="ods_defect"
          placeholder="Defect"
        />
      </Box>
    ),
    renderCell: ({ row }) => <Typography>{row.ods_defect}</Typography>,
  },
  {
    flex: 0.2,
    field: 'action',
    minWidth: 500,
    headerName: 'action',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography style={HeaderName}>Action</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.action || ''}
          value="action"
          placeholder="Action"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'action', row)}
        text={row.action}
        isLoading={isLoadingAll}
        labelInput="Enter Action"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'ata_id',
    minWidth: 180,
    headerName: 'ata_name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography style={HeaderName}>Ata</Typography>
        <FiltrationSelect
          options={odsAtaOptions}
          defaultValue={filters.ata_id || ''}
          handleFilter={handleFilter}
          value="ata_id"
          typeOptions="number"
          placeholder="Ata"
        />
      </Box>
    ),
    renderCell: ({ row }) => {
      const resAtaName = odsAtaOptions?.find(
        (e) => Number(e.value) === row.ata_id
      );

      return (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'ata_id', row)}
          text={resAtaName?.label}
          labelInput="Enter ata"
          type={TYPE_SELECT}
          options={odsAtaOptions}
          isLoading={isLoadingAll}
          activeItem={row.ata_id}
        />
      );
    },
  },
  {
    flex: 0.2,
    field: 'part_off',
    minWidth: 180,
    headerName: 'part_off',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography style={HeaderName}>part off</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.part_off || ''}
          value="part_off"
          placeholder="Part off"
        />
      </Box>
    ),
    renderCell: ({ row }) => <Typography sx={TextWrap}>{row.part_off}</Typography>,
  },
  {
    flex: 0.2,
    field: 'part_on',
    minWidth: 180,
    headerName: 'part_on',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography style={HeaderName}>part on</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.part_on || ''}
          value="part_on"
          placeholder="Part on"
        />
      </Box>
    ),
    renderCell: ({ row }) => <Typography sx={TextWrap}>{row.part_on}</Typography>,
  },

  {
    flex: 0.2,
    field: 'note',
    minWidth: 180,
    headerName: 'note',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography style={HeaderName}>note</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.note || ''}
          value="note"
          placeholder="Note"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'note', row)}
        text={row.note}
        isLoading={isLoadingAll}
        labelInput="Enter note"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'created_by',
    minWidth: 180,
    headerName: 'created_by',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography style={HeaderName}>Created by</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.created_by || ''}
          value="created_by"
          placeholder="Created by"
        />
      </Box>
    ),
    renderCell: ({ row }) => <Typography>{row.created_by}</Typography>,
  },
  {
    flex: 0.2,
    field: 'performed',
    minWidth: 180,
    headerName: 'performed',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography style={HeaderName}>Date</Typography>
        <FiltrationDate
          value={filters.performed || null}
          onChange={(e) => {
            handleFilter(e, 'performed', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'performed', row)}
        text={row.performed}
        isLoading={isLoadingAll}
        labelInput="Enter Date"
        type={TYPE_SELECT_DATE}
      />
    ),
  },
  {
    width: 100,
    field: 'files',
    headerName: 'Files',
    sortable: false,
    renderCell: ({ id, action }) => (
      <Box sx={{ width: '80px', margin: '0, 6px' }}>
        <Button sx={GreenButton} size="small" onClick={() => handleFileDrawer(true, id, OdsActionDocumentModel.types.action.id, 'Action', action)}>Files</Button>
      </Box>
    )
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <StyledDelete onClick={() => handleOpenDeleteModal(row.action_id)} />
    ),
  },
];

import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { totalColumns } from '../columns';

const LogBookTotals = ({ data }) => (
  <>
    <TableRow>
      <TableCell colSpan={7} sx={{ textAlign: 'right !important', fontWeight: 'bold !important' }}>
        TOTAL THIS PAGE
      </TableCell>
      {totalColumns.map(({ field }) => (
        <TableCell key={field}>
          {data?.total_this_page?.[field]}
          {' '}
        </TableCell>
      ))}
      <TableCell rowSpan={3}>
        <Box>
          <Box>
            I certify that then entries in this log are true.
          </Box>
          <Box>
            ------------------------------------------------
          </Box>
          <Box>
            PILOT'S SIGNATURE
          </Box>
        </Box>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell colSpan={7} sx={{ textAlign: 'right !important', fontWeight: 'bold !important' }}>
        TOTAL FROM PREVIOUS PAGES
      </TableCell>
      {totalColumns.map(({ field }) => (
        <TableCell key={field}>
          {data?.total_from_prev_page?.[field]}
        </TableCell>
      ))}
    </TableRow>

    <TableRow>
      <TableCell colSpan={7} sx={{ textAlign: 'right !important', fontWeight: 'bold !important' }}>
        TOTAL TIME
      </TableCell>
      {totalColumns.map(({ field }) => (
        <TableCell key={field}>
          {data?.total_time?.[field]}
        </TableCell>
      ))}
    </TableRow>
  </>
);

export default LogBookTotals;

import React from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AircraftTypeButton = (props) => {
  const { title } = props;
  const navigate = useNavigate();

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: '0.75rem',
        textAlignLast: 'end',
      }}
    >
      <Button
        variant="contained"
        onClick={() => {
          navigate('/aircraft/types/create', { replace: false });
        }}
      >
        {title}
      </Button>
    </Grid>
  );
};

export default AircraftTypeButton;

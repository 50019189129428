import SouthIcon from '@mui/icons-material/South';
import {
  Box, Card, Chip, Divider, Typography
} from '@mui/material';
import React from 'react';
import { headerBtn } from '../../../../Invoices/style';
import { TextWrap } from '../../../style';

const PartPath = (props) => {
  const { path = [] } = props;

  return (
    <Card
      sx={{ padding: '1rem' }}
    >
      <Box sx={headerBtn}>
        <Box>
          <Typography variant="h5" noWrap>
            Parts Path
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {
          path.map((item, index) => (
            <Box sx={{
              display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center'
            }}
            >
              
              <Chip
                label={(
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Typography>
                      {index + 1}
                      .
                    </Typography>
                    <Typography sx={TextWrap}>{item}</Typography>
                  </Box>
                  )}
                sx={{ fontWeight: 'bolder', height: 'auto' }}
              />
              
              {(path?.length !== (index + 1)) && <SouthIcon sx={{ margin: '10px 0' }} />}
            </Box>
          ))
        }
      </Box>
      <Divider />
    </Card>
  );
};

export default PartPath;
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PollIcon from '@mui/icons-material/Poll';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box, Card,
  Grid,
  InputAdornment,
  TextField
} from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment/moment';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import EditableTable from '../../../components/EditableTable';
import TableFilterContainer from '../../../components/EditableTable/TableFilterContainer';
import { useGetAircraftListQuery } from '../../../store/session';
import {
  useGetIncomeFileMutation, useGetIncomeTableQuery, useGetIncomeTotalQuery, useUpdateIncomeItemMutation
} from '../../../store/slices/financeSlice';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import { validateDatePickerValueCopy } from '../../../utils/setDateValue';
import CardStatistics from '../components/CardStatistics';
import UpdatePaidForm from '../components/forms/UpdatePaidForm';
import GenerateNotification from './GenerateNotification/Index';
import { columns } from './columns';

const ORDER_STATUSES = [
  { label: 'Paid', value: 'paid' },
  { label: 'Unpaid', value: 'unpaid' },
  { label: 'Canceled', value: 'canceled' },
];

const IncomePage = () => {
  const { data: AircraftList } = useGetAircraftListQuery();
  const aircraftOptions = AircraftList?.map((item) => ({
    value: item?.id,
    label: item?.aircraft_registration
  }));

  const [invoicePaymentDayFilter, setInvoicePaymentDayFilter] = useState(null);
  const [invoicePaymentDay, setInvoicePaymentDay] = useState(null);
  const [invoiceDayFilter, setInvoiceDayFilter] = useState(null);
  const [invoiceDay, setInvoiceDay] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [search, setSearch] = useState(null);
  const [arFilter, setArFilter] = useState(null);
  const [notificationDrawerState, setNotificationDrawerState] = useState({
    isOpen: false,
    flightId: null,
    brokerEmail: null,
    notificationTable: null,
    invoiceId: null
  });
  const [updatePaidForm, setUpdatePaidForm] = useState(false);
  const [income, setIncome] = useState(null);
  const [refetchTable, setRefetchTable] = useState(0);

  const { data: incomeTotal, refetch } = useGetIncomeTotalQuery(stringifyLocationSearch({
    aircraft_id: arFilter,
    status: statusFilter,
    day_of_invoice: invoiceDayFilter,
    day_of_payment: invoicePaymentDayFilter,
  }), {
    refetchOnMountOrArgChange: true
  });
  const [openFile, { isLoading: isLoadingOpenInvoice }] = useGetIncomeFileMutation();
  const hasPermission = usePermissions('finance_income_writer');

  const handleCloseUpdatePaidForm = () => {
    setIncome(null);
    setUpdatePaidForm(false);
  };
  const handleUpdatePaidModal = (item) => {
    setIncome(item);
    setUpdatePaidForm(true);
  };
  const handleUpdatePaid = () => {
    refetch();
    setRefetchTable(refetchTable + 1);
  };

  const handleOpenFile = async (fileId) => {
    const toastId = toast.loading('Opening...');

    await openFile(fileId).then((res) => {
      if (!res?.errros) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleOpenNotificationDrawer = (isOpen, data = {}) => {
    setNotificationDrawerState(prev => ({
      ...prev,
      isOpen,
      ...data
    }));
  };

  const closeDrower = () => {
    handleOpenNotificationDrawer(false, {
      flightId: null,
      brokerEmail: null,
      notificationTable: null,
      invoiceId: null
    });
  };

  const filterConfig = {
    items: [
      {
        value: arFilter, callback: (e) => setArFilter(e.target.value), closeCallback: () => setArFilter(null), typeFilter: 'Select', icon: 'Airplane', options: aircraftOptions, placeholder: 'Select Aircraft'
      },
      {
        value: statusFilter, callback: (e) => setStatusFilter(e.target.value), closeCallback: () => setStatusFilter(null), typeFilter: 'Select', icon: 'ListStatus', options: ORDER_STATUSES, placeholder: 'Select Status'
      },
      {
        value: validateDatePickerValueCopy(invoiceDay || null),
        callback: (e) => {
          setInvoiceDay(validateDatePickerValueCopy(e));
          setInvoiceDayFilter(e ? moment(e).format('DD-MM-YYYY') : '');
        },
        closeCallback: () => {
          setInvoiceDay(null);
          setInvoiceDayFilter(null);
        },
        typeFilter: 'DatePicker',
        icon: 'CalendarMonthIcon',
        placeholder: 'Select Day Of Invoice'
      },
      {
        value: validateDatePickerValueCopy(invoicePaymentDay || null),
        callback: (e) => {
          setInvoicePaymentDay(validateDatePickerValueCopy(e));
          setInvoicePaymentDayFilter(e ? moment(e).format('DD-MM-YYYY') : '');
        },
        closeCallback: () => {
          setInvoicePaymentDay(null);
          setInvoicePaymentDayFilter(null);
        },
        typeFilter: 'DatePicker',
        icon: 'CalendarMonthIcon',
        placeholder: 'Select Day Of Payment'
      }
    ]
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        {incomeTotal && (
          <Grid container spacing={6}>
            <Grid item xs={12} md={3} sm={6}>
              <CardStatistics title="Total" icon={<PollIcon />} stats={incomeTotal.total} color="info" />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <CardStatistics title="Paid" icon={<CreditScoreIcon />} stats={incomeTotal.paid} />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <CardStatistics title="Unpaid" icon={<CreditCardOffIcon />} stats={incomeTotal.unpaid} color="error" />
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Card>
          <TableFilterContainer filterConfig={filterConfig} />
          <Box sx={{
            p: 5,
            pb: 3,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <TextField
                size="small"
                sx={{
                  mr: 6, mb: 2, minWidth: '300px', maxWidth: '300px'
                }}
                placeholder="Search..."
                onChange={debounce((e) => setSearch(e.target.value), 400)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <EditableTable
            useHookUpdate={useUpdateIncomeItemMutation}
            useHook={useGetIncomeTableQuery}
            columns={columns}
            sortByType="DESC"
            sortByActive="id"
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.id}
            handleOpenFile={handleOpenFile}
            handleOpenNotificationDrawer={(flightId, brokerEmail, notifications_data, inv_id) => handleOpenNotificationDrawer(true, {
              flightId, brokerEmail, notificationTable: notifications_data, invoiceId: inv_id 
            })}
            handleOpenUpdatePaidModal={(incomeId) => handleUpdatePaidModal(incomeId)}
            hasPermission={hasPermission}
            refetchTable={refetchTable}
            tableParams={{
              aircraft_id: arFilter,
              status: statusFilter,
              day_of_invoice: invoiceDayFilter,
              day_of_payment: invoicePaymentDayFilter,
              search
            }}
            style={{
              '& .MuiDataGrid-columnHeaders': { borderRadius: 0 }
            }}
          />
        </Card>
        {income && <UpdatePaidForm open={updatePaidForm} onClose={handleCloseUpdatePaidForm} currentIncome={income} onUpdated={handleUpdatePaid} />}
      </Grid>

      <GenerateNotification
        open={notificationDrawerState.isOpen}
        onClose={closeDrower}
        flightId={notificationDrawerState.flightId}
        brokerEmail={notificationDrawerState.brokerEmail}
        tableData={notificationDrawerState.notificationTable}
        refetchTable={handleUpdatePaid}
        handleOpenFile={handleOpenFile}
        invoiceId={notificationDrawerState.invoiceId}
        isLoadingOpenInvoice={isLoadingOpenInvoice}
      />
    </Grid>
  );
};

export default IncomePage;

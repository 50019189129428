
export const headerBtn = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 3
};

export const btnS = {
  width: '100%'
};

export const headerBtnS = {
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'start',
  marginBottom: '25px'
};

export const headerBtnXs = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'start',
};

export const tableContainer = {
  mt: '10px',
  '& .row-gray': {
    backgroundColor: '#00000033',       
    '&.Mui-selected': {
      backgroundColor: '#00000038  !important',
    },   
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3) !important',
    }
  },
  '& .row-yellow': {
    backgroundColor: '#f1c40f33',
    '&.Mui-selected': {
      backgroundColor: '#f1c40f66  !important',
    },
    '&:hover': {
      backgroundColor: 'rgba(241, 196, 15, 0.3) !important',
    }
  },
  '& .row-green': {
    backgroundColor: '#36c6d333',       
    '&.Mui-selected': {
      backgroundColor: '#36c6d366  !important',
    },   
    '&:hover': {
      backgroundColor: 'rgba(54, 198, 211, 0.3) !important',
    }
  },
  '& .row-red': {
    backgroundColor: '#ed6b7533',
    '&.Mui-selected': {
      backgroundColor: '#ed6b7566  !important',
    },   
    '&:hover': {
      backgroundColor: 'rgba(237, 107, 117, 0.3) !important',
    }
  }
};

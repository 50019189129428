export const loaderSx = {
  position: 'fixed',
  width: '110vw',
  height: '200vh',
  zIndex: 1000,
  top: 0,
  left: 0,
  background: '#e9e9e9'
};

export const cardContainer = {
  p: '30px',
  display: 'flex',
  gap: '15px',
};

export const saveBtn = {
  color: '#fff',
  maxWidth: '200px',
  width: '100%',
};

export const button = {
  background: 'transparent',
  width: '100%',
  maxWidth: '100px',
  borderRadius: '0px',
  border: 'none',
  color: '#000000',
  boxShadow: 'none',
  borderBottom: '2px solid transparent',
  '&:hover': {
    borderBottom: '2px solid rgb(25, 118, 210)',
    boxShadow: 'none',
    color: 'rgb(25, 118, 210)',
    background: 'transparent',
  }
};

export const buttonActive = {
  borderBottom: '2px solid rgb(25, 118, 210)',
  color: 'rgb(25, 118, 210)',
};

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, Tooltip } from '@mui/material';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Button from '../../components/Button';
import Autocomplete from '../../form/components/Autocomplete';
import Input from '../../form/components/Input';
import { useGetCurrencyListQuery, useUpdateBankTransactionsCurrencyMutation } from '../../store/session';
import { getOptions } from '../../utils/getOptions';
import {
  boxInvoice,
  btn,
  containerInvoice,
  content,
  deleteIcon,
  itemNum
} from './style';
import toast from 'react-hot-toast';

const CardsTable = ({ bank = null }) => {
  const { control, watch, setValue } = useFormContext();
  const { data: currencyList, isLoading: isCurrencyLoading } = useGetCurrencyListQuery();
  const [updateBankTransactionsCurrency] = useUpdateBankTransactionsCurrencyMutation();

  const currencyOptions = getOptions(currencyList);

  const {
    fields,
    append,
    remove,
    update,
  } = useFieldArray({ name: 'account_numbers', control });

  const handleAddCard = (index, data) => {
    const accountNumbers = watch(`account_numbers.${index}`);

    update(
      index,
      {
        ...accountNumbers,
        cards: [...(data.cards || []), { card_number: '' }]
      }
    );
  };

  const handleDeleteCard = (index, i) => {
    const accountNumbers = watch(`account_numbers.${index}`);

    update(index, {
      ...accountNumbers,
      cards: accountNumbers.cards.filter((item, itemIndex) => itemIndex !== i)
    });
  };

  const addAccountNumber = () => {
    append({
      account_number: null,
      cards: [],
    });
  };

  const syncCurrency = async () => {
    if (!bank) {
      return;
    }
    const toastId = toast.loading('Loading...');

    const res = await updateBankTransactionsCurrency(bank.id);

    if (res.error) {
      toast.error(res.error?.data?.message ? res.error.data.message : 'Error', {
        id: toastId,
      });

      return;
    }

    toast.success('Successfully synced', {
      id: toastId,
    });
  };

  return (
    <Box sx={content}>
      {fields?.map((item, index) => (
        <Grid container spacing={5} sx={{ display: 'flex', }} key={item.id}>
          <Grid sx={itemNum} item xs={0.3} lg={0.3}>
            {index + 1}
          </Grid>

          <Grid item xs={0.3} lg={0.3}>
            <Tooltip title="Delete account number">
              <DeleteIcon
                sx={deleteIcon}
                onClick={() => remove(index)}
              />
            </Tooltip>
          </Grid>

          <Grid item xs={2} lg={2}>
            <Controller
              name={`account_numbers.${[index]}.account_number`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Account number"
                />
              )}
            />
          </Grid>

          <Grid item xs={1.5} lg={1.5}>
            <Controller
              name={`account_numbers.${index}.currency_id`}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  value={currencyOptions?.filter((currency) => currency?.value == field?.value)[0]}
                  options={currencyOptions}
                  size="small"
                  disable={isCurrencyLoading}
                  placeholder="Currency"
                  onChange={(e, value ) => {
                    field.onChange(value?.value);
                    setValue(`account_numbers.${index}.currency_id`, value?.value || null);
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={2} lg={2}>
            <Controller
              name={`account_numbers.${[index]}.account_number_notes`}
              control={control}
              render={({ field }) => (
                <Input
                  multiline
                  rows={4}
                  {...field}
                  label="Account number notes"
                />
              )}
            />
          </Grid>

          <Grid item xs={5} lg={5} sx={containerInvoice}>
            {item.cards?.map((card, subIndex) => (
              <Grid key={card.id} item sx={boxInvoice}>
                <Controller
                  name={`account_numbers.${[index]}.cards.${[subIndex]}.card_number`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Card number"
                    />
                  )}
                />

                <Tooltip title="Delete card">
                  <DeleteIcon
                    sx={deleteIcon}
                    onClick={() => handleDeleteCard(index, subIndex)}
                  />
                </Tooltip>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={0.5} lg={0.5}>
            <AddIcon
              sx={deleteIcon}
              onClick={() => handleAddCard(index, item)}
            />
          </Grid>
        </Grid>
      ))}

      <Grid sx={{ display: 'flex' }}>
        <Button sx={btn} title="Add account number" onClick={addAccountNumber} />
        {(bank && bank.bank_name.includes('TAKAREK')) && <Button sx={{ ...btn, marginLeft: '16px' }} title="Sync currency" onClick={syncCurrency} />}
      </Grid>
    </Box>
  );
};

export default CardsTable;

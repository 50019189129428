
import {
  object, string, array, number
} from 'yup';

export const defaultValues = {
  srvsup_ap: '',
  srvsup_name: '',
  srvsup_phone: '',
  srvsup_fax: '',
  srvsup_vhf: '',
  srvsup_email: '',
  srvsup_MobileTel: '',
  srvsup_AfterHoursTel: '',
  srvsup_Skype: '',
  srvsup_sita: '',
  srvsup_aftn: '',
  srvsup_open: '',
  srvsup_website: '',
  srvsup_TelInvoice: '',
  srvsup_EmailInvoice: '',
  srvsup_notes: '',
  srvsup_address: ' ',
  srvsup_h_ramp: '0',
  srvsup_h_cargo: '0',
  services: [],
};

export const schema = object().shape({
  srvsup_name: string()
    .nullable()
    .required('${label} cannot be blank.')
    .max(255)
    .min(3)
    .label('Supplier name'),
  srvsup_ap: number()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Airport'),
  srvsup_phone: string()
    .required('${label} cannot be blank.')
    .label('Supplier phone'),
  srvsup_fax: string()
    .nullable()
    .label('Supplier fax'),
  srvsup_vhf: string()
    .nullable()
    .label('Supplier VHF'),
  srvsup_email: string()
    .nullable()
    .label('Supplier Email'),
  srvsup_MobileTel: string()
    .nullable()
    .label('Supplier mobile phone'),
  srvsup_AfterHoursTel: string()
    .nullable()
    .label('Supplier after hours phone'),
  srvsup_Skype: string()
    .nullable()
    .label('Skype'),
  srvsup_sita: string()
    .nullable()
    .label('Supplier Sita'),
  srvsup_aftn: string()
    .nullable()
    .label('Supplier Aftn'),
  srvsup_open: string()
    .nullable()
    .label('Supplier Open'),
  srvsup_website: string()
    .nullable()
    .label('Supplier Website'),
  srvsup_TelInvoice: string()
    .nullable()
    .label('Supplier phone invoice'),
  srvsup_EmailInvoice: string()
    .nullable()
    .label('Supplier email invoice'),
  srvsup_notes: string()
    .nullable()
    .label('Supplier notes'),
  srvsup_address: string()
    .nullable()
    .label('Supplier address'),
  srvsup_h_ramp: string()
    .nullable()
    .label('Supplier ramp handling'),
  srvsup_h_cargo: string()
    .nullable()
    .label('Supplier cargo handling'),
  services: array()
});

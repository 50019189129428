import Typography from '@mui/material/Typography';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from '../../../constans/formats';
import { linkSx } from '../styles';
import { HeaderName } from '../../../components/EditableTable/styles';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import { Box } from '@mui/material';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import { STATUSES, STATUSES_INVOICE_PAYMENT } from '../../../constans/invoiceRequest';
import TextEdit from '../../../components/EditableTable/TextEdit';
import { TYPE_SELECT, TYPE_SELECT_DATE } from '../../../components/EditableTable/constants';
import { OPTIONS_INVOICE_STATUS, OPTIONS_INVOICE_STATUS_EDIT } from '../../../constans/invoice';
import { menuItem, invalidMenuItem } from './styles';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import FiltrationAutocomplete from '../../../components/EditableTable/FiltrationAutocomplete';
import { useIsDeprecated } from './hooks/useIsDeprecated';
import ButtonIcon from '../../../components/ButtonIcon';
import CloseIcon from '@mui/icons-material/Close';

export const columnsInvoicesAccount = ({
  currencyOptions, handleFilter, filters, handleDownload, handleOpen, handleOpenDropDown, handleOpenDeleteModal
}) => ([
  {
    width: 180,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {STATUSES.find(i => i.value === row.payment_status)?.value !== 5 && (
        <ButtonIcon
          sx={{
            ml: 2,
            '&:hover': {
              color: '#f35d5d !important'
            },
          }}
          onClick={() => handleOpenDeleteModal({
            id: row.id, data: row, commentDelete: true,
          })}
        >
          <CloseIcon />
        </ButtonIcon>
        )}
      </>
    ),
  },
  {
    flex: 0.3,
    field: 'file_name',
    minWidth: 220,
    headerName: 'File',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>File</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.file_name || ''}
          value="file_name"
          placeholder="File"
        />
      </Box>
    ),
    renderCell: ({ row: { file_name, id, } }) => (
      <Typography
        sx={{
          ...linkSx,
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
        onClick={() => handleDownload({ file_name, id }, 'inv')}
      >
        {file_name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'invoice_sum',
    minWidth: 180,
    headerName: 'Sum',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Sum</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.invoice_sum || ''}
          value="invoice_sum"
          placeholder="Sum"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {row.invoice_sum || '-'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'currency',
    minWidth: 220,
    headerName: 'Currency',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Currency</Typography>
        <FiltrationAutocomplete
          options={currencyOptions || []}
          handleFilter={handleFilter}
          defaultValue={filters.currency || ''}
          sx={{ width: '180px' }}
          value="currency"
          placeholder="Currency"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {row.currency}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'payment_status',
    minWidth: 300,
    headerName: 'Payment Status',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Payment Status</Typography>
        <FiltrationSelect
          options={STATUSES_INVOICE_PAYMENT || []}
          defaultValue={filters.payment_status || ''}
          handleFilter={handleFilter}
          placeholder="Payment Status"
          value="payment_status"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{STATUSES.find(i => i.value === row.payment_status)?.label || 'Empty'}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'upload_date',
    minWidth: 180,
    headerName: 'Upload Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          '& .MuiPickersCalendarHeader-root': {
            display: 'none'
          }
        }}
      >
        <Typography style={HeaderName}>Upload Date</Typography>
        <FiltrationDate
          value={filters.upload_date || null}
          onChange={(e) => {
            handleFilter(e, 'upload_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.upload_date ? moment(row.upload_date).format(DATE_FORMAT) : null}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'due_payment_date',
    minWidth: 180,
    headerName: 'Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Deadline Payment</Typography>
        <FiltrationDate
          value={filters.due_payment_date || null}
          onChange={(e) => {
            handleFilter(e, 'due_payment_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'due_payment_date', row)}
        text={row.due_payment_date}
        labelInput="Enter Date"
        type={TYPE_SELECT_DATE}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'description',
    minWidth: 280,
    headerName: 'Description',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Description</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.description || ''}
          value="description"
          placeholder="Description"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={{
        textTransform: 'capitalize',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-all'
      }}
      >
        {row.description}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'invoice_payed',
    minWidth: 180,
    headerName: 'Invoice payed',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoice payed</Typography>
        <FiltrationSelect
          options={OPTIONS_INVOICE_STATUS || []}
          defaultValue={filters.payment_status || ''}
          handleFilter={handleFilter}
          placeholder="Invoice payed"
          value="payment_status"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'payment_status', row)}
        options={OPTIONS_INVOICE_STATUS_EDIT}
        text={OPTIONS_INVOICE_STATUS?.find((item) => +item.value === +row.payment_status)?.label}
        activeItem={row.payment_status}
        labelInput="Invoice payed"
        type={TYPE_SELECT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'payment_date',
    minWidth: 180,
    headerName: 'Payment date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Payment date</Typography>
        <FiltrationDate
          value={filters.payment_date || null}
          onChange={(e) => {
            handleFilter(e, 'payment_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'payment_date', row)}
        text={row.payment_date ? moment(row.payment_date).format(DATE_FORMAT) : null}
        labelInput="Payment date"
        type={TYPE_SELECT_DATE}
      />
    ),
  },
  {
    field: 'proof_of_payment_file',
    minWidth: 200,
    headerName: 'Proof Of Payment',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Proof Of Payment</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <>
        <Box sx={{ ...menuItem, ...(row.proof_of_payment_file ? null : invalidMenuItem) }}>
          { row.proof_of_payment_file ? (<EditIcon onClick={(e) => handleOpenDropDown(e, row)} />) : (<AddIcon onClick={(e) => handleOpenDropDown(e, row)} />)}
        </Box>
        {row.due_payment_date && useIsDeprecated(row.due_payment_date) && row.payment_status !== 3 && (
        <Box sx={{ color: 'red', ml: 10 }}>
          <PriorityHighIcon />
        </Box>
        )}
      </>
    ),
  },
]);

import React from 'react';
import Accordion from '../Accordion';
import Grid from '@mui/material/Grid';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { Controller } from 'react-hook-form';

const DeIceing = ({ control, isPanel }) => (
  <Accordion title="DE ICEING/ANTI ICEING" isPanel={isPanel}>
    <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1 }}>
      <Grid item xs={12} lg={3}>
        <FormGroup label="TYPE">
          <Controller
            name="iceing_type"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={3}>
        <FormGroup label="%">
          <Controller
            name="iceing_percent"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={3}>
        <FormGroup label="TIME">
          <Controller
            name="iceing_time"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={3}>
        <FormGroup label="HOT">
          <Controller
            name="iceing_hot"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>
    </Grid>
  </Accordion>
);

export default DeIceing;

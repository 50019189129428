import { Accordion, Box, styled } from '@mui/material';

export const AccordionStyled = styled(Accordion)(() => ({
  border: 'none',
  borderRadius: '0px!important',
  boxShadow: 'none!important',
  backgroundColor: 'transparent!important',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

export const AccordionDetailsContainer = styled(Box)(() => ({ 
  display: 'flex', 
  gap: '5px', 
  alignItems: 'center',
  borderBottom: '1px solid #fff',
  transition: 'all .3s linear',
  '&:hover': {
    borderColor: 'rgba(0, 52, 103, 0.9);'
  },
  marginBottom: '5px'
}));
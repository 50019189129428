import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, TextField
} from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import DefaultButton from '../../../../../components/Button';
import { RedButton } from '../../../../Logistics/style';
import { useDeclineInvoiceMutation } from '../../../../../store/session';

const DeclineButton = ({ invoiceId, onSubmit }) => {
  const [decline, { isLoading }] = useDeclineInvoiceMutation();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [declineMessage, setDeclineMessage] = useState('');

  const handleOpen = () => {
    setConfirmOpen(true);
  };

  const handleClose = () => {
    setConfirmOpen(false);
    setDeclineMessage('');
  };

  const handleDecline = async () => {
    const toastId = toast.loading('Loading...');

    if (!declineMessage.trim()) {
      toast.error('Decline reason is required', {
        id: toastId
      });

      return;
    }
    const { error } = await decline({
      id: invoiceId,
      data: {
        decline_purpose: declineMessage,
      }
    });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId
    });
    setConfirmOpen(false);
    setDeclineMessage('');
    onSubmit();
  };

  const handleDeclineMessageChange = (event) => {
    setDeclineMessage(event.target.value);
  };

  return (
    <>
      <Button
        sx={RedButton}
        variant="contained"
        onClick={handleOpen}
      >
        Decline
      </Button>
      <Dialog
        open={confirmOpen}
        onClose={handleClose}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to decline this invoice? This action cannot be undone.
          </DialogContentText>
          <TextField
            sx={{ mt: '20px' }}
            autoFocus
            id="decline-reason"
            label="Decline Reason"
            type="text"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            value={declineMessage}
            onChange={handleDeclineMessageChange}
          />
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={handleClose}>
            Cancel
          </DefaultButton>
          <Button
            sx={RedButton}
            onClick={handleDecline}
            disabled={isLoading}
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeclineButton;

import React from 'react';
import { Controller } from 'react-hook-form';
import {
  alertsListOptions, roleList,
} from './schema';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Checkbox from '../../../../form/components/Checkbox';
import FieldError from '../../../../form/components/FieldError';
import Autocomplete from '../../../../form/components/Autocomplete';
import FormGroup from '../../../../form/components/FormGroup';
import ContentPageLoader from '../../../../components/Loader/ContentPageLoader';
import Input from '../../../../form/components/Input';
import Select from '../../../../form/components/Select';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useUserForm } from './useUserForm';

const UserForm = () => {
  const {
    showPassword,
    showPasswordCheck,
    maxWidth768px,
    isLoading,
    isUpdating,
    groupsListOptions,
    positionsListOptions,
    update,
    errors,
    groupsLoading,
    control,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleClickShowPasswordCheck,
    handleMouseDownPasswordCheck,
    handleSubmit,
    userData,
    handleChangePositions,
    onSubmit,
  } = useUserForm();

  const title = update ? `Update User ${userData?.name}` : 'Create User';

  if (isLoading) {
    return <ContentPageLoader />;
  }

  return (
    <form>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
            {title}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 7}>
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <Grid sx={{ display: 'flex', gap: '30px' }}>
                    <FormGroup label="Status" hasError={!!errors.status}>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            onChange={() => {
                              field.onChange( !field.value );
                            }}
                          />
                        )}
                      />
                      <FieldError error={errors.status} />
                    </FormGroup>
                    {!update && (
                      <FormGroup label="Notify User" hasError={!!errors.notify_user}>
                        <Controller
                          name="notify_user"
                          control={control}
                          render={({ field }) => (<Checkbox {...field} />)}
                        />
                        <FieldError error={errors.notify_user} />
                      </FormGroup>
                    )}
                    <FormGroup label="Deactivated" hasError={!!errors.deactivated_at}>
                      <Controller
                        name="deactivated_at"
                        control={control}
                        render={({ field }) => ( <Checkbox {...field} />)}
                      />
                      <FieldError error={errors.deactivated_at} />
                    </FormGroup>
                  </Grid>
                  <FormGroup label="Role" required hasError={!!errors.role}>
                    <Controller
                      name="role"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={roleList}
                          placeholder="Role"
                        />
                      )}
                    />
                    <FieldError error={errors.role} />
                  </FormGroup>
                  <FormGroup label="Access Group" required hasError={!!errors.groups_id}>
                    <Controller
                      name="groups_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          disabled={groupsLoading}
                          options={groupsListOptions}
                          placeholder="Alert Group"
                        />
                      )}
                    />
                    <FieldError error={errors.groups_id} />
                  </FormGroup>
                  <FormGroup label="Username" required hasError={!!errors.username}>
                    <Controller
                      name="username"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="Username"
                          {...field}
                        />
                      )}
                    />
                    <FieldError error={errors.username} />
                  </FormGroup>
                  <FormGroup label="Name" required hasError={!!errors.name}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Name" {...field} />
                      )}
                    />
                    <FieldError error={errors.name} />
                  </FormGroup>
                  <FormGroup label="Email" required hasError={!!errors.email}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Email" {...field} />
                      )}
                    />
                    <FieldError error={errors.email} />
                  </FormGroup>
                  <FormGroup label="Alert Group" required hasError={!!errors.alert_group}>
                    <Controller
                      name="alert_group"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={alertsListOptions}
                          placeholder="Alert Group"
                        />
                      )}
                    />
                    <FieldError error={errors.alert_group} />
                  </FormGroup>
                  <FormGroup label="Positions">
                    <Controller
                      name="positions"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          multiple
                          id="tags-outlined"
                          placeholder="Positions"
                          options={positionsListOptions}
                          getOptionLabel={(option) => option.label || ''}
                          isOptionEqualToValue={(option, item) => option.value === item.value}
                          onChange={(e, data) => {
                            handleChangePositions(data, field.onChange);
                          }}
                          value={field.value ?? []}
                        />
                      )}
                    />
                    <FieldError />
                  </FormGroup>
                  {!update && (
                    <>
                      <FormGroup label="Password" required hasError={!!errors.password}>
                        <Controller
                          name="password"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder="Password"
                              type={showPassword ? 'text' : 'password'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          )}
                        />
                        <FieldError error={errors.password} />
                      </FormGroup>
                      <FormGroup label="Password check" required hasError={!!errors.password_confirmation}>
                        <Controller
                          name="password_confirmation"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type={showPasswordCheck ? 'text' : 'password'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPasswordCheck}
                                      onMouseDown={handleMouseDownPasswordCheck}
                                      edge="end"
                                    >
                                      {showPasswordCheck ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              placeholder="Password check"
                            />
                          )}
                        />
                        <FieldError error={errors.password_confirmation} />
                      </FormGroup>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Button sx={{ mt: 5 }} type="submit" disabled={isUpdating} variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
            {update ? 'Update' : 'Save' }
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserForm;

import React from 'react';
import Button from '../Button/Button';

const ButtonIcon = ({
  children, disabled, onClick, sx
}) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    sx={{
      border: 'none',
      boxShadow: 'none !important',
      padding: '2px',
      backgroundColor: 'initial !important',
      color: 'rgba(76, 78, 100, 0.87) !important',
      minWidth: '0',
      '&:hover': {
        backgroundColor: 'initial',
      },
      ...sx,
    }}
  >
    {children}
  </Button>
);

export default ButtonIcon;

import React, { useEffect, useState } from 'react';
import {
  Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow,
} from '@mui/material';
import { Plus } from 'mdi-material-ui';
import Input from '../../../../form/components/Input';
import FormGroup from '../../../../form/components/FormGroup';
import FieldError from '../../../../form/components/FieldError';
import { StyledDelete } from '../../../Flights/list/styles';

const PersonsForm = (props) => {
  const {
    personFields,
    Controller,
    control,
    watch,
    setValue,
    errors,
    trigger,
    getValues,
    personRemove,
  } = props;

  const addNewPerson = async () => {
    const persons = getValues('persons');
    const isValid = await trigger();

    if (isValid) {
      const NewPerson = {

        person_name: '',
        person_phone: '',
        person_email: '',
        person_skype: '',

      };

      const personsItems = [...persons, NewPerson];

      setValue('persons', personsItems);
    }
  };

  useEffect(() => {
    const subscription = watch(async(value, { name, type }) => {
      const formValues = getValues();
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Grid container spacing={3} rowSpacing={2} sx={{ flexGrow: 1 }}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" sx={{ minWidth: '1000px' }}>
            <TableBody>
              {personFields.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <FormGroup required hasError={errors?.persons?.[index]?.person_name}>
                      <Controller
                        name={`persons.${index}.person_name`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            label="Name"
                            placeholder="Name"
                          />
                        )}
                      />
                      <FieldError error={errors?.persons?.[index]?.person_name} />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup required hasError={errors?.persons?.[index]?.person_phone}>
                      <Controller
                        name={`persons.${index}.person_phone`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            label="Phone"
                            placeholder="Phone"
                          />
                        )}
                      />
                      <FieldError error={errors?.persons?.[index]?.person_phone} />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup hasError={errors?.persons?.[index]?.person_email}>
                      <Controller
                        name={`persons.${index}.person_email`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            type="email"
                            label="Email"
                            placeholder="Email"
                          />
                        )}
                      />
                      <FieldError error={errors?.persons?.[index]?.person_email} />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup hasError={errors?.persons?.[index]?.person_skype}>
                      <Controller
                        name={`persons.${index}.person_skype`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            placeholder="Skype"
                            label="Skype"
                          />
                        )}
                      />
                      <FieldError error={errors?.persons?.[index]?.person_skype} />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    {index > 0 && (
                      <StyledDelete
                        onClick={() => { personRemove(index); }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            variant="contained"
            size="small"
            sx={{ margin: '2rem 0.75rem 1rem 0.75rem', padding: '0.75rem' }}
            onClick={addNewPerson}
          >
            <Plus sx={{ mr: 1 }} />
            Add Person
          </Button>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PersonsForm;

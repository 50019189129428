import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import ContentPageLoader from '../../../../components/Loader/ContentPageLoader';
import { HISTORY_GO_BACK } from '../../../../constans/constants';
import {
  useGetRefFileListMaintenanceMutation, useOpenRefFileMaintenanceMutation, useUpdateWorkOrderTaskMutation, useWorkOrderTaskDetailMutation
} from '../../../../store/session';
import { setFilesName } from '../../../../utils/constants/portalFiles';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import FileDrawer from '../../../TrainingModule/FileDrawer/FileDrawer';
import AttachedFilesTable from './AttachedFiles';
import ConsumableList from './ConsumableList';
import DateSing from './DateSing';
import General from './General';
import PartOffPartOn from './PartOffPartOn';
import Task from './Task';
import TaskToolsList from './TaskToolsList';
import { defaultValues, schema } from './schema';
import {
  backIcon, cancelBtn, formContainer, headerSx, saveBtn
} from './style';

const WorkOrderTaskForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [taskData, setTaskData] = useState(null);
  const handleError = useHandleQueryFormError();
  const [fileDrawerState, setFileDrawerState] = useState({
    isOpen: false,
    type: null,
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });

  const [getFiles, { isLoading: isGetFilesLoading }] = useGetRefFileListMaintenanceMutation();
  const [getWorkOrderTask, { data: workOrderTask, isLoading: isLoadingWorkOrder },] = useWorkOrderTaskDetailMutation();
  const [update, { isLoading: isLoadingUpdate }] = useUpdateWorkOrderTaskMutation();

  const hasPermissionsWoEdit = usePermissions(['wo_creator', 'wo_superuser']);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit, setValue, setError, getValues, formState: { dirtyFields }, reset
  } = methods;

  const handleBack = () => {
    navigate(HISTORY_GO_BACK);
  };

  const getFileList = async (id, callback) => {
    const res = await getFiles({ id });

    if (res?.error) {
      toast.error('Unable to get file list.');

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const handleUpdateValues = (result) => {
    Object.entries(result).forEach(
      ([name, value]) => {
        setValue(name, value);
      }
    );
  };

  const handleFlightData = async () => {
    const { data: result } = await getWorkOrderTask(params.id);

    if (result) {
      setTaskData(result);
      handleUpdateValues(result);
    }
  };

  const handleFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    if (id) {
      getFileList(id, (list) => setFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: setFilesName(list || []),
        name,
        currentName
      })));
    }

    setFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
  };

  useEffect(() => {
    handleFlightData();
  }, []);

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const formattedData = Object.keys(dirtyFields).reduce((res, key) => ({ ...res, [key]: data[key] }), {});
    const res = await update({ data: formattedData, id: params.id });

    if (res.error) {
      handleError(res.error, setError, getValues, toastId);

      return;
    }

    reset(res.data);

    toast.success('Work order task was updated!', {
      id: toastId
    });
  };

  if (isLoadingWorkOrder) return <ContentPageLoader />;

  return (
    <Box>
      <Box sx={headerSx}>
        <ChevronLeftIcon sx={backIcon} onClick={handleBack} />
        <h1>{workOrderTask?.name}</h1>
      </Box>

      <Box
        sx={{
          padding: '2rem',
          background: 'white',
          mb: '20px',
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Grid
                container
                xs={12}
                sx={{
                  display: 'flex', gap: '10px', mb: '30px'
                }}
              >
                <Grid item xs={12} md={4}>
                  <Button disabled={isLoadingUpdate || !hasPermissionsWoEdit} sx={saveBtn} type="submit" title="Save" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button sx={cancelBtn} onClick={handleBack} title="Cancel" />
                </Grid>
              </Grid>
            </Box>

            <Box sx={formContainer}>
              <DateSing performedBy={workOrderTask?.performed_by} closedBy={workOrderTask?.closed_by} />
              <Task workOrderTask={workOrderTask} />
              <General taskData={taskData} handleFileDrawer={handleFileDrawer} />
            </Box>
          </form>
        </FormProvider>
      </Box>

      <AttachedFilesTable hasPermissionsWoEdit={hasPermissionsWoEdit} />

      <PartOffPartOn hasPermissionsWoEdit={hasPermissionsWoEdit} workOrderTask={workOrderTask} />

      <ConsumableList hasPermissionsWoEdit={hasPermissionsWoEdit} />

      <TaskToolsList hasPermissionsWoEdit={hasPermissionsWoEdit} />

      <FileDrawer
        open={fileDrawerState.isOpen}
        onClose={() => handleFileDrawer(false)}
        title={fileDrawerState.name}
        itemName={fileDrawerState.currentName}
        itemsList={fileDrawerState.fileList}
        uploadFile={() => {}}
        hasPermissions={false}
        hasUploadButton={false}
        isLoadingFiles={isGetFilesLoading}
        useOpenFileMutation={useOpenRefFileMaintenanceMutation}
        downloadButton
      />
    </Box>
  );
};

export default WorkOrderTaskForm;

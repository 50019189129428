import React from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import {
  calendarBox, calendarDataSx, containerSx, flexContainer, titleSx, wrapperSx
} from './styles';
import { DAY_IN_WEEK, WEEK } from '../../../components/CrewTable/constants';
import { WEEK_COLORS } from './constants';
import { Typography } from '@mui/material';

const getLastDays = (daysAmount) => Array.from({ length: DAY_IN_WEEK }).reduce((res) => ((daysAmount + res) % DAY_IN_WEEK === 0 ? res : res + 1), 0);

export const Calendar = ({ date, data }) => {
  const daysInMonth = moment(date).daysInMonth();
  const firstDayInMonth = moment(date).startOf('month').format('ddd');
  const daysAmount = daysInMonth + WEEK[firstDayInMonth];
  const arrayLength = daysAmount + getLastDays(daysAmount);

  return (
    <Box sx={wrapperSx}>
      <Typography sx={titleSx}>
        {`${moment(date).format('MMMM')} - ${moment(date).format('YYYY')}`}
      </Typography>
      <Box sx={containerSx}>
        {Array.from({ length: arrayLength }).map((item, index) => {
          const skip = WEEK[firstDayInMonth];
          const day = index - skip;
          const currentDate = moment(date).startOf('month');
          if (index > skip && day < daysInMonth) {
            currentDate.add(day, 'day');
          }
          const dayLabel = currentDate.format('ddd');
          const res = data[currentDate.format('YYYY-MM-DD')];
          const {
            duty_time, sum_block_time, sum_flight_time, ...values
          } = res || {};

          return (
            <>
              {(index === 0 || index % 7 === 0) && (
              <Box sx={{ ...calendarBox, fontWeight: 'bold' }}>
                DAY
                <Box sx={{ mt: '22px', fontSize: '14px', }}>DUTY|BLOCK|FLIGHT</Box>
                <Box sx={{ fontSize: '14px', }}>ODS|BL|FL</Box>
              </Box>
              )}
              <Box sx={calendarBox}>
                {(index < skip || day >= daysInMonth) ? '' : (
                  <Box sx={{ color: WEEK_COLORS[dayLabel] }}>
                    {`${day + 1} ${dayLabel}`}
                  </Box>
                )}

                {res && (
                  <Box sx={flexContainer}>
                    <Box sx={calendarDataSx}>DT/ODS</Box>
                    <Box sx={calendarDataSx}>BL</Box>
                    <Box sx={calendarDataSx}>FL</Box>

                    <Box sx={calendarDataSx}>{duty_time}</Box>
                    <Box sx={calendarDataSx}>{sum_block_time}</Box>
                    <Box sx={calendarDataSx}>{sum_flight_time}</Box>

                    {Object.keys(values).map(key => (
                      <>
                        <Box sx={calendarDataSx}>
                          <a href={`/directories/ods/update/${values[key].id}`} target="_blank" rel="noreferrer">
                            {values[key].index}
                          </a>
                        </Box>
                        <Box sx={calendarDataSx}>{values[key].flight_block}</Box>
                        <Box sx={calendarDataSx}>{values[key].flight_time}</Box>
                      </>
                    ))}
                  </Box>
                )}
              </Box>
            </>
          );
        })}
      </Box>
    </Box>
  );
};

export const blue = {
  backgroundColor: '#d9edf7',
};

export const white = {
  backgroundColor: 'white',
};

export const yellow = {
  backgroundColor: '#fcf8e3',
};

export const header = {
  color: '#616161',
  fontWeight: '600 !important',
  whiteSpace: 'nowrap',
  minWidth: '135px',
  textTransform: 'capitalize !important',
};

export const body = {
  minWidth: '125px',
};

export const input = {
  '& .MuiFormLabel-root': {
    fontSize: '14px',
  },
};

export const tableCell = {
  fontSize: '13px !important',
  'input': {
    fontSize: '13px !important',
    '&.MuiFormLabel-root-MuiInputLabel-root': {
      fontSize: '13px !important',
    }
  },
};

export const title = {
  display: 'flex',
  gap: '20px',
  mb: '15px',
};

export const blockTitle = {
  display: 'flex',
  gap: '20px',
  fontSize: '24px'
};

export const price = {
  fontWeight: 700,
};

export const titleSx = {
  flexDirection: 'column',
};
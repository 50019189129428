import {
  Typography
} from '@mui/material';
import { StyledDelete, StyledPencil } from './styles';

export const columns = ({
  handleOpenDeleteModal,
  navigate
}) => ([
  {
    flex: 0.2,
    maxWidth: 100,
    field: 'id',
    headerName: 'ID',
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        #
        {' '}
        {row?.id ?? ''}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 250,
    field: 'aircraft_type_name',
    headerName: 'Type',
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
        {row?.aircraft_type_name ?? 'No Name'}
      </Typography>
    ),
  },
  {
    field: 'actions',
    maxWidth: 350,
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <StyledPencil onClick={() => {
          navigate(`/aircraft/types/${row.id}`, { replace: false });
        }}
        />
        {' '}
        <StyledDelete onClick={() => handleOpenDeleteModal(row?.id)} />
      </>
    ),
  },
]);
import { HeaderName, StyledPencil } from '../../../components/EditableTable/styles';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import React from 'react';
import {
  Chip, Tooltip, Typography, Box
} from '@mui/material';
import { StyledDelete } from '../../Company/CompanyTable/styles';
import { StyledDownload, StyledNotification, StyledPreview } from './styles';

export const columns = ({
  handleFilter,
  filters,
  handleDownloadReport,
  handleOpenDeleteModal,
  handleOpenReport,
  handleNotify
}) => ([
  {
    minWidth: 120,
    flex: 0.1,
    field: 'id',
    headerName: 'Report #',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Report #</Typography>
        <FiltrationInput
          defaultValue={filters.id || ''}
          handleFilter={handleFilter}
          placeholder="Report #"
          value="id"
        />
      </Box>
    ),
    renderCell: ({ row }) => `#${row.id}`
  },
  {
    minWidth: 120,
    flex: 0.1,
    field: 'report_type',
    headerName: 'Report Name',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Report Name</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (row.report_type === 'first_report' ? 'CLASSROOM TRAINING REPORT' : 'OPC FORM')
  },
  {
    flex: 0.1,
    field: 'sent_by',
    minWidth: 120,
    headerName: 'Created By',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Created By</Typography>
        <FiltrationInput
          defaultValue={filters.sent_by || ''}
          handleFilter={handleFilter}
          placeholder="Sent By"
          value="sent_by"
        />
      </Box>
    ),
    renderCell: ({ row }) => row.sent_by
  },
  {
    flex: 0.1,
    field: 'sent_at',
    minWidth: 120,
    headerName: 'Created At',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Created At</Typography>
      </Box>
    ),
    renderCell: ({ row }) => row.sent_at
  },
  {
    flex: 0.1,
    field: 'signatures',
    minWidth: 120,
    headerName: 'Signatures',
    sortable: false,
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Signatures</Typography>
      </Box>
    ),
    renderCell: ({ row }) => {
      const { signatures } = row;
      const totalSignatures = signatures?.length;
      const signedSignatures = signatures?.filter(signature => signature?.signed === 1)?.length;

      const tooltipContent = (
        <Box>
          {signatures.map((signature) => (
            <Box key={signature?.uuid} sx={{ margin: '5px' }}>
              <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: '600' }}>
                {signature?.crew_name}
                :
                {signature?.signed === 1 ? ` YES (${signature?.signed_at})` : ' NO'}
              </Typography>
            </Box>
          ))}
        </Box>
      );

      return (
        <Tooltip
          title={tooltipContent}
          arrow
        >
          <Chip
            label={`${signedSignatures} / ${totalSignatures}`}
            sx={{ cursor: 'default' }}
            color="primary"
          />
        </Tooltip>
      );
    }
  },
  {
    flex: 0.1,
    minWidth: 120,
    align: 'right',
    field: 'actions',
    headerName: '',
    sortable: false,
    renderCell: ({ row }) => (
      <Box>
        <StyledPencil onClick={() => handleOpenReport(row.id, row.report_type)} />
        {' '}
        <StyledNotification onClick={() => handleNotify(row.id)} />
        {' '}
        <StyledDownload onClick={() => handleDownloadReport(row.id)} />
        {' '}
        <StyledPreview onClick={() => handleDownloadReport(row.id, false)} />
        {' '}
        <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
      </Box>
    ),
  },
]);

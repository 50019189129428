import React from 'react';
import TypeCard from '../TypeCard';
import Grid from '@mui/material/Grid';
import { titleSx } from './styles';

const TotalHrs = ({
  isPanel, getValues,
}) => {
  const { current_block, current_air, current_ldg } = getValues();

  return (
    <TypeCard title="TOTAL HRS/LDGS" variant="yellow" isPanel={isPanel}>
      <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={12} md={4} sx={titleSx}>
          BLOCK:
          {' '}
          <b>{current_block}</b>
        </Grid>

        <Grid item xs={12} md={4} sx={titleSx}>
          AIR:
          {' '}
          <b>{current_air}</b>
        </Grid>

        <Grid item xs={12} md={4} sx={titleSx}>
          LDG:
          {' '}
          <b>{current_ldg}</b>
        </Grid>
      </Grid>
    </TypeCard>
  );
};
export default TotalHrs;

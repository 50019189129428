export const header = {
  color: '#616161',
  fontWeight: '600 !important',
  whiteSpace: 'nowrap',
  // minWidth: '125px',
  textTransform: 'capitalize !important',
};

export const body = {
  minWidth: '125px',
};

export const textarea = {
  padding: '8.5px 14px',
  borderRadius: '8px',
  color: 'rgba(76, 78, 100, 0.87)',
  border: '1px solid rgba(76, 78, 100, 0.22)',
  height: '39px',
  width: '100%',
  maxWidth: '100%',
};

export const deleteIcon = {
  cursor: 'pointer',
};

export const btn = {
  maxWidth: '150px',
  mt: 5
};

export const serviceContainer = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '1200px',
  gap: '35px',
  p: '15px',
  width: '100%',
};

export const additionalValuesContainer = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  flexWrap: 'nowrap',
};

export const controllerContainer = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '20px',
};

export const container = {
  display: 'flex',
  gap: '20px',
};
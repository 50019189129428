import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { defaultValues, schema } from './schema';
import { useMediaQuery } from '@mui/material';
import { useGetMntAircraftMutation, useUpdateMntAircraftMutation, useReadOdsMntAircraftMutation } from '../../../../store/session';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-hot-toast';

export const useMntAircraftForm = () => {
  const { id } = useParams();
  const update = id !== 'create';
  const maxWidth768px = useMediaQuery('(max-width:768px)');

  const [getAircraft, { data: aircraftData, isLoading: aircraftLoading }] = useGetMntAircraftMutation();
  const [updateAircraft, { isLoading: updateAircraftLoading }] = useUpdateMntAircraftMutation();
  const [readOds, { isLoading: aircraftOdsLoading }] = useReadOdsMntAircraftMutation();

  const updating = aircraftOdsLoading || updateAircraftLoading;

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    watch,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const setAircraft = async () => {
    const { data: result } = await getAircraft({ id });

    if (result) {
      Object.entries(result).forEach(([name, value]) => {
        setValue(name, value);
      });
    }
  };

  const handleReadOds = async () => {
    const toastId = toast.loading('Loading...');

    const res = await readOds(id);

    if (res.error) {
      toast.error(res.error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    Object.entries(res.data).forEach(([name, value]) => {
      setValue(name, value);
    });

    toast.success('Successfully Updated!', {
      id: toastId
    });
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const res = await updateAircraft({ id, data });

    if (res.error) {
      toast.error(res.error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    Object.entries(res.data).forEach(([name, value]) => {
      setValue(name, value);
    });

    toast.success('Successfully Updated!', {
      id: toastId
    });
  };

  useEffect(() => {
    if (update) {
      setAircraft();
    }
  }, [update]);

  return {
    onSubmit,
    aircraftData,
    aircraftLoading,
    handleSubmit,
    setValue,
    trigger,
    control,
    maxWidth768px,
    errors,
    dirtyFields,
    touchedFields,
    watch,
    getValues,
    updating,
    handleReadOds
  };
};

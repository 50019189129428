import { Box, Typography, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import moment from 'moment';
import React from 'react';
import ButtonSelect from '../../components/ButtonSelect';
import CrewTable from '../../components/CrewTable';
import { MONTH_FORMAT } from '../../components/CrewTable/constants';
import TableAccordion from '../../components/TableAccordion';
import {
  cardContainerSx, titleSx,
} from './styles';
import { useAtrCrewRoster } from './useAtrCrewRoster';

const AtrCrewRoster = () => {
  const {
    year,
    months,
    data,
    list,
    isLoading,
    rostersOptions,
    setYear,
    setMonths,
    handleUpdateDutyList,
    handleDelete,
  } = useAtrCrewRoster();

  const maxWidth450px = useMediaQuery('(max-width:450px)');

  return (

    <Card sx={cardContainerSx}>
      <Box sx={{ ...(maxWidth450px && titleSx), pb: 3 }}>
        <Typography variant="h3">Fleet Air eRoster [ ATR ]</Typography>
      </Box>

      <ButtonSelect
        year={year}
        months={months}
        setYear={setYear}
        setMonths={setMonths}
        options={rostersOptions}
        acftType="atr"
      />

      {!!data?.length && months.map(month => (
        <TableAccordion title={`${moment(`${month}-01-${year}`, 'MMM-DD-YYYY').format(MONTH_FORMAT)} ${year}`}>
          <CrewTable
            data={data}
            date={`${month}-01-${year}`}
            list={list}
            handleDelete={handleDelete}
            handleUpdateDutyList={handleUpdateDutyList}
            isLoading={isLoading}
          />
        </TableAccordion>
      ))}
    </Card>
  ); };

export default AtrCrewRoster;

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Card, Grid, Typography, useMediaQuery
} from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import LinkButton from '../../../../../components/LinkButton/Button';
import { DATE_FORMAT } from '../../../../../constans/formats';
import { OPTIONS_STATE } from '../../../../../constans/workOrder';
import AutoSizeTextarea from '../../../../../form/components/AutoSizeTextarea';
import DatePicker from '../../../../../form/components/DatePicker';
import FieldError from '../../../../../form/components/FieldError';
import FormGroup from '../../../../../form/components/FormGroup';
import Input from '../../../../../form/components/Input';
import Select from '../../../../../form/components/Select';
import ServerAutocomplete from '../../../../../form/components/ServerAutocomplete';
import { useGetCrewListQuery, useGetOdsAutocompleteQuery } from '../../../../../store/session';
import { usePermissions } from '../../../../../utils/hooks/usePermissions';
import { stringifyLocationSearch } from '../../../../../utils/locationSearch';
import { setDateValue, validateDatePickerValue } from '../../../../../utils/setDateValue';
import { cardContainerForm } from '../style';

const FormDescription = ({ workOrder, hasPermissionsWoEdit }) => {
  const {
    formState: { errors }, control, setValue
  } = useFormContext();

  const maxWidth768px = useMediaQuery('(max-width:768px)');
  const hasPermissionWriteOds = usePermissions('work_order_ods_write');
  const { data: crewList } = useGetCrewListQuery(stringifyLocationSearch({
    post: 3,
    crew_wo_can_close: 1,
    crew_selected_id: workOrder?.closed_by ?? ''
  }), {
    refetchOnMountOrArgChange: true
  });

  const optionCrewList = crewList?.map((item) => ({
    label: item.crew_name,
    value: item.id
  }));

  const renderClosedInfo = () => {
    if (workOrder?.state === 5) {
      return (
        <Box>
          Order closed at AC Hrs:
          {' '}
          {workOrder?.ac_hrs}
          {' '}
          - Order closed at AC Cyc:
          {' '}
          {workOrder?.ac_cyc}
        </Box>
      );
    }

    return null;
  };

  return (
    <Card sx={cardContainerForm}>
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1, maxWidth: '102% !important' }}>
        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="Name" required hasError={!!errors.name}>
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Name"
                  {...field}
                />
              )}
              control={control}
              name="name"
            />
            <FieldError error={errors.name} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="WO Date">
            <Controller
              render={({ field } ) => (
                <DatePicker
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-dd"
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 'wo_date', DATE_FORMAT);
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
              control={control}
              name="wo_date"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="Designation" required hasError={!!errors.designation}>
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Designation"
                  {...field}
                />
              )}
              control={control}
              name="designation"
            />
            <FieldError error={errors.designation} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="State">
            <Controller
              render={({ field } ) => (
                <Select
                  options={OPTIONS_STATE}
                  placeholder="State"
                  {...field}
                  disabled={!hasPermissionsWoEdit}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  value={+field.value}
                />
              )}
              control={control}
              name="state"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup
            label={(workOrder?.ods_id && workOrder?.state?.toString() !== '5') ? (
              <Typography sx={{
                display: 'flex',
                alignItems: 'center'
              }}
              >
                ODS
                <LinkButton style={{ textDecoration: 'underline', marginLeft: '5px' }} path={`/directories/ods/update/${workOrder?.ods_id}`}>
                  <OpenInNewIcon />
                </LinkButton>
              </Typography>
            ) : 'ODS'}
            hasError={!!errors.ods}
          >
            {((workOrder?.ods_id && workOrder?.state?.toString() !== '5' && hasPermissionWriteOds) || (!workOrder?.ods_id && workOrder?.state?.toString() !== '5' && hasPermissionWriteOds)) ? (
              <Controller
                control={control}
                name="ods"
                render={({ field }) => (
                  <ServerAutocomplete
                    field={field}
                    searchQueryFn={useGetOdsAutocompleteQuery}
                    disabled={!workOrder?.aircraft_id}
                    queryParams={{ aircraft_id: workOrder?.aircraft_id }}
                    value={field?.value?.value ? field?.value : { label: field?.value?.toString(), value: field?.value }}
                    size="small"
                    placeholder="Select ODS"
                    handleSelect={(value) => {
                      field.onChange(value?.value || value);
                    }}
                  />
                )}
              />
            ) : (workOrder?.ods_id && (workOrder?.state?.toString() === '5' || !hasPermissionWriteOds)) ? (
              <LinkButton style={{ textDecoration: 'underline', }} path={`/directories/ods/update/${workOrder?.ods_id}`}>
                {workOrder?.ods}
              </LinkButton>
            ) : '-'}
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="Closed By" required hasError={!!errors.closed_by}>
            <Controller
              render={({ field } ) => (
                <Select
                  options={optionCrewList}
                  disabled={!hasPermissionsWoEdit}
                  placeholder="Closed By"
                  {...field}
                />
              )}
              control={control}
              name="closed_by"
            />
            <FieldError error={errors.closed_by} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="ODS Action">
            <Controller
              render={({ field } ) => (
                <AutoSizeTextarea
                  placeholder="ODS Action"
                  {...field}
                />
              )}
              control={control}
              name="action_text"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="WO Closed Date" required hasError={!!errors.due_date}>
            <Controller
              render={({ field } ) => (
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  placeholderText="YYYY-MM-DD HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm"
                  showMonthDropdown
                  showYearDropdown
                  disabled={!hasPermissionsWoEdit}
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 'due_date', 'YYYY-MM-DD HH:mm');
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
              control={control}
              name="due_date"
            />
            {renderClosedInfo()}
            <FieldError error={errors.due_date} />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1, maxWidth: '102% !important' }}>
        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="Due Date">
            <Controller
              render={({ field } ) => (
                <DatePicker
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-dd"
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 'deadline', DATE_FORMAT);
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
              control={control}
              name="deadline"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="Due FH" hasError={!!errors.due_fh}>
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Due FH"
                  {...field}
                />
              )}
              control={control}
              name="due_fh"
            />
            <FieldError error={errors.due_fh} />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="Due FC" hasError={!!errors.due_fc}>
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Due FC"
                  type="number"
                  {...field}
                />
              )}
              control={control}
              name="due_fc"
            />
            <FieldError error={errors.due_fc} />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1, maxWidth: '102% !important' }}>
        <Grid item xs={maxWidth768px ? 12 : 2}>
          <FormGroup label="Man Hour Estimate">
            <Controller
              render={({ field } ) => (
                <Input
                  disabled
                  {...field}
                  variant="standard"
                  helperText="Approximate completion time in hours"
                />
              )}
              control={control}
              name="man_hour_estimate"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 2}>
          <FormGroup label="Man Hour Real Job">
            <Controller
              render={({ field } ) => (
                <Input
                  disabled
                  {...field}
                  variant="standard"
                />
              )}
              control={control}
              name="man_hour_real_job"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Card>
  );
};

export default FormDescription;

export const formContainer = {
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',
};

export const headerSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
};

export const backIcon = {
  cursor: 'pointer',
};

export const cardContainer = {
  p: '30px',
  mt: '30px',
  display: 'flex',
  gap: '15px',
};

export const saveBtn = {
  color: '#fff',
  width: '100%',
};

export const updateBtn = {
  backgroundColor: '#36918b',
  width: '100%',
  maxWidth: '100%',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#4cafa8 !important',
  }
};

export const cancelBtn = {
  backgroundColor: '#e9edef',
  color: '#34495e',
  width: '100%',
  '&:hover': {
    backgroundColor: '#c3cfd3'
  }
};

export const buttonBlock = {
  mt: '30px',
};

export const cardContainerForm = {
  p: '20px',
  mt: '30px',
};
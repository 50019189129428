import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Modal from '../../../../components/Modal';
import Input from '../../../../form/components/Input';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '../../../../form/components/FormGroup';
import FieldError from '../../../../form/components/FieldError';
import { Controller, useForm } from 'react-hook-form';
import { defaultValues, schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSendLogBookMutation } from '../../../../store/session';
import { useParams } from 'react-router-dom';
import {
  footerSx, headerSx, cancelButton, sendBtn, stuckSx
} from './styles';
import { toast } from 'react-hot-toast';

export const SendToModal = ({
  onClose, open, email: initEmail, months, year
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    watch,
    setValue,
    handleSubmit,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const [send] = useSendLogBookMutation();
  const params = useParams();

  const onSubmit = async () => {
    const emails = getValues('emails');

    if (!emails.length) {
      toast.error('There are no emails to send');

      return;
    }

    const toastId = toast.loading('Loading...');

    const res = await send({ id: params.id, data: { emails, months: months[0], year } });

    if (res.error) {
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    toast.success('Messages was sended!', {
      id: toastId,
    });

    reset({ email: '', emails: [initEmail] });

    onClose();
  };

  const handleAdd = () => {
    const { email, emails } = getValues();
    if (emails.includes(email)) {
      toast.error(`The email ${email} already exist`);
    } else if (email) {
      setValue('emails', [...emails, email]);
      setValue('email', '');
    }
  };

  const handleDelete = (email) => {
    const emails = getValues('emails');
    setValue('emails', emails.filter(item => item !== email));
  };

  const handleClose = () => {
    reset({ email: '', emails: [initEmail] });
    onClose();
  };

  useEffect(() => {
    if (initEmail) {
      setValue('emails', [initEmail]);
    }
  }, [initEmail]);

  return (
    <Modal onClose={handleClose} open={open}>
      <Box>
        <Box sx={headerSx}>
          <Box>LOOGBOOK MAIL SENDER</Box>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Box>
        <Grid container spacing={5}>
          <Grid item lg={10}>
            <FormGroup label="Email" required hasError={!!errors.caa_name}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.email} />
            </FormGroup>
          </Grid>

          <Grid item lg={2}>
            <Button variant="contained" sx={sendBtn} onClick={handleAdd}>Add</Button>
          </Grid>
        </Grid>

        <Box sx={stuckSx}>
          {watch('emails').map((email) => (
            <Chip label={email} onDelete={() => handleDelete(email)} />
          ))}
        </Box>

        <Box sx={footerSx}>
          <Button onClick={handleClose} sx={cancelButton}>Cancel</Button>
          <Button onClick={handleSubmit(onSubmit)} variant="contained">Send</Button>
        </Box>
      </Box>
    </Modal>
  ); };

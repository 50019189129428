import {
  Box,
  Table,
  TableBody,
  TableCell, TableContainer, TableHead,
  TableRow
} from '@mui/material'
import React from 'react'
import Button from '../../../../../../../components/Button'
import { SLOT } from '../../../../../../../constans/constants'
import AutoSizeTextarea from '../../../../../../../form/components/AutoSizeTextarea'
import CurrencyInput from '../../../../../../../form/components/CurrencyInput'
import { GreenButton, RedButton } from '../../../../../../Logistics/style'
import { columns } from './constants'
import {
  blockTextarea,
  body,
  cellWrapper,
  header,
  input,
} from './style'
import { useServicesForm } from './useServicesForm'

const ServicesForm = (props) => {
  const { handleOpenDrawer } = props;
  const {
    suppliers,
    airportList,
    services,
    fields,
    control,
    Controller
  } = useServicesForm();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <TableCell sx={header}>{item.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields?.map((service, i) => {
            const airport = airportList.find(element => element.id === service.airport_id);
            const list = [];

            const supplierServices = service.services
              .filter((item) => item.pl_serv_supplier !== SLOT)
              .map(i => i.pl_serv_service)
              .filter(Boolean);

            service.services.forEach(item => {
              if (!list.map(i => i.pl_serv_supplier).includes(item.pl_serv_supplier)
                && item.pl_serv_supplier !== SLOT
                && item.pl_serv_service
              ) {
                list.push(item);
              }
            });

            return (
              <TableRow key={service.airport_id}>
                <TableCell sx={body}>{`${airport.airport_iata}/${airport.airport_icao} (${airport?.airport_name})`}</TableCell>
                <TableCell sx={body}>
                  <Box sx={cellWrapper}>
                    {list.map((item) => {
                      const serviceName = supplierServices.map(i => services?.services?.[item.pl_serv_airport]?.[i]).join(',');

                      return (
                        <Box>{serviceName}</Box>
                      );
                    })}
                  </Box>
                </TableCell>
                <TableCell sx={body}>
                  <Box sx={cellWrapper}>
                    {list.map((item) => {
                      const supplier = suppliers[item.pl_serv_airport]?.[item.pl_serv_service]?.[item.pl_serv_supplier];

                      return (
                        <Box>{supplier}</Box>
                      );
                    })}
                  </Box>
                </TableCell>

                <TableCell sx={{ ...body, ...blockTextarea }}>
                  <Box sx={cellWrapper}>
                    {list.map((item, index) => (
                      <Controller
                        name={`planing_services.${i}.services.${index}.pl_serv_description`}
                        control={control}
                        render={({ field }) => (
                          <AutoSizeTextarea
                            placeholder="Description"
                            {...field}
                          />
                        )}
                      />
                    ))}
                  </Box>
                </TableCell>

                <TableCell sx={body}>
                  <Box sx={cellWrapper}>
                    {list.map((item, index) => (
                      <Controller
                        name={`planing_services.${i}.services.${index}.pl_serv_real_price`}
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            placeholder="Price"
                            sx={input}
                            {...field}
                          />
                        )}
                      />
                    ))}
                  </Box>
                </TableCell>
                <TableCell sx={body}>
                  <Box sx={cellWrapper}>
                    {list.map((item, index) => (
                      <Button 
                        key={item?.id} 
                        title="Invoice" 
                        sx={{ alignSelf: 'flex-start', backgroundColor: item?.invoices_count ? GreenButton : RedButton, fontSize: '.6rem' }} 
                        onClick={() => handleOpenDrawer(true, item)}
                      />
                    ))}
                  </Box>
                </TableCell>
              </TableRow>
            ); })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ServicesForm;

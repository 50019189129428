import { createSlice } from '@reduxjs/toolkit';
import { useProvideTags } from '../../../utils/hooks/useProvideTags';
import { sessionApi } from '../../session';

export const trainingReports = createSlice({
  name: 'training_module_reports',
  initialState: {},
});

sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportsTable: builder.query({
      query: (data) => `/training_module/training_report${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    previewReport: builder.mutation({
      query: (data) => ({
        url: '/training_module/training_report/preview',
        method: 'PUT',
        responseHandler: async (response) => ((response.ok) ? response.blob() : response.json()),        
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error)
    }),
    downloadReport: builder.mutation({
      query: ({ id }) => ({
        url: `/training_module/training_report/download/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error)
    }),
    sendReport: builder.mutation({
      query: (data) => ({
        url: '/training_module/training_report',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateReport: builder.mutation({
      query: ({ id, data }) => ({
        url: `/training_module/training_report/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInstructorsList: builder.query({
      query: () => '/training_module/training_report/list/instructors',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getLastReportData: builder.query({
      query: (data) => `/training_module/training_report/last_report${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getReportData: builder.query({
      query: (id) => `/training_module/training_report/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getSignsTable: builder.query({
      query: (data) => `/training_module/training_report/signs${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['SIGNATURES_TABLE']),
    }),
    signReport: builder.mutation({
      query: (data) => ({
        url: '/training_module/training_report/sign',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['SIGNATURES_TABLE'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    notifyUsers: builder.mutation({
      query: (id) => ({
        url: `/training_module/training_report/notify_users/${id}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteReport: builder.mutation({
      query: (id) => ({
        url: `/training_module/training_report/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
  }),
});

export const {
  useGetReportsTableQuery,
  usePreviewReportMutation,
  useSendReportMutation,
  useDownloadReportMutation,
  useGetInstructorsListQuery,
  useGetLastReportDataQuery,
  useGetSignsTableQuery,
  useSignReportMutation,
  useDeleteReportMutation,
  useGetReportDataQuery,
  useUpdateReportMutation,
  useNotifyUsersMutation
} = sessionApi;

export default trainingReports.reducer;

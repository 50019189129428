import React from 'react';
import {
  Box
} from '@mui/material';
import { BlockSelect } from './styles';
import DatePicker from '../../../form/components/DatePicker';
import moment from 'moment';

const SelectDate = ({ value, onChange, ...props }) => (
  <Box
    sx={BlockSelect}
  >
    <DatePicker
      placeholderText="yyyy-MM-dd"
      dateFormat="yyyy-MM-dd"
      timeFormat="HH:mm"
      showMonthDropdown
      showYearDropdown
      value={moment(value).toDate()}
      onChange={onChange}
      {...props}
    />
  </Box>
);

export default SelectDate;

import {
  array,
  object, string
} from 'yup';

export const defaultValues = {
  action: '',
  ata_id: '',
  defect_id: '',
  note: '',
  performed: '',
  performed_crew_id: '',
  re_performed_crew_id: '',
  re_performed_date: '',
  upload_files: [],
  permission: false,
};

export const schema = object().shape({
  action: string()
    .required('${label} cannot be blank.')
    .label('ACTION'),
  upload_files: array()
    .label('Files')
});

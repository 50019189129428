export const cardType = {
  position: 'absolute',
  top: '15px',
  left: '-2px',
  padding: '7px 14px',
  zIndex: '2',
};

export const cardContainer = {
  background: '#e9edef',
  position: 'relative',
  p: '25px',
  borderRadius: '8px',
};

export const rowSX = {
  mt: '45px',
  mb: '-10px',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
};

export const inputContainer = {
  mt: '30px',
  display: 'grid',
  gridTemplateColumns: 'calc(50% - 20px) calc(50% - 20px)',
  gap: '40px',
};

export const inputSx = {
  input: {
    color: '#000 !important'
  }
};

export const rowXs = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
};

export const inputContainerXs = {
  mt: '25px',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px'
};

export const link = {
  width: 'calc(50% - 15px)',
  overflowWrap: 'break-word',
  cursor: 'pointer',
};

export const uploadButton = {
  width: 'calc(50% - 15px)',
  display: 'flex',
  justifyContent: 'end',
};

export const uploadButtonSx = {
  width: '100%',
  justifyContent: 'start',
};

export const linkSx = {
  width: '100%',
};

export const colorLink = {
  color: 'rgba(0, 0, 328)',
};

export const cardLoader = {
  opacity: '0.5',
  width: '100%',
  height: '100%',
};

export const loaderIcon = {
  position: 'absolute',
  top: '40%',
  left: '50%'
};

import React from 'react';
import Box from '@mui/material/Box';
import { cardContainer, typeSx } from './styles';

const types = {
  sky: {
    backgroundColor: '#659be0',
    color: '#fff',
  },
  blue: {
    backgroundColor: '#36c6d3',
    color: '#fff',
  },
  red: {
    backgroundColor: '#ed6b75',
    color: '#fff',
  },
  yellow: {
    backgroundColor: '#F1C40F',
    color: '#010100',
  },
  grey: {
    backgroundColor: '#bac3d0',
    color: '#010100',
  },
};

const TypeCard = ({
  title, variant, children, isPanel, sx
}) => (
  <Box sx={cardContainer(isPanel)}>
    <Box sx={{ ...typeSx, ...types[variant], sx }}>
      {title}
    </Box>

    {children}
  </Box>
);

export default TypeCard;

import React from 'react';
import {
  Card,
  CardContent,
  TextField,
} from '@mui/material';
import { CustomForm } from './styles';

const FlightNotesForm = (props) => {
  const {
    control, Controller,
  } = props;

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <CustomForm fullWidth>
          <Controller
            name="planing_notes"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                rows={4}
                multiline
                variant="filled"
                label="Notes"
                id="textarea-filled-static"
              />
            )}
          />
        </CustomForm>
      </CardContent>
    </Card>
  );
};

export default FlightNotesForm;

import { Box, Card, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import EditableTable from '../../components/EditableTable';
import LinkButton from '../../components/LinkButton';
import {
  useDeleteCrewMutation, useGetAircraftListQuery, useGetCrewQuery, useUpdateCrewMutation
} from '../../store/session';
import { columns } from './columns';
import { ButtonStyle, TitleContainer } from './styles';

const Crew = () => {
  const { data: AircraftList } = useGetAircraftListQuery();

  const aircraftOptions = AircraftList?.map((item) => ({
    value: item.id,
    label: item.aircraft_registration
  }));

  useEffect(() => {
    if (aircraftOptions) {
      aircraftOptions?.push({ value: '', label: '-' });
    }
  }, [aircraftOptions]);

  return (
    <Card sx={{ padding: '1rem' }}>
      <Box
        sx={{ ...TitleContainer, pb: 3 }}
      >
        <Typography variant="h3">Crew</Typography>
        
        <LinkButton style={ButtonStyle} path="/directories/crew/create">Add</LinkButton>
      </Box>

      <EditableTable
        useHook={useGetCrewQuery}
        useHookUpdate={useUpdateCrewMutation}
        useHookDelete={useDeleteCrewMutation}
        columns={columns}
        sortByType="DESC"
        sortByActive="crew_active"
        aircraftOptions={aircraftOptions}
      />
    </Card>
  );
};

export default Crew;

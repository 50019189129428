export const totalColumns = [
  {
    field: 'single_pilot_se'
  },
  {
    field: 'single_pilot_me'
  },
  {
    field: 'multi_pilot'
  },
  {
    field: 'total_flight_time'
  },
  {
    field: 'copilot_name'
  },
  {
    field: 'takeoff_day'
  },
  {
    field: 'takeoff_night'
  },
  {
    field: 'landings_day'
  },
  {
    field: 'landings_night'
  },
  {
    field: 'condition_time_night'
  },
  {
    field: 'condition_time_ifr'
  },
  {
    field: 'pilot_time_in_command_pl'
  },
  {
    field: 'pilot_time_in_command_pl_us'
  },
  {
    field: 'pilot_time_co_pilot'
  },
  {
    field: 'pilot_time_dual'
  },
  {
    field: 'pilot_time_instructor'
  },
  {
    field: 'training_session_date'
  },
  {
    field: 'training_session_type'
  },
  {
    field: 'training_session_total_time'
  },
];

export const columns = [
  {
    field: 'date'
  },
  {
    field: 'departure_place'
  },
  {
    field: 'departure_time'
  },
  {
    field: 'arrival_place'
  },
  {
    field: 'arrival_time'
  },
  {
    field: 'aircraft_type'
  },
  {
    field: 'aircraft_registration'
  },
  ...totalColumns,
];

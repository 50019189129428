
export const HeaderName = {
  fontSize: ' 0.75rem',
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontWeight: '500',
  letterSpacing: '0.17px',
};

export const Maintenance = {
  width: '120px'
};

export const TableStyle = {
  '& .MuiDataGrid-columnHeaders': {
    minHeight: '80px !important',
  },
  '& .MuiDataGrid-iconButtonContainer': {
    width: '30px',
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0px !important',
    height: 'calc(100% - 80px) important'
  },
  '& .MuiDataGrid-columnHeader': {
    display: 'flex !important',
    flexDirection: 'column !important',
    height: 'fit-content !important',
  },
};

export const ContainerSinatures = {
  p: '25px',
  pt: 0
};

export const ContainerSx = {
  paddingTop: '30px',
};

export const ContainerSmall = {
  p: '20px 0',
  paddingTop: '20px',
};

import { mixed, object, string } from 'yup';

export const defaultValues = {
  bank_id: '',
  bank_account_number_id: '',
  file: ''
};

export const schema = object().shape({
  bank_id: string()
    .required('${label} cannot be blank.')
    .label('Bank'),

  bank_account_number_id: string()
    .required('${label} cannot be blank.')
    .label('Bank Account Number'),

  file: mixed()
    .test('isEmpty', 'File cannot be blank', (value) => !!value )
    .required('${label} cannot be blank.')
    .label('Upload File'),
});

import React, { useState } from 'react';
import {
  Box, Card, Divider, Typography
} from '@mui/material';
import EditableTable from '../../../components/EditableTable';
import {
  useGetDailyEmailListQuery,
  useDeleteDailyEmailMutation,
  useUpdateDailyEmailMutation,
} from '../../../store/session';
import { columns } from './columns';
import Button from '../../../components/Button';
import CreateMail from './CreateMail/CreateMail';
import LinkButton from '../../../components/LinkButton';

const Emails = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  return (
    <>
      <LinkButton path="/daily_availability">
        <Button title="Back"></Button>
      </LinkButton>
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3
          }}
        >
          <Typography variant="h3">Emails</Typography>
          <Button onClick={handleOpen} title="Create" />
        </Box>

        <Divider />
        <EditableTable
          useHook={useGetDailyEmailListQuery}
          useHookDelete={useDeleteDailyEmailMutation}
          useHookUpdate={useUpdateDailyEmailMutation}
          columns={columns}
          sortByType="ASC"
          sortByActive="email"
          getRowHeight={() => 'auto'}
          getRowId={(row) => row.id}
          fixedCacheKey="email-table"
        />

        <CreateMail
          onClose={handleClose}
          open={openDrawer}
        />

      </Card>
    </>
  );
};

export default Emails;

export const titleSx = {
  fontFamily: '"Open Sans",sans-serif',
  fontSize: '14px',
  mb: '10px',
  color: '#000',
  textDecoration: 'none',
};

export const subTitleSx = {
  fontSize: '11px',
  fontWeight: 'bold',
  mb: '10px',
  color: '#34495e'
};

export const textSx = {
  fontSize: '11px',
  mb: '5px',
  color: '#34495e'
};

import {
  Card, CardContent, Divider, Typography,
  useMediaQuery
} from '@mui/material';
import React, { useState } from 'react';
import ButtonSelect from '../../components/ButtonSelect';
import Map from '../../components/GoogleMap';
import ContentPageLoader from '../../components/Loader/ContentPageLoader';
import { useGetLoadedAirportsQuery } from '../../store/session';
import { TextWrap } from '../Logistics/style';
import { MONTH_SHORT } from '../MainDeckSchedule/constants';
import { DEFAULT_MONTH, DEFAULT_YEAR } from '../SabCrewRoster/constants';

const FlightMap = () => {
  const [year, setYear] = useState(DEFAULT_YEAR);
  const [months, setMonths] = useState(DEFAULT_MONTH);
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const numberMonths = MONTH_SHORT.reduce((total, amount, index) => {
    const monthIndex = [...total, months.includes(amount) && index + 1];

    return monthIndex.filter((e) => e);
  }, []);

  const { data: loadedAirports, isLoading: airportsLoading } = useGetLoadedAirportsQuery({ year, months: numberMonths });

  if (airportsLoading) {
    return <ContentPageLoader />;
  }

  return (
    <Card sx={{ padding: '1rem' }}>
      <Typography
        variant="h3"
        noWrap
        sx={{
          textTransform: 'capitalize',
          display: 'flex',
          mb: 2,
          ...(maxWidth800px && { ...TextWrap, textAlign: 'center' })
        }}
      >
        Airports with Loadings
      </Typography>
      <Typography
        variant="subtitle2"
        noWrap
        sx={{
          textTransform: 'capitalize',
          display: 'flex',
          mb: 2,
          'fontWeight': 600,
          ...(maxWidth800px && { ...TextWrap, textAlign: 'center' })
        }}
      >
        All numbers presented on the map represent the count of loadings.
      </Typography>
      <Divider sx={{ mb: 5 }} />
      <ButtonSelect
        year={year}
        months={months}
        setYear={setYear}
        setMonths={setMonths}
        disabledSendRoster={false}
      />
      <Divider />
      <CardContent>
        <Map data={loadedAirports} />
      </CardContent>
    </Card>
  );
};

export default FlightMap;

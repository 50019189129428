import React from 'react';
import { Grid } from '@mui/material';
import AircraftProfileButton from './components/AircraftProfileButton';
import Table from './list/Table';

const AircraftProfiles = () => {
  const title = 'Aircrafts | Profiles';

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <AircraftProfileButton title="Add profile" />
        <Table title={title} />
      </Grid>
    </Grid>
  );
};

export default AircraftProfiles;

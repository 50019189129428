import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import {
  HeaderName,
  StyledDelete,
  StyledPencil,
} from '../../../components/EditableTable/styles';
import LinkButton from '../../../components/LinkButton';
import { ORDER_STATUSES } from '../../../constans/logistics';
import { PartsStorageFilter } from './constants';

export const formatOrderId = (row) => {
  let result = null;
  const count = row?.orders_history?.length;
  if (count) {
    const firstItem = row.orders_history[0];
    result = firstItem.type_order === 'internal' ? `${firstItem.order_id}D` : firstItem.order_id;
    if (count > 1) {
      const lastItem = row.orders_history[count - 1];
      if (lastItem.type_order === 'external') {
        result = firstItem.type_order === 'internal' ? `${lastItem.order_id}/${firstItem.order_id}D` : firstItem.order_id;
      }
    }
  }

  return result || 'N/A';
};

const formatOrderEstimatedDate = (row) => {
  if (row?.orders_history?.length) {
    const item = row.orders_history[0];
    if (item.type_order === 'internal' && item?.internal_order?.prices?.length) {
      return item.internal_order.prices[0].estimated_date;
    }
  }

  return 'N/A';
};

const formatAircraftReservationHint = (row) => {
  let result = '';
  for (const key in row.orders_history) {
    if (key === '0') {
      continue;
    }
    const reg = row.orders_history[key]?.order?.aircraft?.aircraft_registration;
    if (reg) {
      result += `${reg};`;
    }
  }

  return result || 'N/A';
};

export const columns = ({
  handleOpenDeleteModal,
  handleFilter,
  filters,
  aircraftOptions,
  partType,
  permissions,
  internalPermissions,
  externalStorageMaster
}) => {
  const cols = [
    {
      flex: 0.2,
      field: 'id',
      minWidth: 120,
      headerName: 'Part ID',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            fontWeight: '650',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Part ID</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.id || ''}
            value="id"
            placeholder="Part ID"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="h6"
          noWrap
          sx={{ textTransform: 'capitalize', fontWeight: '600' }}
        >
          {`#${row.id}`}
        </Typography>
      ),
    },
    {
      flex: 0.2,
      field: 'order_id',
      minWidth: 150,
      headerName: 'Order ID',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            fontWeight: '650',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Order ID</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.order_id || ''}
            value="order_id"
            placeholder="Order ID"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="h6"
          noWrap
          sx={{ textTransform: 'capitalize', fontWeight: '600' }}
        >
          {formatOrderId(row)}
        </Typography>
      ),
    },
    {
      flex: 0.3,
      field: 'aircraft',
      minWidth: 200,
      headerName: 'Aircraft Reservation',
      sortable: false,
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            fontWeight: '650',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Aircraft Reservation</Typography>
          <FiltrationSelect
            options={aircraftOptions}
            defaultValue={filters.aircraft_id || ''}
            handleFilter={handleFilter}
            value="aircraft_id"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Tooltip
          placement="top-end"
          variant="soft"
          title={(
            <Box>
              <Typography sx={{ color: '#fff' }}>
                {(Array.isArray(row?.orders_history) && row.orders_history.length) ? (
                  <Box>
                    <Typography sx={{ color: '#fff' }} variant="h6">Aircraft History:</Typography>
                    {row.orders_history.map(({ order, type_order }) => {
                      const reg = order?.aircraft?.aircraft_registration ?? 'N/A';
                      const orderId = order?.id ?? '';

                      return (
                        <Box sx={{
                          display: 'flex',
                          columnGap: '2rem',
                          color: '#fff',
                          alignItems: 'center'
                        }}
                        >
                          <Typography sx={{
                            color: '#fff',
                            display: 'grid',
                            gridTemplateColumns: '8px 3rem 2rem 3rem',
                            gap: '.5rem',
                            alignItems: 'center',
                            textWrap: 'nowrap'
                          }}
                          >
                            <CircleIcon sx={{ fontSize: '8px', mr: 3 }} />
                            <Typography sx={{ color: '#fff', }}>
                              {orderId}
                              {type_order === 'internal' ? 'D' : ''}
                              {' '}
                              -
                              {' '}
                              {reg}
                            </Typography>
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                ) : null}
              </Typography>
            </Box>
          )}
        >
          <Chip label={row?.orders_history?.[0]?.order?.aircraft?.aircraft_registration ?? 'N/A'} color="secondary" variant="outlined" />
        </Tooltip>
      ),
    },
    {
      flex: 0.3,
      field: 'part_number',
      minWidth: 150,
      headerName: 'Part Number',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            fontWeight: '650',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Part Number</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.part_number || ''}
            value="part_number"
            placeholder="Part Number"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="h6"
          noWrap
          sx={{
            textTransform: 'capitalize',
            whiteSpace: 'break-spaces',
            wordBreak: 'break-all'
          }}
        >
          {row.part_number}
        </Typography>
      ),
    },
    {
      flex: 0.3,
      field: 'part_name',
      minWidth: 150,
      headerName: 'Part Name',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            fontWeight: '650',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Part Name</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.part_name || ''}
            value="part_name"
            placeholder="Part Name"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="h6"
          noWrap
          sx={{
            textTransform: 'capitalize',
            whiteSpace: 'break-spaces',
            wordBreak: 'break-all'
          }}
        >
          {row.part_name ?? 'N/A'}
        </Typography>
      ),
    },
    {
      flex: 0.3,
      field: 'serial_number',
      minWidth: 150,
      headerName: 'Serial Number',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            fontWeight: '650',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Serial Number</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.serial_number || ''}
            value="serial_number"
            placeholder="Serial Number"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="h6"
          noWrap
          sx={{
            textTransform: 'capitalize',
            whiteSpace: 'break-spaces',
            wordBreak: 'break-all'
          }}
        >
          {(row?.serial_number === 'N/A') ? <Chip label="N/A" color="secondary" variant="outlined" /> : (row.serial_number ?? <Chip label="N/A" color="secondary" variant="outlined" />)}
        </Typography>
      ),
    },
    {
      flex: 0.2,
      field: 'storage',
      minWidth: 150,
      headerName: 'Storage',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            fontWeight: '650',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Storage</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.storage || ''}
            value="storage"
            placeholder="Storage"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="h6"
          noWrap
          sx={{ textTransform: 'capitalize' }}
        >
          {row.storage}
        </Typography>
      ),
    },
    {
      flex: 0.3,
      field: 'storage_zone',
      minWidth: 150,
      headerName: 'Storage Zone',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Storage Zone</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.storage_zone || ''}
            value="storage_zone"
            placeholder="Storage Zone"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="h6"
          noWrap
          sx={{
            textTransform: 'capitalize',
            whiteSpace: 'break-spaces',
            wordBreak: 'break-all'
          }}
        >
          {row.storage_zone ?? 'N/A'}
        </Typography>
      ),
    },
    {
      flex: 0.3,
      field: 'storage_number',
      minWidth: 180,
      headerName: 'Storage Number',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Storage Number</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.storage_number || ''}
            value="storage_number"
            placeholder="Storage Number"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="h6"
          noWrap
          sx={{
            textTransform: 'capitalize',
            whiteSpace: 'break-spaces',
            wordBreak: 'break-all'
          }}
        >
          {row.storage_number}
        </Typography>
      ),
    },
    {
      flex: 0.25,
      field: 'delivery_status',
      minWidth: 180,
      headerName: 'Delivery Status',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Delivery Status</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
          {
            (row?.delivery_status !== null) ? ORDER_STATUSES.find(({ value }) => +value === row?.delivery_status)?.icon : <Chip label="N/A" color="secondary" variant="outlined" />
          }
        </Typography>
      ),
    },
    {
      flex: 0.3,
      field: 'estimated_date',
      minWidth: 180,
      headerName: 'Estimated Date',
      sortable: false,
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Estimated Date</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="h6"
          noWrap
          sx={{ textTransform: 'capitalize' }}
        >
          {formatOrderEstimatedDate(row)}
        </Typography>
      ),
    },
    {
      flex: 0.3,
      field: 'exchange_due_date',
      minWidth: 180,
      headerName: 'Exchange Due Date',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Exchange Due Date</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
          <Chip label={row?.exchange_due_date ?? 'N/A'} color="secondary" variant="outlined" />
        </Typography>
      ),
    },
    {
      flex: 0.2,
      field: 'part_count',
      minWidth: 150,
      headerName: 'Quantity',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            fontWeight: '650',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Quantity</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.part_count || ''}
            value="part_count"
            placeholder="Quantity"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="h6"
          noWrap
        >
          <Chip label={`${row?.part_count} ${row?.unit ?? ''}`} color="secondary" variant="outlined" />
        </Typography>
      ),
    },
    {
      flex: 0.3,
      field: 'part_state',
      minWidth: 150,
      headerName: 'Part State',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            fontWeight: '650',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Part State</Typography>
          <FiltrationSelect
            options={PartsStorageFilter}
            defaultValue={filters.part_state || ''}
            handleFilter={handleFilter}
            value="part_state"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="h6"
          noWrap
          sx={{ textTransform: 'capitalize' }}
        >
          {PartsStorageFilter.find(e => e.value === row.part_state)?.label}
        </Typography>
      ),
    },

  ];
  if (partType === 'instrument') {
    cols.push({
      width: 180,
      field: 'due_date',
      headerName: 'Due date',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            fontWeight: '650',
            width: '100%'
          }}
        >
          <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Due date</Typography>
          <FiltrationDate
            value={filters.due_date || null}
            onChange={(e) => {
              handleFilter(e, 'due_date', true);
            }}
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="h6"
          noWrap
          sx={{
            textTransform: 'capitalize',
            whiteSpace: 'break-spaces',
            wordBreak: 'break-all'
          }}
        >
          {row.due_date}
        </Typography>
      )
    });
  }
  cols.push({
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <LinkButton path={`/logistics/orders-storage/${row.id}`}>
          <StyledPencil />
        </LinkButton>
        {'  '}
        <StyledDelete onClick={() => {
          if (permissions || internalPermissions || externalStorageMaster) handleOpenDeleteModal(row.id);
        }}
        />
      </>
    ),
  });

  return cols;
};

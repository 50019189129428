import React from 'react';
import {
  Box, Chip, Typography
} from '@mui/material';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import { HeaderName } from '../../../components/EditableTable/styles';
import LinkButton from '../../../components/LinkButton/Button';
import { OPTIONS_PLANING_STATUS } from '../../../constans/crew';
import {
  headerCellContainer, StyledCopy, StyledDelete, StyledPencil
} from './styles';
import { Link } from 'react-router-dom';

export const columns = ({
  handleFilter,
  filters,
  aircraftOptions,
  handleCopyFlights,
  handleOpenDeleteModal
}) => ([
  {
    flex: 0.2,
    minWidth: 120,
    field: 'planing_index',
    headerName: 'Planning',
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography style={HeaderName}>Planning</Typography>
        <FiltrationInput
          type="number"
          handleFilter={handleFilter}
          defaultValue={filters.planing_index || ''}
          value="planing_index"
          placeholder="Planning"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        #
        {' '}
        {row?.planing_index ?? ''}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 250,
    field: 'company_name',
    headerName: 'Company',
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography style={HeaderName}>Company</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.company_name || ''}
          value="company_name"
          placeholder="Company"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
      >
        {row?.company_name ?? 'No Company'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 180,
    field: 'planing_date',
    headerName: 'Date of departure',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Date of departure</Typography>
        <FiltrationDate
          onChange={(e) => handleFilter(e, 'planing_date', true)}
          value={filters.planing_date || null}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        {row?.planing_date ?? ''}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 210,
    field: 'planing_sector',
    headerName: 'Sector',
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography style={HeaderName}>Sector</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.planing_sector || ''}
          value="planing_sector"
          placeholder="Sector"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
      >
        <Link style={{ 'textDecoration': 'none' }} to={`/flights/${row.flight_id}`}>{row?.planing_sector ?? '-'}</Link>
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 120,
    field: 'aircraft_registration',
    headerName: 'Reg',
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography style={HeaderName}>Reg</Typography>
        <FiltrationSelect
          options={aircraftOptions}
          defaultValue={filters.aircraft_id || ''}
          handleFilter={handleFilter}
          placeholder="Reg"
          value="aircraft_id"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        <Chip label={row?.aircraft_registration ?? ''} color="secondary" variant="outlined" />
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 120,
    field: 'planing_status',
    headerName: 'Planning Status',
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography style={HeaderName}>Planning Status</Typography>
        <FiltrationSelect
          options={OPTIONS_PLANING_STATUS}
          defaultValue={filters.planing_status || ''}
          handleFilter={handleFilter}
          value="planing_status"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        {row?.is_canceled ? <Chip label="Canceled" color="primary" /> : (row?.planing_status ? <Chip label="Closed" color="warning" /> : <Chip label="Opened" color="success" />)}
      </Typography>
    ),
  },
  {
    field: 'actions',
    maxWidth: 350,
    headerName: 'Actions',
    sortable: false,
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography sx={{ ...HeaderName, mb: '12px' }}>Actions</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <>
        <LinkButton path={`/flights/${row.flight_id}`}>
          <StyledPencil />
        </LinkButton>
        {' '}
        <StyledDelete onClick={() => handleOpenDeleteModal(row.flight_id)} />
        {' '}
        <StyledCopy onClick={() => handleCopyFlights(row.flight_id)} />
      </>
    ),
  },
]);

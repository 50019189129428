import { createTheme } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          margin: '0',
        }
      }
    },
    MuiPickersSlideTransition: {
      styleOverrides: {
        root: {
          overflowX: 'visible'
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          cursor: 'default'
        }
      }
    },
    MuiDayCalendar: {
      styleOverrides: {
        monthContainer: {
          overflow: 'visible',
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: ({ variant }) => {
          switch (variant) {
            case 'past': 
              return {
                '&:focus': {
                  backgroundColor: '#a4a4a4',
                },
                '&:hover': {
                  backgroundColor: '#7b7b7b',
                },
                backgroundColor: '#a4a4a4',
                color: 'white'
              };
            case 'today': 
              return {
                '&:focus': {
                  backgroundColor: '#336ba0',
                },
                '&:hover': {
                  backgroundColor: '#264f80',
                },
                backgroundColor: '#336ba0',
                color: 'white'
              };
            case 'future': 
              return {
                '&:focus': {
                  backgroundColor: '#44c9f5',
                },
                '&:hover': {
                  backgroundColor: '#00bdf5',
                },
                backgroundColor: '#44c9f5',
                color: 'white'
              };
            default: 
              return {};
          }
        }
      }
    }
  }
});
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import Button from '../../../components/Button';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import TextEdit from '../../../components/EditableTable/TextEdit';
import { TYPE_INPUT, TYPE_MULTI_INPUT, TYPE_SELECT_DATE, } from '../../../components/EditableTable/constants';
import {
  HeaderName, StyledDelete
} from '../../../components/EditableTable/styles';
import { TrainingProgramModel } from '../../../utils/constants/portalFiles';
import { GreenButton } from '../../Logistics/style';

export const columns = ({
  handleFilter,
  filters,
  handleOpen,
  handleOpenDeleteModal,
  handleFileDrawer,
  hasPermissions,
}) => ([
  {
    flex: 0.2,
    field: 'program_name',
    minWidth: 180,
    headerName: 'Program Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Program Name</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.program_name || ''}
          handleFilter={handleFilter}
          placeholder="Program Name"
          value="program_name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'program_name', row)}
        text={row.program_name}
        labelInput="Enter Program Name"
        disabled={!hasPermissions}
        type={TYPE_INPUT}
      />
    ),
  }, // program_name
  {
    flex: 0.2,
    field: 'review_date',
    minWidth: 180,
    headerName: 'Expiry Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Expiry Date</Typography>
        <FiltrationDate
          value={filters.review_date || null}
          onChange={(e) => {
            handleFilter(e, 'review_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'review_date', row)}
        text={row.review_date}
        labelInput="Enter Expiry Date"
        type={TYPE_SELECT_DATE}
        disabled={!hasPermissions}
      />
    ),
  }, // review_date
  {
    flex: 0.1,
    field: 'threshold_days',
    minWidth: 180,
    headerName: 'Threshold Days',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Threshold Days</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.threshold_days || ''}
          handleFilter={handleFilter}
          placeholder="Threshold Days"
          value="threshold_days"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'threshold_days', row)}
        text={row.threshold_days}
        disabled={!hasPermissions}
        labelInput="Enter Threshold Days"
        type={TYPE_INPUT}
      />
    ),
  }, // threshold_days
  {
    flex: 0.1,
    field: 'slb_file_exist',
    minWidth: 120,
    headerName: 'Files',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Files</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Button sx={GreenButton} size="small" onClick={() => handleFileDrawer(true, row?.id, TrainingProgramModel.types.review.id, '', row?.program_name)}>Files</Button>
      </Box>
    )
  }, // SLB file
  {
    flex: 0.2,
    field: 'feedback',
    minWidth: 180,
    headerName: 'Remarks',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Remarks</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.feedback || ''}
          handleFilter={handleFilter}
          placeholder="Remarks"
          value="feedback"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'feedback', row)}
        text={row.feedback}
        disabled={!hasPermissions}
        labelInput="Feedback"
        type={TYPE_MULTI_INPUT}
      />
    ),
  }, // feedback
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => hasPermissions && (
      <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
    ),
  }, // actions
]);

export const TitleContainer = {
  padding: 3,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: 3
};

export const PilotContainer = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: 10
};

export const paper = {
  padding: '0.25rem 1rem',
  backgroundColor: '#174674',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const paperPilot = {
  padding: '0.5rem 1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const typesContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  flexWrap: 'wrap',
  maxHeight: '158px',
  overflowY: 'auto',
  backgroundColor: '#F5F5F7',
  padding: '15px',
  borderRadius: '8px',
};

export const downloadBtn = {
  backgroundColor: '#659be0',
};

export const headerSx = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '15px',
};

export const otherBtn = {
  backgroundColor: '#a4926c',
  borderRadius: '6px',
};

export const headerRow = {
  minWidth: '350px',
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  overflowY: 'auto',
  backgroundColor: '#F5F5F7',
  padding: '15px',
  borderRadius: '8px',
};

export const typeSx = {
  color: '#fff',
  backgroundColor: '#ACB5C3',
  borderRadius: '25px',
  whiteSpace: 'nowrap',
  height: '40px',
  p: '6px 12px',
  textTransform: 'capitalize',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: '#8e9bae !important'
  }
};

export const activeTypeSx = {
  backgroundColor: '#36918b',
  '&:hover': {
    backgroundColor: '#36918b !important'
  }
};

export const tableContainer = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#f5f5f7',
  borderRadius: '10px',
  mt: '5px',
  '& .row-yellow': {
    backgroundColor: '#f1c40f33',
    '&.Mui-selected': {
      backgroundColor: '#f1c40f66  !important',
    },
    '&:hover': {
      backgroundColor: 'rgba(241, 196, 15, 0.3) !important',
    }
  },
  '& .row-green': {
    backgroundColor: '#36c6d333',       
    '&.Mui-selected': {
      backgroundColor: '#36c6d366  !important',
    },   
    '&:hover': {
      backgroundColor: 'rgba(54, 198, 211, 0.3) !important',
    }
  },
  '& .row-red': {
    backgroundColor: '#ed6b7533',
    '&.Mui-selected': {
      backgroundColor: '#ed6b7566  !important',
    },   
    '&:hover': {
      backgroundColor: 'rgba(237, 107, 117, 0.3) !important',
    }
  }
};

export const types = {
  flexDirection: 'column',
};

export const header = {
  display: 'flex',
  flexDirection: 'column',
};

export const typesSmall = {
  minWidth: '100%'
};

export const actionPerformed = {
  padding: '8px 16px',
  height: 'unset'
};

// columns 
export const menuContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
};

export const menuItem = {
  width: '34px',
  height: '30px',
  borderRadius: '3px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#31699e',
  cursor: 'pointer',
  svg: {
    width: '20px',
    path: {
      fill: '#fff',
    }
  },
  boxShadow: '0px 4px 8px -4px rgba(76, 78, 100, 0.42)'
};

export const invalidMenuItem = {
  backgroundColor: '#ed6b75'
};
import { useState } from 'react';
import { getOptions, getOptionsWithData } from '../utils/getOptions';

// A hook that takes the search query function from RTK Query
export function useServerSearch({ searchQueryFn, queryParams, withData }) {
  const [searchTerm, setSearchTerm] = useState('');
  const { data: items, isFetching } = searchQueryFn({ search: searchTerm, ...queryParams }, {
    skip: searchTerm.length < 2,
    refetchOnMountOrArgChange: true
  });

  return {
    items: !withData ? getOptions(items) : getOptionsWithData(items),
    isFetching,
    setSearchTerm,
    searchTerm
  };
}

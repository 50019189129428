import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridRoot = {
  display: 'flex',
  flex: '1 1 auto',
  height: '100%'
};

export const boardsWrap = {
  display: 'flex',
  flex: '1 1 auto',
  overflowX: 'auto',
  overflowY: 'hidden',
  height: '100%',
  padding: '5px'
};

export const tabsSx = {
  maxWidth: '300px',
  '& .MuiTab-textColorPrimary': {
    alignItems: 'flex-start'
  }
};

export const tabsSmall = {
  maxWidth: '100%',
  marginRight: 'unset',
  padding: '0.75rem 0',
  '& .MuiTab-root': {
    maxWidth: '100%'
  }
};

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fafcff', // #fafcff, #e9f0fe
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: '4px',
  cursor: 'pointer',
  width: '100%',
  '&:hover': {
    backgroundColor: '#e9f0fe',
  },
}));

export const StatusHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px',
  fontWeight: 600,
  paddingInline: '15px'
};

export const StatusText = {
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '8px',
  userSelect: 'none'
};

export const StackStyle = {
  padding: '10px',
  alignItems: 'center'
};

export const JobChipLink = {
  cursor: 'alias',
  userSelect: 'none',
  '&:hover': {
    backgroundColor: '#ffa5003d',
  },
};

export const OrderDetailsStyle = {
  margin: '1rem 1rem 1rem 0rem',
  backgroundColor: 'white',
  borderRadius: '8px'
};

export const RightMenuStyle = {
  display: 'flex',
  justifyContent: 'end',
  padding: '1.4rem 1rem 0rem 0rem',
};

export const CloseIconStyle = {
  cursor: 'pointer',
  '&:hover': {
    color: '#bb3a3a',
  },
};

export const DetailsGridStyle = {
  backgroundColor: '#e9f0fe',
  boxShadow: '8px -13px 20px 20px #e9f0fe'
};

export const noticeStyle = {
  width: '100%',
  borderRadius: '8px',
  backgroundColor: '#fff',
  '.Mui-disabled': {
    color: 'rgba(76, 78, 100, 0.87);',
    '-webkit-text-fill-color': 'rgba(76, 78, 100, 0.87)!important;'
  }
};

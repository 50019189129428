import { Box, Typography } from '@mui/material';
import React from 'react';
import FiltrationInput from '../../../../components/EditableTable/FiltrationInput';
import { HeaderName, StyledDelete, StyledPencil } from '../../../../components/EditableTable/styles';

export const columns = ({
  handleFilter,
  filters,
  setUpdateDepartment,
  handleOpenDeleteModal,
  hasPermissions
}) => ([
  {
    flex: 0.2,
    field: 'name',
    minWidth: 140,
    headerName: 'Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Name</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.name || ''}
          handleFilter={handleFilter}
          placeholder="Name"
          value="name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row.name}</Typography>
      </Box>
      // <TextEdit
      //   handleEdit={(...params) => handleOpen(...params, 'name', row)}
      //   text={row.name}
      //   labelInput="Enter Name"
      //   type={TYPE_INPUT}
      // />
    ),
  }, // Name
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', gap: 2 }}>
        <StyledPencil onClick={() => setUpdateDepartment(row)} />
        <StyledDelete onClick={() => {
          if (hasPermissions) handleOpenDeleteModal(row.id); }}
        />
      </Box>
    ),
  }, // actions
]);

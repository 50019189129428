
import {
  object, string,
} from 'yup';

export const defaultValues = {
  days_left: 30,
  days_right: 30,
  hours_left: 100,
  hours_right: 100,
  cyc_treshold_left: 0,
  cyc_treshold_right: 2000,
  wo_date: '',
  single_wo: false,
  negative_period: false
};

export const schema = object().shape({
  days_left: string().required('${label} cannot be blank.').label('Days treshold[-]').nullable(),
  days_right: string().required('${label} cannot be blank.').label('Days treshold[+]'),
  hours_left: string().required('${label} cannot be blank.').label('Hours treshold[-]'),
  hours_right: string().required('${label} cannot be blank.').label('Hours treshold[+]'),
  cyc_treshold_left: string().required('${label} cannot be blank.').label('Cyc treshold[-]'),
  cyc_treshold_right: string().required('${label} cannot be blank.').label('Cyc treshold[+]'),
  wo_date: string().required('${label} cannot be blank.').label('Wo date').nullable()
});

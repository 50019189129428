import { object, string } from 'yup';

export const defaultValues = {
  payment_department_id: '',
  name: '',
  rate_type: '',
  month_rate: '',
  day_rate: '',
  night_rate: '',
  type_of_payment: '',
  payment_details: '',
  comments: ''
};

export const schema = object().shape({
  name: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Name'),
  rate_type: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Rate Type'),
  month_rate: string()
    .nullable()
    .label('Rate'),
  day_rate: string()
    .nullable()
    .label('Rate'),
  night_rate: string()
    .nullable()
    .label('Rate'),
  type_of_payment: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Type of Payment'),
  payment_details: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Payment Details'),
  comments: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Comments'),
});

import {
  array,
  boolean,
  object,
  string,
} from 'yup';

export const defaultValues = {
  crew_name: '',
  crew_shortname: '',
  crew_post: '',
  crew_birth: '',
  crew_pasport: '',
  crew_expires: '',
  crew_nationality: '',
  crew_email: '',
  crew_licence: '',
  crew_proficiency_notes: '',
  crew_home_base: '',
  crew_active: false,
  crew_acft_type: ['NOTYPE'],
  photo_path: '',
  sign_path: '',
  crew_user_id: null,
  positions: []
};

export const schema = object().shape({
  crew_user_id: string()
    .nullable()
    .label('User Account'),
  crew_name: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Name'),
  crew_shortname: string()
    .required('${label} cannot be blank.')
    .label('Crew Shortname'),
  crew_post: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Post'),
  crew_birth: string().nullable(),
  crew_acft_type: array().nullable(),
  crew_pasport: string().nullable(),
  crew_expires: string().nullable(),
  crew_nationality: string().nullable(),
  crew_home_base: string().nullable(),
  crew_email: string().nullable(),
  crew_licence: string().nullable(),
  crew_proficiency_notes: string().nullable(),
  crew_active: boolean(),
});

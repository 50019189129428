import React, { useEffect, useState } from 'react';
import { Card, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import FormGroup from '../../form/components/FormGroup';
import DatePicker from '../../form/components/DatePicker';
import { useGetAirTypeListQuery, useGetAirListMutation, useGetStatisticByCompanyMutation } from '../../store/session';
import { setDateValue, validateDatePickerValue } from '../../utils/setDateValue';
import Select from '../../form/components/Select';
import { omitBy } from 'lodash';
import { parseLocationSearch, stringifyLocationSearch } from '../../utils/locationSearch';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../Button';
import { defaultValues } from './schema';
import { INVOICE_STATUS } from '../../constans/Statistics';
import { selectOptionFormat } from '../../utils/number';

const defaultOptions = [{ value: 0, label: 'All' }];

const Search = ({ useHook = () => [], fixedCacheKey, clearPath }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [filters, setFilters] = useState(parseLocationSearch(location.search));
  const [startDate, setStartDate] = useState(parseLocationSearch(location.search));

  const {
    handleSubmit, control, setValue, reset,
  } = useForm({
    defaultValues
  });

  const { data: aircraftTypeList } = useGetAirTypeListQuery();

  const [getAirList, { data: aircraftList, isLoading: isAircraftListLoading }] = useGetAirListMutation();
  const [getStatistic] = useHook({
    fixedCacheKey
  });

  const aircraftTypeListOptions = [
    ...defaultOptions,
    ...selectOptionFormat(aircraftTypeList, 'aircraft_type_name'),
  ];

  let aircraftListOptions = [
    ...defaultOptions,
    ...selectOptionFormat(aircraftList, 'aircraft_registration'),
  ];

  const onSubmit = async (data) => {
    const newFilters = {
      ...data
    };

    const result = omitBy(newFilters, (v) => v === null || v === undefined || v === '' || v === 0);

    if (result) {
      setFilters(result);

      const locationSearch = stringifyLocationSearch(result);

      navigate(location.pathname + locationSearch);
    }

    await getStatistic(stringifyLocationSearch(result));
  };

  const handleSelectType = async (typeId) => {
    if (typeId) {
      await getAirList(typeId);
    }

    if (!typeId) {
      aircraftListOptions = defaultOptions;
      setValue('aircraft_id', 0);
    }
  };

  const handleClear = async () => {
    reset({ aircraft_id: 0, aircraft_type: 0 });
    navigate(clearPath, { replace: true });
  };

  useEffect(() => {
    if (Object.values(filters).length) {
      handleSelectType(filters.aircraft_type);
      Object.entries(filters).forEach(
        ([name, value]) => setValue(name, value),
      );
    }
  }, [filters]);

  return (
    <Card sx={{ mt: 3, p: '0px 15px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={4}>
            <FormGroup label="AC Type">
              <Controller
                name="aircraft_type"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={aircraftTypeListOptions}
                    defaultValues={0}
                    placeholder="AC Type"
                    onChange={(e) => {
                      field.onChange(e);
                      handleSelectType(e.target.value);
                    }}
                  />
                )}
              />

            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormGroup label="AC">
              <Controller
                name="aircraft_id"
                control={control}
                render={({ field }) => (
                  <Select
                    options={aircraftListOptions}
                    defaultValues="0"
                    disabled={isAircraftListLoading}
                    placeholder="AC"
                    {...field}
                  />
                )}
              />

            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormGroup label="Invoice status">
              <Controller
                name="invoice_status"
                control={control}
                render={({ field }) => (
                  <Select
                    options={INVOICE_STATUS}
                    placeholder="Invoice status"
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormGroup label="From">
              <Controller
                name="date_from"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="YYYY-MM-DD "
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'date_from', 'YYYY-MM-DD');
                      setStartDate(e);
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormGroup label="To">
              <Controller
                name="date_to"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'date_to', 'YYYY-MM-DD');
                    }}
                    value={validateDatePickerValue(field.value)}
                    minDate={startDate || null}
                  />
                )}
              />

            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <Button type="submit" title="Submit" sx={{ width: '45%', mt: '23px' }} />
            <Button onClick={handleClear} title="Clear" sx={{ width: '45%', mt: '23px' }} />
          </Grid>
        </Grid>

      </form>
    </Card>
  );
};

export default Search;

import {
  object, string
} from 'yup';

export const defaultValues = {
  description: '',
};

export const schema = object().shape({
  description: string()
    .required('${label} cannot be blank.')
    .label('DESCRIPTION'),
});
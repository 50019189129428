import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateUserPositionsMutation,
  useGetPositionsQuery, useGetUserPositionsQuery, useUpdateUserPositionsMutation
} from '../../../../store/session';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValues, schema } from './schema';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Checkbox from '../../../../form/components/Checkbox';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import ContentPageLoader from '../../../../components/Loader/ContentPageLoader';
import Input from '../../../../form/components/Input';
import { toast } from 'react-hot-toast';

const UserPositionsForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const update = id;
  const { data: positions, isLoading: positionsLoading } = useGetPositionsQuery();
  const { data: currentPositionsUser, isLoading: currentPositionsUserLoading } = useGetUserPositionsQuery({ id }, { skip: !id, refetchOnMountOrArgChange: true, });
  const [createUserPosition, { isLoading: createPositionLoading }] = useCreateUserPositionsMutation();
  const [updateUserPosition, { isLoading: updatePositionLoading }] = useUpdateUserPositionsMutation();

  const updating = createPositionLoading || updatePositionLoading;
  const positionsCheckboxesArray = positions ? Object.keys(positions).map(i => ({ id: i, label: positions[i] })) : [];

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const formatAccess = data.current_position_access.map((i, key) => !!i && key).filter( i => !!i);

    let res;

    if (update) {
      const updateData = {
        ...(currentPositionsUser.current_position.position_name !== data.position_name && { position_name: data.position_name } )
      };

      res = await updateUserPosition({ id, data: { ...updateData, current_position_access: formatAccess } });
    } else {
      res = await createUserPosition({ ...data, current_position_access: formatAccess });
    }

    if (res.error) {
      toast.error(res.error?.data?.message, {
        id: toastId,
      });

      return;
    }

    toast.success(`User positions was ${update ? 'updated' : 'created'}!`, {
      id: toastId,
    });

    navigate('/manage-users-positions');
  };

  useEffect(() => {
    if (currentPositionsUser) {
      setValue('position_name', currentPositionsUser.current_position.position_name);

      currentPositionsUser.current_position_access.forEach(i => {
        setValue(`current_position_access.${i}`, true);
      });
    }
  }, [currentPositionsUser]);

  if (currentPositionsUserLoading || positionsLoading) return <ContentPageLoader />;

  return (
    <form>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
            {update ? 'Update' : 'Create'}
            {' '}
            User Positions
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <FormGroup label="Position name" required hasError={!!errors.position_name}>
                    <Controller
                      name="position_name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="Position name"
                          {...field}
                        />
                      )}
                    />

                    <FieldError error={errors.position_name} />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12}>
                {positionsCheckboxesArray?.map(i => (
                  <Grid item xs={12} sm={6}>
                    <FormGroup hasError={!!errors.airport_SlotsRequired}>
                      <Controller
                        name={`current_position_access.${i.id}`}
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            label={i.label}
                            {...field}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>

          <Button sx={{ mt: 5 }} type="submit" disabled={updating} variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
            {update ? 'Update' : 'Save' }
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserPositionsForm;

export const getOptions = (values = {}) => values && Object.keys(values)?.map(key => ({ value: key, label: values[key] }));

export const getOptionsWithKeyValue = (values = {}) => Object.keys(values).map(key => ({ value: key, label: `${key} ${values[key]}` }));

export const getMultiselectOptions = (data) => data?.reduce((acc, currValue) => acc.concat([...currValue.items].map((item) => ({
  value: item.crew_id,
  label: item.title,
}))), []);

export const getOptionsWithData = (values = {}) => Object.keys(values).map(key => ({
  value: values[key].value || values[key]?.id,
  label: values[key].label || values[key]?.part_name || values[key]?.part_number,
  data: values[key]
}));

export const changeKeyToName = (key = '') => {
  if (key.length <= 3) {
    return key.toUpperCase();
  }

  const toUpperCaseFirstLetter = (str = '') => {
    const [firstLetter, ...word] = str.split('');

    return [firstLetter.toUpperCase(), ...word].join('');
  };

  const nameArray = key.split('_');

  if (nameArray.length > 1) {
    const newNames = nameArray.map(item => toUpperCaseFirstLetter(item));

    return newNames.join(' ');
  }

  return toUpperCaseFirstLetter(key);
};

export const changeKey = (key = '') => {
  const newKey = key?.split('_');

  if (newKey?.length > 1) {
    return newKey.join(' ');
  } 

  return key;
};
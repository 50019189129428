import { Box, Tooltip, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import React from 'react';
import {
  AogPriorityIcon,
  HighPriorityIcon,
  HighestPriorityIcon,
  LowPriorityIcon,
  MediumPriorityIcon
} from '../components/Icons';

export const ORDER_STATUSES = [
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: '', value: '0', icon: (<Chip label="SENT" size="small" color="secondary" sx={{ fontWeight: 'bolder' }} />) },
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: '', value: '1', icon: (<Chip label="PENDING APPROVE" size="small" color="warning" sx={{ fontWeight: 'bolder' }} />) },
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: '', value: '2', icon: (<Chip label="APPROVED" size="small" color="info" sx={{ fontWeight: 'bolder' }} />) },
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: '', value: '3', icon: (<Chip label="ON THE WAY" size="small" color="primary" sx={{ fontWeight: 'bolder' }} />) },
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: '', value: '4', icon: (<Chip label="DELIVERED" size="small" color="success" sx={{ fontWeight: 'bolder' }} />) },
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: '', value: '5', icon: (<Chip label="DECLINED" size="small" color="error" sx={{ fontWeight: 'bolder' }} />) },
];

export const PART_TYPES = [
  { label: 'Part', value: 'part' },
  { label: 'Consumable', value: 'consumable' },
  { label: 'Instrument', value: 'instrument' },
  { label: 'Other', value: 'other' }
];

export const DELIVERY_PART_TYPES = [
  { label: 'Part Delivery', value: 'part_delivery', send_type: 'delivery' },
  { label: 'Overhaul', value: 'overhaul', send_type: 'Overhaul' },
  { label: 'Repair', value: 'repair', send_type: 'Repair' },
  { label: 'Exchange', value: 'exchange', send_type: 'Exchange' },
  { label: 'Other', value: 'other', send_type: 'Other' }
];

export const PRIORITY = [
  { label: 'AOG', value: 10, icon: (<AogPriorityIcon />) },
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: 'Highest', value: 4, icon: (<HighestPriorityIcon />) },
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: 'High', value: 3, icon: (<HighPriorityIcon />) },
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: 'Medium', value: 2, icon: (<MediumPriorityIcon />) },
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: 'Low', value: 1, icon: (<LowPriorityIcon />) },
];

// eslint-disable-next-line consistent-return
export const handleStatusChip = (status, comment, ttm, isAircraftBody = false, isPartiallyInstalled = false) => {
  if (isAircraftBody) {
    return <Chip label="INSTALLED" sx={{ fontWeight: 'bolder', backgroundColor: '#2240fa', color: '#ffffff' }} />;
  }
  if (isPartiallyInstalled) {
    return <Chip label="DELIVERED" sx={{ fontWeight: 'bolder', backgroundColor: '#2240fa', color: '#ffffff' }} />;
  }
  // eslint-disable-next-line default-case
  switch (status) {
    case 0:
      // eslint-disable-next-line react/react-in-jsx-scope
      return <Chip label="SENT" color="secondary" sx={{ fontWeight: 'bolder' }} />;
    case 1:
      // eslint-disable-next-line react/react-in-jsx-scope
      return <Chip label="PENDING APPROVE" color="warning" sx={{ fontWeight: 'bolder' }} />;
    case 2:
      // eslint-disable-next-line react/react-in-jsx-scope
      return <Chip label="APPROVED" color="info" sx={{ fontWeight: 'bolder' }} />;
    case 3:
      // eslint-disable-next-line react/react-in-jsx-scope
      return (
        <Tooltip
          placement="top-end"
          variant="soft"
          size="md"
          title={(
            <Box>
              <Typography sx={{ color: '#fff' }}>{ttm}</Typography>
            </Box>
        )}
        >
          <Chip label="ON THE WAY" color="primary" sx={{ fontWeight: 'bolder' }} />
        </Tooltip>
      );
    case 4:
      // eslint-disable-next-line react/react-in-jsx-scope
      return <Chip label="DELIVERED" color="success" sx={{ fontWeight: 'bolder' }} />;
    case 5:
      // eslint-disable-next-line react/react-in-jsx-scope
      return comment ? (
        <Tooltip
          placement="top-end"
          variant="soft"
          size="md"
          title={(
            <Box>
              <Typography sx={{ color: '#fff' }}>{comment}</Typography>
            </Box>
          )}
        >
          <Chip label="DECLINED" color="error" sx={{ fontWeight: 'bolder', backgroundColor: 'red', color: '#fff' }} />
        </Tooltip>
      ) : (<Chip label="DECLINED" color="error" sx={{ fontWeight: 'bolder', backgroundColor: 'red', color: '#fff' }} />);
    case 6:
      return <Chip label="INSTALLED" sx={{ fontWeight: 'bolder', backgroundColor: '#2240fa', color: '#ffffff' }} />;
    case 7:
      return <Chip label="DELIVERED" sx={{ fontWeight: 'bolder', backgroundColor: '#2240fa', color: '#ffffff' }} />;
  }
};

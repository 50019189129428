import {
  Box, InputLabel, Typography, Button, Input, styled,
} from '@mui/material';

export const WrapLoginPage = styled(Box)(({ theme }) => ({
  width: '100wv',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  background: theme.palette.customColors.lightBg,
}));

export const CustomForm = styled('form')(({ theme }) => ({
  width: '31.25rem',
  display: 'flex',
  justifyContent: 'start',
  borderRadius: '1rem',
  background: theme.palette.primary.main,
  padding: '2rem',
  gap: '1rem',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.common.white}`,
  boxShadow: '0px 0px 1px rgba(117, 131, 142, 0.04), 0px 2px 4px rgba(52, 60, 68, 0.16)',
}));

export const CustomLabel = styled(InputLabel)(({ theme, email }) => ({
  width: '100%',
  textAlign: 'left',
  color: theme.palette.primary.contrastText,
  fontWeight: '600',
  left: email && '-15px',
}));

export const ErrorMessage = styled(Box)(({ theme }) => ({
  width: '100%',
  color: theme.palette.error.main,
}));

export const LoginHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textAlign: 'center',
  fontWeight: '700',
  fontSize: '2rem',
}));

export const ButtonContainerLogin = styled(Box)(({ theme }) => ({
  width: '100%',
  fontWeight: '600',
  backgroundColor: theme.palette.primary.contrastText,
  background: theme.palette.customColors.textPrimary,
  marginTop: '1.5rem',
  borderRadius: '0.5rem',
  button: {
    width: '100%',
  },
}));

export const SignInButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontWeight: '700',
}));

export const LoginInput = styled(Input)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  '&:before': {
    borderBottom: '1px solid rgb(233 233 233)',
  },
  '&:after': {
    borderBottom: '1px solid rgb(197 164 104)',
  },
  '&:hover:not(.Mui-disabled):before': {
    borderBottom: '1px solid rgb(233 233 233)',
  },
  '& input:-webkit-autofill': {
    transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
    transitionDelay: 'background-color 5000s, color 5000s',
  },
  '& input:-webkit-autofill:hover': {
    transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
    transitionDelay: 'background-color 5000s, color 5000s',
  },
  '& input:-webkit-autofill:focus': {
    transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
    transitionDelay: 'background-color 5000s, color 5000s',
  },
  '& input:-webkit-autofill:active': {
    transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
    transitionDelay: 'background-color 5000s, color 5000s',
  },
}));

export const Logo = {
  width: '13rem',
  alignSelf: 'center',
  paddingBottom: '1rem',
};

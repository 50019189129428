import moment from 'moment';
import React from 'react';
import Box from '@mui/material/Box';
import { FERRY } from '../../../constans/constants';
import { Typography } from '@mui/material';

export const postColumns = (crew, formattedCrew) => ([
  {
    name: 'captain',
    id: 'pl_hop_capitane',
    cell: () => (
      <>
        {Object.keys(formattedCrew.caps).reverse().map(i => {
          const data = crew?.find(item => item.id === +i);

          return (
            <Typography sx={{ fontSize: '14px' }}>
              {data && `(${data.crew_shortname}) ${data.crew_name} (legs ${formattedCrew.caps[i]})`}
            </Typography>
          );
        })}
      </>
    ),
  },
  {
    name: 'first officer',
    id: 'pl_hop_fo',
    cell: () => (
      <>
        {Object.keys(formattedCrew.coP).reverse().map(i => {
          const data = crew?.find(item => item.id === +i);

          return (
            <Typography sx={{ fontSize: '14px' }}>
              {data && `(${data.crew_shortname}) ${data.crew_name} (legs ${formattedCrew.coP[i]})`}
            </Typography>
          );
        })}
      </>
    )
  },
  {
    name: 'engineer',
    id: 'pl_hop_engineer',
    cell: () => (
      <>
        {Object.keys(formattedCrew.eng).reverse().map(i => {
          const data = crew?.find(item => item.id === +i);

          return (
            <Typography sx={{ fontSize: '14px' }}>
              {data && `(${data.crew_shortname}) ${data.crew_name} (legs ${formattedCrew.eng[i]})`}
            </Typography>
          );
        })}
      </>
    )
  },
]);

export const regColumns = (options) => ([
  {
    id: 'planing_reg',
    name: 'reg',
    cell: ({ planing_reg }) => options?.find(aircraft => aircraft.id === planing_reg)?.aircraft_registration || '-'
  },
  {
    id: 'tel',
    name: 'ops tel no.',
    cell: () => '+36 1 7654433',
  },
]);

export const hopsColumns = (data, airportList) => ([
  {
    name: 'date of flight',
    id: 'pl_hop_departure_date',
    cell: ({ pl_hop_departure }) => moment(pl_hop_departure).format('DD MMM YYYY'),
  },
  {
    name: 'flight no.',
    id: 'pl_hop_prefix',
  },
  {
    name: 'departure time',
    id: 'pl_hop_departure',
    cell: ({ pl_hop_departure, pl_hop_departure_lt }) => `${moment(pl_hop_departure).format('HH:mm')}${` (${moment(pl_hop_departure_lt).format('HH:mm')} LT)` || ''}`,
  },
  {
    name: 'from',
    id: 'pl_hop_from',
    cell: ({ pl_hop_from_id, pl_hop_from }) => {
      const airport = airportList?.find(item => item.id === +pl_hop_from_id);

      return `${pl_hop_from.split('/')?.[1]}${airport?.airport_SlotsRequired ? ' (SLOT)' : ''}`;
    },
  },
  {
    name: 'to',
    id: 'pl_hop_to',
    cell: ({ pl_hop_to_id, pl_hop_to }) => {
      const airport = airportList?.find(item => item.id === +pl_hop_to_id);

      return `${pl_hop_to.split('/')?.[1]}${airport?.airport_SlotsRequired ? ' (SLOT)' : ''}`;
    },
  },
  {
    name: 'arrival time',
    id: 'pl_hop_arriving',
    cell: ({ pl_hop_arriving, pl_hop_arriving_lt }) => `${moment(pl_hop_arriving).format('HH:mm')}${` (${moment(pl_hop_arriving_lt).format('HH:mm')} LT)` || ''}`,
  },
  {
    name: 'payload',
    id: 'planing_crg_weight',
    cell: ({ pl_hop_empty }) => (pl_hop_empty ? FERRY : data.planing_crg_weight),
  },
]);

export const serviceColumns = (airportList, services, data) => ([
  {
    name: 'airfield',
    id: 'airfield',
    cell: ({ pl_serv_airport }) => {
      const airport = airportList?.find(item => item.id === +pl_serv_airport);

      return airport ? `${airport.airport_icao}/${airport.airport_iata} (${airport.airport_name})` : '-';
    }
  },
  {
    name: 'handling agent.',
    id: 'srvsup_name',
    cell: ({ pl_serv_airport, supplierServices, srvsup_name }) => (
      <Box>
        <Box>{srvsup_name}</Box>
        <Box>
          {supplierServices.length ? `(${supplierServices.map(service => services?.services?.[pl_serv_airport]?.[service])?.join(',')})` : ''}
        </Box>
      </Box>
    )
  },
  {
    name: 'vhf freq',
    id: 'srvsup_vhf',
  },
  {
    name: 'telephone',
    id: 'srvsup_MobileTel',
    cell: ({ srvsup_phone, srvsup_AfterHoursTel }) => `${srvsup_AfterHoursTel || ''}${srvsup_phone ? ` ${srvsup_phone}` : ''}`,
  },
  {
    name: 'email',
    id: 'srvsup_email',
    cell: ({ srvsup_email }) => (
      <Box sx={{ maxWidth: '200px', wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
        {`${srvsup_email || ''}`}
      </Box>
    )
  },
  {
    name: 'legs',
    id: 'pl_hop_departure',
    cell: ({ pl_serv_hop_id }) => {
      const currentLeg = data?.planing_hop.findIndex(i => i.pl_hop_id === pl_serv_hop_id);

      return currentLeg + 1;
    },
  },
]);

export const fuelColumns = (airportList, flightFuel) => ([
  {
    name: 'airport',
    id: 'airfield',
    cell: ({ plfuel_ap_id }) => {
      const airport = airportList?.find(item => item.id === +plfuel_ap_id);

      return airport ? `${airport.airport_icao}/${airport.airport_iata} (${airport.airport_name})` : '-';
    }
  },
  {
    name: 'fuel needed',
    id: 'plfuel_fuel_needed',
  },
  {
    name: 'fuel supplier',
    id: 'srvsup_vhf',
    cell: ({ plfuel_ap_id, plfuel_suplier }) => {
      const supplier = flightFuel?.airports[plfuel_ap_id]?.suppliers
        .find(element => element.id === plfuel_suplier) || [];

      return supplier?.label;
    }
  },
  {
    name: 'method',
    id: 'plfuel_payment_method',
    cell: ({ plfuel_payment_method }) => flightFuel?.paymentMethods?.[plfuel_payment_method]
  },
  {
    name: 'price [per Gal]',
    id: 'plfuel_price',
    cell: ({ plfuel_price }) => plfuel_price && `${plfuel_price || ''} USD`
  },
]);

export const cardContainerSx = {
  mt: 3,
  p: 5,
  pb: 3,
  fontFamily: '"Open Sans",sans-serif',
};

export const titleSx = {
  fontSize: '24px'
};

export const titleS = {
  fontSize: '19px'
};
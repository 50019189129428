import React, { useMemo } from 'react';
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  Divider,
  Grid, Typography,
} from '@mui/material';
import { headerBtn } from '../../../Invoices/style';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';

const TransactionsModal = ({ open, onClose, transactions }) => {
  const parseJsonAndRender = useMemo(() => {
    if (!transactions?.length) return;
    const option = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };

    // eslint-disable-next-line consistent-return
    return transactions.map((item, index) => (
      <Grid item xs={12} key={index}>
        <Typography>
          Amount:
          {' '}
          {item?.amount}
          {' '}
          {item?.currency}
        </Typography>
        <Typography>
          Transaction date:
          {' '}
          {new Date(item?.transaction_date).toLocaleDateString([], option)}
        </Typography>
        <Divider />
      </Grid>
    ));
  }, [transactions]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <DialogContent>
          <Card sx={{ padding: '1rem' }}>
            <Box sx={headerBtn}>
              <Box>
                <Typography variant="h5" noWrap>
                  Transactions:
                </Typography>
              </Box>
              <CloseIcon
                sx={CloseIconStyle}
                onClick={onClose}
              />
            </Box>

            <Divider />

            <Grid container spacing={4}>
              {parseJsonAndRender}
            </Grid>
          </Card>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default TransactionsModal;

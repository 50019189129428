import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FiltrationSelect from '../../components/EditableTable/FiltrationSelect';
import {
  TYPE_SELECT, TYPE_CHECK_BOX, TYPE_INPUT, TYPE_SELECT_DATE
} from '../../components/EditableTable/constants';
import FiltrationInput from '../../components/EditableTable/FiltrationInput';
import TextEdit from '../../components/EditableTable/TextEdit';
import FiltrationDate from '../../components/EditableTable/FiltrationDate';
import LinkButton from '../../components/LinkButton';
import {
  HeaderName, StyledDelete, StyledPencil,
} from '../../components/EditableTable/styles';
import { CREW_POST_OPTIONS, OPTIONS_ACTIVE, OPTIONS_LINE_CHECK } from '../../constans/crew';

export const columns = ({
  handleFilter,
  filters,
  handleOpen,
  aircraftOptions,
  handleOpenDeleteModal,
}) => ([
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <LinkButton path={`/directories/crew/update/${row.id}`}>
          <StyledPencil />
        </LinkButton>
        {' '}
        <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
      </>
    ),
  },
  {
    flex: 0.2,
    field: 'crew_active',
    minWidth: 180,
    headerName: 'ROSTER ACTIVE',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>ROSTER ACTIVE</Typography>
        <FiltrationSelect
          options={OPTIONS_ACTIVE}
          defaultValue={filters.crew_active || ''}
          handleFilter={handleFilter}
          placeholder="ROSTER ACTIVE"
          value="crew_active"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'crew_active', row)}
        text={row.crew_active}
        labelInput="Enter ROSTER ACTIVE"
        type={TYPE_CHECK_BOX}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'crew_name',
    minWidth: 200,
    headerName: 'Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.crew_name || ''}
          value="crew_name"
          placeholder="Name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'crew_name', row)}
        text={row.crew_name}
        labelInput="Enter Name"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'crew_aircraft_id',
    minWidth: 180,
    headerName: 'Reg',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Reg</Typography>
        <FiltrationSelect
          options={aircraftOptions}
          defaultValue={filters.crew_aircraft_id || ''}
          handleFilter={handleFilter}
          value="crew_aircraft_id"
          typeOptions="number"
          placeholder="Reg"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'crew_aircraft_id', row)}
        text={aircraftOptions?.find((item) => item?.value === +row.crew_aircraft_id)?.label}
        labelInput="Enter Reg"
        options={aircraftOptions}
        activeItem={row.crew_aircraft_id}
        type={TYPE_SELECT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'crew_post',
    minWidth: 180,
    headerName: 'Post',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Post</Typography>
        <FiltrationSelect
          options={CREW_POST_OPTIONS}
          defaultValue={filters.crew_post || ''}
          handleFilter={handleFilter}
          value="crew_post"
          typeOptions="number"
          placeholder="Post"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'crew_post', row)}
        options={CREW_POST_OPTIONS}
        text={CREW_POST_OPTIONS?.find((item) => item.value === +row.crew_post)?.label}
        activeItem={row.crew_post}
        labelInput="Enter Name"
        type={TYPE_SELECT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'crew_shortname',
    minWidth: 120,
    headerName: 'Shortname',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Shortname</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.crew_shortname || ''}
          value="crew_shortname"
          placeholder="Shortname"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'crew_shortname', row)}
        text={row.crew_shortname}
        labelInput="Enter Shortname"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'crew_pasport',
    minWidth: 150,
    headerName: 'Passport',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Passport</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.crew_passport || ''}
          value="crew_passport"
          placeholder="Passport"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'crew_pasport', row)}
        text={row.crew_pasport}
        labelInput="Enter Passport"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'crew_expires',
    minWidth: 180,
    headerName: 'Expires',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Expires</Typography>
        <FiltrationDate
          value={filters.crew_expires || null}
          onChange={(e) => {
            handleFilter(e, 'crew_expires', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'crew_expires', row)}
        text={row.crew_expires}
        labelInput="Enter Name"
        type={TYPE_SELECT_DATE}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'crew_email',
    minWidth: 200,
    headerName: 'Email',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Email</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.crew_email || ''}
          value="crew_email"
          placeholder="Email"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'crew_email', row)}
        text={row.crew_email}
        labelInput="Enter Email"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'crew_line_check',
    minWidth: 180,
    headerName: 'LC',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>LC</Typography>
        <FiltrationSelect
          options={OPTIONS_LINE_CHECK}
          defaultValue={filters.crew_active || ''}
          handleFilter={handleFilter}
          value="crew_line_check"
          placeholder="LC"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'crew_line_check', row)}
        text={row.crew_line_check}
        labelInput="Enter LC"
        type={TYPE_CHECK_BOX}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'crew_licence',
    minWidth: 200,
    headerName: 'Licence',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Licence</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.crew_licence || ''}
          value="crew_licence"
          placeholder="Licence"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'crew_licence', row)}
        text={row.crew_licence}
        labelInput="Enter Licence"
        type={TYPE_INPUT}
      />
    ),
  },
]);

import Box from '@mui/material/Box';
import React from 'react';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import ButtonIcon from '../../../../../components/ButtonIcon';
import DropDownMenu from '../../../../../components/DropDownMenu';
import { useCreateOdsFromFlightMutation } from '../../../../../store/session';
import { containerSx, dropdownMenuSx, itemSx } from './styles';

const GenerateDropDown = ({
  handleFlightData,
  open,
  onClose,
  anchorEl,
  hasPermissionsOdsCreate,
  handleSetModalState,
  id,
}) => {
  const [createODSFromFlight] = useCreateOdsFromFlightMutation();

  const handleODS = async () => {
    const toastId = toast.loading('Loading...');

    const res = await createODSFromFlight(id);

    if (res.error) {
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    await handleFlightData();
    toast.success('ODS was created!', {
      id: toastId,
    });

    onClose();
  };

  return (
    <DropDownMenu
      sx={dropdownMenuSx}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
    >
      <Box sx={containerSx}>
        <Link to={`/flights/crew-briefing/${id}`} target="_blank">
          <Box sx={itemSx}>
            Crew Briefing
          </Box>
        </Link>
        <ButtonIcon
          sx={{
            fontFamily: 'unset',
            fontSize: '1rem',
            textTransform: 'none'
          }}
          disabled={!hasPermissionsOdsCreate}
          onClick={() => handleSetModalState({
            text: 'Are you sure you want to generate and send all ODS from this flight?',
            data: null,
            callback: handleODS
          })}
        >
          <Box disabled sx={itemSx}>Operational Data Sheet</Box>
        </ButtonIcon>
      </Box>
    </DropDownMenu>
  );
};

export default GenerateDropDown;

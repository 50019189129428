import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetCrewByIdQuery, useGetExportExcelMutation, useReceiveFtlMutation
} from '../../../store/session';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import { DEFAULT_MONTH, DEFAULT_YEAR, MONTH_SHORT } from './constants';

const getUrlParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return {
    year: urlParams.get('year'),
    months: urlParams.get('months'),
  };
};

export const useFlightTimeLimitations = () => {
  const initialMonth = getUrlParams().months;
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [year, setYear] = useState(getUrlParams().year || DEFAULT_YEAR);
  const [months, setMonths] = useState(initialMonth ? initialMonth.split(',') : DEFAULT_MONTH);
  const [data, setData] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const isPdf = params.isPdf && params.id;

  const numberMonths = MONTH_SHORT.reduce((total, amount, index) => {
    const monthIndex = [...total, months.includes(amount) && index + 1];
    const res = monthIndex.filter((e) => e);

    return res;
  }, []);

  const [exportExcel] = useGetExportExcelMutation();
  const { data: crew, isLoading: isCrewLoading } = useGetCrewByIdQuery(params.id);
  const [receiveFtl] = useReceiveFtlMutation();

  const loadData = () => {
    setIsListLoading(true);
    receiveFtl( {
      data: stringifyLocationSearch({
        year,
        months: numberMonths,
      }),
      id: params.id,
    }).then((res) => {
      if (res) {
        setData(res?.data?.data);
        setIsListLoading(false);
      }
    });
  };

  const list = useMemo(() => (Object.keys(data)
    .reduce(
      (res, key) => [...res, ...Object.keys(data[key])
        .map((item, index) => ({ ...data[key][item], id: item, ...(index === 0 && { date: key }) }))],
      []
    )), [data]);

  // !: date should be dd.mm.yyyy
  const formatDate = (date) => {
    const arrayDate = date.split('.');

    return `${arrayDate[1]} ${arrayDate[0]} ${arrayDate[2]}`;
  };

  const getWrongAvail = useMemo(() => list.reduce((acc, item, index) => {
    if (item?.next_avail_exceeded) {
      return [...acc, index - 1];
    }

    return acc;
  }, []), [list]);

  const print = () => {
    setIsLoading(true);
    const inputs = document.querySelectorAll('#forPdfFTL');

    inputs.forEach(input => {
      html2canvas(input, { scale: 1.2 })
        .then((canvas) => {
          const pdf = new JSPDF({
            orientation: 'landscape',
            unit: 'px',
            format: [900, 1120]
          });
          const imgData = canvas.toDataURL('image/png');
          pdf.addImage(imgData, 'JPEG', 0, 0);
          pdf.save('download.pdf');
          setIsLoading(false);
        });
    });
  };

  const handlePrint = () => {
    const paramsValues = getUrlParams();
    navigate(`/crew/ftl/pdf/${params.id}${stringifyLocationSearch(paramsValues)}`);
  };

  const handleSetYear = (value) => {
    const paramsValues = getUrlParams();

    setYear(value);
    navigate(`/crew/ftl/${params.id}${stringifyLocationSearch({ ...paramsValues, year: value })}`);
  };

  const handleSetMonths = (value) => {
    const paramsValues = getUrlParams();

    setMonths(value);
    navigate(`/crew/ftl/${params.id}${stringifyLocationSearch({ ...paramsValues, months: value })}`);
  };

  const handleLogBook = () => {
    const paramsValues = getUrlParams();
    navigate(`/crew/log-book/${params.id}${stringifyLocationSearch(paramsValues)}`);
  };

  const handleDoc = async () => {
    const res = await exportExcel({ id: crew.id, year, months: numberMonths });

    FileSaver.saveAs(res.data, 'ftl_report');
  };

  useEffect(() => {
    loadData();
  }, [params.id, year, months]);

  useEffect(() => {
    if (Object.keys(data || {}).length && isPdf) {
      print();
    }
  }, [data, isPdf]);

  return {
    year,
    months,
    data,
    crew,
    list,
    isPdf,
    isPdfLoading: isLoading,
    isLoading: isCrewLoading || isListLoading,
    numberMonths,
    handleLogBook,
    handlePrint,
    getWrongAvail,
    handleDoc,
    print,
    setYear: handleSetYear,
    setMonths: handleSetMonths,
  };
};

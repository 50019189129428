export const headerSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
};

export const backIcon = {
  cursor: 'pointer',
};

export const cardsContainer = {
  display: 'flex',
  flexDirection: 'column',
  gap: '30px'
};

export const cardContainer = {
  p: '30px',
  mt: '30px',
  display: 'flex',
  gap: '15px',
};

export const saveBtn = {
  color: '#fff',
  maxWidth: '200px',
  width: '100%',
};

export const downloadBtn = {
  backgroundColor: '#1b996a',
  color: '#fff',
  maxWidth: '200px',
  width: '100%',
  '&:hover': {
    backgroundColor: '#004c30'
  }
};

export const cancelBtn = {
  backgroundColor: '#e9edef',
  color: '#34495e',
  maxWidth: '200px',
  width: '100%',
  '&:hover': {
    backgroundColor: '#c3cfd3'
  }
};

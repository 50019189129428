import { toast } from 'react-hot-toast';

export function useHandleQueryFormError() {
  return (
    error,
    setError,
    getValues,
    toastId,
  ) => {
    const formFields = Object.keys(getValues());

    Object.keys(error?.data?.errors || {}).forEach((field) => {
      if (formFields.includes(field)) {
        // manually set api validation-schemas errors to the form fields
        setError(field, { type: 'manual', message: error?.data?.errors?.[field]?.[0] });
      }
    });

    toast.error(error?.data?.message || 'Something went wrong', {
      id: toastId
    } );
  };
}

import Typography from '@mui/material/Typography';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from '../../../constans/formats';
import { linkSx } from '../styles';
import { HeaderName } from '../../../components/EditableTable/styles';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import { Box, Tooltip } from '@mui/material';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import FiltrationAutocomplete from '../../../components/EditableTable/FiltrationAutocomplete';
import ButtonIcon from '../../../components/ButtonIcon';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { APPROVE_INVOICE } from '../../../constans/deleteConfirmation';
import { STATUSES } from '../../../constans/invoiceRequest';
import TextEdit from '../../../components/EditableTable/TextEdit';
import { TYPE_SELECT_DATE } from '../../../components/EditableTable/constants';
import { menuItem, invalidMenuItem } from './styles';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useIsDeprecated } from './hooks/useIsDeprecated';

export const columnsInvoicesFull = ({
  handleOpen, currencyOptions, handleFilter, filters, handleDownload, handleOpenDeleteModal, purposeOptions, hasPermissionsOCCM, onOpenReasonModal,
  handleOpenDropDown,
}) => ([
  {
    width: 180,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => {
      const status = STATUSES.find(i => i.value === row.payment_status)?.value;

      return (
        <>
          {hasPermissionsOCCM && (status === 0 || status === 6) && (
            <ButtonIcon
              sx={{
                '&:hover': {
                  color: '#6ddf95 !important'
                },
              }}
              disabled={!hasPermissionsOCCM || (status !== 0 && status !== 6)}
              onClick={() => handleOpenDeleteModal({
                id: row.id, text: APPROVE_INVOICE, successButton: 'Yes', cancelButton: 'No', approve: true,
              })}
            >
              <CheckIcon />
            </ButtonIcon>
          )}
          {' '}
          {STATUSES.find(i => i.value === row.payment_status)?.value !== 5 && (
            <ButtonIcon
              sx={{
                ml: 2,
                '&:hover': {
                  color: '#f35d5d !important'
                },
              }}
              onClick={() => handleOpenDeleteModal({
                id: row.id, data: row, commentDelete: true,
              })}
            >
              <CloseIcon />
            </ButtonIcon>
          )}
        </>
      );
    },
  },
  {
    flex: 0.3,
    field: 'file_name',
    minWidth: 220,
    headerName: 'File',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>File</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.file_name || ''}
          value="file_name"
          placeholder="File"
        />
      </Box>
    ),
    renderCell: ({ row: { file_name, id, } }) => (
      <Typography
        sx={{
          ...linkSx,
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
        onClick={() => handleDownload({ file_name, id }, 'inv')}
      >
        {file_name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'payment_status',
    minWidth: 300,
    headerName: 'Payment Status',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Payment Status</Typography>
        <FiltrationSelect
          options={STATUSES || []}
          defaultValue={filters.payment_status || ''}
          handleFilter={handleFilter}
          placeholder="Payment Status"
          value="payment_status"
        />
      </Box>
    ),
    renderCell: ({ row }) => (+row.payment_status === 5 ? (
      <Tooltip title={row.decline_purpose || 'Decline purpose is empty'} placement="top-start">
        <Typography
          sx={{
            ...linkSx,
            textTransform: 'capitalize',
            whiteSpace: 'break-spaces',
            wordBreak: 'break-all'
          }}
          onClick={() => { onOpenReasonModal(row.decline_purpose); }}
        >
          {STATUSES.find(i => i.value === row.payment_status)?.label || 'Empty'}
        </Typography>
      </Tooltip>
    ) : (
      <Typography sx={{
        textTransform: 'capitalize',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-all'
      }}
      >
        {STATUSES.find(i => i.value === row.payment_status)?.label || 'Empty'}

      </Typography>
    )),
  },
  {
    flex: 0.2,
    field: 'upload_date',
    minWidth: 180,
    headerName: 'Upload Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          '& .MuiPickersCalendarHeader-root': {
            display: 'none'
          }
        }}
      >
        <Typography style={HeaderName}>Upload Date</Typography>
        <FiltrationDate
          value={filters.upload_date || null}
          onChange={(e) => {
            handleFilter(e, 'upload_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{moment(row.upload_date).format(DATE_FORMAT)}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'due_payment_date',
    minWidth: 180,
    headerName: 'Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Deadline Payment</Typography>
        <FiltrationDate
          value={filters.due_payment_date || null}
          onChange={(e) => {
            handleFilter(e, 'due_payment_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={{
        textTransform: 'capitalize',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-all'
      }}
      >
        {row.due_payment_date}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'sender',
    minWidth: 180,
    headerName: 'Sender',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Sender</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.sender || ''}
          value="sender"
          placeholder="Sender"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {row.sender}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'description',
    minWidth: 320,
    headerName: 'Description',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',

        }}
      >
        <Typography style={HeaderName}>Description</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.description || ''}
          value="description"
          placeholder="Description"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={{
        textTransform: 'capitalize',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-all'
      }}
      >
        {row.description}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'invoice_sum',
    minWidth: 180,
    headerName: 'Sum',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Sum</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.invoice_sum || ''}
          value="invoice_sum"
          placeholder="Sum"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {row.invoice_sum || '-'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'currency',
    minWidth: 220,
    headerName: 'Currency',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Currency</Typography>
        <FiltrationAutocomplete
          options={currencyOptions || []}
          handleFilter={handleFilter}
          defaultValue={filters.currency || ''}
          sx={{ width: '180px' }}
          value="currency"
          placeholder="Currency"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {row.currency}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'payment_purpose',
    minWidth: 220,
    headerName: 'Purpose Of Payment',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Purpose Of Payment</Typography>
        <FiltrationAutocomplete
          options={purposeOptions || []}
          handleFilter={handleFilter}
          defaultValue={filters.payment_purpose || ''}
          sx={{ width: '180px' }}
          value="payment_purpose"
          placeholder="Purpose Of Payment"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {row.payment_purpose}
      </Typography>
    ),
  },
  {
    field: 'proof_of_payment_file',
    minWidth: 200,
    headerName: 'Proof Of Payment',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Proof Of Payment</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <>
        <Box sx={{ ...menuItem, ...(row.proof_of_payment_file ? null : invalidMenuItem) }}>
          { row.proof_of_payment_file ? (<EditIcon onClick={(e) => handleOpenDropDown(e, row)} />) : (<AddIcon onClick={(e) => handleOpenDropDown(e, row)} />)}
        </Box>
        {row.due_payment_date && useIsDeprecated(row.due_payment_date) && row.payment_status !== 3 && row.payment_status !== 5 && (
          <Box sx={{ color: 'red', ml: 10 }}>
            <PriorityHighIcon />
          </Box>
        )}
      </>
    ),
  },
]);

import React from 'react';
import { Card, CardContent } from '@mui/material';
import QuotationTable from './QuotationTable';

const Quotations = () => (
  <Card sx={{ mt: 3 }}>
    <CardContent>
      <QuotationTable title="Quotations" />
    </CardContent>
  </Card>
);

export default Quotations;

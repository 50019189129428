import React, { useEffect } from 'react';
import {
  Card,
  Grid,
  CardContent,
  CardHeader,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import FormGroup from '../../../../form/components/FormGroup';
import { schema, defaultValues } from './schema';
import {
  useGetTrainingFlightCrewDefaultBlockMutation,
  useCreateTrainingFlightCrewDefaultBlockMutation,
  useUpdateTrainingFlightCrewDefaultBlockMutation,
} from '../../../../store/session';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import Input from '../../../../form/components/Input';
import FieldError from '../../../../form/components/FieldError';
import Button from '../../../../components/Button';
import CardsTable from './CardsTable';
import { usePermissions } from '../../../../utils/hooks/usePermissions';

const CreateDocumentsBlock = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleError = useHandleQueryFormError();

  const hasPermissionsBank = usePermissions('training_module_write');

  const [createBlock] = useCreateTrainingFlightCrewDefaultBlockMutation();
  const [updateBlock] = useUpdateTrainingFlightCrewDefaultBlockMutation();
  const [getBlockInfo] = useGetTrainingFlightCrewDefaultBlockMutation();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    if (!id) {
      const res = await createBlock(data);

      if (res.error) {
        handleError(res.error, setError, getValues, toastId);

        return;
      }

      navigate(`/training-module/flight-crew/update-block/${res.data.id}`);

      toast.success('Block was created!', {
        id: toastId,
      });
    } else {
      const res = await updateBlock({ data, id });

      if (res.error) {
        handleError(res.error, setError, getValues, toastId);

        return;
      }

      toast.success('Block was updated!', {
        id: toastId,
      });
    }
  };

  const handleUpdateValues = (result) => {
    Object.entries(result).forEach(
      ([name, value]) => {
        setValue(name, value);
      }
    );
  };

  const handleGetBlockInfo = async () => {
    const { data: result } = await getBlockInfo(id);

    if (result) {
      handleUpdateValues(result);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetBlockInfo();
    }
  }, [id]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          item
          sx={{
            display: 'flex',
            gap: '9px',
          }}
          xs={12}
        >
          <Button
            title="Back"
            size="large"
            onClick={() => {
              navigate('/training-module/flight-crew/default-documents');
            }}
          />
          <Button disable={!hasPermissionsBank} type="submit" variant="contained" title={`${id ? 'Save' : 'Create'}`} size="large" />
        </Grid>

        <Card sx={{ mt: 3 }}>
          <CardHeader title={`${id ? 'Update' : 'Create'} Block`} />
          <CardContent>
            <Grid xs={12} container spacing={4} rowSpacing={4} sx={{ flexGrow: 1, mt: 2 }}>
              <Grid item xs={12}>
                <FormGroup label="Name" required hasError={!!errors.block_name}>
                  <Controller
                    name="block_name"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Name"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.block_name} />
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
          {id && (
            <>
              <CardHeader title="Documents" />
              <CardContent sx={{ mt: 5, overflowX: 'scroll' }}>
                <CardsTable />
              </CardContent>
            </>
          )}
        </Card>
      </form>
    </FormProvider>
  );
};

export default CreateDocumentsBlock;

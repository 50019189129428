import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useStoreOrderMutation } from '../../../../store/session';
import { defaultValues, schema } from './schema';

export const useOrderForm = ({ onClose }) => {
  const [storeOrder, isLoading] = useStoreOrderMutation();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { reset, setValue } = methods;

  const handleSelectJob = (selection) => {
    const option = selection || { value: null, label: '' };

    setValue('wo_job_id', option?.value || null);
  };

  const handleClose = (resetData = false) => {
    if (resetData) {
      reset(defaultValues);
    }
    onClose();
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const sendData = {
      ...data,
      aircraft_id: +data?.aircraft_id === 0 ? null : data?.aircraft_id
    };

    const { error } = await storeOrder(sendData);

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    handleClose(true);
    toast.success('Successfully!', {
      id: toastId,
    });
  };

  return {
    methods,
    onSubmit,
    handleClose,
    handleSelectJob,
    isLoading,
  };
};

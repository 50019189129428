import { yupResolver } from '@hookform/resolvers/yup';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  Box, Divider, Grid, InputLabel, ToggleButton, ToggleButtonGroup, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import ApproveModal from '../../../../../components/ApproveModal';
import Button from '../../../../../components/Button';
import InBoxIcon from '../../../../../components/Icons/InBoxIcon';
import Checkbox from '../../../../../form/components/Checkbox';
import FormGroup from '../../../../../form/components/FormGroup';
import Input from '../../../../../form/components/Input';
import Select from '../../../../../form/components/Select';
import { useCreateWorkOrderPartsPairMutation, useUpdateWorkOrderPartsPairMutation } from '../../../../../store/session';
import AutocompleteForm from '../../../../Maintenance/WorkOrder/WorkOrderTaskForm/PartOffPartOn/CreatePartsPairDrawer/components/AutocompleteForm';
import AutocompletePartOffForm from '../../../../Maintenance/WorkOrder/WorkOrderTaskForm/PartOffPartOn/CreatePartsPairDrawer/components/AutocompletePartOffForm';
import ManualForm from '../../../../Maintenance/WorkOrder/WorkOrderTaskForm/PartOffPartOn/CreatePartsPairDrawer/components/ManualForm';
import ManualPartOffForm from '../../../../Maintenance/WorkOrder/WorkOrderTaskForm/PartOffPartOn/CreatePartsPairDrawer/components/ManualPartOffForm';
import { defaultValues, schema } from '../../../../Maintenance/WorkOrder/WorkOrderTaskForm/PartOffPartOn/CreatePartsPairDrawer/schema';
import { cancelSx, footerSx } from '../../../../Maintenance/WorkOrder/WorkOrderTaskForm/PartOffPartOn/CreatePartsPairDrawer/styles';

const Create = (props) => {
  const {
    handleSetTypeBody, currentParts, onClose, optionsCrewList = [], id, ods 
  } = props;
  const [currentPart, setCurrentPart] = useState(null);
  const [currentPartSerial, setCurrentPartSerial] = useState(null);
  const [currentPartNumber, setCurrentPartNumber] = useState(null);
  const [currentPartIDOff, setCurrentPartIDOff] = useState(null);
  const [currentPartNameOff, setCurrentPartNameOff] = useState(null);
  const [currentPartSNOff, setCurrentPartSNOff] = useState(null);
  const [sameUnit, setSameUnit] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState({
    isOpen: false,
    data: null
  });
  const [partOnType, setPartOnType] = useState('autocomplete');
  const [partOffType, setPartOffType] = useState('autocomplete');
  const [createWorkOrderPartsPair, { isLoading }] = useCreateWorkOrderPartsPairMutation();
  const [updateWorkOrderPartsPair, { isLoading: isUpdateLoading }] = useUpdateWorkOrderPartsPairMutation();
  const isOpenApproveModal = ((!!currentPartSerial?.data?.aircraft_id && !!ods?.aircraft_id) && (currentPartSerial?.data?.aircraft_id === ods?.aircraft_id));

  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    watch
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const formData = new FormData();
    let res;

    const sendData = {
      ...data,
      part_aircraft_body_id: !data?.part_aircraft_body_id ? '' : data?.part_aircraft_body_id,
      part_id: data?.part_id === 0 ? '' : data?.part_id,
      action_id: id,
      same_unit: sameUnit
    };

    if (sendData?.tabValue === 'autocomplete') {
      delete sendData.certificate;
      delete sendData.unit_id;
    }

    if ((sendData?.tabValue === 'manual') && (sendData.certificate === null)) {
      delete sendData.certificate;
    }

    delete sendData.tabValue;
    delete sendData.tabOffValue;
    delete sendData.wo_task_id;

    Object.entries(sendData).forEach(([key, value]) => {
      if (key === 'certificate') {
        formData.append(key, data.certificate);
      } else {
        formData.append(key, value);
      }
    });
    if (!currentParts) {
      res = await createWorkOrderPartsPair(formData);
    } else {
      formData.delete('part_aircraft_body_id');
      formData.delete('serial_off_number');
      formData.delete('part_off_number');

      if (!sendData?.same_unit
        && ((sendData?.part_off_name === sendData?.part_name)
        || (sendData?.part_off_number === sendData?.part_on_number)
        || (sendData?.serial_off_number === sendData?.serial_on_number))) {
        toast.error('Part On cannot be the same as Part Off!', {
          id: toastId,
        });

        return;
      }
      res = await updateWorkOrderPartsPair({ data: formData, id: currentParts?.id });
    }

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong.', {
        id: toastId,
      });

      return;
    }

    onClose();
    reset();

    toast.success('Successfully created!', {
      id: toastId,
    });
  };

  const handleSelectPart = (item, type = '') => {
    if (type === 'id') {
      setCurrentPart(item);
      setCurrentPartSerial(null);
      setCurrentPartNumber(null);
    }
    if (type === 'serial') {
      setCurrentPart(null);
      setCurrentPartSerial(item);
      setCurrentPartNumber(null);
    }
    if (type === 'number') {
      setCurrentPartNumber(item);
      setCurrentPart(null);
      setCurrentPartSerial(null);
    }

    setValue('part_id', item?.value);
    setValue('part_on_number', item?.data?.part_number);
    setValue('serial_on_number', item?.data?.serial_number === 'N/A' ? item?.data?.quantity : item?.data?.serial_number);
    setValue('part_name', item?.data?.part_name);
    setValue('storage', item?.data?.storage);
    setValue('quantity', item?.data?.quantity);
    trigger('part_id', 'part_on_number', 'serial_on_number', 'part_name', 'storage');
  };

  const handleSelectAircraftPart = (item, type) => {
    if (type === 'part_id') {
      setCurrentPartIDOff(item);
      setCurrentPartNameOff(null);
      setCurrentPartSNOff(null);
    }
    if (type === 'part_name') {
      setCurrentPartIDOff(null);
      setCurrentPartNameOff(item);
      setCurrentPartSNOff(null);
    }
    if (type === 'serial_number') {
      setCurrentPartIDOff(null);
      setCurrentPartNameOff(null);
      setCurrentPartSNOff(item);
    }

    setValue('part_aircraft_body_id', item?.value);
    setValue('part_off_number', item?.data?.part_number);
    setValue('part_off_name', item?.data?.part_name);
    setValue('serial_off_number', item?.data?.serial_number || item?.data?.quantity);
    trigger('part_aircraft_body_id', 'part_off_number', 'serial_off_number');
  };

  const handleChangePartOnType = (type) => {
    setCurrentPart(null);
    setCurrentPartSerial(null);
    setCurrentPartNumber(null);
    setValue('tabValue', type);
    setValue('storage', '');
    setValue('part_id', 0);
    setValue('quantity', 1);
    setValue('part_name', '');
    setValue('part_on_number', '');
    trigger('part_on_text');
    setPartOnType(type);
  };

  const handleChangePartOffType = (type) => {
    setCurrentPartIDOff(null);
    setCurrentPartNameOff(null);
    setCurrentPartSNOff(null);
    setValue('tabOffValue', type);
    if (!currentParts) {
      setValue('part_off_number', '');
      setValue('serial_off_number', '');
      setValue('part_off_name', '');
    }
    setValue('part_aircraft_body_id', 0);
    setPartOffType(type);
  };

  const handleCloseApproveModal = () => {
    setOpenApproveModal({
      isOpen: false,
      data: null
    });
  };

  const handleChangeSameUnit = (value) => {
    const part_id = getValues('part_id');

    setSameUnit(value);
    if (value) {
      setPartOnType('autocomplete');

      if (!part_id) {
        setValue('part_id', 0);
        setValue('part_name', '');
        setValue('serial_on_number', '');
        setValue('part_on_number', '');
        setValue('storage', '');
        setValue('quantity', 1);
        setValue('unit_id', '3');
      }
    }
  };

  useEffect(() => {
    if (id) {
      reset();
      setCurrentPartIDOff(null);
      setCurrentPartNameOff(null);
      setCurrentPartSNOff(null);
      setCurrentPart(null);
      setCurrentPartSerial(null);
      setCurrentPartNumber(null);
      setSameUnit(false);
      setValue('wo_task_id', id);
      setPartOnType('autocomplete');
      setPartOffType('autocomplete');
    }
  }, [id]);

  useEffect(() => {
    if (currentParts) {
      setValue('part_off_number', currentParts?.part_off_number);
      setValue('part_off_name', currentParts?.part_off_name);
      setValue('part_off_text', currentParts?.part_off_text);
      setValue('performed_by', currentParts?.performed_by);
      setValue('serial_off_number', currentParts?.serial_off_number);
      setPartOffType('manual');
      setValue('tabOffValue', 'manual');
    }
  }, [currentParts]);

  return (
    <>
      <Grid sx={{
        padding: '24px'
      }}
      >
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        >
          <Button
            title={(
              <Box sx={{ display: 'flex', gap: 4 }}>
                <KeyboardBackspaceIcon />
                <Typography sx={{ color: '#fff' }}>Back</Typography>
              </Box>
          )}
            onClick={() => handleSetTypeBody('table')}
          />
          <Typography variant="h4">Create</Typography>
          <Box />
        </Box>
      </Grid>
      <Grid sx={{
        padding: '0 24px'
      }}
      >
        <Grid
          container
          spacing={5}
          rowSpacing={2}
          sx={{ p: '30px' }}
        >
          <Grid item xs={12}>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="h4"
                sx={{
                  textTransform: 'capitalize', display: 'flex', gap: '15px', alignItems: 'center'
                }}
              >
                Part Off
                <InBoxIcon />
              </Typography>
              {!currentParts && (
                <Box>
                  <ToggleButtonGroup
                    value={partOffType}
                    exclusive
                    onChange={(e) => handleChangePartOffType(e.target.value)}
                    size="small"
                    aria-label="Small sizes"
                    sx={{
                      mr: 2,
                      '& .Mui-selected': {
                        backgroundColor: '#dfe6f4'
                      }
                    }}
                  >
                    <ToggleButton value="autocomplete" aria-label="module">
                      Autocomplete
                    </ToggleButton>
                    <ToggleButton value="manual" aria-label="list" disabled={sameUnit}>
                      Manual
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              )}
            </Box>

            <Divider />
          </Grid>

          {(partOffType === 'autocomplete') && (
            <AutocompletePartOffForm
              errors={errors}
              control={control}
              handleSelectAircraftPart={handleSelectAircraftPart}
              currentPartIDOff={currentPartIDOff}
              currentPartNameOff={currentPartNameOff}
              currentPartSNOff={currentPartSNOff}
              currentParts={currentParts}
            />
          )}

          {(partOffType === 'manual') && (
            <ManualPartOffForm
              control={control}
              errors={errors}
              currentPartIDOff={currentPartIDOff}
              currentPartNameOff={currentPartNameOff}
              currentPartSNOff={currentPartSNOff}
              currentParts={currentParts}
            />
          )}

          <Grid item xs={12}>
            <FormGroup label="Part Off Text" required hasError={errors?.part_off_text}>
              <Controller
                name="part_off_text"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows={2}
                    placeholder="Enter Part Off Text"
                    multiline
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="h4"
                sx={{
                  textTransform: 'capitalize', display: 'flex', gap: '15px', alignItems: 'center'
                }}
              >
                Part On
                <InBoxIcon />
              </Typography>
              <Box>
                <ToggleButtonGroup
                  value={partOnType}
                  exclusive
                  onChange={(e) => handleChangePartOnType(e.target.value)}
                  size="small"
                  aria-label="Small sizes"
                  sx={{
                    mr: 2,
                    '& .Mui-selected': {
                      backgroundColor: '#dfe6f4'
                    }
                  }}
                >
                  <ToggleButton value="autocomplete" aria-label="module">
                    Autocomplete
                  </ToggleButton>
                  <ToggleButton value="manual" aria-label="list" disabled={sameUnit}>
                    Manual
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>

            <Divider />
          </Grid>

          {(partOnType === 'autocomplete') && (
            <AutocompleteForm
              errors={errors}
              control={control}
              handleSelectPart={handleSelectPart}
              currentPart={currentPart}
              currentPartNumber={currentPartNumber}
              currentPartSerial={currentPartSerial}
              setValue={setValue}
              handleChangePartOnType={handleChangePartOnType}
            />
          )}

          {(partOnType === 'manual') && (
            <ManualForm
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          )}

          <Grid item xs={12}>
            <FormGroup label="Part On Text" required hasError={errors?.part_on_text}>
              <Controller
                name="part_on_text"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows={2}
                    placeholder="Enter Part On Text"
                    multiline
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider />
            <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
              Performed By
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="Performed By" required hasError={!!errors.performed_by}>
              <Controller
                render={({ field } ) => (
                  <Select
                    options={optionsCrewList}
                    placeholder="Performed By"
                    {...field}
                  />
                )}
                control={control}
                name="performed_by"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Box sx={footerSx}>
          <Button
            disabled={isLoading || isUpdateLoading}
            onClick={handleSubmit((data) => {
              if (isOpenApproveModal) {
                setOpenApproveModal({
                  isOpen: true,
                  data
                });
              } else {
                onSubmit(data);
              }
            })}
            variant="contained"
            title={currentParts ? 'Save' : 'Create'}
          />
          <Button sx={cancelSx} title="Close" onClick={onClose} />
          {currentParts && (
          <Box>
            <InputLabel sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <Checkbox
                value={sameUnit}
                onChange={(el) => {
                  handleChangeSameUnit(el.target.checked);
                }}
              />
              <Typography sx={{ textTransform: 'uppercase' }}>Same Unit</Typography>
              
            </InputLabel>
           
          </Box>
          )}
        </Box>

        <ApproveModal
          open={openApproveModal.isOpen}
          onClose={handleCloseApproveModal}
          data={{
            text: `The spare part is already installed on the ${currentPartSerial?.data?.aircraft_reservation}, are you sure you want to make this entry?`,
            data: openApproveModal.data,
            successButton: 'Send'
          }}
          onApprove={({ data }) => {
            handleCloseApproveModal();
            onSubmit(data);
          }}
          refetch={() => {}}
        />
      </Grid>
    </>
  );
};

export default Create;
export const headerTitleSx = {
  color: '#34495e',
  fontWeight: 600,
  fontSize: '14px'
};

export const headerSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  p: '8px',
  gap: '10px',
  backgroundColor: '#e9edef',
  mb: '10px',
};

export const titlesSx = {
  fontSize: '18px',
  fontWeight: 300,
  mb: '-20px'
};

export const buttonSx = {
  color: '#fff',
  width: '100%',
  '&:hover': {
    backgroundColor: 'rgb(53 151 243 / 80%) !important'
  }
};

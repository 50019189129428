import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody, CircularProgress,
} from '@mui/material';
import moment from 'moment';
import { useGetFdpMutation } from '../../../../../store/session';

const FdpCard = (props) => {
  const {
    watch, update, hopFields,
  } = props;
  const [firstLoad, setFirstLoad] = useState(true);
  const [fdp, { data: fdpResult, isLoading: isUpdating }] = useGetFdpMutation({
    fixedCacheKey: 'get-fdp-data',
  });

  const fetchFDP = useCallback(async (values) => {
    await fdp(values);
  }, []);

  useEffect(() => {
    if (firstLoad && update) {
      if (hopFields) {
        fetchFDP(hopFields).catch();
        setFirstLoad(false);
      }
    } else if (firstLoad && !update) {
      fetchFDP([]).catch();
      setFirstLoad(false);
    }
  }, [firstLoad]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name.substring(14) === 'pl_hop_from_id'
        || name.substring(14) === 'pl_hop_to_id'
        || name.substring(14) === 'pl_hop_departure'
        || name.substring(14) === 'pl_hop_arriving'
        || name === 'planing_hop'
      ) {
        const hopsCount = value.planing_hop.length;
        let definedHops = 0;
        Object.entries(value.planing_hop).forEach(([index]) => {
          if (value.planing_hop[index].pl_hop_from_id
            && value.planing_hop[index].pl_hop_departure
            && value.planing_hop[index].pl_hop_to_id
            && value.planing_hop[index].pl_hop_arriving
          ) {
            definedHops += 1;
          }
        });

        if (definedHops === hopsCount) {
          const data = [];
          // eslint-disable-next-line no-unused-vars
          Object.entries(value.planing_hop).forEach(([index, hop]) => {
            data.push({
              pl_hop_arriving: moment(hop.pl_hop_arriving).format('YYYY-MM-DD HH:mm'),
              pl_hop_departure: moment(hop.pl_hop_departure).format('YYYY-MM-DD HH:mm'),
              pl_hop_to_id: hop.pl_hop_to_id,
              pl_hop_from_id: hop.pl_hop_from_id,
              pl_hop_blockoff: hop.pl_hop_blockoff,
              pl_hop_blockon: hop.pl_hop_blockon,
              pl_hop_id: hop.pl_hop_id,
            });
          });

          fetchFDP(data).catch();
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader title="Flight Duty Period" />
      <CardContent sx={{ textAlign: 'center' }}>
        { !isUpdating
          ? (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">FDP #</TableCell>
                    <TableCell align="center">Max FDP</TableCell>
                    <TableCell align="center">Current FDP</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fdpResult
                  && fdpResult.map((fpds) => (
                    <TableRow key={fpds.num}>
                      <TableCell align="center">{fpds.num}</TableCell>
                      <TableCell align="center">{fpds.maxFdp}</TableCell>
                      <TableCell align="center">{fpds.currentFdp}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
          : (
            <CircularProgress sx={{
              marginTop: '2rem',
            }}
            />
          )}
      </CardContent>
    </Card>
  );
};

export default FdpCard;


import {
  object, string,
} from 'yup';

export const defaultValues = {
  training_date: null,
  training_start: null,
  training_end: null,
  training_type: '',
  participants: [],
  instructor: '',
  location: '',
  remarks: '',
};

export const schema = object().shape({
  training_type: string()
    .required('${label} cannot be blank.')
    .label('Training Type'),
  instructor: string()
    .required('${label} cannot be blank.')
    .label('Instructor'),
  location: string()
    .required('${label} cannot be blank.')
    .label('Location'),
  training_date: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Training Date'),
  training_start: string()
    .nullable()
    .when('training_end', {
      is: (val) => val !== null,
      then: string().nullable().required('${label} cannot be blank if Training Finish selected.'),
    })
    .label('Training Start'),
  training_end: string()
    .nullable()
    .when('training_start', {
      is: (val) => val !== null,
      then: string().nullable().required('${label} cannot be blank if Training Start selected.'),
    })
    .label('Training End'),
}, [['training_start', 'training_end', 'training_date', 'training_type', 'instructor', 'location']]);

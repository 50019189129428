export const footerSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  p: '20px'
};

export const cancelSx = {
  background: '#e1e5ec'
};

export const numberInputSx = {
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  }
};

export const drawerSx = {
  '& .MuiPaper-root': {
    width: '100%',
  }
};
import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '../../../../components/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Flight from '../Flight';
import Departure from '../Departure';
import Arrival from '../Arrival';
import PreviousHrs from '../PreviousHrs';
import TotalHrs from '../TotalHrs';
import Crew from '../Crew';
import Load from '../Load';
import FuelRamp from '../FuelRamp';
import Weight from '../Weight';
import PaxInformation from '../PaxInformation';
import DeIceing from '../DeIceing';
import OilUplift from '../OilUplift';
import InstrumentsRegardings from '../InstrumentsRegardings';
import Remarks from '../Remarks';
import Actions from '../Actions';
import Defects from '../Defects';
import {
  backIcon,
  cancelBtn,
  cardContainer,
  cardsContainer,
  downloadBtn,
  headerSx,
  saveBtn,
} from './styles';

export const OdsFormLayout = ({
  handleBack,
  control,
  errors,
  isTypeF,
  getValues,
  setValue,
  print,
  hasPermissionsOdsEdit,
  odsStatusesOptions,
  odsTocOptions,
  updateIsLoading,
  airportList,
}) => (
  <Box sx={{ padding: '0 2rem 2rem 2rem', minHeight: 'calc(100vh - 200px)' }}>
    <Box sx={headerSx}>
      <ChevronLeftIcon sx={backIcon} onClick={handleBack} />
      <h1>Operational Data Sheet</h1>
    </Box>

    <Box sx={cardsContainer}>
      <Flight
        odsStatusesOptions={odsStatusesOptions}
        odsTocOptions={odsTocOptions}
        setValue={setValue}
        control={control}
        isTypeF={isTypeF}
        getValues={getValues}
        errors={errors}
      />
      {isTypeF && (
        <>
          <Departure airportList={airportList} setValue={setValue} control={control} errors={errors} />
          <Arrival airportList={airportList} setValue={setValue} control={control} errors={errors} />
        </>
      )}
      <PreviousHrs control={control} />
      {isTypeF && (
        <>
          <TotalHrs getValues={getValues} />
          <Crew control={control} />
          <Load control={control} errors={errors} />
          <FuelRamp control={control} errors={errors} />
          <Weight control={control} errors={errors} />
          <PaxInformation control={control} />
          <DeIceing control={control} />
        </>
      )}
      <OilUplift control={control} />
      {isTypeF && <InstrumentsRegardings control={control} />}
      <Remarks control={control} />
      <Actions hasPermissionsOdsEdit={hasPermissionsOdsEdit} control={control} getMainValues={getValues} setValue={setValue} />
      <Defects hasPermissionsOdsEdit={hasPermissionsOdsEdit} control={control} getMainValues={getValues} setValue={setValue} />
    </Box>

    <Card sx={cardContainer}>
      <Button sx={saveBtn} disabled={updateIsLoading || !hasPermissionsOdsEdit} type="submit" title="Save" />
      <Button sx={downloadBtn} onClick={print} title="Print" />
      <Button sx={cancelBtn} title="Cancel" />
    </Card>
  </Box>
);

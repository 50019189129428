export const columns = [
  {
    id: 'departure',
    label: 'Departure',
  },
  {
    id: 'arriving',
    label: 'Arriving',
  },
  {
    id: 'flight_no',
    label: 'Flight No',
  },
  {
    id: 'block_off',
    label: 'Block OFF',
  },
  {
    id: 'take_off',
    label: 'Take OFF',
  },
  {
    id: 'landing',
    label: 'Landing',
  },
  {
    id: 'block_on',
    label: 'Block ON',
  },
  {
    id: 'navigation',
    label: 'Navigation',
  },
  {
    id: 'flight_time',
    label: 'Flight time',
  },
  {
    id: 'blh_time',
    label: 'BLH time',
  },
];

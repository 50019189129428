export const STATUSES = [ // all parameters are required if create new one
  {
    value: '',
    label: '-',
  },
  {
    value: 0,
    label: 'PENDING APPROVE (OCCM)',
    color: '#f1ff0069',
    tag: 'pndng-apprv-occm'
  },
  {
    value: 1,
    label: 'PENDING APPROVE (AM)',
    color: 'rgba(255,171,58,0.49)',
    tag: 'pndng-apprv-am'
  },
  {
    value: 2,
    label: 'PENDING PAYMENT',
    color: 'rgba(0,51,255,0.16)',
    tag: 'pndng-pmnt'
  },
  {
    value: 3,
    label: 'PAID',
    color: 'rgba(150,217,132,0.65)',
    tag: 'payed'
  },
  {
    value: 4,
    label: 'NOT PAID',
    color: 'rgba(236,112,99,0.49)',
    tag: 'not-payed'
  },
  {
    value: 5,
    label: 'DECLINED',
    color: '#0000002e',
    tag: 'declined'
  },
  {
    value: 6,
    label: 'PENDING APPROVE (LOGISTIC)',
    color: 'rgba(255,238,4,0.24)',
    tag: 'declined'
  },
  {
    value: 7,
    label: 'PREPAYMENT',
    color: 'rgba(255,238,4,0.24)',
    tag: 'payed'
  },
  {
    value: 8,
    label: 'ISSUE',
    color: 'rgba(255,138,4,0.24)',
    tag: 'issue'
  },
  {
    value: 10,
    label: 'ARCHIVE',
    color: 'rgba(255,213,4,0.24)',
    tag: 'archive'
  },
];

export const STATUSES_INVOICE_APPROVE_EXPENSIVE = [ // all parameters are required if create new one
  {
    value: 1,
    label: 'PENDING APPROVE (AM)',
    color: 'rgba(255,171,58,0.49)',
    tag: 'pndng-apprv-am'
  },
  {
    value: 2,
    label: 'PENDING PAYMENT',
    color: 'rgba(0,51,255,0.16)',
    tag: 'pndng-pmnt'
  },
  {
    value: 3,
    label: 'PAID',
    color: 'rgba(150,217,132,0.65)',
    tag: 'payed'
  },
  {
    value: 4,
    label: 'NOT PAID',
    color: 'rgba(236,112,99,0.49)',
    tag: 'not-payed'
  },
  {
    value: 5,
    label: 'DECLINED',
    color: '#0000002e',
    tag: 'declined'
  },
];

export const STATUSES_INVOICE_PAYMENT = [ // all parameters are required if create new one
  {
    value: 2,
    label: 'PENDING PAYMENT',
    color: 'rgba(0,51,255,0.16)',
    tag: 'pndng-pmnt'
  },
  {
    value: 3,
    label: 'PAID',
    color: 'rgba(150,217,132,0.65)',
    tag: 'payed'
  },
  {
    value: 4,
    label: 'NOT PAID',
    color: 'rgba(236,112,99,0.49)',
    tag: 'not-payed'
  },
];

import {
  object, string, date, number,
} from 'yup';

export const schema = object().shape({
  date: date().required('${label} cannot be blank.').label('Date'),
  supplier_id: number()
    .required('${label} cannot be blank.')
    .label('Supplier'),
  sum: number()
    .required('${label} cannot be blank.')
    .label('Sum'),
  notes: string()
    .label('Notes'),
});

import { Box, Grid, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { MONTH_SHORT } from '../../../pages/SabCrewRoster/constants';
import { useSendRosterMutation } from '../../../store/session';
import Button from '../../Button/Button';
import CheckboxesTags from '../../CheckboxesTags';
import Drawer from '../../Drawer';
import {
  cancelSx, footerSx,
} from './styles';

const SendRoster = ({
  open, onClose, options, year, months, acftType
}) => {
  const [selectedRoster, setSelectedRoster] = useState([]);
  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const [sendRoster] = useSendRosterMutation();

  const handleAllRosters = () => {
    setSelectedRoster(options);
  };

  const handleSave = async () => {
    const numberMonths = MONTH_SHORT.reduce((total, amount, index) => {
      const monthIndex = [...total, months.includes(amount) && index + 1];
      const res = monthIndex.filter((e) => e);

      return res;
    }, []);

    const resCrews = selectedRoster?.map(item => (
      item.value
    ));

    const res = sendRoster({
      crews: resCrews,
      year: +year,
      months: numberMonths,
      acftType
    });

    toast.promise(res, {
      loading: 'Loading',
      success: 'All messages was sended',
      error: 'Something went wrong',
    });

    onClose();
  };

  return (
    <Drawer
      title="eROSTER SENDER"
      open={open}
      onClose={onClose}
      sx={maxWidth800px ? {
        '& .MuiPaper-elevation': {
          width: '100vw'
        }
      } : {}}
    >
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        sx={{ p: '30px' }}
      >
        <CheckboxesTags
          options={options}
          label="Select Roster"
          placeholder="Select Roster"
          value={selectedRoster}
          onChange={(e, data) => {
            setSelectedRoster(data);
          }}
        />
        <Button onClick={handleAllRosters} sx={{ mt: '15px' }} title="Add all" />
      </Grid>
      <Box sx={footerSx}>
        <Button variant="contained" title="Send" onClick={handleSave} />
        <Button sx={cancelSx} title="Cancel" onClick={onClose} />
      </Box>
    </Drawer>
  );
};

export default SendRoster;

import React from 'react';
import TypeCard from '../TypeCard';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { Controller } from 'react-hook-form';
import FieldError from '../../../../form/components/FieldError';

const Weight = ({ control, isPanel, errors = {} }) => (
  <TypeCard title="WEIGHT" variant="sky" isPanel={isPanel}>
    <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1 }}>
      <Grid item xs={12} lg={6}>
        <FormGroup label="TOW" required={isPanel} hasError={!!errors.weight_tow}>
          <Controller
            name="weight_tow"
            control={control}
            render={({ field }) => (
              <Input
                InputProps={{
                  endAdornment: <InputAdornment position="end">KG</InputAdornment>,
                }}
                {...field}
              />
            )}
          />
          <FieldError error={errors.weight_tow} />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={6}>
        <FormGroup label="LW" required={isPanel} hasError={!!errors.weight_lw}>
          <Controller
            name="weight_lw"
            control={control}
            render={({ field }) => (
              <Input
                InputProps={{
                  endAdornment: <InputAdornment position="end">KG</InputAdornment>,
                }}
                {...field}
              />
            )}
          />
          <FieldError error={errors.weight_lw} />
        </FormGroup>
      </Grid>
    </Grid>
  </TypeCard>
);

export default Weight;

import mapStyle from './mapStyle.json';

export const options = {
  center: {
    lat: 46.6696892,
    lng: 10.9539249
  },
  zoom: 4.8,
  options: {
    styles: mapStyle,
    mapTypeControl: false,
    streetViewControl: false
  }
};

export const apiToken = 'AIzaSyCHacqzQdOh9YE8MFffzThwmGcTco7Wjhk';

export const airportMarkerOptions = {
  icon: {
    url: 'https://api.iconify.design/material-symbols/weight.svg?color=%23008cff',
    scaledSize: {
      width: 50,
      height: 50
    }
  },
  styles: {
    textColor: '#008cff'
  },
  title: 'Airport',
};

export const markerOptions = {
  imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  gridSize: 60,
  maxZoom: 15,
  zoomOnClick: true,
  averageCenter: true,
  minimumClusterSize: 2,
  styles: [
    {
      url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
      height: 53,
      width: 53,
      textColor: '#ffffff',
      textSize: 12
    },
    // Add more cluster styles if needed
  ]
};

import React from 'react';
import Card from '@mui/material/Card';
import FileSaver from 'file-saver';
import {
  useDownloadUserDocumentMutation, useGetUserDocumentsListQuery, useConfirmDocumentMutation
} from '../../../store/session';
import { columnsDocs } from './columnsDocs';
import { toast } from 'react-hot-toast';
import EditableTable from '../../../components/EditableTable';

const DocumentsTab = () => {
  const [downloadDocuments] = useDownloadUserDocumentMutation();
  const [confirmDocument] = useConfirmDocumentMutation();

  const handleDownload = async({ id, document_patch, file_name }) => {
    const toastId = toast.loading('Downloading...');

    const res = await downloadDocuments(id);

    const fileName = (document_patch || file_name)?.replace( /\\/g, '/' ).replace( /.*\//, '' );

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    FileSaver.saveAs(res.data, fileName);

    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleConfirmDocument = async(id) => {
    const toastId = toast.loading('Loading...');

    const res = await confirmDocument(id);

    if (res.error) {
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    toast.success('The document has been read!', {
      id: toastId,
    });
  };

  return (
    <Card>
      <EditableTable
        handleDownload={handleDownload}
        getRowHeight={() => 'auto'}
        useHook={useGetUserDocumentsListQuery}
        confirmDocument={handleConfirmDocument}
        columns={columnsDocs}
        sortByType="DESC"
        sortByActive="created_at"
      />
    </Card>
  );
};

export default DocumentsTab;

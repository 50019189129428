import React from 'react';
import Grid from '@mui/material/Grid';
import AboutOverivew from './AboutOverivew';
import ActivityTimeline from './ActivityTimeline';
import { useReadNotificationMutation, useReadAllNotificationMutation } from '../../../store/session';
import toast from 'react-hot-toast';
import Loader from '../../../components/Loader/ContentPageLoader';

const ProfileTab = ({
  data, notifications, isLoading, refetch
}) => {
  const [readNotification] = useReadNotificationMutation();
  const [readAllNotification] = useReadAllNotificationMutation();

  const handleRead = async (id) => {
    const toastId = toast.loading('Loading...');

    await readNotification(id).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        refetch();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleReadAll = async () => {
    const toastId = toast.loading('Loading...');
    await readAllNotification().then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        refetch();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  return (data && Object.values(data).length ? (
    <Grid container spacing={6}>
      <Grid item lg={4} md={5} xs={12}>
        <AboutOverivew user={data} />
      </Grid>
      <Grid item lg={8} md={7} xs={12}>
        <Grid container spacing={6}>
          {isLoading ? <Loader /> : (
            <Grid item xs={12}>
              <ActivityTimeline data={notifications} handleRead={handleRead} handleReadAll={handleReadAll} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  ) : null);
};

export default ProfileTab;

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FiltrationInput from '../../components/EditableTable/FiltrationInput';
import LinkButton from '../../components/LinkButton';
import {
  HeaderName,
} from '../../components/EditableTable/styles';
import ButtonIcon from '../../components/ButtonIcon';
import FiltrationDate from '../../components/EditableTable/FiltrationDate';
import FiltrationSelect from '../../components/EditableTable/FiltrationSelect';
import { INVOICE_TYPE, PAYMENTS } from '../../constans/invoice';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { CONFIRM_DELETE, CONFIRM_DELETE_CANCEL } from '../../constans/deleteConfirmation';
import moment from 'moment';

export const columns = ({
  handleFilter,
  filters,
  hasPermissions,
  banksListOptions,
  getBankAccountNumbers,
  bankAccountNumbers,
  cardsOptions,
  getCards,
  currencyOptions,
  departmentsOptions,
  aircraftTypeOptions,
  aircraftOptions,
  getAir,
  refetch,
  handleOpenDeleteModal,
}) => ([
  {
    width: 180,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <ButtonIcon
          sx={{
            '&:hover': {
              color: '#6ddf95 !important'
            },
          }}
          disabled={!hasPermissions}
          onClick={() => handleOpenDeleteModal({ id: row.id, text: CONFIRM_DELETE, refetch })}
        >
          <CheckIcon />
        </ButtonIcon>
        {' '}
        <ButtonIcon
          sx={{
            ml: 2,
            '&:hover': {
              color: '#f35d5d !important'
            },
          }}
          disabled={!hasPermissions}
          onClick={() => handleOpenDeleteModal({
            id: row.id, data: row, text: CONFIRM_DELETE_CANCEL, refetch, successButton: 'Yes', cancelButton: 'No', confirm: true,
          })}
        >
          <CloseIcon />
        </ButtonIcon>
      </>
    ),
  },
  {
    flex: 0.2,
    field: 'index',
    minWidth: 200,
    headerName: 'Record number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Record number</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.index || ''}
          value="index"
          placeholder="Record number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.index}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'year',
    minWidth: 200,
    headerName: 'Year',
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.year}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'month',
    minWidth: 200,
    headerName: 'Month',
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {moment(row.month, 'MM').format('MMMM')}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'bank',
    minWidth: 200,
    headerName: 'Name bank',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Name bank</Typography>
        <FiltrationSelect
          options={banksListOptions || []}
          defaultValue={filters.bank || ''}
          handleFilter={handleFilter}
          getData={getBankAccountNumbers}
          placeholder="Name bank"
          value="bank"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row?.bank}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'bank_account_number',
    minWidth: 250,
    headerName: 'Bank account number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Typography style={HeaderName}>Bank account number</Typography>
        <FiltrationSelect
          options={bankAccountNumbers || []}
          defaultValue={filters.bank_account_number || ''}
          getData={getCards}
          disabled={!filters.bank}
          handleFilter={handleFilter}
          placeholder="Bank account number"
          value="bank_account_number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row?.bank_account_number || '-'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'account_number_card',
    minWidth: 200,
    headerName: 'Card number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Typography style={HeaderName}>Card number</Typography>
        <FiltrationSelect
          options={(filters.bank_account_number ? cardsOptions : []) || []}
          defaultValue={filters.account_number_card || ''}
          disabled={!filters.bank_account_number}
          handleFilter={handleFilter}
          placeholder="Card number"
          value="account_number_card"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row?.account_number_card || '-'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'payment',
    minWidth: 200,
    headerName: 'Payment',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Payment</Typography>
        <FiltrationSelect
          options={[...PAYMENTS, { value: '', label: '-' }]}
          defaultValue={filters.payment || ''}
          handleFilter={handleFilter}
          placeholder="Payment"
          value="payment"
        />
      </Box>
    ),
    renderCell: ({ row }) => {
      const text = PAYMENTS.find(item => +item.value === row.payment);

      return (
        <Typography
          variant="subtitle1"
          noWrap
          sx={{ textTransform: 'capitalize' }}
        >
          {text.label}
        </Typography>
      );
    },
  },
  {
    flex: 0.2,
    field: 'payment_date',
    minWidth: 200,
    headerName: 'Payment date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          '& .MuiPickersCalendarHeader-root': {
            display: 'none'
          }
        }}
      >
        <Typography style={HeaderName}>Payment date</Typography>
        <FiltrationDate
          value={filters.payment_date || null}
          onChange={(e) => {
            handleFilter(e, 'payment_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.payment_date}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'payment_amount',
    minWidth: 200,
    headerName: 'Payment amount',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Payment amount</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.payment_amount || ''}
          value="payment_amount"
          placeholder="Payment amount"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.payment_amount}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'payment_currency',
    minWidth: 220,
    headerName: 'Payment Currency',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Payment Currency</Typography>
        <FiltrationSelect
          options={currencyOptions || []}
          defaultValue={filters.payment_currency || ''}
          handleFilter={handleFilter}
          placeholder="Payment Currency"
          value="payment_currency"
        />
      </Box>
    ),
    renderCell: ({ row }) => {
      const text = currencyOptions.find(item => +item.value === row.payment_currency);

      return (
        <Typography
          variant="subtitle1"
          noWrap
          sx={{ textTransform: 'capitalize' }}
        >
          {text?.label || '-'}
        </Typography>
      );
    },
  },
  {
    flex: 0.2,
    field: 'invoice_type',
    minWidth: 200,
    headerName: 'Invoice Type',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoice Type</Typography>
        <FiltrationSelect
          options={[...INVOICE_TYPE, { value: '', label: '-' }]}
          defaultValue={filters.invoice_type || ''}
          handleFilter={handleFilter}
          placeholder="Invoice Type"
          value="invoice_type"
        />
      </Box>
    ),
    renderCell: ({ row }) => {
      const text = INVOICE_TYPE.find(item => +item.value === row.invoice_type);

      return (
        <Typography
          variant="subtitle1"
          noWrap
          sx={{ textTransform: 'capitalize' }}
        >
          {text?.label || '-'}
        </Typography>
      );
    },
  },
  {
    flex: 0.2,
    field: 'counterparty_name',
    minWidth: 200,
    headerName: 'Counterparty Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Counterparty Name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.counterparty_name || ''}
          value="counterparty_name"
          placeholder="Counterparty Name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', whiteSpace: 'pre-wrap' }}
      >
        {row.counterparty_name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'invoice_number',
    minWidth: 200,
    headerName: 'Invoice Number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoice Number</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.invoice_number || ''}
          value="invoice_number"
          placeholder="Invoice Number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', }}
      >
        {row.invoice_number}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'invoice_date',
    minWidth: 200,
    headerName: 'Invoice Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoice Date</Typography>
        <FiltrationDate
          value={filters.invoice_date || null}
          onChange={(e) => {
            handleFilter(e, 'invoice_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', }}
      >
        {row.invoice_date}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'invoice_amount',
    minWidth: 200,
    headerName: 'Invoice Amount',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoice Amount</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.invoice_amount || ''}
          value="invoice_amount"
          placeholder="Invoice Number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', }}
      >
        {row.invoice_amount}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'invoice_currency',
    minWidth: 200,
    headerName: 'Invoice Currency',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoice Currency</Typography>
        <FiltrationSelect
          options={currencyOptions || []}
          defaultValue={filters.invoice_currency || ''}
          handleFilter={handleFilter}
          placeholder="Invoice Currency"
          value="invoice_currency"
        />
      </Box>
    ),
    renderCell: ({ row }) => {
      const text = currencyOptions.find(item => +item.value === row.payment_currency);

      return (
        <Typography
          variant="subtitle1"
          noWrap
          sx={{ textTransform: 'capitalize' }}
        >
          {text?.label || '-'}
        </Typography>
      );
    },
  },
  {
    flex: 0.2,
    field: 'tmts',
    minWidth: 200,
    headerName: 'TMTS',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>TMTS</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.tmts || ''}
          value="tmts"
          placeholder="TMTS"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', }}
      >
        {row.tmts}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'department',
    minWidth: 200,
    headerName: 'Department',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Department</Typography>
        <FiltrationSelect
          options={departmentsOptions || []}
          defaultValue={filters.department || ''}
          handleFilter={handleFilter}
          placeholder="Department"
          value="department"
        />
      </Box>
    ),
    renderCell: ({ row }) => {
      const text = departmentsOptions.find(item => +item.value === row.department);

      return (
        <Typography
          variant="subtitle1"
          noWrap
          sx={{ textTransform: 'capitalize', }}
        >
          {text?.label || '-'}
        </Typography>
      );
    },
  },
  {
    flex: 0.2,
    field: 'aircraft_type',
    minWidth: 220,
    headerName: 'Aircraft Type',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Aircraft Type</Typography>
        <FiltrationSelect
          options={aircraftTypeOptions || []}
          defaultValue={filters.aircraft_type || null}
          handleFilter={handleFilter}
          getData={getAir}
          placeholder="Aircraft Type"
          value="aircraft_type"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', }}
      >
        {row.aircraft_type || '-'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'aircraft',
    minWidth: 200,
    headerName: 'Aircraft',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Typography style={HeaderName}>Aircraft</Typography>
        <FiltrationSelect
          options={filters.aircraft_type ? aircraftOptions : []}
          defaultValue={filters.aircraft || ''}
          disabled={!filters.aircraft_type}
          handleFilter={handleFilter}
          placeholder="Aircraft"
          value="aircraft"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', }}
      >
        {row?.aircraft || '-'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'invoice_sender_department',
    minWidth: 220,
    headerName: 'Sender Department',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Sender Department</Typography>
        <FiltrationSelect
          options={departmentsOptions || []}
          defaultValue={filters.invoice_sender_department || ''}
          handleFilter={handleFilter}
          placeholder="Sender Department"
          value="invoice_sender_department"
        />
      </Box>
    ),
    renderCell: ({ row }) => {
      const text = departmentsOptions.find(item => +item.value === row.invoice_sender_department);

      return (
        <Typography
          variant="subtitle1"
          noWrap
          sx={{ textTransform: 'capitalize', }}
        >
          {text?.label || '-'}
        </Typography>
      );
    },
  },
  {
    flex: 0.2,
    field: 'flight',
    minWidth: 200,
    headerName: 'Flight',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Typography style={HeaderName}>Flight</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.flight || ''}
          value="flight"
          placeholder="Flight Url"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', }}
      >
        {row.flight_id ? (
          <LinkButton
            target="_blank"
            path={`/flights/${row.flight_id}`}
          >
            {row?.flight || '-'}
          </LinkButton>
        ) : '-'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'document_url',
    minWidth: 200,
    headerName: 'Document Url',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Document Url</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.document_url || ''}
          value="document_url"
          placeholder="Document Url"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', color: 'rgba(0, 70, 136, 0.8)' }}
      >
        {row.document_url ? (
          <LinkButton
            target="_blank"
            path={row.document_url}
          >
            File link
          </LinkButton>
        ) : '-'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'comment',
    minWidth: 200,
    headerName: 'Comment',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Comment</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.comment || ''}
          value="comment"
          placeholder="Comment"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', whiteSpace: 'pre-wrap' }}
      >
        {row.comment}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'executor',
    minWidth: 200,
    headerName: 'Executor',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Executor</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.executor || ''}
          value="executor"
          placeholder="Executor"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', whiteSpace: 'pre-wrap' }}
      >
        {row.executor}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'payment_order_number',
    minWidth: 200,
    headerName: 'Payment Order Number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Payment Order Number</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.payment_order_number || ''}
          value="payment_order_number"
          placeholder="Payment Order Number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', whiteSpace: 'pre-wrap' }}
      >
        {row.payment_order_number}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'incoming_document_number',
    minWidth: 240,
    headerName: 'Incoming Document Number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Incoming Document Number</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.incoming_document_number || ''}
          value="incoming_document_number"
          placeholder="Incoming Document Number"
        />
      </Box>
    ),

    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', whiteSpace: 'pre-wrap' }}
      >
        {row.incoming_document_number}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'invoice_url',
    minWidth: 200,
    headerName: 'Invoice Url',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoice Url</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.invoice_url || ''}
          value="invoice_url"
          placeholder="Invoice Url"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', color: 'rgba(0, 70, 136, 0.8)' }}
      >
        {row.invoice_url ? (
          <LinkButton
            target="_blank"
            path={row.invoice_url}
          >
            Invoice link
          </LinkButton>
        ) : '-'}
      </Typography>
    ),
  },
]);

import {
  Box,
  Chip,
  CircularProgress,
  Typography
} from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis, YAxis
} from 'recharts';
import { useGetStatisticByProfitMutation } from '../../../../store/session';

const Chart = () => {
  const [getStatistic, { data, isLoading: isStatisticLoading, reset }] = useGetStatisticByProfitMutation({
    fixedCacheKey: 'get-statistic-by-profit',
  });

  let chartValue = data ? Object.entries(data?.chartData).map(([date, entry]) => ({ date, profit: entry.profit })) : [];

  chartValue = chartValue.sort((a, b) => moment(a.date, 'DD MMM YYYY').diff(moment(b.date, 'DD MMM YYYY')));

  useEffect(() => {
    reset();
  }, []);

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'
    }}
    >
      <Box sx={{ p: '10px', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">
          PROFIT CHART
        </Typography>
        {data ? (
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Typography variant="h6">
              {`${data.overall.total_profit} €`}
            </Typography>
            <Chip label={`${data.overall.total_profit_percent}%`} color={data.overall.total_profit_percent ? 'success' : 'error'} />
          </Box>
        ) : null}
      </Box>
      <Box sx={{ height: '300px' }}>
        {isStatisticLoading ? (
          <Box sx={{
            display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'
          }}
          >
            <CircularProgress sx={{
              width: '100%', display: 'flex', justifyContent: 'center'
            }}
            />
          </Box>
        ) : null}
        {data ? (
          <ResponsiveContainer>
            <LineChart
              height={300}
              data={chartValue}
              margin={{
                left: 20,
                right: 10,
                bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="0" />
              <XAxis
                dataKey="date"
                name="Date"
                label={{
                  value: 'Date',
                  position: 'insideBottom',
                  offset: -10,
                }}
                tickFormatter={(value) => moment(value, 'DD MMM YYYY').format('DD/MM')}
              />
              <YAxis
                dataKey="profit"
              />
              <Tooltip
                formatter={(value) => [`${value} €`, 'Profit']}
              />
              <Line strokeWidth={2} dataKey="profit" stroke="#1a4671" dot={false} />
            </LineChart>
          </ResponsiveContainer>
        ) : null}
      </Box>
    </Box>

  );
};

export default Chart;

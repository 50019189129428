export const TYPE_OF_PAYMENTS = [
  { value: 'FOP', label: 'FOP' },
  { value: 'Contract with Fleet Air', label: 'Contract with Fleet Air' },
  { value: 'Contract', label: 'Contract' },
  { value: 'СС', label: 'СС' },
];

export const RATE_TYPE_MONTH = 'month';

export const RATE_TYPE_DUTY = 'duty';

export const RATE_TYPES = [
  { value: RATE_TYPE_MONTH, label: 'Month' },
  { value: RATE_TYPE_DUTY, label: 'Duty' },
];

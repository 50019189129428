export const header = {
  color: '#616161',
  fontWeight: '600 !important',
  whiteSpace: 'nowrap',
  minWidth: '125px',
  textTransform: 'capitalize !important',
};

export const body = {
  minWidth: '125px',
  fontSize: '13px !important',
  'input': {
    fontSize: '13px !important',
  },
  '& input::placeholder': {
    fontSize: '13px'
  }
};

export const blockTextarea = {
  maxWidth: '400px'
};

export const textarea = {
  padding: '10px 14px',
  borderRadius: '8px',
  color: 'rgba(76, 78, 100, 0.87)',
  border: '1px solid rgba(76, 78, 100, 0.22)',
  minWidth: '200px',
  maxWidth: '200px',
  marginTop: '8px',
};

export const input = {
  minWidth: '135px',
  '& .MuiFormLabel-root': {
    fontSize: '14px',
  },
};

export const cellWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  justifyContent: 'space-between'
};

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetFuelPaymentMutation,
  useGetFuelSuppliersListQuery,
  useUpdateFuelPaymentMutation,
  useStoreFuelPaymentMutation
} from '../../../store/session';
import {
  Button,
  Card, CardContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { schema } from './schema';
import FormGroup from '../../../form/components/FormGroup';
import DatePicker from '../../../form/components/DatePicker';
import FieldError from '../../../form/components/FieldError';
import moment from 'moment/moment';
import { selectOptionFormat } from '../../../utils/number';
import Select from '../../../form/components/Select';
import Input from '../../../form/components/Input';
import toast from 'react-hot-toast';

const defaultValues = {
  supplier_id: '',
  date: '',
  sum: '',
  notes: ''
};

const FuelPaymentForm = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const update = id !== 'create';
  const [getFuelPayment] = useGetFuelPaymentMutation();
  const [updateFuelPayment] = useUpdateFuelPaymentMutation();
  const [storeFuelPayment] = useStoreFuelPaymentMutation();
  const { data: SupplierList, isLoading: supplierListLoad } = useGetFuelSuppliersListQuery();

  const formattedSupplierList = selectOptionFormat(SupplierList, 'fuel_suplier');

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const setFuelPaymentValues = async () => {
    setLoading(true);
    const { data: result } = await getFuelPayment(id);

    return result;
  };

  useEffect(() => {
    if (update) {
      setFuelPaymentValues().then((result) => {
        if (result) {
          setLoading(false);
          setValue('date', moment(result.date).toDate());
          setValue('supplier_id', result.supplier_id);
          setValue('sum', result.sum);
          setValue('notes', result.notes);
        }
      });
    }
  }, [update]);

  const onSubmit = (data) => {
    const updateValues = {
      ...data,
      date: moment(data.date).format('YYYY-MM-DD')
    };

    if (update) {
      const result = updateFuelPayment({ data: updateValues, id });

      if (!result?.error) {
        toast.success('Successfully updated!');
        navigate('/fuel/payments');
      }

      return;
    }

    const result = storeFuelPayment(updateValues);

    if (!result?.error) {
      toast.success('Fuel Payment created!');
      navigate('/fuel/payments');
    }
  };

  if (isLoading || supplierListLoad) {
    return <ContentPageLoader />;
  }

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h3" noWrap sx={{ textTransform: 'capitalize' }}>
                {update ? 'Update Fuel Payment' : 'Create Fuel Payment'}
              </Typography>
              <Divider />
              <Grid item xs={12} sm={6}>
                <FormGroup label="Date" required hasError={!!errors.date}>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="YYYY-MM-DD"
                        dateFormat="yyyy-MM-dd"
                        showMonthDropdown
                        showYearDropdown
                        {...field}
                      />
                    )}
                  />
                  <FieldError error={errors.date} />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup label="Supplier" required hasError={!!errors.supplier_id}>
                  <Controller
                    name="supplier_id"
                    control={control}
                    render={({ field: { onChange, ...req } }) => (
                      <Select
                        options={formattedSupplierList}
                        placeholder="Supplier"
                        {...req}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        disabled={supplierListLoad}
                      />
                    )}
                  />

                  <FieldError error={errors.supplier_id} />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup required label="Sum, USD" hasError={!!errors.sum}>
                  <Controller
                    name="sum"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Sum, USD"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.sum} />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup label="Notes" hasError={!!errors.notes}>
                  <Controller
                    name="notes"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Notes"
                        multiline
                        rows={4}
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.notes} />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" size="large">{update ? 'Update' : 'Save' }</Button>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default FuelPaymentForm;

import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetInstructorDocMutation,
  useCreateInstructorDocMutation,
  useDeleteInstructorDocMutation,
} from '../../../../store/session';
import { useMenuToggle } from '../../../../utils/hooks/useMenuToggle';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import toast from 'react-hot-toast';

export const useInstructorPage = () => {
  const maxWidth1000px = useMediaQuery('(max-width:1275px)');
  const maxWidth733px = useMediaQuery('(max-width:733px)');
  const params = useParams();

  const [selectedRow, setSelectedRow] = useState(null);
  const [refetch, setRefetch] = useState(0);

  const useRefetch = () => {
    setRefetch(refetch + 1);
  };

  const instructorId = params.id;

  const hasPermissionsOdsCreate = usePermissions('ods_creator');

  const {
    open: openFile,
    openMenu: openMenuFile,
    closeMenu: closeMenuFile,
    anchorEl: anchorElFile,
  } = useMenuToggle();

  const [getFile, { isLoading: FileLoading }] = useGetInstructorDocMutation();
  const [onUpdateFile, { isSuccess: FileSuccess }] = useCreateInstructorDocMutation();
  const [onDeleteFile, { isSuccess: FileSuccessDelete }] = useDeleteInstructorDocMutation();

  const handlePreviewFile = async (id) => {
    const toastId = toast.loading('Loading...');

    await getFile(id).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleUpdateFile = async (id, file) => {
    const toastId = toast.loading('Loading...');
    const formData = new FormData();
    formData.append('file', file);

    const requestObj = { id, data: formData };
    await onUpdateFile(requestObj).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        useRefetch();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleDeleteFile = async (id) => {
    const toastId = toast.loading('Loading...');
    await onDeleteFile(id).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        useRefetch();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleOpenDropDownFile = (e, values) => {
    setSelectedRow(values);
    openMenuFile(e);
  };

  return {
    maxWidth1000px,
    maxWidth733px,
    instructorId,
    selectedRow,
    openFile,
    closeMenuFile,
    anchorElFile,
    handleOpenDropDownFile,
    handlePreviewFile,
    FileLoading,
    handleUpdateFile,
    FileSuccess,
    handleDeleteFile,
    hasPermissionsOdsCreate,
    refetch,
    useRefetch,
    toast
  };
};

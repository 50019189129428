import { useGetTrainingEventsCalendarQuery } from '../../../../store/session';
import {
  PREV_MONTH, CURR_MONTH, NEXT_MONTH, date_start, date_end
} from './constants';

export const useEventsCalendar = () => {
  const { data: events, error, isLoading } = useGetTrainingEventsCalendarQuery({ date_start, date_end });

  // TODO: Error handling

  return {
    events,
    isLoading,
    PREV_MONTH,
    CURR_MONTH,
    NEXT_MONTH,
  };
};

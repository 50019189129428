import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import PdfLogo from '../../../components/PdfLogo';
import { useUpdateOdsMutation } from '../../../store/session';
import Actions from '../OdsForm/Actions';
import Arrival from '../OdsForm/Arrival';
import Crew from '../OdsForm/Crew';
import DeIceing from '../OdsForm/DeIceing';
import Defects from '../OdsForm/Defects';
import Departure from '../OdsForm/Departure';
import Flight from '../OdsForm/Flight';
import FuelRamp from '../OdsForm/FuelRamp';
import InstrumentsRegardings from '../OdsForm/InstrumentsRegardings';
import Load from '../OdsForm/Load';
import OilUplift from '../OdsForm/OilUplift';
import PaxInformation from '../OdsForm/PaxInformation';
import PreviousHrs from '../OdsForm/PreviousHrs';
import Remarks from '../OdsForm/Remarks';
import TotalHrs from '../OdsForm/TotalHrs';
import Weight from '../OdsForm/Weight';
import { defaultValues, schema } from '../OdsForm/schema';
import { cardsContainer } from './styles';

export const OdsFormLayoutNonAuthorized = ({
  isTypeF,
  data,
  isSubmit,
  setIsSubmit,
  setIsLoading,
  setNavItems,
  hasPermissionsOdsEdit,
  airportList,
  odsStatusesOptions,
  isOffline,
  odsTocOptions,
}) => {
  const [update, { isLoading }] = useUpdateOdsMutation();

  const headingRefs = useRef({});

  const [savedData, setSavedData] = useState(JSON.parse(localStorage.getItem(`${data.index}`)));
  const [init, setInit] = useState(true);

  const {
    control,
    setValue,
    getValues,
    watch,
    trigger,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: isTypeF && yupResolver(schema)
  });

  useEffect(() => {
    if (!init) {
      trigger('fuel_arrival_kg');
    }

    setInit(false);
  }, [watch('fuel_departure_kg'), init]);

  const onSubmit = async (values) => {
    if (isOffline) {
      localStorage.setItem(`${values.index}`, JSON.stringify(values));

      toast.error(
        'Data saved locally. Don\'t reload the page and press \'Save\' when the internet connection is available.',
        {
          duration: 10000,
        }
      );

      return;
    }

    const toastId = toast.loading('Loading...');

    const formattedData = Object.keys(dirtyFields).reduce((res, key) => ({ ...res, [key]: values[key] }), {});

    if (+data.fuel_uplift_kg !== +values.fuel_uplift_kg) {
      formattedData.fuel_uplift_kg = values.fuel_uplift_kg;
    }

    if (+data.fuel_departure_kg !== +values.fuel_departure_kg) {
      formattedData.fuel_departure_kg = values.fuel_departure_kg;
    }
    formattedData.is_mail_form = true;

    const res = await update({ data: formattedData, id: values.id });

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong!', {
        id: toastId,
      });

      return;
    }

    localStorage.removeItem(`${values.index}`);

    toast.success('Saved successfully!', {
      id: toastId,
      position: 'top-center',
      style: {
        border: '1px solid #61d345',
        padding: '16px',
        color: '#61d345',
        maxWidth: '300px',
        width: '100%',
      },
      iconTheme: {
        primary: '#61d345',
        secondary: '#FFFAEE',
      },
    });
  };

  useEffect(() => {
    if (data?.ods_status !== 3) {
      localStorage.removeItem(`${data.index}`);
      setSavedData(null);
    }

    if (data) {
      if (data?.ods_status === 3) {
        localStorage.setItem(`${data.index}`, JSON.stringify(data));
      }

      Object.entries(data).forEach(
        ([name, value]) => {
          if (['load_cargo', 'load_drg', 'load_pax'].includes(name)) {
            setValue(name, value || 0);
          } else {
            setValue(name, value);
          }
        }
      );

      trigger('aircraft_registration');
    } else if (savedData && data?.ods_status === 3) {
      Object.entries(savedData).forEach(
        ([name, value]) => {
          if (['load_cargo', 'load_drg', 'load_pax'].includes(name)) {
            setValue(name, value || 0);
          } else {
            setValue(name, value);
          }
        }
      );

      trigger('aircraft_registration');
    }
  }, [data]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit(onSubmit)();
      setIsSubmit(false);
    }
  }, [isSubmit]);

  useEffect(() => {
    const { fuel_density, fuel_uplift, previous_fuel_arrival_kg } = getValues();
    const uplift = fuel_density * fuel_uplift;

    setValue('fuel_uplift_kg', uplift);
    setValue('fuel_departure_kg', uplift + +(previous_fuel_arrival_kg || 0), { shouldDirty: true });
  }, [watch('fuel_density'), watch('fuel_uplift')]);

  const definedRef = useCallback(
    (node) => {
      if (node !== null) {
        headingRefs.current[node.id] = { current: node };
        const navItem = {
          text: data.index,
          id: node.id,
          ref: headingRefs.current[node.id]
        };

        setNavItems((prevNavItems) => prevNavItems.concat([navItem]));
      }
    },
    [setNavItems]
  );

  useEffect(() => {
    Object.values(errors).map(item => toast.error(item.message, {
      style: {
        border: '1px solid red',
        padding: '16px',
        color: 'red',
        maxWidth: '300px',
        width: '100%',
      },
      iconTheme: {
        primary: 'red',
        secondary: '#FFFAEE',
      },
    }));
  }, [errors]);

  return (
    <Box
      sx={{
        padding: '2rem',
        minHeight: 'calc(100vh - 200px)',
        background: 'white',
        mb: '20px',
      }}
      ref={definedRef}
      id={data.index}
    >
      <PdfLogo title="OPERATIONAL DATA SHEET" />
      <Box sx={cardsContainer}>
        <Flight
          odsStatusesOptions={odsStatusesOptions}
          odsTocOptions={odsTocOptions}
          setValue={setValue}
          control={control}
          isTypeF={isTypeF}
          getValues={getValues}
          errors={errors}
          isPanel
        />
        {isTypeF && (
          <>
            <Departure airportList={airportList} setValue={setValue} control={control} errors={errors} isPanel />
            <Arrival airportList={airportList} setValue={setValue} control={control} errors={errors} isPanel />
          </>
        )}
        <PreviousHrs control={control} isPanel />
        {isTypeF && (
          <>
            <TotalHrs getValues={getValues} isPanel />
            <Crew isOffline={isOffline} control={control} isPanel />
            <Load control={control} isPanel errors={errors} />
            <FuelRamp control={control} isPanel errors={errors} />
            <Weight control={control} isPanel errors={errors} />
            <PaxInformation control={control} isPanel />
            <DeIceing control={control} isPanel />
          </>
        )}
        <OilUplift control={control} isPanel />
        {isTypeF && <InstrumentsRegardings control={control} isPanel />}
        <Remarks control={control} isPanel />
        {/* <LCCheck control={control} isPanel getMainValues={getValues} /> */}
        <Actions savedData={savedData} isOffline={isOffline} hasPermissionsOdsEdit={hasPermissionsOdsEdit} control={control} isPanel getMainValues={getValues} setValue={setValue} />
        <Defects savedData={savedData} isOffline={isOffline} hasPermissionsOdsEdit={hasPermissionsOdsEdit} control={control} isPanel getMainValues={getValues} setValue={setValue} />
      </Box>
    </Box>
  );
};

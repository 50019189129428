import { object, string } from 'yup';

export const defaultValues = {
  wo_item_text: '',
  quantity: '',
};

export const schema = object().shape({
  wo_item_text: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Wo item text'),
  quantity: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('QTY'),
});

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Card,
  Button as CommonButton,
  DialogContent,
  Divider,
  Grid,
  useMediaQuery
} from '@mui/material';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React from 'react';
import Button from '../../../../../../components/Button';
import {
  BtnActive,
  BtnGroup, BtnGroupXs,
  ButtonContainer,
  ButtonContainerX,
  ButtonContainerXs, DefaultBtn, DefaultBtnXs
} from '../../../../../../components/ButtonSelect/styles';
import DeleteModal from '../../../../../../components/DeleteModal';
import SkeletonGrid from '../../../../../../components/Skeleton/SkeletonGrid';
import Upload from '../../../../../../components/Upload';
import Select from '../../../../../../form/components/Select';
import { StyledDelete } from '../../../../../CrewDoc/styles';
import { RedButton, TextWithIcon, YellowButton } from '../../../../../Logistics/style';
import TransactionsModal from '../../TransactionsModal';
import { useMonthReport } from './useMonthReport';

const Monthly = ({ bankList }) => {
  const maxWidth1040px = useMediaQuery('(max-width:1225px)');
  const maxWidth500px = useMediaQuery('(max-width:500px)');
  const months = moment.months();
  const cardHeight = '220px';

  const {
    years,
    year,
    handleSetYear,
    accountList,
    accountsLoading,
    bank,
    bankName,
    setBank,
    setBankName,
    account,
    setAccount,
    reportsLoading,
    handleMonthData,
    handleUploadTransactions,
    handleDeleteReport,
    openDeleteModal,
    handleCloseDeleteModel,
    deleteFile,
    handleDownloadFile,
    fileDownloading,
    handleUploadPdf,
    pdfLoading,
    handleDownloadPdf,
    pdfDownloadLoading,
    openDeletePdfModal,
    handleCloseDeletePdfModal,
    handleOpenDeletePdfModal,
    handleDeleteMonthlyPdf,
    openTransactionsModal,
    transactions,
    handleOpenTransactionsModal,
    handleCloseTransactionsModal,
  } = useMonthReport();

  return (
    <DialogContent>
      <Card sx={{ padding: '1rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Typography variant="h5">
              Bank
            </Typography>
            <Select
              placeholder="Select Bank"
              options={bankList}
              onChange={(e) => {
                setBank(e.target.value);
                const selectBank = bankList.find((item) => item.value === e.target.value);
                setBankName(selectBank.label);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Typography variant="h5">
              Account Number
            </Typography>
            <Select
              placeholder="Select Account Number"
              options={accountList}
              disabled={accountsLoading || !bank}
              onChange={(e) => {
                setAccount(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Typography variant="h5">
              Year
            </Typography>
            <Box
              sx={{
                ...ButtonContainer,
                ...(maxWidth1040px && ButtonContainerX),
                ...(maxWidth500px && ButtonContainerXs),
              }}
            >
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                sx={{ ...BtnGroup, ...(maxWidth500px && BtnGroupXs) }}
              >
                {years.map((item, id) => (
                  <CommonButton
                    onClick={() => handleSetYear(item)}
                    sx={{
                      ...DefaultBtn,
                      ...(maxWidth500px && DefaultBtnXs),
                      ...(year.includes(item) && BtnActive)
                    }}
                    key={id}
                  >
                    {item}
                  </CommonButton>
                ))}
              </ButtonGroup>
            </Box>
          </Grid>
        </Grid>

        <Divider />

        {(!bank || !account) ? (
          <Box sx={{ textAlign: 'center', pt: 5 }}>
            <Typography>
              Please, select bank and account number to see the grid.
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={2} sx={{ pt: 5 }}>
            {reportsLoading ? (
              <>
                <SkeletonGrid itemCount={4} height={cardHeight} />
                <SkeletonGrid itemCount={4} height={cardHeight} />
                <SkeletonGrid itemCount={4} height={cardHeight} />
              </>
            ) : (
              <>
                {months.map((month, index) => {
                  const data = handleMonthData(month);

                  return (
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                      <Card sx={{
                        bgcolor: data ? 'whitesmoke' : '#69c68b1a',
                        borderRadius: 0,
                        padding: '1rem',
                        height: cardHeight
                      }}
                      >
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 3
                        }}
                        >
                          <Typography variant="h5">
                            {month}
                          </Typography>

                          {data && (
                            <CommonButton
                              sx={RedButton}
                              startIcon={<DeleteIcon />}
                              size="small"
                              onClick={() => {
                                handleDeleteReport(data?.id);
                              }}
                            >
                              Delete
                            </CommonButton>
                          )}
                        </Box>

                        <Divider />

                        {!data ? (
                          <Box sx={{ textAlign: 'center', pt: 2, height: '100%' }}>
                            {(bankName === 'TAKAREK FleetAir' || bankName === 'TAKAREK Pannonia') ? (
                              <Upload
                                sx={{ mb: 2 }}
                                accept=".pdf"
                                title="Upload PDF"
                                disabled={pdfLoading(null)}
                                icon={<UploadIcon />}
                                handleUpload={(e) => {
                                  handleUploadPdf(e, null, month);
                                }}
                              />
                            ) : null}
                            <Upload
                              title="Upload Transactions"
                              handleUpload={(e) => {
                                handleUploadTransactions(e, month);
                              }}
                            />
                          </Box>
                        ) : (
                          <Grid container spacing={2}>
                            {data?.duplicated_transactions_count && (
                              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                                <Typography variant="subtitle1">
                                  <Chip
                                    label={`Transactions Found: ${data?.duplicated_transactions_count || 0}`}
                                    onClick={() => handleOpenTransactionsModal(data?.duplicated_transactions)}
                                    icon={<CheckIcon />}
                                    variant="outlined"
                                    color="primary"
                                  />
                                </Typography>
                              </Grid>
                            )}
                            {data?.new_transactions_count && (
                              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                                <Chip
                                  label={`New Transactions: ${data?.new_transactions_count || 0}`}
                                  onClick={() => handleOpenTransactionsModal(data?.new_transactions)}
                                  icon={<AddIcon />}
                                  variant="outlined"
                                  color="primary"
                                />
                              </Grid>
                            )}

                            <Grid item xs={12} sx={{ textAlign: 'center', mt: 5 }}>
                              {!data?.pdf ? (
                                <Upload
                                  accept=".pdf"
                                  title="Upload PDF"
                                  disabled={pdfLoading(data?.id)}
                                  icon={<UploadIcon />}
                                  handleUpload={(e) => {
                                    handleUploadPdf(e, data?.id);
                                  }}
                                />
                              ) : (
                                <Box sx={{ ...TextWithIcon, justifyContent: 'center' }}>
                                  <Button
                                    startIcon={<DownloadIcon />}
                                    title="Download PDF"
                                    sx={YellowButton}
                                    size="small"
                                    disabled={pdfDownloadLoading(data?.id)}
                                    onClick={() => {
                                      handleDownloadPdf(data?.id);
                                    }}
                                  />

                                  <StyledDelete
                                    onClick={() => handleOpenDeletePdfModal(data?.id)}
                                  />
                                </Box>

                              )}
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                              {data?.file_name ? (
                                <Button
                                  startIcon={<DownloadIcon />}
                                  title="Download Transactions"
                                  size="small"
                                  loading={fileDownloading(data?.id)}
                                  onClick={() => handleDownloadFile(data?.id, data?.file_name)}
                                />
                              ) : (
                                <Upload
                                  title="Upload Transactions"
                                  handleUpload={(e) => {
                                    handleUploadTransactions(e, month, data?.id);
                                  }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </Card>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        )}
      </Card>

      <DeleteModal open={openDeleteModal} onClose={handleCloseDeleteModel} onDelete={deleteFile} />
      <DeleteModal open={openDeletePdfModal} onClose={handleCloseDeletePdfModal} onDelete={handleDeleteMonthlyPdf} />
      <TransactionsModal open={openTransactionsModal} onClose={handleCloseTransactionsModal} transactions={transactions} />
    </DialogContent>
  );
};

export default Monthly;

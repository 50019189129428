// ** React Imports
import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';

// ** MUI Imports
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';

// ** Third Party Imports
import clsx from 'clsx';
import { usePopper } from 'react-popper';

// ** Icons Imports
import ChevronDown from 'mdi-material-ui/ChevronDown';
import ChevronRight from 'mdi-material-ui/ChevronRight';

// ** Theme Config Import
import themeConfig from '../../themes/themeConfig';

// ** Custom Components Imports
import UserIcon from './UserIcon';

// ** Utils
import { hexToRGBA } from '../../utils/hex-to-rgba';
import { hasActiveChild } from '../../utils/utils';
// eslint-disable-next-line import/no-cycle
import HorizontalNavItems from './HorizontalNavItems';

import { AccordionDetails, AccordionSummary } from '@mui/material';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { AccordionStyled } from './styles';

// ** Styled Components
const ListItem = styled(MuiListItem)(({ theme }) => ({
  cursor: 'pointer',
  paddingTop: theme.spacing(2.25),
  paddingBottom: theme.spacing(2.25),
  '&:hover': {
    background: theme.palette.action.menuHover,
  },
}));

const NavigationMenu = styled(Paper)(({ theme }) => ({
  overflowY: 'auto',
  padding: theme.spacing(2, 0),
  backgroundColor: theme.palette.background.paper,
  ...(themeConfig.menuTextTruncate ? { width: 260 } : { minWidth: 260 }),
  '&::-webkit-scrollbar': {
    width: 6,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 20,
    background: hexToRGBA(theme.palette.mode === 'light' ? '#BFBFD5' : '#57596C', 0.6),
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: 20,
    background: 'transparent',
  },
  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .menu-group.Mui-selected': {
    borderRadius: 0,
    backgroundColor: theme.palette.action.hover,
  },
}));

const HorizontalNavGroup = (props) => {
  // ** Props
  const { item, hasParent, isMobile = false } = props;

  // ** Hooks & Vars
  const theme = useTheme();
  const {
    navSubItemIcon, menuTextTruncate, horizontalMenuToggle, horizontalMenuAnimation,
  } = themeConfig;
  const popperOffsetHorizontal = -16;
  const popperPlacement = 'bottom-start';
  const popperPlacementSubMenu = 'right-start';

  // ** States
  const [menuOpen, setMenuOpen] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: hasParent ? popperPlacementSubMenu : popperPlacement,
    modifiers: [
      {
        enabled: true,
        name: 'offset',
        options: {
          offset: hasParent ? [-8, 15] : [popperOffsetHorizontal, 5],
        },
      },
    ],
  });

  const hasPermissions = usePermissions(item.permissions);

  const handleGroupOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleGroupClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleMenuToggleOnClick = (event) => {
    if (anchorEl) {
      handleGroupClose();
    } else {
      handleGroupOpen(event);
    }
  };
  // useEffect(() => {
  //   handleGroupClose();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [router.asPath]);
  const IconTag = item.icon ? item.icon : navSubItemIcon;
  const ToggleIcon = ChevronRight;
  const WrapperCondition = horizontalMenuToggle === 'click';
  const MainWrapper = WrapperCondition ? ClickAwayListener : 'div';
  const ChildWrapper = WrapperCondition ? 'div' : Fragment;
  const AnimationWrapper = horizontalMenuAnimation ? Fade : Fragment;

  // eslint-disable-next-line consistent-return
  const childMenuGroupStyles = () => {
    if (attributes && attributes.popper) {
      if (attributes.popper['data-popper-placement'] === 'right-start') {
        return 'right';
      }
      if (attributes.popper['data-popper-placement'] === 'left-start') {
        return 'left';
      }
    }
  };

  const location = useLocation();
  const currentURL = location.pathname;

  return (
    <MainWrapper {...(WrapperCondition ? { onClickAway: handleGroupClose } : { onMouseLeave: handleGroupClose })}>
      <ChildWrapper>
        {!isMobile && (
        <List component="div" sx={{ py: 2.75 }}>
          {hasPermissions && (
          <ListItem
            aria-haspopup="true"
            {...(WrapperCondition ? {} : { onMouseEnter: handleGroupOpen })}
            className={clsx('menu-group', { 'Mui-selected': hasActiveChild(item, currentURL) })}
            {...(horizontalMenuToggle === 'click' ? { onClick: handleMenuToggleOnClick } : {})}
            sx={{
              ...(menuOpen ? { backgroundColor: theme.palette.action.hover } : {}),
              ...(!hasParent
                ? {
                  borderRadius: '8px',
                  '& .MuiTypography-root, & .MuiListItemIcon-root, & .MuiSvgIcon-root': {
                    color: 'common.white',
                  },
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.customColors.textPrimary,
                    '& .MuiTypography-root, & .MuiListItemIcon-root, & .MuiSvgIcon-root': {
                      color: 'common.white',
                    },
                  },
                }
                : {}),
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              ref={setReferenceElement}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  ...(menuTextTruncate && { overflow: 'hidden' }),
                }}
              >
                <ListItemIcon sx={{ mr: hasParent ? 3 : 2.5, color: 'text.primary' }}>
                  <UserIcon
                    icon={IconTag}
                    componentType="horizontal-menu"
                    iconProps={{ sx: IconTag === navSubItemIcon ? { fontSize: '0.5rem' } : {} }}
                  />
                </ListItemIcon>
                <Typography {...(menuTextTruncate && { noWrap: true })}>
                  {item.title}
                </Typography>
              </Box>
              <Box sx={{ ml: 1.5, display: 'flex', alignItems: 'center' }}>
                {item.badgeContent ? (
                  <Chip
                    size="small"
                    label={item.badgeContent}
                    color={item.badgeColor || 'primary'}
                    sx={{ mr: 0.75, '& .MuiChip-label': { px: 2.5, lineHeight: 1.385, textTransform: 'capitalize' } }}
                  />
                ) : null}
                {hasParent ? (
                  <ToggleIcon sx={{ color: 'text.secondary' }} />
                ) : (
                  <ChevronDown sx={{ color: 'text.secondary' }} />
                )}
              </Box>
            </Box>
          </ListItem>
          )}  
          <AnimationWrapper {...(horizontalMenuAnimation && { in: menuOpen, timeout: { exit: 300, enter: 400 } })}>
            <Box
              style={styles.popper}
              ref={setPopperElement}
              {...attributes.popper}
              sx={{
                zIndex: theme.zIndex.appBar,
                ...(!horizontalMenuAnimation && { display: menuOpen ? 'block' : 'none' }),
                pl: childMenuGroupStyles() === 'left' ? 1.25 : 0,
                pr: childMenuGroupStyles() === 'right' ? 1.25 : 0,
                ...(hasParent ? { position: 'fixed !important' } : { pt: 5.5 }),
              }}
            >
              <NavigationMenu
                sx={{
                  ...(hasParent
                    ? { overflowX: 'visible', maxHeight: 'calc(100vh - 21rem)' }
                    : { maxHeight: 'calc(100vh - 13rem)' }),
                }}
              >
                {hasPermissions && <HorizontalNavItems {...props} hasParent navList={item.children} />}
              </NavigationMenu>
            </Box>
          </AnimationWrapper>
          
        </List>
        )}
        {(isMobile && hasPermissions) && (
          <ListItem
            aria-haspopup="true"
            {...(WrapperCondition ? {} : { onMouseEnter: handleGroupOpen })}
            className={clsx('menu-group', { 'Mui-selected': hasActiveChild(item, currentURL) })}
            {...(horizontalMenuToggle === 'click' ? { onClick: handleMenuToggleOnClick } : {})}
            sx={{
              paddingLeft: '0',
              ...(menuOpen ? { backgroundColor: theme.palette.action.hover } : {}),
              ...(!hasParent
                ? {
                  '& .MuiTypography-root, & .MuiListItemIcon-root, & .MuiSvgIcon-root': {
                    color: 'common.white',
                  },
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.customColors.textPrimary,
                    '& .MuiTypography-root, & .MuiListItemIcon-root, & .MuiSvgIcon-root': {
                      color: 'common.white',
                    },
                  },
                }
                : {}),
            }}
          >
            <AccordionStyled sx={{
              boxShadow: 'none',
              borderRadius: '0'
            }}
            >
              <AccordionSummary>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      ...(menuTextTruncate && { overflow: 'hidden' }),
                    }}
                  >
                    <ListItemIcon sx={{ mr: hasParent ? 3 : 2.5, color: 'text.primary' }}>
                      <UserIcon
                        icon={IconTag}
                        componentType="horizontal-menu"
                        iconProps={{ sx: IconTag === navSubItemIcon ? { fontSize: '0.5rem' } : {} }}
                      />
                    </ListItemIcon>
                    <Typography {...(menuTextTruncate && { noWrap: true })} sx={{ color: '#fff' }}>
                      {item.title}
                    </Typography>
                  </Box>
                  <Box sx={{
                    ml: 1.5, display: 'flex', alignItems: 'center'
                  }}
                  >
                    {item.badgeContent ? (
                      <Chip
                        size="small"
                        label={item.badgeContent}
                        color={item.badgeColor || 'primary'}
                        sx={{ mr: 0.75, '& .MuiChip-label': { px: 2.5, lineHeight: 1.385, textTransform: 'capitalize' } }}
                      />
                    ) : null}
                    {hasParent ? (
                      <ToggleIcon sx={{ color: '#fff' }} />
                    ) : (
                      <ChevronDown sx={{ color: '#fff' }} />
                    )}
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{
                boxShadow: 'none',
                borderRadius: '0'
              }}
              >
                {hasPermissions && <HorizontalNavItems {...props} hasParent navList={item.children} />}
              </AccordionDetails>
            </AccordionStyled>
          </ListItem>
        )}
      </ChildWrapper>
    </MainWrapper>
  );
};

export default HorizontalNavGroup;

import { array, object, string } from 'yup';

export const defaultValues = {
  aircraft_type: ['0'],
  aircraft_id: ['0'],
  date_from: '',
  date_to: '',
};

export const schema = object().shape({
  aircraft_type: array()
    .min(1)
    .label('AC Type'),
  aircraft_id: array()
    .min(1)
    .label('AC'),
  date_from: string()
    .required('${label} cannot be blank')
    .label('From'),
  date_to: string()
    .required('${label} cannot be blank')
    .label('To')
});

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useCreateCrewDocMutation } from '../../../store/session';
import { schema, defaultValues } from './schema';

export const useCreateDoc = (onClose, refetch) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [createDoc] = useCreateCrewDocMutation();

  const onSubmit = async (values) => {
    onClose();
    await createDoc(values);
    refetch();
  };

  return {
    control,
    errors,
    handleSubmit,
    onSubmit,
  };
};

import React from 'react';
import Box from '@mui/material/Box';
import TableMui from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRowMui from '@mui/material/TableRow';
import TableRow from './TableRow';
import ContentPageLoader from '../Loader/ContentPageLoader';
import {
  tableContainer,
  tableCell,
  tableSx,
  boxTh,
  tableLoader,
} from './styles';

const ViewTable = ({
  columns,
  list,
  actionsMenu,
  sx,
  singleView,
  onRowClick,
  isLoading,
  sxFunction = () => null,
}) => (
  <Box sx={{ ...tableContainer, ...sx }}>
    {isLoading && <ContentPageLoader sx={tableLoader} />}

    {!isLoading && (
      <TableMui sx={tableSx}>
        <TableHead>
          <TableRowMui>
            {(columns || [])?.map(({
              minWidth, sxHeader, name,
            }, index) => (
              <TableCell
                key={index}
                sx={{
                  minWidth,
                  ...tableCell,
                  ...sxHeader,
                  ...sxFunction(),
                }}
              >
                <Box sx={boxTh}>
                  {name}
                </Box>
              </TableCell>
            ))}
          </TableRowMui>
        </TableHead>

        <TableBody>
          {singleView
            ? (
              <TableRow
                onRowClick={onRowClick}
                columns={columns}
                row={list[0]}
                rowIndex={0}
                actionsMenu={actionsMenu}
                sxFunction={sxFunction}
              />
            )
            : list.map((row, rowIndex) => (
              <TableRow
                onRowClick={onRowClick}
                key={rowIndex}
                columns={columns}
                row={row}
                rowIndex={rowIndex}
                actionsMenu={actionsMenu}
                sxFunction={sxFunction}
              />
            ))}
        </TableBody>
      </TableMui>
    )}
  </Box>
);

export default ViewTable;

export const ButtonStyle = {
  backgroundColor: 'rgba(0, 70, 136, 0.8)',
  padding: '7px 22px',
  fontWeight: '500',
  borderRadius: '8px',
  color: '#fff',
  height: 'unset',
};

export const TitleContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const TitleContainerS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: '25px'
};
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React from 'react';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import TextEdit from '../../../components/EditableTable/TextEdit';
import {
  TYPE_INPUT,
  TYPE_MULTI_INPUT,
  TYPE_SELECT_DATE,
} from '../../../components/EditableTable/constants';
import {
  HeaderName,
  StyledDelete,
} from '../../../components/EditableTable/styles';
import ParticipantsEdit from './ParticipantsEdit/ParticipantsEdit';

const concatenateParticipants = (selectedParticipants, participantList) => {
  if (selectedParticipants.length === 0) return [];

  return selectedParticipants.map((selectedParticipant) => {
    if (!participantList) return [];

    return participantList.find(
      (listParticipant) => listParticipant?.crew_id === selectedParticipant?.crew_id
    );
  });
};

const calculateDuration = (trainingStart, trainingEnd) => {
  if (!trainingStart || !trainingEnd) return '';
  const start = moment(trainingStart);
  const end = moment(trainingEnd);

  const duration = moment.duration(end.diff(start));
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

const calculateDate = (date) => {
  if (!date) return '';

  let hours = moment(date).hours();
  let minutes = moment(date).minutes();

  if (hours.toString().length === 1) {
    hours = `0${hours}`;
  };
  if (minutes.toString().length === 1) {
    minutes = `0${minutes}`;
  };

  return `${hours}:${minutes}`;
};

export const columns = ({
  handleFilter,
  filters,
  handleOpen,
  handleOpenDeleteModal,
  participantsOptions,
  hasPermissions,
}) => [
  {
    flex: 0.2,
    field: 'training_date',
    minWidth: 180,
    headerName: 'Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',

        }}
      >
        <Typography style={HeaderName}>Date</Typography>
        <FiltrationDate
          value={filters.training_date || null}
          onChange={(e) => {
            handleFilter(e, 'training_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'training_date', row)}
        text={row.training_date}
        labelInput="Enter Name"
        disabled={!hasPermissions}
        type={TYPE_SELECT_DATE}
      />
    ),
  }, // training_date
  {
    flex: 0.2,
    field: 'training_type',
    minWidth: 180,
    headerName: 'Type Of Training',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Type Of Training</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.training_type || ''}
          handleFilter={handleFilter}
          placeholder="Type Of Training"
          value="training_type"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'training_type', row)}
        text={row.training_type}
        labelInput="Type of Training"
        disabled={!hasPermissions}
        type={TYPE_INPUT}
      />
    ),
  }, // training_type
  {
    flex: 0.2,
    field: 'participants',
    minWidth: 350,
    headerName: 'Participants',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Participants</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.participants || ''}
          handleFilter={handleFilter}
          placeholder="Participant"
          value="participants"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <ParticipantsEdit
        handleEdit={(...params) => handleOpen(...params, 'participants', row)}
        options={participantsOptions}
        participantsList={concatenateParticipants(
          row.participants || [],
          participantsOptions
        )} /* text */
        activeItem={
          row.participants
            ? row.participants.map((e) => ({ crew_id: e?.crew_id }))
            : []
        }
        type="TRAINING_AUTOCOMPLETE"
        disabled={!hasPermissions}
      />
    ),
  }, // participants
  {
    flex: 0.2,
    field: 'instructor',
    minWidth: 160,
    headerName: 'Instructor',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Instructor</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.instructor || ''}
          handleFilter={handleFilter}
          placeholder="Instructor"
          value="Instructor"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'instructor', row)}
        text={row.instructor}
        labelInput="Instructor"
        type={TYPE_INPUT}
        disabled={!hasPermissions}
      />
    ),
  }, // instructor
  {
    flex: 0.2,
    field: 'location',
    minWidth: 180,
    headerName: 'Location',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Location</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.location || ''}
          handleFilter={handleFilter}
          placeholder="Location"
          value="Location"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'location', row)}
        text={row.location}
        labelInput="Location"
        disabled={!hasPermissions}
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'training_start',
    minWidth: 180,
    headerName: 'Training Start',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Training Start</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'training_start', row)}
        text={calculateDate(row?.training_duration?.training_start)}
        duration={row?.training_duration?.training_start}
        labelInput="Training Duration"
        disabled={!hasPermissions}
        type="DURATION_INPUT"
      />
    ),
  },
  {
    flex: 0.2,
    field: 'training_end',
    minWidth: 180,
    headerName: 'Training End',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Training End</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'training_end', row)}
        text={calculateDate(row?.training_duration?.training_end)}
        duration={row?.training_duration?.training_end}
        labelInput="Training Duration"
        disabled={!hasPermissions}
        type="DURATION_INPUT"
      />
    ),
  }, // training_duration
  {
    flex: 0.2,
    field: 'remarks',
    minWidth: 180,
    headerName: 'Remarks',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Remarks</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.remarks || ''}
          handleFilter={handleFilter}
          placeholder="Remarks"
          value="Remarks"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'remarks', row)}
        text={row.remarks}
        labelInput="Remarks"
        disabled={!hasPermissions}
        type={TYPE_MULTI_INPUT}
      />
    ),
  }, // remarks
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => hasPermissions && (
    <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
    ),
  }, // actions
];

import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import React from 'react';
import { DARE_FORMAT_FIRST } from '../../../constans/formats';
import { linkSx } from '../styles';

export const columnsDocs = ({ handleDownload, confirmDocument }) => ([
  {
    flex: 0.2,
    field: 'document_patch',
    minWidth: 180,
    headerName: 'Document',
    renderCell: ({ row: { document_patch, id, } }) => (
      <Typography sx={linkSx} onClick={() => handleDownload({ document_patch, id })}>{document_patch}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'document_description',
    minWidth: 200,
    headerName: 'description',
    renderCell: ({ row }) => (
      <Typography>{row.document_description}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'created_at',
    minWidth: 180,
    headerName: 'Upload Date',
    renderCell: ({ row }) => (
      <Typography>{moment(row.created_at).format(DARE_FORMAT_FIRST)}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'read_at',
    minWidth: 180,
    headerName: 'Read On',
    renderCell: ({ row }) => (
      <Typography>
        {row.read_at ? moment.unix(row.read_at).format(DARE_FORMAT_FIRST) : ''}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'document_read',
    minWidth: 120,
    headerName: 'Document Read',
    renderCell: ({ row }) => (
      <Checkbox onClick={() => confirmDocument(row.id)} disabled={row.read_at} {...(row.read_at && { checked: true })} />
    ),
  },
]);

import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import React from 'react';
import Button from '../../../components/Button';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import {
  HeaderName
} from '../../../components/EditableTable/styles';
import { formatOrderId } from '../../Logistics/Storage/columns';
import { PartsStorageFilter } from '../../Logistics/Storage/constants';

export const columns = ({
  handleFileDrawer,
  handleFilter,
  filters,
  aircraftOptions,
  handleOpenApproveReturn,
  aircraftBodyReturnToStoragePermission,
  isLoadingReturnToStorage,
  // permissions,
  handleOpenTask
}) => ([
  {
    flex: 0.2,
    field: 'wo_task_id',
    minWidth: 140,
    headerName: 'Task',
    renderHeader: () => (
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        fontWeight: '650'
      }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Task</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (row?.wo_task_id ? (
      <Button
        onClick={() => handleOpenTask(row?.wo_task_id)}
        sx={{
          fontSize: '.8rem',
          padding: '0.6rem 0.7rem'
        }}
      >
        Open Task
      </Button>
    ) : null),
  },
  {
    flex: 0.2,
    field: 'id',
    minWidth: 120,
    headerName: 'Part ID',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Part ID</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.id || ''}
          value="id"
          placeholder="Part ID"
          type="number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize', fontWeight: '600' }}
      >
        {`#${row.id}`}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'order_id',
    minWidth: 120,
    headerName: 'Order ID',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Order ID</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.order_id || ''}
          value="order_id"
          placeholder="Order ID"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize', fontWeight: '600' }}
      >
        {formatOrderId(row)}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'aircraft',
    minWidth: 140,
    headerName: 'Aircraft Reservation',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Aircraft Reservation</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        <Chip label={row?.aircraft ?? 'N/A'} color="secondary" variant="outlined" />
      </Typography>
    ),
  },
  {
    flex: 0.3,
    minWidth: 180,
    field: 'part_number',
    headerName: 'Part Number',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Part Number</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.part_number || ''}
          value="part_number"
          placeholder="Part Number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
      >
        {row.part_number}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'part_name',
    minWidth: 180,
    headerName: 'Part Name',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Part Name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.part_name || ''}
          value="part_name"
          placeholder="Part Name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
      >
        {row.part_name ?? 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'serial_number',
    minWidth: 180,
    headerName: 'Serial Number',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Serial Number</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.serial_number || ''}
          value="serial_number"
          placeholder="Serial Number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
      >
        {row.serial_number ?? 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'storage',
    minWidth: 180,
    headerName: 'Storage',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Storage</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.storage || ''}
          value="storage"
          placeholder="Storage"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.storage}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'storage_zone',
    minWidth: 140,
    headerName: 'Storage Zone',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Storage Zone</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.storage_zone || ''}
          value="storage_zone"
          placeholder="Storage Zone"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
      >
        {row.storage_zone ?? 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'storage_number',
    minWidth: 180,
    headerName: 'Storage Number',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Storage Number</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.storage_number || ''}
          value="storage_number"
          placeholder="Storage Number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
      >
        {row.storage_number}
      </Typography>
    ),
  },
  {
    flex: 0.25,
    field: 'delivery_status',
    minWidth: 180,
    headerName: 'Delivery Status',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Delivery Status</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        <Chip label={row?.delivery_status ?? 'N/A'} color="secondary" variant="outlined" />
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'exchange_due_date',
    minWidth: 180,
    headerName: 'Exchange Due Date',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Exchange Due Date</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        <Chip label={row?.exchange_due_date ?? 'N/A'} color="secondary" variant="outlined" />
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'part_count',
    minWidth: 140,
    headerName: 'Quantity',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Quantity</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.part_count || ''}
          value="part_count"
          placeholder="Quantity"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
      >
        {`${row.part_count} ${row?.unit ?? ''}`}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'part_state',
    minWidth: 180,
    headerName: 'Part State',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Part State</Typography>
        <FiltrationSelect
          options={PartsStorageFilter}
          defaultValue={filters.part_state || ''}
          handleFilter={handleFilter}
          value="part_state"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {PartsStorageFilter.find(e => e.value === row.part_state)?.label}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'files',
    minWidth: 140,
    headerName: 'Files',
    sortable: false,
    renderHeader: () => (
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        fontWeight: '650'
      }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Files</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Button
        onClick={() => handleFileDrawer(true, { part_id: row?.id || null, order_id: row?.order_id || null }, null, 'Transaction', row?.id)}
        sx={{
          fontSize: '.8rem',
          padding: '0.6rem 0.7rem'
        }}
      >
        Open Files
      </Button>
    ),
  },
  {
    minWidth: 120,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <Tooltip title="Return To Storage" sx={{ fontSize: '2rem' }}>
        <SettingsBackupRestoreIcon disabled={!aircraftBodyReturnToStoragePermission || isLoadingReturnToStorage} onClick={() => handleOpenApproveReturn(true, row?.id)} sx={{ cursor: 'pointer', fontSize: '1.8rem' }} />
      </Tooltip>

    ),
  }
]);

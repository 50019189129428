export const menuContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
};

export const menuItem = {
  width: '34px',
  height: '30px',
  borderRadius: '3px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#659be0',
  cursor: 'pointer',
  svg: {
    width: '20px',
    path: {
      fill: '#fff',
    }
  }
};

export const popoverIconContainer = {
  backgroundColor: '#3fc9d5'
};

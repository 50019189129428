import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  Divider,
  Grid, Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { headerBtn } from '../../../Invoices/style';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';

const UploadErrors = ({
  open, onClose, upload_id, useGetHook = () => {}
}) => {
  const { data: errors, isLoading } = useGetHook(upload_id, {
    refetchOnMountOrArgChange: true,
    skip: !upload_id
  });

  const parseJsonAndRender = useMemo(() => {
    if (errors?.message) {
      return <Grid item xs={12}><Typography>{errors.message}</Typography></Grid>;
    }

    if (!errors?.length) return;

    // eslint-disable-next-line consistent-return
    return errors.map((item, index) => {
      if (!item?.message || !item.row_number) {
        return (
          <Grid item xs={12} key={index}>
            <Typography>
              Message:
              {' '}
              {item}
            </Typography>

            <Divider />
          </Grid>
        );
      }

      return (
        <Grid item xs={12} key={index}>
          <Typography>
            Message:
            {' '}
            {item?.message}
          </Typography>
          <Typography>
            Row Number:
            {' '}
            {item?.row_number}
          </Typography>
          <Divider />
        </Grid>
      );
    });
  }, [errors]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <DialogContent>
          <Card sx={{ padding: '1rem' }}>
            <Box sx={{ ...headerBtn, display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="h5" noWrap>
                  {`Upload ID: ${upload_id}`}
                </Typography>
              </Box>
              <CloseIcon
                sx={CloseIconStyle}
                onClick={onClose}
              />
            </Box>

            <Divider />

            <Grid container spacing={4}>
              {errors?.message ? (
                <Grid item xs={12}>
                  <Typography>
                    Message:
                    {' '}
                    {errors?.message}
                  </Typography>
      
                  <Divider />
                </Grid>
              ) : (parseJsonAndRender)}
            </Grid>
          </Card>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default UploadErrors;

import {
  array, boolean, object, string
} from 'yup';

export const defaultValues = {
  silent_sign: false,
  allow_to_fill: false,
  issue_number: '',
  issue_date: '',
  revision_number: '',
  revision_date: '',
  training_place: '',
  training_date: '',
  training_title: '',
  training_reference: '',
  trainees: [
    {
      position: '',
      crew_id: '',
      passed_satisfactory: null
    }
  ],
  instructor_id: '',
  training_crew_id: '',
  observer: null,
  evaluator: null,
  instructor_comments: '',
  manager_decision: '',
  training_titles: [
    {
      abbreviation: '',
      training: '',
      reference: ''
    }
  ]
};

export const schema = object().shape({
  issue_number: string()
    .nullable(),
  issue_date: string()
    .nullable(),
  revision_number: string()
    .nullable(),
  revision_date: string()
    .nullable(),
  training_place: string()
    .nullable(),
  training_date: string()
    .nullable(),
  training_title: string()
    .nullable(),
  training_reference: string()
    .nullable(),
  trainees: array().of(object().shape({
    position: string().nullable(),
    crew_id: string().nullable(),
    passed_satisfactory: boolean().nullable()
  })),
  instructor_id: string()
    .nullable(),
  training_titles: array().of(object().shape({
    abbreviation: string().nullable(),
    training: string().nullable(),
    reference: string().nullable()
  })),
  instructor_comments: string()
    .nullable(),
  manager_decision: string()
    .nullable(),
  training_crew_id: string()
    .nullable()
});

import {
  array,
  number,
  object, string
} from 'yup';

export const schema = object().shape({
  planing_cln_price: string()
    .matches(
      /^$|^\d+$|^\d+\.[0-9][0-9]?$/,
      'Incorrect price format.'
    )
    .label('Price'),
  planing_hop: array(object().shape({
    pl_hop_from_id: number().required().label('Airport From'),
    pl_hop_to_id: number().required().label('Airport To'),
    pl_hop_departure: string().required('${label} cannot be blank.').label('Date of Departure')
  }))
});

import React from 'react';
import { Divider, Typography } from '@mui/material';
import { FLIGHT_CARGO } from '../../../../../../constans/constants';
import { Link } from 'react-router-dom';
import { usePermissions } from '../../../../../../utils/hooks/usePermissions';

const FlightFormHeader = (props) => {
  const { update, getValues, type } = props;
  const hasPermissions = usePermissions('fleet_access');

  const subtitle1 = `Created by ${getValues('created_by') ?? ''} at ${getValues('created_at') ?? ''} UTC ${(getValues('updated_by') && getValues('updated_at')) ? `| Updated by ${getValues('updated_by')} at ${getValues('updated_at')} UTC` : ''}`;
  const h5 = `${getValues('company_name')} [${getValues('sector')}] `;

  return (
    <>
      <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
        {`${update ? 'Update' : 'Create'} ${(type === FLIGHT_CARGO) ? 'Cargo' : 'technical'} Flight ${update ? `#${getValues('index')}` : ''}`}
        {update && (<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{subtitle1}</Typography>)}
      </Typography>
      <Divider />
      {update && (
      <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
        {h5}
        {' '}
        {(getValues('planing_calc_id') && hasPermissions) ? (
          <Link to={`/quotations/update/${getValues('planing_calc_id')}`} target="_blank">Calculation</Link>
        ) : (
          ''
        )}
      </Typography>
      )}
    </>
  );
};

export default FlightFormHeader;

import React, { useEffect, useState } from 'react';
import {
  Box, Card, Divider, Typography
} from '@mui/material';
import EditableTable from '../../components/EditableTable';
import { columns } from './columns';
import {
  useGetInvoiceTableQuery,
  useGetBankAccountNumbersListMutation,
  useGetBankInvoicesListQuery,
  useGetCardsListMutation,
  useGetCurrencyListQuery,
  useGetDepartmentsListQuery,
  useGetAirTypeListQuery,
  useGetAirListMutation,
  useGetFlightsQuery,
  useDeleteInvoiceMutation,
  useDeleteConfirmationMutation,
  useUpdateInvoiceMutation
} from '../../store/session';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ButtonSelect from '../../components/ButtonSelect';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { DEFAULT_MONTH, DEFAULT_YEAR } from '../SabCrewRoster/constants';
import { getOptions } from '../../utils/getOptions';
import { stringifyLocationSearch } from '../../utils/locationSearch';
import { selectOptionFormat } from '../../utils/number';
import { MONTH_SHORT } from '../MainDeckSchedule/constants';

export const DeleteConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [year, setYear] = useState(DEFAULT_YEAR);
  const [months, setMonths] = useState(DEFAULT_MONTH);

  const { data: banksList, isLoading: isBanksListLoading } = useGetBankInvoicesListQuery();
  const [getBankAccountNumbers, { data: bankAccountNumbers, isLoading: isGetBankAccountNumbersLoading }] = useGetBankAccountNumbersListMutation();
  const [getCards, { data: cards, isLoading: isGetCardsLoading }] = useGetCardsListMutation();
  const [deleteConfirmation] = useDeleteConfirmationMutation();
  const [updateInvoice] = useUpdateInvoiceMutation();
  const { data: currencyList, isLoading: isCurrencyLoading } = useGetCurrencyListQuery();
  const { data: departmentsList, isLoading: isDepartmentsListLoading } = useGetDepartmentsListQuery();
  const { data: aircraftTypeList, isLoading: isAircraftTypeListLoading } = useGetAirTypeListQuery();
  const [getAir, { data: aircraftList, isLoading: isAircraftListLoading }] = useGetAirListMutation();
  const { data: flightsList, isLoading: isFlightsListLoading } = useGetFlightsQuery();

  const banksListOptions = getOptions(banksList);
  const bankAccountNumbersOptions = getOptions(bankAccountNumbers);
  const cardsOptions = getOptions(cards);
  const currencyOptions = getOptions(currencyList);
  const flightsOptions = getOptions(flightsList);
  const departmentsOptions = getOptions(departmentsList);
  const aircraftTypeOptions = selectOptionFormat(aircraftTypeList, 'aircraft_type_name');
  const aircraftOptions = selectOptionFormat(aircraftList, 'aircraft_registration');

  const hasPermissionsBank = usePermissions('bank_edit');

  const isLoading = isBanksListLoading
  || isGetBankAccountNumbersLoading
  || isGetCardsLoading
  || isCurrencyLoading
  || isDepartmentsListLoading
  || isAircraftListLoading
  || isFlightsListLoading
  || isAircraftTypeListLoading;

  const onCustomAddParams = (result, filterBy) => {
    if (filterBy === 'bank') {
      delete result.account_number_card;
      delete result.bank_account_number;
    }

    if (filterBy === 'aircraft_type') {
      delete result.aircraft;
    }

    const locationSearch = stringifyLocationSearch(result);
    navigate(location.pathname + locationSearch);
  };

  const numberMonths = MONTH_SHORT.reduce((total, amount, index) => {
    const monthIndex = [...total, months.includes(amount) && index + 1];
    const res = monthIndex.filter((e) => e);

    return res;
  }, []);

  useEffect(() => {
    if (banksListOptions) {
      banksListOptions?.push({ value: '', label: '-' });
    }
    if (bankAccountNumbersOptions) {
      bankAccountNumbersOptions?.push({ value: '', label: '-' });
    }
    if (cardsOptions) {
      cardsOptions?.push({ value: '', label: '-' });
    }

    if (currencyOptions) {
      currencyOptions?.push({ value: '', label: '-' });
    }

    if (flightsOptions) {
      flightsOptions?.push({ value: '', label: '-' });
    }
    if (departmentsOptions) {
      departmentsOptions?.push({ value: '', label: '-' });
    }

    if (aircraftTypeOptions) {
      aircraftTypeOptions?.push({ value: '', label: '-' });
    }

    if (aircraftOptions) {
      aircraftOptions?.push({ value: '', label: '-' });
    }
  }, [banksListOptions,
    bankAccountNumbersOptions,
    cardsOptions,
    currencyOptions,
    flightsOptions,
    departmentsOptions,
    aircraftTypeOptions,
    aircraftOptions
  ]);

  return (
    <Card sx={{
      mt: 3,
      padding: '1rem',
    }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}
      >
        <Typography variant="h3">Delete Confirmation</Typography>
      </Box>
      <ButtonSelect
        year={year}
        months={months}
        setYear={setYear}
        setMonths={setMonths}
        disabledSendRoster={false}
      />
      <Divider />
      <EditableTable
        useHook={useGetInvoiceTableQuery}
        useHookDelete={useDeleteInvoiceMutation}
        columns={columns}
        tableParams={{
          year,
          months: numberMonths,
          deleted: 1
        }}
        sortByType="DESC"
        sortByActive="created_at"
        getRowHeight={() => 'auto'}
        hasPermissions={hasPermissionsBank}
        getRowId={(row) => row.id}
        banksListOptions={banksListOptions}
        bankAccountNumbers={bankAccountNumbersOptions}
        cardsOptions={cardsOptions}
        customIsLoading={isLoading}
        getBankAccountNumbers={getBankAccountNumbers}
        getCards={getCards}
        getAir={getAir}
        aircraftTypeOptions={aircraftTypeOptions}
        currencyOptions={currencyOptions}
        departmentsOptions={departmentsOptions}
        aircraftOptions={aircraftOptions}
        flightsList={flightsList}
        flightsOptions={flightsOptions}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onCustomAddParams={onCustomAddParams}
        deleteConfirmation={deleteConfirmation}
        updateInvoice={updateInvoice}
      />
    </Card>
  );
};

export const INVOICE_TYPE = [
  { label: 'NONE', value: '0' },
  { label: 'INVOICE', value: '1' },
  { label: 'PROFORM', value: '2' },
];

export const PAYMENTS = [
  { label: 'NONE', value: '0' },
  { label: 'PREPAID', value: '1' },
  { label: 'POSTPAID', value: '2' },
];

export const MONTH = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const OPTIONS_INVOICE_STATUS = [
  { label: 'Yes', value: '3' },
  { label: 'No', value: '4' },
  { label: 'Pending', value: '2' },
  { label: '-', value: '' },
];

export const OPTIONS_INVOICE_STATUS_EDIT = [
  { label: 'Yes', value: '3' },
  { label: 'No', value: '4' },
  { label: 'Pending', value: '2' },
];


import {
  boolean,
  mixed, number,
  object, string
} from 'yup';

export const defaultValues = {
  planing_service_id: null,
  invoice_sum: '',
  file: '',
  description: '',
  invoice_number: '',
  invoice_name: '',
  due_payment_date: null,
  currency_id: '',
  is_proforma: 0,
  predicted_invoice_sum: '',
  is_payed: false
};

export const schema = object().shape({
  file: mixed().test('isEmpty', 'File cannot be blank', (value) => !!value ).nullable(),
  invoice_sum: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('SUM'),
  predicted_invoice_sum: string()
    .nullable(),
  is_proforma: boolean(),
  is_payed: boolean(),
  description: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Description'),
  invoice_number: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Invoice Number'),
  invoice_name: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Supplier'),
  currency: object()
    .nullable()
    .required('${label} cannot be blank')
    .label('Currency'),
  currency_id: number()
    .nullable()
    .required('${label} cannot be blank')
    .label('Currency'),
  due_payment_date: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Deadline Payment'),
});

import React, { useState } from 'react';
import Drawer from '../../../../components/Drawer';
import { useGetUserListQuery } from '../../../../store/session';
import CreateDepartment from './createDepartment';
import UpdateDepartment from './updateDepartment';

const ManageDepartments = ({
  open, onClose, refetchDepartmentsList, hasPermissions
}) => {
  const [updateDepartment, setUpdateDepartment] = useState(null);
  const { data: userList } = useGetUserListQuery();

  const userOptions = (userList || []).map(user => ({
    value: user?.id,
    label: user?.name
  }));

  return (
    <Drawer
      title={updateDepartment ? `Update ${updateDepartment?.name}` : 'Manage Departments'}
      open={open}
      onClose={() => {
        onClose();
        setUpdateDepartment(null);
      }}
      sxTitle={{ margin: '0 auto' }}
    >
      {!updateDepartment && <CreateDepartment setUpdateDepartment={setUpdateDepartment} refetchDepartmentsList={refetchDepartmentsList} userOptions={userOptions} hasPermissions={hasPermissions} />}
      {updateDepartment && <UpdateDepartment departmentId={updateDepartment?.id} setUpdateDepartment={setUpdateDepartment} userOptions={userOptions} hasPermissions={hasPermissions} />}
    </Drawer>
  );
};

export default ManageDepartments;

import { Card } from '@mui/material';
import React from 'react';
import PayedInvoiceForm from './PayedInvoiceForm';

const PrepaymentForm = ({
  invoice, refetchInvoice, invoiceId, refetchBoard, onClose 
}) => (
  <>
    {((invoice?.payment_type === 'flight') && (invoice?.payment_status === 7) && !!invoice?.is_proforma) ? (
      <Card sx={{ mb: 5 }}>
        <PayedInvoiceForm parentId={invoiceId} refetchInvoice={() => refetchInvoice(invoiceId)} refetchBoard={refetchBoard} onClose={onClose} />
      </Card>
    ) : (
      <Card sx={{ padding: '1rem 1rem 1rem 1rem' }}>
        Proforma payed. Awaiting Invoice.
      </Card>
    )}
  </>
 
);

export default PrepaymentForm;

import {
  array, object, string
} from 'yup';

export const defaultValues = {
  position_name: '',
  current_position_access: [],
};

export const schema = object().shape({
  position_name: string()
    .required('${label} cannot be blank.')
    .label('Position name'),
  current_position_access: array()
});

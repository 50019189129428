import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import ButtonIcon from '../../../../../components/ButtonIcon';
import FiltrationInput from '../../../../../components/EditableTable/FiltrationInput';
import FiltrationSelect from '../../../../../components/EditableTable/FiltrationSelect';
import TextEdit from '../../../../../components/EditableTable/TextEdit';
import { TYPE_INPUT } from '../../../../../components/EditableTable/constants';
import {
  HeaderName, StyledDelete, StyledPencil,
} from '../../../../../components/EditableTable/styles';
import LinkButton from '../../../../../components/LinkButton';
import { OPTIONS_LINE_CHECK } from '../../../../../constans/workOrder';

export const columns = ({
  handleFilter,
  filters,
  handleOpenDeleteModal,
  deletePermission,
  handleOpen,
  hasPermissionsWoEdit,
}) => ([
  {
    flex: 0.2,
    field: 'performed',
    minWidth: 130,
    headerName: 'Performed',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Typography style={HeaderName}>Performed</Typography>
        <FiltrationSelect
          options={OPTIONS_LINE_CHECK}
          defaultValue={filters.performed || ''}
          handleFilter={handleFilter}
          placeholder="Performed"
          value="performed"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.performed ? 'Yes' : 'No'}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'inspected',
    minWidth: 130,
    headerName: 'Inspected',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Typography style={HeaderName}>Inspected</Typography>
        <FiltrationSelect
          options={OPTIONS_LINE_CHECK}
          defaultValue={filters.inspected || ''}
          handleFilter={handleFilter}
          placeholder="Inspected"
          value="inspected"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.inspected ? 'Yes' : 'No'}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'closed',
    minWidth: 120,
    headerName: 'Closed',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Typography style={HeaderName}>Closed</Typography>
        <FiltrationSelect
          options={OPTIONS_LINE_CHECK}
          defaultValue={filters.closed || ''}
          handleFilter={handleFilter}
          placeholder="Closed"
          value="closed"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.closed ? 'Yes' : 'No'}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'mnt_upd',
    minWidth: 120,
    headerName: 'Mnt Upd',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Typography style={HeaderName}>Mnt Upd</Typography>
        <FiltrationSelect
          options={OPTIONS_LINE_CHECK}
          defaultValue={filters.mnt_upd || ''}
          handleFilter={handleFilter}
          placeholder="Mnt Upd"
          value="mnt_upd"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.mnt_upd ? 'Yes' : 'No'}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'name',
    minWidth: 180,
    headerName: 'Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Name</Typography>
        <FiltrationInput
          defaultValue={filters.name || ''}
          handleFilter={handleFilter}
          placeholder="Name"
          value="name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        disabled={!hasPermissionsWoEdit}
        handleEdit={(...params) => handleOpen(...params, 'name', row)}
        text={row.name}
        labelInput="Name"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'task',
    minWidth: 180,
    headerName: 'Task',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>task</Typography>
        <FiltrationInput
          defaultValue={filters.task || ''}
          handleFilter={handleFilter}
          placeholder="Task"
          value="task"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'task', row)}
        disabled={!hasPermissionsWoEdit}
        text={row.task}
        labelInput="Task"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'description',
    minWidth: 450,
    headerName: 'Description',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Typography style={HeaderName}>Description</Typography>
        <FiltrationInput
          defaultValue={filters.description || ''}
          handleFilter={handleFilter}
          placeholder="Description"
          value="description"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'description', row)}
        text={row.description}
        labelInput="Description"
        disabled={!hasPermissionsWoEdit}
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'interval',
    minWidth: 180,
    headerName: 'Interval',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Interval</Typography>
        <FiltrationInput
          defaultValue={filters.interval || ''}
          handleFilter={handleFilter}
          placeholder="Interval"
          value="interval"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.interval}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'man_hour_real_job',
    minWidth: 180,
    headerName: 'Man Hour Real Job',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Man Hour Real Job</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.man_hour_real_job}</Typography>
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <LinkButton path={`/maintenance/work-order-task/update/${row.id}`}>
          <StyledPencil />
        </LinkButton>
        {' '}
        {(hasPermissionsWoEdit && deletePermission) && (
        <ButtonIcon disabled={!hasPermissionsWoEdit && !deletePermission} onClick={() => handleOpenDeleteModal(row.id)}>
          <StyledDelete />
        </ButtonIcon>
        )}
      </>
    ),
  },
]);

import React from 'react';
import Box from '@mui/material/Box';
import DropDownMenu from '../../../../components/DropDownMenu';
import { useNavigate } from 'react-router-dom';
import {
  listContainer, listItem, header, dropdownMenuSx
} from './styles';

const DropDown = ({
  open,
  onClose,
  anchorEl,
  idRow,
}) => {
  const navigate = useNavigate();

  return (
    <DropDownMenu
      sx={dropdownMenuSx}
      transformOrigin={{
        vertical: 'right',
        horizontal: 'left',
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
    >
      <Box>
        <Box sx={header}>
          Actions
        </Box>

        <Box sx={listContainer}>
          <Box
            sx={listItem}
            onClick={() => {
              navigate(`/directories/ods-pdf/${idRow}`);
              onClose();
            }}
          >
            Print
          </Box>
          <Box sx={listItem} onClick={onClose}>
            Load Sheet
          </Box>
          <Box sx={listItem} onClick={onClose}>
            Close
          </Box>
        </Box>
      </Box>
    </DropDownMenu>
  );
};

export default DropDown;

import React from 'react';
import Button from '../../../components/Button';
import { OdsFormLayoutNonAuthorized } from '../OdsFormLayoutNonAuthorized';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import OdsPositionForm from './OdsPositionForm';
import OdsTrainingForm from './OdsTrainingForm';
import { useOdsForm } from './useOdsForm';
import PdfLogo from '../../../components/PdfLogo';
import { POSITION_ODS, TRAINING_ODS } from './constants';
import {
  button, buttonActive, cardContainer, loaderSx, saveBtn
} from './styles';

const OdsForm = ({
  id, isSubmitForms, setIsSubmitForms,
}) => {
  const {
    isSubmit,
    isLoading,
    forms,
    isFormLoading,
    setIsSubmit,
    handleSubmit,
    hasPermissionsOdsEdit,
    handleItemClick,
    setNavItems,
    navItems,
    active,
    airportList,
    odsStatusesOptions,
    odsTocOptions,
    isOffline,
    setIsLoading,
    isLoadingUpdate,
    openPrevOds,
  } = useOdsForm({ id, isSubmitForms, setIsSubmitForms });

  return (
    <>
      {(isLoading || isFormLoading) && (
        <ContentPageLoader containerSx={loaderSx} />
      )}
      <Box
        sx={{
          left: 0,
          zIndex: 34,
          position: 'fixed',
          background: '#ffffff',
          width: ' 100%',
          top: 0,
          display: 'flex',
          border: '1px solid rgba(76, 78, 100, 0.22)',
          boxShadow: '0px 1px 1px rgb(76 78 100 / 22%)',
        }}
      >
        <Button
          onClick={(e) => {
            e.preventDefault();
            openPrevOds();
          }}
          title="Previous ODS"
          sx={{ ...button, fontSize: '0.685rem' }}
        />
        {navItems.map((item) => (
          <Button
            key={item?.text}
            onClick={(event) => {
              event.preventDefault();
              handleItemClick(item.ref);
            }}
            href={`#${item.id}`}
            title={item.text}
            sx={{ ...button, ...(active.includes(item.id) && buttonActive) }}
          />
        ))}
      </Box>
      {forms?.map(data => (
        <>
          {data.type === POSITION_ODS && (
          <Box sx={{ padding: '2rem', }}>
            <PdfLogo title="OPERATIONAL DATA SHEET" />
            <OdsPositionForm isOffline={isOffline} data={data} isPanel isSubmit={isSubmit} setIsSubmit={setIsSubmit} />
          </Box>
          )}
          {data.type === TRAINING_ODS && (
          <Box sx={{ padding: '2rem', }}>
            <PdfLogo title="OPERATIONAL DATA SHEET" />
            <OdsTrainingForm isOffline={isOffline} data={data} isPanel isSubmit={isSubmit} setIsSubmit={setIsSubmit} />
          </Box>
          )}
          {![POSITION_ODS, TRAINING_ODS].includes(data.type) && (
            <OdsFormLayoutNonAuthorized
              data={data}
              odsStatusesOptions={odsStatusesOptions}
              odsTocOptions={odsTocOptions}
              isOffline={isOffline}
              hasPermissionsOdsEdit={hasPermissionsOdsEdit}
              isSubmit={isSubmit}
              airportList={airportList}
              setIsLoading={setIsLoading}
              setIsSubmit={setIsSubmit}
              isTypeF={data.type === 1}
              setNavItems={setNavItems}
            />
          )}
        </>
      ))}

      <Card sx={cardContainer}>
        <Button disabled={isLoadingUpdate || !hasPermissionsOdsEdit} sx={saveBtn} onClick={handleSubmit} title="Save" />
      </Card>
    </>
  );
};

export default OdsForm;

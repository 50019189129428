import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetFuelUpliftExportExcelMutation
} from '../../store/session';
import FileSaver from 'file-saver';

const getUrlParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return {
    year: urlParams.get('year'),
    months: urlParams.get('months'),
  };
};

export const useFuelUpliftLimitations = () => {
  const initialMonth = getUrlParams().months;
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);

  const [data, setData] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  const [exportExcel] = useGetFuelUpliftExportExcelMutation();

  const handleDoc = async () => {
    const res = await exportExcel();

    FileSaver.saveAs(res.data, 'fuel_uplift_report');
  };

  useEffect(() => {
    if (Object.keys(data || {}).length && isPdf) {
      print();
    }
  }, );

  return {

    data,

    handleDoc,

  };
};

import React from 'react';
import Typography from '@mui/material/Typography';
import { HeaderName, StyledDelete } from '../../../../../components/EditableTable/styles';
import { linkSx } from '../../../../MyProfile/styles';
import ButtonIcon from '../../../../../components/ButtonIcon';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const columns = ({
  handleDownloadInvoice,
  statusOptions,
  handleOpenDeleteModal,
  onOpenReasonModal,
  openProofOfPayment,
}) => ([
  {
    flex: 0.2,
    field: 'fileName',
    minWidth: 200,
    headerName: 'Invoice File',
    renderHeader: () => (
      <Typography style={HeaderName}>Invoice File</Typography>
    ),
    renderCell: ({ row: { fileName, id, } }) => (
      <Typography
        sx={{
          ...linkSx,
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
        onClick={() => handleDownloadInvoice({ id, fileName })}
      >
        {fileName}

      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'payment_status',
    minWidth: 200,
    headerName: 'Status',
    renderHeader: () => (
      <Typography style={HeaderName}>Status</Typography>
    ),
    renderCell: ({ row }) => (+row.payment_status === 5 ? (
      <Typography
        sx={{
          ...linkSx,
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
        onClick={() => { onOpenReasonModal(row.decline_purpose); }}
      >
        {statusOptions.find(i => i.value === row.payment_status)?.label || 'Empty'}
      </Typography>
    ) : (
      <Typography sx={{
        textTransform: 'capitalize',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-all'
      }}
      >
        {statusOptions.find(i => i.value === row.payment_status)?.label || 'Empty'}
      </Typography>
    )),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <ButtonIcon onClick={() => handleOpenDeleteModal(row.id)}>
          <Tooltip title="Delete">
            <StyledDelete />
          </Tooltip>
        </ButtonIcon>
        <IconButton
          disabled={!row.proof_of_payment_file_path}
          onClick={() => openProofOfPayment(row.id)}
        >
          <Tooltip title="Open Proof Of Payment">
            <OpenInNewIcon />
          </Tooltip>
        </IconButton>
      </>
    ),
  },
]);

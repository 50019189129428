import Typography from '@mui/material/Typography';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from '../../../constans/formats';
import { linkSx } from '../styles';
import { HeaderName, StyledDelete } from '../../../components/EditableTable/styles';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import { Box, Tooltip } from '@mui/material';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import ButtonIcon from '../../../components/ButtonIcon';
import { STATUSES } from '../../../constans/invoiceRequest';
// import Button from '../../../components/Button';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const columnsMyInvoices = ({
  handleFilter, filters, handleDownload, handleOpenDeleteModal, onOpenReasonModal, openProofOfPayment,
}) => ([
  {
    flex: 0.2,
    field: 'fileName',
    minWidth: 200,
    headerName: 'File',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>File</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.fileName || ''}
          value="fileName"
          placeholder="File"
        />
      </Box>
    ),
    renderCell: ({ row: { fileName, id, } }) => (
      <Typography
        sx={{
          color: 'rgb(108, 108, 241)',
          cursor: 'pointer',
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
        variant="subtitle1"
        onClick={() => handleDownload({ fileName, id }, 'myInv')}
      >
        {fileName}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'payment_status',
    minWidth: 200,
    headerName: 'Payment Status',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Payment Status</Typography>
        <FiltrationSelect
          options={STATUSES || []}
          defaultValue={filters.payment_status || ''}
          handleFilter={handleFilter}
          placeholder="Payment Status"
          value="payment_status"
        />
      </Box>
    ),
    renderCell: ({ row }) => (+row.payment_status === 5 ? (
      <Typography
        sx={{
          ...linkSx,
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
        onClick={() => { onOpenReasonModal(row.decline_purpose); }}
      >
        {STATUSES.find(i => i.value === row.payment_status)?.label || 'Empty'}
      </Typography>
    ) : (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}

      >
        {STATUSES.find(i => i.value === row.payment_status)?.label || 'Empty'}
      </Typography>
    )),
  },
  {
    flex: 0.2,
    field: 'created_at',
    minWidth: 180,
    headerName: 'Created at',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Created at</Typography>
        <FiltrationDate
          onChange={(e) => handleFilter(e, 'created_at', true)}
          value={filters.created_at || null}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {moment(row.created_at).format(DATE_FORMAT)}
      </Typography>
    ),
  },
  {
    width: 120,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <ButtonIcon onClick={() => handleOpenDeleteModal(row.id)}>
          <Tooltip title="Delete">
            <StyledDelete />
          </Tooltip>
        </ButtonIcon>
        <IconButton
          disabled={!row.proof_of_payment_file_path}
          onClick={() => openProofOfPayment(row.id)}
        >
          <Tooltip title="Open Proof Of Payment">
            <OpenInNewIcon />
          </Tooltip>
        </IconButton>
      </>
    ),
  },
]);

import {
  number, object, string
} from 'yup';

export const defaultValues = {
  ap_fuel_price: '',
  currency: '',
  unit: '',
};

export const schema = object().shape({
  ap_fuel_price: number()
    .required('${label} cannot be blank.')
    .label('Price'),
  currency: string()
    .required('${label} cannot be blank.')
    .label('Currency'),
  unit_id: number()
    .required('${label} cannot be blank.')
    .label('Unit'),
});

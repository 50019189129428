import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCrewByIdQuery, useGetLogBookQuery } from '../../../store/session';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import { DEFAULT_MONTH, DEFAULT_YEAR, MONTH_SHORT } from './constants';
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';

const getUrlParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return {
    year: urlParams.get('year'),
    months: urlParams.get('months'),
  };
};

export const usePilotLogBook = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const initialMonth = getUrlParams().months;
  const year = getUrlParams().year || DEFAULT_YEAR;
  const months = initialMonth ? initialMonth.split(',') : DEFAULT_MONTH;
  const params = useParams();
  const navigate = useNavigate();
  const isPdf = params.isPdf && params.id;

  const numberMonths = MONTH_SHORT.reduce((total, amount, index) => {
    const monthIndex = [...total, months.includes(amount) && index + 1];
    const res = monthIndex.filter((e) => e);

    return res;
  }, []);

  const { data: crew, } = useGetCrewByIdQuery(params.id);
  const { data, isLoading: isListLoading } = useGetLogBookQuery(
    {
      data: stringifyLocationSearch({
        year,
        months: numberMonths,
      }),
      id: params.id,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const list = Object.keys(data?.data || {})
    .reduce(
      (res, key) => [...res, ...Object.keys(data?.data[key])
        .map((item, index) => ({ ...data?.data[key][item], id: item, ...(index === 0 && { date: key }) }))],
      []
    );

  const print = () => {
    const inputs = document.querySelectorAll('#forPdfFTL');

    inputs.forEach(input => {
      html2canvas(input, { scale: 1.2 })
        .then((canvas) => {
          const pdf = new JSPDF({
            orientation: 'landscape',
            unit: 'px',
            format: [1000, 1430]
          });
          const imgData = canvas.toDataURL('image/png');
          pdf.addImage(imgData, 'JPEG', 0, 0);
          pdf.save('download.pdf');
        });
    });
  };

  const handlePrint = () => {
    navigate(`/crew/log-book/pdf/${params.id}`);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isPdf) {
      print();
    }
  }, [isPdf]);

  return {
    list,
    isLoading: isListLoading,
    isPdf,
    isModalOpen,
    crew,
    data,
    months: numberMonths,
    year,
    handleOpen,
    handleClose,
    handlePrint,
  };
};

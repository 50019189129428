import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import AirlinesIcon from '@mui/icons-material/Airlines';
import {
  Box, Card,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import LinkButton from '../../../../components/LinkButton';
import {
  useGetAircraftListQuery,
  useGetStorageOrdersTableQuery, useStorePartMutation, useUpdateStorageOrderMutation,
} from '../../../../store/session';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { headerBtn, headerBtnS } from '../../../Invoices/style';
import { GreenButton, RedButton, TextWithIcon } from '../../style';
import PartTab from './tabs/part/PartTab';
import { defaultValues, schema } from './tabs/part/schema';
import PartInvoiceTab from './tabs/partInvoice/PartInvoiceTab';

const PartPage = () => {
  const params = useParams();
  const partId = params.id;
  const maxWidth1000px = useMediaQuery('(max-width:1000px)');
  const create = params.id.includes('create');
  const { data, isLoading: partLoading } = useGetStorageOrdersTableQuery(`/${partId}`, {
    skip: create
  });
  const { data: AircraftList, isLoading: aircraftLoading } = useGetAircraftListQuery();
  const aircraftOptions = AircraftList?.map((item) => ({
    value: item?.id,
    label: item?.aircraft_registration
  }));
  const isLoading = partLoading || aircraftLoading;
  const hasPermissions = usePermissions(['logistics_storage_write', 'logistics_storage_master']);
  const hasInternalPermissions = usePermissions('internal_logistics_storage_master'); 

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const partType = methods.watch('part_type');
  const isInstrument = !create && partType && partType === 'instrument';
  const isCreateInstrument = params.id.includes('instrument');

  useEffect(() => {
    if (data) {
      Object.keys(defaultValues).forEach(key => {
        if (key === 'aircraft_id') {
          const id = aircraftOptions?.find(e => e.label === data.aircraft)?.value;
          methods.setValue(key, id || 0);
        } else if (key === 'storage') {
          const storageValue = data?.[key];
          const value = storageValue.split(' - ')?.[0]?.toLowerCase();

          if (value) {
            methods.setValue(key, value);
          } else {
            methods.setValue(key, data?.[key]);
          }
        } else {
          methods.setValue(key, data[key] !== undefined && data[key] !== null ? data[key] : defaultValues[key]);
        }
      });
    }
  }, [data, AircraftList]);

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [updatePart, { isLoadingUpdatePart }] = useUpdateStorageOrderMutation();
  const [storePart, { isLoadingStorePart }] = useStorePartMutation();
  const isLoadingPart = isLoadingUpdatePart || isLoadingStorePart;
  const navigate = useNavigate();

  const onSubmit = async (_data) => {
    const toastId = toast.loading('Loading...');
    const sendData = {
      ..._data,
      aircraft_id: _data?.aircraft_id === '0' ? null : _data?.aircraft_id,
    };
    const res = !create ? await updatePart({ data: sendData, id: partId }) : await storePart({
      ...sendData,
      serial_number: (sendData?.serial_number === '') ? 'N/A' : sendData?.serial_number
    });

    if (res?.error) {
      toast.error(res?.error.data.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    navigate(`/logistics/orders-storage/${res?.data?.id}`);
    toast.success('Successfully!', {
      id: toastId,
    });
  };

  return (
    <>
      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <Box
          sx={{ ...headerBtn }}
        >
          <Box>
            <Typography noWrap variant="h4" sx={TextWithIcon}>
              <AirlinesIcon />
              {(isInstrument || isCreateInstrument) ? 'Instrument' : 'Part'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <LinkButton path="/logistics/orders-storage">
              <Button title="Back" sx={RedButton}></Button>
            </LinkButton>
            <Button
              title="Save"
              sx={GreenButton}
              onClick={methods.handleSubmit(onSubmit)}
              disabled={(!create && !hasPermissions && !hasInternalPermissions) || isLoadingPart}
            />
          </Box>

        </Box>
      </Card>

      {(!create && data?.order_id) && (
      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <Box
          sx={{ ...headerBtn, ...(maxWidth1000px && headerBtnS) }}
        >
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="part tabs">
            <Tab label="Part" />
            <Tab label="Part Invoices" />
            <Tab label="Delivery Invoices" />
          </Tabs>
        </Box>
      </Card>
      )}

      <Card sx={{ mt: 3, padding: '1rem' }}>
        <Box>
          {(activeTab === 0 && aircraftOptions?.length) && (
            <FormProvider {...methods}>
              <PartTab data={data} aircraftOptions={aircraftOptions} create={create} isLoading={isLoading} />
            </FormProvider>
          )}
          {activeTab === 1 && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {(isLoading && data?.order_id) ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <PartInvoiceTab part={partId} orderId={data.order_id} variant="part" />
              ) }
            </>
          )}
          {activeTab === 2 && (
            <>
              {(isLoading && data?.order_id) ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <PartInvoiceTab part={partId} orderId={data.order_id} variant="delivery" />
              ) }
            </>
          )}
        </Box>
      </Card>
    </>
  );
};

export default PartPage;

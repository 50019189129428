import { styled } from '@mui/material/styles';
import { Airplane } from 'mdi-material-ui';
import Copy from 'mdi-material-ui/ContentCopy';

export const StyledCopy = styled(Copy)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const StyledAirplane = styled(Airplane)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const StyledHeader = styled(Copy)(() => ({
  '.MuiDataGrid-columnHeaders': {
    minHeight: '300px',
  },
}));

export const StyledLink = {
  color: 'unset',
  textDecoration: 'none',
  height: '24px',
};

export const HeaderName = {
  fontSize: ' 0.75rem',
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontWeight: '500',
  letterSpacing: '0.17px',
};

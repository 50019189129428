import React from 'react';
import Box from '@mui/material/Box';
import ModalBase from '@mui/material/Modal';
import {
  closeIcon, headerSx, modalSx, titleSx, cancel, blockBtn, text,
} from './styles';
import Button from '../Button';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const DeleteModal = ({ open, onClose, onDelete }) => (
  <ModalBase
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={modalSx}>
      <Box sx={headerSx}>
        <Typography sx={titleSx}>
          Delete
        </Typography>
        <CloseIcon onClick={onClose} sx={closeIcon} />
      </Box>
      <Box sx={text}>
        Are you sure you want to delete this record?
      </Box>
      <Box sx={blockBtn}>
        <Button onClick={() => onDelete()} title="Delete" />
        <Button onClick={onClose} sx={cancel} title="Cancel" />
      </Box>
    </Box>
  </ModalBase>
);

export default DeleteModal;

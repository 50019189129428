import {
  object, string,
} from 'yup';

export const defaultValues = {
  currency: ''
};

export const schema = object().shape({
  currency: string().required('${label} cannot be blank.').label('Currency').nullable(),
});

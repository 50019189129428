
import AutocompleteMui from '@mui/material/Autocomplete';
import React, { useState } from 'react';
import { CloseIcon } from '../../Icons';
import Input from '../../Input/Input';

const FiltrationAutocomplete = ({
  options,
  placeholder,
  customIcon,
  isLoading,
  onChangeInput,
  popupIcon,
  sx,
  handleFilter,
  defaultValue,
  label,
  value,
  ...props
}) => {
  const [active, setActive] = useState(defaultValue);

  return (
    <AutocompleteMui
      {...props}
      isOptionEqualToValue={(option, e) => option.value === e?.value || option.label === e}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option?.label)}
      onInputChange={(event, newInputValue) => {
        event?.preventDefault();
        if (onChangeInput) {
          onChangeInput(newInputValue);
          handleFilter(onChangeInput.target.value, value);
        }
      }}
      clearIcon={<CloseIcon color="action" />}
      onChange={(e, data) => {
        e.preventDefault();
        handleFilter(data?.value || null, value);
        setActive(data?.label || null);
      }}
      loading={isLoading}
      options={options}
      value={active}
      size="small"
      fullWidth
      sx={sx}
      renderInput={(params) => (
        <Input
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onChange={(e) => {
            e.preventDefault();
            handleFilter(e.target.value, value);
            setActive(e.target.label);
          }}
          value={active}
          sx={
          customIcon
            ? {
              '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                right: 38,
                top: 'calc(50% - 16px)',
                width: '100%'
              },
            }
            : {}
        }
          placeholder={placeholder}
          {...params}
        />
      )}
      popupIcon={popupIcon || false}
    />
  ); };

export default FiltrationAutocomplete;

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Card,
  Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import Autocomplete from '../../../../form/components/Autocomplete/Autocomplete';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { useGetDepartmentInfoQuery, useUpdateDepartmentsMutation } from '../../../../store/slices/paymentDataSlice';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { defaultValues, schema } from './schema';

const UpdateDepartment = ({
  departmentId, setUpdateDepartment, userOptions, hasPermissions
}) => {
  const { data: departmentInfo } = useGetDepartmentInfoQuery({ id: departmentId }, {
    refetchOnMountOrArgChange: true
  });
  const [updateDepartment] = useUpdateDepartmentsMutation();
  const handleError = useHandleQueryFormError();

  const {
    handleSubmit,
    setError,
    getValues,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const newData = {
      ...data,
      leaders: data.leaders.map(item => ({ user_id: item?.value }))
    };

    const res = await updateDepartment({ id: departmentId, data: newData });

    if (res.error) {
      handleError(res.error, setError, getValues, toastId);

      return;
    }

    toast.success(`The ${departmentInfo?.name || 'Department'} was update!`, {
      id: toastId,
    });
  };

  useEffect(() => {
    if (departmentInfo) {
      setValue('name', departmentInfo?.name);
      setValue('leaders', departmentInfo?.leaders.map(item => ({
        value: item?.user_id,
        label: userOptions?.find(us => us?.value === item?.user_id)?.label
      })));
    }
  }, [departmentInfo]);

  return (
    <>
      <Card sx={{ padding: 4 }}>
        <Button onClick={() => setUpdateDepartment(null)}>Back</Button>
      </Card>
      <Card sx={{ padding: 4 }}>
        <Box>
          <Typography variant="h5">Change Department Name</Typography>
        </Box>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup
              label="Name"
              required
              hasError={!!errors.name}
            >
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input placeholder="Name" {...field} />
                )}
              />
            </FormGroup>
            <Box sx={{ mt: 8 }}>
              <Typography variant="h5">Change Department Leaders</Typography>
            </Box>
            <FormGroup
              label="Leaders"
              required
              hasError={!!errors.leaders}
            >
              <Controller
                name="leaders"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={userOptions}
                    size="small"
                    multiple
                    placeholder="Enter Leaders"
                    onChange={(e, value ) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
            </FormGroup>
            <Button type="submit" disabled={!hasPermissions}>Save</Button>
          </form>
        </Box>
      </Card>
    </>
  );
};

export default UpdateDepartment;

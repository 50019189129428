export const listContainer = {
  p: '4px',
  gap: '1px',
  display: 'flex',
  flexDirection: 'column',
};

export const listItem = {
  userSelect: 'none',
  cursor: 'pointer',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: '#000',
  padding: '20px 10px',
  whiteSpace: 'nowrap',
  '&:hover': {
    color: '#23527c'
  }
};

export const closeSx = {
  color: '#fff',
  background: '#000',
};

export const dropdownMenuSx = {
  '& .MuiPaper-root': {
    p: 0
  }
};

import { number, object, string } from 'yup';

export const defaultValues = {
  aircraft_id: null,
  storage: '',
  storage_number: '',
  storage_zone: '',
  description: '',
  part_number: '',
  part_name: '',
  quantity: '',
  unit_id: null,
  part_type: 'part',
  ttn_code: null,
  serial_number: '',
  part_state: '',
  exchange_due_date: '',
  due_date: '',
  instrument_id: '',
};

export const schema = object().shape({
  aircraft_id: string().nullable(),
  storage: string().required('Storage cannot be blank.'),
  storage_number: string().required('Storage Number cannot be blank.'),
  storage_zone: string().nullable(),
  description: string().nullable(),
  part_number: string().required('Part Number cannot be blank.'),
  part_name: string().required('Part Name cannot be blank.'),
  quantity: string()
    .when('part_type', {
      is: (val) => val === 'part' || val === 'consumable' || val === 'other',
      then: string().required('Quantity cannot be blank.'),
    }),
  part_type: string().required('Part Type cannot be blank.'),
  ttn_code: string().nullable(),
  serial_number: string(),
  part_state: number().required('Part State cannot be blank.').integer(),
  unit_id: number()
    .nullable()
    .when('part_type', {
      is: (val) => val === 'consumable',
      then: number().required('${label} cannot be blank if Training Finish selected.').integer(),
    })
});

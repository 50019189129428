import { object, string } from 'yup';

export const defaultValues = {
  aircraft_type: '',
  aircraft_id: '',
  date_from: '',
  date_to: '',
  invoice_status: '',
};

export const schema = object().shape({
  aircraft_type: string()
    .label('AC Type'),
  aircraft_id: string()
    .label('AC'),
  date_from: string()
    .required('${label} cannot be blank')
    .label('From'),
  date_to: string()
    .required('${label} cannot be blank')
    .label('To'),
  invoice_status: string()
    .label('Invoice status'),
});

import React from 'react';
import Card from '@mui/material/Card';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import moment from 'moment';
import TableDeck from './TableDeck';
import { MONTH_FORMAT } from './TableDeck/constants';
import ButtonSelect from '../../components/ButtonSelect';
import { TITLE } from './constants';
import { useMainDeckSchedule } from './useMainDeckSchedule';
import {
  accoriondSummarySx,
  accordionDetails,
  accordionSx,
  cardContainerSx,
} from './styles';

const MainDeckSchedule = () => {
  const {
    year, months, setYear, setMonths, data
  } = useMainDeckSchedule();

  return (
    <Card sx={cardContainerSx}>
      <h1>{TITLE}</h1>

      <ButtonSelect
        year={year}
        months={months}
        setYear={setYear}
        setMonths={setMonths}
        disabledSendRoster={false}
      />

      {!!data?.length && months.map(month => (
        <Accordion defaultExpanded sx={accordionSx}>
          <AccordionSummary
            aria-controls="table-accorion"
            id="table-accorion"
            sx={accoriondSummarySx}
          >
            {`${moment(`${month}-01-${year}`, 'MMM-DD-YYYY').format(MONTH_FORMAT)} ${year}`}
          </AccordionSummary>

          <AccordionDetails sx={accordionDetails}>
            <TableDeck data={data} date={`${month}-01-${year}`} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Card>
  );
};

export default MainDeckSchedule;

import {
  Box,
  CircularProgress,
  Table,
  TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import LinkButton from '../../../../components/LinkButton/Button';
import { useGetStatisticByProfitMutation } from '../../../../store/session';
import { columns } from './columns';
import {
  blue,
  header,
  tableCell,
  white,
} from './styles';

const DataTable = () => {
  const location = useLocation();

  const [rowData, setRowsData] = useState([]);

  const [getStatistic, { data, isLoading: isStatisticLoading }] = useGetStatisticByProfitMutation({
    fixedCacheKey: 'get-statistic-by-profit',
  });

  useEffect(() => {
    if (data) {
      const res = {
        acmi: data.overall.total_acmi,
        total_expenses: data.overall.total_expenses,
        flt_time: data.overall.total_flt_time,
        total_price: data.overall.total_price,
        profit: data.overall.total_profit,
        profit_percent: data.overall.total_profit_percent,
        from: 'Total',
        planing_id: uuid(),
      };

      setRowsData([...[res], ...data.data]);
    }
  }, [data]);

  useEffect(() => {
    if (!location.search) {
      setRowsData([]);
    }
  }, [location.search]);

  if (isStatisticLoading) {
    return (
      <Box sx={{
        display: 'flex', justifyContent: 'center', height: '100px'
      }}
      >
        <CircularProgress sx={{
          width: '100%', display: 'flex', justifyContent: 'center'
        }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{
      mt: '30px',
      height: '65vh',
      width: '100%',
      p: '0px 15px',
    }}
    >
      <TableContainer sx={{
        maxHeight: '100%',
      }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((item) => (
                <TableCell key={item.field} sx={header}>
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{ overflow: 'auto' }}
          >
            {rowData.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  white,
                  ...(index === 0 ? { ...blue, position: 'sticky', top: 38 } : null )
                }}
              >
                <TableCell sx={tableCell}>{item?.date}</TableCell>
                <TableCell sx={tableCell}>{item?.flt_num}</TableCell>
                <TableCell sx={tableCell}>{item?.from}</TableCell>
                <TableCell sx={tableCell}>{item?.to}</TableCell>
                <TableCell sx={tableCell}>{item?.atd}</TableCell>
                <TableCell sx={tableCell}>{item?.ata}</TableCell>
                <TableCell sx={tableCell}>{item?.flt_time}</TableCell>
                <TableCell sx={tableCell}>{item?.total_price}</TableCell>
                <TableCell sx={tableCell}>{item?.acmi}</TableCell>
                <TableCell sx={tableCell}>{item?.fuel}</TableCell>
                <TableCell sx={tableCell}>{item?.inv}</TableCell>
                <TableCell sx={tableCell}>{item?.nav}</TableCell>
                <TableCell sx={tableCell}>{item?.total_expenses}</TableCell>
                <TableCell sx={tableCell}>{item?.profit}</TableCell>
                <TableCell sx={tableCell}>{item?.profit_percent}</TableCell>
                <TableCell sx={tableCell}>
                  {item?.aircraft_name ? (
                    <LinkButton target="_blank" path={`/flights/${item?.flight_id}`} style={{ textDecoration: 'underline !important', }}>
                      Flight#
                      {' '}
                      {item?.flight_index}
                    </LinkButton>
                  ) : (
                    <Typography>{item?.flights_count}</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ); };

export default DataTable;

import Chip from '@mui/material/Chip';
import React from 'react';

export const STATES = [
  {
    label: 'In Queue',
    value: 'created',
    // eslint-disable-next-line react/jsx-filename-extension
    icon: (<Chip label="In Queue" size="small" color="info" sx={{ fontWeight: 'bolder' }} />)
  },
  {
    label: 'Processing...',
    value: 'in_process',
    icon: (<Chip label="Processing..." size="small" color="primary" sx={{ fontWeight: 'bolder' }} />)
  },
  {
    label: 'Created',
    value: 'generated',
    icon: (<Chip label="Created" size="small" color="success" sx={{ fontWeight: 'bolder' }} />)
  },
  {
    label: 'Failed',
    value: 'failed',
    icon: (<Chip label="Failed" size="small" color="error" sx={{ fontWeight: 'bolder' }} />)
  }
];

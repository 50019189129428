import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
// import { menuItem, invalidMenuItem, readonlyMenuItem } from './styles';
import Button from '../../../components/Button';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import TextEdit from '../../../components/EditableTable/TextEdit';
import {
  TYPE_INPUT, TYPE_MULTI_INPUT, TYPE_SELECT
} from '../../../components/EditableTable/constants';
import { HeaderName, StyledDelete } from '../../../components/EditableTable/styles';
import {
  RATE_TYPE_DUTY, RATE_TYPE_MONTH, RATE_TYPES, TYPE_OF_PAYMENTS
} from './constants';
import {GreenButton, RedButton} from '../../Logistics/style';
import { upperCase } from 'lodash';

export const columns = ({
  handleFilter,
  filters,
  handleOpen,
  handleOpenDeleteModal,
  setIsOpenFileDrawer,
  hasPermissions,
  handleOpenUpdateRate
}) => ([
  {
    flex: 0.2,
    field: 'name',
    minWidth: 140,
    headerName: 'Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Name</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.name || ''}
          handleFilter={handleFilter}
          placeholder="Name"
          value="name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'name', row)}
        text={row.name}
        disabled={!hasPermissions}
        labelInput="Enter Name"
        type={TYPE_INPUT}
      />
    ),
  }, // Name
  {
    flex: 0.2,
    field: 'rate_type',
    minWidth: 140,
    headerName: 'Rate Type',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Rate Type</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.rate_type || ''}
          handleFilter={handleFilter}
          placeholder="Rate Types"
          value="rate_type"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'rate_type', row)}
        text={upperCase(row.rate_type)}
        disabled={!hasPermissions}
        labelInput="Enter Type of payment"
        options={RATE_TYPES}
        activeItem={row.rate_type}
        type={TYPE_SELECT}
      />
    ),
  }, // Rate type
  {
    flex: 0.2,
    field: 'rate_data',
    minWidth: 140,
    headerName: 'Rate',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Rate</Typography>
      </Box>
    ),
    renderCell: ({ row }) => {
      const { rate_data } = row;

      return (
        <Box
          sx={{
            border: '1px solid',
            p: 2,
            borderRadius: '5px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#e9f0fe',
            },
          }}
          onClick={() => {
            if (hasPermissions) { handleOpenUpdateRate({
              rate_type: row?.rate_type,
              night_rate: row?.rate_data?.night_rate,
              day_rate: row?.rate_data?.day_rate,
              month_rate: row?.rate_data?.month_rate,
              name: row?.name,
              id: row.id
            }); }
          }}
        >
          {row.rate_type === RATE_TYPE_MONTH && (<Typography>{`Rate: ${rate_data?.month_rate ?? 'N/A'}`}</Typography>)}
          {row.rate_type === RATE_TYPE_DUTY && (<Typography>{`Day Rate: ${rate_data?.day_rate ?? 'N/A'}`}</Typography>)}
          {row.rate_type === RATE_TYPE_DUTY && (<Typography>{`Night Rate: ${rate_data?.night_rate ?? 'N/A'}`}</Typography>)}

        </Box>
      );
    },
  }, // Rate data
  {
    flex: 0.2,
    field: 'rate',
    minWidth: 140,
    headerName: 'Old Rate Data',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Old Rate Data</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      row?.rate
    ),
  }, // Old Rate
  {
    flex: 0.2,
    field: 'type_of_payment',
    minWidth: 140,
    headerName: 'Type of payment',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Type of payment</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.type_of_payment || ''}
          handleFilter={handleFilter}
          placeholder="Type of payment"
          value="type_of_payment"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'type_of_payment', row)}
        text={row.type_of_payment}
        disabled={!hasPermissions}
        labelInput="Enter Type of payment"
        options={TYPE_OF_PAYMENTS}
        activeItem={row.type_of_payment}
        type={TYPE_SELECT}
      />
    ),
  }, // Type of payment
  {
    flex: 0.2,
    field: 'payment_details',
    minWidth: 140,
    headerName: 'Payment details',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Payment details</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.payment_details || ''}
          handleFilter={handleFilter}
          placeholder="Payment details"
          value="payment_details"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'payment_details', row)}
        text={row.payment_details}
        disabled={!hasPermissions}
        labelInput="Enter Payment details"
        type={TYPE_MULTI_INPUT}
      />
    )
  }, // Payment details
  {
    flex: 0.2,
    field: 'comments',
    minWidth: 140,
    headerName: 'Comments',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Comments</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.comments || ''}
          handleFilter={handleFilter}
          placeholder="Comments"
          value="comments"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'comments', row)}
        text={row.comments}
        labelInput="Comments"
        disabled={!hasPermissions}
        type={TYPE_MULTI_INPUT}
      />
    ),
  }, // Comments
  {
    // flex: 0.2,
    field: 'file',
    minWidth: 120,
    headerName: 'Files',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Files</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Button
          sx={row?.payment_files_count ? (GreenButton) : (RedButton)}
          size="small"
          onClick={() => setIsOpenFileDrawer({ open: true, row })}
        >
          Files
        </Button>
      </Box>
    )
  }, // Attached Documents
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <StyledDelete onClick={() => {
          if (hasPermissions) handleOpenDeleteModal(row.id);
        }}
        />
      </Box>
    ),
  }, // actions
]);

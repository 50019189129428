import { useState } from 'react';

export const useMenuToggle = (handleMenuClose) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);

    if (handleMenuClose) {
      handleMenuClose();
    }
  };

  return {
    openMenu,
    closeMenu,
    anchorEl,
    open,
  };
};

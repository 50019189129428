import React from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import DatePicker from '../../../../form/components/DatePicker';
import Input from '../../../../form/components/Input';
import Upload from '../../../../components/Upload';
import {
  cardContainer,
  cardType, inputContainer, inputSx, rowSX, rowXs, inputContainerXs, link, uploadButton, uploadButtonSx, linkSx, loaderIcon, cardLoader, colorLink,
} from './styles';
import moment from 'moment';
import { validateDatePickerValue } from '../../../../utils/setDateValue';

const DOCUMENT_TYPE_COLORS = {
  1: { backgroundColor: '#ed6b75', color: '#fff', },
  2: { backgroundColor: '#F1C40F', color: '#010100', },
  3: { backgroundColor: '#36c6d3', color: '#fff', },
};

const Card = ({
  doc_name,
  alert_treshold,
  instance_path_loc,
  handleChangeExpired,
  handleUpload,
  dateExpired,
  dayLefts,
  item_id,
  isLoading,
  handleDownload,
  selectedId,
}) => {
  const maxWidth1024px = useMediaQuery('(max-width:1024px)');
  const maxWidth892px = useMediaQuery('(max-width:892px)');

  return (
    <Box sx={{ ...cardContainer, ...((isLoading && selectedId === item_id) && cardLoader) }}>
      {(isLoading && selectedId === item_id) && (
        <Box
          sx={loaderIcon}
        >
          <CircularProgress />
        </Box>
      ) }
      <Box sx={{ ...cardType, ...DOCUMENT_TYPE_COLORS[!instance_path_loc ? 2 : dayLefts ? 1 : 3] }}>
        {doc_name}
        {' '}
        {dayLefts}
      </Box>

      <Box sx={{ ...rowSX, ...(maxWidth1024px && rowXs) }}>
        <Box
          onClick={() => {
            if (instance_path_loc) {
              handleDownload(item_id, instance_path_loc);
            }
          }}
          sx={{ ...link, ...(maxWidth1024px && linkSx), ...(instance_path_loc && colorLink) }}
        >
          {instance_path_loc || 'Please select document for upload.'}
        </Box>
        <Box sx={{ ...uploadButton, ...(maxWidth1024px && uploadButtonSx) }}>
          <Upload
            accept="image/png, image/jpeg, application/pdf, application/doc"
            title="Document for upload (jpg, png, doc, pdf)"
            handleUpload={handleUpload}
          />
        </Box>
      </Box>

      <Box sx={{ ...inputContainer, ...(maxWidth892px && inputContainerXs) }}>
        <DatePicker
          placeholderText="YYYY-MM-DD"
          dateFormat="yyyy-MM-dd"
          showMonthDropdown
          showYearDropdown
          label="Expiring Date"
          onChange={handleChangeExpired}
          value={validateDatePickerValue(dateExpired)}
        />

        <Input
          disabled
          sx={inputSx}
          label="Expiring threshold days"
          value={alert_treshold || ''}
        />
      </Box>
    </Box>
  ); };

export default Card;

import React, { forwardRef, useEffect } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Cash } from 'mdi-material-ui';
import { useHasError } from '../FormGroup';

const PriceInput = forwardRef(
  ({
    onChange, value, ...props
  }, ref) => {
    const hasError = useHasError();

    useEffect(() => {
      if (value && value.includes(',')) {
        onChange(value.replace(',', '.'));
      }
    }, []);

    return (
      <TextField
        inputRef={ref}
        error={hasError}
        {...props}
        value={value ? value.replace(',', '.') : ''}
        onChange={(event) => {
          const regex = /^\d{0,8}(\.\d{0,2})?$/g;
          if (regex.test(event.target.value)) {
            onChange(event);
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Cash />
            </InputAdornment>
          ),
        }}
      />
    );
  },
);

export default PriceInput;

import DeleteIcon from '@mui/icons-material/Delete';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box, Card, Chip,
  CircularProgress,
  Divider, Grid, Typography
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import Upload from '../../../../components/Upload';
import {
  useAttachFilePaymentMutation, useDeleteFilePaymentMutation, useGetFilePaymentMutation, useGetPaymentDataQuery
} from '../../../../store/slices/paymentDataSlice';
import {
  RedButton, StatusHeader, TextWithIcon, TextWrap
} from '../../../Logistics/style';

const FileDrawer = ({
  open, onClose, currentData: { userId, payment_department_id, userName }, hasPermissions
}) => {
  const { data: paymentData, refetch } = useGetPaymentDataQuery({ userId, payment_department_id }, {
    refetchOnMountOrArgChange: true,
    skip: !userId
  });
  const [openFile, { isLoading: fileLoading }] = useGetFilePaymentMutation();
  const [deleteFile, { isLoading: isDeleteLoading }] = useDeleteFilePaymentMutation();
  const [attachFile, { isLoading: isAttachLoading }] = useAttachFilePaymentMutation();

  const handleOpenFile = async (fileId) => {
    const toastId = toast.loading('Opening...');

    await openFile(fileId).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleDeleteFile = async (fileId) => {
    const toastId = toast.loading('Deleting...');

    await deleteFile(fileId).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        refetch();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const uploadFile = async (e) => {
    if (!e.target.files[0]) return;
    const toastId = toast.loading('Uploading...');

    const formData = new FormData();

    formData.append('file', e.target.files[0]);

    const res = await attachFile({ id: paymentData.id, formData });
    e.target.value = null;

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    await refetch();
    toast.success('Successfully upload file!', {
      id: toastId
    });
  };

  return (
    <Drawer
      title="Files"
      open={open}
      onClose={onClose}
      sx={{
        '.MuiTypography-root': {
          margin: '0 auto'
        }
      }}
    >
      <Card sx={{ padding: 4 }}>
        <Card sx={{
          gap: '15px',
          backgroundColor: '#e9f0fe',
          padding: '10px',
          borderRadius: '8px'
        }}
        >
          <Box>
            <Box>
              <Typography variant="h5" noWrap sx={TextWithIcon}>
                <DocumentScannerIcon />
                {`${userName} Files`}
              </Typography>
              <Typography variant="subtitle2" noWrap>
                You can upload files to this user.
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {paymentData?.payment_files?.length ? (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    overflowY: 'auto',
                    maxHeight: '70vh'
                  }}
                >
                  {paymentData?.payment_files.map((file, index) => (
                    <Grid item xs={12} key={file.id} sx={{ backgroundColor: 'white', margin: '10px', borderRadius: '8px' }}>
                      <Box sx={StatusHeader}>
                        <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '70%' }}>
                          {`#${index + 1}`}

                          <Chip label={file?.name} sx={{ ...TextWrap, ml: 2 }} />
                        </Box>

                        <Box sx={{ margin: '0.2rem' }}>
                          <Button
                            key={file.id}
                            startIcon={!fileLoading ? <OpenInNewIcon /> : <CircularProgress size={15} color="inherit" />}
                            disabled={fileLoading}
                            title="Open"
                            size="small"
                            onClick={() => handleOpenFile(file.id)}
                          />
                          <Button
                            startIcon={<DeleteIcon />}
                            sx={{ marginLeft: '0.5rem', ...(RedButton) }}
                            onClick={() => handleDeleteFile(file.id)}
                            disabled={!hasPermissions || isDeleteLoading}
                            title="Delete"
                            size="small"
                          />
                        </Box>
                      </Box>
                      <Box sx={{
                        display: 'flex', justifyContent: 'flex-end', margin: '0.5rem'
                      }}
                      >
                        <Typography
                          noWrap
                          variant="subtitle2"
                          sx={{
                            margin: '0!important'
                          }}
                        >
                          {`Uploaded at: ${moment(file?.updated_at).format('YYYY-MM-DD hh:mm:ss')}`}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography variant="subtitle1" bold noWrap sx={{ textAlignLast: 'center' }}>
                  No files uploaded yet.
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sx={{ textAlignLast: 'center' }}>
              <Upload
                sx={{ marginTop: '1rem' }}
                disabled={!hasPermissions || isAttachLoading}
                handleUpload={uploadFile}
                title="Upload New File"
              />
            </Grid>
          </Grid>

        </Card>
      </Card>

    </Drawer>
  );
};

export default FileDrawer;

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useGetAirportListQuery, useGetFlightFuelAirportQuery } from '../../../../../../../store/session';
import { useEffect } from 'react';

const FuelCostVariables = ['plfuel_real_amount', 'plfuel_price'];

export const useFuelForm = () => {
  const {
    control, Controller, setValue, watch
  } = useFormContext();

  const {
    fields,
  } = useFieldArray({
    name: 'planing_fuel',
    control,
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const field = name.split('.');

      if (FuelCostVariables.includes(field[2]) && type === 'change') {
        const plfuel_price = Number(value.planing_fuel[field[1]].plfuel_price);
        const plfuel_real_amount = Number(value.planing_fuel[field[1]].plfuel_real_amount);
        const calc_cost = plfuel_price * plfuel_real_amount * 0.264172052;

        setValue(`planing_fuel.${field[1]}.plfuel_real_fullcost`, calc_cost.toFixed(2));
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const airports = fields?.map(item => item.plfuel_ap_id)?.join(',');
  const { data: airportList } = useGetAirportListQuery();
  const { data: flightFuel } = useGetFlightFuelAirportQuery(airports);

  return {
    Controller,
    control,
    fields,
    airportList,
    flightFuel,
    setValue,
  };
};

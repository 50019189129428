import {
  Box, Card, CardContent, Typography
} from '@mui/material';
import React, { useState } from 'react';
import EditableTable from '../../../../../components/EditableTable';
import { cardContent } from '../../../../../components/EditableTable/styles';
import {
  useDeleteTaskToolsMutation, useGetTaskToolsListQuery, useUpdateTaskToolsMutation,
} from '../../../../../store/session';
import { columns } from './columns';
import { useParams } from 'react-router-dom';
import Button from '../../../../../components/Button';
import CreateTaskToolsDrawer from './CreateTaskToolsDrawer';

export const TaskToolsList = ({ hasPermissionsWoEdit }) => {
  const params = useParams();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const tableParams = { wo_task_id: params?.id };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Box>
      <Card sx={{ marginTop: '35px' }}>
        <CardContent sx={cardContent}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">Tools & Equipment List</Typography>
            <Button disabled={!hasPermissionsWoEdit} onClick={handleOpenDrawer} title="Add" />
          </Box>

          <EditableTable
            useHook={useGetTaskToolsListQuery}
            useHookDelete={useDeleteTaskToolsMutation}
            useHookUpdate={useUpdateTaskToolsMutation}
            columns={columns}
            tableParams={tableParams}
            hasPermissionsWoEdit={hasPermissionsWoEdit}
            sortByType="DESC"
            sortByActive="wo_item_text"
          />
        </CardContent>
      </Card>

      <CreateTaskToolsDrawer open={isDrawerOpen} onClose={handleOpenDrawer} />
    </Box>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  Dialog, DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../components/Button';
import FormGroup from '../../../form/components/FormGroup';
import Input from '../../../form/components/Input';
import { useChangeFeesMutation } from '../../../store/session';
import { headerBtn } from '../../Invoices/style';
import { CloseIconStyle } from '../../Logistics/OrdersDashboard/styles';
import { FEES_TYPE_EMPTY, FEES_TYPE_LOADED } from '../constants';
import { defaultValues, schema } from './updateFeesSchema';

const UpdateFees = ({
  onClose, onSubmit, feesData
}) => {
  const [updateFees] = useChangeFeesMutation();

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  useEffect(() => {
    setValue('fees_e_atr42', feesData?.airport_fees_e_data?.atr42);
    setValue('fees_e_atr72', feesData?.airport_fees_e_data?.atr72);
    setValue('fees_f_atr42', feesData?.airport_fees_f_data?.atr42);
    setValue('fees_f_atr72', feesData?.airport_fees_f_data?.atr72);
  }, [feesData]);

  const onSubmitForm = async (data) => {
    const toastId = toast.loading('Loading...');
    let updateData = null;
    if (feesData?.type === FEES_TYPE_EMPTY) {
      if (!data.fees_e_atr42 || !data.fees_e_atr72) {
        toast.error('Fees empty data cannot be blank or zero.', { id: toastId });

        return;
      }
      updateData = {
        airport_fees_e_data: {
          atr42: data.fees_e_atr42,
          atr72: data.fees_e_atr72
        },
      };
    }
    if (feesData?.type === FEES_TYPE_LOADED) {
      if (!data.fees_f_atr42 || !data.fees_f_atr72) {
        toast.error('Fees loaded data cannot be blank or zero.', { id: toastId });

        return;
      }
      updateData = {
        airport_fees_f_data: {
          atr42: data.fees_f_atr42,
          atr72: data.fees_f_atr72
        },
      };
    }
    if (!updateData) {
      return;
    }
    const { error } = await updateFees({ id: feesData?.id, data: updateData });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId
    });
    reset();
    onClose();
    onSubmit();
  };

  return (
    <Dialog
      fullWidth
      open={!!feesData}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogContent>
        <Card sx={{ padding: 4 }}>
          <Box sx={{ ...headerBtn, display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant="h5" noWrap>
                {`Update Fees - ${feesData?.type}`}
              </Typography>
            </Box>
            <CloseIcon
              sx={CloseIconStyle}
              onClick={onClose}
            />
          </Box>

          <Divider />
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Grid container spacing={4}>
              {feesData?.type === FEES_TYPE_EMPTY && (
                <>
                  <Grid item xs={6}>
                    <FormGroup
                      label="ATR-42"
                      required
                      hasError={!!errors.fees_e_atr42}
                    >
                      <Controller
                        name="fees_e_atr42"
                        control={control}
                        render={({ field }) => (
                          <Input type="number" placeholder="ATR-42 Empty" {...field} />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <FormGroup
                      label="ATR-72"
                      required
                      hasError={!!errors.fees_e_atr72}
                    >
                      <Controller
                        name="fees_e_atr72"
                        control={control}
                        render={({ field }) => (
                          <Input type="number" placeholder="ATR-72 Empty" {...field} />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                </>
              )}
              {feesData?.type === FEES_TYPE_LOADED && (
                <>
                  <Grid item xs={6}>
                    <FormGroup
                      label="ATR-42"
                      required
                      hasError={!!errors.fees_f_atr42}
                    >
                      <Controller
                        name="fees_f_atr42"
                        control={control}
                        render={({ field }) => (
                          <Input type="number" placeholder="ATR-42 Loaded" {...field} />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <FormGroup
                      label="ATR-72"
                      required
                      hasError={!!errors.fees_f_atr72}
                    >
                      <Controller
                        name="fees_f_atr72"
                        control={control}
                        render={({ field }) => (
                          <Input type="number" placeholder="ATR-72 Loaded" {...field} />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                </>
              )}
            </Grid>
            <Button type="submit" title="Submit" />
          </form>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateFees;

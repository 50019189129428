import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Card,
  CardContent,
  CardHeader, Typography,
  Grid,
} from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import toast from 'react-hot-toast';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import {
  useUpdateCompanyMutation,
  useGetCompanyMutation,
  useStoreCompanyMutation,
} from '../../../store/session';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, defaultValues } from './schema';
import FormGroup from '../../../form/components/FormGroup';
import Input from '../../../form/components/Input';
import FieldError from '../../../form/components/FieldError';
import PersonsForm from './form/PersonsForm';

const CompanyForm = () => {
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const update = id !== 'create';
  const [updateCompany] = useUpdateCompanyMutation();
  const [storeCompany] = useStoreCompanyMutation();
  const [getCompany] = useGetCompanyMutation();
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });
  const {
    fields: personFields,
    append: personAppend,
    remove: personRemove,
    replace: personUpdate,
  } = useFieldArray({
    name: 'persons',
    control,
  });

  const personRepeaterProps = {
    personFields,
    personAppend,
    personRemove,
    personUpdate,
  };
  const formProps = {
    setValue, control, Controller, errors, trigger, getValues, watch,
  };
  const handleCompanyData = async () => {
    setLoading(true);
    const { data: result } = await getCompany({ id });

    return result;
  };
  useEffect(() => {
    if (update) {
      handleCompanyData().then((result) => {
        if (result) {
          Object.entries(result).forEach(
            ([name, value]) => setValue(name, value),
          );
        }
        setLoading(false);
      });
    }
  }, [update]);

  if (isLoading) {
    return <ContentPageLoader />;
  }

  const onSubmit = async (data) => {
    if (update) {
      const res = await updateCompany({
        ...data,
      });
      if (!res?.error) {
        toast.success('Client updated!');
        navigate('/directories/company');
      } else {
        toast.error('Something went wrong');
      }
    } else {
      const res = await storeCompany({
        ...data,
      });
      if (!res?.error) {
        toast.success('Client  created!');
        navigate('/directories/company');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 3 }}>
        <CardHeader title="Create Client" />
        <CardContent sx={{ mt: 5 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Name" required hasError={!!errors.company_name}>
                <Controller
                  name="company_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Name"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.company_name} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="VAT" required hasError={!!errors.company_vat}>
                <Controller
                  name="company_vat"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="VAT"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.company_vat} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="State Registration number" required hasError={!!errors.company_registration}>
                <Controller
                  name="company_registration"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="State Registration number"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.company_registration} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Shortname" required hasError={!!errors.company_shortname}>
                <Controller
                  name="company_shortname"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Shortname"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.company_shortname} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Phone" required hasError={!!errors.company_phone}>
                <Controller
                  name="company_phone"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Phone"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.company_phone} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Website" required hasError={!!errors.company_website}>
                <Controller
                  name="company_website"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="www.privacy.com"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.company_website} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Fax" required hasError={!!errors.company_fax}>
                <Controller
                  name="company_fax"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Fax"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.company_fax} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Email" required hasError={!!errors.company_email}>
                <Controller
                  name="company_email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="company@gmail.com"
                      type="email"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.company_email} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup label="Email for invoice" required hasError={!!errors.company_inv_email}>
                <Controller
                  name="company_inv_email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="invoice@gmail.com"
                      type="email"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.company_inv_email} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormGroup label="Company address" hasError={!!errors.company_address}>
                <Controller
                  name="company_address"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Company address"
                      multiline
                      rows={4}
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.company_address} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormGroup label="Billing address" hasError={!!errors.company_biladdress}>
                <Controller
                  name="company_biladdress"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Company Billing address"
                      multiline
                      rows={4}
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.company_biladdress} />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 21 }} color="text.secondary" gutterBottom>
                Persons
              </Typography>
              <Divider />
              <Card sx={{ mt: '1.25rem' }}>
                <TabContext value="1">
                  <CardContent sx={{ padding: '0rem' }}>
                    <TabPanel value="1" sx={{ padding: '0rem' }}>
                      <PersonsForm {...formProps} {...personRepeaterProps} />
                    </TabPanel>
                  </CardContent>
                </TabContext>
              </Card>
              <Divider />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" size="large">
              {update ? 'Update' : 'Save' }
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default CompanyForm;

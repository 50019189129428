export const footerSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  p: '20px'
};

export const cancelSx = {
  background: '#e1e5ec'
};

export const numberInputSx = {
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  }
};

export const formControlLabel = {
  ml: '0'
};

export const drawerSx = {
  '& .MuiPaper-root': {
    width: '100%',
  }
};

export const headerSx = {
  minHeight: '43px',
  display: 'flex',
  gap: '15px',
  padding: '9px 11px',
  borderBottom: '1px solid #eee',
  width: '100%',
  mb: '20px',
  a: {
    textDecoration: 'none',
    color: 'rgba(0, 70, 136, 0.8)'
  }
};

import {
  Box,
  Typography,
  useMediaQuery
} from '@mui/material';
import React from 'react';
import { InvoiceBoxStyle } from '../Invoices';
import { headerTitle, headerTitleS } from '../Invoices/style';
import DocumentsForm from './DocumentsForm';
import { asterisk } from './style';

const OdsDocuments = () => {
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  return (
    <Box sx={InvoiceBoxStyle}>
      <Box sx={{ ...headerTitle, ...(maxWidth800px && headerTitleS) }}>
        <Box>
          <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
            Manage User documents
          </Typography>
  
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              textTransform: 'capitalize', display: 'flex', mb: 2, 'fontWeight': 600
            }}
          >
            Fields with
            {' '}
            <Typography sx={asterisk}>*</Typography>
            {' '}
            are required.
          </Typography>
        </Box>
      </Box>
      <DocumentsForm />
    </Box>
  );
};

export default OdsDocuments;

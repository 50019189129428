import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card, Grid, useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import { DATE_FORMAT } from '../../../../constans/formats';
import Checkbox from '../../../../form/components/Checkbox';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { useCreateWorkOrderMutation } from '../../../../store/session';
import { defaultValues, schema } from './schema';
import {
  cancelSx, card,
  drawer,
  footerSx, gridContainer
} from './style';

const WoForm = ({ open, onClose, aircraft = [] }) => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  const [createWorkOrder, { isLoading }] = useCreateWorkOrderMutation();
  const currentAircraft = aircraft?.find(({ id }) => +id === +params.id)?.aircraft_registration;
  const maxWidth768px = useMediaQuery('(max-width:840px)');
  const maxWidth900px = useMediaQuery('(max-width:900px)');

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const reqData = {
      ...data,
      aircraft_id: params.id,
      wo_date: moment(data.wo_date).format(DATE_FORMAT),
    };

    const res = await createWorkOrder(reqData);

    if (res.error) {
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    onClose();
    reset();

    navigate(`/maintenance/work-order/update/${res.data.id}`);

    toast.success('Successfully created!', {
      id: toastId,
    });
  };

  return (
    <Drawer
      title={`Create Work Order ${currentAircraft}`}
      open={open}
      onClose={onClose}
      sx={{ ...(maxWidth900px && drawer ) }}
    >
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        sx={{ p: '20px' }}
      >
        <Card sx={card}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={5} rowSpacing={2} sx={gridContainer}>

              <Grid item xs={maxWidth768px ? 12 : 6}>
                <FormGroup>
                  <Controller
                    render={({ field }) => (
                      <Checkbox
                        label="Choose negative period"
                        {...field}
                      />
                    )}
                    control={control}
                    name="negative_period"
                  />

                </FormGroup>
              </Grid>

              <Grid item xs={maxWidth768px ? 12 : 6}>
                <FormGroup>
                  <Controller
                    render={({ field }) => (
                      <Checkbox
                        label="Create Single WO"
                        {...field}
                      />
                    )}
                    control={control}
                    name="single_wo"
                  />

                </FormGroup>
              </Grid>

              {watch('negative_period') && (
                <Grid item xs={maxWidth768px ? 12 : 6}>
                  <FormGroup label="DAYS TRESHOLD[-]" required hasError={!!errors.days_left}>
                    <Controller
                      render={({ field } ) => (
                        <Input
                          {...field}
                          disabled={watch('single_wo')}
                        />
                      )}
                      control={control}
                      name="days_left"
                    />
                    <FieldError error={errors.days_left} />
                  </FormGroup>
                </Grid>
              )}

              <Grid item xs={maxWidth768px ? 12 : watch('negative_period') ? 6 : 12}>
                <FormGroup label="DAYS TRESHOLD[+]" required hasError={!!errors.days_right}>
                  <Controller
                    render={({ field } ) => (
                      <Input
                        {...field}
                        disabled={watch('single_wo')}
                      />
                    )}
                    control={control}
                    name="days_right"
                  />
                  <FieldError error={errors.days_right} />
                </FormGroup>
              </Grid>

              {watch('negative_period') && (
                <Grid item xs={maxWidth768px ? 12 : 6}>
                  <FormGroup label="HOURS TRESHOLD [-]" required hasError={!!errors.hours_left}>
                    <Controller
                      render={({ field } ) => (
                        <Input
                          {...field}
                          disabled={watch('single_wo')}
                        />
                      )}
                      control={control}
                      name="hours_left"
                    />
                    <FieldError error={errors.hours_left} />
                  </FormGroup>
                </Grid>
              )}

              <Grid item xs={maxWidth768px ? 12 : watch('negative_period') ? 6 : 12}>
                <FormGroup label="HOURS TRESHOLD[+]" required hasError={!!errors.hours_right}>
                  <Controller
                    render={({ field } ) => (
                      <Input
                        {...field}
                        disabled={watch('single_wo')}
                      />
                    )}
                    control={control}
                    name="hours_right"
                  />
                  <FieldError error={errors.hours_right} />
                </FormGroup>
              </Grid>

              {watch('negative_period') && (
                <Grid item xs={maxWidth768px ? 12 : 6}>
                  <FormGroup label="FLIGHT CYC TRESHOLD[-]" required hasError={!!errors.cyc_treshold_left}>
                    <Controller
                      render={({ field } ) => (
                        <Input
                          {...field}
                          disabled={watch('single_wo')}
                        />
                      )}
                      control={control}
                      name="cyc_treshold_left"
                    />
                    <FieldError error={errors.cyc_treshold_left} />
                  </FormGroup>
                </Grid>
              )}

              <Grid item xs={maxWidth768px ? 12 : watch('negative_period') ? 6 : 12}>
                <FormGroup label="FLIGHT CYC TRESHOLD[+]" required hasError={!!errors.cyc_treshold_right}>
                  <Controller
                    render={({ field } ) => (
                      <Input
                        {...field}
                        disabled={watch('single_wo')}
                      />
                    )}
                    control={control}
                    name="cyc_treshold_right"
                  />
                  <FieldError error={errors.cyc_treshold_right} />
                </FormGroup>
              </Grid>

              <Grid item xs={maxWidth768px ? 12 : 6}>
                <FormGroup label="WO DATE" required hasError={!!errors.wo_date}>
                  <Controller
                    render={({ field } ) => (
                      <DatePicker
                        placeholderText="YYYY-MM-DD"
                        dateFormat="yyyy-MM-dd"
                        showMonthDropdown
                        showYearDropdown
                        {...field}
                      />
                    )}
                    control={control}
                    name="wo_date"
                  />
                  <FieldError error={errors.wo_date} />
                </FormGroup>
              </Grid>

            </Grid>
            <Box sx={{ ...footerSx, }}>
              <Button type="submit" disabled={isLoading} title="Create WO" />
              <Button sx={cancelSx} onClick={onClose} title="Cancel" />
            </Box>
          </form>
        </Card>
      </Grid>
    </Drawer>

  );
};

export default WoForm;

export const FilterContainer = { backgroundColor: '#dfe6f4', margin: '10px', borderRadius: '5px' };

export const StyledTableFilter = {
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px'
};

export const SelectFilterStyle = { minWidth: '150px', backgroundColor: 'white', borderRadius: '8px' };

export const AutocompleteFilterStyle = { minWidth: '250px', backgroundColor: 'white', borderRadius: '8px' };

export const DatePickerFilterStyle = {
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '8px'
};

export const FilterCardHeader = { pb: 4, '& .MuiCardHeader-title': { letterSpacing: '.15px', fontSize: '28px' } };

export const StyledSwitch = {
  width: '60px',
  height: '34px',
  padding: '0',
  '& > .MuiButtonBase-root': { 
    left: '4px',
    bottom: '0px',
    padding: '0'
  },
  '& > .MuiSwitch-track': { 
    backgroundColor: '#fff',
    borderRadius: '34px',
    opacity: '1!important'
  },
  '& .MuiSwitch-thumb': { 
    color: 'rgb(54, 145, 139)!important',
    width: '26px',
    height: '26px'
  },
  '& .Mui-checked': { 
    transform: 'translateX(26px)'
  }
};
export const accordionSx = {
  borderBottomLeftRadius: '0',
  borderBottomRightRadius: '0',
  '&.Mui-expanded': {
    m: 0,
  },
};

export const accordionSummarySx = {
  '&.MuiAccordionSummary-root': {
    p: '6px 12px',
    backgroundColor: '#F2F2FF',
    height: '35px',
    minHeight: '35px',
    '&:hover, &.Mui-expanded': {
      backgroundColor: '#286090',
      color: '#fff',

      '& .MuiAccordionSummary-content': {
        color: '#fff'
      }
    }
  },
  '& .MuiAccordionSummary-content': {
    m: 0,
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    color: '#000',
    textTransform: 'uppercase',
    '&:hover': {
      color: '#fff',
    }
  }
};

export const accordionDetails = {
  '&.MuiAccordionDetails-root': {
    p: 0
  }
};
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FiltrationSelect from '../../components/EditableTable/FiltrationSelect';
import {
  TYPE_INPUT, TYPE_SELECT_DATE
} from '../../components/EditableTable/constants';
import FiltrationInput from '../../components/EditableTable/FiltrationInput';
import TextEdit from '../../components/EditableTable/TextEdit';
import {
  HeaderName, StyledDelete,
} from '../../components/EditableTable/styles';
import { Link } from 'react-router-dom';

export const columns = ({
  handleFilter,
  filters,
  handleOpen,
  fuelOptions,
  aircraftOptions,
  handleOpenDeleteModal
}) => ([

  {
    flex: 0.1,
    field: 'id',
    minWidth: 100,
    headerName: 'ID',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>ID</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.fuel_id || ''}
          value="fuel_id"
          placeholder="ID"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.id}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'fuel_date',
    minWidth: 120,
    headerName: 'Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Date</Typography>

      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.fuel_date}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'fuel_prefix',
    minWidth: 80,
    headerName: 'Flight No',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Flight No</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        <Link style={{ 'textDecoration': 'none' }} to={`/flights/${row.flights_id}`} target="_blank">{row.fuel_prefix}</Link>
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'flights_id',
    minWidth: 90,
    headerName: 'Flight',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Flight</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.fuel_flight_id || ''}
          value="fuel_flight_id"
          placeholder="Flight No"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        <Link style={{ 'textDecoration': 'none' }} to={`/flights/${row.flights_id}`} target="_blank">{row.flights_id}</Link>
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'aircraft_name',
    minWidth: 100,
    headerName: 'Aircraft',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Aircraft</Typography>
        <FiltrationSelect
          options={aircraftOptions}
          defaultValue={filters.fuel_aircraft_id || ''}
          handleFilter={handleFilter}
          value="fuel_aircraft_id"
          typeOptions="number"
          placeholder="Aicraft"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.aircraft_name}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'airport_name',
    minWidth: 120,
    headerName: 'Airport',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Airport</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.fuel_airport || ''}
          value="fuel_airport"
          placeholder="Airport"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.airport_name}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'fuel_suplier',
    minWidth: 110,
    headerName: 'Supplier',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Supplier</Typography>
        <FiltrationSelect
          options={fuelOptions}
          defaultValue={filters.fuel_supplier_id || ''}
          handleFilter={handleFilter}
          value="fuel_supplier_id"
          typeOptions="number"
          placeholder="Supplier"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.fuel_suplier}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'plfuel_price',
    minWidth: 80,
    headerName: 'Price',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Price</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.fuel_price || ''}
          value="fuel_price"
          placeholder="Price"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'plfuel_price', row)}
        text={row.plfuel_price}
        labelInput="Enter Price"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.1,
    field: 'plfuel_real_amount',
    minWidth: 110,
    headerName: 'Uplift, I',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Uplift, I</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.fuel_real_amount || ''}
          value="fuel_real_amount"
          placeholder="Uplift, I"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'plfuel_real_amount', row)}
        text={row.plfuel_real_amount}
        labelInput="Enter Uplift, I"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.1,
    field: 'plfuel_real_fullcost',
    minWidth: 110,
    headerName: 'Cost, USD',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Cost, USD</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.fuel_real_fullcost || ''}
          value="fuel_real_fullcost"
          placeholder="Cost, USD"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'plfuel_real_fullcost', row)}
        text={row.plfuel_real_fullcost}
        labelInput="Enter Cost, USD"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.1,
    field: 'plfuel_invoice_num',
    minWidth: 120,
    headerName: 'Invoice num ',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoice num</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.fuel_invoice_num || ''}
          value="fuel_invoice_num"
          placeholder="Invoice num"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'plfuel_invoice_num', row)}
        text={row.plfuel_invoice_num}
        labelInput="Enter Invoice num"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.1,
    field: 'plfuel_payment_date',
    minWidth: 120,
    headerName: 'Invoice date ',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoice date</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.fuel_payment_date || ''}
          value="fuel_payment_date"
          placeholder="Invoice date"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'plfuel_payment_date', row)}
        text={row.plfuel_payment_date}
        labelInput="Enter Invoice date"
        type={TYPE_SELECT_DATE}
      />
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
    ),
  },

]);

import React, { useEffect, useState } from 'react';
import {
  Box, Card, Grid, IconButton, InputAdornment, useMediaQuery
} from '@mui/material';
import { TitleContainer } from '../../../Crew/styles';
import { Controller, useForm } from 'react-hook-form';
import FieldError from '../../../../form/components/FieldError';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, defaultValues } from './schema';
import { useChangePasswordMutation, useGetUserMutation } from '../../../../store/session';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Input from '../../../../form/components/Input';
import Button from '../../../../components/Button';
import ContentPageLoader from '../../../../components/Loader/ContentPageLoader';
import FormGroup from '../../../../form/components/FormGroup';
import Checkbox from '../../../../form/components/Checkbox';

const UserChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCheck, setShowPasswordCheck] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => { event.preventDefault(); };
  const handleClickShowPasswordCheck = () => setShowPasswordCheck((show) => !show);
  const handleMouseDownPasswordCheck = (event) => { event.preventDefault(); };

  const [getUser, { data: userData, isLoading }] = useGetUserMutation();
  const [changePassword, { isLoading: isChangePassLoading }] = useChangePasswordMutation();

  const maxWidth768px = useMediaQuery('(max-width:768px)');

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data) => {
    const res = await changePassword({ id, data });

    if (res.error) {
      toast.error(res.error.data.message || 'Something went wrong');

      return;
    }

    toast.success('Successfully!');
    navigate('/manage-users');
  };

  useEffect(() => {
    getUser({ id });
  }, [id]);

  if (isLoading) return <ContentPageLoader />;

  return (
    <Card sx={{ padding: '1rem' }}>
      <Box sx={TitleContainer}>
        <h1>
          Change user password
          {' '}
          {userData?.username}
        </h1>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item xs={maxWidth768px ? 12 : 7} sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <FormGroup label="Notify User" hasError={!!errors.notify_user}>
              <Controller
                name="notify_user"
                control={control}
                render={({ field }) => (<Checkbox {...field} />)}
              />
              <FieldError error={errors.notify_user} />
            </FormGroup>

            <FormGroup label="Password" required hasError={!!errors.password}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />

              <FieldError error={errors.password} />
            </FormGroup>

            <FormGroup label="Password check" required hasError={!!errors.password_confirmation}>
              <Controller
                name="password_confirmation"
                control={control}
                render={({ field }) => (
                  <Input
                    type={showPasswordCheck ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordCheck}
                            onMouseDown={handleMouseDownPasswordCheck}
                            edge="end"
                          >
                            {showPasswordCheck ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Password check"
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.password_confirmation} />
            </FormGroup>
          </Grid>

          <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
            <Button disabled={isChangePassLoading} title="Save" type="submit" />
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

export default UserChangePassword;

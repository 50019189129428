import {
  object, string,
} from 'yup';

export const defaultValues = {
  department_name: ''
};

export const schema = object().shape({
  department_name: string().required('${label} cannot be blank.').label('Department Name').nullable(),
});

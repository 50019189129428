import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
  Box, Chip, Dialog, DialogContent, Grid, InputAdornment, TextField, Typography
} from '@mui/material';
import { Rename } from 'mdi-material-ui';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { CloseIcon } from '../../../../components/Icons';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import Select from '../../../../form/components/Select';
import { useAppDispatch } from '../../../../hooks/toolkitHooks';
import { fetchCurrencies } from '../../../../store/slices/logisticsSlice';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import { headerBtn } from '../../../Invoices/style';
import { content } from '../../../Logistics/MyOrders/NewOrder/components/style';
import { CloseIconStyle, RightMenuStyle } from '../../../Logistics/OrdersDashboard/styles';
import { GreenButton, RedButton } from '../../../Logistics/style';
import { INVOICE_TYPE, PAYMENT_METHOD } from '../../constants';
import { useNewOrderForm } from './useNewInvoiceForm';

const CreateInvoice = ({
  open, onClose, refresh, currentInvoice 
}) => {
  const dispatch = useAppDispatch();
  const {
    formMethods: {
      setValue,
      setError,
      getValues,
      reset,
      control,
      formState: { errors }
    },
    invoiceData,
    defaultValues,
    handleSubmit,
    onSubmit,
    onUpdate
  } = useNewOrderForm({ refresh, onClose, currentInvoice });

  useEffect(() => {
    if (open && currentInvoice) {
      setValue('invoice_data', currentInvoice?.invoice_data);
      setValue('currentInvoice', !!currentInvoice);
      setValue('currency_id', currentInvoice?.currency_id);
    }
  }, [open]);

  useEffect(() => {
    dispatch(fetchCurrencies());
  }, []);
  const { currencies } = useSelector((state) => state.logistics);

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
    >
      <form>
        <Grid container spacing={6}>
          <Grid item xs={12} sx={{ ...(RightMenuStyle), mt: 5 }}>
            <CloseIcon
              sx={CloseIconStyle}
              onClick={onClose}
            />
          </Grid>

          <Grid item xs={12}>
            <DialogContent>
              <Grid container spacing={2}>
                {!currentInvoice && (
                <Grid item xs={12} md={4}>
                  <FormGroup required label="Invoice Type" hasError={!!errors.invoice_type}>
                    <Controller
                      name="invoice_type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="Invoice Type"
                          {...field}
                          options={INVOICE_TYPE}
                        />
                      )}
                    />
                    <FieldError error={errors.invoice_type} />
                  </FormGroup>
                </Grid>
                )}

                {!currentInvoice && (
                <Grid item xs={12} md={4}>
                  <FormGroup required label="Payment Method" hasError={!!errors.payment_method}>
                    <Controller
                      name="payment_method"
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="Payment Method"
                          {...field}
                          options={PAYMENT_METHOD}
                        />
                      )}
                    />
                    <FieldError error={errors.payment_method} />
                  </FormGroup>
                </Grid>
                )}

                {!currentInvoice && (
                <Grid item xs={12} md={4}>    
                  <FormGroup required label="Currency" hasError={!!errors.currency_id}>
                    <Controller
                      name="currency_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="Select Invoice Currency"
                          {...field}
                          options={currencies}
                        />
                      )}
                    />
                    <FieldError error={errors.currency_id} />
                  </FormGroup>
                </Grid>
                )}

                {!currentInvoice && (
                <Grid item xs={12} md={4}>
                  <FormGroup required label="Customer Name" hasError={!!errors.customer_name}>
                    <Controller
                      name="customer_name"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Customer Name" {...field} />
                      )}
                    />
                    <FieldError error={errors.customer_name} />
                  </FormGroup>
                </Grid>
                )}

                {!currentInvoice && (
                <Grid item xs={12} md={4}>
                  <FormGroup required label="Customer Address" hasError={!!errors.customer_address}>
                    <Controller
                      name="customer_address"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Customer Address" {...field} />
                      )}
                    />
                    <FieldError error={errors.customer_address} />
                  </FormGroup>
                </Grid>
                )}

                {!currentInvoice && (
                <Grid item xs={12} md={4}>
                  <FormGroup required label="Customer Tax" hasError={!!errors.customer_tax}>
                    <Controller
                      name="customer_tax"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Customer Tax" {...field} />
                      )}
                    />
                    <FieldError error={errors.customer_tax} />
                  </FormGroup>
                </Grid>
                )}

                {!currentInvoice && (
                <Grid item xs={12} md={4}>
                  <FormGroup required label="Completion Date" hasError={!!errors.completion_date}>
                    <Controller
                      name="completion_date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          placeholderText="YYYY-MM-DD"
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setDateValue(e, setValue, 'completion_date', 'YYYY-MM-DD');
                          }}
                          value={validateDatePickerValue(field.value)}
                        />
                      )}
                    />
                    <FieldError error={errors.completion_date} />
                  </FormGroup>
                </Grid>
                )}

                {!currentInvoice && (
                <Grid item xs={12} md={4}>
                  <FormGroup required label="Issue Date" hasError={!!errors.issue_date}>
                    <Controller
                      name="issue_date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          placeholderText="YYYY-MM-DD"
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setDateValue(e, setValue, 'issue_date', 'YYYY-MM-DD');
                          }}
                          value={validateDatePickerValue(field.value)}
                        />
                      )}
                    />
                    <FieldError error={errors.issue_date} />
                  </FormGroup>
                </Grid>
                )}

                {!currentInvoice && (
                <Grid item xs={12} md={4}>
                  <FormGroup required label="Due Date" hasError={!!errors.due_date}>
                    <Controller
                      name="due_date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          placeholderText="YYYY-MM-DD"
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setDateValue(e, setValue, 'due_date', 'YYYY-MM-DD');
                          }}
                          value={validateDatePickerValue(field.value)}
                        />
                      )}
                    />
                    <FieldError error={errors.due_date} />
                  </FormGroup>
                </Grid>
                )}

                {currentInvoice && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                        <Typography variant="h6" sx={{ fontWeight: '600' }}>Invoice Type:</Typography>
                        <Chip label={INVOICE_TYPE.find(item => item?.value === currentInvoice?.invoice_type)?.label} variant="outlined" size="small" sx={{ fontWeight: 800, textTransform: 'capitalize' }} />
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                        <Typography variant="h6" sx={{ fontWeight: '600' }}>Invoice Status:</Typography>
                        <Chip label={currentInvoice?.invoice_status} color={currentInvoice?.invoice_status === 'paid' ? 'success' : 'error'} variant="outlined" size="small" sx={{ fontWeight: 800, textTransform: 'capitalize' }} />
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                        <Typography variant="h6" sx={{ fontWeight: '600' }}>Invoice Number:</Typography>
                        <Chip label={currentInvoice?.invoice_number} variant="outlined" size="small" sx={{ fontWeight: 800, textTransform: 'capitalize' }} />
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                      <FormGroup required label="Currency" hasError={!!errors.currency_id}>
                        <Controller
                          name="currency_id"
                          control={control}
                          render={({ field }) => (
                            <Select
                              placeholder="Select Invoice Currency"
                              {...field}
                              options={currencies}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AttachMoneyIcon sx={{ ...(field?.value && { color: '#626477' }) }} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                        <FieldError error={errors.currency_id} />
                      </FormGroup>
                    </Grid>
                  </>
                 
                )}

                <Grid item xs={12}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4
                  }}
                  >
                    <Box sx={content}>
                      {invoiceData.fields.map((item, index) => (
                        <Grid
                          container
                          sx={{
                            display: 'flex',
                            backgroundColor: 'white',
                            padding: '15px',
                            borderRadius: '8px'
                          }}
                          key={item.id}
                        >
                          <Grid item xs={12}>
                            <Box sx={headerBtn}>
                              <Box>
                                <Typography noWrap variant="h6">
                                  Item #
                                  {index + 1}
                                </Typography>
                              </Box>

                              {index !== 0 && (
                                <Button
                                  sx={{ ...(RedButton), fontWeight: 600 }}
                                  endIcon={<CloseIcon />}
                                  size="small"
                                  title="Delete"
                                  onClick={() => {
                                    invoiceData.remove(index);
                                  }}
                                />
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <FormGroup label="Description" required hasError={errors?.invoice_data?.[index]?.description}>
                              <Controller
                                name={`invoice_data.${[index]}.description`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    size="small"
                                    type="text"
                                    placeholder="Description"
                                    sx={{ width: '80%' }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={6}>
                            <FormGroup label="Price" required hasError={!!errors.invoice_data?.[index]?.price}>
                              <Controller
                                control={control}
                                name={`invoice_data.${[index]}.price`}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    size="small"
                                    type="number"
                                    placeholder="Amount"
                                    sx={{ width: '80%' }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      ))}
                      <Button
                        sx={{
                          mb: 4, ...(GreenButton), maxWidth: '120px', alignSelf: 'center'
                        }}
                        onClick={() => invoiceData.append(defaultValues.invoice_data[0])}
                      >
                        Add New
                      </Button>
                    </Box>

                    <Box sx={{ ...(headerBtn), justifyContent: 'end' }}>
                      {!currentInvoice ? (
                        <Button
                          sx={{ mb: 4, ...(GreenButton) }}
                          onClick={handleSubmit(onSubmit)}
                        >
                          Generate
                        </Button>
                      ) : (
                        <Button
                          sx={{ mb: 4, ...(GreenButton) }}
                          onClick={handleSubmit(onUpdate)}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default CreateInvoice;

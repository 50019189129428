export const cardContainerSx = {
  mt: 3,
  p: 5,
  pb: 3,
  fontFamily: '"Open Sans",sans-serif',
  minHeight: 'calc(100vh - 200px)'
};

export const uploadPhoto = {
  width: '160px',
  marginLeft: '25px',
};

export const tabsSx = {
  maxWidth: '300px',
  '& .MuiTab-textColorPrimary': {
    alignItems: 'flex-start'
  }
};

export const tabsContainer = {
  display: 'flex',
};

export const footerContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  ml: '25px',
};

export const cancelSx = {
  background: '#e1e5ec'
};

export const tabContainer = {
  minWidth: '300px',
};

export const tabPanelContainer = {
  width: '100%',
  overflow: 'auto'
};

export const uploadContainer = {
  width: 'calc(50% - 15px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const TabBlocksSx = {
  width: 'calc(50% - 15px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const tabContainerSx = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
};

export const tabsContainerSx = {
  display: 'flex',
  flexDirection: 'column',
};

export const tabContainerSmall = {
  flexDirection: 'column',
};

export const uploadContainerSmall = {
  width: '100%',
};
export const TabBlocksSmall = {
  width: '100%',
};

export const tabsSmall = {
  maxWidth: '100%',
  marginRight: 'unset',
  padding: '0.75rem 0',
  '& .MuiTab-root': {
    maxWidth: '100%'
  }
};

export const uploadPhotoSmall = {
  marginLeft: 'unset',
};

export const footerContainerSmall = {
  ml: 'unset',
};
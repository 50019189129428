import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import FormGroup from '../../form/components/FormGroup';
import { schema, defaultValues } from './schema';
import {
  useCreateInvoiceMutation, useGetBankAccountNumbersListMutation, useGetBankInvoicesListQuery, useGetCardsListMutation,
  useGetCurrencyListQuery, useGetDepartmentsListQuery, useGetFlightsQuery, useGetInvoiceMutation, useUpdateInvoiceMutation
} from '../../store/session';
import { useHandleQueryFormError } from '../../utils/hooks/useHandleQueryFormError';
import Input from '../../form/components/Input';
import FieldError from '../../form/components/FieldError';
import Button from '../../components/Button';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { getOptions } from '../../utils/getOptions';
import Select from '../../form/components/Select';
import DatePicker from '../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../utils/setDateValue';
import { INVOICE_TYPE, MONTH, PAYMENTS } from '../../constans/invoice';
import Autocomplete from '../../form/components/Autocomplete';
import ContentPageLoader from '../../components/Loader/ContentPageLoader';

const CreateInvoice = () => {
  const hasPermissionsEdit = usePermissions(['invoice_edit', 'invoice_su']);

  const [bankCards, setBankCards] = useState(null);
  const [accNumbers, setAccNumbers] = useState(null);

  const { data: banksList, isLoading: isBanksListLoading } = useGetBankInvoicesListQuery();

  const [getBankAccountNumbers] = useGetBankAccountNumbersListMutation();
  const [getCards] = useGetCardsListMutation();
  const [getInvoice, { data: invoicesInfo }] = useGetInvoiceMutation();
  const { data: currencyList, isLoading: isCurrencyListLoading } = useGetCurrencyListQuery();
  const { data: flightsList, isLoading: isFlightsListLoading } = useGetFlightsQuery();
  const { data: departmentsList, isLoading: isDepartmentsListLoading } = useGetDepartmentsListQuery();

  const banksListOptions = getOptions(banksList);
  const bankAccountNumbersOptions = getOptions(accNumbers);
  const cardsOptions = getOptions(bankCards);
  const currencyOptions = getOptions(currencyList);
  const flightsOptions = flightsList && Object.keys(flightsList)?.map(key => ({ value: flightsList[key], label: key }));
  const departmentsOptions = getOptions(departmentsList);

  const isLoadingData = isDepartmentsListLoading || isFlightsListLoading || isCurrencyListLoading || isBanksListLoading;

  const { id } = useParams();
  const navigate = useNavigate();

  const handleError = useHandleQueryFormError();

  const [createInvoice] = useCreateInvoiceMutation();
  const [updateInvoice] = useUpdateInvoiceMutation();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    setValue,
    formState: { errors, dirtyFields, touchedFields },
  } = methods;

  const handleGetAccountNumbers = async (bankId) => {
    const accountNumbers = await getBankAccountNumbers(bankId);
    setAccNumbers(accountNumbers?.data);
  };

  const handleGetCards = async (accountNumbersId) => {
    const newBankCards = await getCards(accountNumbersId);
    setBankCards(newBankCards?.data);
  };

  const handleSelectBank = async (bankId) => {
    await handleGetAccountNumbers(bankId);
    setBankCards(null);
    setValue('bank_account_number_id', null);
    setValue('account_number_card_id', null);
  };

  const handleBankAccountNumbers = async (accountNumbersId) => {
    await handleGetCards(accountNumbersId);
    setValue('account_number_card_id', null);
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const flightsId = data.flights.map(item => (+item.value));

    let reqData = { ...data, flights: flightsId };

    const formattedData = Object.keys(dirtyFields).reduce((res, key) => ({ ...res, [key]: data[key] }), {});

    if (!id) {
      const res = await createInvoice(reqData);

      if (res.error) {
        handleError(res.error, setError, getValues, toastId);

        return;
      }

      navigate(`/directories/invoices/update/${res.data.id}`);

      toast.success('Invoice was created!', {
        id: toastId,
      });
    } else {
      if (formattedData.flights) {
        const flightIdUpdated = formattedData.flights.map(item => (+item.value));

        reqData = { ...formattedData, flights: flightIdUpdated };
      } else {
        reqData = { ...formattedData, ...(touchedFields.flights && { flights: [] }) };
      }

      const res = await updateInvoice({ data: reqData, id });

      if (res.error) {
        handleError(res.error, setError, getValues, toastId);

        return;
      }

      toast.success('Invoice was updated!', {
        id: toastId,
      });
    }
  };

  const handleUpdateValues = (result) => {
    Object.entries(result).forEach(
      ([name, value]) => {
        if (name === 'flights') {
          const currentKeyByValue = Object.keys(flightsList).filter(key => value.includes(flightsList[key]));

          const formattedPositionsValue = currentKeyByValue?.map(i => ({ value: flightsList[i], label: i }));

          setValue('flights', formattedPositionsValue || []);
        } else {
          setValue(name, value);
        }
      }
    );
  };

  const handleChangeFlight = (data, onChange) => {
    onChange(data);
    setValue('flights', data, { shouldTouch: true });
  };

  const handleGetInvoiceInfo = async () => {
    const { data: result } = await getInvoice(id);

    if (result) {
      handleUpdateValues(result);
    }
  };

  useEffect(() => {
    if (id && !isLoadingData) {
      handleGetInvoiceInfo();
    }
  }, [id, isLoadingData]);

  useEffect(() => {
    if (invoicesInfo?.bank_id) {
      handleGetAccountNumbers(invoicesInfo.bank_id);
    }

    if (invoicesInfo?.bank_account_number_id) {
      handleGetCards(invoicesInfo.bank_account_number_id);
    }
  }, [invoicesInfo]);

  if (isLoadingData) return <ContentPageLoader />;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ mt: 3 }}>
          <CardHeader title={`${id ? 'Update' : 'Create'} invoice`} />

          <CardContent>
            <Grid xs={12} container spacing={4} rowSpacing={4} sx={{ flexGrow: 1, mt: 2 }}>
              {id && (
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Number of record:
                    {' '}
                    <b>{invoicesInfo?.index}</b>
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Year" required hasError={!!errors.year}>
                  <Controller
                    name="year"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="yyyy"
                        showYearPicker
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e, setValue, 'year', 'YYYY');
                        }}
                        value={validateDatePickerValue(field.value)}
                      />
                    )}
                  />

                  <FieldError error={errors.year} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Month" required hasError={!!errors.month}>
                  <Controller
                    render={({ field } ) => (
                      <Select
                        options={MONTH}
                        placeholder="Month"
                        {...field}
                      />
                    )}
                    control={control}
                    name="month"
                  />

                  <FieldError error={errors.month} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ marginLeft: '20px' }} variant="h5">Bank info </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <FormGroup label="Bank" required hasError={!!errors.bank_id}>
                  <Controller
                    render={({ field } ) => (
                      <Select
                        options={banksListOptions}
                        placeholder="Bank"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          handleSelectBank(e.target.value);
                        }}
                      />
                    )}
                    control={control}
                    name="bank_id"
                  />

                  <FieldError error={errors.bank_id} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Bank account numbers" hasError={!!errors.bank_account_number_id}>
                  <Controller
                    render={({ field } ) => (
                      <Select
                        options={bankAccountNumbersOptions}
                        placeholder="Bank account numbers"
                        disabled={!accNumbers}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          handleBankAccountNumbers(e.target.value);
                        }}
                      />
                    )}
                    control={control}
                    name="bank_account_number_id"
                  />

                  <FieldError error={errors.bank_account_number_id} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Account number cards" hasError={!!errors.account_number_card_id}>
                  <Controller
                    render={({ field } ) => (
                      <Select
                        options={cardsOptions}
                        placeholder="Account number cards"
                        disabled={!accNumbers}
                        {...field}
                      />
                    )}
                    control={control}
                    name="account_number_card_id"
                  />

                  <FieldError error={errors.account_number_card_id} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ marginLeft: '20px' }} variant="h5">Payment info</Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Payment" hasError={!!errors.payment}>
                  <Controller
                    render={({ field } ) => (
                      <Select
                        placeholder="Payment"
                        options={PAYMENTS}
                        {...field}
                      />
                    )}
                    control={control}
                    name="payment"
                  />

                  <FieldError error={errors.payment} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Payment date" hasError={!!errors.payment_date}>
                  <Controller
                    name="payment_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="YYYY-MM-DD"
                        dateFormat="yyyy-MM-dd"
                        showMonthDropdown
                        showYearDropdown
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e, setValue, 'payment_date', 'YYYY-MM-DD');
                        }}
                        value={validateDatePickerValue(field.value)}
                      />
                    )}
                  />

                  <FieldError error={errors.payment_date} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Payment amount" hasError={!!errors.payment_amount}>
                  <Controller
                    name="payment_amount"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Payment amount"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.payment_amount} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Payment currency" hasError={!!errors.payment_currency}>
                  <Controller
                    render={({ field } ) => (
                      <Select
                        placeholder="Payment currency"
                        options={currencyOptions}
                        {...field}
                      />
                    )}
                    control={control}
                    name="payment_currency"
                  />

                  <FieldError error={errors.payment_currency} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ marginLeft: '20px' }} variant="h5">Invoice info</Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Invoice type" hasError={!!errors.invoice_type}>
                  <Controller
                    render={({ field } ) => (
                      <Select
                        placeholder="Invoice type"
                        options={INVOICE_TYPE}
                        {...field}
                      />
                    )}
                    control={control}
                    name="invoice_type"
                  />

                  <FieldError error={errors.invoice_type} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Counterparty name" hasError={!!errors.counterparty_name}>
                  <Controller
                    name="counterparty_name"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Counterparty name"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.counterparty_name} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Invoice number" hasError={!!errors.invoice_number}>
                  <Controller
                    name="invoice_number"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Invoice number"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.invoice_number} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Invoice amount" hasError={!!errors.invoice_amount}>
                  <Controller
                    name="invoice_amount"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Invoice amount"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.invoice_amount} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Invoice date" hasError={!!errors.invoice_date}>
                  <Controller
                    name="invoice_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="YYYY-MM-DD"
                        dateFormat="yyyy-MM-dd"
                        showMonthDropdown
                        showYearDropdown
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e, setValue, 'invoice_date', 'YYYY-MM-DD');
                        }}
                        value={validateDatePickerValue(field.value)}
                      />
                    )}
                  />

                  <FieldError error={errors.invoice_date} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Invoice currency" hasError={!!errors.invoice_currency}>
                  <Controller
                    render={({ field } ) => (
                      <Select
                        placeholder="Invoice currency"
                        options={currencyOptions}
                        {...field}
                      />
                    )}
                    control={control}
                    name="invoice_currency"
                  />

                  <FieldError error={errors.invoice_currency} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="TMTS" hasError={!!errors.tmts}>
                  <Controller
                    name="tmts"
                    control={control}
                    render={({ field }) => (
                      <Input
                        multiline
                        rows={4}
                        placeholder="TMTS"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.tmts} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ marginLeft: '20px' }} variant="h5">Department info</Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Department" hasError={!!errors.department_id}>
                  <Controller
                    render={({ field } ) => (
                      <Select
                        placeholder="Department"
                        options={departmentsOptions}
                        {...field}
                      />
                    )}
                    control={control}
                    name="department_id"
                  />

                  <FieldError error={errors.department_id} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Flights" hasError={!!errors.flights}>
                  <Controller
                    name="flights"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        id="tags-outlined"
                        placeholder="Flights"
                        options={flightsOptions || []}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, flightListItem) => option.value === flightListItem.value}
                        onChange={(e, data) => {
                          handleChangeFlight(data, field.onChange);
                        }}
                        value={field.value ?? []}
                      />
                    )}
                  />

                  <FieldError error={errors.flights} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Invoice sender department" required hasError={!!errors.invoice_sender_department}>
                  <Controller
                    render={({ field } ) => (
                      <Select
                        placeholder="Invoice sender department"
                        options={departmentsOptions}
                        {...field}
                      />
                    )}
                    control={control}
                    name="invoice_sender_department"
                  />

                  <FieldError error={errors.invoice_sender_department} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Document url" hasError={!!errors.document_url}>
                  <Controller
                    name="document_url"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Document"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.document_url} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="Comment" hasError={!!errors.comment}>
                  <Controller
                    name="comment"
                    control={control}
                    render={({ field }) => (
                      <Input
                        multiline
                        rows={4}
                        placeholder="Comment"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.comment} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Executor" hasError={!!errors.executor}>
                  <Controller
                    name="executor"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Executor"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.executor} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Payment order number" hasError={!!errors.payment_order_number}>
                  <Controller
                    name="payment_order_number"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Payment order number"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.payment_order_number} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Incoming document_number" hasError={!!errors.incoming_document_number}>
                  <Controller
                    name="incoming_document_number"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Payment order number"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.incoming_document_number} />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormGroup label="Invoice url" hasError={!!errors.invoice_url}>
                  <Controller
                    name="invoice_url"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Invoice url"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.invoice_url} />
                </FormGroup>
              </Grid>

              <Grid
                item
                sx={{
                  display: 'flex',
                  gap: '9px',
                }}
                xs={12}
              >
                <Button disable={!hasPermissionsEdit} type="submit" variant="contained" title={`${id ? 'Save' : 'Create'}`} size="large" />

                <Button
                  title="Cancel"
                  size="large"
                  onClick={() => {
                    navigate('/directories/invoices');
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
};

export default CreateInvoice;

import {
  Button, Dialog, DialogContent, Grid
} from '@mui/material';
import React from 'react';
import { CloseIcon } from '../../../components/Icons';
import { CloseIconStyle, RightMenuStyle } from '../../Logistics/OrdersDashboard/styles';
import PriceInput from './PriceInput';
import { useForm } from 'react-hook-form';
import { defaultValues, schema } from './updatePriceSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { useUpdateFuelPriceFieldMutation } from '../../../store/session';

const CreateInvoice = ({
  onClose, fuelId, onSubmit
}) => {
  const [updatePrice, { isLoading }] = useUpdateFuelPriceFieldMutation();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const onSubmitForm = async (data) => {
    const toastId = toast.loading('Loading...');
    if (data.ap_fuel_price <= 0) {
      toast.error('The price cannot be below zero', {
        id: toastId
      });

      return;
    }
    const { error } = await updatePrice({
      id: fuelId,
      data: {
        ap_fuel_price: data.ap_fuel_price,
        currency: data.currency,
        unit_id: data.unit_id,
      }
    });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId
    });
    reset();
    onClose();
    onSubmit();
  };

  return (
    <Dialog
      scroll="body"
      maxWidth="lg"
      open={!!fuelId}
      onClose={() => {
        onClose();
        reset();
      }}
    >
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={6}>
          <Grid item xs={12} sx={{ ...(RightMenuStyle), mt: 5 }}>
            <CloseIcon
              sx={CloseIconStyle}
              onClick={() => {
                onClose();
                reset();
              }}
            />
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container spacing={4}>
            <PriceInput control={control} errors={errors} sm={12} />
            <Grid item xs={12}>
              <Button type="submit" variant="contained" size="large" disabled={isLoading}>Update</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CreateInvoice;

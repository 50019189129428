export const cardContainerSx = {
  mt: 3,
  p: 5,
  pb: 3,
  fontFamily: '"Open Sans",sans-serif',
};

export const dataCellSx = {
  minWidth: '250px',
  maxWidth: '250px',
  width: '250px',
};

export const titleSx = {
  fontSize: '25px'
};
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {
  accordionSummarySx,
  accordionDetails,
  accordionSx,
} from './styles';

const TableAccordion = ({ title, children }) => (
  <Accordion defaultExpanded sx={accordionSx}>
    <AccordionSummary
      aria-controls="table-accorion"
      id="table-accorion"
      sx={accordionSummarySx}
    >
      {title}
    </AccordionSummary>

    <AccordionDetails sx={accordionDetails}>
      {children}
    </AccordionDetails>
  </Accordion>
);

export default TableAccordion;

import {
  Box, Card, Divider, Typography, useMediaQuery,
} from '@mui/material';
import React from 'react';
import { boxTitle, cardInfo } from './styles';

const Info = ({ aircraft }) =>
{
  const maxWidth425px = useMediaQuery('(max-width:425px)');

  return (
    <Card sx={{ mb: 5, width: '268px', ...(maxWidth425px && { width: '100%' }) }}>
      <Card sx={cardInfo}>
        <Box sx={boxTitle}>
          <Box>
            <Typography noWrap sx={{ textTransform: 'capitalize', mb: 2 }}>
              FH:
            </Typography>
            <Typography noWrap sx={{ textTransform: 'capitalize', mb: 2 }}>
              FC:
            </Typography>
            <Typography noWrap sx={{ textTransform: 'capitalize' }}>
              DATE:
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ minWidth: '133px' }}>
            <Typography
              noWrap
              sx={{
                mb: 2,
                fontWeight: 600,
              }}
            >
              {aircraft.fh}
            </Typography>
            <Typography noWrap sx={{ textTransform: 'capitalize', mb: 2, fontWeight: 600 }}>
              {aircraft.fc}
            </Typography>
            <Typography noWrap sx={{ textTransform: 'capitalize', fontWeight: 600 }}>
              {aircraft.aircraft_data_date}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Card>
  ); };

export default Info;

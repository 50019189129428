import React from 'react';
import Drawer from '../../../components/Drawer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '../../../form/components/FormGroup';
import FieldError from '../../../form/components/FieldError';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '../../../form/components/Input';
import { Controller } from 'react-hook-form';
import { useCreateDoc } from './useCreateDoc';
import {
  cancelSx, footerSx, numberInputSx, formControlLabel
} from './styles';

const CreateDoc = ({ open, onClose, refetch }) => {
  const {
    control, errors, handleSubmit, onSubmit,
  } = useCreateDoc(onClose, refetch);

  return (
    <Drawer
      title="New Doc Field"
      open={open}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          sx={{ flexGrow: 1, p: '30px' }}
        >
          <Grid item xs={12}>
            <FormGroup label="Name" required hasError={!!errors.doc_name}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="Name"
                    {...field}
                  />
                )}
                control={control}
                name="doc_name"
              />

              <FieldError error={errors.doc_name} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="Expired threshold days" required hasError={!!errors.expired_treshold}>
              <Controller
                render={({ field } ) => (
                  <Input
                    type="number"
                    sx={numberInputSx}
                    placeholder="Expired threshold days"
                    {...field}
                  />
                )}
                control={control}
                name="expired_treshold"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <Controller
                name="post_captain"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    sx={formControlLabel}
                    control={<Checkbox />}
                    label="Assigned to Captain"
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <Controller
                name="post_fo"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    sx={formControlLabel}
                    control={<Checkbox />}
                    label="Assigned to FO"
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item sm={12}>
            <FormGroup>
              <Controller
                name="post_engineer"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    sx={formControlLabel}
                    control={<Checkbox />}
                    label="Assigned to Engineer"
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Box sx={footerSx}>
            <Button variant="contained" type="submit">
              Save
            </Button>
            <Button
              sx={cancelSx}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </form>
    </Drawer>
  ); };

export default CreateDoc;

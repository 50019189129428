import React from 'react';
import TypeCard from '../TypeCard';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { Controller } from 'react-hook-form';

const Remarks = ({ control, isPanel }) => (
  <TypeCard title="REMARKS" variant="grey" isPanel={isPanel}> 
    <FormGroup label="REMARK">
      <Controller
        name="remark"
        control={control}
        render={({ field }) => (
          <Input
            rows={4}
            multiline
            {...field}
          />
        )}
      />
    </FormGroup>
  </TypeCard>
);

export default Remarks;

import React, { useEffect, useState } from 'react';
import TypeCard from '../TypeCard';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { Controller } from 'react-hook-form';
import { useGetCrewListByPostQuery, useGetCrewListQuery } from '../../../../store/session';
import { ENGINEER, CAA } from '../../../../constans/crew';
import { stringifyLocationSearch } from '../../../../utils/locationSearch';

const Crew = ({ control, isPanel, isOffline } ) => {
  const [crewList, setCrewList] = useState(JSON.parse(localStorage.getItem('crewListData')) || []);
  const [engineers, setEngineers] = useState(JSON.parse(localStorage.getItem('engineersData')) || []);
  const [caa, setCaa] = useState(JSON.parse(localStorage.getItem('caaData')) || []);

  const { data: crewListData } = useGetCrewListQuery(stringifyLocationSearch({
    post: '1,2'
  }));
  const { data: engineersData } = useGetCrewListByPostQuery(ENGINEER);
  const { data: caaData } = useGetCrewListByPostQuery(CAA);

  const crewCapsCPOptions = crewList?.map(item => ({ value: item.id, label: `${item.crew_shortname} ${item.crew_name}` }));
  const engineersOptions = engineers?.map(item => ({ value: item.id, label: `${item.crew_shortname} ${item.crew_name}` }));
  const caaOptions = caa?.map(item => ({ value: item.id, label: `${item.crew_shortname} ${item.crew_name}` }));

  useEffect(() => {
    if (!isOffline && crewListData && engineersData && caaData) {
      localStorage.setItem('crewListData', JSON.stringify(crewListData));
      localStorage.setItem('engineersData', JSON.stringify(engineersData));
      localStorage.setItem('caaData', JSON.stringify(caaData));

      setCrewList(crewListData);
      setEngineers(engineersData);
      setCaa(caaData);
    }
  }, [isOffline, crewListData, engineersData, caaData]);

  return (
    <TypeCard title="CREW" variant="sky" isPanel={isPanel}>
      <Grid container spacing={7} rowSpacing={8} sx={{ flexGrow: 1 }}>
        <Grid item xs={12} lg={4}>
          <FormGroup label="PIC">
            <Controller
              name="crew_pic"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  freeSolo
                  value={crewCapsCPOptions?.filter((AirportItem) => AirportItem.value === field.value)[0] || null}
                  options={crewCapsCPOptions}
                  isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputRef={field.ref}
                    />
                  )}
                  onChange={(e, data) => {
                    field.onChange(data?.value);
                  }}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={4}>
          <FormGroup label="F/O">
            <Controller
              name="crew_fo"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  freeSolo
                  value={crewCapsCPOptions?.filter((AirportItem) => AirportItem.value === field.value)[0] || null}
                  options={crewCapsCPOptions}
                  isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputRef={field.ref}
                    />
                  )}
                  onChange={(e, data) => {
                    field.onChange(data?.value);
                  }}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={4}>
          <FormGroup label="F/E">
            <Controller
              name="crew_engineer"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  freeSolo
                  value={engineersOptions?.filter((AirportItem) => AirportItem.value === field.value)[0] || null}
                  options={engineersOptions}
                  isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputRef={field.ref}
                    />
                  )}
                  onChange={(e, data) => {
                    field.onChange(data?.value);
                  }}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={4}>
          <FormGroup label="CCM">
            <Controller
              name="crew_cca"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  freeSolo
                  value={caaOptions?.filter((AirportItem) => AirportItem.value === field.value)[0] || null}
                  options={caaOptions}
                  isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputRef={field.ref}
                    />
                  )}
                  onChange={(e, data) => {
                    field.onChange(data?.value);
                  }}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={4}>
          <FormGroup label="LM">
            <Controller
              name="crew_lm"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={4}>
          <FormGroup label="INSP">
            <Controller
              name="crew_insp"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </TypeCard>
  ); };

export default Crew;

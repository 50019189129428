export const titlesSx = {
  fontSize: '18px',
  fontWeight: 300,
  mb: '-20px'
};

export const buttonSx = {
  color: '#fff',
  width: '100%',
  '&:hover': {
    backgroundColor: 'rgb(53 151 243 / 80%) !important'
  }
};

export const typeBtnActive = {
  backgroundColor: 'rgb(53 151 243 / 80%)'
};

import React, { useState } from 'react';
import {
  Box, Card, Divider, Typography
} from '@mui/material';
import EditableTable from '../../../../components/EditableTable';
import { columns } from './columns';
import {
  useGetTrainingProgramBlocksTableQuery,
  useCreateTrainingProgramBlocksTableMutation,
  useDeleteTrainingProgramBlocksTableMutation,
  useUpdateTrainingProgramBlocksTableMutation,
} from '../../../../store/session';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import toast from 'react-hot-toast';
import BlockForm from './BlockForm';
import LinkButton from '../../../../components/LinkButton';
import { usePermissions } from '../../../../utils/hooks/usePermissions';

const DefaultDocuments = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [createBlock] = useCreateTrainingProgramBlocksTableMutation();
  const [refetch, setRefetch] = useState(0);

  const useRefetch = () => {
    setRefetch(refetch + 1);
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const res = await createBlock(data);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }
    useRefetch();
    toast.success('Success!', {
      id: toastId,
    });
    setOpenDrawer(false);
  };

  return (
    <>
      <LinkButton path="/training-module/training-programs">
        <Button title="Back"></Button>
      </LinkButton>
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3">Training Blocks</Typography>
          <Button title="Add Block" onClick={() => setOpenDrawer(true)} />
        </Box>

        <Divider />

        <EditableTable
          useHook={useGetTrainingProgramBlocksTableQuery}
          useHookDelete={useDeleteTrainingProgramBlocksTableMutation}
          useHookUpdate={useUpdateTrainingProgramBlocksTableMutation}
          columns={columns}
          sortByType="DESC"
          sortByActive="block_name"
          refetchTable={refetch}
        // hasPermissions={hasPermissionsBank}
          getRowId={(row) => row.id}
        />
      </Card>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <BlockForm onSubmit={onSubmit} />
      </Drawer>
    </>
  );
};

export default DefaultDocuments;

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FiltrationInput from '../../../../components/EditableTable/FiltrationInput';
import TextEdit from '../../../../components/EditableTable/TextEdit';
import { TYPE_INPUT } from '../../../../components/EditableTable/constants';
import {
  HeaderName, StyledDelete, 
} from '../../../../components/EditableTable/styles';
import ButtonIcon from '../../../../components/ButtonIcon';

export const columns = ({
  handleFilter,
  filters,
  hasPermissions,
  handleOpen,
  handleOpenDeleteModal,
}) => ([
  {
    flex: 0.2,
    field: 'block_name',
    minWidth: 200,
    headerName: 'Block Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Block Name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.block_name || ''}
          value="block_name"
          placeholder="Block Name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'block_name', row)}
        text={row.block_name}
        labelInput="Enter Block Name"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    width: 120,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <ButtonIcon onClick={() => handleOpenDeleteModal(row.id)}>
        <StyledDelete />
      </ButtonIcon>
    ),
  },
]);

import React from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  deleteIcon, btn, content, itemNum, containerInvoice, boxInvoice
} from './style';
import AddIcon from '@mui/icons-material/Add';
import Input from '../../../../form/components/Input';
import Button from '../../../../components/Button';

const CardsTable = () => {
  const { control, watch } = useFormContext();

  const {
    fields,
    append,
    remove,
    update,
  } = useFieldArray({ name: 'documents', control });

  const addDocument = () => {
    append({
      document_name: null,
    });
  };

  return (
    <Box sx={content}>
      {fields?.map((item, index) => (
        <Grid container spacing={5} sx={{ display: 'flex', }} key={item.id}>
          <Grid sx={itemNum} item xs={0.3} lg={0.3}>
            {index + 1}
          </Grid>

          <Grid item xs={0.3} lg={0.3}>
            <Tooltip title="Delete Document">
              <DeleteIcon
                sx={deleteIcon}
                onClick={() => remove(index)}
              />
            </Tooltip>
          </Grid>

          <Grid item xs={2} lg={2}>
            <Controller
              name={`documents.${[index]}.document_name`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Document Name"
                />
              )}
            />
          </Grid>
        </Grid>
      ))}

      <Button sx={btn} title="Add document" onClick={addDocument} />
    </Box>
  );
};

export default CardsTable;

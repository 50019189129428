import {
  Box, Card, Divider, Typography
} from '@mui/material';
import React, { useState } from 'react';
import Button from '../../../../components/Button';
import EditableTable from '../../../../components/EditableTable';
import {
  useDeletePurposeMutation,
  useGetPurposesTableQuery,
  useUpdatePurposeMutation
} from '../../../../store/session';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { columns } from './columns';
import CreatePaymentPurposes from './CreatePaymentPurposes/CreatePaymentPurposes';

export const PaymentPurposes = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const hasPermissionsEdit = usePermissions(['invoice_request_access']);

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  return (
    <Card sx={{
      mt: 3,
      padding: '1rem',
    }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}
      >
        <Typography variant="h3">Payment Purposes</Typography>
        <Button disabled={!hasPermissionsEdit} onClick={handleOpen} title="Create" />
      </Box>

      <Divider />
      <EditableTable
        useHook={useGetPurposesTableQuery}
        useHookDelete={useDeletePurposeMutation}
        useHookUpdate={useUpdatePurposeMutation}
        columns={columns}
        sortByType="DESC"
        hasPermissions={hasPermissionsEdit}
        sortByActive="purpose"
        getRowHeight={() => 'auto'}
        getRowId={(row) => row.id}
        fixedCacheKey="purposes-table"
      />

      <CreatePaymentPurposes
        onClose={handleClose}
        open={openDrawer}
      />

    </Card>
  );
};

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Box,
  Chip, Grid, Tooltip
} from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Button from '../../../../../../../components/Button';
import { DATE_FORMAT } from '../../../../../../../constans/formats';
import DatePicker from '../../../../../../../form/components/DatePicker';
import Input from '../../../../../../../form/components/Input';
import Textarea from '../../../../../../../form/components/Textarea';
import { useGetAdditionalInvoicePdfMutation } from '../../../../../../../store/session';
import { setDateValue, validateDatePickerValue } from '../../../../../../../utils/setDateValue';
import {
  boxInvoice,
  btn,
  containerInvoice,
  content,
  deleteIcon,
  itemNum,
  pdfIcon,
  price,
  textarea
} from './style';

const Content = () => {
  const { id } = useParams();
  const {
    control, Controller, watch, setValue, getValues
  } = useFormContext();
  const [getInvoice, { isLoading: invoiceLoading }] = useGetAdditionalInvoicePdfMutation();

  const {
    fields,
    append,
    remove,
    update,
  } = useFieldArray({ name: 'additionalInvoices', control });

  const handleAddRow = (index, data) => {
    update(
      index,
      {
        ...data,
        invoice_data: [...(data.invoice_data || []), { id: Date.now().toString() }]
      }
    );
  };

  const handleDeleteRow = (index, i) => {
    const invoice = watch(`additionalInvoices.${index}`);

    update(index, {
      ...invoice,
      invoice_data: invoice.invoice_data.filter((item, itemIndex) => itemIndex !== i)
    });
  };

  const handlePreviewPdf = async (invoice_id) => {
    if (!invoiceLoading && invoice_id && id) {
      const toastId = toast.loading('Loading...');
      await getInvoice({ id, data: { invoice_id } }).then((res) => {
        if (!res?.error) {
          toast.success('Success!', {
            id: toastId,
          });
          const fileUrl = URL.createObjectURL(res.data);
          window.open(fileUrl, '_blank', 'noreferrer');
        } else {
          toast.error('Something went wrong.', {
            id: toastId,
          });
        }
      });
    }
  };

  const addInvoice = () => {
    append({
      paid: 0,
      date: moment().format(DATE_FORMAT),
      invoice_data: [{
        description: '',
        price: '',
      }],
    });
  };

  useEffect(() => {
    const actualValServices = getValues('actualValServices');
    setValue('fullCost.add_invoice', actualValServices.reduce((sum, item) => sum + +(item.av_serv_price || 0), 0));
  }, [watch('actualValServices')]);

  const handleStatusChip = (field) => {
    // eslint-disable-next-line default-case
    switch (field.value) {
      case 1:
        // eslint-disable-next-line react/react-in-jsx-scope
        return (
          <Chip
            {...field}
            label="PAID"
            color="success"
            sx={{ fontWeight: 'bolder' }}
          />
        );
      case 0:
        // eslint-disable-next-line react/react-in-jsx-scope
        return (
          <Chip
            {...field}
            label="UNPAID"
            color="warning"
            sx={{ fontWeight: 'bolder', }}
          />
        );
      
      default:
        return '';
    }
  };

  return (
    <Box sx={content}>
      {fields?.map((item, index) => (
        <Grid container spacing={5} sx={{ display: 'flex', }} key={item.id}>
          <Grid sx={itemNum} item xs={0.5} lg={0.5}>
            {index + 1}
          </Grid>
          <Grid item xs={1} lg={1}>
            <Controller
              name={`additionalInvoices.${[index]}.paid`}
              control={control}
              render={({ field }) => handleStatusChip(field)}
            />
          </Grid>
          <Grid item xs={2} lg={2}>
            <Controller
              name={`additionalInvoices.${[index]}.date`}
              control={control}
              render={({ field }) => (
                <DatePicker
                  label="Date"
                  showMonthDropdown
                  showYearDropdown
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-dd"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, `additionalInvoices.${index}.date`, DATE_FORMAT);
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
            />
          </Grid>
          <Grid item xs={7} lg={7} sx={containerInvoice}>
            {item.invoice_data?.map((element, subIndex) => (
              <Grid key={element.id} item sx={boxInvoice}>
                <Controller
                  name={`additionalInvoices.${[index]}.invoice_data.${[subIndex]}.description`}
                  control={control}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      placeholder="Description"
                      style={textarea}
                    />
                  )}
                />
                <Controller
                  name={`additionalInvoices.${[index]}.invoice_data.${[subIndex]}.price`}
                  control={control}
                  render={({ field }) => (
                    <Box sx={price}>
                      <Input
                        {...field}
                        label="Price"
                        type="number"
                      />
                      EUR
                    </Box>
                  )}
                />

                {subIndex === 0 && (
                  <Tooltip title="Delete invoice">
                    <DeleteIcon
                      sx={deleteIcon}
                      onClick={() => remove(index)}
                    />
                  </Tooltip>
                )}

                {subIndex !== 0 && (
                  <Tooltip title="Delete row">
                    <DeleteIcon
                      sx={deleteIcon}
                      onClick={() => handleDeleteRow(index, subIndex)}
                    />
                  </Tooltip>
                )}
              </Grid>
            ))}
          </Grid>
          <Grid item xs={1} lg={1}>
            <AddIcon
              sx={deleteIcon}
              onClick={() => handleAddRow(index, item)}
            />
            {!!item?.itemId && (
            <Tooltip title="Print PDF">
              <PdfIcon
                sx={pdfIcon}
                onClick={() => handlePreviewPdf(item?.itemId)}
              />
            </Tooltip>
            )}
          </Grid>
        </Grid>
      ))}
      <Button sx={btn} title="Add row" onClick={addInvoice} />
    </Box>
  );
};

export default Content;

import { array, object, string } from 'yup';

export const defaultValues = {
  name: '',
  leaders: []
};

export const schema = object().shape({
  name: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Name'),
  leaders: array()
    .nullable()
    .required('${label} cannot be blank')
    .label('Leaders'),
});
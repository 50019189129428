import {
  Box, Card, Grid, TextField, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Button from '../../../../../../../../components/Button';
import Upload from '../../../../../../../../components/Upload';
import DatePicker from '../../../../../../../../form/components/DatePicker';
import FormGroup from '../../../../../../../../form/components/FormGroup';
import { setDateValue, validateDatePickerValue } from '../../../../../../../../utils/setDateValue';
import { headerBtn } from '../../../../../../../Invoices/style';
import { content } from '../../../../../../MyOrders/NewOrder/components/style';
import { Filters, RedButton } from '../../../../../../style';
import { file, fileName, label } from '../../../approved/invoices/style';

const StorageForm = (props) => {
  const {
    formMethods,
    errors,
    items = [],
    submit = () => {},
    onClose = () => {},
    isLoading = false,
    quantity = []
  } = props;

  const [filesName, setFilesName] = useState([]);

  useEffect(() => {
    items.forEach((el, index) => {
      formMethods.setValue(`items[${index}].id`, el?.id);
    });
  }, []);

  return (
    <form>
      {items?.length ? (
        <Card
          sx={content}
        >
          {items.map(({ id, serial_number = '', certificates = [] }, index) => (
            <Grid
              container
              key={id}
              sx={{
                backgroundColor: 'white',
                padding: '15px',
                borderRadius: '8px'
              }}
            >
              <Grid item xs={12} lg={12}>
                <Box
                  sx={headerBtn}
                >
                  <Box>
                    <Typography noWrap variant="h5">
                      #
                      {id}
                    </Typography>
                  </Box>

                </Box>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <FormGroup label="Storage Number" required hasError={!!errors?.items?.[index]?.storage_number}>
                    <Controller
                      name={`items[${index}].storage_number`}
                      control={formMethods?.control}
                      rules={{ required: 'Storage number is required' }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          helperText={errors?.items?.[index]?.storage_number ? errors?.items?.[index]?.storage_number?.message : ''}
                          sx={{ width: '100%' }}
                          size="small"
                          placeholder="Enter Storage Number"
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={6}>
                  <FormGroup
                    label="Serial Number"
                    hasError={!!errors?.items?.[index]?.serial_number}
                  >
                    <Controller
                      name={`items[${index}].serial_number`}
                      control={formMethods?.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          helperText={errors?.items?.[index]?.serial_number ? errors?.items?.[index]?.serial_number : ''}
                          sx={{ width: '100%' }}
                          disabled={((Array.isArray(quantity) && ((+quantity?.[index] > 1) || (+quantity?.find((item) => item?.id?.toString() === id?.toString())?.count > 1))) || (!!serial_number && (serial_number !== 'N/A')))}
                          size="small"
                          placeholder="Enter Serial Number"
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={6}>
                  <FormGroup
                    label="Storage Zone"
                    hasError={!!errors?.items?.[index]?.storage_zone}
                  >
                    <Controller
                      name={`items[${index}].storage_zone`}
                      control={formMethods?.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          helperText={errors?.items?.[index]?.storage_zone ? errors?.items?.[index]?.storage_zone?.message : ''}
                          sx={{ width: '100%' }}
                          size="small"
                          placeholder="Enter Storage Zone"
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={6}>
                  <FormGroup
                    label="Expected Day Delivery"
                    hasError={!!errors?.items?.[index]?.expected_day_delivery}
                  >
                    <Controller
                      name={`items[${index}].expected_day_delivery`}
                      control={formMethods?.control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          placeholderText="YYYY-MM-DD"
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          onChange={(e) => {
                            field.onChange(e);
                            setDateValue(e, formMethods?.setValue, `items[${index}].expected_day_delivery`, 'YYYY-MM-DD');
                          }}
                          value={validateDatePickerValue(field.value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={file}>
                    <FormGroup sx={label} required label="Certificate" hasError={!!errors.certificate}>
                      <Controller
                        name={`items[${index}].certificate`}
                        control={formMethods.control}
                        render={({ field }) => (
                          <Upload
                            {...field}
                            accept=".pdf"
                            handleUpload={(e) => {
                              if (!e.target.files[0]) return;
                              if (!e.target.files[0]?.name?.includes('.pdf')) return;
                              formMethods.setValue(`items[${index}].certificate`, e.target.files?.[0]);
                              setFilesName(prev => {
                                const isName = prev.some(fl => fl?.id === id);

                                if (isName) {
                                  const newState = prev.map(fl => {
                                    if (fl.id === id) {
                                      return {
                                        ...fl,
                                        name: e.target.files[0]?.name
                                      };
                                    }

                                    return fl;
                                  });

                                  return newState;
                                }
 
                                return [...prev, { id, name: e.target.files[0]?.name }];
                              });
                            }}
                            title="Upload file"
                            disabled={certificates?.length}
                          />
                        )}
                      />
                    </FormGroup>
                    <Typography sx={{
                      ...(fileName), overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap' 
                    }}
                    >
                      {filesName?.find(fl => fl.id === id)?.name || ''}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))}
          
        </Card>
      ) : <></>}

      <Grid item xs={12}>
        <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
          <Button
            sx={{ mt: 2, ...(RedButton) }}
            variant="contained"
            size="large"
            title="Cancel"
            onClick={onClose}
          /> 
          {submit}
        </Box>
      </Grid>
    </form>
  );
};

export default StorageForm;

export const headerBtn = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 3
};

export const btnS = {
  width: '100%'
};

export const headerBtnS = {
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'start',
  marginBottom: '25px'
};

export const headerBtnXs = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'start',
};

export const tableContainer = {
  mt: '50px',
  '& .row-prev': {
    backgroundColor: '#ebeff3',
    '&.Mui-selected': {
      backgroundColor: '#ebeff3  !important',
    },
    '&:hover': {
      backgroundColor: '#ebeff3 !important',
    }
  },
  '& .row-next': {
    backgroundColor: '#f0ffff',
    '&.Mui-selected': {
      backgroundColor: '#f0ffff  !important',
    }, 
    '&:hover': {
      backgroundColor: '#f0ffff !important',
    }
  }
};

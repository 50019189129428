import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  MarkerClusterer
} from '@react-google-maps/api';
import { MapStyle } from './style';
// eslint-disable-next-line import/no-deprecated,import/named
import { options, apiToken, markerOptions } from './googleMapOptions';
import AirportMarker from './components/AirportMarker';
import Typography from "@mui/material/Typography";

const Map = ({ data }) => {
  const [selectedMarker, setSelectedMarker] = useState(null);

  /**
   *
   * @param marker
   * @returns {Promise<void>}
   */
  const handleMarkerClick = async (marker) => {
    await setSelectedMarker(marker);
  };

  const customCalculator = (markers) => {
    const count = markers.length;
    let loadings = 0;
    // Access airportData within the cluster calculator
    // eslint-disable-next-line array-callback-return
    markers.map((marker) => {
      loadings += marker.airportData.loadedCount;
    });

    return {
      text: loadings.toString(),
      index: count,
    };
  };

  return (
    <LoadScript
      googleMapsApiKey={apiToken}
    >
      <GoogleMap
        id="fleetair-google-map"
        {...options}
        mapContainerStyle={MapStyle}
      >
        <MarkerClusterer options={markerOptions} calculator={customCalculator}>
          {(clusterer) => data?.map((item) => (
            <AirportMarker
              key={item.id}
              airportData={item}
              handleMarkerClick={handleMarkerClick}
              clusterer={clusterer}
            />
          ))}
        </MarkerClusterer>

        {selectedMarker && (
          <InfoWindow
            position={{ lat: selectedMarker.lat, lng: selectedMarker.lon }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div>
              {/* Additional information content */}
              <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                {selectedMarker.airport_name}
                {' '}
                {selectedMarker.airport_icao}
                /
                {selectedMarker.airport_iata}
              </Typography>
              <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
                Loadings:
                {' '}
                {selectedMarker.loadedCount}
              </Typography>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;

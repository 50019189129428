export const AIRCRAFT_TYPES = [
  { value: 'atr_42', label: 'ATR-42' },
  { value: 'atr_72', label: 'ATR-72' },
  { value: 'saab_340', label: 'SAAB-340' }
];

export const FFS_REG_NR = [
  { value: 'AT-FFS-1088', label: 'AT-FFS-1088' },
  { value: 'EU-DK155', label: 'EU-DK155' },
  { value: 'EU-DK156', label: 'EU-DK156' }
];

export const CONDITIONS = [
  { value: 'summer', label: 'SUMMER' },
  { value: 'winter', label: 'WINTER' },
];

export const TRAINING_CYCLES = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
];

export const PASS_ON_SELECT = [
  { value: 1, label: 'PASS on 1-st attempt' },
  { value: 2, label: 'PASS on 2-st attempt' },
  { value: 'fail', label: 'FAIL' }
];

export const SCORES = [
  { value: 'E', label: 'E' },
  { value: 'G', label: 'G' },
  { value: 'A', label: 'A' },
  { value: 'P', label: 'P' },
  { value: 'V', label: 'V' },
];

export const OPC_IS = [
  { value: true, label: 'PASSED, and trainee is released for LIFUS; or' },
  { value: false, label: 'FAILED, and TRI/SFI recommendations are filed in appropriate field below' },
];

export const OBS_EVAL = [
  { value: 'observer', label: 'OBSERVER (INSTRUCTOR TRAINEE)' },
  { value: 'evaluator', label: 'EVALUATOR' },
];

import React from 'react';

const UserIcon = (props) => {
  // ** Props
  const { icon, iconProps } = props;
  const IconTag = icon;

  // @ts-ignore
  return <IconTag {...iconProps} />;
};

export default UserIcon;

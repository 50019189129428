import React from 'react';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import OdsPositionForm from '../OdsPositionForm';
import OdsTrainingForm from '../OdsTrainingForm';
import { OdsFormLayout } from './OdsFormLayout';
import { useOdsForm } from './useOdsForm';

const OdsForm = () => {
  const {
    control,
    isTypeF,
    data,
    errors,
    getValues,
    print,
    handleBack,
    setValue,
    handleSubmit,
    onSubmit,
    updateIsLoading,
    hasPermissionsOdsEdit,
    airportList,
    odsStatusesOptions,
    odsTocOptions,
  } = useOdsForm();

  if (!data) return <ContentPageLoader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {data.type === 4 && (
        <OdsPositionForm data={data} />
      )}
      {data.type === 5 && (
        <OdsTrainingForm data={data} />
      )}
      {![5, 4].includes(data.type) && (
        <OdsFormLayout
          airportList={airportList}
          hasPermissionsOdsEdit={hasPermissionsOdsEdit}
          setValue={setValue}
          handleBack={handleBack}
          control={control}
          odsStatusesOptions={odsStatusesOptions}
          odsTocOptions={odsTocOptions}
          isTypeF={isTypeF}
          errors={errors}
          getValues={getValues}
          print={print}
          updateIsLoading={updateIsLoading}
        />
      )}
    </form>
  );
};

export default OdsForm;

import { Box } from '@mui/material';
import React from 'react';
import DatePicker from '../../../form/components/DatePicker';
import FormGroup from '../../../form/components/FormGroup';
import { BlockSelect } from './styles';

import moment from 'moment';

const SelectDuration = ({ value, onChange, ...props }) => {
  const { key, data } = value;

  const localOnChange = (date, type) => {
    if (!date) {
      onChange({ [type]: 0 });
    } else {
      onChange({ [type]: date });
    }
  };

  return (
    <Box sx={BlockSelect}>
      <FormGroup>
        <DatePicker
          placeholderText="hh:mm"
          showTimeSelect
          showTimeSelectOnly
          timeFormat="HH:mm"
          dateFormat="HH:mm"
          timeCaption="Time"
          isClearable
          timeIntervals={15}
          value={data ? moment(data).toDate() : null}
          onChange={(e) => localOnChange(e, key)}
          {...props}
        />
      </FormGroup>
    </Box>
  );
};

export default SelectDuration;

import {
  Box, List, ListItem, ListItemButton, ListItemText
} from '@mui/material';
import React from 'react';
import DropDownMenu from '../../../../components/DropDownMenu';
import { dropdownMenuSx } from '../../../../components/EditableTable/DropDownInput/styles';

const AircraftDropDown = (props) => {
  const { 
    open = true,
    closeMenu = () => {},
    options = [],
    activeSelect = null,
    handleChangeSelect = () => {},
    anchorEl = null,
  } = props;

  const handleSetNewAircraft = (vlv) => {
    handleChangeSelect(vlv);
    closeMenu();
  };

  return (
    <DropDownMenu
      sx={{
        ...dropdownMenuSx, 
        '& .MuiPaper-elevation': {
          boxShadow: '0 0 8px 2px'
        }
      }}
      open={open}
      handleClose={closeMenu}
      anchorEl={anchorEl}
    > 
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
      >
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {options.map((item) => {
            const isActive = (activeSelect === item?.value) || (activeSelect === item?.label);

            return (
              <ListItem
                key={item?.value}
                disablePadding
                sx={{
                  backgroundColor: isActive ? 'rgba(0, 70, 136, 0.8)' : 'transparent',
                  '.MuiListItemText-primary': {
                    color: isActive ? '#fff' : 'rgba(76, 78, 100, 0.87)'
                  },
                  '&:hover': {
                    color: '#fff!important',
                    backgroundColor: 'rgba(0, 70, 136, 0.8)',
                    '.MuiListItemText-primary': {
                      color: '#fff'
                    }
                  }
                }}
                onClick={() => handleSetNewAircraft(item?.value || null)}
              >
                <ListItemButton>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </DropDownMenu>
  );
};

export default AircraftDropDown;
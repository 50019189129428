export const tableRow = {
  position: 'relative',
};

export const tableCell = {
  '&.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium': {
    lineHeight: '16px',
    fontSize: '14px',
    color: 'text.primary',
    p: 0,
    borderBottom: '2px solid #e7ecf1',
    '&:first-of-type': {
      borderLeft: '2px solid #e7ecf1',
      pl: '15px',
    },
    '&:Last-of-type': {
      borderRight: '2px solid #e7ecf1',
    },
  },
};
import React from 'react';
import Box from '@mui/material/Box';
import Input from '../../../../form/components/Input';

export const InputFilter = ({
  title, onChange, defaultValue, value
}) => (
  <Box>
    <Box>{title}</Box>

    <Input
      size="small"
      placeholder={title}
      defaultValue={defaultValue}
      onChange={(e) => {
        e.stopPropagation();
        onChange(e.target.value, value);
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  </Box>
);

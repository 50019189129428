import { object, string } from 'yup';

export const defaultValues = {
  access: '',
  action_taken: '',
  aircraft_id: 0,
  ata: '',
  closed: 0,
  closed_at: null,
  closed_by: 0,
  cyc: 0,
  days: 0,
  description: '',
  hrs: 0,
  id: 0,
  inspection_at: null,
  inspection_by: null,
  interval: '',
  maint_id: 0,
  mnt_upd: 0,
  name: '',
  part_off: 0,
  part_on: 0,
  performed: 0,
  performed_at: null,
  performed_by: 0,
  pn: '',
  position: '',
  ref: '',
  sn: '',
  task: '',
  task_group: '',
  title: '',
  wo_id: 0,
  wo_task_index: 0,
  zone: '',
  man_hour_real_job: '00:00'
};

export const schema = object().shape({
  name: string()
    .required('${label} cannot be blank.')
    .label('Name'),
  description: string()
    .required('${label} cannot be blank.')
    .label('description'),
  man_hour_real_job: string()
    .matches(
      /^[0-9][0-9]:[0-5][0-9]$/,
      'Incorrect time format.'
    )
    .label('Man Hour Real Job'),
});

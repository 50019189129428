import React from 'react';
import LinkButton from '../../../components/LinkButton/Button';
import Box from '@mui/material/Box';
import { ColumnCell } from './ColumnCell';
import { Fdp } from './Fdp';
import { ViewColumn } from './ViewColumn';
import { FPD_COLORS } from './constants';

export const columns = ({ isPdf }) => [
  {
    field: 'date',
    headerName: 'DATE',
    cell: ({ date, fdp }) => <ViewColumn value={date} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'on_duty',
    headerName: 'ON DUTY',
    cell: ({ on_duty, fdp }) => <ViewColumn value={on_duty} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'off_duty',
    headerName: 'OFF DUTY',
    cell: ({ off_duty, fdp }) => <ViewColumn value={off_duty} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'total_duty',
    headerName: 'TOTAL DUTY',
    cell: ({ total_duty, fdp }) => <ViewColumn value={total_duty} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'fdp',
    cell: ({ fdp, flight_id, max_fdp }) => <Fdp fdp={fdp} max_fdp={max_fdp} id={flight_id} isPdf={isPdf} />
  },
  {
    field: 'max_fdp',
    headerName: 'MAX FDP',
    cell: ({ max_fdp, fdp }) => <ViewColumn value={max_fdp} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'flight_time',
    headerName: 'FLIGHT TIME',
    cell: ({ flight_time, fdp }) => <ViewColumn value={flight_time} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'next_avail',
    headerName: 'NEXT AVAIL',
    cell: ({ next_avail, fdp, bg }) => <ViewColumn bg={bg} value={next_avail} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'duty_last_7_days',
    cell: ({ duty, fdp }) => <ColumnCell value={duty?.duty_last_7_days} limit={60} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'duty_last_14_days',
    cell: ({ duty, fdp }) => <ColumnCell value={duty?.duty_last_14_days} limit={110} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'duty_last_28_days',
    cell: ({ duty, fdp }) => <ColumnCell value={duty?.duty_last_28_days} limit={190} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'duty_last_calendar_year',
    cell: ({ duty, fdp }) => <ColumnCell value={duty?.duty_last_calendar_year} limit={1800} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'flight_last_28_days',
    cell: ({ flight, fdp }) => <ColumnCell value={flight?.flight_last_28_days} limit={100} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'flight_last_calendar_year',
    cell: ({ flight, fdp }) => <ColumnCell value={flight?.flight_last_calendar_year} limit={900} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'flight_last_12_months',
    cell: ({ flight, fdp }) => <ColumnCell value={flight?.flight_last_12_months} limit={1000} isPdf={isPdf} fdp={fdp} />,
  },
  {
    field: 'ods_id',
    cell: ({ ods_id, index, fdp }) => (
      <Box sx={{ height: '32px', backgroundColor: FPD_COLORS[fdp] }}>
        <LinkButton style={{ color: !isPdf ? '#6c6cf1' : '#4c4e64de', padding: '5px' }} path={`/directories/ods/update/${ods_id}`}>{index}</LinkButton>
      </Box>
    )
  },
];

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOptions } from '../../utils/getOptions';
import { useProvideTags } from '../../utils/hooks/useProvideTags';
import { sessionApi } from '../session';

export const fetchOrder = createAsyncThunk(
  'logistics/fetchOrder',
  // eslint-disable-next-line consistent-return
  async ({ orderId }, { dispatch, rejectWithValue }) => {
    try {
      // eslint-disable-next-line no-use-before-define
      if (orderId) dispatch(setOrder({ id: orderId }));
    } catch (error) {
      // Handle any errors appropriately
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCurrencies = createAsyncThunk(
  'currency/fetchCurrencies',
  // eslint-disable-next-line consistent-return
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await dispatch(sessionApi.endpoints.getCurrencyList.initiate());
      const options = getOptions(res.data);

      // eslint-disable-next-line no-use-before-define
      dispatch(setCurrencies(options));
    } catch (error) {
      // Handle any errors appropriately
      return rejectWithValue(error.response.data);
    }
  }
);

// State and actions
export const logisticsSlice = createSlice({
  name: 'logistics',
  initialState: {
    order: {},
    currencies: {},
    orderRequests: [],
    loading: false,
    error: null
  },
  reducers: {
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    updateExistInvoiceInOrder: (state, action) => {
      state.order.is_exist_invoice = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      sessionApi.endpoints.getOrder.matchFulfilled,
      (state, { payload }) => {
        state.order = payload;
      }
    );
  },
});

// Logistics Endpoints
sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrder: builder.query({
      query: ({ orderId }) => `/logistics/order/${orderId}`,
      providesTags: (result, error) => useProvideTags(result, error, [{ type: 'ORDER', id: result?.id }]),
    }),
    updateOrderInfo: builder.mutation({
      query: ({ data, id }) => ({
        url: `/logistics/my_orders/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getPaymentRequests: builder.query({
      query: ( { payment_type, orderId } ) => `/logistics/payment_requests/${orderId}?payment_type=${payment_type}`,
      providesTags: (result, error) => useProvideTags(result, error, ['ALL_ORDERS', 'ORDER', 'INVOICE_REQUEST_SEND']),
      keepUnusedDataFor: 0, // Disable caching
    }),
    postPaymentRequest: builder.mutation({
      query: ({ data }) => ({
        url: '/logistics/payment_request',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['INVOICE_REQUEST_SEND', 'ORDER'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deletePaymentRequest: builder.mutation({
      query: (id) => ({
        url: `/logistics/payment_requests/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['INVOICE_REQUEST_SEND', 'ORDER'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendRequestApprove: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/order/request_approve/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error, arg) => useProvideTags(result, error, [{ type: 'ORDER', id: arg?.[0]?.id }]),
      invalidatesTags: (result) => (result ? ['ALL_ORDERS'] : []),
    }),
    declineOrer: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/order/decline/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: (result) => (result ? ['ALL_ORDERS', 'ORDER'] : []),
    }),
    sendApprove: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/order/approve/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error, arg) => useProvideTags(result, error, [{ type: 'ORDER', id: arg?.[0]?.id }]),
      invalidatesTags: (result) => (result ? ['ALL_ORDERS'] : []),
    }),
    sendComplete: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/order/complete/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error, arg) => useProvideTags(result, error, [{ type: 'ORDER', id: arg?.[0]?.id }]),
      invalidatesTags: (result) => (result ? ['ALL_ORDERS'] : []),
    }),
    sendDelivered: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/order/delivered/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error, arg) => useProvideTags(result, error, [{ type: 'ORDER', id: arg?.[0]?.id }]),
      invalidatesTags: (result) => (result ? ['ALL_ORDERS'] : []),
    }),
    updateExTypeOrder: builder.mutation({
      query: ({ data, id }) => ({
        url: `/logistics/my_orders/update_exchange_due_date/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOrdersList: builder.query({
      query: ({ variant, supplier_id = '' }) => `/logistics/get_list/orders?invoice_type=${variant}&supplier_id=${supplier_id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUnitsList: builder.query({
      query: (data = {}) => `/units/list${data?.type ? `?type=${data?.type}` : ''}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `/logistics/my_orders/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['ORDERS'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/my_orders/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['ORDERS'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getSuppliers: builder.query({
      query: (data) => `/logistics/suppliers${data}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error) => useProvideTags(result, error, ['SUPPLIERS']),
    }),
    getSupplierInfo: builder.query({
      query: ({ id }) => `/logistics/suppliers/${id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_SUPPLIER']),
    }),
    updateSupplierInfo: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/suppliers/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['GET_SUPPLIER'] : []),
    }),
    removeSupplier: builder.mutation({
      query: (id) => ({
        url: `/logistics/suppliers/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['SUPPLIERS'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createSupplier: builder.mutation({
      query: ({ data }) => ({
        url: '/logistics/suppliers',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['SUPPLIER_CREATE_SEND'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    searchSupplier: builder.query({
      query: ({
        type, search, is_external_vendor, id 
      }) => `/logistics/suppliers/list/search?search=${search}${type ? `&type=${type}` : ''}${is_external_vendor ? '&is_external_vendor=true' : ''}${id ? `&id=${id}` : ''}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    previewSuppliersInvoice: builder.mutation({
      query: ({ id }) => ({
        url: `/logistics/suppliers/invoice/preview/${id}`,
        method: 'POST',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    previewSupplierInvoice: builder.mutation({
      query: (arg) => ({
        url: '/logistics/suppliers/invoice/preview',
        method: 'POST',
        body: arg.data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendSupplierInvoice: builder.mutation({
      query: (arg) => ({
        url: '/logistics/suppliers/invoice/send',
        method: 'POST',
        body: arg.data,
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadCertificate: builder.mutation({
      query: (data) => ({
        url: '/logistics/certificate/upload',
        method: 'POST',
        body: data,
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCertificates: builder.query({
      query: ({ order_id = null, part_id = null }) => `/logistics/certificate/list?order_id=${order_id ?? ''}&part_id=${part_id ?? ''}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCertificatesForDrawer: builder.mutation({
      query: ({ order_id = null, part_id = null }) => ({
        url: `/logistics/certificate/list?order_id=${order_id ?? ''}&part_id=${part_id ?? ''}`,
        method: 'GET',
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    openCertificate: builder.mutation({
      query: (cert_id) => ({
        url: `/logistics/certificate/open/${cert_id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteCertificate: builder.mutation({
      query: (cert_id) => ({
        url: `/logistics/certificate/delete/${cert_id}`,
        method: 'DELETE',
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateAircraft: builder.mutation({
      query: ({ id, data }) => {
        let newData = { ...data };

        if (data?.aircraft_id === 0) {
          newData = {
            ...data,
            aircraft_id: null
          };
        }

        return {
          url: `/logistics/my_orders/update_aircraft/${id}`,
          method: 'PATCH',
          body: newData,
          headers: {
            Accept: 'application/json',
          },
        };
      },
    }),
    getOrderAllInfo: builder.query({
      query: ({ id }) => `/logistics/order/get_info/${id}`,
      invalidatesTags: (result) => (result ? ['EXTERNAL_ORDER_ALL_INFO'] : []),
      providesTags: (result, error) => useProvideTags(result, error, [{ type: 'EXTERNAL_ORDER_ALL_INFO', id: result?.main?.order_number }]),
    }),
    reportDefectivePart: builder.mutation({
      query: ({ data }) => ({
        url: '/logistics/storage/report_part',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createCreditNote: builder.mutation({
      query: ({ data }) => ({
        url: '/finance/credit_note',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['CREDIT_NOTES', 'GET_SUPPLIER', 'GET_INVOICE'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateCreditNote: builder.mutation({
      query: ({ id, data }) => ({
        url: `/finance/credit_note/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['CREDIT_NOTES'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCreditNotes: builder.query({
      query: (data) => `/finance/credit_note${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['CREDIT_NOTES']),
    }),
    getCreditNotePartAutocomplete: builder.query({
      query: ({ search, vendor_id }) => `/finance/credit_note/part_list/search?vendor_id=${vendor_id}${search ? `&search=${search}` : ''}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCreditNoteFiles: builder.mutation({
      query: ({ id }) => ({
        url: `/finance/credit_note/files/list/${id}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadCreditNoteFile: builder.mutation({
      query: ({ id, data }) => ({
        url: `/finance/credit_note/files/upload/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },

      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    openCreditNoteFile: builder.mutation({
      query: ({ id }) => ({
        url: `/finance/credit_note/files/open/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteCreditNoteFile: builder.mutation({
      query: ({ id }) => ({
        url: `/finance/credit_note/files/delete/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
  }),
});

export const {
  useGetOrderQuery,
  useUpdateOrderInfoMutation,
  useSendRequestApproveMutation,
  useSendApproveMutation,
  useSendCompleteMutation,
  useSendDeliveredMutation,
  useDeclineOrerMutation,
  useUpdateExTypeOrderMutation,
  useGetOrdersListQuery,
  useGetSuppliersQuery,
  useGetSupplierInfoQuery,
  useUpdateSupplierInfoMutation,
  useRemoveSupplierMutation,
  useCreateSupplierMutation,
  useReportDefectivePartMutation,
  // paymentRequests
  useGetPaymentRequestsQuery,
  usePostPaymentRequestMutation,
  useDeletePaymentRequestMutation,
  useGetUnitsListQuery,
  useDeleteOrderMutation,
  useUpdateOrderMutation,
  useSearchSupplierQuery,
  usePreviewSuppliersInvoiceMutation,
  usePreviewSupplierInvoiceMutation,
  useSendSupplierInvoiceMutation,
  useUploadCertificateMutation,
  useGetCertificatesQuery,
  useGetCertificatesForDrawerMutation,
  useOpenCertificateMutation,
  useDeleteCertificateMutation,
  useUpdateAircraftMutation,
  useGetOrderAllInfoQuery,
  useCreateCreditNoteMutation,
  useUpdateCreditNoteMutation,
  useGetCreditNotesQuery,
  useGetCreditNotePartAutocompleteQuery,
  useGetCreditNoteFilesMutation,
  useUploadCreditNoteFileMutation,
  useOpenCreditNoteFileMutation,
  useDeleteCreditNoteFileMutation
} = sessionApi;
export const {
  setOrder, setCurrencies, updateExistInvoiceInOrder
} = logisticsSlice.actions;
export default logisticsSlice.reducer;

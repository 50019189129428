import React from 'react';
import TypeCard from '../TypeCard';
import Grid from '@mui/material/Grid';
import Select from '../../../../form/components/Select';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import DatePicker from '../../../../form/components/DatePicker';
import { Controller } from 'react-hook-form';
import { useGetAllAircraftTypeQuery, useGetOdsStatusesQuery } from '../../../../store/session';
import { ODS_POST_OPTIONS } from '../../../../constans/crew';
import { getOptions } from '../../../../utils/getOptions';
import { getTimeDiffHm } from '../../../../utils/getTime';
import FieldError from '../../../../form/components/FieldError';
import moment from 'moment';
import { validateDatePickerValue } from '../../../../utils/setDateValue';

const Flight = ({
  control, pilotNameOptions, trigger, setCrewPost, setValue, watch, isPanel, errors,
}) => {
  const { data: aircraft } = useGetAllAircraftTypeQuery();
  const { data: odsStatuses } = useGetOdsStatusesQuery();

  const odsStatusesOptions = getOptions(odsStatuses);
  const aircraftOptions = aircraft?.data?.map(item => ({ value: item.id, label: item.aircraft_type_name }));

  const start = watch('start');
  const finish = watch('finish');

  const filterPassedTime = (time) => {
    const startDate = moment(start).toDate();
    const endDate = moment(time).toDate();

    return startDate.getTime() < endDate.getTime();
  };

  return (
    <TypeCard title="TRAINING ODS" variant="sky" isPanel={isPanel}>
      <Grid container spacing={7} rowSpacing={2}>
        <Grid item xs={12} lg={3}>
          <FormGroup label="POSITION" hasError={!!errors.crew_post}>
            <Controller
              render={({ field }) => (
                <Select
                  options={ODS_POST_OPTIONS}
                  placeholder="POSITION"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setCrewPost(e.target.value);
                    setValue('crew_id', '');
                    trigger('crew_id');
                  }}
                />
              )}
              control={control}
              name="crew_post"
            />

            <FieldError error={errors.crew_post} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="PILOT NAME" hasError={!!errors.crew_id}>
            <Controller
              render={({ field }) => (
                <Select
                  options={pilotNameOptions}
                  disabled={!pilotNameOptions?.length}
                  placeholder="PILOT NAME"
                  {...field}
                />
              )}
              control={control}
              name="crew_id"
            />

            <FieldError error={errors.crew_id} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="AIRCRAFT" hasError={!!errors.aircraft_type}>
            <Controller
              render={({ field }) => (
                <Select
                  options={aircraftOptions}
                  placeholder="AIRCRAFT TYPE"
                  {...field}
                />
              )}
              control={control}
              name="aircraft_type"
            />

            <FieldError error={errors.aircraft_type} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="STATUS" hasError={!!errors.ods_status}>
            <Controller
              render={({ field }) => (
                <Select
                  options={odsStatusesOptions}
                  placeholder="STATUS"
                  {...field}
                />
              )}
              control={control}
              name="ods_status"
            />

            <FieldError error={errors.ods_status} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="TYPE OF TRAINING" hasError={!!errors.training_type}>
            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="TYPE OF TRAINING"
                />
              )}
              control={control}
              name="training_type"
            />

            <FieldError error={errors.training_type} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="TRAINING START" hasError={!!errors.start}>
            <Controller
              render={({ field: { onChange, ...req } }) => (
                <DatePicker
                  {...req}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD H:M"
                  dateFormat="yyyy-MM-dd HH:mm"
                  maxDate={finish ? moment(finish).toDate() : null}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => {
                    onChange(e);
                    trigger('start');
                  }}
                  value={validateDatePickerValue(req.value)}
                />
              )}
              control={control}
              name="start"
            />

            <FieldError error={errors.start} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="TRAINING FINISH" hasError={!!errors.finish}>
            <Controller
              render={({ field: { onChange, ...req } }) => (
                <DatePicker
                  {...req}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD H:M"
                  dateFormat="yyyy-MM-dd HH:mm"
                  minDate={start ? moment(start).toDate() : null}
                  filterTime={filterPassedTime}
                  showMonthDropdown
                  showYearDropdown
                  onChange={onChange}
                  value={validateDatePickerValue(req.value)}
                />
              )}
              control={control}
              name="finish"
            />

            <FieldError error={errors.finish} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormGroup label="TOTAL TRAINING TIME" hasError={!!errors.total_training_time}>
            <Controller
              render={({ field }) => (
                <Input
                  placeholder="TOTAL TRAINING TIME"
                  InputProps={{
                    readOnly: true,
                  }}
                  {...field}
                  value={getTimeDiffHm(start, finish)}
                  defaultValue={getTimeDiffHm(start, finish)}
                />
              )}
              control={control}
              name="total_training_time"
            />

            <FieldError error={errors.total_training_time} />
          </FormGroup>
        </Grid>
      </Grid>
    </TypeCard>
  );
};

export default Flight;

import { useGetCrewListQuery, useGetFlightMutation } from '../../../../../../../store/session';

export const useCrewForm = () => {
  const { data: CrewList } = useGetCrewListQuery();
  const [_, { data }] = useGetFlightMutation({
    fixedCacheKey: 'get-flight-data',
  });

  const flightData = data?.planing_hop.map(item => ({
    departure: item.pl_hop_from.split(' ')[0],
    arriving: item.pl_hop_to.split(' ')[0],
    flightNo: item.pl_hop_prefix,
    captain: CrewList?.find(i => i.id === item.pl_hop_capitane)?.crew_name,
    fo: CrewList?.find(i => i.id === item.pl_hop_fo)?.crew_name,
    engineer: CrewList?.find(i => i.id === item.pl_hop_engineer)?.crew_name
  })) || [];

  return {
    flightData,
  };
};

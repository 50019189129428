import { hexToRGBA } from '../../../../utils/hex-to-rgba';

export const dateCell = {
  width: '60px',
  minWidth: '60px',
  maxWidth: '60px',
};

export const tableContainer = {
  position: 'relative',
  width: '100%',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: 6,
    height: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 20,
    background: (theme) => hexToRGBA(theme.palette.mode === 'light' ? '#BFBFD5' : '#57596C', 0.6),
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: 20,
    background: 'transparent',
  },
};

export const loaderSx = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: 'calc(100vw + 52px)',
  height: 'calc(100vh + 52px)',
  background: '#ffffff8c',
};

export const tableSx = (isPdf) => ({
  border: '1.5px solid #F2F2FF',
  background: 'white',
  p: '0 20px',
  width: '100%',
  ...(isPdf && ({
    minWidth: '1600px',
    maxWidth: '1600px',
  }))
});

export const tableHeadSx = {
  '& .MuiTableCell-head': {
    border: '1px solid rgba(76, 78, 100, 0.12)',
    textAlign: 'center',
    p: '5px',
    '&:first-of-type': {
      p: '5px !important'
    }
  }
};

export const tableBodySx = {
  '& .MuiTableCell-body': {
    border: '1px solid rgba(76, 78, 100, 0.12)',
    textAlign: 'center',
    p: '0px !important',
    '&:first-of-type': {
      p: '0px !important'
    }
  }
};

export const noDataSx = {
  width: '100%',
  height: '500px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'rgb(83 84 96 / 12%)',
  fontSize: '20px',
  fontWeight: 'bold',
};

import { useProvideTags } from '../../utils/hooks/useProvideTags';
import { sessionApi } from '../session';

sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkOrderFilesTable: builder.query({
      query: (data) => `/work-order-files${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['WORK_ORDER_FILES']),
    }),
    downloadFileView: builder.mutation({
      query: (id) => ({
        url: `/work-order-files/${id}`,
        method: 'POST',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteFileViewWO: builder.mutation({
      query: (id) => ({
        url: `/work-order-files/${id}`,
        method: 'DELETE',
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
  }),
});

export const {
  useGetWorkOrderFilesTableQuery,
  useDownloadFileViewMutation,
  useDeleteFileViewWOMutation
} = sessionApi;

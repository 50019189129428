import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDetails, AccordionSummary, Box, Card, Divider, Typography
} from '@mui/material';
import React from 'react';
import { headerBtn } from '../../../../Invoices/style';
import { AccordionDetailsContainer, AccordionStyled } from './styles';

const TimelinePart = (props) => {
  const { timeline = [] } = props;

  return (
    <Card
      sx={{ padding: '1rem' }}
    >
      <AccordionStyled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            padding: '0!important',
            margin: '0',
            '& .MuiAccordionSummary-content': {
              margin: '0'
            }
          }}
        >
          <Box sx={{ ...headerBtn, mb: 0 }}>
            <Box>
              <Typography variant="h5" noWrap>
                Timeline part
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {timeline.map((item) => (
            <AccordionDetailsContainer key={item}>
              <CircleIcon sx={{ fontSize: '8px', mr: 3 }} />
              <Typography>{item}</Typography>
            </AccordionDetailsContainer>
          ))}
        </AccordionDetails>
      </AccordionStyled>

      <Divider />
    </Card>
  );
};

export default TimelinePart;
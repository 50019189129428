import React from 'react';
import Box from '@mui/material/Box';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import { Typography } from '@mui/material';
import { HeaderName, Maintenance } from './styles';

import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import ShowText from './ShowText';

export const columns = ({
  handleFilter,
  filters,
  aircraftOptions,
}) => ([
  {
    flex: 0.2,
    field: 'date',
    minWidth: 190,
    headerName: 'Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Date</Typography>
        <FiltrationDate
          onChange={(e) => handleFilter(e, 'date', true)}
          value={filters.date || null}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.date}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'comp_type',
    minWidth: 180,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>CRA/C or Comp TypeS</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.comp_type || ''}
          value="comp_type"
          placeholder="CRA/C or Comp TypeS"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.comp_type}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'reg',
    minWidth: 180,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>reg</Typography>
        <FiltrationSelect
          options={aircraftOptions}
          defaultValue={filters.crew_aircraft_id || ''}
          handleFilter={handleFilter}
          value="crew_aircraft_id"
          typeOptions="number"
        />

      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.reg}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'trainings',
    minWidth: 150,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Trainings</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.trainings || ''}
          value="trainings"
          placeholder="Trainings"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <ShowText text={row.trainings} />
    ),
  },
  {
    flex: 0.2,
    field: 'perform',
    minWidth: 150,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Perform</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.perform || ''}
          value="perform"
          placeholder="Perform"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <ShowText text={row.perform} />
    ),
  },
  {
    flex: 0.2,
    field: 'supervise',
    minWidth: 150,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>supervise</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.supervise || ''}
          value="supervise"
          placeholder="Trainings"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <ShowText text={row.supervise} />
    ),
  },
  {
    flex: 0.2,
    field: 'crs',
    minWidth: 150,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>CRS</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.crs || ''}
          value="crs"
          placeholder="Crs"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <ShowText text={row.crs} />
    ),
  },
  {
    flex: 0.2,
    field: 'operation_performed',
    minWidth: 300,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...HeaderName, ...Maintenance }}>Operation Performed</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.operation_performed || ''}
          value="operation_performed"
          placeholder="Operation Performed"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box sx={{ overflow: 'auto' }}>
        <Typography>{row.operation_performed}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'maintenance',
    minWidth: 280,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...HeaderName, ...Maintenance }}>Maintenance record reference</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.maintenance || ''}
          value="maintenance"
          placeholder="Maintenance record reference"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.maintenance}</Typography>
    ),
  }
]);

import FileSaver from 'file-saver';
import { useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDownloadInvoiceMutation, useDownloadUserInvoiceMutation, useGetProofOfPaymentMutation } from '../../../store/session';
import { useGetBoardDataQuery } from '../../../store/slices/invoiceManagementSlice';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import { INVOICE_TYPES } from './constants';

export const useInvoiceManagement = () => {
  const [search, setSearch] = useState('');
  const [invoiceFileDrawerState, setInvoiceFileDrawerState] = useState({
    isOpen: false,
    config: {
      invoiceFile: null,
      invoicePoP: null,
      proformFile: null,
      proformPoP: null
    }
  });
  const [filterState, setFilterState] = useState({
    search: null,
    payment_status: null,
    payment_type: null,
  });

  const { data: boardData, loading: isBoardDataLoading, refetch: refetchBoard } = useGetBoardDataQuery(stringifyLocationSearch({ ...filterState, search }), {
    refetchOnMountOrArgChange: true
  });
  const [downloadInvoice] = useDownloadInvoiceMutation();
  const [getProofOfPayment] = useGetProofOfPaymentMutation();
  const [openInvoiceFile] = useDownloadUserInvoiceMutation();

  const isLoading = isBoardDataLoading;

  const handleTasksByStatus = (statusOptions) => useMemo(() => {
    if (isLoading || !boardData) {
      return {
        invoices: [],
        count: 0
      };
    }

    const statusArray = Array.isArray(statusOptions) ? statusOptions : [statusOptions];
    const filteredInvoices = boardData.filter(item => statusArray.includes(item.payment_status));

    return {
      invoices: filteredInvoices,
      count: filteredInvoices.length
    };
  }, [boardData, isBoardDataLoading]);

  const getInvoiceTitle = (invoice, with_id = true) => {
    if (!invoice) {
      return '';
    }

    const parentId = invoice.parent_id;
    let { id } = invoice;
    let type = 'Invoice';

    if (parentId) {
      id = parentId;
      type = 'Inv. + Pr.';
    }

    if (invoice.is_proforma) {
      type = 'Proforma';
    }

    return `${type} ${with_id ? `#${id}` : ''}`;
  };

  const formatInvoice = (invoice) => {
    if (!invoice) {
      return {
        id: '',
        status: '',
        number: '',
        type: {
          icon: '',
          label: '',
          value: '',
        },
        amount: '',
        purpose: '',
        due_date: '',
        is_expired: false,
        is_expired_warning: false,
        sender: '',
        is_proforma: 0,
        invoice_number: '',
        invoice_name: '',
        orders: []
      };
    }

    const type = INVOICE_TYPES.find((invoiceType) => invoiceType.value === invoice.payment_type) || {
      icon: '',
      label: '',
      value: ''
    };

    return {
      id: invoice.id,
      status: invoice.payment_status,
      number: getInvoiceTitle(invoice),
      type,
      amount: `${invoice.amount} ${invoice.currency}`,
      purpose: invoice.purpose,
      due_date: invoice.due_date ?? '',
      is_expired: invoice.is_expired ?? false,
      is_expired_warning: invoice.is_expired_warning ?? false,
      sender: invoice.sender_name,
      is_proforma: invoice.is_proforma,
      invoice_number: invoice.invoice_number,
      invoice_name: invoice.invoice_name,
      orders: invoice.orders,
    };
  };

  const handlePreviewPoP = async (id) => {
    const toastId = toast.loading('Loading...');

    await getProofOfPayment(id).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleDownloadInvoice = async ({ id, file_name }) => {
    const toastId = toast.loading('Downloading...');
    const res = await downloadInvoice(id);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    const fileName = file_name?.replace( /\\/g, '/' ).replace( /.*\//, '' );
    FileSaver.saveAs(res.data, fileName);
    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleOpenInvoice = async ( id ) => {
    const toastId = toast.loading('Downloading...');

    const res = await openInvoiceFile(id);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    if (res.data instanceof Blob) {
      // Create a URL for the Blob
      const fileURL = URL.createObjectURL(res.data);
      window.open(fileURL, '_blank');
    } else {
      // If res.data is already a URL
      window.open(res.data, '_blank');
    }

    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleDownloadPoP = async ({ id, file_name }) => {
    const toastId = toast.loading('Downloading...');
    const res = await getProofOfPayment(id);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    const fileName = file_name?.replace( /\\/g, '/' ).replace( /.*\//, '' );
    FileSaver.saveAs(res.data, fileName);
    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleOpenInvoiceFileDrawer = (isOpen = true, config = {}) => {
    setInvoiceFileDrawerState(prev => ({
      ...prev,
      isOpen,
      config: {
        ...prev.config,
        ...config
      }
    }));
  };

  return {
    handleTasksByStatus,
    formatInvoice,
    handleDownloadInvoice,
    handleDownloadPoP,
    getInvoiceTitle,
    filterState,
    setFilterState,
    setSearch,
    refetchBoard,
    handlePreviewPoP,
    invoiceFileDrawerState,
    handleOpenInvoice,
    handleOpenInvoiceFileDrawer
  };
};

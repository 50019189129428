import {
  Box, Card, Typography, useMediaQuery
} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import EditableTable from '../../components/EditableTable';
import LinkButton from '../../components/LinkButton';
import {
  useDeleteFuelPriceMutation,
  useGetFuelPriceTableListQuery,
  useGetFuelSuppliersListQuery,
  useUpdateFuelPriceMutation
} from '../../store/session';
import { columns } from './columns';
import ImportFuelPrices from './components/importFuelPrices/ImportFuelPrices';
import UpdatePrice from './components/UpdatePrice';
import {
  ButtonStyle, TitleBtn, TitleBtnS, TitleContainer, TitleContainerS
} from './styles';
import { useFuelPriceLimitations } from './useFuelPriceLimitations';

const FuelPrice = () => {
  const [fuelId, setFuelId] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [importDrawerState, setImportDrawerState] = useState({
    isOpen: false
  });

  const { data: FuelSuppliersList } = useGetFuelSuppliersListQuery();

  const fuelOptions = FuelSuppliersList?.map((item) => ({
    value: item.id,
    label: item.fuel_suplier
  }));

  const {
    handleDoc,
  } = useFuelPriceLimitations();

  const maxWidth655px = useMediaQuery('(max-width:655px)');

  const handleCloseUpdatePriceForm = () => {
    setFuelId(null);
  };

  const handleOpenUpdatePriceForm = (id) => {
    setFuelId(id);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleOpenImportDrawer = (isOpen = false) => {
    setImportDrawerState(prev => ({
      ...prev,
      isOpen
    }));
  };

  useEffect(() => {
    if (fuelOptions) {
      fuelOptions?.push({ value: '', label: '-' });
    }
  }, [fuelOptions]);

  return (
    <Card sx={{ padding: '1rem' }}>
      <Box
        sx={{ ...TitleContainer, ...(maxWidth655px && TitleContainerS), pb: 3 }}
      >
        <Typography variant="h3">Manage Fuel Price</Typography>
        <Box
          display="flex"
          gap="10px"
          sx={{ ...TitleBtn, ...(maxWidth655px && TitleBtnS) }}
        >
          <LinkButton style={ButtonStyle} path="/fuel/price/create">Add Fuel Price</LinkButton>
          <Button onClick={handleDoc} variant="contained">Export</Button>
          <Button onClick={() => handleOpenImportDrawer(true)} variant="contained">Import Fuel Prices</Button>
        </Box>
      </Box>

      <EditableTable
        useHook={useGetFuelPriceTableListQuery}
        useHookUpdate={useUpdateFuelPriceMutation}
        useHookDelete={useDeleteFuelPriceMutation}
        columns={columns}
        sortByType="ASC"
        sortByActive="id"
        fuelOptions={fuelOptions}
        getRowHeight={() => 'auto'}
        handleOpenUpdatePriceForm={handleOpenUpdatePriceForm}
        tableParams={{ refresh }}
      />
      <UpdatePrice onClose={handleCloseUpdatePriceForm} fuelId={fuelId} onSubmit={handleRefresh} />

      <ImportFuelPrices 
        open={importDrawerState.isOpen}
        onClose={() => handleOpenImportDrawer(false)}
      />
    </Card>
  );
};

export default FuelPrice;

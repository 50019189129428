export const asterisk = {
  color: (theme) => theme.palette.error.main,
  marginInline: '4px'
};

export const headerTitle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

export const headerTitleS = {
  flexDirection: 'column',
  alignItems: 'start'
};
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateUserMutation, useGetPositionsQuery, useGetUserGroupsQuery, useGetUserMutation, useUpdateUserMutation
} from '../../../../store/session';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  defaultValues, defaultValuesUpdate, schema, schemaUpdate
} from './schema';
import { getOptions } from '../../../../utils/getOptions';
import { toast } from 'react-hot-toast';
import { useMediaQuery } from '@mui/material';

export const useUserForm = () => {
  const { id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCheck, setShowPasswordCheck] = useState(false);
  const navigate = useNavigate();

  const update = id !== 'create';

  const [getUser, { data: userData, isLoading: userDataLoading }] = useGetUserMutation();
  const { data: groupsList, isLoading: groupsLoading } = useGetUserGroupsQuery();
  const { data: positionList, isLoading: positionLoading } = useGetPositionsQuery();
  const [createUser, { isLoading: createUserLoading }] = useCreateUserMutation();
  const [updateUser, { isLoading: updateUserLoading }] = useUpdateUserMutation();

  const groupsListOptions = getOptions(groupsList);
  const positionsListOptions = getOptions(positionList);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => { event.preventDefault(); };
  const handleClickShowPasswordCheck = () => setShowPasswordCheck((show) => !show);
  const handleMouseDownPasswordCheck = (event) => { event.preventDefault(); };

  const maxWidth768px = useMediaQuery('(max-width:768px)');

  const {
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(update ? schemaUpdate : schema),
    defaultValues: update ? defaultValuesUpdate : defaultValues,
  });

  const setUser = async () => {
    const { data: result } = await getUser({ id });
    if (result) {
      Object.entries(result).forEach(([name, value]) => {
        if (name === 'positions') {
          const formattedPositionsValue = value.map(i => ({ value: i.id, label: positionList[i.id] }));

          setValue('positions', formattedPositionsValue);
        } else if (name === 'deactivated_at') {
          setValue('deactivated_at', !!value);
        } else if (name === 'groups_id') {
          setValue('groups_id', value ? `${value}` : value);
        } else {
          setValue(name, value);
        }
      });
    }
  };

  const handleChangePositions = (data, onChange) => {
    onChange(data);
    setValue('positions', data, { shouldTouch: true });
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const positions = data.positions ? data.positions.map(item => ({ id: +item.value })) : [];
    let resData = { ...data, positions };
    const formattedData = Object.keys(dirtyFields).reduce((res, key) => ({ ...res, [key]: data[key] }), {});

    if (update) {
      if (formattedData.positions) {
        const positionsUpdate = formattedData.positions.map(item => ({ id: +item.value }));
        resData = { ...formattedData, positions: positionsUpdate };
      } else {
        resData = { ...formattedData, ...(touchedFields.positions && { positions: [] }) };
      }

      const res = await updateUser({ id, data: resData });

      if (res.error) {
        toast.error(res.error.data.message || 'Something went wrong', {
          id: toastId
        });

        return;
      }

      toast.success('Successfully!', {
        id: toastId
      });
    } else {
      const res = await createUser(resData);

      if (res.error) {
        toast.error(res.error.data.message || 'Something went wrong', {
          id: toastId
        });

        return;
      }

      toast.success('Successfully!', {
        id: toastId
      });
    }

    navigate('/manage-users');
  };

  const isLoading = userDataLoading || groupsLoading || positionLoading;
  const isUpdating = createUserLoading || updateUserLoading;

  useEffect(() => {
    if (update && !positionLoading) {
      setUser();
    }
  }, [update, positionLoading]);

  return {
    showPassword,
    showPasswordCheck,
    maxWidth768px,
    isLoading,
    isUpdating,
    groupsListOptions,
    positionsListOptions,
    update,
    control,
    errors,
    groupsLoading,
    handleClickShowPassword,
    handleMouseDownPassword,
    userData,
    handleClickShowPasswordCheck,
    handleChangePositions,
    handleMouseDownPasswordCheck,
    handleSubmit,
    setValue,
    trigger,
    onSubmit,
  };
};

import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useGetFuelSuppliersListQuery, useImportFuelPricesMutation } from '../../../../store/session';
import { useGetTransactionUploadFileMutation } from '../../../../store/slices/financeSlice';
import { selectOptionFormat } from '../../../../utils/number';
import { defaultValues, schema } from './schema';

export const useImportFuelPrices = () => {
  const [uploadFuelFile, { isLoading: isLoadingFuelFile }] = useImportFuelPricesMutation();
  const { data: SupplierList, isLoading: supplierListLoad } = useGetFuelSuppliersListQuery();
  const [download, { isLoading: fileLoading }] = useGetTransactionUploadFileMutation();
  const [refresh, setRefresh] = useState(false);
  const [openErrors, setOpenErrors] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [currentUpload, setCurrentUpload] = useState(null);
  const [fileName, setFileName] = useState('');

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const formattedSupplierList = selectOptionFormat(SupplierList, 'fuel_suplier');

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleOpenErrors = (upload_id) => {
    setCurrentUpload(upload_id);
    setOpenErrors(true);
  };

  const handleCloseErrors = () => {
    setOpenErrors(false);
    setCurrentUpload(null);
  };

  const handleOpenInfo = (upload_id) => {
    setCurrentUpload(upload_id);
    setOpenInfo(true);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
    setCurrentUpload(null);
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    formData.append('upload_file', data?.upload_file);
    formData.append('supplier_id', data?.supplier_id);

    const res = await uploadFuelFile(formData);

    methods.reset();
    setFileName('');
    handleRefresh();

    if (res?.error) {
      toast.error(res?.error?.message || 'Something went wrong.', {
        id: toastId,
      });

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleDownloadFile = async (upload_id) => {
    if (fileLoading) return;
    const toastId = toast.loading('Opening...');

    await download(upload_id).then((res) => {
      if (!res?.errros) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  return {
    methods,
    onSubmit,
    formattedSupplierList,
    isLoadingFuelFile,
    supplierListLoad,
    fileName,
    setFileName,
    handleDownloadFile,
    refresh,
    handleCloseErrors,
    handleCloseInfo,
    handleOpenErrors,
    handleOpenInfo,
    openErrors,
    openInfo,
    currentUpload
  };
};

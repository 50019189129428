export const CREW_SAAB = 'saab';
export const CREW_ATR = 'atr';
export const CREW_OPS = 'ops';
export const CREW_ENGINEER = 'engineer';
export const CAPTAIN = 1;
export const CO_PILOTS = 2;
export const ENGINEER = 3;
export const CAA = 4;
export const OPS = 5;
export const SALES = 6;

export const CREW_POST = [
  { label: 'All', value: 0 },
  { label: 'Captain', value: 1 },
  { label: 'FO', value: 2 },
  { label: 'Engineer', value: 3 }
];
export const CREW_POST_OPTIONS = [
  { label: 'Captain', value: CAPTAIN },
  { label: 'FO', value: CO_PILOTS },
  { label: 'Engineer', value: ENGINEER },
  { label: 'CCA', value: CAA },
  { label: 'OPS', value: OPS },
  { label: 'SALES', value: SALES },
  { label: '-', value: '' },
];

export const ODS_POST_OPTIONS = [
  { label: 'Captain', value: CAPTAIN },
  { label: 'FO', value: CO_PILOTS },
];

export const OPTIONS_ACTIVE = [
  { label: 'yes', value: '1' },
  { label: 'no', value: '-1' },
  { label: '-', value: '' },
];

export const OPTIONS_PLANING_STATUS = [
  { label: 'Opened', value: '-1' },
  { label: 'Closed', value: '1' },
  { label: 'Canceled', value: 'canceled' },
  { label: '-', value: '' },
];

export const OPTIONS_LINE_CHECK = [
  { label: 'yes', value: '1' },
  { label: 'no', value: '-1' },
  { label: '-', value: '' },
];

export const AIRCRAFT_TYPE_LIST = [
  { value: 'NOTYPE', label: 'No type' }, 
  { value: 'SAAB', label: 'SAAB' }, 
  { value: 'ATR', label: 'ATR' }
];

import {
  object, string,
} from 'yup';

export const defaultValues = {
  purpose: ''
};

export const schema = object().shape({
  purpose: string().required('${label} cannot be blank.').label('Purpose').nullable(),
});

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import Flight from './Flight';
import { useOdsTrainingForm } from './useOdsTrainingForm';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { backIcon, headerSx } from './styles';

const OdsTrainingForm = ({ data, isPanel }) => {
  const {
    control,
    pilotNameOptions,
    getValues,
    watch,
    trigger,
    handleBack,
    handleSubmit,
    onSubmit,
    setValue,
    errors,
    setCrewPost,
    hasPermissionsOdsEdit
  } = useOdsTrainingForm(data);

  return (
    <Box>
      {!isPanel && (
      <Box sx={headerSx}>
        <ChevronLeftIcon sx={backIcon} onClick={handleBack} />
      </Box>
      )}
      <Flight
        control={control}
        errors={errors}
        pilotNameOptions={pilotNameOptions}
        setValue={setValue}
        trigger={trigger}
        setCrewPost={setCrewPost}
        getValues={getValues}
        watch={watch}
        isPanel
      />
      {!isPanel && (
        <Button disabled={!hasPermissionsOdsEdit} variant="contained" onClick={handleSubmit(onSubmit)} sx={{ mt: '25px' }}>Save</Button>
      )}

    </Box>
  );
};

export default OdsTrainingForm;

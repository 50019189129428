export const card = {
  mt: 1,
  mb: 5,
};

export const btn = {
  width: '100%',
  mt: '23px'
};

export const gridContainer = {
  flexGrow: 1,
};

export const gridContainerSx = {
  width: '100%',
  marginLeft: '-10px',
};

export const footerSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  pt: '20px'
};

export const cancelSx = {
  background: '#e1e5ec',
  color: 'rgba(0, 70, 136, 0.8)',
  ':hover': {
    color: '#fff',
  }
};

export const drawer = {
  '& .MuiPaper-root': {
    width: '100%',
  },
};

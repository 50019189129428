import {
  number,
  object, string
} from 'yup';

export const INCOME_STATUS = [
  { label: 'Paid', value: 'paid' },
  { label: 'Unpaid', value: 'unpaid' },
  { label: 'Canceled', value: 'canceled' }
];
export const schema = object().shape({
  status: object()
    .nullable()
    .required('${label} cannot be blank')
    .label('Status'),
  status_value: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Status'),
  paid: string()
    .nullable()
    .when('status_value', {
      is: (val) => val === 'paid',
      then: string().nullable().required('${label} cannot be blank'),
    })
    .label('Paid')
    .matches(
      /^$|^\d+$|^\d+\.[0-9][0-9]?$/,
      'Incorrect paid format.'
    ),
  day_of_payment: string()
    .nullable()
    .when('status_value', {
      is: (val) => val === 'paid',
      then: string().nullable().required('${label} cannot be blank'),
    })
    .label('Day of payment'),
});

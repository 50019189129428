
import {
  object, string,
} from 'yup';

export const defaultValues = {
  crew_id: '',
  crew_post: '',
  aircraft_type: '',
  ods_status: '',
  training_type: '',
  type: 5,
  total_training_time: null,
  start: null,
  finish: null,
};

export const schema = object().shape({
  crew_post: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('POSITION'),
  crew_id: string()
    .required('${label} cannot be blank.')
    .label('PILOT NAME'),
  aircraft_type: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('AIRCRAFT'),
  ods_status: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('STATUS'),
  training_type: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('TYPE OF TRAINING'),
  type: string()
    .nullable(),
  total_training_time: string()
    .nullable(),
  start: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('POSITIONING START'),
  finish: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('POSITIONING FINISH'),
});

export const header = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  pb: '10px',
  mb: '20px',
  borderBottom: '1px solid #eee',
  a: {
    textDecoration: 'none',
    color: '#fff'
  }
};

export const flexContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
};

export const settingsBtn = {
  textDecoration: 'none',
  color: '#fff'
};

export const containerSx = {
  padding: '30px ',
  paddingTop: '0px',
};

export const ContainerSmallXS = {
  padding: '30px ',
  paddingTop: '30px',
};

export const cardsContainer = {
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
};

export const ContainerSmall = {
  p: '20px 0',
  paddingTop: '20px',
};

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  ClickToEdit,
  DefaultText,
} from './styles';

const ParticipantsEdit = ({
  handleEdit, participantsList, labelInput, isLoading, type, options, activeItem, disabled
}) => {
  const handleClick = (e) => {
    if (!isLoading && !disabled) {
      handleEdit(e, participantsList /* text */, labelInput, type, options, activeItem);
    }
  };

  return (
    <Box
      onClick={(e) => handleClick(e)}
    >
      {participantsList.length ? participantsList.map((item, index) => {
        if (item?.crew_id && item?.label) {
          return (
            <Typography
              key={item.crew_id}
              sx={DefaultText}
            >
              {index + 1}
              .
              {item.label}
            </Typography>
          );
        }

        return (
          <Typography
            sx={ClickToEdit}
          >
            Click to edit
          </Typography>
        );
      }) : (
        <Typography
          sx={ClickToEdit}
        >
          Click to edit
        </Typography>
      )}
    </Box>
  ); };

export default ParticipantsEdit;

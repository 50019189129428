import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { DataGridPremium, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useGetStatisticByDepartureMutation } from '../../../../store/session';
import { columns } from './columns';

const Table = () => {
  const location = useLocation();

  const [rowData, setRowsData] = useState([]);

  const [getStatistic, { data, isLoading: isStatisticLoading }] = useGetStatisticByDepartureMutation({
    fixedCacheKey: 'get-statistic-by-departure',
  });

  const xpath = "//div[text()='MUI X Missing license key']";
  const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

  if (matchingElement) {
    matchingElement.remove();
  }

  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['airport_name'],
      },
    },
  });

  useEffect(() => {
    if (data) {
      const total = data.reduce((acc, current) => {
        const resFlights = current.flights.map(item => ({
          date: item.date,
          company_name: item.company_name,
          id: item.id,
          flight_id: item.id,
          airport_name: current.airport_name,
        }));

        return [
          ...acc,
          ...resFlights
        ];
      }, []);

      setRowsData(total);
    }
  }, [data]);

  useEffect(() => {
    if (!location.search) {
      setRowsData([]);
    }
  }, [location.search]);

  return (
    <Box sx={{
      mt: '30px', height: '65vh', width: '100%', p: '0px 15px'
    }}
    >
      <DataGridPremium
        rows={rowData || []}
        columns={columns}
        apiRef={apiRef}
        initialState={initialState}
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        loading={isStatisticLoading}
        height={110}
        getRowHeight={() => 'auto'}
        groupingColDef={{
          headerName: 'Departure',
          minWidth: 300,
          flex: 0.2
        }}
        sx={{
          '& .MuiDataGrid-root': {
            maxHeight: 900,
            overflow: 'auto !important'
          },
          '& .MuiDataGrid-virtualScroller': {
            marginTop: '0px !important',
          },
          '& .row-custom': {
            cursor: 'pointer'
          },
          '& .row-total': {
            backgroundColor: 'rgb(240, 255, 240)',
            cursor: 'auto',
          },
          '& .row-total:hover': {
            backgroundColor: 'rgb(240, 255, 240)',
            cursor: 'auto',
          }
        }}
      />
    </Box>
  ); };

export default Table;

const Avatar = (theme) => ({
  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.customColors.bodyBg,
      },
      rounded: {
        borderRadius: 8,
      },
    },
  },
  MuiAvatarGroup: {
    styleOverrides: {
      root: {
        justifyContent: 'flex-end',
        '.MuiCard-root & .MuiAvatar-root': {
          borderColor: theme.palette.background.paper,
        },
      },
    },
  },
});

export default Avatar;

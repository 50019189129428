import React from 'react';
import Box from '@mui/material/Box';
import DropDownMenu from '../../../../components/DropDownMenu';

import {
  listContainer, listItem, header, dropdownMenuSx
} from './styles';

const DropDown = ({
  open,
  onClose,
  anchorEl,
  selectedRow,
  useGetHook,
  usePostHook,
  columnType,
  hasPermissionsInvoicePayment
}) => (
  <DropDownMenu
    sx={dropdownMenuSx}
    transformOrigin={{
      vertical: 'right',
      horizontal: 'left',
    }}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={open}
    onClose={onClose}
    anchorEl={anchorEl}
  >
    <Box>
      <Box sx={header}>
        Actions
      </Box>

      <Box sx={listContainer}>
        {hasPermissionsInvoicePayment && (
          <Box
            sx={listItem}
          >
            <label htmlFor="file-upload">
              New File
              <input
                hidden
                type="file"
                accept="application/pdf"
                id="file-upload"
                onChange={(e) => {
                  usePostHook(selectedRow.id, e.target.files[0]);
                  onClose(e);
                }}
              />
            </label>
          </Box>
        )}

        {selectedRow?.[columnType] ? (
          <Box
            sx={listItem}
            onClick={async (event) => {
              await useGetHook(selectedRow.id);
              onClose(event);
            }}
          >
            Open File
          </Box>
        ) : null}

      </Box>
    </Box>
  </DropDownMenu>
);

export default DropDown;

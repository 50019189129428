import React, { useEffect } from 'react';
import {
  Card, Grid, Dialog, DialogContent, Box, Typography, Divider
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, defaultValues } from './schema';
import { RATE_TYPE_DUTY, RATE_TYPE_MONTH } from '../constants';
import Input from '../../../../form/components/Input';
import FormGroup from '../../../../form/components/FormGroup';
import { headerBtn } from '../../../Invoices/style';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';
import Button from '../../../../components/Button';
import { useUpdatePaymentDataMutation } from '../../../../store/slices/paymentDataSlice';
import toast from 'react-hot-toast';
import { useUpdateSalaryMutation } from '../../../../store/slices/salariesSlice';

const UpdateRate = ({
  open, onClose, handleRefresh, paymentData
}) => {
  const [updateRate] = useUpdatePaymentDataMutation();
  const [updateSalary] = useUpdateSalaryMutation();

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  useEffect(() => {
    setValue('month_rate', paymentData?.month_rate);
    setValue('day_rate', paymentData?.day_rate);
    setValue('night_rate', paymentData?.night_rate);
    setValue('rate_type', paymentData?.rate_type);
  }, [open]);

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const updateData = {
      id: paymentData.id,
      data: {
        rate_data: {
          night_rate: data?.night_rate,
          day_rate: data?.day_rate,
          month_rate: data?.month_rate
        }
      }
    };

    if (paymentData?.type === 'salary') {
      await updateSalary(updateData).then((res) => {
        if (!res?.erro) {
          toast.success('Success!', {
            id: toastId,
          });

          handleRefresh();
          reset();
          onClose();
        } else {
          toast.error('Something went wrong.', {
            id: toastId,
          });
        }
      });
    } else {
      await updateRate(updateData).then((res) => {
        if (!res?.erro) {
          toast.success('Success!', {
            id: toastId,
          });

          handleRefresh();
          reset();
          onClose();
        } else {
          toast.error('Something went wrong.', {
            id: toastId,
          });
        }
      });
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogContent>
        <Card sx={{ padding: 4 }}>
          <Box sx={headerBtn}>
            <Box>
              <Typography variant="h5" noWrap>
                {`Update Rate - ${paymentData?.name}`}
              </Typography>
            </Box>
            <CloseIcon
              sx={CloseIconStyle}
              onClick={onClose}
            />
          </Box>

          <Divider />
          <form onSubmit={handleSubmit(onSubmit)}>
            {watch('rate_type') === RATE_TYPE_MONTH && (
              <FormGroup
                label="Month Rate"
                required
                hasError={!!errors.month_rate}
              >
                <Controller
                  name="month_rate"
                  control={control}
                  render={({ field }) => (
                    <Input type="number" placeholder="Rate" {...field} />
                  )}
                />
              </FormGroup>
            )}
            {watch('rate_type') === RATE_TYPE_DUTY && (
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <FormGroup
                    label="Day Duty Rate"
                    required
                    hasError={!!errors.day_rate}
                  >
                    <Controller
                      name="day_rate"
                      control={control}
                      render={({ field }) => (
                        <Input type="number" placeholder="Day Duty Rate" {...field} />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={6}>
                  <FormGroup
                    label="Night Duty Rate"
                    required
                    hasError={!!errors.night_rate}
                  >
                    <Controller
                      name="night_rate"
                      control={control}
                      render={({ field }) => (
                        <Input type="number" placeholder="Night Duty Rate" {...field} />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            )}

            <Button type="submit" title="Submit" />
          </form>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateRate;

import { Box, Drawer, styled } from '@mui/material';

export const BarBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledLink = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  marginRight: theme.spacing(8),
}));

export const StyledMobileNav = styled(Drawer)(({ theme }) => ({
  color: '#fff',
  '& .menu-content': {
    backgroundColor: 'rgba(0, 52, 103, 0.9)'
  },
}));
import React from 'react';
import TypeCard from '../TypeCard';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { Controller } from 'react-hook-form';
import FieldError from '../../../../form/components/FieldError';

const FuelRamp = ({ control, isPanel, errors = {} }) => (
  <TypeCard title="FUEL (RAMP)" variant="sky" isPanel={isPanel}>
    <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1 }}>

      <Grid item xs={12} lg={12}>
        <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} lg={2}>
            <FormGroup label="PLANNED" required={isPanel} hasError={!!errors.fuel_planned_kg}>
              <Controller
                name="fuel_planned_kg"
                control={control}
                render={({ field }) => (
                  <Input
                    InputProps={{
                      endAdornment: <InputAdornment position="end">KG</InputAdornment>,
                    }}
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.fuel_planned_kg} />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={2}>
        <FormGroup label="UPLIFT">
          <Controller
            name="fuel_uplift"
            control={control}
            render={({ field }) => (
              <Input
                InputProps={{
                  endAdornment: <InputAdornment position="end">L</InputAdornment>,
                }}
                {...field}
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={2}>
        <FormGroup label="DENSITY" hasError={!!errors.fuel_density}>
          <Controller
            name="fuel_density"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
              />
            )}
          />

          <FieldError error={errors.fuel_density} />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={2}>
        <FormGroup label="UPLIFT">
          <Controller
            name="fuel_uplift_kg"
            control={control}
            render={({ field }) => (
              <Input
                InputProps={{
                  disabled: true,
                  endAdornment: <InputAdornment position="end">KG</InputAdornment>,
                }}
                {...field}
              />
            )}
          />

        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={2}>
        <FormGroup label="DEPARTURE">
          <Controller
            name="fuel_departure_kg"
            control={control}
            render={({ field }) => (
              <Input
                InputProps={{
                  disabled: true,
                  endAdornment: <InputAdornment position="end">KG</InputAdornment>,
                }}
                {...field}
              />
            )}
          />

        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={2}>
        <FormGroup label="ARRIVAL" required={isPanel} hasError={!!errors.fuel_arrival_kg}>
          <Controller
            name="fuel_arrival_kg"
            control={control}
            render={({ field }) => (
              <Input
                InputProps={{
                  endAdornment: <InputAdornment position="end">KG</InputAdornment>,
                }}
                {...field}
              />
            )}
          />
          <FieldError error={errors.fuel_arrival_kg} />
        </FormGroup>
      </Grid>
    </Grid>
  </TypeCard>
);

export default FuelRamp;

import React, { useEffect, useState } from 'react';
import Drawer from '../../../../components/Drawer';
import { Card, Grid } from '@mui/material';
import {
  useSearchPaymentDataQuery,
  useSearchTransactionQuery,
  useStoreSalaryMutation
} from '../../../../store/slices/salariesSlice';
import { Controller, useForm } from 'react-hook-form';
import { defaultValues, schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import moment from 'moment/moment';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import DatePicker from '../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Button from '../../../../components/Button';
import ServerAutocomplete from '../../../../form/components/ServerAutocomplete';

const NewSalary = ({
  open,
  onClose,
  handleRefresh,
  currentBlock
}) => {
  const [storeSalary] = useStoreSalaryMutation();
  const handleError = useHandleQueryFormError();
  const [currentPayment, setCurrentPayment] = useState({ value: null, label: '' });

  const {
    handleSubmit,
    watch,
    setError,
    getValues,
    reset,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    data.salary_date = data.salary_date ? moment(data.salary_date).format('YYYY-MM-DD') : '';

    const res = await storeSalary({ ...(data), payment_department_id: currentBlock });

    if (res.error) {
      if (res.error.status === 403) {
        toast.error('Access is denied', {
          id: toastId,
        });

        return;
      }
      handleError(res.error, setError, getValues, toastId);

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    handleRefresh();
    reset();
    onClose();
  };

  useEffect(() => {
    setValue('payment_department_id', currentBlock);
  }, [currentBlock]);

  const handleSelectPayment = (selection) => {
    const option = selection || { value: null, label: '' };

    setCurrentPayment(option);
    setValue('payment_id', option?.value || null);
  };

  return (
    <Drawer
      title="Create New Salary Record"
      open={open}
      onClose={onClose}
    >
      <Card sx={{ padding: 4 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item xs={12}>
              <FormGroup label="Payment Data" hasError={!!errors.payment_id}>
                <Controller
                  name="payment_id"
                  control={control}
                  render={({ field }) => (
                    <ServerAutocomplete
                      field={field}
                      withData
                      value={currentPayment}
                      queryParams={{ department_id: currentBlock }}
                      placeholder="Search Payment Data"
                      searchQueryFn={useSearchPaymentDataQuery}
                      handleSelect={handleSelectPayment}
                    />
                  )}
                />

                <FieldError error={errors.transaction_id} />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup label="Salary Date" hasError={!!errors.salary_date}>
                <Controller
                  name="salary_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="YYYY-MM-DD"
                      dateFormat="yyyy-MM-dd"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setDateValue(e, setValue, 'salary_date');
                      }}
                      value={validateDatePickerValue(field.value)}
                    />
                  )}
                />

                <FieldError error={errors.salary_date} />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                title="Submit"
              />
            </Grid>
          </Grid>
        </form>
      </Card>
    </Drawer>
  );
};

export default NewSalary;

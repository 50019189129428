import React from 'react';
import TypeCard from '../TypeCard';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import ViewTable from '../../../../components/ViewTable';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { defaultValues, schema } from './schema';
import { buttonSx } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import FieldError from '../../../../form/components/FieldError';
import { useCreateOdsDefectMutation } from '../../../../store/session';
import { columns } from './columns';
import { toast } from 'react-hot-toast';

const Defects = ({
  control, isPanel, getMainValues, setValue, hasPermissionsOdsEdit, isOffline, savedData,
}) => {
  const {
    control: subFormControl,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

  const ods_id = getMainValues('id');

  const [create, { isLoading }] = useCreateOdsDefectMutation();

  const {
    fields,
  } = useFieldArray({
    name: 'defects',
    control,
  });

  const onSubmit = async (values) => {
    if (isOffline) {
      toast.error('Need to have internet connection');

      return;
    }

    const toastId = toast.loading('Loading...');

    const res = await create({ ...values, ods_id });

    if (res.error) {
      toast.error(res.error.data.message || 'Something went wrong.', {
        id: toastId
      });

      return;
    }

    toast.success('Defect was added!', {
      id: toastId
    });

    if (savedData) {
      localStorage.setItem(`${savedData.index}`, JSON.stringify({ ...savedData, defects: res.data }));
    }

    setValue('defects', res.data);

    reset();
  };

  return (
    <TypeCard title="DEFECTS" variant="red" isPanel={isPanel}>
      <Grid container spacing={7} rowSpacing={6} sx={{ flexGrow: 1 }}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ width: '100%', overflow: 'auto', }}>
            <ViewTable
              list={fields}
              columns={columns}
            />
          </Box>

        </Grid>

        <Grid item xs={12}>
          <FormGroup label="ADD DEFECT" required hasError={!!errors.description}>
            <Controller
              name="description"
              control={subFormControl}
              render={({ field }) => (
                <Input
                  placeholder="Description"
                  {...field}
                />
              )}
            />

            <FieldError error={errors.description} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Button disabled={!hasPermissionsOdsEdit || isLoading} onClick={handleSubmit(onSubmit)} sx={buttonSx}>Add Defect</Button>
        </Grid>
      </Grid>
    </TypeCard>
  ); };

export default Defects;

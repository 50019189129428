import {
  object,
  string,
} from 'yup';

export const defaultValues = {
  airport: '',
  airport_fees_e: '',
  airport_fees_f: '',
};

export const schema = object().shape({
  airport: string()
    .required()
    .nullable()
    .label('Email'),
});

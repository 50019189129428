import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import {
  useGetAirportListQuery, useGetFewOdsFormsQuery, useGetOdsStatusesQuery, useGetOdsTocQuery
} from '../../../store/session';
import { getOptions } from '../../../utils/getOptions';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { stringifyLocationSearch } from '../../../utils/locationSearch';

export const useOdsForm = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [active, setActive] = useState([]);
  const [navItems, setNavItems] = useState([]);
  const [isLoadingUpdate, setIsLoading] = useState(false);
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  const [airportList, setAirportList] = useState(JSON.parse(localStorage.getItem('airportList')) || []);
  const [odsStatuses, setOdsStatuses] = useState(JSON.parse(localStorage.getItem('odsStatuses')) || {});
  const [odsToc, setOdsToc] = useState(JSON.parse(localStorage.getItem('odsToc')) || {});

  const hasPermissionsOdsEdit = usePermissions('ods_edit');

  const params = new URLSearchParams(window.location.search);
  const ods_id = params.get('id')?.split(',');

  const { data: forms, isLoading } = useGetFewOdsFormsQuery(stringifyLocationSearch({ ods_id }), { skip: !ods_id?.length });
  const { data: airportListData } = useGetAirportListQuery();
  const { data: odsStatusesData } = useGetOdsStatusesQuery();
  const { data: odsTocData } = useGetOdsTocQuery();

  const odsStatusesOptions = getOptions(odsStatuses);
  const odsTocOptions = getOptions(odsToc);

  const dataTabs = forms?.map(item => ({
    id_form: item.id,
    sheet_no: item.index
  }));

  const handleSubmit = () => {
    setIsSubmit(true);
  };

  const observer = useMemo(
    () => new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActive((prevActive) => [...prevActive, entry.target.id]);
        } else {
          setActive((prevActive) => prevActive.filter((activeId) => activeId !== entry.target.id));
        }
      });
    }),
    []
  );

  function handleItemClick(ref) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  function openPrevOds() {
    const prevOdsId = forms[0]?.previous_ods_id;
    window.open(`${process.env.REACT_APP_URL}ods?id=${prevOdsId}`);
  }

  const handleStatusChange = () => {
    setIsOffline(!navigator.onLine);
  };

  useEffect(() => {
    navItems.forEach((item) => {
      if (item.ref?.current) {
        observer.observe(item.ref.current);
      }
    });

    return () => {
      navItems.forEach((item) => {
        if (item.ref?.current) {
          observer.unobserve(item.ref.current);
        }
      });
    };
  }, [navItems, observer]);

  useEffect(() => {
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    let offlineToastId;

    if (isOffline) {
      offlineToastId = toast.loading('Offline mode. Do not reload the page or the data will be lost.', {
        position: 'top-center',
        style: {
          border: '1px solid red',
          padding: '16px',
          color: 'red',
          maxWidth: '400px',
          width: '100%',
        },
        iconTheme: {
          primary: 'red',
          secondary: '#FFFAEE',
        },
      });
    }

    if (!isOffline) {
      toast.dismiss(offlineToastId);
      toast.success('Online mode.', {
        position: 'top-center',
      });
    }

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOffline]);

  useEffect(() => {
    if (!isOffline && airportListData && odsStatusesData && odsTocData) {
      localStorage.setItem('airportList', JSON.stringify(airportListData));
      localStorage.setItem('odsStatuses', JSON.stringify(odsStatusesData));
      localStorage.setItem('odsToc', JSON.stringify(odsTocData));

      setOdsStatuses(odsStatusesData);
      setOdsToc(odsTocData);
      setAirportList(airportListData);
    }
  }, [isOffline, airportListData, odsStatusesData, odsTocData]);

  return {
    forms,
    isLoading,
    isLoadingUpdate,
    isSubmit,
    setNavItems,
    navItems,
    setIsLoading,
    airportList,
    isOffline,
    odsStatusesOptions,
    odsTocOptions,
    active,
    dataTabs,
    setIsSubmit,
    hasPermissionsOdsEdit,
    openPrevOds,
    handleItemClick,
    handleSubmit,
  };
};

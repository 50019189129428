export const header = {
  color: '#616161',
  fontWeight: '600 !important',
  whiteSpace: 'nowrap',
  minWidth: '135px',
  textTransform: 'capitalize !important',
};

export const body = {
  minWidth: '125px',
};

export const input = {
  '& .MuiFormLabel-root': {
    fontSize: '14px',
  },
};

export const tableCell = {
  fontSize: '13px !important',
  'input': {
    fontSize: '13px !important',
    '&.MuiFormLabel-root-MuiInputLabel-root': {
      fontSize: '13px !important',
    }
  },
};
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FiltrationInput from '../../../../components/EditableTable/FiltrationInput';
import TextEdit from '../../../../components/EditableTable/TextEdit';
import LaunchIcon from '@mui/icons-material/Launch';
import { TYPE_INPUT, TYPE_SELECT_DATE, } from '../../../../components/EditableTable/constants';
import Tooltip from '@mui/material/Tooltip';
import {
  HeaderName, StyledDelete
} from '../../../../components/EditableTable/styles';
import FiltrationDate from '../../../../components/EditableTable/FiltrationDate';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { menuItem, invalidMenuItem, readonlyMenuItem } from './styles';

export const columns = ({
  handleFilter,
  filters,
  handleOpen,
  handleOpenDeleteModal,
  handleOpenDropDown,
  hasPermissions,
  handlePreviewFile,
}) => ([
  {
    flex: 0.2,
    field: 'document_name',
    minWidth: 180,
    headerName: 'Document Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Document Name</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.document_name || ''}
          handleFilter={handleFilter}
          placeholder="Document Name"
          value="document_name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'document_name', row)}
        text={row.document_name}
        disabled={!hasPermissions}
        labelInput="Enter Document Name"
        type={TYPE_INPUT}
      />
    ),
  }, // document_name
  {
    flex: 0.2,
    field: 'expiry_date',
    minWidth: 180,
    headerName: 'Expiry Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Expiry Date</Typography>
        <FiltrationDate
          value={filters.expiry_date || null}
          onChange={(e) => {
            handleFilter(e, 'expiry_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'expiry_date', row)}
        text={row.expiry_date}
        labelInput="Enter Expiry Date"
        disabled={!hasPermissions}
        type={TYPE_SELECT_DATE}
      />
    ),
  }, // expiry_date
  {
    // flex: 0.2,
    field: 'file_exist',
    minWidth: 120,
    headerName: 'Document',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Document</Typography>
      </Box>
    ),
    renderCell: ({ row }) => {
      if (hasPermissions) {
        return (
          <Box sx={{ ...menuItem, ...(row.file_exist ? null : invalidMenuItem) }}>
            { row.file_exist ? (<EditIcon onClick={(e) => handleOpenDropDown(e, row)} />) : (<AddIcon onClick={(e) => handleOpenDropDown(e, row)} />)}
          </Box>
        );
      }

      return (
        <Tooltip title={row.file_exist ? 'Open FDBK' : 'Empty FDBK file'}>
          <Box sx={{ ...menuItem, ...(row.file_exist ? null : readonlyMenuItem) }}>
            { row.file_exist ? (<LaunchIcon onClick={() => handlePreviewFile(row.id)} />) : (<LaunchIcon />)}
          </Box>
        </Tooltip>
      );
    },
  }, // document
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => hasPermissions && (
      <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
    ),
  }, // actions
]);

export const defaultValues = {
  to: '',
  cc: 'sales@fleetair.eu',
  subject: '',
  description: `BEST REGARDS,
TYMOFIEIEV DENYS
FLEET AIR OPS
TEL: +36 1 4453979 (24H)
FAX: +36 1 5772378
SITA: BUDFA7X
AFTN: KAIRFRFX
EMAIL: SALES@FLEETAIR.EU`,
};

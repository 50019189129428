import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PollIcon from '@mui/icons-material/Poll';
import { Grid } from '@mui/material';
import React from 'react';
import { useGetStatisticsTotalQuery } from '../../../store/slices/financeSlice';
import CardStatistics from '../components/CardStatistics';

const Statistics = () => {
  const { data: statisticsTotal, refetch, isSuccess } = useGetStatisticsTotalQuery();

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        {statisticsTotal && (
        <Grid container spacing={6}>
          {statisticsTotal?.total && (
          <Grid item xs={12} md={3} sm={6}>
            <CardStatistics title="Total" icon={<PollIcon />} stats={statisticsTotal?.total || 0} color="info" />
          </Grid>
          )}
          {statisticsTotal?.paid && (
          <Grid item xs={12} md={3} sm={6}>
            <CardStatistics title="Paid" icon={<CreditScoreIcon />} stats={statisticsTotal?.paid || 0} />
          </Grid>
          )}
          {statisticsTotal.unpaid && (
          <Grid item xs={12} md={3} sm={6}>
            <CardStatistics title="Unpaid" icon={<CreditCardOffIcon />} stats={statisticsTotal.unpaid || 0} color="error" />
          </Grid>
          )}
        </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Statistics;
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '../../../../../components/Button';
import Select from '../../../../../form/components/Select';
import Typography from '@mui/material/Typography';
import ServiceFormDrawer from './ServiceFormDrawer';
import { useFieldArray } from 'react-hook-form';
import { getOptions } from '../../../../../utils/getOptions';
import {
  useGetSessionByAirportMutation,
  useGetPaymentByAirportQuery,
  useGetAirportListQuery,
  useGetServicesByAirportQuery,
} from '../../../../../store/session';
import {
  additionalValuesContainer, controllerContainer, deleteIcon, serviceContainer, supplierButton
} from './styles';
import { SRVSERVICE_SLOT_ID, SRVSUP_SLOT_ID } from '../../../../../constans/flight';
import { CircularProgress, Divider } from '@mui/material';
import { StyledDelete } from './FuelForm/styles';

const ServicesForm = ({
  control, setValue, watch, setServicesIsLoading
}) => {
  const [suppliers, setSuppliers] = useState({});
  const [isSuppliersLoaded, setIsSuppliersLoaded] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const {
    fields: list,
    update,
  } = useFieldArray({
    name: 'planing_services',
    control,
  });
  const airports = list?.map(item => item.airport_id)?.join(',');
  const [getSuppliers, { isLoading: getSuppliersLoading }] = useGetSessionByAirportMutation();
  const { data: airportList, isFetching: airportListFetching, isLoading: airportListLoading } = useGetAirportListQuery();
  const { data: services, isFetching: servicesFetching, isLoading: servicesLoading } = useGetServicesByAirportQuery(airports, { skip: !list?.length });
  const { data: payments, isFetching: paymentsFetching, isLoading: paymentsLoading } = useGetPaymentByAirportQuery();

  const isLoading = airportListLoading || getSuppliersLoading || servicesLoading || paymentsLoading || airportListFetching || servicesFetching || paymentsFetching;

  const handleOpen = (supplierLabel) => {
    setOpenDrawer(supplierLabel);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleAddServiceValues = (index) => {
    const subService = watch(`planing_services.${index}`);
    const subServiceItem = subService?.services?.[0];

    setValue(`planing_services.${index}.services.${subService.services.length}`, {
      pl_serv_airport: subServiceItem.pl_serv_airport,
      pl_serv_description: '',
      pl_serv_fleet_id: subServiceItem.pl_serv_fleet_id,
      pl_serv_hop_id: subServiceItem.pl_serv_hop_id,
      pl_serv_mail: '',
      pl_serv_payment_method: '',
      pl_serv_real_price: '',
      pl_serv_service: '',
      pl_serv_status: '',
      pl_serv_supplier: '',
      itemId: null,
    });
  };

  const handleUpdate = (i, subindex, item, newData) => {
    update(
      i,
      {
        ...item,
        services: item.services.map((data, dataIndex) => (dataIndex === subindex ? { ...data, ...newData } : data)),
      }
    );
  };

  const handleChangeService = async (i, subindex, item, newData) => {
    update(
      i,
      {
        ...item,
        services: item.services.map((data, dataIndex) => (dataIndex === subindex ? { ...data, ...newData, pl_serv_supplier: null, } : data)),
      }
    );

    const res = await getSuppliers({ airports: newData.pl_serv_airport, service: newData.pl_serv_service, });
    const data = res.data?.suppliers;
    if (data) {
      setSuppliers((prev) => ({
        ...prev,
        [newData.pl_serv_airport]: { ...(suppliers[newData.pl_serv_airport] || {}), [newData.pl_serv_service]: data }
      }));
    }
  };

  useEffect(() => {
    if (list.length && !isSuppliersLoaded) {
      setIsSuppliersLoaded(true);
      list.forEach((service) => {
        service.services.forEach(async(item) => {
          if (item.pl_serv_service) {
            const res = await getSuppliers({ airports: item.pl_serv_airport, service: item.pl_serv_service });
            const data = res.data?.suppliers;
            if (data) {
              setSuppliers((prevSuppliers) => ({
                ...prevSuppliers,
                [item.pl_serv_airport]: { ...(prevSuppliers[item.pl_serv_airport] || {}), [item.pl_serv_service]: data }
              }));
            }
          }
        });
      });
    }
  }, [isSuppliersLoaded, list]);

  const paymentsOptions = getOptions(payments?.methods);
  const suppliersList = list.reduce((res, item) => [...res, ...item.services.map((item) => item.pl_serv_supplier)], []);

  const handleDelete = (index, i) => {
    const subService = watch(`planing_services.${index}`);

    update(index, {
      ...subService,
      services: subService.services.filter((item, itemIndex) => itemIndex !== i)
    });
  };

  const handleServiceSupplierLabel = (serviceSupplierId, supplierLabel) => {
    if (serviceSupplierId === SRVSUP_SLOT_ID) {
      return 'SLOT REQUEST';
    }

    return suppliersList.includes(serviceSupplierId) ? supplierLabel : 'TBA';
  };

  useEffect(() => {
    setServicesIsLoading(isLoading);
  }, [isLoading]);

  return (
    <Box sx={serviceContainer}>
      {list.map((item, index) => {
        const airport = airportList.find(airportItem => airportItem.id === item.airport_id) || {};
        const suppliersHadEmail = [];

        return (
          item.services.map((service, i) => {
            const suppliersOptions = getOptions(suppliers[service.pl_serv_airport]?.[service.pl_serv_service]);
            const supplierLabel = suppliersOptions.find(option => +option.value === service.pl_serv_supplier)?.label;
            const isEmailRequired = (serviceSupplier) => {
              if (!serviceSupplier) {
                return false;
              }

              if (suppliersHadEmail.includes(serviceSupplier)) {
                return false;
              }

              suppliersHadEmail.push(serviceSupplier);

              return true;
            };

            if (service.pl_serv_service === SRVSERVICE_SLOT_ID) {
              service.pl_serv_supplier = SRVSUP_SLOT_ID;
            }

            return (
              <>
                <Grid container spacing={5} key={i} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <Grid item xs={3} lg={3}>
                    {i === 0 && (
                      <>
                        <Typography>
                          {`${airport.airport_iata}/${airport.airport_icao} (${airport.airport_name})`}
                        </Typography>
                        <Button title="ADD SERVICE" onClick={() => handleAddServiceValues(index)} />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Box sx={additionalValuesContainer}>
                      <Box sx={controllerContainer}>
                        <Select
                          options={getOptions(services?.services?.[service.pl_serv_airport])}
                          placeholder="Service"
                          label="Service"
                          value={service.pl_serv_service || null}
                          onChange={(e, { props: { value } }) => handleChangeService(index, i, item, { ...service, pl_serv_service: +value })}
                        />

                        <Select
                          options={suppliersOptions}
                          label="Supplier"
                          placeholder="Supplier"
                          disabled={isLoading}
                          value={service.pl_serv_supplier || null}
                          onChange={(e, { props: { value } }) => handleUpdate(index, i, item, { ...service, pl_serv_supplier: +value })}
                        />

                        {service.pl_serv_service !== SRVSERVICE_SLOT_ID && (
                          <Select
                            options={paymentsOptions}
                            label="Payment"
                            placeholder="Payment"
                            value={service.pl_serv_payment_method || null}
                            onChange={(e, { props: { value } }) => handleUpdate(index, i, item, { ...service, pl_serv_payment_method: +value })}
                          />
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2} lg={2}>
                    {isEmailRequired(service.pl_serv_supplier) && (
                      <Box sx={controllerContainer}>
                        {!!service.pl_serv_supplier && (
                          <Button
                            sx={supplierButton}
                            title={(
                              <Box sx={{
                                maxWidth: '274px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }}
                              >
                                {handleServiceSupplierLabel(service.pl_serv_supplier, supplierLabel) ?? (
                                  <CircularProgress size={18} color="inherit" />)}
                              </Box>
                            )}
                            onClick={() => handleOpen({
                              id: service.pl_serv_supplier,
                              allServices: item?.services.filter(srvItem => srvItem.pl_serv_supplier === service.pl_serv_supplier),
                              serviceLabels: getOptions(services?.services?.[service.pl_serv_airport]),
                              additionalData: item?.additional_data
                            })}
                          />
                        )}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={1} lg={1}>
                    <Box sx={controllerContainer}>
                      <StyledDelete sx={deleteIcon} onClick={() => handleDelete(index, i)} />
                    </Box>
                  </Grid>
                </Grid>
                {(i === item.services.length - 1 && list.length - 1 !== index) && ( <Divider />)}
              </>
            );
          })
        );
      })}

      <ServiceFormDrawer open={openDrawer} onClose={handleClose} />
    </Box>
  ); };
export default ServicesForm;

export const header = {
  color: '#616161',
  fontWeight: '600 !important',
  whiteSpace: 'nowrap',
  // minWidth: '125px',
  textTransform: 'capitalize !important',
};

export const body = {
  minWidth: '125px',
};

export const textarea = {
  padding: '8.5px 14px',
  borderRadius: '8px',
  color: 'rgba(76, 78, 100, 0.87)',
  border: '1px solid rgba(76, 78, 100, 0.22)',
  height: '39px',
  width: '100%',
};

export const deleteIcon = {
  cursor: 'pointer',
  marginTop: '7px'
};

export const pdfIcon = {
  cursor: 'pointer',
  marginTop: '7px',
  marginLeft: '20px'
};

export const btn = {
  maxWidth: '230px',
  mt: 5
};

export const content = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  minWidth: '1000px',
  pt: '20px',
};

export const itemNum = {
  display: 'flex',
  alignItems: 'start',
  mt: '10px'
};

export const containerInvoice = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px'
};

export const boxInvoice = {
  display: 'flex',
  gap: '10px'
};

export const price = {
  display: 'flex',
  alignItems: 'center',
  gap: 5
};

// ** React Imports
import React, { useEffect, useState } from 'react';

// ** MUI Imports
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import toast from 'react-hot-toast';

import { useGetNotificationListQuery, useReadAllNotificationMutation, useReadNotificationMutation } from '../../store/session';
import Button from '../Button';
import LinkButton from '../LinkButton/Button';
import NotificationDropdownItem from './NotificationDropdownItem';
// ** Styled Menu component
const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 380,
    overflow: 'hidden',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}));

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));

// ** Styled component for the title in MenuItems
export const MenuItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  flex: '1 1 100%',
  overflow: 'hidden',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginBottom: theme.spacing(0.75)
}));

// ** Styled component for the subtitle in MenuItems
export const MenuItemSubtitle = styled(Typography)({
  flex: '1 1 100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
});

const ScrollWrapper = ({ children, hidden }) => {
  if (hidden) {
    return <Box sx={{ maxHeight: 349, overflowY: 'auto', overflowX: 'hidden' }}>{children}</Box>;
  }

  return <Box sx={{ maxHeight: 350, overflow: 'auto' }}>{children}</Box>;
};

const NotificationDropdown = () => {
  // ** Props
  const [tabVisibility, setTabVisibility] = useState(true);

  const { data, refetch } = useGetNotificationListQuery(undefined, {
    skip: !tabVisibility,
    pollingInterval: 30000,
  });

  const changeTabVisibility = () => {
    const tabHidden = document.hidden;

    setTabVisibility(!tabHidden);
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', changeTabVisibility);

    return () => document.removeEventListener('visibilitychange', changeTabVisibility);
  }, []);

  const notifications = data?.data;

  const [anchorEl, setAnchorEl] = useState(null);

  const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'));

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  
  const [readNotification] = useReadNotificationMutation();
  const [readAllNotification] = useReadAllNotificationMutation();

  const handleRead = async (id) => {
    const toastId = toast.loading('Loading...');
    await readNotification(id).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        refetch();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };
  const handleReadAll = async () => {
    const toastId = toast.loading('Loading...');
    await readAllNotification().then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        refetch();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  return (
    <>
      <IconButton color="inherit" aria-haspopup="true" onClick={handleDropdownOpen} aria-controls="customized-menu">
        <Badge
          color="error"
          variant="dot"
          invisible={!notifications?.length}
          sx={{
            '& .MuiBadge-badge': { top: 4, right: 4, boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}` }
          }}
        >
          <NotificationsNoneIcon sx={{ color: 'white', fontSize: 'xx-large' }} />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{ cursor: 'default', userSelect: 'auto', backgroundColor: 'transparent !important' }}
        >
          <Box sx={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'
          }}
          >
            <Typography sx={{ cursor: 'text', fontWeight: 600 }}>Notifications</Typography>
            <Chip
              skin="light"
              size="small"
              color="primary"
              label={`${data?.total || notifications?.length} New`}
              sx={{
                height: 20, fontSize: '0.75rem', fontWeight: 500, borderRadius: '10px'
              }}
            />
          </Box>
        </MenuItem>
        <ScrollWrapper hidden={hidden} sx={{ width: '600px' }}>
          {notifications && notifications.map((notification, index) => <NotificationDropdownItem notification={notification} index={index} handleRead={handleRead} />)}
        </ScrollWrapper>
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            py: 3.5,
            borderBottom: 0,
            display: 'flex',
            // flexDirection: 'column',
            gap: '1rem',
            cursor: 'default',
            userSelect: 'auto',
            backgroundColor: 'transparent !important',
            borderTop: theme => `1px solid ${theme.palette.divider}`

          }}
        >
          {notifications?.length ? (
            <>
              <LinkButton style={{ width: '100%', height: 'auto' }} path="/my-profile/profile">
                <Button fullWidth onClick={handleDropdownClose}>
                  Open All Notifications
                </Button>
              </LinkButton>
              <Button
                sx={{ width: '100%', height: 'auto' }}
                onClick={() => handleReadAll()}
              >
                Clear All
              </Button>
            </>
          ) : (
            <Typography sx={{ cursor: 'text', fontWeight: 600 }}>No New Notifications</Typography>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

export default NotificationDropdown;

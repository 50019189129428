import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  Dialog, DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import Autocomplete from '../../../../form/components/Autocomplete';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import { useGenerateSalariesMutation } from '../../../../store/slices/salariesSlice';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import { headerBtn } from '../../../Invoices/style';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';
import { GreenButton } from '../../../Logistics/style';
import { defaultValues, schema } from './schema';

const GenerateSalaries = ({
  open, onClose, departmentsList, handleRefresh
}) => {
  const [generateSalaries, { isLoading: generating }] = useGenerateSalariesMutation();

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const departmentOptions = (departmentsList || []).map(user => ({
    value: user?.id,
    label: user?.name
  }));

  const onSubmit = async (data) => {
    const toastId = toast.loading('Deleting...');

    await generateSalaries(data).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });

        onClose();
        reset();
        handleRefresh();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        sx: {
          minHeight: 400
        }
      }}
    >
      <DialogContent>
        <Card sx={{ padding: 4 }}>
          <Box sx={headerBtn}>
            <Box>
              <Typography variant="h5" noWrap>
                Generate Salaries
              </Typography>
            </Box>

            <Box />
            
            <Box sx={{ justifySelf: 'end' }}>
              <CloseIcon
                sx={CloseIconStyle}
                onClick={onClose}
              />
            </Box>
          </Box>

          <Divider />

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <FormGroup
                  label="Departments"
                  required
                  hasError={!!errors.payment_departments}
                >
                  <Controller
                    name="payment_departments"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={departmentOptions}
                        size="small"
                        multiple
                        placeholder="Departments"
                        onChange={(e, value ) => {
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Button
                  title="Select all departments"
                  onClick={() => {
                    setValue('payment_departments', departmentOptions);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <FormGroup label="Salary Date" hasError={!!errors.salary_date}>
                  <Controller
                    name="salary_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="YYYY-MM"
                        dateFormat="yyyy-MM"
                        showMonthYearPicker
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e, setValue, 'salary_date', 'YYYY-MM');
                        }}
                        value={validateDatePickerValue(field.value)}
                      />
                    )}
                  />

                  <FieldError error={errors.salary_date} />
                </FormGroup>
              </Grid>

              <Grid xs={12}>
                <Box sx={{ float: 'right' }}>
                  <Button type="submit" title="Generate" sx={GreenButton} disabled={generating} />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default GenerateSalaries;

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  defaultValues, schema
} from './schema';
import {
  Box, Card, Typography, Grid, CardContent, Button
} from '@mui/material';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import Autocomplete from '../../../../form/components/Autocomplete';

const TrainingForm = ({ onSubmit, options }) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const handleChangeParticipants = (data, onChange) => {
    onChange(data);
    setValue('participants', data, { shouldTouch: true });
  };

  return (
    <form>
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box>
          <Typography variant="h3">Create New Training</Typography>
        </Box>

        <CardContent sx={{ mt: 5 }}>
          <Grid container spacing={5}>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Type Of Training " required hasError={!!errors.training_type}>
                <Controller
                  name="training_type"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Training Type"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.training_type} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Location" required hasError={!!errors.location}>
                <Controller
                  name="location"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Location"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.location} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Instructor" required hasError={!!errors.instructor}>
                <Controller
                  name="instructor"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Instructor"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.instructor} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Training Date" required hasError={!!errors.training_date}>
                <Controller
                  name="training_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Enter Training Date"
                      dateFormat="yyyy-MM-dd"
                      placeholder="2023-01-01"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.training_date} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Training Start" hasError={!!errors.training_start}>
                <Controller
                  name="training_start"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Enter Training Start Date"
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="yyyy-MM-dd HH:mm"
                      placeholder="2023-01-01"
                      showMonthDropdown
                      timeIntervals={1}
                      isClearable
                      showYearDropdown
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.training_start} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Training Finish" hasError={!!errors.training_end}>
                <Controller
                  name="training_end"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Enter Training Finish Date"
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="yyyy-MM-dd HH:mm"
                      placeholder="2023-01-01"
                      inter
                      showMonthDropdown
                      isClearable
                      timeIntervals={1}
                      showYearDropdown
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.training_end} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormGroup label="Participants" hasError={!!errors.participants}>
                <Controller
                  name="participants"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      id="tags-outlined"
                      placeholder="Participants"
                      options={options.map(option => ({ label: option.label, value: option.crew_id })) || []}
                      getOptionLabel={(option) => {
                        const selectedOption = options.find((opt) => opt.crew_id === option.crew_id);

                        return selectedOption ? selectedOption.label : '';
                      }}
                      value={field.value ?? []}
                      onChange={(_, newValue) => {
                        handleChangeParticipants(newValue, field.onChange);
                      }}
                      disableCloseOnSelect
                    />
                  )}
                />
                <FieldError error={errors.participants} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormGroup label="Remarks">
                <Controller
                  name="remarks"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Remarks"
                      multiline
                      rows={4}
                      fullWidth
                      {...field}
                    />
                  )}
                />

              </FormGroup>
            </Grid>

          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default TrainingForm;

import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import toast from 'react-hot-toast';
import { menuContainer, menuItem, popoverIconContainer } from './styles';
import { Typography } from '@mui/material';
import LinkButton from '../../../../components/LinkButton/Button';

const ActionMenu = ({ item, odsRecalculate, openMenu }) => (
  <Box sx={menuContainer}>
    <Box sx={menuItem}>
      <AddIcon onClick={() => {
        toast.promise(
          odsRecalculate(item.id),
          {
            loading: 'Recalculating...',
            success: <Typography>Sheets was recalculated.</Typography>,
            error: <Typography>All sheets not was recalculated.</Typography>,
          }
        );
      }}
      />
    </Box>
    <Box sx={{ ...menuItem, ...popoverIconContainer }}>
      <ListIcon onClick={(e) => openMenu(e, item.id)} />
    </Box>
    <Box>
      <LinkButton path={`/directories/ods/update/${item.id}`} target="_blank">
        <Box sx={menuItem}>
          <EditIcon />
        </Box>
      </LinkButton>
    </Box>
  </Box>
);

export default ActionMenu;


import {
  number,
  object, string
} from 'yup';

export const defaultValues = {
  expiry_date: null,
  threshold_days: null,
  document_name: '',
  file: '',
  feedback: '',
};

export const schema = object().shape({
  document_name: string()
    .required('${label} cannot be blank.')
    .label('Training Name'),
  threshold_days: number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .label('Threshold Days')
    .typeError('${label} must be a number.')
});

export const OPTIONS_STATE = [
  { label: 'CREATED', value: '0' },
  { label: 'PERFORMED', value: '3' },
  { label: 'CLOSED', value: '5' },
  { label: 'REOPENED', value: '7' },
  { label: '-', value: null },
];

export const OPTIONS_LINE_CHECK = [
  { label: 'yes', value: '1' },
  { label: 'no', value: '0' },
  { label: '-', value: '' },
];

export const headerSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '15px',
  mb: '20px'
};

export const backIcon = {
  cursor: 'pointer',
};

export const taskBlock = {
  mt: '5px',
};

export const buttonBlock = {
  display: 'flex',
  gap: '10px'
};

export const headerTitle = {
  display: 'flex',
  alignItems: 'center',
  gap: '5px'
};

export const headerSmall = {
  flexDirection: 'column',
  alignItems: 'start'
};

export const buttonBlockSmall = {
  flexDirection: 'column',
};

export const buttonBlockS = {
  width: '100%',
};

export const tableContainer = {
  mt: '50px',
  '& .row-m': {
    backgroundColor: '#f5fffa',
    '&.Mui-selected': {
      backgroundColor: '#f5fffa !important',
    },
    '&:hover': {
      backgroundColor: '#f5fff6',
    }
  },
  '& .row-f': {
    backgroundColor: '#F0FFFF',
    '&.Mui-selected': {
      backgroundColor: '#F0FFFF !important',
    },
    '&:hover': {
      backgroundColor: '#F0FFFF',
    }
  },
  '& .row-cnld': {
    backgroundColor: '#fff5ee',
    '&.Mui-selected': {
      backgroundColor: '#fff5ee !important',
    },
    '&:hover': {
      backgroundColor: '#fff2ee !important',
    }
  },
  '& .row-yellow': {
    backgroundColor: '#f1c40f33',
    '&.Mui-selected': {
      backgroundColor: '#f1c40f66  !important',
    },
    '&:hover': {
      backgroundColor: 'rgba(241, 196, 15, 0.3) !important',
    }
  },
};

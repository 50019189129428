
import {
  object, string,
} from 'yup';

export const defaultValues = {
  expiry_date: null,
  document_name: '',
  file: '',
};

export const schema = object().shape({
  document_name: string()
    .required('${label} cannot be blank.')
    .label('Training Name'),
});

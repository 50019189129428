import { Box, Chip, Typography } from '@mui/material';
import Button from '../../../../components/Button';
import { HeaderName } from '../../../../components/EditableTable/styles';
import { TextWrap } from '../../../Logistics/style';

export const columns = ({
  getInvoiceTitle,
  isDecline,
  handlePreviewPoP,
  handleOpenInvoice,
  handleOpenInvoiceFileDrawer
}) => ([
  {
    flex: 0.1,
    field: 'id',
    minWidth: 100,
    headerName: '#',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>#</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Chip label={row?.id} variant="outlined" sx={{ fontWeight: 'bolder' }} />
    ),
  },
  {
    flex: 0.15,
    field: 'is_proforma',
    minWidth: 160,
    headerName: 'Order Type',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Order Type</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Chip label={getInvoiceTitle(row, false)} variant="outlined" sx={{ fontWeight: 'bolder' }} />
    ),
  },
  {
    flex: 0.15,
    field: 'payment_type',
    minWidth: 140,
    headerName: 'Payment Type',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Payment Type</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Chip label={row?.payment_type} variant="outlined" sx={{ fontWeight: 'bolder', textTransform: 'capitalize' }} />
    )
  },
  {
    // flex: 0.1,
    field: 'amount',
    minWidth: 120,
    headerName: 'Amount',
    align: 'left',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Amount</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Chip label={row?.amount} variant="outlined" sx={{ fontWeight: 'bolder', textTransform: 'capitalize' }} />
    )
  },
  {
    // flex: 0.1,
    field: 'currency',
    minWidth: 120,
    headerName: 'Currency',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Currency</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Chip label={row?.currency} variant="outlined" sx={{ fontWeight: 'bolder', textTransform: 'capitalize' }} />
    ),
  },
  (isDecline && {
    flex: 0.2,
    field: 'decline_purpose',
    minWidth: 180,
    headerName: 'Decline Purpose',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Decline Purpose</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        sx={TextWrap}
      >
        {row?.decline_purpose}
      </Typography>
    )
  }),
  {
    flex: 0.2,
    field: 'sender_name',
    minWidth: 200,
    headerName: 'Sender Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Sender Name</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize', ...TextWrap }}
      >
        {row?.sender_name}
      </Typography>
    )
  },
  {
    flex: 0.2,
    field: 'payment_date',
    minWidth: 180,
    headerName: 'Payment Date',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Payment Date</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={TextWrap}
      >
        {row?.payment_date}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'description',
    minWidth: 240,
    headerName: 'Description',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Description</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={TextWrap}
      >
        {row?.description}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'files',
    minWidth: 240,
    headerName: 'Files',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Files</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Button
        title="Open Files"
        onClick={() => handleOpenInvoiceFileDrawer(true, {
          invoiceFile: row?.invoice_path ? { id: row?.id, name: row?.invoice_path.split('/')?.[5] } : null,
          invoicePoP: row?.proof_of_payment_file_path ? { id: row?.id, name: row?.proof_of_payment_file_path.split('/')?.[5] } : null,
          proformFile: row?.parent_invoice?.invoice_path ? { id: row?.parent_invoice?.id, name: row?.parent_invoice?.invoice_path.split('/')?.[5] } : null,
          proformPoP: row?.parent_invoice?.proof_of_payment_file_path ? { id: row?.parent_invoice?.id, name: row?.parent_invoice?.proof_of_payment_file_path.split('/')?.[5] } : null,
        })}
      />

      // <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      //   {!!row?.invoice_path && (
      //   <Typography onClick={() => handleOpenInvoice(row?.id)} sx={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
      //     {getNameInPath(row?.invoice_path)}
      //   </Typography>
      //   )}
      //   {!!row?.proof_of_payment_file_path && (
      //   <Typography onClick={() => handlePreviewPoP(row?.id)} sx={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
      //     {getNameInPath(row?.proof_of_payment_file_path)}
      //   </Typography>
      //   )}
      // </Box>
    ),
  }
].filter(item => !!item));

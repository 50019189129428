import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import moment from 'moment';
import TableHead from './TableHead';
import TableBody from './TableBody';
import { DAY_IN_WEEK, WEEK } from './constants';

import {
  tableSx,
  TableContainerSX,
} from './styles';

const TableDeck = ({ data, date }) => {
  const daysInMonth = moment(date, 'MMM-DD-YYYY').daysInMonth();
  const firstDayInMonth = moment(date, 'MMM-DD-YYYY').startOf('month').format('ddd');
  const tableAmount = Math.ceil((daysInMonth + WEEK[firstDayInMonth]) / DAY_IN_WEEK);

  return (
    <TableContainer sx={TableContainerSX}>
      {Array.from({ length: tableAmount }).map((_, index) => (
        <Table
          sx={tableSx}
          aria-label="sticky table"
        >
          <TableHead data={data} />

          <TableBody
            data={data}
            date={date}
            index={index}
            firstDayInMonth={firstDayInMonth}
            daysInMonth={daysInMonth}
          />
        </Table>
      ))}
    </TableContainer>
  );
};

export default TableDeck;

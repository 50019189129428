export const PartsStorage = [
  { value: 0, label: 'New' },
  { value: 1, label: 'Overhauled' },
  { value: 2, label: 'Repaired' },
  { value: 3, label: 'Inspected' },
  { value: 4, label: 'Broken' },
  // { value: 5, label: 'Under repair' },
  { value: 6, label: 'Recycle' },
  { value: 7, label: 'Sale' },
  { value: 8, label: 'BER' },
  { value: 9, label: 'Under Overhaul' },
  { value: 10, label: 'Under Repair' },
];

export const PartsStorageFilter = [
  { value: 0, label: 'New' },
  { value: 1, label: 'Overhauled' },
  { value: 2, label: 'Repaired' },
  { value: 3, label: 'Inspected' },
  { value: 4, label: 'Broken' },
  // { value: 5, label: 'Under repair' },
  { value: 6, label: 'Recycle' },
  { value: 7, label: 'Sale' },
  { value: 8, label: 'BER' },
  { value: 9, label: 'Under Overhaul' },
  { value: 10, label: 'Under Repair' },
  { value: '', label: '-' }
];

import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormGroup from '../../../../../form/components/FormGroup';
import Input from '../../../../../form/components/Input';
import TypeCard from '../../../../Ods/OdsForm/TypeCard';
import DatePicker from '../../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../../utils/setDateValue';

const DueAtAc = ({ disabled }) => {
  const { control, setValue } = useFormContext();

  const maxWidth768px = useMediaQuery('(max-width:768px)');

  return (
    <TypeCard title="DUE AT AC" variant="yellow">
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="DUE AT AC DATE">
            <Controller
              render={({ field } ) => (
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm"
                  showMonthDropdown
                  showYearDropdown
                  disabled={disabled}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 'due_at_ac_date');
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
              control={control}
              name="due_at_ac_date"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="DUE AT AC FH">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="0"
                  type="number"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="due_at_ac_fh"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="DUE AT AC FC">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="0"
                  type="number"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="due_at_ac_fc"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </TypeCard>
  );
};

export default DueAtAc;

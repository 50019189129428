import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle
} from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import DefaultButton from '../../../../../components/Button';
import { YellowButton } from '../../../../Logistics/style';
import { useSetInvoiceArchiveMutation } from '../../../../../store/slices/invoiceManagementSlice';

const ArchiveButton = ({ invoiceId, onSubmit }) => {
  const [archiveInvoice, { isLoading }] = useSetInvoiceArchiveMutation();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOpen = () => {
    setConfirmOpen(true);
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleDecline = async () => {
    const toastId = toast.loading('Loading...');
    const { error } = await archiveInvoice(invoiceId);

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId
    });
    setConfirmOpen(false);
    onSubmit();
  };

  return (
    <>
      <Button
        sx={YellowButton}
        variant="contained"
        onClick={handleOpen}
      >
        Move to archive
      </Button>
      <Dialog
        open={confirmOpen}
        onClose={handleClose}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want move to archive this invoice? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={handleClose}>
            Cancel
          </DefaultButton>
          <Button
            sx={YellowButton}
            onClick={handleDecline}
            disabled={isLoading}
          >
            Move
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArchiveButton;

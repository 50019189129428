import { Card, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import { useDeleteFuelPaymentMutation, useGetFuelPaymentsListQuery } from '../../../store/session';
import { columns } from './columns';

const Table = (props) => {
  const { title, type } = props;
  const navigate = useNavigate();

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card sx={{ padding: '1rem' }}>
          <Box sx={{
            pb: 3, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between',
          }}
          >
            <Typography variant="h3" noWrap sx={{ textTransform: 'capitalize' }}>
              {title}
            </Typography>
            <Button
              variant="contained"
              title="Add Fuel Payments"
              onClick={() => {
                navigate('/fuel/payments/create', { replace: false });
              }}
            />
          </Box>
          <EditableTable
            getRowId={(row) => row.payment_id}
            useHook={useGetFuelPaymentsListQuery}
            useHookDelete={useDeleteFuelPaymentMutation}
            getRowHeight={() => 'auto'}
            sortByType="DESC"
            sortByActive="payment_id"
            navigate={navigate}
            tableParams={{
              type
            }}
            columns={columns}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Table;

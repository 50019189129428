import {
  Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@mui/material';
import React from 'react';
import Button from '../../../../../../components/Button';
import { useDeleteCreditNoteFileMutation, useOpenCreditNoteFileMutation } from '../../../../../../store/slices/logisticsSlice';
import { useCreditNoteFiles } from '../../../../../Logistics/Suppliers/CreditNotes/useCreditNoteFiles';
import FileDrawer from '../../../../../TrainingModule/FileDrawer/FileDrawer';

const CreditNotesTable = (props) => {
  const { creditNotes = [] } = props;
  const filesProps = useCreditNoteFiles();

  return (
    <>
      <Typography variant="h6" sx={{ mt: 2 }}>
        Credit Notes
      </Typography>
      <Table>
        <TableHead>
          <TableRow sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr 2fr 3fr 1fr' }}>
            <TableCell>ID</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Files</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {creditNotes.map((creditNote) => (
            <TableRow key={creditNote.id} sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr 2fr 3fr 1fr' }}>
              <TableCell>{creditNote.id}</TableCell>
              <TableCell>{creditNote.value}</TableCell>
              <TableCell>{creditNote.date}</TableCell>
              <TableCell>{creditNote.description ? ((creditNote.description === 'null') ? 'N/A' : creditNote.description) : 'N/A'}</TableCell>
              <TableCell><Button title="Files" onClick={() => filesProps?.handleFileDrawer(true, creditNote?.id, null, 'Credit Note', creditNote?.id)} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <FileDrawer
        open={filesProps.fileDrawerState.isOpen}
        onClose={() => filesProps.handleFileDrawer(false)}
        title={filesProps.fileDrawerState.name}
        itemName={filesProps.fileDrawerState.currentName}
        itemsList={filesProps.fileDrawerState.fileList}
        uploadFile={(file) => filesProps.updateFiles(
          filesProps.fileDrawerState.currentId,
          file,
          filesProps.fileDrawerState.type,
          () => filesProps.handleFileDrawer(
            true,
            filesProps.fileDrawerState.currentId,
            filesProps.fileDrawerState.type,
            filesProps.fileDrawerState.name,
            filesProps.fileDrawerState.currentName
          )
        )}
        hasPermissions
        isAttachLoading={filesProps.isAttachLoading}
        isLoadingFiles={filesProps.isLoadingFiles}
        useOpenFileMutation={useOpenCreditNoteFileMutation}
        useDeleteFileMutation={useDeleteCreditNoteFileMutation}
      />
    </>
  );
};

export default CreditNotesTable;

import {
  array, boolean,
  object, string
} from 'yup';

export const defaultValues = {
  invoice_type: '', // proforma, e_invoice, final_invoice
  customer_name: '',
  customer_address: '',
  customer_tax: '',
  payment_method: '', // bank_transfer, cash
  completion_date: '',
  issue_date: '',
  due_date: '',
  currentInvoice: false,
  currency_id: null,
  invoice_data: [
    {
      description: '',
      price: 0
    }
  ]
};

export const schema = object().shape({
  currentInvoice: boolean(),
  invoice_type: string()
    .when('currentInvoice', {
      is: val => val,
      then: string().nullable(),
      otherwise: string().required(),
    })
    .label('Invoice Type'),
  customer_name: string()
    .when('currentInvoice', {
      is: val => val,
      then: string().nullable(),
      otherwise: string().required(),
    })
    .label('Customer Name'),
  customer_address: string()
    .when('currentInvoice', {
      is: val => val,
      then: string().nullable(),
      otherwise: string().required(),
    })
    .label('Customer Address'),
  customer_tax: string()
    .when('currentInvoice', {
      is: val => val,
      then: string().nullable(),
      otherwise: string().required(),
    })
    .label('Customer Tax'),
  payment_method: string()
    .when('currentInvoice', {
      is: val => val,
      then: string().nullable(),
      otherwise: string().required(),
    })
    .label('Payment Method'),
  completion_date: string()
    .when('currentInvoice', {
      is: val => val,
      then: string().nullable(),
      otherwise: string().required(),
    })
    .label('Completion Date'),
  issue_date: string()
    .when('currentInvoice', {
      is: val => val,
      then: string().nullable(),
      otherwise: string().required(),
    })
    .label('Issue Date'),
  due_date: string()
    .when('currentInvoice', {
      is: val => val,
      then: string().nullable(),
      otherwise: string().required(),
    })
    .label('Due Date'),
  currency_id: string()
    .required('${label} cannot be blank.')
    .label('Due Date'),
  invoice_data: array().of(object({}).shape({
    description: string().required('${label} cannot be blank.').label('Description'),
    price: string().required('${label} cannot be blank.').label('Price'),
  })),
});

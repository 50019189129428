import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  HeaderName, StyledDelete, StyledPencil,
} from '../../../components/EditableTable/styles';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import LinkButton from '../../../components/LinkButton/Button';

export const columns = ({
  handleFilter,
  filters,
  handleOpenDeleteModal,
}) => ([
  {
    flex: 0.3,
    field: 'srvsup_name',
    minWidth: 330,
    headerName: 'Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.srvsup_name || ''}
          value="srvsup_name"
          placeholder="Name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
      >
        {row.srvsup_name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'srvsup_phone',
    minWidth: 170,
    headerName: 'Address',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Phone</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.srvsup_phone || ''}
          value="srvsup_phone"
          placeholder="Phone"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
      >
        {row.srvsup_phone}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'srvsup_email',
    minWidth: 200,
    headerName: 'Shortname',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Email</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.srvsup_email || ''}
          value="srvsup_email"
          placeholder="Email"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
      >
        {row.srvsup_email}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'airport',
    minWidth: 230,
    headerName: 'Airport',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Airport</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.airport || ''}
          value="airport"
          placeholder="Airport"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
      >
        {row.airport}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'service',
    minWidth: 100,
    headerName: 'Services',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Services</Typography>

      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.service}
      </Typography>
    ),
  },
  {
    width: 130,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <LinkButton path={`/directories/supplier/${row.id}`}>
          <StyledPencil />
        </LinkButton>
        {' '}
        <StyledDelete onClick={() => { handleOpenDeleteModal(row.id); }} />
        {' '}
      </>
    ),
  },
]);

import {
  object,
  string,
} from 'yup';

export const defaultValues = {
  doc_name: '',
  post_captain: false,
  post_engineer: false,
  post_fo: false,
  expired_treshold: 0,
};

export const schema = object().shape({
  doc_name: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Name'),
  expired_treshold: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Expired threshold days'),
});

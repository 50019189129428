import React from 'react';
import { Box, TableCell, TableRow as TableRowMui } from '@mui/material';
import {
  tableRow, tableCell,
} from './styles';

const TableRow = ({
  columns,
  row,
  rowIndex,
  sxFunction = () => null,
  onRowClick = () => null,
}) => (
  <TableRowMui
    sx={{
      ...tableRow,
      ...sxFunction(row),
    }}
    onClick={() => onRowClick(row)}
  >
    {columns.map(({ id, cell }, colIndex ) => (
      <TableCell
        key={rowIndex.toString() + colIndex.toString()}
        sx={tableCell}
      >
        <Box data-cell-container>
          {cell ? cell(row, rowIndex) : row[id]}
        </Box>
      </TableCell>
    ))}
  </TableRowMui>
);

export default TableRow;

import React from 'react';
import { FPD_COLORS } from '../constants';
import Box from '@mui/material/Box';

export const ViewColumn = ({ value, fdp, bg }) => (
  <Box sx={{
    backgroundColor: FPD_COLORS[fdp] || bg,
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
  >
    {value}
  </Box>
);

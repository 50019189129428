import * as React from 'react';
import { DataGridPremium, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useGetStatisticBySectorMutation } from '../../../../store/session';
import { columns } from './columns';

const Table = () => {
  const location = useLocation();

  const [rowData, setRowsData] = useState([]);

  const [getStatistic, { data, isLoading: isStatisticLoading }] = useGetStatisticBySectorMutation({
    fixedCacheKey: 'get-statistic-by-sector',
  });

  const xpath = "//div[text()='MUI X Missing license key']";
  const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

  if (matchingElement) {
    matchingElement.remove();
  }

  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['sector', 'company_name'],
      },
    },
  });

  useEffect(() => {
    if (data) {
      const total = [];
      data.forEach(({ companies, sector }) => {
        Object.values(companies).forEach(company => {
          company.forEach(({ sector: compSector, ...item }) => {
            total.push({ sector: compSector || sector, ...item });
          });
        });
      });

      setRowsData(total);
    }
  }, [data]);

  useEffect(() => {
    if (!location.search) {
      setRowsData([]);
    }
  }, [location.search]);

  return (
    <Box sx={{
      mt: '30px', height: '65vh', width: '100%', p: '0px 15px'
    }}
    >
      <DataGridPremium
        rows={rowData || []}
        columns={columns}
        getRowId={(row) => row.flight_id}
        apiRef={apiRef}
        initialState={initialState}
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        loading={isStatisticLoading}
        getRowHeight={() => 'auto'}
        rowGroupingColumnMode="multiple"
        groupingColDef={(params) => (params.fields.includes('company_name')
          ? {
            leafField: 'Company Name',
            mainGroupingCriteria: 'company_name',
            flex: 0.2
          }
          : {
            headerName: 'Sector',
            minWidth: 300,
            flex: 0.2
          })}
        sx={{
          '& .MuiDataGrid-root': {
            maxHeight: 900,
            overflow: 'auto !important'
          },
          '& .MuiDataGrid-virtualScroller': {
            marginTop: '0px !important',
          },
          '& .row-custom': {
            cursor: 'pointer'
          },
        }}
      />
    </Box>
  ); };

export default Table;

import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import ContentPageLoader from '../../../../components/Loader/ContentPageLoader';
import PdfLogo from '../../../../components/PdfLogo';
import LogBookTotals from './LogBookTotals';
import TableHead from './TableHead';
import { columns } from './columns';
import {
  loaderSx, tableContainer, tableSx, tableBodySx,
} from './styles';

export const LogBookTable = ({
  isLoading, isPdf, data
}) => (
  <TableContainer sx={tableContainer}>
    {isLoading && <ContentPageLoader containerSx={loaderSx} />}
    <Box id="forPdfFTL" sx={{ width: 'fit-content', p: '30px' }}>
      {isPdf && <PdfLogo title="PILOT LOGBOOK" /> }
      <Table
        sx={tableSx(isPdf)}
        aria-label="sticky table"
      >
        <TableHead />

        <TableBody sx={tableBodySx}>

          {data?.data?.map((item, i) => (
            <TableRow key={i}>
              {columns.map(column => (
                <TableCell key={column.field}>
                  {item[column.field]}
                </TableCell>
              ))}
            </TableRow>
          ))}

          <LogBookTotals data={data} />
        </TableBody>
      </Table>
    </Box>
  </TableContainer>
);

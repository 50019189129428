import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Drawer from '../../../../../../components/Drawer';
// import Button from '@mui/material/Button';
import { FormGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import toast from 'react-hot-toast';
import Button from '../../../../../../components/Button';
import ContentPageLoader from '../../../../../../components/Loader/ContentPageLoader';
import DatePicker from '../../../../../../form/components/DatePicker';
import Input from '../../../../../../form/components/Input';
import Select from '../../../../../../form/components/Select';
import {
  useGetFuelSuppliersListQuery,
  useGetPlaningFuelAirportSuppliersQuery,
  useUpdateFuelSuppliersMutation
} from '../../../../../../store/session';
import { validateDatePickerValue } from '../../../../../../utils/setDateValue';
import {
  addSx,
  cancelSx,
  footerPrices,
  footerSx, StyledDelete
} from './styles';
// import { getOptions } from '../../../../../../utils/getOptions';
// import { fetchCurrencies, useGetUnitsListQuery } from '../../../../../../store/slices/logisticsSlice';
// import { useAppDispatch } from '../../../../../../hooks/toolkitHooks';
// import { useSelector } from 'react-redux';

const FuelPrices = ({
  open, isFlightFuelLoading, onClose, airportName, refetchFlightFuelAirport, planingFuel, handleFlightData
}) => {
  const defaultValues = {
    id: null,
    airport_id: null,
    fuel_suppliers: []
  };

  const { data: listSuppliers, isLoading: isLoadingSuppliers } = useGetFuelSuppliersListQuery();
  const { data: planingFuelAirportSuppliers, isLoading, refetch, } = useGetPlaningFuelAirportSuppliersQuery({ id: planingFuel?.id }, {
    skip: !planingFuel?.id
  });
  // const { data: units } = useGetUnitsListQuery({ type: 'mass' });
  // const dispatch = useAppDispatch();
  // useEffect(() => {
  //   dispatch(fetchCurrencies());
  // }, []);
  // const { currencies } = useSelector((state) => state.logistics);

  const [updateFuel] = useUpdateFuelSuppliersMutation();

  const {
    handleSubmit,
    setValue,
    control,
    reset
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const {
    fields: supplierFields,
    append: supplierAppend,
    remove: supplierRemove,
  } = useFieldArray({
    name: 'fuel_suppliers',
    control,
  });

  const resSupplier = supplierFields?.map((e) => e.fuel_suplier);

  const supplierOptions = listSuppliers?.filter((e) => (
    !resSupplier?.includes(e.fuel_suplier)
  )).map((item) => ({ value: item.id, label: item.fuel_suplier }));

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const setFuelOrderForm = () => {
    setValue('id', planingFuelAirportSuppliers.id);
    setValue('airport_id', planingFuel?.plfuel_ap_id);
    setValue('fuel_suppliers', planingFuelAirportSuppliers.fuel_suppliers);
  };

  useEffect(() => {
    if (planingFuelAirportSuppliers && open) {
      setFuelOrderForm();
    }
  }, [planingFuelAirportSuppliers, open]);

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    data.fuel_suppliers.forEach((item, index) => {
      if (typeof item.id === 'string') delete data.fuel_suppliers[index].id;
      delete data.fuel_suppliers[index].itemId;
    });

    const values = {
      ...data,
      fuel_suppliers: data.fuel_suppliers.map(({ id, itemId, ...rest }) => ({
        ...rest,
        id: typeof id === 'string' ? null : id,
      }))
    };

    await updateFuel(values).then((res) => {
      if (!res?.error) {
        toast.success('Fuel prices was changed successfully!', {
          id: toastId
        });
        refetch();
        refetchFlightFuelAirport();
        handleFlightData();
      } else {
        toast.error(res?.error?.data?.message, {
          id: toastId
        });
      }
    });
  };

  const handleAddSupplier = () => {
    supplierAppend({
      fuel_email: '',
      fuel_suplier: null,
      id: null,
      pivot: {
        ap_fuel_apid: null,
        ap_fuel_price: null,
        ap_fuel_spid: null,
        ap_fuel_to: moment().format('YYYY-MM-DD'),
      }
    });
  };

  if (isLoading || isFlightFuelLoading || isLoadingSuppliers) {
    return <ContentPageLoader />;
  }

  return (
    <Drawer
      title={`Change Fuel Prices: ${airportName}`}
      open={open}
      onClose={handleClose}
    >
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        sx={{ p: '20px' }}
      >
        <TableContainer component={Paper}>
          {(isLoading || isFlightFuelLoading || isLoadingSuppliers)
            ? <ContentPageLoader />
            : (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography>
                        Suplier
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        Price
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        To Data
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        Action
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {supplierFields?.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        {item?.fuel_suplier ? (
                          item?.fuel_suplier
                        ) : (
                          <FormGroup required>
                            <Controller
                              name={`fuel_suppliers.${index}.id`}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  placeholder="Supplier"
                                  options={supplierOptions}
                                  {...field}
                                  onChange={(e) => {
                                    const id = listSuppliers.find(i => i.id === e.target.value)?.fuel_suplier;

                                    setValue(`fuel_suppliers.${index}.fuel_suplier`, id);
                                    setValue(`fuel_suppliers.${index}.pivot.ap_fuel_apid`, planingFuel?.plfuel_ap_id);
                                    setValue(`fuel_suppliers.${index}.pivot.ap_fuel_spid`, e.target.value);

                                    field.onChange(e);
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        )}
                      </TableCell>
                      <TableCell>
                        <FormGroup required>
                          <Controller
                            name={`fuel_suppliers.${index}.pivot.ap_fuel_price`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Price"
                                {...field}
                              />
                            )}
                          />
                        </FormGroup>
                      </TableCell>
                      {/*<TableCell>*/}
                      {/*  <FormGroup required>*/}
                      {/*    <Controller*/}
                      {/*      name={`fuel_suppliers.${index}.pivot.currency`}*/}
                      {/*      control={control}*/}
                      {/*      render={({ field }) => (*/}
                      {/*        <Select*/}
                      {/*          placeholder="Select Currency"*/}
                      {/*          {...field}*/}
                      {/*          options={currencies.map((itemCurrency) => ({ value: itemCurrency.label, label: itemCurrency.label }))}*/}
                      {/*        />*/}
                      {/*      )}*/}
                      {/*    />*/}
                      {/*  </FormGroup>*/}
                      {/*</TableCell>*/}
                      {/*<TableCell>*/}
                      {/*  <FormGroup required>*/}
                      {/*    <Controller*/}
                      {/*      name={`fuel_suppliers.${index}.pivot.unit_id`}*/}
                      {/*      control={control}*/}
                      {/*      render={({ field }) => (*/}
                      {/*        <Select*/}
                      {/*          placeholder="Select Unit"*/}
                      {/*          {...field}*/}
                      {/*          options={getOptions(units)}*/}
                      {/*        />*/}
                      {/*      )}*/}
                      {/*    />*/}
                      {/*  </FormGroup>*/}
                      {/*</TableCell>*/}
                      <TableCell>
                        <FormGroup required>
                          <Controller
                            name={`fuel_suppliers.${index}.pivot.ap_fuel_to`}
                            control={control}
                            render={({ field: { onChange, ...req } }) => (
                              <DatePicker
                                {...req}
                                variant="standard"
                                placeholderText="YYYY-MM-DD"
                                dateFormat="yyyy-MM-dd"
                                showMonthDropdown
                                showYearDropdown
                                onChange={(e) => {
                                  onChange(e ? moment(e).format('yyyy-MM-DD') : null);
                                }}
                                value={validateDatePickerValue(req.value)}
                              />
                            )}
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>
                        <StyledDelete
                          onClick={() => supplierRemove(index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <Button sx={addSx} title="Add supplier" size="small" onClick={handleAddSupplier} />
              </Table>
            )}
        </TableContainer>

      </Grid>
      <Box sx={{ ...footerSx, ...footerPrices }}>
        <Button type="submit" title="Save" size="small" onClick={handleSubmit(onSubmit)} />
        <Button sx={cancelSx} onClick={onClose} title="Cancel" size="small" />
      </Box>
    </Drawer>
  ); };

export default FuelPrices;

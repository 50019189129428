import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { HeaderName, StyledDelete } from '../../../../components/EditableTable/styles';
import { link } from '../../../CrewForm/Documentation/Card/styles';

export const columns = ({
  handleDownloadFile, handleOpenDeleteModal, handleOpenErrors, handleOpenInfo
}) => ([
  {
    flex: 0.1,
    field: 'id',
    maxWidth: 120,
    headerName: 'Upload ID',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Upload ID</Typography>
      </Box>
    ),
    renderCell: (row) => (row.id),
  },
  {
    flex: 0.2,
    field: 'file_name',
    minWidth: 140,
    headerName: 'File Name',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>File Name</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (row.file_path ? (
      <Typography
        sx={{ color: '#36918c', ...(link) }}
        onClick={() => handleDownloadFile(row.id)}
      >
        {row.file_name}
      </Typography>
    ) : (
      <Typography>
        {row.file_name}
      </Typography>
    )),
  },
  {
    flex: 0.1,
    field: 'status',
    minWidth: 140,
    headerName: 'Status',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Status</Typography>
      </Box>
    ),
    renderCell: ({ row }) => {
      if (row?.failed_at) {
        return (
          <Chip
            label="Failed"
            color="error"
            variant="filled"
            size="small"
            sx={{ fontWeight: 800, cursor: 'pointer' }}
            onClick={() => handleOpenErrors(row.id)}
          />
        );
      }

      if (row?.imported_at) {
        return (
          <Chip
            label="Success"
            color="success"
            variant="filled"
            size="small"
            sx={{ fontWeight: 800, cursor: 'pointer' }}
            onClick={() => handleOpenInfo(row.id)}
          />
        );
      }

      return <Chip label="In Progress" color="info" variant="filled" size="small" sx={{ fontWeight: 800 }} />;
    },
  },
  {
    flex: 0.1,
    field: 'supplier',
    minWidth: 140,
    headerName: 'Supplier',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Supplier</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (row.supplier ?? ''),
  },
  {
    flex: 0.1,
    field: 'uploaded_by',
    minWidth: 140,
    headerName: 'Imported By',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Imported By</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (row.uploaded_by ?? ''),
  },
  {
    flex: 0.1,
    field: 'uploaded_at',
    minWidth: 140,
    headerName: 'Imported At',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Imported At</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (row.uploaded_at ?? ''),
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    renderCell: ({ row }) => (
      <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
    ),
  },
]);

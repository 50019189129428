import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import {
  useUpdateOperatorMutation,
  useGetOperatorMutation,
  useStoreOperatorMutation,
} from '../../../store/session';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, defaultValues } from './schema';
import FormGroup from '../../../form/components/FormGroup';
import Input from '../../../form/components/Input';
import FieldError from '../../../form/components/FieldError';

const OperatorForm = () => {
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const update = id !== 'create';
  const [updateOperator] = useUpdateOperatorMutation();
  const [storeOperator] = useStoreOperatorMutation();
  const [getOperator] = useGetOperatorMutation();
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const handleOperatorData = async () => {
    setLoading(true);
    const { data: result } = await getOperator({ id });

    return result;
  };
  useEffect(() => {
    if (update) {
      handleOperatorData().then((result) => {
        if (result) {
          Object.entries(result).forEach(
            ([name, value]) => setValue(name, value),
          );
        }
        setLoading(false);
      });
    }
  }, [update]);

  if (isLoading) {
    return <ContentPageLoader />;
  }

  const onSubmit = async (data) => {
    if (update) {
      const res = await updateOperator({
        ...data,
      });
      if (!res?.error) {
        toast.success('Operator updated!');
        navigate('/directories/operator');
      } else {
        toast.error('Something went wrong');
      }
    } else {
      const res = await storeOperator({
        ...data,
      });
      if (!res?.error) {
        toast.success('Operator created!');
        navigate('/directories/operator');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 3 }}>
        <CardHeader title="Operator" />
        <CardContent sx={{ mt: 5 }}>
          <Grid container spacing={5}>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Icao Code" required hasError={!!errors.icao_code}>
                <Controller
                  name="icao_code"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Icao Code"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.icao_code} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Company Name" required hasError={!!errors.company_name}>
                <Controller
                  name="company_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Company name"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.company_name} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Address" required hasError={!!errors.address}>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Address"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.company_name} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Phone" required hasError={!!errors.phone}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Phone"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.phone} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Email" required hasError={!!errors.email}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Email"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.email} />
              </FormGroup>
            </Grid>

          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" size="large">
              {update ? 'Update' : 'Save' }
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default OperatorForm;

import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  useGetPortalFilesMutation,
  useGetTrainingPilotDataQuery,
  useUploadPortalFilesMutation
} from '../../../../store/session';
import { CrewTrainingDocumentModel } from '../../../../utils/constants/portalFiles';
import { usePermissions } from '../../../../utils/hooks/usePermissions';

export const usePilotPage = () => {
  const maxWidth1000px = useMediaQuery('(max-width:1275px)');
  const maxWidth733px = useMediaQuery('(max-width:733px)');
  const params = useParams();

  const [getCrewFiles, { isLoading: isLoadingCrewFiles }] = useGetPortalFilesMutation();
  const [uploadCrewFiles, { isLoading: isAttachLoading }] = useUploadPortalFilesMutation();
  const [refetch, setRefetch] = useState(0);

  const useRefetch = () => {
    setRefetch(refetch + 1);
  };

  const pilotId = params.id;

  const hasPermissionsOdsCreate = usePermissions('ods_creator');

  const getFileList = async (id, type, callback) => {
    const res = await getCrewFiles({
      model_id: id,
      model: CrewTrainingDocumentModel.name,
    });

    if (res?.error) {
      toast.error('Unable to get file list.');

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const updateFiles = async (id, file, type, callback) => {
    if (!file.target.files[0]) return;

    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    formData.append('model_id', id);
    formData.append('model', CrewTrainingDocumentModel.name);

    Object.keys(file.target.files)?.forEach((key) => {
      formData.append('upload_files[]', file.target.files[key]);
    });

    const res = await uploadCrewFiles(formData);

    if (res?.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    callback();
    useRefetch();
  };

  return {
    maxWidth1000px,
    maxWidth733px,
    pilotId,
    useGetTrainingPilotDataQuery,
    refetch,
    useRefetch,
    toast,

    hasPermissionsOdsCreate,
    getFileList,
    updateFiles,
    isAttachLoading,
    isLoadingCrewFiles
  };
};

import React, { useEffect } from 'react';
import {
  Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow,
} from '@mui/material';
import Input from '../../../../form/components/Input';
import FormGroup from '../../../../form/components/FormGroup';
import FieldError from '../../../../form/components/FieldError';

const FuelSuppliersForm = (props) => {
  const {
    fuelSupplierFields,
    Controller,
    control,
    watch,
    setValue,
    errors,
    trigger,
    getValues,
    supplierRemove,
  } = props;

  useEffect(() => {
    const subscription = watch(async(value, { name, type }) => {
      const formValues = getValues();
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Grid container spacing={3} rowSpacing={2} sx={{ flexGrow: 1 }}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {fuelSupplierFields.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <FormGroup required hasError={errors?.fuel_suppliers?.[index]?.fuel_suplier}>
                      <Controller
                        name={`fuel_suppliers.${index}.fuel_suplier`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            disabled
                            variant="standard"
                            label="Name"
                            placeholder="Name"
                          />
                        )}
                      />
                      <FieldError error={errors?.fuel_suppliers?.[index]?.fuel_suplier} />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup required hasError={errors?.fuel_suppliers?.[index]?.pivot.ap_fuel_price}>
                      <Controller
                        name={`fuel_suppliers.${index}.pivot.ap_fuel_price`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            label="Price"
                            placeholder="Price"
                          />
                        )}
                      />
                      <FieldError error={errors?.fuel_suppliers?.[index]?.pivot.ap_fuel_price} />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup hasError={errors?.fuel_suppliers?.[index]?.pivot.ap_fuel_to}>
                      <Controller
                        name={`fuel_suppliers.${index}.pivot.ap_fuel_to`}
                        control={control}
                        disabled
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            label="Valid to"
                            placeholder="Valid to"
                          />
                        )}
                      />
                      <FieldError error={errors?.fuel_suppliers?.[index]?.pivot.ap_fuel_to} />
                    </FormGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default FuelSuppliersForm;

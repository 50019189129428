export const updateBtn = {
  backgroundColor: '#36918b',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#4cafa8 !important',
  }
};

export const flightBtnContainer = {
  display: 'flex',
  gap: '10px',
};

export const flightBtnContainerS = {
  display: 'flex',
  flexDirection: 'column'
};

export const flightTabContainer = {
  position: 'relative'
};

export const flightTabIcon = {
  position: 'absolute',
  top: '-14px',
  right: '-16px',
  fontSize: '20px',
  path: {
    fill: '#e63d3d'
  }
};

export const flightTabIconWarning = {
  ...flightTabIcon,
  path: {
    fill: '#efae37'
  }
};

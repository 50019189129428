import {
  Box, MenuItem, TextField
} from '@mui/material';
import React, { useState } from 'react';

const FiltrationSelect = ({
  options,
  handleFilter,
  value,
  defaultValue,
  placeholder,
  getData = () => [],
  ...props
}) => {
  const [active, setActive] = useState(defaultValue);

  return (
    <TextField
      {...props}
      select
      value={active}
      fullWidth
      size="small"
      onBlur={(e) => e.stopPropagation()}
      onChange={(e) => {
        e.stopPropagation();
        handleFilter(e.target.value, value);
        setActive(e.target.value);
        getData(e.target.value);
      }}
      variant="outlined"
      SelectProps={{
        displayEmpty: true,
        MenuProps: {
          sx: {
            '.MuiBackdrop-root': {
              background: 'transparent',
            },
          },
        },
      }}
    >
      <MenuItem value="" style={{ display: 'none' }}>
        <Box component="span" sx={{ color: '#9cabbd' }}>
          {placeholder || 'Select...'}
        </Box>
      </MenuItem>
      {options?.map((item) => (
        <MenuItem value={item.value} key={item.id}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  ); };

export default FiltrationSelect;

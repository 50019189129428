import {
  Box,
  Card,
  Divider,
  Typography
} from '@mui/material';
import React from 'react';
import EditableTable from '../../../../components/EditableTable';
import { useGetMntAircraftTableQuery } from '../../../../store/session';
import {
  titleContainer
} from '../../MaintenanceACFT/styles';
import { columns } from './columns';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const MntAircraftTable = () => (
  <Card sx={{
    mt: 3,
    padding: '1rem',
  }}
  >
    <Box sx={titleContainer}>
      <Typography variant="h3" noWrap>
        Manage Aircraft
      </Typography>
    </Box>
  
    <Divider />
  
    <EditableTable
      useHook={useGetMntAircraftTableQuery}
      columns={columns}
      sortByType="ASC"
      sortByActive="id"
      getRowHeight={() => 'auto'}
      getRowId={(row) => row.id}
    />
  </Card>
);

export default MntAircraftTable;

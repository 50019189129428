import {
  boolean,
  object,
  string
} from 'yup';

export const defaultValues = {
  password: '',
  password_confirmation: '',
  notify_user: false
};

export const schema = object().shape({
  password: string()
    .required('${label} cannot be blank.')
    .label('Password'),
  password_confirmation: string()
    .required('${label} cannot be blank.')
    .label('Password Confirmation'),
  notify_user: boolean()
    .label('Notify User Confirmation'),
});

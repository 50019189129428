export const ContainerCard = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
};

export const ContainerCardXs = {
  flexDirection: 'column',
  flexWrap: 'no-wrap',
};

export const CardBlockX = {
  width: 'calc(19% - 1px)',
};

export const CardBlock = {
  width: 'calc(25% - 15px)',
};

export const CardBlockXs = {
  width: '100%',
};

import moment from 'moment';
import React from 'react';
import Box from '@mui/material/Box';
import LinkButton from '../../../../components/LinkButton/Button';
import {
  POSITIONING, RESERVE, STAND_BY, TRAINING, FPD_COLORS
} from '../constants';

const MOCK_DATE = '01-01-2023';

export const Fdp = ({
  isPdf, fdp, id, max_fdp
}) => (
  ![POSITIONING, TRAINING, STAND_BY, RESERVE].includes(fdp)
    ? (
      <Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '32px',
        backgroundColor: moment(`${MOCK_DATE} ${fdp}`).diff(moment(`${MOCK_DATE} ${max_fdp}`)) > 0 && '#e1e176'
      }}
      >
        <LinkButton path={`/flights/${id}`}><b style={{ color: !isPdf ? '#6c6cf1' : '#4c4e64de', padding: '5px' }}>{fdp}</b></LinkButton>
      </Box>
    )
    : (
      <b style={{
        width: '100%',
        display: 'block',
        color: '#4c4e64de',
        padding: '5px',
        height: '32px',
        backgroundColor: FPD_COLORS[fdp]
      }}
      >
        {fdp}
      </b>
    )
);

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import {
  Button, Card, CardContent, CardHeader, Divider, Grid
} from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import ContentPageLoader from '../../../../components/Loader/ContentPageLoader'
import { HOME_PAGES_LABELS } from '../../../../constans/constants'
import Checkbox from '../../../../form/components/Checkbox'
import FieldError from '../../../../form/components/FieldError'
import FormGroup from '../../../../form/components/FormGroup'
import Input from '../../../../form/components/Input'
import Select from '../../../../form/components/Select'
import {
  useCreateUserGroupMutation,
  useGetUserGroupQuery,
  useUpdateUserGroupMutation,
} from '../../../../store/session'
import { getOptions } from '../../../../utils/getOptions'
import { defaultValues, schema } from './schema'
import { wrapperSx } from './styles'

const UserGroupForm = () => {
  const { id } = useParams();
  const update = id !== 'create';
  const { data: group, isLoading: groupLoading, refetch: refetchUserGroup } = useGetUserGroupQuery({ id }, {
    skip: !update
  });
  const navigate = useNavigate();
  const [createUserGroup, { isLoading: createUserGroupLoading }] = useCreateUserGroupMutation();
  const [updateUserGroup, { isLoading: updateUserGroupLoading }] = useUpdateUserGroupMutation();

  const updating = createUserGroupLoading || updateUserGroupLoading;
  const homePageOptions = getOptions(HOME_PAGES_LABELS);

  const {
    handleSubmit,
    control,
    setValue,
    formState,
  } = useForm({
    mode: 'obChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    if (update && group) {
      if (group) {
        Object.entries(group).forEach(([name, value]) => {
          setValue(name, value ?? false);
        });
      }
    }
  }, [update, groupLoading]);

  const { errors } = formState;

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    if (update) {
      const { name: groupName, ...values } = data;

      const formattedData = {
        ...(groupName !== group.name && { name: groupName }),
        ...values
      };

      await updateUserGroup({ id, data: formattedData }).then((res) => {
        if (!res?.error) {
          toast.success('Access Group updated!', {
            id: toastId
          });
          refetchUserGroup();
          navigate(`/manage-users-groups/${id}`);
        } else {
          toast.error(res?.error?.data?.message, {
            id: toastId
          });
        }
      });
    } else {
      await createUserGroup(data).then((res) => {
        if (!res?.error) {
          toast.success('Access Group created!', {
            id: toastId
          });

          navigate(`/manage-users-groups/${res?.data?.id}`);
        } else {
          toast.error(res?.error?.data?.message, {
            id: toastId
          });
        }
      });
    }
  };

  if (groupLoading) return <ContentPageLoader />;

  return (
    <form>
      <Grid container spacing={6} sx={wrapperSx}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
            {update ? 'Update' : 'Create'}
            {' '}
            User Access Group
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Button type="submit" disabled={updating} variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
            {update ? 'Update' : 'Save' }
          </Button>
        </Grid>

        {/* General Settings */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="General Settings" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item md={12} xs={12} sm={12}>
                  <FormGroup label="Name" required hasError={!!errors.name}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="Group Name"
                          {...field}
                        />
                      )}
                    />

                    <FieldError error={errors.name} />
                  </FormGroup>
                </Grid>

                <Grid item md={4} xs={12} sm={12}>
                  <FormGroup label="Home Page" required hasError={!!errors.home_page}>
                    <Controller
                      name="home_page"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={homePageOptions}
                          placeholder="Home Page"
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Main Deck */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Main Deck" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Main Deck Access" hasError={!!errors.maindeck_access}>
                    <Controller
                      name="maindeck_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.maindeck_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Daily Availability Access" hasError={!!errors.daily_availability_access}>
                    <Controller
                      name="daily_availability_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.daily_availability_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Flight Schedule Access" hasError={!!errors.flights_schedule_access}>
                    <Controller
                      name="flights_schedule_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.flights_schedule_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Aircraft */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Aircraft" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Aircraft" hasError={!!errors.aircrafts_access}>
                    <Controller
                      name="aircrafts_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.aircrafts_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Aircraft Profile" hasError={!!errors.aircraft_profile}>
                    <Controller
                      name="aircraft_profile"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.aircraft_profile} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Quotations */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Quotations" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Quotations" hasError={!!errors.fleet_access}>
                    <Controller
                      name="fleet_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.fleet_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Flights */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Flights" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Flight Access" hasError={!!errors.flights_access}>
                    <Controller
                      name="flights_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.flights_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Flights Statistics Access" hasError={!!errors.flights_statistics_access}>
                    <Controller
                      name="flights_statistics_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.flights_statistics_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Flight Commerce Result" hasError={!!errors.flights_comm_statistics_access}>
                    <Controller
                      name="flights_comm_statistics_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.flights_comm_statistics_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Fuel */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Fuel" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Fuel View/Edit" hasError={!!errors.fuel_access}>
                    <Controller
                      name="fuel_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.fuel_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Crew Roster */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Crew Roster" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Roster Access" hasError={!!errors.roster_access}>
                    <Controller
                      name="roster_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.roster_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Roster SAAB Access" hasError={!!errors.roster_saab_access}>
                    <Controller
                      name="roster_saab_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.roster_saab_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Roster ATR Access" hasError={!!errors.roster_atr_access}>
                    <Controller
                      name="roster_atr_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.roster_atr_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Roster Technicians Access" hasError={!!errors.roster_tech_access}>
                    <Controller
                      name="roster_tech_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.roster_tech_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Roster OPS Access" hasError={!!errors.roster_ops_access}>
                    <Controller
                      name="roster_ops_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.roster_ops_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Roster Access Edit Mode" hasError={!!errors.roster_edit}>
                    <Controller
                      name="roster_edit"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.roster_edit} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Flight Time report Access" hasError={!!errors.flight_report_access}>
                    <Controller
                      name="flight_report_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.flight_report_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Airports */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Airports" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Airports View/Edit" hasError={!!errors.airport_access}>
                    <Controller
                      name="airport_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.airport_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Crews */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Crew" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Crew View/Edit" hasError={!!errors.crew_access}>
                    <Controller
                      name="crew_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.crew_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Suppliers */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Suppliers" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Services Suppliers" hasError={!!errors.services_supplier_access}>
                    <Controller
                      name="services_supplier_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.services_supplier_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Clients */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Clients" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Clients" hasError={!!errors.company_access}>
                    <Controller
                      name="company_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.company_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* ACFT Operator */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="ACFT Operator" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Operator Access" hasError={!!errors.operator_access}>
                    <Controller
                      name="operator_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.operator_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* CAA */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="CAA" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="CAA" hasError={!!errors.caa_access}>
                    <Controller
                      name="caa_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.caa_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* ODS */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="ODS" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="View ODS" hasError={!!errors.ods_access}>
                    <Controller
                      name="ods_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.ods_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Edit ODS" hasError={!!errors.ods_edit}>
                    <Controller
                      name="ods_edit"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.ods_edit} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="ODS Creator" hasError={!!errors.ods_creator}>
                    <Controller
                      name="ods_creator"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.ods_creator} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Print All ODS" hasError={!!errors.print_all_ods}>
                    <Controller
                      name="print_all_ods"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.print_all_ods} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="ODS Action Upload Files" hasError={!!errors.ods_action_upload_file}>
                    <Controller
                      name="ods_action_upload_file"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.ods_action_upload_file} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Documents & Invoices */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Documents & Invoices" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="User documents section" hasError={!!errors.users_documents}>
                    <Controller
                      name="users_documents"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.users_documents} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Flight manager (send user documents)" hasError={!!errors.flight_manager}>
                    <Controller
                      name="flight_manager"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.flight_manager} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Maintenance manager (send user documents)" hasError={!!errors.maintenance_manager}>
                    <Controller
                      name="maintenance_manager"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.maintenance_manager} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Manager Documents (send user documents for managers)" hasError={!!errors.manager_documents}>
                    <Controller
                      name="manager_documents"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.manager_documents} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="User invoice request section" hasError={!!errors.invoice_request_access}>
                    <Controller
                      name="invoice_request_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.invoice_request_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Invoice request approve" hasError={!!errors.invoice_request_approve}>
                    <Controller
                      name="invoice_request_approve"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.invoice_request_approve} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Invoice request approve expensive" hasError={!!errors.invoice_request_approve_expensive}>
                    <Controller
                      name="invoice_request_approve_expensive"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.invoice_request_approve_expensive} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Invoice request payment" hasError={!!errors.invoice_request_payment}>
                    <Controller
                      name="invoice_request_payment"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.invoice_request_payment} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Invoice request logistic access" hasError={!!errors.invoice_request_logist}>
                    <Controller
                      name="invoice_request_logist"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.invoice_request_logist} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Internal Invoice request logistic access" hasError={!!errors.internal_invoice_request_logist}>
                    <Controller
                      name="internal_invoice_request_logist"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.internal_invoice_request_logist} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Invoice request logistic approve" hasError={!!errors.invoice_request_logist_approve}>
                    <Controller
                      name="invoice_request_logist_approve"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.invoice_request_logist_approve} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Invoices */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Invoices" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Invoice su" hasError={!!errors.invoice_su}>
                    <Controller
                      name="invoice_su"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.invoice_su} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Invoice edit" hasError={!!errors.invoice_edit}>
                    <Controller
                      name="invoice_edit"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.invoice_edit} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Bank access" hasError={!!errors.bank_access}>
                    <Controller
                      name="bank_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.bank_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Bank edit" hasError={!!errors.bank_edit}>
                    <Controller
                      name="bank_edit"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.bank_edit} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Invoice access" hasError={!!errors.invoice_access}>
                    <Controller
                      name="invoice_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.invoice_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Maps */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Maps" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Maps Access" hasError={!!errors.map_access}>
                    <Controller
                      name="map_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.map_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Maintenance */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Maintenance" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Maintenance" hasError={!!errors.maintenance_access}>
                    <Controller
                      name="maintenance_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.maintenance_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Maintenance Edit" hasError={!!errors.maintenance_edit}>
                    <Controller
                      name="maintenance_edit"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.maintenance_edit} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Maintenance SAAB Access" hasError={!!errors.maintenance_saab_access}>
                    <Controller
                      name="maintenance_saab_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.maintenance_saab_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Maintenance ATR Access" hasError={!!errors.maintenance_atr_access}>
                    <Controller
                      name="maintenance_atr_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.maintenance_atr_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="ATR Maintenance Change Version" hasError={!!errors.atr_maintenance_change_version}>
                    <Controller
                      name="atr_maintenance_change_version"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.atr_maintenance_change_version} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Maintenance Expenses" hasError={!!errors.maintenance_expenses_access}>
                    <Controller
                      name="maintenance_expenses_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.maintenance_expenses_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Maintenance Tasks" hasError={!!errors.maintenance_tasks_access}>
                    <Controller
                      name="maintenance_tasks_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.maintenance_tasks_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="ATR Maintenance Access" hasError={!!errors.atr_maintenance_access}>
                    <Controller
                      name="atr_maintenance_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.atr_maintenance_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Parts Base Access" hasError={!!errors.parts_base_access}>
                    <Controller
                      name="parts_base_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.parts_base_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Work Order Access" hasError={!!errors.wo_access}>
                    <Controller
                      name="wo_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.wo_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Work Order Creator" hasError={!!errors.wo_creator}>
                    <Controller
                      name="wo_creator"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.wo_creator} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Assign Work Order ODS" hasError={!!errors.work_order_ods_write}>
                    <Controller
                      name="work_order_ods_write"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.work_order_ods_write} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="WO SUPERUSER" hasError={!!errors.wo_superuser}>
                    <Controller
                      name="wo_superuser"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.wo_superuser} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="WO ODS Sign" hasError={!!errors.work_order_ods_write}>
                    <Controller
                      name="work_order_ods_write"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.work_order_ods_write} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Utilization Reports" hasError={!!errors.utilization_report}>
                    <Controller
                      name="utilization_report"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.utilization_report} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Aircraft Body Return To Storage" hasError={!!errors.aircraft_body_return_to_storage}>
                    <Controller
                      name="aircraft_body_return_to_storage"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.aircraft_body_return_to_storage} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Delete Task Card" hasError={!!errors.atr_maintenance_delete_access}>
                    <Controller
                      name="atr_maintenance_delete_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.atr_maintenance_delete_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Delete WO and Job card" hasError={!!errors.wo_and_job_delete_access}>
                    <Controller
                      name="wo_and_job_delete_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.wo_and_job_delete_access} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Deleted Work Orders access" hasError={!!errors.deleted_wo_access}>
                    <Controller
                      name="deleted_wo_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.deleted_wo_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Training Module */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Training Module" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Training Module - Read" hasError={!!errors.training_module_read}>
                    <Controller
                      name="training_module_read"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.training_module_read} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Training Module - Write" hasError={!!errors.training_module_write}>
                    <Controller
                      name="training_module_write"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.training_module_write} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Training Report Read" hasError={!!errors.training_report_read}>
                    <Controller
                      name="training_report_read"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.training_report_read} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Training Report Write" hasError={!!errors.training_report_write}>
                    <Controller
                      name="training_report_write"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.training_report_write} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Users */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Users" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Users Access" hasError={!!errors.users_access}>
                    <Controller
                      name="users_access"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.users_access} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Logistics */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Logistics" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Order Reader (AOG)" hasError={!!errors.logistics_order_read_aog}>
                    <Controller
                      name="logistics_order_read_aog"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.logistics_order_read_aog} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Order Reader" hasError={!!errors.logistics_order_read}>
                    <Controller
                      name="logistics_order_read"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.logistics_order_read} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Order Creator" hasError={!!errors.logistics_order_creator}>
                    <Controller
                      name="logistics_order_creator"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.logistics_order_creator} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Logist" hasError={!!errors.logist}>
                    <Controller
                      name="logist"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.logist} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Order Approve" hasError={!!errors.logistics_order_approve}>
                    <Controller
                      name="logistics_order_approve"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.logistics_order_approve} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Storage Master" hasError={!!errors.logistics_storage_master}>
                    <Controller
                      name="logistics_storage_master"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.logistics_storage_master} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Storage Read" hasError={!!errors.logistics_storage_read}>
                    <Controller
                      name="logistics_storage_read"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.logistics_storage_read} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Storage Write" hasError={!!errors.logistics_storage_write}>
                    <Controller
                      name="logistics_storage_write"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.logistics_storage_write} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Logistics Suppliers Read" hasError={!!errors.logistics_suppliers_read}>
                    <Controller
                      name="logistics_suppliers_read"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.logistics_suppliers_read} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Logistics Suppliers Write" hasError={!!errors.logistics_suppliers_write}>
                    <Controller
                      name="logistics_suppliers_write"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.logistics_suppliers_write} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Logistics Aircraft Reservation" hasError={!!errors.logistics_aircraft_reservation}>
                    <Controller
                      name="logistics_aircraft_reservation"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.logistics_aircraft_reservation} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Internal Logistics */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Internal Logistics" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Internal Order Reader (AOG)" hasError={!!errors.internal_logistics_order_read_aog}>
                    <Controller
                      name="internal_logistics_order_read_aog"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.internal_logistics_order_read_aog} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Internal Order Reader" hasError={!!errors.internal_logistics_order_read}>
                    <Controller
                      name="internal_logistics_order_read"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.internal_logistics_order_read} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Internal Order Creator" hasError={!!errors.internal_logistics_order_creator}>
                    <Controller
                      name="internal_logistics_order_creator"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.internal_logistics_order_creator} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Internal Logist" hasError={!!errors.internal_logist}>
                    <Controller
                      name="internal_logist"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.internal_logist} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Order Approve" hasError={!!errors.internal_logistics_order_approve}>
                    <Controller
                      name="internal_logistics_order_approve"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.internal_logistics_order_approve} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Internal Storage Master" hasError={!!errors.internal_logistics_storage_master}>
                    <Controller
                      name="internal_logistics_storage_master"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.internal_logistics_storage_master} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Overhaul Order Approve" hasError={!!errors.internal_logistics_overhaul_order_approve}>
                    <Controller
                      name="internal_logistics_overhaul_order_approve"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.internal_logistics_overhaul_order_approve} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Finance" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Finance Income Reader" hasError={!!errors.finance_income_reader}>
                    <Controller
                      name="finance_income_reader"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.finance_income_reader} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Finance Income Writer" hasError={!!errors.finance_income_writer}>
                    <Controller
                      name="finance_income_writer"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.finance_income_writer} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Finance Generate Invoice Reader" hasError={!!errors.finance_generate_invoice_read}>
                    <Controller
                      name="finance_generate_invoice_read"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.finance_generate_invoice_read} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Finance Generate Invoice Writer" hasError={!!errors.finance_generate_invoice_write}>
                    <Controller
                      name="finance_generate_invoice_write"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.finance_generate_invoice_write} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Finance Transaction Reader" hasError={!!errors.finance_transactions_read}>
                    <Controller
                      name="finance_transactions_read"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.finance_transactions_read} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Finance Transaction Writer" hasError={!!errors.finance_transactions_write}>
                    <Controller
                      name="finance_transactions_write"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.finance_transactions_write} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Finance Transactions Financiers" hasError={!!errors.finance_transactions_financiers}>
                    <Controller
                      name="finance_transactions_financiers"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.finance_transactions_financiers} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Finance Transactions Tax" hasError={!!errors.finance_transactions_tax}>
                    <Controller
                      name="finance_transactions_tax"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.finance_transactions_tax} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Finance Statistics Read" hasError={!!errors.finance_statistics_read}>
                    <Controller
                      name="finance_statistics_read"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.finance_statistics_read} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Payment Data */}
        <Grid item xl={3} lg={6} md={6} xs={12} sm={12}>
          <Card sx={{ mt: 3, whiteSpace: 'break-spaces' }}>
            <CardHeader variant="h1" title="Payment Data" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Payment Data Admin" hasError={!!errors.payment_data_admin}>
                    <Controller
                      name="payment_data_admin"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.payment_data_admin} />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                  <FormGroup label="Payment Data Export" hasError={!!errors.payment_data_export}>
                    <Controller
                      name="payment_data_export"
                      control={control}
                      render={({ field }) => (<Checkbox {...field} />)}
                    />
                    <FieldError error={errors.payment_data_export} />
                  </FormGroup>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserGroupForm;

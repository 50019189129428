import React from 'react';
import Box from '@mui/material/Box';
import DropDownMenu from '../../DropDownMenu';
import {
  listContainer, listItem, header, closeSx, dropdownMenuSx
} from './styles';

const DropDown = ({
  open,
  onClose,
  onClear,
  anchorEl,
  onUpdateDutyList,
  selectedCellData,
  list,
}) => (
  <DropDownMenu
    sx={dropdownMenuSx}
    open={open}
    onClose={onClose}
    anchorEl={anchorEl}
  >
    <Box>
      <Box sx={header}>
        {selectedCellData.cellDate?.format('DD MMM YYYY')}
      </Box>

      <Box sx={listContainer}>
        {selectedCellData.itemId && (
          <Box sx={listItem} onClick={onClear}>
            CLEAR
          </Box>
        )}

        {list?.map(item => (
          <Box
            sx={{ ...listItem, backgroundColor: item.COLOR }}
            key={item.id}
            onClick={() => onUpdateDutyList(item)}
          >
            {item.NAME}
          </Box>
        ))}

        <Box sx={{ ...listItem, ...closeSx }} onClick={onClose}>
          CLOSE
        </Box>
      </Box>
    </Box>
  </DropDownMenu>
);

export default DropDown;

import React, { useEffect } from 'react';
import { useUpdateSalaryMutation } from '../../../../store/slices/salariesSlice';
import { Controller, useForm } from 'react-hook-form';
import { defaultValues, schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { headerBtn } from '../../../Invoices/style';
import {
  Box, Card, Dialog, DialogContent, Divider, Grid, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import Button from '../../../../components/Button';

const UpdateQuantity = ({
  open, onClose, handleRefresh, salaryData
}) => {
  const [updateSalary] = useUpdateSalaryMutation();

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  useEffect(() => {
    setValue('night_duty_quantity', salaryData?.night_duty_quantity);
    setValue('day_duty_quantity', salaryData?.day_duty_quantity);
  }, [open]);

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const updateData = {
      id: salaryData.id,
      data: {
        night_duty_quantity: data?.night_duty_quantity,
        day_duty_quantity: data?.day_duty_quantity,
      }
    };

    await updateSalary(updateData).then((res) => {
      if (!res?.erro) {
        toast.success('Success!', {
          id: toastId,
        });

        handleRefresh();
        reset();
        onClose();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogContent>
        <Card sx={{ padding: 4 }}>
          <Box sx={headerBtn}>
            <Box>
              <Typography variant="h5" noWrap>
                Update Quantity
              </Typography>
            </Box>
            <CloseIcon
              sx={CloseIconStyle}
              onClick={onClose}
            />
          </Box>

          <Divider />

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormGroup
                  label="Day Duty Quantity"
                  required
                  hasError={!!errors.day_duty_quantity}
                >
                  <Controller
                    name="day_duty_quantity"
                    control={control}
                    render={({ field }) => (
                      <Input type="number" placeholder="Day Duty Quantity" {...field} />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={6}>
                <FormGroup
                  label="Night Duty Quantity"
                  required
                  hasError={!!errors.night_duty_quantity}
                >
                  <Controller
                    name="night_duty_quantity"
                    control={control}
                    render={({ field }) => (
                      <Input type="number" placeholder="Night Duty Quantity" {...field} />
                    )}
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Button type="submit" title="Submit" />
          </form>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateQuantity;

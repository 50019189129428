import React, { useState } from 'react';
import Card from '@mui/material/Card';
import EditableTable from '../../../components/EditableTable';
import {
  CardContent
} from '@mui/material';
import FileSaver from 'file-saver';
import {
  useGetPurposesQuery, useDeclineInvoiceMutation, useDownloadInvoiceMutation, useApproveInvoiceMutation,
  useGetApproveInvoiceRequestsQuery, useGetCurrencyListQuery, useUpdateInvoiceByAccountMutation,
} from '../../../store/session';
import { columnsInvoicesFull } from './columnsInvoicesFull';
import { toast } from 'react-hot-toast';
import { getOptions } from '../../../utils/getOptions';
import {
  cardContent, UserInvoicesContainer
} from '../styles';
import { columnsInvoicesExp } from './columnsInvoicesExp';
import { columnsInvoicesAccount } from './columnsInvoicesAccount';
import { STATUSES } from '../../../constans/invoiceRequest';
import InfoModal from '../../../components/InfoModal';
import DropDown from './DropDown/DropDown';
import { useDropDown } from './DropDown/useDropDown';

const InvoicesTab = ({
  hasPermissionsOCCM,
  hasPermissionsInvoiceExpensive,
  hasPermissionsInvoicePayment,
  type
}) => {
  const [isModalReasonOpen, setIsModalReasonOpen] = useState(false);
  const [reasonText, setReasonText] = useState('');

  const { data: currencyList } = useGetCurrencyListQuery();
  const { data: purpose } = useGetPurposesQuery();

  const [downloadInvoice] = useDownloadInvoiceMutation();

  const [approveInvoice] = useApproveInvoiceMutation();

  const currencyOptions = getOptions(currencyList);
  const purposeOptions = getOptions(purpose);

  const {
    openFile,
    closeMenuFile,
    anchorElFile,
    selectedRow,
    handlePreviewFile,
    handleUpdateFile,
    refetch,
    handleOpenDropDownFile,
  } = useDropDown();

  const currInvoiceColumns = () => {
    if (hasPermissionsOCCM) {
      return columnsInvoicesFull;
    } if (hasPermissionsInvoiceExpensive) {
      return columnsInvoicesExp;
    } if (hasPermissionsInvoicePayment) {
      return columnsInvoicesAccount;
    }

    return columnsInvoicesFull;
  };

  const handleOpenReasonModal = (text) => {
    setIsModalReasonOpen(true);
    setReasonText(text);
  };

  const handleDownload = async({ id, document_patch, file_name }) => {
    const toastId = toast.loading('Downloading...');

    const res = await downloadInvoice(id);

    const fileName = (document_patch || file_name)?.replace( /\\/g, '/' ).replace( /.*\//, '' );

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    FileSaver.saveAs(res.data, fileName);

    toast.success('Success!', {
      id: toastId,
    });
  };

  return (
    <Card>
      <CardContent sx={{ ...cardContent, ...UserInvoicesContainer }}>
        <EditableTable
          handleDownload={handleDownload}
          deleteConfirmation={approveInvoice}
          useHook={useGetApproveInvoiceRequestsQuery}
          useHookUpdate={useUpdateInvoiceByAccountMutation}
          hasPermissionsOCCM={hasPermissionsOCCM}
          onOpenReasonModal={handleOpenReasonModal}
          useHookDelete={useDeclineInvoiceMutation}
          hasPermissionsInvoiceExpensive={hasPermissionsInvoiceExpensive}
          getRowHeight={() => 'auto'}
          getRowClassName={(params) => {
            const status = STATUSES.find(item => item.value === params.row.payment_status);

            return `row-${status?.tag}`;
          }}
          handleOpenDropDown={handleOpenDropDownFile}
          purposeOptions={purposeOptions}
          currencyOptions={currencyOptions}
          columns={currInvoiceColumns()}
          sortByType="ASC"
          sortByActive="upload_date"
          refetchTable={refetch}
          tableParams={{
            payment_type: type,
          }}
        />
      </CardContent>
      <InfoModal open={isModalReasonOpen} onClose={() => { setIsModalReasonOpen(false); }} reasonText={reasonText} />
      <DropDown
        open={openFile}
        onClose={closeMenuFile}
        anchorEl={anchorElFile}
        selectedRow={selectedRow}
        useGetHook={handlePreviewFile}
        usePostHook={handleUpdateFile}
        hasPermissionsInvoicePayment={hasPermissionsInvoicePayment}
        columnType="proof_of_payment_file"
      />
    </Card>
  );
};

export default InvoicesTab;

import HelpIcon from '@mui/icons-material/Help';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import Button from '../../../../../components/Button';
import EditableTable from '../../../../../components/EditableTable';
import { useDeleteFileViewWOMutation, useDownloadFileViewMutation, useGetWorkOrderFilesTableQuery } from '../../../../../store/slices/workOrderSlice';
import { TextWithIcon } from '../../../../Logistics/style';
import { tableContainer } from '../TaskListTable/style';
import { columns } from './columns';

const WorkOrderFiles = ({ work_order_id }) => {
  const [refresh, setRefresh] = useState(false);
  const [openFile] = useDownloadFileViewMutation();

  const handleRefreshTable = () => {
    setRefresh(!refresh);
  };

  const handleOpenFile = async (fileId) => {
    if (fileId) {
      const toastId = toast.loading('Loading...');
      await openFile(fileId).then((res) => {
        if (!res?.error) {
          toast.success('Success', {
            duration: 6000,
            id: toastId,
          });

          const fileUrl = URL.createObjectURL(res.data);
          window.open(fileUrl, '_blank', 'noreferrer');
        } else {
          toast.error('Something went wrong.', {
            id: toastId,
          });
        }
      });
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={TextWithIcon}>
          <HelpIcon />
          <Typography variant="subtitle2">If the status is 'created,' you can click on the file name to download it.</Typography>
        </Box>

        <Box>
          <Button
            title="Refresh"
            onClick={() => handleRefreshTable()}
          />
        </Box>
      </Box>

      <EditableTable
        useHook={useGetWorkOrderFilesTableQuery}
        useHookDelete={useDeleteFileViewWOMutation}
        columns={columns}
        sortByType="DESC"
        sortByActive="id"
        tableParams={{
          work_order_id,
          refresh
        }}
        style={tableContainer}
        getRowHeight={() => 'auto'}
        getRowId={(row) => row.id}
        handleOpenFile={handleOpenFile}
      />
    </>
  );
};

export default WorkOrderFiles;

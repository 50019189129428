import {
  Box, Card, Paper, Typography, useMediaQuery
} from '@mui/material';
import React from 'react';
import {
  CardBlock,
  CardBlockX,
  CardBlockXs,
} from '../styles';

const MainCard = ({
  item: {
    pic, aircraft_registration, id, tsn, eng, csn, fo, from, to, status,
  }
}) => {
  const maxWidth990px = useMediaQuery('(max-width:990px)');
  const minWidth1679px = useMediaQuery('(min-width:1679px)');

  const isError = status === 'MAINTENANCE';

  return (

    <Card
      sx={{
        ...CardBlock,
        ...(maxWidth990px && CardBlockXs),
        ...(minWidth1679px && CardBlockX),
      }}
      key={id}
    >
      <Paper
        variant="elevation"
        elevation={20}
        sx={{
          backgroundColor: '#f5f5f5',
          padding: '1rem',
          paddingTop: '5rem',
          position: 'relative',
        }}
      >
        <Paper
          elevation={8}
          variant="elevation"
          sx={{
            backgroundColor: isError ? '#ed6b75' : '#36c6d3',
            padding: '5px',
            borderRadius: '0px',
            position: 'absolute',
            textTransform: 'uppercase',
            top: '15px',
            left: '0px',
            fontSize: '14px',
            color: 'white',
          }}
        >
          {aircraft_registration}
          {isError && ` ${status} ${from ? `From: ${from}` : ''} ${to ? `To: ${to}` : ''}`}
        </Paper>
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            mb: 4,
          }}
        >
          <Typography sx={{ width: 'calc(33% - 8px)' }}>
            PIC:
            {' '}
            {pic}
          </Typography>

          <Typography sx={{ width: 'calc(33% - 8px)' }}>
            FO:
            {' '}
            {fo}
          </Typography>

          <Typography sx={{ width: 'calc(33% - 8px)' }}>
            ENG:
            {' '}
            {eng}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>
            TSN:
            {' '}
            {tsn}
          </Typography>

          <Typography>
            CSN:
            {' '}
            {csn}
          </Typography>
        </Box>
      </Paper>
    </Card>
  );
};

export default MainCard;

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { GreenButton } from '../../pages/Logistics/style';

const Upload = ({
  title, sx = {}, buttonSx = {}, handleUpload, accept = '*', disabled, multiple = false, icon = null
}) => (
  <Box sx={{ label: { cursor: 'pointer' }, ...sx }}>
    <label htmlFor={disabled ? 'no-file-upload' : 'file-upload'}>

      <Button
        disabled={disabled}
        size="small"
        variant="contained"
        component="label"
        color="secondary"
        startIcon={icon ?? <CloudUploadIcon />}
        sx={{ ...GreenButton, ...buttonSx }}
      >
        {title}
        <input multiple={multiple ? 'multiple' : ''} hidden accept={accept} type="file" id="file-upload" onChange={handleUpload} />
      </Button>
    </label>
  </Box>
);

export default Upload;

import { Autocomplete } from '@mui/material';
import React, { forwardRef } from 'react';
import { CloseIcon } from '../../../components/Icons';
import { useServerSearch } from '../../../hooks/useSearchHook';
import Input from '../Input';

const ServerAutocomplete = forwardRef(
  (
    {
      fields,
      searchQueryFn,
      queryParams,
      customIcon,
      sx,
      label,
      placeholder,
      popupIcon,
      handleSelect,
      withData = false,
      savedOptions = true,
      onInputChangeCallback = () => {},
      ...props
    },
    ref
  ) => {
    const {
      items, isFetching, setSearchTerm, searchTerm
    } = useServerSearch({ searchQueryFn, queryParams, withData });

    return (
      <Autocomplete
        {...fields}
        ref={ref}
        isOptionEqualToValue={(option, value) => option?.value === value?.value || option?.label === value}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option?.label) || ''}
        onInputChange={(e, newValue, type) => {
          setSearchTerm(newValue);
          onInputChangeCallback(e, newValue, type);
        }}
        onChange={(e, data) => {
          handleSelect(data, e);
        }}
        filterOptions={(value) => value}
        clearIcon={<CloseIcon color="action" />}
        loading={isFetching}
        options={(!savedOptions && searchTerm === '') ? [] : items}
        size="small"
        sx={sx}
        renderInput={(params) => (
          <Input
            fullWidth
            label={label}
            sx={
              customIcon
                ? {
                  '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                    right: 38,
                    top: 'calc(50% - 16px)'
                  },
                }
                : {}
            }
            placeholder={placeholder}
            {...params}
          />
        )}
        popupIcon={popupIcon || false}
        {...props}
      />
    );
  }
);

export default ServerAutocomplete;

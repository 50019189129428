import ClearIcon from '@mui/icons-material/Clear';
import {
  Box, IconButton, MenuItem, Typography
} from '@mui/material';
import React from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useOpenNotificationFileMutation } from '../../store/session';
import Button from '../Button';
import { MenuItemSubtitle, MenuItemTitle } from './NotificationDropdown';

export const handleOpen = (url, isPart, message = null, isCreate = null) => {
  if (url.includes('my-profile')) {
    window.open(url, '_blank', 'noreferrer');

    return;
  }

  const isIncludeId = url.includes('?order');
  if (isIncludeId) {
    const [_, orderId] = url.split('=');
    window.open(`/logistics/dashboard/orders?order=${orderId}`, '_blank', 'noreferrer');

    return;
  }

  if (isPart) {
    window.open(url, '_blank', 'noreferrer');

    return;
  }

  if (!isIncludeId && message && message?.includes('Order #')) {
    const [_, orderId] = message.split('#');

    window.open(`/logistics/dashboard/orders?order=${orderId}`, '_blank', 'noreferrer');

    return;
  }

  if (isCreate) {
    window.open(`/logistics/my-orders/orders/create/${isCreate}`, '_blank', 'noreferrer');
  }
};

export const handleOpenFile = async (url = '', openFile = () => {}) => {
  const toastId = toast.loading('Loading...');

  const res = await openFile({ path: url });

  if (res?.error) {
    toast.error('Unable to open file.', {
      id: toastId,
    });

    return;
  }

  toast.success('Success!', {
    id: toastId,
  });

  const fileUrl = URL.createObjectURL(res.data);
  window.open(fileUrl, '_blank', 'noreferrer');
};

export const changeNotificationText = (message = '') => {
  if (message.includes('Order #')) {
    const [first, second] = message.split('! ');

    if (second) return `${second}. ${first}.`;

    return message;
  }

  return message;
};

const NotificationDropdownItem = (props) => {
  const { notification, index, handleRead } = props;
  const isPartArray = Array.isArray(notification?.data);
  const [openFile, { isLoading: isFileOpening }] = useOpenNotificationFileMutation();
  const location = useLocation();

  return (
    <MenuItem
      key={index}
      disableRipple
      disableTouchRipple
    >
      <Box sx={{
        width: '100%', display: 'flex', alignItems: 'center'
      }}
      >
        {isPartArray ? (
          <Box sx={{
            width: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden'
          }}
          >
            {
              notification?.data.map((item) => (
                <Box sx={{
                  mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column'
                }}
                >
                  <MenuItemTitle>{item?.subject}</MenuItemTitle>
                  <MenuItemSubtitle variant="body2">{changeNotificationText(item?.message)}</MenuItemSubtitle>
                  {item?.url?.length && (
                  <Box sx={{ mt: 3 }}>
                    <Button size="small" onClick={() => handleOpen(item?.url, true)}>Open</Button>
                  </Box>
                  )}
                </Box>
              ))
            }
          </Box>
        ) : (
          <Box sx={{
            mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column'
          }}
          >
            <MenuItemTitle>{notification?.data?.subject}</MenuItemTitle>
            <MenuItemSubtitle variant="body2">{changeNotificationText(notification?.data?.message)}</MenuItemSubtitle>
            {notification?.data?.maintenance_invoice_part_id && (
            <Box sx={{ mt: 3 }}>
              <Button size="small" onClick={() => handleOpen(location.pathname, false, '', notification?.data?.maintenance_invoice_part_id)}>Order Again</Button>
            </Box>
            )}

            {notification?.data?.url?.length && (
            <Box sx={{ mt: 3 }}>
              <Button size="small" onClick={() => handleOpen(notification?.data?.url, false, notification?.data?.message)}>Open</Button>
            </Box>
            )}
            {notification?.data?.ods_print_path && (
            <Box sx={{ mt: 3 }}>
              <Button size="small" disabled={isFileOpening} onClick={() => handleOpenFile(notification?.data?.ods_print_path, openFile)}>Open File</Button>
            </Box>
            )}
          </Box>
        )}
        <Typography variant="caption" sx={{ color: 'text.disabled' }}>
          {notification.meta}
        </Typography>
        <IconButton color="primary" disabled={notification.loading} onClick={() => handleRead(notification.id)}>
          <ClearIcon />
        </IconButton>
      </Box>
    </MenuItem>
  );
};

export default NotificationDropdownItem;

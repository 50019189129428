import React, {
  Component,
  useEffect,
} from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useRoutes,
} from 'react-router-dom';
import { authRoutes, mainRoutes } from './router/routes';
import { useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { SettingsConsumer, SettingsProvider } from './context/settingsContext';
import ThemeComponent from './themes/ThemeComponent';
import 'react-datepicker/dist/react-datepicker.css';
import ReactHotToast from './components/Toast/styles';
import { AuthProvider } from './context/AuthContext';

const App = () => {
  const { adminToken, user } = useSelector((state) => state.session);
  const location = useLocation();
  const navigate = useNavigate();
  const setConfig = Component.setConfig ?? undefined;

  const createAccessRouts = (routs) => ({
    ...routs,
    children: routs.children.map((routItem) => {
      if (!routItem.permissions) {
        return routItem;
      }

      if (typeof routItem?.permissions === 'string' && user?.group[routItem?.permissions]) {
        return routItem;
      }

      if (Array.isArray([routItem?.permissions])) {
        const keysOfPermissions = Object.keys(user?.group || {});

        const arrayOfAccesses = keysOfPermissions.filter(i => user?.group[i]);

        return arrayOfAccesses.some(access => routItem?.permissions.includes(access)) && routItem;
      }

      return { ...routItem, element: (<Navigate to="/my-profile" replace />) };
    })
  });

  const mainAccessRoutes = user?.role === 'admin' ? mainRoutes : createAccessRouts(mainRoutes);

  const routing = useRoutes([mainAccessRoutes, authRoutes]);

  useEffect(() => {
    if (!adminToken && !authRoutes.children.map(route => route.path).includes(location.pathname)) {
      navigate('/login', { replace: true });
    }
  }, [adminToken]);

  useEffect(() => {
    if (!adminToken
       && !authRoutes.children.map(route => route.path).includes(location.pathname )
       && location.pathname === '/ods')
    {
      navigate('/login', { state: `${location.pathname}${location.search}` });
    }
  }, []);

  return (
    <AuthProvider>
      <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
        <SettingsConsumer>
          {({ settings }) => (
            <ThemeComponent settings={settings}>
              {routing}
              <ReactHotToast>
                <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
              </ReactHotToast>
            </ThemeComponent>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </AuthProvider>
  );
};

export default App;

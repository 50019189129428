import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import {
  MenuItem,
  Select, useMediaQuery
} from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Upload from '../../../components/Upload';
import { AIRCRAFT_TYPE_LIST } from '../../../constans/crew';
import Checkbox from '../../../form/components/Checkbox/Checkbox';
import FormGroup from '../../../form/components/FormGroup';
// import Select from '../../../form/components/Select';
import { useCrewUploadSingMutation } from '../../../store/session';
import { MenuProps } from '../../Maintenance/UtilizationReport/style';
import {
  ContainerSmall,
  NoPhotography,
  containerSx,
  containerXs,
  crewSignTitleSx,
  formControlLabel,
  selectSx,
  uploadedSign
} from './styles';

const Settings = () => {
  const {
    control, getValues, setValue, trigger
  } = useFormContext();
  const params = useParams();
  const [selectACType, setSelectACType] = useState(['NOTYPE']);
  const [uploadSing] = useCrewUploadSingMutation();

  const img = getValues('sign_path');

  const uploadFile = async (e) => {
    const toastId = toast.loading('Loading...');

    const formData = new FormData();

    formData.append('id', params.id);
    formData.append('crew_sign', e.target.files[0]);

    const res = await uploadSing(formData);

    e.target.value = null;

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    setValue('sign_path', res.data?.crew_photo);
    trigger('sign_path');

    toast.success('Sign was uploaded!', {
      id: toastId,
    });
  };

  const handleSelectType = async (typeId, callback) => {
    const lastItem = typeId?.[typeId.length - 1];
    const isAll = typeId.includes('NOTYPE');
    let currentValue;

    if (isAll) {
      const newValue = typeId.filter(item => item !== 'NOTYPE');

      currentValue = newValue;
    }

    if (lastItem === 'NOTYPE') {
      currentValue = ['NOTYPE'];
    } 

    if (!isAll && lastItem !== 'NOTYPE') {
      currentValue = typeof typeId === 'string' ? typeId.split(',') : typeId;
    }

    callback(currentValue);
  };

  const maxWidth590px = useMediaQuery('(max-width:590px)');
  const maxWidth892px = useMediaQuery('(max-width:892px)');

  useEffect(() => {
    setSelectACType(getValues('crew_acft_type'));
  }, []);

  return (
    <Box sx={{ ...containerSx, ...(maxWidth892px && containerXs), ...(maxWidth590px && ContainerSmall) }}>
      <Grid item xs={12} sm={6} sx={selectSx}>
        <FormGroup label="Aircraft type">
          <Controller
            name="crew_acft_type"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                multiple
                value={selectACType}
                placeholder="Aircraft type"
                defaultValue="NOTYPE"
                onChange={(e) => {
                  field.onChange(e);
                  handleSelectType(e.target.value, (value) => {
                    setSelectACType(value);
                    setValue('crew_acft_type', value);
                  });
                }}
                MenuProps={MenuProps}
                sx={{
                  width: '100%',
                  height: '2.5rem'
                }}
              >
                {(AIRCRAFT_TYPE_LIST || []).map(({ label, value }) => (
                  <MenuItem
                    key={value}
                    value={value}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormGroup>
          <Controller
            name="crew_line_check"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                sx={formControlLabel}
                control={<Checkbox />}
                label="LC"
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormGroup>
          <Controller
            name="crew_wo_can_perform"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                sx={formControlLabel}
                control={<Checkbox />}
                label="WO Can Perform"
              />
            )}
          />

        </FormGroup>
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormGroup>
          <Controller
            name="crew_wo_can_close"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                sx={formControlLabel}
                control={<Checkbox />}
                label="WO Can Close"
              />
            )}
          />

        </FormGroup>
      </Grid>

      <Box sx={uploadedSign}>
        <Typography sx={crewSignTitleSx}>Uploaded Crew Sign:</Typography>
        {img ? (
          <img alt="fe-img" src={img} />
        ) : (
          <NoPhotographyIcon sx={NoPhotography} />
        )}

      </Box>
      <Upload handleUpload={uploadFile} title="Upload file" />
    </Box>
  ); };

export default Settings;

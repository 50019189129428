import React from 'react';
import Box from '@mui/material/Box';
import logoWhite from '../../assets/img/fleet-logo-font.png';

const PdfLogo = ({ title }) => (
  <Box sx={{
    background: '#00254F',
    p: '10px',
    mb: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media print': {
      display: 'none',
    }
  }}
  >
    {title && (
      <Box sx={{
        fontSize: '20px', fontWeight: 'bold', color: '#fff'
      }}
      >
        {title}
      </Box>
    )}
    <img
      src={logoWhite}
      alt="logoWhite"
      style={{
        maxWidth: '150px',
        width: '125px',
        height: '30px',
        fill: 'none',
      }}
    />
  </Box>
);

export default PdfLogo;

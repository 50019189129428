import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  IconButton, Toolbar, useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logoWhite from '../../assets/img/fleet-logo-font.png';
import { navList } from '../../constans/constants';
import Navigation from '../Navigation/Navigation';
import NotificationDropdown from '../NotificationDropdown/NotificationDropdown';
import UserDropdown from '../UserDropdown/UserDropdown';
import {
  BarBox,
  StyledLink,
  StyledMobileNav
} from './styles';

const AppBarContent = () => {
  const navigate = useNavigate();
  const mobile = useMediaQuery('(max-width:768px)');
  const tablet = useMediaQuery('only screen and (min-width : 769px) and (max-width : 1025px)');
  const [mobileNav, setMobileNav] = useState(false);

  const logo = (sx = {}) => (
    <StyledLink
      onClick={() => {
        navigate('/', { replace: true });
      }}
      sx={sx}
    >
      <img
        src={logoWhite}
        alt="logoWhite"
        style={{
          maxWidth: '150px',
          width: '100%',
          height: 'auto',
          fill: 'none',
        }}
      />
    </StyledLink>
  );

  useEffect(() => {
    if (!mobile || !tablet) setMobileNav(false);
  }, [mobile, tablet]);

  return (
    <>
      <BarBox>
        {(mobile || tablet) ? (
          <IconButton
            size="large"
            color="#fff"
            aria-label="menu"
            onClick={() => setMobileNav(prev => !prev)}
          >
            <MenuIcon sx={{ color: '#fff' }} />
          </IconButton>
        ) : (logo())}
      
        <Box className="layout-horizontal-nav" sx={{ width: '100%', }}>
          {(mobile || tablet) ? (logo({ margin: '0', justifyContent: 'center' })) : (
            <Toolbar
              className="horizontal-nav-content-container"
              sx={{
                mx: 'auto',
                minHeight: (theme) => `${theme.mixins.toolbar.minHeight}px !important`,
              }}
            >
              <Navigation navList={navList} />
            </Toolbar>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <NotificationDropdown />
          <UserDropdown />
        </Box>
      </BarBox>
      <StyledMobileNav
        open={mobileNav}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(0, 52, 103)',
            color: '#fff',
          }
        }}
        onClose={() => setMobileNav(false)}
        anchor="left"
        sx={{
          ...(tablet && {
            '& > .MuiModal-backdrop': {
              backgroundColor: 'transparent',
            }
          })
        }}
      >
        <Navigation navList={navList} isMobile isTablet={tablet} onCloseDrawer={() => setMobileNav(false)} />
      </StyledMobileNav>
    </>
    
  );
};

export default AppBarContent;

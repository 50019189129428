export const formContainer = {
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',
};

export const headerSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
};

export const backIcon = {
  cursor: 'pointer',
};

export const cardContainer = {
  p: '30px',
  mt: '30px',
  display: 'flex',
  gap: '15px',
};

export const saveBtn = {
  color: '#fff',
  width: '100%',
};

export const cancelBtn = {
  backgroundColor: '#e9edef',
  color: '#34495e',
  width: '100%',
  '&:hover': {
    backgroundColor: '#c3cfd3'
  }
};

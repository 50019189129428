import React, { useEffect } from 'react';
import Drawer from '../../../../../../components/Drawer';
import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Input from '../../../../../../form/components/Input';
import Button from '@mui/material/Button';

import {
  footerSx
} from './styles';
import { Controller, useForm } from 'react-hook-form';
import {
  defaultValues,
  schema
} from './schema';
import FormGroup from '../../../../../../form/components/FormGroup';
import { numberInputSx } from '../../../../../Quotations/CreateQuotation/CharterDrawer/styles';
import toast from 'react-hot-toast';
import { useSendFuelOrderMutation } from '../../../../../../store/session';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FieldError from '../../../../../../form/components/FieldError';

const FuelOrder = ({
  open, onClose, planingFuel
}) => {
  const [sendFuelOrder, { isLoading: loading }] = useSendFuelOrderMutation();

  const {
    control,
    formState: { errors },
    setValue,
    reset,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const mailData = {
      ...data,
      to: data.to.split(' ').join('').split(','),
      cc: data.cc.split(' ').join('').split(','),
      message: data.description
    };

    await sendFuelOrder(mailData).then((res) => {
      if (!res?.error) {
        toast.success('Fuel order was sent successfully!', {
          id: toastId
        });
        handleClose();
      } else {
        toast.error(res?.error?.data?.message, {
          id: toastId
        });
      }
    });
  };

  const setMail = () => {
    const mailData = planingFuel?.mailData;
    if (mailData) {
      // To
      setValue('to', mailData?.to ?? '');

      // Subject
      setValue('subject', mailData?.subject ?? '');

      // Message
      setValue('description', `DEAR OPS,

PLEASE MAKE AN ORDER FOR APPROXIMATELY 1000 KG OF FUEL (OR ACCORDING TO CAPTAINS REQUEST) AT:

${mailData?.description?.airportCode ?? ''} ${mailData?.description?.fuelPrice ?? ''}

TYPE OF A/C: ${mailData?.description?.aircraftRegType ?? ''}
REG: ${mailData?.description?.aircraftRegName ?? ''}
OPERATOR: ${mailData?.description?.operator ?? ''}
CAPTAIN: ${mailData?.description?.pic ?? ''}

SCHEDULE (ALL TIME UTC):
${mailData?.description?.hop ?? ''}
${!!mailData?.description?.international ? 'PLS TAKE INTO ACCOUNT THAT WE WILL OPERATE COMMERCIAL INTERNATIONAL FLIGHT AND WE HAVE AN AOC. NO VAT SHOULD BE APPLIED.\n' : ''}
BEST REGARDS,
FLEET AIR OPS
TEL: ${mailData?.description?.operatorPhone ?? ''}
FAX: +36 1 5772378
SITA: BUDFA7X AFTN: KAIRFRFX
EMAIL: OPS@FLEETAIR.EU
`);
    }
  };

  useEffect(() => {
    setMail();
  }, [open]);

  return (
    <Drawer
      title="FUEL MAILER"
      open={open}
      onClose={handleClose}
    >
      <form>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          sx={{ flexGrow: 1, p: '30px' }}
        >
          <Grid item xs={12}>
            <FormGroup label="To" required hasError={!!errors.to}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="To"
                    {...field}
                  />
                )}
                control={control}
                name="to"
              />

              <FieldError error={errors.to} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="CC">
              <Controller
                render={({ field } ) => (
                  <Input
                    sx={numberInputSx}
                    placeholder="CC"
                    {...field}
                  />
                )}
                control={control}
                name="cc"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="Subject">
              <Controller
                render={({ field } ) => (
                  <Input
                    sx={numberInputSx}
                    placeholder="Subject"
                    {...field}
                  />
                )}
                control={control}
                name="subject"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="Message" required hasError={!!errors.description}>
              <Controller
                render={({ field } ) => (
                  <Input
                    multiline
                    rows={26}
                    sx={numberInputSx}
                    placeholder="Message"
                    {...field}
                  />
                )}
                control={control}
                name="description"
              />

              <FieldError error={errors.description} />
            </FormGroup>
          </Grid>

          <Box sx={footerSx}>
            <Button variant="contained" disabled={loading} onClick={handleSubmit(onSubmit)}>Send</Button>
            <Button
              sx={{ background: '#e1e5ec' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </form>
    </Drawer>
  );
};

export default FuelOrder;

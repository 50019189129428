import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { HISTORY_GO_BACK } from '../../../constans/constants';
import {
  useGetAirportListQuery, useGetOdsByIdQuery, useGetOdsStatusesQuery, useGetOdsTocQuery, useUpdateOdsMutation
} from '../../../store/session';
import { getOptions } from '../../../utils/getOptions';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { defaultValues } from './schema';

export const useOdsForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data, isLoading: odsDataLoading } = useGetOdsByIdQuery(params.id, { refetchOnMountOrArgChange: true });
  const [update, { isLoading: updateIsLoading }] = useUpdateOdsMutation();
  const { data: airportList } = useGetAirportListQuery();
  const { data: odsStatuses } = useGetOdsStatusesQuery();
  const { data: odsToc } = useGetOdsTocQuery();

  const odsStatusesOptions = getOptions(odsStatuses);
  const odsTocOptions = getOptions(odsToc);

  const isTypeF = data?.type === 1;

  const hasPermissionsOdsEdit = usePermissions('ods_edit');

  const {
    control,
    watch,
    setValue,
    getValues,
    setError,
    trigger,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const handleBack = () => {
    navigate(HISTORY_GO_BACK);
  };

  const print = () => {
    window.open(`/directories/ods-pdf/${params.id}`, '_blank', 'noreferrer');
  };

  const onSubmit = async (values) => {
    const loading = toast.loading('ODS update in process...');

    const formattedData = Object.keys(dirtyFields).reduce((res, key) => ({ ...res, [key]: values[key] }), {});

    if (+data.fuel_uplift_kg !== +values.fuel_uplift_kg) {
      formattedData.fuel_uplift_kg = values.fuel_uplift_kg;
    }

    if (+data.fuel_departure_kg !== +values.fuel_departure_kg) {
      formattedData.fuel_departure_kg = values.fuel_departure_kg;
    }

    const res = await update({ data: formattedData, id: values.id });

    if (!res?.error) {
      toast.success('ODS successfully updated!', {
        id: loading
      });
      navigate(`/directories/ods/update/${res.data.id}`);
    } else {
      Object.entries(res?.error?.data?.errors).forEach(([key, value]) => {
        setError(key, value);
      });
      
      toast.error(res?.error?.data?.message, {
        id: loading
      });
    }
  };

  useEffect(() => {
    if (data) {
      Object.entries(data).forEach(
        ([name, value]) => setValue(name, value),
      );
      trigger('aircraft_registration');
    }
  }, [data]);
  
  useEffect(() => {
    const { fuel_density, fuel_uplift, previous_fuel_arrival_kg } = getValues();
    const uplift = fuel_density * fuel_uplift;

    setValue('fuel_uplift_kg', uplift);
    setValue('fuel_departure_kg', uplift + +(previous_fuel_arrival_kg || 0), { shouldDirty: true });
  }, [watch('fuel_density'), watch('fuel_uplift'), odsDataLoading]); // we should wait previous ODS fuel value

  return {
    control,
    errors,
    isTypeF,
    data,
    odsStatusesOptions,
    odsTocOptions,
    airportList,
    trigger,
    setValue,
    hasPermissionsOdsEdit,
    print,
    getValues,
    handleSubmit,
    onSubmit,
    handleBack,
    updateIsLoading
  };
};

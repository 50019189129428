import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSendInternalApproveMutation } from '../../../../../../store/slices/internalLogisticsSlice';
import { useSendApproveMutation } from '../../../../../../store/slices/logisticsSlice';
import {
  defaultValues,
  schema
} from './schema';

export const usePendingApproveForm = ({ order, onClose, dashboardType }) => {
  const [sendCurrent, isLoadingCurrent] = useSendApproveMutation();
  const [sendInternal, isLoadingInternal] = useSendInternalApproveMutation();
  const send = dashboardType === 'orders' ? sendCurrent : sendInternal;
  const isLoading = dashboardType === 'orders' ? isLoadingCurrent : isLoadingInternal;
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const { error } = await send({ id: order.id, data });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    onClose();
    toast.success('Successfully!', {
      id: toastId
    });
  };

  return {
    formMethods,
    defaultValues,
    handleSubmit: formMethods.handleSubmit,
    onSubmit,
    isLoading
  };
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ForPrint } from './ForPrint';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import { useGetPrintODSDataQuery } from '../../../store/session';
import html2PDF from 'jspdf-html2canvas';

const OdsPdf = () => {
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  const { data } = useGetPrintODSDataQuery(params.id, { refetchOnMountOrArgChange: true });

  const print = () => {
    setIsLoading(true);
    const input = document.getElementById('forPdf');

    html2PDF(input, {
      jsPDF: {
        format: 'a4',
      },
      imageType: 'image/jpeg',
      output: 'download.pdf'
    });
  };

  useEffect(() => {
    if (data) {
      // print();
    }
  }, [data]);

  if (isLoading) <ContentPageLoader />;

  return (
    <ForPrint data={data || {}} />
  );
};

export default OdsPdf;

export const TrainingProgramModel = {
  name: 'TrainingProgram',
  types: {
    review: {
      id: 1,
      name: 'Program Review'
    },
    feedback: {
      id: 2,
      name: 'Program Feedback'
    }
  }
};
export const CrewTrainingDocumentModel = {
  name: 'CrewTrainingDocument',
  types: {
    training: {
      id: 3,
      name: 'Document Training'
    },
    feedback: {
      id: 4,
      name: 'Document Feedback'
    }
  }
};

export const OdsActionDocumentModel = {
  name: 'OdsAction',
  types: {
    action: {
      id: 1,
      name: 'Ods Action Files'
    }
  }
};

export const TransactionModel = {
  name: 'Transaction',
  types: {
    action: {
      id: 5,
      name: 'Transaction Invoices'
    },
    salary: {
      id: 6,
      name: 'Transaction Salary'
    }
  }
};

export const setFilesName = (fileList = []) => {
  const list = fileList.map(item => {
    const path = item?.path.split('/');

    return {
      ...item,
      name: path[path.length - 1] || ''
    };
  });

  return list;
};

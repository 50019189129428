import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import moment from 'moment/moment';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Drawer from '../../../../../components/Drawer';
import DatePicker from '../../../../../form/components/DatePicker/DatePicker';
import FieldError from '../../../../../form/components/FieldError';
import FormGroup from '../../../../../form/components/FormGroup';
import Input from '../../../../../form/components/Input';
import {
  useGetFlightContractMutation,
  useGetFlightInvoiceMutation,
  useSendContractAndInvoiceMutation
} from '../../../../../store/session';
import { setDateValue, validateDatePickerValue } from '../../../../../utils/setDateValue';
import { defaultValues } from './shema';
import {
  cancelSx, drawerSx, footerSx, formControlLabel, headerSx, numberInputSx,
} from './styles';

const ContractInvoiceDrawer = ({
  open,
  onClose,
  flightData,
  flightId
}) => {
  const [getContract, { isLoading: contractLoading }] = useGetFlightContractMutation();
  const [getInvoice, { isLoading: invoiceLoading }] = useGetFlightInvoiceMutation();
  const [sendMail, { isLoading: sending }] = useSendContractAndInvoiceMutation();

  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const maxWidth900px = useMediaQuery('(max-width:900px)');
  const maxWidth755px = useMediaQuery('(max-width:755px)');

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const mailData = {
      ...data,
      to: data.to.split(' ').join('').split(','),
      cc: data.cc.split(' ').join('').split(','),
      message: data.description
    };

    delete mailData.isDue_date;
    if (!data.isDue_date) {
      delete mailData.due_date;
    }

    await sendMail({ data: mailData, id: flightId }).then((res) => {
      if (!res?.error) {
        toast.success('Mail was sent successfully!', {
          id: toastId
        });
        onClose();
      } else {
        toast.error(res?.error?.data?.message, {
          id: toastId
        });
      }
    });
  };

  const handlePreviewContract = async () => {
    const toastId = toast.loading('Loading...');
    const isDue = getValues('isDue_date');
    const reqData = {
      prepayment_before: getValues('prepayment_before'),
      prepayment_in: getValues('prepayment_in'),
      ...(isDue && { due_date: getValues('due_date') })
    };
    await getContract({ id: flightId, data: reqData }).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handlePreviewInvoice = async () => {
    const toastId = toast.loading('Loading...');
    const isDue = getValues('isDue_date');
    const reqData = {
      otp_bank: getValues('otp_bank'),
      raiffeisen_bank: getValues('raiffeisen_bank'),
      prepayment_before: getValues('prepayment_before'),
      prepayment_in: getValues('prepayment_in'),
      ...(isDue && { due_date: getValues('due_date') })
    };
    await getInvoice({ id: flightId, data: reqData }).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const getSubject = () => {
    const send_contract = watch('send_contract');
    const send_invoice = watch('send_invoice');
    const default_subject = flightData?.contractAndInvoice?.subject;
    const prefill = `${(send_contract && send_invoice)
      ? 'CONTRACT & INVOICE'
      : (send_contract
        ? 'CONTRACT'
        : (send_invoice
          ? 'INVOICE'
          : '')
      )
    }`;

    return `${prefill} ${default_subject}`;
  };

  useEffect(() => {
    setValue('subject', getSubject());
  }, [watch('send_contract'), watch('send_invoice')]);

  useEffect(() => {
    if (flightData) {
      const mailData = flightData?.contractAndInvoice;
      setValue('to', mailData?.to);
      setValue('cc', mailData?.cc);
      setValue('subject', getSubject());
      setValue('description', mailData?.description);
      setValue('due_date', moment().format('YYYY-MM-DD HH:mm'));
    }
    if (!open) reset();
  }, [open]);

  return (
    <Drawer
      title="Contract & Invoice Generator"
      open={open}
      onClose={onClose}
      sx={{ ...(maxWidth900px && drawerSx ) }}
    >
      <form>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          sx={{ flexGrow: 1, p: '30px' }}
        >
          <Grid item xs={12}>
            <Box sx={headerSx}>
              {watch('send_contract') && (
                <Button size="small" variant="contained" onClick={handlePreviewContract} disabled={contractLoading}>Contract Preview</Button>
              )}
              {watch('send_invoice') && (
                <Button size="small" variant="contained" onClick={handlePreviewInvoice} disabled={invoiceLoading}>Invoice Preview</Button>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: '5px', ...(maxWidth755px && { flexDirection: 'column' }) }}>
              <Controller
                name="send_contract"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    checked={field.value}
                    value={field.value}
                    sx={formControlLabel}
                    control={<Checkbox />}
                    label="Send Contract"
                  />
                )}
              />

              <Controller
                name="send_invoice"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    checked={field.value}
                    value={field.value}
                    sx={formControlLabel}
                    control={<Checkbox />}
                    label="Send Invoice"
                  />
                )}
              />

              <Controller
                name="raiffeisen_bank"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    checked={field.value}
                    value={field.value}
                    sx={formControlLabel}
                    control={<Checkbox />}
                    label="Raiffeisen Bank Zrt"
                    onChange={(e, data) => {
                      field.onChange(e);
                      if (data) {
                        setValue('otp_bank', !data);
                      }
                    }}
                  />
                )}
              />

              <Controller
                name="otp_bank"
                control={control}
                label="OTP Bank Plc."
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    checked={field.value}
                    value={field.value}
                    sx={formControlLabel}
                    control={<Checkbox />}
                    label="OTP Bank Plc."
                    onChange={(e, data) => {
                      field.onChange(e);
                      if (data) {
                        setValue('raiffeisen_bank', !data);
                      }
                    }}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="To" required hasError={!!errors.doc_name}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="To"
                    {...field}
                  />
                )}
                control={control}
                name="to"
              />

              <FieldError error={errors.doc_name} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="CC" required hasError={!!errors.expired_treshold}>
              <Controller
                render={({ field } ) => (
                  <Input
                    sx={numberInputSx}
                    placeholder="CC"
                    {...field}
                  />
                )}
                control={control}
                name="cc"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="Subject" required hasError={!!errors.expired_treshold}>
              <Controller
                render={({ field } ) => (
                  <Input
                    sx={numberInputSx}
                    placeholder="Subject"
                    {...field}
                  />
                )}
                control={control}
                name="subject"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <Controller
                render={({ field } ) => (
                  <Input
                    multiline
                    rows={10}
                    sx={numberInputSx}
                    placeholder="Subject"
                    {...field}
                  />
                )}
                control={control}
                name="description"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex' }}>
            <FormGroup>
              <Controller
                name="isDue_date"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    checked={field.value}
                    value={field.value}
                    sx={formControlLabel}
                    control={<Checkbox />}
                    label="Due Date"
                    onChange={(e, data) => {
                      field.onChange(e);
                      if (data) {
                        setValue('isDue_date', data);
                      }
                    }}
                  />
                )}
              />
            </FormGroup>

            <FormGroup>
              <Controller
                name="due_date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd HH:mm"
                    timeFormat="HH:mm"
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    disabled={!watch('isDue_date')}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'due_date');
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Box sx={footerSx}>
              <Button variant="contained" type="submit" disabled={sending} onClick={handleSubmit(onSubmit)}>
                Send
              </Button>
              <Button
                sx={cancelSx}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>

        </Grid>
      </form>
    </Drawer>
  ); };

export default ContractInvoiceDrawer;

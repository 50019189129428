
import {
  object, string, array, mixed
} from 'yup';

export const defaultValues = {
  users: [],
  file: '',
  description: '',
};

export const schema = object().shape({
  users: array()
    .nullable()
    .min(1, 'Users cannot be blank')
    .required('${label} cannot be blank')
    .label('users'),
  file: mixed().test('isEmpty', 'File cannot be blank', (value) => !!value ).nullable(),
  description: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Description'),
});

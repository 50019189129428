import { Card, CardContent, CardHeader } from '@mui/material';
import React, { useState } from 'react';
import InvoiceDrawer from './InvoiceDrawer/InvoiceDrawer';
import Table from './Table';

const ServicesForm = ({ handleFlightData }) => {
  const [drawerState, setDrawerState] = useState({
    isOpen: false,
    service: null,
    callback: () => {}
  });

  const handleOpenDrawer = (isOpen, service, callback = () => {}) => {
    setDrawerState(prev => ({
      ...prev,
      isOpen,
      service,
      callback
    }));
  };

  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader title="Services" />
      <CardContent sx={{ mt: 5 }}>
        <Table handleOpenDrawer={handleOpenDrawer} />
      </CardContent>
      <InvoiceDrawer 
        {...drawerState}
        onClose={() => handleOpenDrawer(false, null, () => {})}
        handleFlightData={handleFlightData}
      />
    </Card>
  );
};

export default ServicesForm;

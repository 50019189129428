import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import { useUpdateAircraftTypeMutation, useGetAircraftTypeMutation, useStoreAircraftTypeMutation } from '../../../store/session';
import { CustomForm } from '../components/form/styles';

const defaultValues = {
  aircraft_type_name: '',
  aircraft_display_name: '',
};
const AircraftTypeForm = () => {
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const update = id !== 'create';
  const [updateAircraftType] = useUpdateAircraftTypeMutation();
  const [storeAircraftType] = useStoreAircraftTypeMutation();
  const [getAircraftType] = useGetAircraftTypeMutation();
  const {
    handleSubmit,
    control,
    setValue,
  } = useForm({ defaultValues });

  const handleAircraftTypeData = async () => {
    setLoading(true);
    const { data: result } = await getAircraftType({ id });

    return result;
  };
  useEffect(() => {
    if (update) {
      handleAircraftTypeData().then((result) => {
        if (result) {
          Object.entries(result).forEach(
            ([name, value]) => setValue(name, value),
          );
        }
        setLoading(false);
      });
    }
  }, [update]);

  if (isLoading) {
    return <ContentPageLoader />;
  }
  const onSubmit = async (data) => {
    if (update) {
      await updateAircraftType({
        id: data.id,
        aircraft_type_name: data.aircraft_type_name,
        aircraft_display_name: data.aircraft_display_name,
      });
    } else {
      await storeAircraftType({
        aircraft_type_name: data.aircraft_type_name,
        aircraft_display_name: data.aircraft_display_name,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 3 }}>
        <CardHeader title="Aircraft Type" />
        <CardContent sx={{ mt: 5 }}>

          <Grid item xs={3}>
            <CustomForm sx={{ width: 1 / 2 }}>
              <Controller
                name="aircraft_type_name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    required={Boolean(true)}
                    label="Aircraft Type Name"
                    onChange={onChange}
                  />
                )}
              />
            </CustomForm>
          </Grid>

          <Grid item xs={3}>
            <CustomForm sx={{ width: 1 / 2 }}>
              <Controller
                name="aircraft_display_name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    required={Boolean(true)}
                    label="Aircraft Notifications Display Name"
                    onChange={onChange}
                  />
                )}
              />
            </CustomForm>
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="contained" size="large" onClick={() => toast.success(update ? 'Successfully updated!' : 'Successfully created!')}>
              {update ? 'Update' : 'Save' }
            </Button>
          </Grid>

        </CardContent>
      </Card>
    </form>
  );
};

export default AircraftTypeForm;

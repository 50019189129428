import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FiltrationSelect from '../../components/EditableTable/FiltrationSelect';
import {
  TYPE_CHECK_BOX, TYPE_INPUT, TYPE_NUMBER,
} from '../../components/EditableTable/constants';
import FiltrationInput from '../../components/EditableTable/FiltrationInput';
import TextEdit from '../../components/EditableTable/TextEdit';
import {
  HeaderName, StyledDelete,
} from '../../components/EditableTable/styles';
import { OPTIONS_ACTIVE } from '../../constans/crew';

export const columns = ({
  handleFilter,
  filters,
  handleOpen,
  handleDelete,
  refetch,
}) => ([
  {
    flex: 0.2,
    field: 'doc_name',
    minWidth: 180,
    headerName: 'ROSTER ACTIVE',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.doc_name || ''}
          placeholder="Name"
          value="doc_name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'doc_name', row)}
        text={row.doc_name}
        labelInput="Name"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'expired_treshold',
    minWidth: 200,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Expired threshold days</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.expired_treshold || ''}
          placeholder="Name"
          value="expired_treshold"
          type="number"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'expired_treshold', row)}
        text={row.expired_treshold}
        labelInput="Expired threshold days"
        type={TYPE_INPUT}
        typeField={TYPE_NUMBER}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'post_captain',
    minWidth: 180,
    headerName: 'Assigned to Captain',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}

      >
        <Typography style={HeaderName}>Assigned to Captain</Typography>
        <FiltrationSelect
          options={OPTIONS_ACTIVE}
          defaultValue={filters.post_captain || ''}
          handleFilter={handleFilter}
          value="post_captain"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'post_captain', row)}
        text={row.post_captain}
        labelInput="Enter ROSTER ACTIVE"
        type={TYPE_CHECK_BOX}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'post_fo',
    minWidth: 180,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography style={HeaderName}>Assigned to FO</Typography>
        <FiltrationSelect
          options={OPTIONS_ACTIVE}
          defaultValue={filters.post_fo || ''}
          handleFilter={handleFilter}
          value="post_fo"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'post_fo', row)}
        text={row.post_fo}
        labelInput="Assigned to FO"
        type={TYPE_CHECK_BOX}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'post_engineer',
    minWidth: 120,
    headerName: 'Shortname',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography style={HeaderName}>Assigned to Engineer</Typography>
        <FiltrationSelect
          options={OPTIONS_ACTIVE}
          defaultValue={filters.post_engineer || ''}
          handleFilter={handleFilter}
          value="post_engineer"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'post_engineer', row)}
        text={row.post_engineer}
        labelInput="Assigned to FO"
        type={TYPE_CHECK_BOX}
      />
    ),
  },
  {
    width: 80,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <StyledDelete onClick={async () => {
        await handleDelete(row.id);
        refetch();
      }}
      />
    ),
  },

]);

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import FiltrationInput from '../../components/EditableTable/FiltrationInput';
import FiltrationSelect from '../../components/EditableTable/FiltrationSelect';
import TextEdit from '../../components/EditableTable/TextEdit';
import {
  TYPE_SELECT_DATE
} from '../../components/EditableTable/constants';
import {
  HeaderName, StyledDelete,
} from '../../components/EditableTable/styles';

export const columns = ({
  handleFilter,
  filters,
  handleOpen,
  fuelOptions,
  handleOpenDeleteModal,
  handleOpenUpdatePriceForm
}) => ([

  {
    flex: 0.1,
    field: 'id',
    minWidth: 80,
    headerName: 'ID',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>ID</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.price_id || ''}
          value="price_id"
          placeholder="ID"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.id}
      </Typography>
    ),
  },
  {
    flex: 0.4,
    field: 'airport_name',
    minWidth: 200,
    headerName: 'Airport',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Airport</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.airport_name || ''}
          value="airport_name"
          placeholder="Airport"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{
          textTransform: 'capitalize',
          textOverflow: 'unset',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word',
        }}
      >
        {row.airport_name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'suplier_name',
    minWidth: 120,
    headerName: 'Supplier',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Supplier</Typography>
        <FiltrationSelect
          options={fuelOptions}
          defaultValue={filters.price_suplier_id || ''}
          handleFilter={handleFilter}
          value="price_suplier_id"
          typeOptions="number"
          placeholder="Supplier"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.suplier_name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'ap_fuel_price',
    minWidth: 100,
    headerName: 'Price (EUR/KG)',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Price (EUR/KG)</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.price || ''}
          value="price"
          placeholder="Price"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{
          textTransform: 'capitalize', color: '#337ab7', cursor: 'pointer', textDecoration: 'underline'
        }}
        onClick={() => handleOpenUpdatePriceForm(row.id)}
      >
        {row.ap_fuel_price}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'ap_fuel_to',
    minWidth: 100,
    headerName: 'Valid to',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Valid to</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.valid_to || ''}
          value="valid_to"
          placeholder="Valid to"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'ap_fuel_to', row)}
        text={row.ap_fuel_to}
        labelInput="Enter Name"
        type={TYPE_SELECT_DATE}
      />
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
    ),
  },

]);

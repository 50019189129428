export const BtnActive = {
  backgroundColor: ' #004688'
};

export const DefaultBtn = {
  color: '#fff',
  textTransform: 'capitalize',
  padding: '5px 13px'
};

export const cardContainerSx = {
  mt: 3,
  p: 5,
  pb: 3,
  fontFamily: '"Open Sans",sans-serif',
};

export const ButtonContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  mb: '30px',
};

export const ButtonContainerX = {
  flexDirection: 'column',
  width: 'fit-content',
  gap: 10
};

export const BtnGroupX = {
  flexWrap: 'wrap',
  boxShadow: 'unset',
};

export const AllMonths = {
  whiteSpace: 'nowrap',
  minWidth: '125px',
};

export const BtnGroup = {
  width: 'fit-content',
};

export const DefaultBtnX = {
  border: '1px solid #2e6da4'
};

export const BtnGroupXs = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '10px',
  boxShadow: 'unset',
};

export const ButtonContainerXs = {
  width: '100%',
};

export const DefaultBtnXs = {
  borderColor: 'unset !important',
  borderRadius: '8px !important',
  width: '100%',
};

export const accordionSx = {
  borderBottomLeftRadius: '0',
  borderBottomRightRadius: '0',
  '&.Mui-expanded': {
    m: 0,
  },
};

export const accoriondSummarySx = {

  '&.MuiAccordionSummary-root': {
    p: '6px 12px',
    backgroundColor: '#F2F2FF',
    height: '35px',
    minHeight: '35px',
    '&:hover, &.Mui-expanded': {
      backgroundColor: '#286090',
      color: '#fff',

      '& .MuiAccordionSummary-content': {
        color: '#fff'
      }
    }
  },
  '& .MuiAccordionSummary-content': {
    m: 0,
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    color: '#000',
    textTransform: 'uppercase',
    '&:hover': {
      color: '#fff',
    }
  }
};

export const accordionDetails = {
  '&.MuiAccordionDetails-root': {
    p: 0
  }
};
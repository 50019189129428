import React from 'react';
import LinkButton from '../../../../components/LinkButton';

export const columns = [
  {
    field: 'airport_name',
    headerName: 'Airport Name',
    flex: 0.2
  },
  {
    field: 'flight_id',
    minWidth: 80,
    headerName: 'FLIGHT',
    flex: 0.2,
    renderCell: ({ rowNode }) => {
      if (rowNode.type === 'leaf') {
        return (
          <LinkButton target="_blank" path={`/flights/${rowNode.groupingKey}`} style={{ textDecoration: 'underline !important', }}>
            Flight#
            {' '}
            {rowNode.groupingKey}
          </LinkButton>
        );
      }

      return null;
    }
  },
  {
    field: 'company_name',
    headerName: 'Company Name',
    minWidth: 150,
    flex: 0.2
  },
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 120,
    flex: 0.2
  },
];
import { object, string } from 'yup';

export const defaultValues = {
  day_duty_quantity: '',
  night_duty_quantity: '',
};

export const schema = object().shape({
  day_duty_quantity: string()
    .nullable()
    .label('Day Duty Quantity'),
  night_duty_quantity: string()
    .nullable()
    .label('Night Duty Quantity'),
});

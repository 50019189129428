import {
  Toolbar,
  Box,
  AppBar,
  styled,
} from '@mui/material';
import { hexToRGBA } from '../../utils/hex-to-rgba';

export const LayoutWrapper = styled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  overflow: 'clip',
}));

export const LayoutAppBar = styled(AppBar)(({ theme }) => ({
  alignItems: 'center',
  color: 'text.primary',
  justifyContent: 'center',
  zIndex: 13,
  transition: 'border-bottom 0.2s ease-in-out, backdrop-filter .25s ease-in-out, box-shadow .25s ease-in-out',
  backdropFilter: 'blur(8px)',
  backgroundColor: hexToRGBA(theme.palette.primary.main, 0.9),
  '@media print': {
    display: 'none',
  }
}));

export const HeaderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  borderBottom: `1px solid ${theme.palette.customColors.textPrimary}`,
}));

export const HeaderToolbar = styled(Toolbar)(({ theme }) => ({
  mx: 'auto',
  '@media (min-width:1440px)': { maxWidth: '100%' },
  minHeight: `${theme.mixins.toolbar.minHeight - 1}px !important`,
}));

export const ContentWrapper = styled('main')(({ theme }) => ({
  mx: 'auto',
  // '@media (min-width:1200px)': { maxWidth: '100%' },
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  '@media print': {
    // '& body': {
    //   fontSize: '5px',
    // },
    marginLeft: '-32px',
    width: '106%'
  }
}));

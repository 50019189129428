import { Card, Grid, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import {
  useDeleteCaaMutation,
  useGetCaaTableListQuery,
} from '../../../store/session';
import { columns } from './columns';

const CaaTable = ({ title }) => {
  const navigate = useNavigate();
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  return (
    <Grid>
      <Grid>
        <Card>
          <Box
            sx={maxWidth800px ? {
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              mb: 4
            } : {
              pb: 3,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="h3"
              noWrap
              sx={{ textTransform: 'capitalize' }}
            >
              {title}
            </Typography>

            <Button title="Add Caa" onClick={() => { navigate('/directories/caa/create'); }} />
          </Box>
          <EditableTable
            useHook={useGetCaaTableListQuery}
            useHookDelete={useDeleteCaaMutation}
            columns={columns}
            sortByType="ASC"
            sortByActive="caa_country"
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.caa_id}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default CaaTable;

import {
  Box, Card, Typography, useMediaQuery
} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import EditableTable from '../../components/EditableTable';
import {
  useDeleteFuelUpliftMutation,
  useGetAircraftListQuery,
  useGetFuelSuppliersListQuery,
  useGetFuelUpliftTableListQuery, useUpdateFuelUpliftMutation
} from '../../store/session';
import { columns } from './columns';
import { TitleContainer, TitleContainerS } from './styles';
import { useFuelUpliftLimitations } from './useFuelUpliftLimitations';

const FuelUplift = () => {
  const { data: FuelSuppliersList } = useGetFuelSuppliersListQuery();
  const { data: AircraftList } = useGetAircraftListQuery();

  const fuelOptions = FuelSuppliersList?.map((item) => ({
    value: item.id,
    label: item.fuel_suplier
  }));
  const aircraftOptions = AircraftList?.map((item) => ({
    value: item.id,
    label: item.aircraft_registration
  }));

  const {
    handleDoc,
  } = useFuelUpliftLimitations();

  const maxWidth540px = useMediaQuery('(max-width:540px)');

  useEffect(() => {
    if (fuelOptions) {
      fuelOptions?.push({ value: '', label: '-' });
    }
  }, [fuelOptions]);

  useEffect(() => {
    if (aircraftOptions) {
      aircraftOptions?.push({ value: '', label: '-' });
    }
  }, [aircraftOptions]);

  return (
    <Card sx={{ padding: '1rem' }}>
      <Box
        sx={{
          ...TitleContainer, ...(maxWidth540px && TitleContainerS), pb: 3 
        }}
      >
        <Typography variant="h3">Manage Fuel Uplifts</Typography>

        <Box display="flex" gap="10px" sx={{ justifyContent: 'end' }}>
          <Button onClick={handleDoc} variant="contained">Export</Button>
        </Box>
      </Box>

      <EditableTable
        useHook={useGetFuelUpliftTableListQuery}
        useHookUpdate={useUpdateFuelUpliftMutation}
        useHookDelete={useDeleteFuelUpliftMutation}
        columns={columns}
        sortByType="DESC"
        sortByActive="fuel_date"
        fuelOptions={fuelOptions}
        aircraftOptions={aircraftOptions}
      />
    </Card>
  );
};

export default FuelUplift;

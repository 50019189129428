import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import moment from 'moment';
import TableHead from './TableHead';
import TableBody from './TableBody';
import DropDown from './DropDown';
import Loader from '../Loader/ContentPageLoader';
import { DAY_IN_WEEK, WEEK } from './constants';
import { useMenuToggle } from '../../utils/hooks/useMenuToggle';
import {
  loaderSx,
  tableContainer,
  tableSx,
} from './styles';
import { usePermissions } from '../../utils/hooks/usePermissions';

const CrewTable = ({
  data,
  date,
  list,
  handleUpdateDutyList,
  dataCellSx,
  handleDelete,
  isLoading,
}) => {
  const hasPermissions = usePermissions('roster_edit');

  const [selectedCellData, setSelectedCellData] = useState({});
  const daysInMonth = moment(date, 'MMM-DD-YYYY').daysInMonth();
  const firstDayInMonth = moment(date, 'MMM-DD-YYYY').startOf('month').format('ddd');
  const tableAmount = Math.ceil((daysInMonth + WEEK[firstDayInMonth]) / DAY_IN_WEEK);
  const {
    open, openMenu, closeMenu, anchorEl,
  } = useMenuToggle();

  const handleOpen = (e, values) => {
    if (!hasPermissions) return;

    setSelectedCellData(values);
    openMenu(e);
  };

  const onUpdateDutyList = (values) => {
    const { cellDate, crew_id } = selectedCellData;
    handleUpdateDutyList({ duty_date: moment(cellDate).format('YYYY-MM-DD'), duty_type: values.id, crew_id });
    closeMenu();
  };

  const onClear = () => {
    closeMenu();
    handleDelete(selectedCellData.itemId);
  };

  return (
    <TableContainer sx={tableContainer}>
      {isLoading && <Loader containerSx={loaderSx} />}
      {Array.from({ length: tableAmount }).map((_, index) => (
        <Table
          sx={tableSx}
          aria-label="sticky table"
        >
          <TableHead data={data} />

          <TableBody
            dataCellSx={dataCellSx}
            openMenu={handleOpen}
            list={list}
            data={data}
            date={date}
            index={index}
            firstDayInMonth={firstDayInMonth}
            daysInMonth={daysInMonth}
          />
        </Table>
      ))}

      <DropDown
        selectedCellData={selectedCellData}
        list={list}
        open={open}
        onClose={closeMenu}
        onClear={onClear}
        anchorEl={anchorEl}
        onUpdateDutyList={onUpdateDutyList}
      />
    </TableContainer>
  );
};

export default CrewTable;

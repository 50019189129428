import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button';
import { HeaderName } from '../../../../components/EditableTable/styles';

export const columns = ({
  handleFileDrawer,
}) => ([
  {
    flex: 0.3,
    field: 'value',
    minWidth: 160,
    headerName: 'Amount',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Amount</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize', fontWeight: '600' }}
      >
        {`${row?.value} ${row?.currency_id}`}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'parts',
    minWidth: 160,
    headerName: 'Relates to',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Relates to</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography noWrap>
        {row?.parts?.map((part) => (
          <Typography>
            <Link
              style={{ 'textDecoration': 'none' }}
              to={`/logistics/orders-storage/${part?.id}`}
              target="_blank"
            >
              {`Part #${part?.id}`}
            </Link>
          </Typography>
        ))}
        {row?.invoices?.map((invoice, index) => (
          <>
            <Typography>
              <Link
                style={{ 'textDecoration': 'none' }}
                to={`/finance/invoice_management?invoice=${invoice?.id}`}
                target="_blank"
              >
                {`Invoice #${invoice?.id}`}
              </Link>
            </Typography>
            {invoice?.orders?.map((order) => {
              if (row?.invoices?.some((inv, invIndex) => inv?.orders?.some(ord => ((ord?.id === order?.id) && (invIndex < index))))) return null;

              return (
                <Typography>
                  <Link
                    style={{ 'textDecoration': 'none' }}
                    to={`/logistics/my-orders/orders/order-info/${order?.id}`}
                    target="_blank"
                  >
                    {`Order #${order?.id}`}
                  </Link>
                </Typography>
              );
            })}
          </>
        ))}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'date',
    minWidth: 180,
    headerName: 'Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Date</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize', fontWeight: '600' }}
      >
        {row?.date ?? 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.4,
    field: 'description',
    minWidth: 180,
    headerName: 'Description',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Description</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all',
        }}
      >
        {row?.description ? (row?.description === 'null' ? 'N/A' : row?.description) : 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.4,
    field: 'reason',
    minWidth: 200,
    headerName: 'Reason',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Reason</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all',
        }}
      >
        {row?.reason ? (row?.reason === 'null' ? 'N/A' : row?.reason) : 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.15,
    field: 'files',
    minWidth: 120,
    headerName: 'Files',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Files</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Button
          size="small"
          onClick={() => handleFileDrawer(true, row?.id, null, 'Credit Note', row?.id)}
        >
          Files
        </Button>
      </Box>
    ),
  }
]);

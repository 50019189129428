import { object, string } from 'yup';

export const defaultValues = {
  month_rate: '',
  day_rate: '',
  night_rate: '',
};

export const schema = object().shape({
  month_rate: string()
    .nullable()
    .label('Rate'),
  day_rate: string()
    .nullable()
    .label('Rate'),
  night_rate: string()
    .nullable()
    .label('Rate'),
});

import {
  Box, Card, Divider,
  Menu, MenuItem,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import TableFilterContainer from '../../../components/EditableTable/TableFilterContainer';
import LinkButton from '../../../components/LinkButton';
import { useDeleteReportMutation, useGetReportsTableQuery } from '../../../store/slices/TrainingModule/trainingReportSlice';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { GreenButton } from '../../Logistics/style';
import { PilotContainer, tableContainer, TitleContainer } from '../TrainingProgram/styles';
import { columns } from './columns';
import { useTrainingReport } from './useTrainingReport';

export const TrainingReport = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const {
    getTableRowStyle,
    filterConfig,
    signFilter,
    crewFilter,
    handleDownloadReport,
    handleOpenReport,
    handleNotify
  } = useTrainingReport();

  const trainingReportsWrite = usePermissions('training_report_write');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    handleClose();
    navigate(path);
  };

  return (
    <>
      <LinkButton path="/training-module">
        <Button title="Back"></Button>
      </LinkButton>
      <Card sx={{ padding: '1rem', mt: 3 }}>
        <Box sx={TitleContainer}>
          <Box sx={PilotContainer}>
            {/* <Paper sx={paperPilot} elevation={4}> */}
            <Typography variant="h3" sx={{ display: 'block' }}>
              Training Reports
            </Typography>
            {/* </Paper> */}
          </Box>

          <div>
            <Button
              sx={{ mr: '15px', ...GreenButton }}
              title="Create New"
              onClick={handleClick}
              disabled={!trainingReportsWrite}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleMenuItemClick('/training-module/training-reports/first_report/create')}>CLASSROOM TRAINING REPORT</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('/training-module/training-reports/second_report/create')}>OPC FORM</MenuItem>
            </Menu>
          </div>
        </Box>

        <Divider sx={{ mt: 5 }} />

        <TableFilterContainer filterConfig={filterConfig} />

        <Box sx={tableContainer}>
          <EditableTable
            getRowId={(row) => row.id}
            getRowClassName={({ row }) => `row-${getTableRowStyle(row)}`}
            useHook={useGetReportsTableQuery}
            getRowHeight={() => 'auto'}
            sortByType="DESC"
            sortByActive="id"
            columns={columns}
            handleDownloadReport={handleDownloadReport}
            useHookDelete={useDeleteReportMutation}
            handleOpenReport={handleOpenReport}
            handleNotify={handleNotify}
            tableParams={{
              sign: signFilter,
              crew: crewFilter
            }}
          />
        </Box>
      </Card>
    </>
  );
};

export default TrainingReport;

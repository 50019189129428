import { boolean, object, string } from 'yup';

export const defaultValues = {
  bank_name: '',
  notes: '',
  transactions: false
};

export const schema = object().shape({
  bank_name: string()
    .required('${label} cannot be blank.')
    .label('Name'),
  notes: string().nullable(),
  transactions: boolean().nullable(),
});

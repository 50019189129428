export const renderBox = {
  display: 'flex',
  alignItems: 'center',
};

export const NoPhotography = {
  width: '100px',
  height: '100px',
};

export const uploadedSign = {
  display: 'flex',
  alignItems: 'center',
  height: '120px',
  mb: '10px',
  img: {
    maxWidth: '150px',
    maxHeight: '200px',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
};

export const containerSx = {
  padding: '30px ',
  paddingTop: '0px',
};

export const checkBoxLabel = {
  ml: '-10px'
};

export const formControlLabel = {
  ml: '0'
};

export const selectSx = {
  mb: '30px'
};

export const crewSignTitleSx = {
  mr: '10px'
};

export const containerXs = {
  padding: '30px ',
  paddingTop: '30px',
};

export const ContainerSmall = {
  p: '20px 0',
  paddingTop: '20px',
};
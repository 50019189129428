import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import DrawerBase from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import {
  closeIcon, drawerSx, headerSx, subTitleSx, titleSx
} from './styles';

const Drawer = ({
  open, onClose, title, children, sx = {}, subTitle, sxTitle = {}, ...props
}) => {
  useEffect(() => {
    const bodyElement = document.querySelector('body');

    if (open) {
      bodyElement.classList.add('disable-scroll');
    } else {
      bodyElement.classList.remove('disable-scroll');
    }

    return () => {
      bodyElement.classList.remove('disable-scroll');
    };
  }, [open]);

  return (
    <DrawerBase
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ ...drawerSx, ...sx }}
      {...props}
    >
      <Box sx={headerSx}>
        <Typography sx={{ ...titleSx, ...sxTitle }}>
          {title}
        </Typography>

        <CloseIcon onClick={onClose} sx={closeIcon} />
      </Box>

      {subTitle && (
        <Box sx={headerSx}>
          <Typography sx={subTitleSx}>
            {subTitle}
          </Typography>
        </Box>
      )}

      {children}
    </DrawerBase>
  );
};

export default Drawer;

import React from 'react';
import Checkbox from '../../form/components/Checkbox/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckboxesTags = ({
  options, label, placeholder, ...req
}) => (
  <Autocomplete
    multiple
    id="checkboxes-tags-demo"
    options={options}
    disableCloseOnSelect
    getOptionLabel={(option) => option.label}
    renderOption={(props, option, { selected }) => (
      <li {...props}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {option.label}
      </li>
    )}
    style={{ width: '100%' }}
    renderInput={(params) => (
      <TextField {...params} label={label} placeholder={placeholder} />
    )}
    {...req}
  />
);

export default CheckboxesTags;

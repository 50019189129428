import React, { useEffect } from 'react';
import Drawer from '../../../../../components/Drawer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormGroup from '../../../../../form/components/FormGroup';
import FieldError from '../../../../../form/components/FieldError';
import Input from '../../../../../form/components/Input';
import { Controller, useForm } from 'react-hook-form';
import {
  cancelSx, drawerSx, footerSx, numberInputSx,
} from './styles';
import { defaultValues } from './shema';
import { useSendCharterConfirmationMutation } from '../../../../../store/session';
import toast from 'react-hot-toast';
import { useMediaQuery } from '@mui/material';

const CharterDrawer = ({ open, onClose, flightData }) => {
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const maxWidth900px = useMediaQuery('(max-width:900px)');

  const [sendCharterConfirmation, { isLoading: sending }] = useSendCharterConfirmationMutation();

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const mailData = {
      ...data,
      to: data.to.split(' ').join('').split(','),
      cc: data.cc.split(' ').join('').split(','),
      message: data.description
    };

    await sendCharterConfirmation(mailData).then((res) => {
      if (!res?.error) {
        toast.success('Charter confirmation was sent successfully!', {
          id: toastId
        });
        onClose();
      } else {
        toast.error(res?.error?.data?.message, {
          id: toastId
        });
      }
    });
  };

  useEffect(() => {
    if (flightData?.charterConfirmation) {
      const mailData = flightData?.charterConfirmation;
      setValue('to', mailData?.to);
      setValue('cc', mailData?.cc);
      setValue('subject', mailData?.subject);
      setValue('description', mailData?.description);
    }
  }, [open]);

  return (
    <Drawer
      title="Mailer"
      open={open}
      onClose={onClose}
      sx={{ ...(maxWidth900px && drawerSx ) }}
    >
      <form>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          sx={{ flexGrow: 1, p: '30px' }}
        >
          <Grid item xs={12}>
            <FormGroup label="To" required hasError={!!errors.doc_name}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="To"
                    {...field}
                  />
                )}
                control={control}
                name="to"
              />

              <FieldError error={errors.doc_name} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="CC" required hasError={!!errors.expired_treshold}>
              <Controller
                render={({ field } ) => (
                  <Input
                    sx={numberInputSx}
                    placeholder="CC"
                    {...field}
                  />
                )}
                control={control}
                name="cc"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="Subject" required hasError={!!errors.expired_treshold}>
              <Controller
                render={({ field } ) => (
                  <Input
                    sx={numberInputSx}
                    placeholder="Subject"
                    {...field}
                  />
                )}
                control={control}
                name="subject"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <Controller
                render={({ field } ) => (
                  <Input
                    multiline
                    rows={35}
                    sx={numberInputSx}
                    placeholder="Subject"
                    {...field}
                  />
                )}
                control={control}
                name="description"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Box sx={footerSx}>
            <Button variant="contained" type="submit" disabled={sending} onClick={handleSubmit(onSubmit)}>
              Send
            </Button>
            <Button
              sx={cancelSx}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </form>
    </Drawer>
  ); };

export default CharterDrawer;

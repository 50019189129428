import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '../../../../../form/components/Checkbox';
import TypeCard from '../../../../Ods/OdsForm/TypeCard';

const Status = () => {
  const { control } = useFormContext();

  const maxWidth768px = useMediaQuery('(max-width:768px)');

  return (
    <TypeCard title="STATUS" variant="yellow">
      <Grid xs={12} item container sx={{ flexGrow: 1 }}>
        <Grid xs={maxWidth768px ? 12 : 4}>
          <Controller
            render={({ field } ) => (
              <Checkbox
                label="ACTIVE"
                {...field}
              />
            )}
            control={control}
            name="is_active"
          />
        </Grid>
        <Grid xs={maxWidth768px ? 12 : 4}>
          <Controller
            render={({ field } ) => (
              <Checkbox
                label="CHECKED"
                {...field}
              />
            )}
            control={control}
            name="is_checked"
          />
        </Grid>
      </Grid>
    </TypeCard>
  );
};

export default Status;

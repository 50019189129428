import { object, string } from 'yup';

export const defaultValues = {
  block_name: '',
};

export const schema = object().shape({
  block_name: string()
    .required('${label} cannot be blank.')
    .label('Name'),
});

import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Create from './Create';
import Table from './Table';

const PartOffPartOnDrawer = (props) => {
  const {
    open, onClose, id, ods, actionName, optionsCrewList = [], currentActionParts = {} 
  } = props;
  const [typeBody, setTypeBody] = useState({
    type: 'table',
    currentParts: null
  });

  const handleSetTypeBody = (type, currentParts = null) => {
    setTypeBody(prev => ({
      ...prev, 
      type,
      currentParts
    }));
  };

  useEffect(() => {
    if (open) {
      setTypeBody({
        type: 'table',
        currentParts: null
      });
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xll"
    >
      {(typeBody.type === 'table') && (
        <Table 
          open={open} 
          id={id} 
          handleSetTypeBody={handleSetTypeBody} 
          optionsCrewList={optionsCrewList}  
          actionName={actionName}
          currentActionParts={currentActionParts}
        />
      )}
      {(typeBody.type === 'create') && (
      <Create 
        id={id} 
        handleSetTypeBody={handleSetTypeBody} 
        optionsCrewList={optionsCrewList} 
        currentParts={typeBody.currentParts}
        ods={ods} 
        onClose={() => handleSetTypeBody('table')}
      />
      )}
    </Dialog>
  );
};

export default PartOffPartOnDrawer;
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Card, CardContent,
  Chip,
  CircularProgress,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../../../../components/Button';
import LinkButton from '../../../../../components/LinkButton/Button';
import { useGetInvoiceDetailsMutation } from '../../../../../store/slices/invoiceManagementSlice';
import { headerBtn } from '../../../../Invoices/style';
import {
  CloseIconStyle, DetailsGridStyle, OrderDetailsStyle, RightMenuStyle
} from '../../../../Logistics/OrdersDashboard/styles';
import {
  GreenButton, RedButton, TextWithIcon, TextWrap
} from '../../../../Logistics/style';
import {
  INVOICE_STATUSES, ISSUE, PAYED,
  PENDING_APPROVE_AM,
  PENDING_APPROVE_LOGIST,
  PENDING_APPROVE_OCCM,
  PENDING_PAYMENT, PREPAYMENT
} from '../../constants';
import { CardAction } from '../../styles';
import IssueForm from './forms/IssueForm';
import PayedForm from './forms/PayedForm';
import PendingApproveForm from './forms/PendingApproveForm';
import PendingPaymentForm from './forms/PendingPaymentForm';
import PrepaymentForm from './forms/PrepaymentForm';
import VendorInfo from './forms/VendorInfo';
import TransactionModal from './TransactionModal';

const InvoiceForm = ({
  onClose, locationInvoiceId = null, props
}) => {
  const [transactionModalState, setTransactionModalState] = useState({
    isOpen: false,
    config: null
  });

  const [getInvoice, { data: invoice, isLoading: invoiceLoading, refetch }] = useGetInvoiceDetailsMutation();

  const formattedInvoice = props.formatInvoice(invoice);
  const handleInvoiceTitle = useCallback((with_id = true) => props.getInvoiceTitle(invoice, with_id), [invoice]);

  const handleOpenTransactionModal = (isOpen, config = null) => {
    setTransactionModalState(prev => ({
      ...prev,
      config,
      isOpen,
    }));
  };

  const handleGetInvoiceData = async (id) => {
    await getInvoice({ id });
  };

  const renderSidebarMainInfo = useCallback((invoiceData, transactionBtn = false) => ([
    { label: 'Status', value: INVOICE_STATUSES.find(i => i.value === invoiceData?.payment_status?.toString())?.icon },
    { label: 'Purpose', value: invoiceData?.payment_purpose },
    { label: 'Sender', value: invoiceData?.sender },
    { label: 'Due date', value: invoiceData?.due_payment_date },
    { label: 'Payment date', value: invoiceData?.payment_date },
    { label: 'Created', value: invoiceData?.created_at },
    {
      label: 'Transaction',
      value: invoiceData?.transaction_id ? (
        <LinkButton
          style={{
            textDecoration: 'underline', display: 'flex', alignItems: 'center', gap: 4
          }}
          path={`/finance/transactions?search_transaction=${invoiceData?.transaction_id}`}
          target="_blank"
        >
          №
          {invoiceData?.transaction_id}
          {' '}
          Link
          <OpenInNewIcon sx={{ fontSize: '1rem' }} />
        </LinkButton>
      ) : null
    },
    (transactionBtn && {
      label: !invoiceData?.transaction_id ? 'Transaction Link' : '',
      value: <Button title="+ Add" sx={{ fontSize: '.6rem', padding: '4px 6px', backgroundColor: invoiceData?.transaction_id ? (GreenButton) : (RedButton) }} onClick={() => handleOpenTransactionModal(true, { id: invoiceData?.id, transaction_id: invoiceData?.transaction_id, created_date: invoiceData?.created_at })} />
    })
  ].map((item, index) => item.value && (
    <Grid key={index} item xs={12}>
      <Box
        sx={{
          display: 'column', justifyContent: 'left'
        }}
      >
        <Typography noWrap variant="subtitle2">
          {item.label}
        </Typography>
        <Typography noWrap variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
          <b>{typeof item.value === 'function' ? item.value() : item.value}</b>
        </Typography>
      </Box>
    </Grid>
  ))
  ), [invoice]);

  const renderSidebarFileInfo = useCallback((invoiceData) => ([
    {
      label: `Download ${invoiceData?.is_proforma ? 'Proforma' : 'Invoice'}`,
      value: invoiceData?.invoice_path,
      type: 'invoice',
      id: invoiceData?.id
    },
    {
      label: 'Download Proof of payment',
      value: invoiceData?.proof_of_payment_file_path,
      type: 'proof_of_payment',
      id: invoiceData?.id
    },
  ].map((item, index) => item.value && (
    <Grid key={index} container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={CardAction}
          onClick={() => {
            if (item.type === 'invoice') {
              props.handleDownloadInvoice({ id: item.id, file_name: item.value });
            } else if (item.type === 'proof_of_payment') {
              props.handleDownloadPoP({ id: item.id, file_name: item.value });
            }
          }}
        >
          <DownloadIcon />
          {item.label}
        </Box>
      </Grid>
    </Grid>
  ))
  ), [invoice]);

  const handleFormSubmit = () => {
    onClose();
    props.refetchBoard();
  };

  const renderInvoiceForm = useCallback(() => {
    switch (invoice?.payment_status) {
      case PENDING_APPROVE_OCCM:
      case PENDING_APPROVE_AM:
      case PENDING_APPROVE_LOGIST:
        return <PendingApproveForm invoiceId={invoice?.id} s3Link={invoice?.s3_link} onSubmit={handleFormSubmit} />;
      case PENDING_PAYMENT:
        return <PendingPaymentForm invoiceId={invoice?.id} onSubmit={handleFormSubmit} s3Link={invoice?.s3_link} invoice={invoice} refetchInvoice={handleGetInvoiceData} />;
      case PAYED:
        return <PayedForm invoiceId={invoice?.id} onSubmit={handleFormSubmit} creditNotes={invoice?.credit_notes} />;
      case PREPAYMENT:
        return <PrepaymentForm invoice={invoice} refetchInvoice={handleGetInvoiceData} invoiceId={invoice?.id} onClose={onClose} refetchBoard={props?.refetchBoard} />;
      case ISSUE:
        return <IssueForm reason={invoice?.decline_purpose} invoice={invoice} onSubmit={handleFormSubmit} />;
      default:
        return null;
    }
  }, [invoice]);

  const renderSupplier = useCallback(() => {
    const getSupplierName = () => {
      if (invoice?.invoice_name) return invoice.invoice_name;
      if (invoice?.invoice_supplier) return invoice.invoice_supplier;
      const orderWithSupplier = invoice?.orders?.find(order => order?.prices?.some(price => price?.approved_by && price?.supplier?.name));

      return orderWithSupplier?.prices?.find(price => price?.approved_by && price?.supplier?.name)?.supplier?.name || null;
    };

    const supplierName = getSupplierName();

    return supplierName ? (
      <Grid item xs={12}>
        <Typography sx={TextWithIcon}>
          <b>Supplier:</b>
          <Typography sx={TextWrap}>{supplierName}</Typography>
        </Typography>
      </Grid>
    ) : null;
  }, [invoice]);

  useEffect(() => {
    if (locationInvoiceId) {
      handleGetInvoiceData(locationInvoiceId);
    }
  }, [locationInvoiceId]);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={!!locationInvoiceId}
      onClose={onClose}
    >
      {invoiceLoading ? (
        <DialogContent sx={{ alignSelf: 'center' }}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={9.5} sx={{ backgroundColor: '#e9f1fe26' }}>
            <DialogContent>
              <Card sx={{ padding: '1rem' }}>
                <Box sx={{ ...headerBtn, display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography variant="h5" noWrap>
                      {handleInvoiceTitle()}
                      {' '}
                      {(invoice?.resolved_at && (invoice?.payment_status === 3)) && <Chip label="Resolved" size="small" color="success" />}
                    </Typography>
                  </Box>

                  <Box sx={TextWithIcon}>
                    {formattedInvoice?.type?.icon}
                    {formattedInvoice?.type?.label}
                  </Box>
                </Box>

                <Divider />

                <Grid container spacing={1}>
                  {invoice?.invoice_number && (
                    <Grid item xs={12}>
                      <Typography sx={TextWithIcon}>
                        <b>Number:</b>
                        <Typography sx={TextWrap}>{invoice.invoice_number}</Typography>
                      </Typography>
                    </Grid>
                  )}
                  {renderSupplier()}
                  {invoice?.amount && (
                    <Grid item xs={12}>
                      <Typography sx={TextWithIcon}>
                        <b>Amount:</b>
                        <Typography sx={TextWrap}>{invoice.amount}</Typography>
                      </Typography>
                    </Grid>
                  )}
                  {invoice?.description && (
                    <Grid item xs={12}>
                      <Typography sx={{ ...TextWithIcon, alignItems: 'start' }}>
                        <b>Description:</b>
                        <Typography sx={TextWrap}>{invoice.description}</Typography>
                      </Typography>
                    </Grid>
                  )}
                  {invoice?.decline_purpose && (
                    <Grid item xs={12}>
                      <Typography sx={{ ...TextWithIcon, alignItems: 'start' }}>
                        <b>Issue Reason:</b>
                        <Typography sx={TextWrap}>{invoice?.decline_purpose}</Typography>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Divider />
                {invoice?.proforma && (
                  <>
                    {invoice?.proforma?.invoice_name && (
                      <Grid item xs={12}>
                        <Typography sx={TextWithIcon}>
                          <b>Proforma Supplier: </b>
                          <Typography sx={TextWrap}>{invoice?.proforma?.invoice_name}</Typography>
                        </Typography>
                      </Grid>
                    )}
                    {invoice?.proforma?.invoice_number && (
                      <Grid item xs={12}>
                        <Typography sx={TextWithIcon}>
                          <b>Proforma Number: </b>
                          <Typography sx={TextWrap}>{invoice?.proforma?.invoice_number}</Typography>
                        </Typography>
                      </Grid>
                    )}
                    {invoice?.proforma?.amount && (
                      <Grid item xs={12}>
                        <Typography sx={TextWithIcon}>
                          <b>Proforma Amount: </b>
                          <Typography sx={TextWrap}>{invoice?.proforma?.amount}</Typography>
                        </Typography>
                      </Grid>
                    )}
                    {invoice?.proforma?.description && (
                      <Grid item xs={12}>
                        <Typography sx={{ ...TextWithIcon, alignItems: 'start' }}>
                          <b>Proforma Description:</b>
                          <Typography sx={TextWrap}>{invoice?.proforma?.description}</Typography>
                        </Typography>
                      </Grid>
                    )}
                    {invoice?.proforma?.decline_purpose && (
                      <Grid item xs={12}>
                        <Typography sx={{ ...TextWithIcon, alignItems: 'start' }}>
                          <b>Issue Reason:</b>
                          <Typography sx={TextWrap}>{invoice?.proforma?.decline_purpose}</Typography>
                        </Typography>
                      </Grid>
                    )}

                    <Divider />
                  </>
                )}
              </Card>

              <Grid item xs={12}>
                {renderInvoiceForm()}
              </Grid>
            </DialogContent>
          </Grid>

          <Grid item xs={2.5} sx={DetailsGridStyle}>
            <Grid container>
              <Grid item xs={12} sx={RightMenuStyle}>
                <CloseIcon
                  sx={CloseIconStyle}
                  onClick={onClose}
                />
              </Grid>
              <Grid item xs={12}>
                <Card sx={OrderDetailsStyle}>
                  <CardContent>
                    {invoice?.proforma && (
                      <>
                        <Typography variant="h5">Invoice</Typography>
                        <Divider />
                      </>
                    )}

                    <Grid container spacing={2}>
                      {renderSidebarMainInfo(invoice, ((invoice?.payment_status === 7) || (invoice?.payment_status === 3)))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={OrderDetailsStyle}>
                  <CardContent>
                    {renderSidebarFileInfo(invoice)}
                  </CardContent>
                </Card>
              </Grid>

              {invoice?.proforma && (
                <>
                  <Grid item xs={12}>
                    <Card sx={OrderDetailsStyle}>
                      <CardContent>
                        <Typography variant="h5">Proforma</Typography>
                        <Divider />
                        <Grid container spacing={2}>
                          {renderSidebarMainInfo(invoice.proforma, ((invoice?.payment_status === 7) || (invoice?.payment_status === 3)))}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card sx={OrderDetailsStyle}>
                      <CardContent>
                        {renderSidebarFileInfo(invoice.proforma)}
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}

              {!!invoice?.group_invoices?.length && (
                <Grid item xs={12}>
                  <Card sx={OrderDetailsStyle}>
                    <CardContent>
                      <Typography variant="h5">Invoices Group</Typography>
                      <Divider />
                      <Grid container spacing={2}>
                        {invoice?.group_invoices.map((inv, index) => (
                          <Grid item xs={12} sx={{ display: 'flex' }}>
                            <Typography>
                              {index + 1}
                              .
                            </Typography>
                            <LinkButton
                              style={{
                                width: '100%', height: 'auto', color: 'blue', fontWeight: '600'
                              }}
                              path={`/finance/invoice_management?invoice=${inv?.id}`}
                              target="_blank"
                            >
                              {inv?.id}
                            </LinkButton>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {(invoice?.supplier || invoice?.orders?.[0]?.prices?.[0]?.supplier) && (<VendorInfo vendor={invoice?.orders?.[0]?.delivery_operator || invoice?.supplier || invoice?.orders?.[0]?.prices?.[0]?.supplier} creditNotes={invoice?.credit_notes} />)}
            </Grid>
          </Grid>
        </Grid>
      )}
      <TransactionModal
        open={transactionModalState.isOpen}
        onClose={() => handleOpenTransactionModal(false)}
        config={transactionModalState?.config}
        refetchCallback={() => locationInvoiceId && handleGetInvoiceData(locationInvoiceId)}
      />
    </Dialog>
  );
};

export default InvoiceForm;

/**
 * @param search - location search
 * @param filter - optional - Array<string>, allows you to select the desired parameters from the location search
 */
export function parseLocationSearch(search, filter) {
  if (!search || search === '?') {
    return {};
  }

  return search
    .substr(1)
    .split('&')
    .reduce((obj, param) => {
      const [key, value] = param.split('=');
      const decodedKey = decodeURIComponent(key);

      if (filter && !filter.includes(decodedKey)) {
        return obj;
      }

      return {
        ...obj,
        [decodedKey]: decodeURIComponent(value),
      };
    }, {});
}

export function stringifyLocationSearch(searchObj) {
  if (!searchObj || Object.keys(searchObj).length === 0) {
    return '';
  }

  const params = Object.entries(searchObj)
    .filter(([, value]) => value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  return `?${params}`;
}

export function stringifyLocationFiltration(searchObj) {
  if (!searchObj || Object.keys(searchObj).length === 0) {
    return '';
  }

  const params = Object.entries(searchObj)
    .filter(([, value]) => value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${(value)}`)
    .join('&');

  return `?${params}`;
}

/**
 * @param search: string - location search string
 * @param searchObj: { [key: string]: string | number | null } - new params object { name: value, ... }
 */
export function modifyLocationSearch(search, searchObj) {
  if (!searchObj || Object.keys(searchObj).length === 0) {
    return search;
  }

  const newSearchObj = {
    ...parseLocationSearch(search),
    ...searchObj,
  };

  return stringifyLocationSearch(newSearchObj);
}

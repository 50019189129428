import Typography from '@mui/material/Typography';
import React from 'react';
import { linkSx } from '../styles';
import Box from '@mui/material/Box';
import Button from '../../../components/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BrushIcon from '@mui/icons-material/Brush';
import { GreenButton } from '../../Logistics/style';

export const columnsSigns = ({ handlePreview, handleSignDoc }) => ([
  {
    flex: 0.1,
    field: 'id',
    minWidth: 180,
    headerName: 'Document #',
    renderCell: (row) => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Typography sx={linkSx}>{`Document #${row?.id}`}</Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'sent_at',
    minWidth: 200,
    headerName: 'Sent At',
    renderCell: ({ row }) => (
      <Typography>{row?.sent_at}</Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'sent_by',
    minWidth: 180,
    headerName: 'Sent By',
    renderCell: ({ row }) => (
      <Typography>{row?.sent_by}</Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'actions',
    minWidth: 180,
    headerName: '',
    renderCell: (row) => (
      <Box>
        <Button
          title="Open"
          startIcon={<VisibilityIcon />}
          onClick={() => handlePreview(row?.id)}
        />
      </Box>
    ),
  },
  {
    flex: 0.1,
    field: 'signed',
    minWidth: 180,
    headerName: 'Sign',
    renderCell: ({ row }) => (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {row?.signed === 0 ? (
          <Box>
            <Button
              title="Sign"
              sx={GreenButton}
              startIcon={<BrushIcon />}
              onClick={() => handleSignDoc(row?.uuid)}
            />
          </Box>
        ) : (
          <Typography>Signed.</Typography>
        )}
      </>
    ),
  },
]);

import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader, FormHelperText,
  Grid, InputLabel, MenuItem, Select,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import { CustomForm } from './components/form/styles';
import {
  useUpdateCaaMutation,
  useGetCaaMutation,
  useStoreCaaMutation,
  useGetCountryListQuery,
} from '../../../store/session';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, defaultValues } from './schema';
import FormGroup from '../../../form/components/FormGroup';
import Input from '../../../form/components/Input';
import FieldError from '../../../form/components/FieldError';

const CaaForm = () => {
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const update = id !== 'create';
  const { data: countryList, isLoading: countryListLoad } = useGetCountryListQuery();
  const [updateCaa] = useUpdateCaaMutation();
  const [storeCaa] = useStoreCaaMutation();
  const [getCaa] = useGetCaaMutation();
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const handleCaaData = async () => {
    setLoading(true);
    const { data: result } = await getCaa({ id });

    return result;
  };
  useEffect(() => {
    if (update) {
      handleCaaData().then((result) => {
        if (result) {
          Object.entries(result).forEach(
            ([name, value]) => setValue(name, value),
          );
        }
        setLoading(false);
      });
    }
  }, [update]);

  if (isLoading || countryListLoad) {
    return <ContentPageLoader />;
  }

  const onSubmit = async (data) => {
    if (update) {
      const res = await updateCaa({
        ...data,
      });
      if (!res?.error) {
        toast.success('Caa operator updated!');
        navigate('/directories/caa');
      } else {
        toast.error('Something went wrong');
      }
    } else {
      const res = await storeCaa({
        ...data,
      });
      if (!res?.error) {
        toast.success('Caa operator created!');
        navigate('/directories/caa');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 3 }}>
        <CardHeader title="Create Caa" />
        <CardContent sx={{ mt: 5 }}>
          <Grid container spacing={5}>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Name" required hasError={!!errors.caa_name}>
                <Controller
                  name="caa_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Name"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.caa_name} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Address" required hasError={!!errors.caa_address}>
                <Controller
                  name="caa_address"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Address"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.caa_address} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Tel" required hasError={!!errors.caa_tel}>
                <Controller
                  name="caa_tel"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Tel"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.caa_tel} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Fax" required hasError={!!errors.caa_fax}>
                <Controller
                  name="caa_fax"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Fax"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.caa_fax} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Email" required hasError={!!errors.caa_email}>
                <Controller
                  name="caa_email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Email"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.caa_email} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomForm fullWidth>
                <InputLabel
                  id="validation-basic-select"
                  error={Boolean(errors.caa_country)}
                  htmlFor="validation-basic-select"
                >
                  Country
                </InputLabel>
                <Controller
                  name="caa_country"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      defaultValue=""
                      value={value}
                      label="Country"
                      onChange={onChange}
                      error={Boolean(errors.caa_country)}
                      labelId="validation-basic-select"
                      aria-describedby="validation-basic-select"
                    >
                      {countryList?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.country_name}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.caa_country && (
                <FormHelperText sx={{ color: 'error.main' }} id="validation-basic-select">
                  This field is required
                </FormHelperText>
                )}
              </CustomForm>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Sita" required hasError={!!errors.caa_sita}>
                <Controller
                  name="caa_sita"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Sita"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.caa_sita} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="AFTN" required hasError={!!errors.caa_aftn}>
                <Controller
                  name="caa_aftn"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Aftn"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.caa_aftn} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Website" required hasError={!!errors.caa_web}>
                <Controller
                  name="caa_web"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="www.privacy.com"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.caa_web} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Application format" hasError={!!errors.caa_application_format}>
                <Controller
                  name="caa_application_format"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Applications format"
                      multiline
                      rows={4}
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.caa_application_format} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Notice period" hasError={!!errors.caa_notice_period}>
                <Controller
                  name="caa_notice_period"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Notice period"
                      multiline
                      rows={4}
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.caa_notice_period} />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup label="Remarks" hasError={!!errors.caa_remarks}>
                <Controller
                  name="caa_remarks"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Remarks"
                      multiline
                      rows={4}
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.caa_remarks} />
              </FormGroup>
            </Grid>

          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" size="large">
              {update ? 'Update' : 'Save' }
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default CaaForm;

export const cardContainer = {
  background: '#fff',
  borderRadius: '8px',
  boxShadow: 'none',
  border: '1px solid rgba(76, 78, 100, 0.22)',
  p: '60px 25px 25px 25px',
  position: 'relative',
};

export const typeSx = {
  position: 'absolute',
  top: '15px',
  left: '-2px',
  padding: '7px 14px',
  zIndex: '2',
};

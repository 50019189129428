import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Modal from '../../../../../components/Modal';
import FieldError from '../../../../../form/components/FieldError';
import FormGroup from '../../../../../form/components/FormGroup';
import Input from '../../../../../form/components/Input';
import { useChangeFeesMutation } from '../../../../../store/session';
import { defaultValues, schema } from './schema';
import {
  cancelButton,
  footerSx, headerSx,
  title
} from './styles';

export const FeesModal = ({
  onClose, open, airportList, setQuotation, refetchAirportList, feesKey, handleChangedAirport, handleChangeHops
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    getValues,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const [changeFees] = useChangeFeesMutation();

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit = async ({ airport }) => {
    const toastId = toast.loading('Loading...');
    const selectAirport = airportList?.find((AirportItem) => AirportItem.id === getValues().airport);
    if (!selectAirport) {
      toast.error('Not valid selected airport.', {
        id: toastId
      });

      return;
    }
    if (!feesKey) {
      toast.error('Not valid fees key.', {
        id: toastId
      });

      return;
    }

    const feesE = parseInt(getValues('airport_fees_e'), 10) ?? null;
    const feesF = parseInt(getValues('airport_fees_f'), 10) ?? null;
    if (!feesE || !feesF) {
      toast.error('Not valid fees.', {
        id: toastId
      });

      return;
    }
    const reqData = {
      airport_fees_e_data: { ...selectAirport.airport_fees_e_data, [feesKey]: feesE },
      airport_fees_f_data: { ...selectAirport.airport_fees_f_data, [feesKey]: feesF },
    };

    await changeFees({ id: airport, data: reqData, }).then(async (res) => {
      if (!res?.error) {
        toast.success('Airport fees updated!', {
          id: toastId
        });
  
        if (res?.data) {
          setValue('airport_fees_e', feesE);
          setValue('airport_fees_f', feesF);
        }
        
        handleClose();
        await refetchAirportList();
        // setQuotation();
        // handleResetHops();
      } else {
        toast.error('Something went wrong', {
          id: toastId
        });
      }
    }).then(() => {
      handleChangeHops(airport);
      handleChangedAirport(airport);
    });
  };

  const handleChange = (e, value) => {
    reset();
    setValue('airport', value.id);
    trigger('airport');
  };

  const getFeesByKey = (key, fieldName) => {
    const airport = airportList?.find((AirportItem) => AirportItem.id === getValues().airport);
    if (feesKey && airport) {
      const value = airport?.[key]?.[feesKey] || null;
      if (value) {
        setValue(fieldName, value);
      }

      return value;
    }

    return null;
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Box>
        <Box sx={headerSx}>
          <Typography sx={title}>Change A/P Fees</Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Box>
        <Grid spacing={2}>
          <Grid item lg={12}>
            <FormGroup required hasError={!!errors.airport}>
              <Controller
                name="airport"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    freeSolo
                    value={airportList?.filter((AirportItem) => AirportItem.id === field.value)[0] || null}
                    options={airportList}
                    getOptionLabel={(option) => `${option.airport_iata}/${option.airport_icao} (${option.airport_name})` || ''}
                    isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        inputRef={field.ref}
                        error={Boolean(errors.airport)}
                        label="Airport From"
                      />
                    )}
                    onChange={handleChange}
                  />
                )}
              />

              <FieldError error={errors.email} />
            </FormGroup>
          </Grid>

          <Grid item lg={12}>
            <FormGroup label="Ferry Flight">
              <Controller
                name="airport_fees_e"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    value={field.value || getFeesByKey('airport_fees_e_data', 'airport_fees_e') || ''}
                    onChange={(e) => {
                      setValue('airport_fees_e', parseInt(e.target.value, 10));
                    }}
                  />
                )}
              />

              <FieldError error={errors.email} />
            </FormGroup>
          </Grid>

          <Grid item lg={12}>
            <FormGroup label="To Cargo Flight">
              <Controller
                name="airport_fees_f"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    value={field.value || getFeesByKey('airport_fees_f_data', 'airport_fees_f') || ''}
                    onChange={(e) => {
                      setValue('airport_fees_f', parseInt(e.target.value, 10));
                    }}
                  />
                )}
              />

              <FieldError error={errors.email} />
            </FormGroup>
          </Grid>
        </Grid>

        <Box sx={footerSx}>
          <Button onClick={handleClose} sx={cancelButton}>Cancel</Button>
          <Button onClick={handleSubmit(onSubmit)} variant="contained">Save</Button>
        </Box>
      </Box>
    </Modal>
  ); };

import React from 'react';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  container,
  accountIcon,
  profilePhoto,
  ContainerSmall
} from './styles';
import { useMediaQuery } from '@mui/material';

const ImageContainer = ({ img }) => {
  const maxWidth590px = useMediaQuery('(max-width:590px)');

  return (
    <Box sx={{ ...container(!!img), ...(maxWidth590px && ContainerSmall) }}>
      {img !== '/images/crew/' ? (
        <img src={img} alt="fe-img" />
      ) : (
        <>
          <AccountCircleIcon sx={accountIcon} />
          <Box sx={profilePhoto}>
            Profile photo
          </Box>
        </>
      )}

    </Box>
  ); };

export default ImageContainer;

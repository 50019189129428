import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useState } from 'react';
import { Calendar } from './Calendar';
import Filtration from './Filtration';
import { calendarWrapper } from './style';

const DutyFlightTime = () => {
  const [months, setMonths] = useState([]);
  const [data, setData] = useState({});

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>Duty time and Flight time report</Typography>
        <Filtration setMonths={setMonths} setData={setData} />

        <Box sx={calendarWrapper}>
          {months.map(month => (
            <Calendar date={moment(month)} data={data} />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default DutyFlightTime;

import React from 'react';
import { Card } from '@mui/material';
import CreditNoteForm from './CreditNoteForm';
import EditableTable from '../../../../components/EditableTable';
import {
  useDeleteCreditNoteFileMutation,
  useGetCreditNotesQuery,
  useOpenCreditNoteFileMutation
} from '../../../../store/slices/logisticsSlice';
import { columns } from './columns';
import { useCreditNoteFiles } from './useCreditNoteFiles';
import FileDrawer from '../../../TrainingModule/FileDrawer/FileDrawer';

const CreditNotes = ({ supplier = null }) => {
  const filesProps = useCreditNoteFiles();

  return (
    <>
      <Card sx={{ mt: 3, padding: '1rem' }}>
        <CreditNoteForm type="create" drawerClose={() => {}} handleRefresh={() => {}} supplier={supplier} />
      </Card>

      <Card sx={{ mt: 3, padding: '1rem' }}>
        <EditableTable
          useHook={useGetCreditNotesQuery}
          getRowId={(row) => row.id}
          columns={columns}
          sortByType="DESC"
          sortByActive="id"
          getRowHeight={() => 'auto'}
          handleFileDrawer={filesProps.handleFileDrawer}
          tableParams={{
            'logistic_supplier_id': supplier?.id
          }}
        />

        <FileDrawer
          open={filesProps.fileDrawerState.isOpen}
          onClose={() => filesProps.handleFileDrawer(false)}
          title={filesProps.fileDrawerState.name}
          itemName={filesProps.fileDrawerState.currentName}
          itemsList={filesProps.fileDrawerState.fileList}
          uploadFile={(file) => filesProps.updateFiles(
            filesProps.fileDrawerState.currentId,
            file,
            filesProps.fileDrawerState.type,
            () => filesProps.handleFileDrawer(
              true,
              filesProps.fileDrawerState.currentId,
              filesProps.fileDrawerState.type,
              filesProps.fileDrawerState.name,
              filesProps.fileDrawerState.currentName
            )
          )}
          hasPermissions
          isAttachLoading={filesProps.isAttachLoading}
          isLoadingFiles={filesProps.isLoadingFiles}
          useOpenFileMutation={useOpenCreditNoteFileMutation}
          useDeleteFileMutation={useDeleteCreditNoteFileMutation}
        />
      </Card>
    </>
  );
};

export default CreditNotes;

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import Button from '../../../../../../components/Button';
import Select from '../../../../../../form/components/Select';
import { useGetAirportListQuery, useGetFlightFuelAirportQuery } from '../../../../../../store/session';
import { getOptions } from '../../../../../../utils/getOptions';
import { deleteIcon } from '../styles';
import FuelOrder from './FuelOrder';
import FuelPrices from './FuelPrices';
import { StyledDelete, noData } from './styles';

const FuelForm = ({ control, watch, handleFlightData }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const [airport, setAirportName] = useState('');
  const [priceData, setPriceData] = useState(null);
  const [indexFuel, setIndexFuel] = useState(null);
  const {
    fields,
    update
  } = useFieldArray({
    name: 'planing_fuel',
    control,
  });
  const rawPlaningFuel = watch('planing_fuel');
  const planingFuel = watch('planing_fuel')?.[indexFuel];
  const airports = fields?.map(item => item.plfuel_ap_id)?.join(',');
  const { data: airportList } = useGetAirportListQuery();
  const { data: flightFuel, isLoading: isFlightFuelLoading, refetch: refetchFlightFuelAirport } = useGetFlightFuelAirportQuery(airports);

  const handleOpen = ({ airportName, supplier, index }) => {
    setAirportName(airportName);
    setPriceData(supplier);
    setIndexFuel(index);

    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleOpenOrder = ({ index }) => {
    setIndexFuel(index);
    setOpenOrder(true);
  };

  const handleCloseOrder = () => {
    setOpenOrder(false);
  };

  const handleUpdate = (i, item) => {
    const {
      withoutName, index, itemId, ...data
    } = item;

    update(i, { ...data, itemId, ...(itemId && { id: itemId }) });
  };

  const fuelNeededOptions = getOptions(flightFuel?.fuelNeeded).map(i => ({
    value: i.label, label: i.label,
  }));

  const paymentMethodsOptions = getOptions(flightFuel?.paymentMethods);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        {fields.length ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {fields.map((item, index) => {
                  const airportData = airportList.find(e => item.plfuel_ap_id === e.id) || {};
                  const airportName = `${airportData.airport_iata}/${airportData.airport_icao} (${airportData.airport_name})`;
                  const supplier = flightFuel?.airports[item.plfuel_ap_id]?.suppliers || [];
                  const airportsOptions = supplier?.map((e) => ({
                    value: e.id,
                    label: e.label
                  }));

                  const handlePrice = (id) => supplier?.find((e) => (
                    e.id === id
                  ));

                  return (
                    <TableRow>
                      <TableCell align="left">
                        <Typography sx={{ whiteSpace: 'nowrap' }}>
                          {airportName}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Select
                          options={airportsOptions}
                          value={item.plfuel_suplier || null}
                          label="Supplier"
                          placeholder=" "
                          onChange={(e, { props: { value } }) => {
                            handleUpdate(
                              index,
                              {
                                ...item,
                                plfuel_suplier: +value,
                                plfuel_price: handlePrice(value).price,
                                plfuel_mail: handlePrice(value).email
                              }
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Select
                          options={paymentMethodsOptions}
                          value={item.plfuel_payment_method || null}
                          label="Method"
                          placeholder=" "
                          onChange={(e, { props: { value } }) => handleUpdate(index, { ...item, plfuel_payment_method: +value })}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Select
                          options={fuelNeededOptions}
                          value={item.plfuel_fuel_needed}
                          label="Fuel Needed"
                          placeholder=" "
                          onChange={(e, { props: { value } }) => handleUpdate(index, { ...item, plfuel_fuel_needed: value })}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Typography sx={{ whiteSpace: 'nowrap' }}>
                          {`${item?.plfuel_price || ''} USG/USD`}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" />
                      <TableCell align="center">
                        <Button
                          disabled={!rawPlaningFuel[index].id}
                          onClick={() => handleOpen({
                            airportName,
                            supplier,
                            id: item.plfuel_ap_id,
                            index,
                          })}
                          sx={{ color: '#fff !important', minWidth: '207px' }}
                          title="Change Fuel Prices"
                        />
                      </TableCell>
                      <TableCell align="center">
                        {item?.plfuel_price && (
                        <Button
                          onClick={() => handleOpenOrder({ index })}
                          sx={{ color: '#fff !important', minWidth: '140px' }}
                          title="Fuel Order"
                        />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <StyledDelete
                          sx={deleteIcon}
                          onClick={() => handleUpdate(index, {
                            ...item,
                            plfuel_suplier: null,
                            plfuel_payment_method: null,
                            plfuel_fuel_needed: null,
                            plfuel_price: null,
                            plfuel_mail: null
                          })}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={noData}>
            <Typography variant="h4">No data</Typography>
          </Box>
        )}
      </Grid>

      <FuelPrices
        handleFlightData={handleFlightData}
        isFlightFuelLoading={isFlightFuelLoading}
        onClose={handleClose}
        open={openDrawer}
        refetchFlightFuelAirport={refetchFlightFuelAirport}
        planingFuel={planingFuel}
        airportName={airport}
      />
      <FuelOrder onClose={handleCloseOrder} open={openOrder} planingFuel={planingFuel} />
    </Grid>
  );
};
export default FuelForm;

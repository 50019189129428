export const boldSx = {
  fontWeight: 'bold',
};

export const flightTitle = {
  fontSize: '30px',
  span: {
    fontSize: '30px',
    ml: '10px'
  }
};

export const flightTitleXs = {
  fontSize: '23px',
  span: {
    fontSize: '23px',
  }
};

export const containerSx = (isPanel) => (isPanel ? ({
  '& .MuiTypography-root': {
    // fontSize: '14px !important',
  }
}) : ({}));

import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import { Weight } from 'mdi-material-ui';
import React, { forwardRef } from 'react';

const Checkbox = forwardRef(({ label, loadIcon, ...props }, ref) => (
  <FormControlLabel
    control={(
      <MuiCheckbox
        inputRef={ref}
        checked={Boolean(props.value)}
        checkedIcon={loadIcon && (
          <Weight sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          />
        )}
        {...props}
      />
    )}
    label={label}
  />
));

export default Checkbox;

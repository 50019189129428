export const useProvideTags = (result, error, tags) => {
  if (!result && error?.status === 401) {
    return ['UNAUTHORIZED'];
  }

  if (result && tags) {
    return tags;
  }

  return [];
};

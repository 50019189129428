export const selectStyle = {
  width: '7rem',
};

export const BlockSelect = {
  display: 'flex',
  alignItems: 'center',
  gap: 2
};

export const SelectDay = {
  width: '4rem',
};
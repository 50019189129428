import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment/moment';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCrewListQuery } from '../../../../store/session';
import {
  useGetInstructorsListQuery, useGetLastReportDataQuery, useGetReportDataQuery, usePreviewReportMutation,
  useSendReportMutation, useUpdateReportMutation
} from '../../../../store/slices/TrainingModule/trainingReportSlice';
import { getOptions } from '../../../../utils/getOptions';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { stringifyLocationSearch } from '../../../../utils/locationSearch';
import { FIRST_REPORT_TYPE } from '../constants';
import { defaultValues, schema } from './schema';

export const useTrainingReportForm = () => {
  const { id } = useParams();
  const isUpdate = id !== 'create';
  const [sendReport] = useSendReportMutation();
  const [updateReport] = useUpdateReportMutation();
  const handleError = useHandleQueryFormError();
  const { data: Instructors } = useGetInstructorsListQuery();
  const instructorsOptions = getOptions(Instructors);
  const { data: LastReportData } = useGetLastReportDataQuery(stringifyLocationSearch({ report_type: 'first_report' }), {
    skip: isUpdate,
    refetchOnMountOrArgChange: true
  });
  const { data: ReportData } = useGetReportDataQuery(id, {
    skip: !isUpdate,
    refetchOnMountOrArgChange: true
  });
  const navigate = useNavigate();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const trainees = useFieldArray({
    name: 'trainees',
    control: form.control,
  });

  const trainingTitles = useFieldArray({
    name: 'training_titles',
    control: form.control,
  });

  const { data: CrewList } = useGetCrewListQuery(stringifyLocationSearch({
    post: '1,2,5',
    crew_active: 1
  }));

  /**
   * Autocomplete
   */
  useEffect(() => {
    if (!isUpdate && LastReportData) {
      form.setValue('training_place', LastReportData?.report_data?.trainingPlace ?? '');
      form.setValue('training_title', LastReportData?.report_data?.trainingTitle ?? '');
      form.setValue('training_reference', LastReportData?.report_data?.trainingReference ?? '');

      form.setValue('issue_number', LastReportData?.report_data?.issueNumber ?? '');
      form.setValue('revision_number', LastReportData?.report_data?.revisionNumber ?? '');
      form.setValue('training_titles', LastReportData?.report_data?.trainingTitles ?? '');
    }

    if (isUpdate && ReportData) {
      Object.entries(ReportData).forEach(
        ([name, value]) => form.setValue(name, value),
      );
    }
  }, [LastReportData, ReportData]);

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    data.report_type = FIRST_REPORT_TYPE;
    data.issue_date = data.issue_date ? moment(data.issue_date).format('YYYY-MM-DD') : '';
    data.revision_date = data.revision_date ? moment(data.revision_date).format('YYYY-MM-DD') : '';
    data.training_date = data.training_date ? moment(data.training_date).format('YYYY-MM-DD') : '';

    let res;
    if (!isUpdate) {
      res = await sendReport(data);
    } else {
      res = await updateReport({ id, data });
    }

    if (res?.error) {
      handleError(res?.error, form?.setError, form?.getValues, toastId);

      return;
    }

    if (!isUpdate) navigate('/training-module/training-reports');
    toast.success('Success!', {
      id: toastId,
    });
  };

  const [getPreview] = usePreviewReportMutation();

  const onPreview = async (data) => {
    const toastId = toast.loading('Loading...');

    data.report_type = FIRST_REPORT_TYPE;
    data.issue_date = data.issue_date ? moment(data.issue_date).format('YYYY-MM-DD') : '';
    data.revision_date = data.revision_date ? moment(data.revision_date).format('YYYY-MM-DD') : '';
    data.training_date = data.training_date ? moment(data.training_date).format('YYYY-MM-DD') : '';

    const res = await getPreview(data);

    if (res?.error) {
      handleError(res?.error, form?.setError, form?.getValues, toastId);

      return;
    }

    const fileUrl = URL.createObjectURL(res.data);
    window.open(fileUrl, '_blank', 'noreferrer');

    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleCrewListOptions = (crewList, position) => {
    let filterCondition;

    switch (position) {
      case 'FCM':
        filterCondition = (crew) => crew.crew_post === 1 || crew.crew_post === 2;
        break;
      case 'OCC':
        filterCondition = (crew) => crew.crew_post === 5;
        break;
      default:
        return crewList;
    }

    return crewList?.filter(filterCondition);
  };

  return {
    isUpdate,
    form,
    trainees,
    trainingTitles,
    onSubmit,
    onPreview,
    CrewList,
    instructorsOptions,
    handleCrewListOptions
  };
};

import React from 'react';
import { Grid } from '@mui/material';
import AircraftTypeButton from './components/AircraftTypeButton';
import Table from './list/Table';

const AircraftTypes = () => {
  const title = 'Aircraft Types';
  const btnTitle = 'Add Type';

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <AircraftTypeButton title={btnTitle} />
        <Table title={title} />
      </Grid>
    </Grid>
  );
};

export default AircraftTypes;

import {
  Box,
  Card, CardContent, Divider, Grid, Typography
} from '@mui/material';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useOpenCreditNoteFileMutation } from '../../../../../../store/slices/logisticsSlice';
import { OrderDetailsStyle } from '../../../../../Logistics/OrdersDashboard/styles';
import { TextWrap } from '../../../../../Logistics/style';

const VendorInfo = (props) => {
  const { vendor, creditNotes } = props;
  
  const [openFile] = useOpenCreditNoteFileMutation();
  const [currentFile, setCurrentFile] = useState(null);
  const vendorPayoutAmount = (creditNotes || [])?.map(nt => nt?.value);
  const vendorPayoutDescription = (creditNotes || [])?.map(nt => nt?.description);
  const vendorPayoutFile = (creditNotes || [])?.map(nt => nt?.files);
  
  const vendorInfoConfig = [
    { label: 'Name', value: vendor?.name },
    { label: 'Balance', value: vendor?.vendor_balance },
    { label: 'Credit Notes', value: vendorPayoutAmount },
  ];

  const handleOpenFile = async (id) => {
    const toastId = toast.loading('Loading...');
    setCurrentFile(id);
    const res = await openFile({ id });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.', {
          id: toastId,
        });
      } else {
        toast.error('Unable to open file.', {
          id: toastId,
        });
      }

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    setCurrentFile(null);

    const fileUrl = URL.createObjectURL(res.data);
   
    window.open(fileUrl, '_blank', 'noreferrer');
  };

  return (
    <Grid item xs={12}>
      <Card sx={OrderDetailsStyle}>
        <CardContent>
          <Typography variant="h5">Vendor</Typography>
          <Divider />
          <Grid container spacing={2}>
            {vendorInfoConfig.map(conf => {
              if (Array.isArray(conf?.value)) {
                return (
                  <>
                    {(creditNotes || []).map((_, index) => (
                      <Grid key={index} item xs={12}>
                        <Divider />
                        <Box sx={{ display: 'grid', gridTemplateColumns: '2rem 1fr' }}>
                          <Typography>{index + 1}</Typography>
                          <Box>
                            {Object.entries({
                              amount: vendorPayoutAmount?.[index],
                              description: vendorPayoutDescription?.[index],
                              ...(vendorPayoutFile?.[index]?.length && { files: vendorPayoutFile?.[index] }),
                            }).map(([key, vlv]) => (
                              <Box
                                key={key}
                                sx={{
                                  display: 'column', justifyContent: 'left'
                                }}
                              >
                                <Typography noWrap variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                                  <b>
                                    {key}
                                    :
                                  </b>
                                 
                                </Typography>
                                <Typography noWrap variant="subtitle1" sx={{ textTransform: 'capitalize', ...TextWrap }}>
                                  {!Array.isArray(vlv) ? vlv : vlv.map((file, fileIndex) => (
                                    <Typography key={file?.id} sx={{ ...TextWrap, display: 'flex' }}>
                                      {fileIndex + 1}
                                      .
                                      {' '}
                                      <Typography
                                        sx={{
                                          textDecoration: 'underline', cursor: 'pointer', color: 'blue', ...TextWrap 
                                        }}
                                        onClick={() => {
                                          if (currentFile === file?.id) {
                                            return;
                                          }
                                          handleOpenFile(file?.id);
                                        }}
                                      >
                                        {file?.path?.split('/')?.[5] || file?.path?.split('/')?.[4] || ''}
                                      </Typography>
                                    </Typography>
                                  ))}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </>
                );
              }

              return (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'column', justifyContent: 'left'
                    }}
                  >
                    <Typography noWrap variant="subtitle2">
                      {conf?.label}
                    </Typography>
                    <Typography noWrap variant="subtitle1" sx={{ textTransform: 'capitalize', ...TextWrap }}>
                      <b>{conf?.value}</b>
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
            
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default VendorInfo;
import {
  Box, Card, Divider, Typography
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import EditableTable from '../../components/EditableTable';
import { useDeleteBankMutation, useGetBankListQuery } from '../../store/session';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { columns } from './columns';

export const Banks = () => {
  const navigate = useNavigate();

  const hasPermissionsBank = usePermissions('bank_edit');

  return (
    <Card sx={{
      mt: 3,
      padding: '1rem',
    }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3">Banks</Typography>
        <Button onClick={() => navigate('/finance/banks/create')} title="Add" />
      </Box>

      <Divider />

      <EditableTable
        useHook={useGetBankListQuery}
        useHookDelete={useDeleteBankMutation}
        columns={columns}
        sortByType="DESC"
        sortByActive="bank_name"
        getRowHeight={() => 'auto'}
        hasPermissions={hasPermissionsBank}
        getRowId={(row) => row.id}
      />
    </Card>
  );
};

import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableBodyBase from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import {
  DATE_FORMAT,
  DAY_FORMAT,
  DAY_IN_WEEK,
  MONTH_FORMAT,
  WEEK,
  WEEK_COLORS,
  WORK_DAY_COLOR
} from '../constants';
import moment from 'moment';
import {
  boldBorderLeft, boldBorderRight, borderLeft, borderRight, cellSx, dataCell, dateCell,
} from '../styles';

const TableBody = ({
  data, date, index, firstDayInMonth, daysInMonth, list, openMenu, dataCellSx,
}) => (
  <TableBodyBase>
    {index === 0 && Array.from({ length: WEEK[firstDayInMonth] }).map(() => (
      <TableRow>
        <TableCell />
        <TableCell />

        {data?.map(item => (
          item.items.length ? item?.items?.map((subItem, i) => (
            <TableCell
              align="center"
              sx={{
                ...cellSx,
                ...borderRight,
                ...(i === 0 && boldBorderLeft ),
                ...(i === item.items.length - 1 && boldBorderRight)
              }}
            />
          )) : <TableCell />
        ))}
      </TableRow>
    ))}

    {Array.from({ length: index === 0 ? DAY_IN_WEEK - WEEK[firstDayInMonth] : DAY_IN_WEEK }).map((_, i) => {
      const dayNumber = i + (index * (DAY_IN_WEEK - WEEK[firstDayInMonth])) + (index > 1 ? WEEK[firstDayInMonth] * (index - 1) : 0);
      const cellDate = moment(date, 'MMM-DD-YYYY');
      cellDate.add(dayNumber, 'days');
      const dayLabel = cellDate.format(DAY_FORMAT);

      return (
        dayNumber < daysInMonth && (
        <TableRow>
          <TableCell sx={{ ...cellSx, ...dateCell, backgroundColor: WEEK_COLORS[dayLabel] || WORK_DAY_COLOR }} align="center">
            {`${dayNumber + 1} ${moment(date, 'MMM-DD-YYYY').format(MONTH_FORMAT)}`}
          </TableCell>

          <TableCell
            align="center"
            sx={{
              ...cellSx, ...dateCell, ...borderLeft, backgroundColor: WEEK_COLORS[dayLabel] || WORK_DAY_COLOR
            }}
          >
            {dayLabel}
          </TableCell>

          {data?.map((item) => (
            item.items.length ? item?.items?.map((subItem, subIndex) => {
              const currentItem = subItem.values[cellDate.format(DATE_FORMAT)];
              const values = currentItem && currentItem[Object.keys(currentItem)[0]];
              const { duty_type } = values || {};
              const listItem = list?.find((dutyListItem) => dutyListItem.id === +duty_type);

              return (
                <TableCell
                  align="center"
                  onClick={(e) => openMenu(e, {
                    crew_id: subItem.crew_id, cellDate, itemId: Object.keys(currentItem || {})[0]
                  })}
                  sx={{
                    ...dataCell,
                    ...(subIndex === 0 && boldBorderLeft),
                    ...(subIndex === item.items.length - 1 && boldBorderRight),
                    ...(listItem && { backgroundColor: listItem.COLOR }),
                    ...dataCellSx,
                  }}
                >
                  {listItem?.NAME}
                </TableCell>
              ); }) : <TableCell sx={dataCell} />
          ))}
        </TableRow>
        )
      ); })}
  </TableBodyBase>
);

export default TableBody;

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSendInternalRequestApproveMutation } from '../../../../../../store/slices/internalLogisticsSlice';
import { useSendRequestApproveMutation } from '../../../../../../store/slices/logisticsSlice';
import {
  defaultValues,
  schema
} from './schema';

export const useNewOrderForm = ({ order, onClose, dashboardType }) => {
  const [sendCurrent, { isLoading: isLoadingCurrent }] = useSendRequestApproveMutation();
  const [sendInternal, { isLoading: isLoadingInternal }] = useSendInternalRequestApproveMutation();
  const send = (dashboardType === 'orders') ? sendCurrent : sendInternal;
  const isLoading = (dashboardType === 'orders') ? isLoadingCurrent : isLoadingInternal;

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const prices = useFieldArray({
    name: 'prices',
    control: formMethods.control
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const sendData = { ...data };
    const sendPrices = dashboardType === 'orders'
      ? sendData.prices.map(item => ({
        amount: item?.amount,
        currency: item?.currency,
        supplier_id: item?.supplier_id,
        suggested: item?.suggested,
        status: item?.status,
        transaction_type: item?.transaction_type
      }))
      : sendData.prices.map(item => {
        let obj = {
          currency_id: item.currency,
          suggested: item?.suggested,
        };

        if (data.orderType === 'delivery') {
          obj = {
            ...obj,
            amount: item?.amount,
            supplier_id: item?.supplier_id,
          };
        }

        if (data.orderType === 'Return') {
          obj = {
            ...obj,
            amount: 1,
            address_from: item?.address_from,
            address_to: item?.address_to,
          };
        }

        if (['Overhaul', 'Repair'].includes(data?.orderType)) {
          obj = {
            ...obj,
            amount: item?.amount,
            address_from: item?.address_from,
            address_to: item?.address_to,
            address_to_supplier_id: item?.address_to_supplier_id,
          };
        }

        if (data?.orderType === 'Repair') {
          obj = {
            ...obj,
            estimated_date: item?.estimated_date,
          };
        }

        if (data?.orderType === 'Other') {
          obj = {
            amount: item?.amount,
            currency_id: item.currency,
            supplier_id: item?.supplier_id,
            suggested: item?.suggested,
            // status: item?.status,
            // transaction_type: item?.transaction_type
          };
        }

        return obj;
      });

    const { error } = await send({
      id: order.id,
      data: {
        notice: data?.notice,
        prices: sendPrices
      }
    });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    onClose();
    toast.success('Successfully!', {
      id: toastId
    });
  };

  return {
    formMethods,
    prices,
    defaultValues,
    handleSubmit: formMethods.handleSubmit,
    onSubmit,
    isLoading,
    setValue: formMethods.setValue
  };
};

export const modalSx = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '488px',
  width: '100%',
  bgcolor: '#fff',
  p: '25px',
  borderRadius: '10px'
};

export const headerSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const closeIcon = {
  cursor: 'pointer',
};

export const titleSx = {
  fontWeight: '600',
  fontSize: '22px',
};

export const cancel = {
  background: '#e1e5ec',
  color: '#003467',
  '&:hover': {
    backgroundColor: 'rgba(0, 52, 103, 0.08)',
  }
};

export const blockBtn = {
  display: 'flex',
  gap: '20px',
};

export const text = {
  fontSize: '22px',
  padding: '20px 0',
};
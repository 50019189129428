import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { styled } from '@mui/material/styles'
import Copy from 'mdi-material-ui/ContentCopy'

export const header = {
  backgroundColor: '#f7f7f7',
  p: '8px 14px',
  color: '#34495e',
  borderBottom: '1px solid #ebebeb',
};

export const listContainer = {
  p: '10px',
  gap: '1px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '20px'
};

export const listItem = {
  userSelect: 'none',
  cursor: 'pointer',
  width: '112px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#000',
  '&:hover': {
    color: '#23527c'
  }
};

export const closeSx = {
  color: '#fff',
  background: '#000',
};

export const dropdownMenuSx = {
  '& .MuiPaper-root': {
    p: 0,
    display: 'inline-table',
  }
};

export const StyledClear = styled(ClearIcon)(({ theme }) => ({
  width: '20px',
  height: '20px',
  '&:hover': {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));

export const StyledCheck = styled(CheckIcon)(() => ({
  width: '20px',
  height: '20px',
  '&:hover': {
    color: 'green',
    cursor: 'pointer',
  },
}));

export const StyledHeader = styled(Copy)(() => ({
  '.MuiDataGrid-columnHeaders': {
    minHeight: '300px',
  },
}));

export const ClicktoEdit = {
  fontStyle: 'italic',
  color: '#DD1144',
  borderBottom: 'dashed 1px #0088cc',
  cursor: 'pointer',
};

export const DefaultText = {
  borderBottom: 'dashed 1px #0088cc',
  color: '#337ab7',
  fontSize: '14px',
  cursor: 'pointer'
};

export const ContainerInput = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: '5px',
  '& .MuiSelect-select.MuiSelect-select': {
    width: '10rem',
  }
};

export const datePickerContainer = {
  alignItems: 'flex-start',
  '& .react-datepicker-popper': {
    transform: 'translate(-115px, -10px)!important;',
    width: '377px !important'
  }
};

export const InputStyle = {
  width: '100%',
  '& input': {
    fontSize: '13px',
  },
};

export const BlockIcons = {
  display: 'flex',
  flexDirection: 'column',
};

export const CheckboxStyle = {
  width: '10px',
  height: '10px',
};

export const BlockIconsCheckBox = {
  display: 'flex',
  alignItems: 'center',
};

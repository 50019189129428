import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  useCrewUploadImgMutation,
  useGetCrewByIdQuery,
  useGetUserListQuery,
  useUpdateCrewMutation
} from '../../store/session';
import { defaultValues, schema } from './schema';

export const useCrewForm = () => {
  const [tab, setTab] = useState(0);
  const params = useParams();

  const {
    data, isLoading: dataIsLoading, refetch
  } = useGetCrewByIdQuery(params.id, { refetchOnMountOrArgChange: true });
  const [updateCrew, { isLoading: isCrewLoading }] = useUpdateCrewMutation();
  const [crewUploadImg] = useCrewUploadImgMutation();
  const { data: userList, isLoading: userListLoading } = useGetUserListQuery();

  const isLoading = userListLoading || dataIsLoading;

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const maxWidth768px = useMediaQuery('(max-width:768px)');
  const maxWidth590px = useMediaQuery('(max-width:590px)');

  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { dirtyFields }
  } = methods;

  const crewPhoto = getValues('photo_path');

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const onSubmit = async (values) => {
    const { documents, ...changedFields } = dirtyFields;

    const dataForUpdate = Object.keys(changedFields).reduce((res, key) => ({ ...res, [key]: values[key] }), {});

    const docs = documents?.map((item, index) => {
      if (item) {
        const { id, doc_id } = data.documents[index];

        return {
          id, doc_id, crew_id: +params.id, date_expired: values.documents[index].date_expired
        };
      }

      return null;
    }).filter(Boolean);

    const reqData = {
      ...dataForUpdate,
      ...(docs && { documents: docs })
    };

    const res = await updateCrew({ data: reqData, id: data.id });

    if (res.error) {
      toast.error(res?.error?.data?.message);

      return;
    }

    refetch();
    toast.success('Changes saved!');
  };

  const uploadFile = async (e) => {
    if (!e.target.files[0]) return;

    const toastId = toast.loading('Loading...');

    const formData = new FormData();

    formData.append('id', params.id);
    formData.append('crew_photo', e.target.files[0]);

    const res = await crewUploadImg(formData);

    e.target.value = null;

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    toast.success('Successfully upload file!', {
      id: toastId
    });

    setValue('photo_path', res.data?.crew_photo);
    trigger('photo_path');
  };

  useEffect(() => {
    if (data) {
      const {
        crew_name,
        crew_user_id,
        positions,
        crew_shortname,
        crew_post,
        crew_birth,
        crew_pasport,
        crew_expires,
        crew_nationality,
        crew_email,
        crew_licence,
        crew_proficiency_notes,
        crew_active,
        crew_acft_type,
        crew_home_base,
        crew_wo_can_perform,
        crew_wo_can_close,
        crew_line_check,
        documents,
        photo_path,
        sign_path
      } = data;

      reset({
        crew_acft_type,
        crew_user_id,
        positions,
        crew_name,
        crew_shortname,
        crew_post,
        crew_birth,
        crew_pasport,

        crew_expires,
        crew_nationality,
        crew_email,
        crew_home_base,
        crew_licence,
        crew_proficiency_notes,
        crew_active,
        crew_wo_can_perform,
        crew_wo_can_close,
        crew_line_check,
        documents: documents?.map(item => ({ ...item, item_id: item.id })) || [],
        photo_path,
        sign_path
      });
    }
  }, [data]);

  return {
    data,
    tab,
    methods,
    isLoading,
    maxWidth768px,
    crewPhoto,
    maxWidth590px,
    onSubmit,
    isCrewLoading,
    handleSubmit,
    handleChange,
    uploadFile,
    refetch,
    userList,
  };
};

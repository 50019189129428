import {
  Box, Card, useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import EditableTable from '../../../../components/EditableTable';
import Loader from '../../../../components/Loader/ContentPageLoader';
import {
  useConfirmDeleteWorkOrderMutation,
  useGetAircraftListQuery, useGetWorkOrderQuery,
  useUpdateWorkOrderMutation
} from '../../../../store/session';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { headerRow, types, typesSmall } from '../../../Ods/OdsTable/styles';
import { columns } from './columns';
import {
  activeTypeSx,
  boxAircraft,
  boxAircraftSx,
  containerAircraft,
  containerAircraftSx,
  titleContainer,
  typeSx
} from './styles';

const DeleteConfirmationWorkOrder = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [activeType, setActiveType] = useState(+params.id);

  const [deleteConfirmation] = useConfirmDeleteWorkOrderMutation();
  const [updateWorkOrder] = useUpdateWorkOrderMutation();

  const maxWidth768px = useMediaQuery('(max-width:840px)');
  const maxWidth1000px = useMediaQuery('(max-width:1275px)');
  const maxWidth733px = useMediaQuery('(max-width:733px)');

  const deletedWOPermission = usePermissions('deleted_wo_access');

  const { data: aircraft } = useGetAircraftListQuery();

  const handleSetActiveType = (id) => {
    setActiveType(id);
    navigate(`/maintenance/work-order/delete/confirmation/${id}`);
  };

  const tableParams = { aircraft_id: params.id, deleted: 1 };

  if (!aircraft) return <Loader />;

  return (
    <Card sx={{
      mt: 3,
      padding: '1rem',
    }}
    >
      <Box sx={titleContainer}>
        <h1>Deleted Work Order list</h1>
      </Box>
      <Box sx={{ ...containerAircraft, ...(maxWidth768px && containerAircraftSx) }}>
        <Box sx={{ ...boxAircraft, ...(maxWidth768px && boxAircraftSx) }}>
          {aircraft?.map(type => (
            <Button
              title={type.aircraft_registration}
              sx={{ ...typeSx, ...(type.id === activeType && activeTypeSx) }}
              key={type.id}
              onClick={() => handleSetActiveType(type.id)}
            />
          ))}
        </Box>
        <Box
          sx={{
            ...headerRow,
            ...(maxWidth1000px && types),
            ...(maxWidth733px && typesSmall),
            minWidth: 'auto',
            backgroundColor: 'none',
            padding: 0
          }}
        >
          <Button
            sx={typeSx}
            onClick={() => navigate(`/maintenance/work-order/${+params.id}`)}
          >
            Active Work Orders
          </Button>
        </Box>
      </Box>

      <EditableTable
        useHook={useGetWorkOrderQuery}
        useHookUpdate={useUpdateWorkOrderMutation}
        useHookDelete={useConfirmDeleteWorkOrderMutation}
        getRowId={(row) => row.id}
        columns={columns}
        sortByType="DESC"
        sortByActive="wo_date"
        tableParams={tableParams}
        deletedWOPermission={deletedWOPermission}
        deleteConfirmation={deleteConfirmation}
        updateInvoice={updateWorkOrder}
      />
    </Card>
  );
};

export default DeleteConfirmationWorkOrder;

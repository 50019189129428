import { useState } from 'react';
import { FILTER_SIGNATURES_STATUSES } from './constants';
import { useGetCrewListQuery } from '../../../store/session';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import {
  useDownloadReportMutation,
  useNotifyUsersMutation
} from '../../../store/slices/TrainingModule/trainingReportSlice';
import { toast } from 'react-hot-toast';
import FileSaver from 'file-saver';
import { useNavigate } from 'react-router-dom';

export const useTrainingReport = () => {
  const navigate = useNavigate();
  const [signFilter, setSignFilter] = useState(null);
  const [crewFilter, setCrewFilter] = useState(null);
  const { data: crewList } = useGetCrewListQuery(stringifyLocationSearch({
    post: '1,2'
  }));
  const [downloadReport] = useDownloadReportMutation();
  const [sendNotification] = useNotifyUsersMutation();

  const handleOpenReport = (id, type) => {
    navigate(`/training-module/training-reports/${type}/${id}`);
  };

  const handleDownloadReport = async (id, download = true) => {
    const toastId = toast.loading('Loading...');

    const res = await downloadReport({ id });

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    if (download) {
      FileSaver.saveAs(res.data, 'training_report');
    } else {
      const fileUrl = URL.createObjectURL(res.data);
      window.open(fileUrl, '_blank', 'noreferrer');
    }

    toast.success('Done.', {
      id: toastId,
    });
  };

  const filterConfig = {
    items: [
      {
        value: signFilter,
        callback: (e) => setSignFilter(e.target.value),
        closeCallback: () => setSignFilter(null),
        typeFilter: 'Select',
        icon: 'ListStatus',
        options: FILTER_SIGNATURES_STATUSES,
        placeholder: 'Signed/Unsigned'
      },
      {
        value: crewFilter,
        callback: (e, data) => setCrewFilter(data?.id),
        closeCallback: () => setCrewFilter(null),
        typeFilter: 'CrewAutocomplete',
        icon: 'Face',
        options: crewList,
        placeholder: 'Filter by crew'
      }
    ]
  };

  const getTableRowStyle = (row) => {
    const { signatures } = row;
    const totalSignatures = signatures?.length;
    const signedSignatures = signatures?.filter(signature => signature?.signed === 1)?.length;

    if (totalSignatures === signedSignatures) {
      return 'green';
    }

    return 'yellow';
  };

  const handleNotify = async (id) => {
    const toastId = toast.loading('Loading...');

    const res = await sendNotification(id);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    toast.success(res.data.message, {
      duration: 12000,
      id: toastId,
    });
  };

  return {
    getTableRowStyle,
    filterConfig,
    signFilter,
    crewFilter,
    handleDownloadReport,
    handleOpenReport,
    handleNotify
  };
};

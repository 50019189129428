import React from 'react';
import { Card, Divider } from '@mui/material';
import Search from '../../../components/SearchStatistics/Search';
import { useGetStatisticByCompanyMutation } from '../../../store/session';

import Table from './Table/Table';

const ByCompany = () => (
  <Card sx={{ mt: 3 }}>
    <Search
      fixedCacheKey="get-statistic-by-company"
      useHook={useGetStatisticByCompanyMutation}
      clearPath="/statistics/by-company"
    />

    <Divider />

    <Table />
  </Card>
);

export default ByCompany;

import React from 'react';
import {
  Card,
  CardContent,
  CardHeader, FormHelperText, InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Weight } from 'mdi-material-ui';
import { CustomForm } from './styles';

const CargoTypeForm = (props) => {
  const {
    errors, control, Controller, cargoList,
  } = props;

  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader title="Cargo" />
      <CardContent sx={{ mt: 5 }}>
        <CustomForm fullWidth>
          <InputLabel
            id="validation-basic-select"
            error={Boolean(errors.planing_crg_type)}
            htmlFor="validation-basic-select"
          >
            Type
          </InputLabel>
          <Controller
            name="planing_crg_type"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                defaultValue=""
                value={value}
                label="Type"
                onChange={onChange}
                error={Boolean(errors.planing_crg_type)}
                labelId="validation-basic-select"
                aria-describedby="validation-basic-select"
              >
                {cargoList?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.cargo_name}</MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.planing_crg_type && (
            <FormHelperText sx={{ color: 'error.main' }} id="validation-basic-select">
              This field is required
            </FormHelperText>
          )}
        </CustomForm>
        <CustomForm fullWidth>
          <Controller
            name="planing_crg_dimenssions"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                rows={4}
                multiline
                variant="filled"
                label="Dimensions"
                id="textarea-filled-static"
              />
            )}
          />
        </CustomForm>
        <CustomForm fullWidth>
          <Controller
            name="planing_crg_weight"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                label="Weight"
                onChange={onChange}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Weight />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </CustomForm>
        <CustomForm fullWidth>
          <Controller
            name="planing_awb"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                label="AWB"
                onChange={onChange}
              />
            )}
          />
        </CustomForm>
        <CustomForm fullWidth>
          <Controller
            name="planing_crg_reference"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                label="Client reference number"
                onChange={onChange}
              />
            )}
          />
        </CustomForm>
      </CardContent>
    </Card>
  );
};

export default CargoTypeForm;

import React from 'react';
import {
  TableCell, TableContainer, TableHead, TableBody, TableRow, Table
} from '@mui/material';
import DatePicker from '../../../../../../../form/components/DatePicker';
import Checkbox from '../../../../../../../form/components/Checkbox';
import Input from '../../../../../../../form/components/Input';
import { columns } from './constants';
import { useTimeForm } from './useTimeForm';
import {
  header, body, datePickerWidth
} from './style';
import { setDateValue, validateDatePickerValue } from '../../../../../../../utils/setDateValue';

const TimeForm = () => {
  const {
    Controller,
    control,
    flightData,
    setValue,
  } = useTimeForm();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <TableCell sx={header}>
                {item.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {flightData?.planing_hop.map((item, index) => (
            <TableRow>
              <TableCell sx={body}>
                {item.pl_hop_from.split(' ')[0]}
              </TableCell>
              <TableCell sx={body}>
                {item.pl_hop_to.split(' ')[0]}
              </TableCell>
              <TableCell sx={body}>
                {item.pl_hop_prefix}
              </TableCell>
              <TableCell sx={{ ...body, ...datePickerWidth }}>
                <Controller
                  name={`planing_hop.${index}.pl_hop_blockoff`}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      placeholderText="YYYY-MM-DD HH:mm"
                      dateFormat="yyyy-MM-dd HH:mm"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setDateValue(e, setValue, `planing_hop.${index}.pl_hop_blockoff`);
                      }}
                      value={validateDatePickerValue(field.value)}
                    />
                  )}
                />
              </TableCell>
              <TableCell sx={{ ...body, ...datePickerWidth }}>
                <Controller
                  name={`planing_hop.${index}.pl_hop_takeoff`}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      placeholderText="YYYY-MM-DD HH:mm"
                      dateFormat="yyyy-MM-dd HH:mm"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setDateValue(e, setValue, `planing_hop.${index}.pl_hop_takeoff`);
                      }}
                      value={validateDatePickerValue(field.value)}
                    />
                  )}
                />
              </TableCell>
              <TableCell sx={{ ...body, ...datePickerWidth }}>
                <Controller
                  name={`planing_hop.${index}.pl_hop_takeon`}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      placeholderText="YYYY-MM-DD HH:mm"
                      dateFormat="yyyy-MM-dd HH:mm"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setDateValue(e, setValue, `planing_hop.${index}.pl_hop_takeon`);
                      }}
                      value={validateDatePickerValue(field.value)}
                    />
                  )}
                />
              </TableCell>
              <TableCell sx={{ ...body, ...datePickerWidth }}>
                <Controller
                  name={`planing_hop.${index}.pl_hop_blockon`}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      placeholderText="YYYY-MM-DD HH:mm"
                      dateFormat="yyyy-MM-dd HH:mm"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setDateValue(e, setValue, `planing_hop.${index}.pl_hop_blockon`);
                      }}
                      value={validateDatePickerValue(field.value)}
                    />
                  )}
                />
              </TableCell>
              <TableCell sx={{ ...body, ...datePickerWidth, display: 'flex' }}>
                <Controller
                  name={`planing_hop.${index}.pl_hop_nav_act`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`planing_hop.${index}.pl_hop_nav`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                    />
                  )}
                />
              </TableCell>
              <TableCell sx={body}>
                <Controller
                  name={`planing_hop.${index}.pl_hop_taketime`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      variant="standard"
                      {...field}
                      disabled
                    />
                  )}
                />
              </TableCell>
              <TableCell sx={body}>
                <Controller
                  name={`planing_hop.${index}.pl_hop_blocktime`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      variant="standard"
                      disabled
                      {...field}
                    />
                  )}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TimeForm;

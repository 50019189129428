import React, { forwardRef } from 'react';
import { TextField } from '@mui/material';
import { useHasError } from '../FormGroup';

const AutoSizeTextarea = forwardRef(
  ({ variant = 'filled', ...props }, ref) => {
    const hasError = useHasError();

    return <TextField variant={variant} multiline fullWidth inputRef={ref} error={hasError} size="small" {...props} />;
  },
);

export default AutoSizeTextarea;

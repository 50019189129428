import React from 'react';
import { Box, Card, useMediaQuery } from '@mui/material';
import { ButtonStyle, TitleContainer } from '../../Crew/styles';
import LinkButton from '../../../components/LinkButton';
import { columns } from './columns';
import EditableTable from '../../../components/EditableTable';
import { useDeleteUserPositionsMutation, useGetUsersPositionsTableQuery } from '../../../store/session';
import { titleContainerS } from './styles';

const UsersPositions = () => {
  const maxWidth600px = useMediaQuery('(max-width:600px)');

  return (
    <Card sx={{ padding: '1rem' }}>
      <Box
        sx={{ ...TitleContainer, ...(maxWidth600px && titleContainerS) }}
      >
        <h1>Manage Users Positions</h1>
        <LinkButton style={ButtonStyle} path="/manage-users-positions/create">Add</LinkButton>
      </Box>

      <EditableTable
        useHook={useGetUsersPositionsTableQuery}
        useHookDelete={useDeleteUserPositionsMutation}
        columns={columns}
        sortByType="DESC"
        sortByActive="position_name"
      />
    </Card>
  );
};

export default UsersPositions;

export const columns = [
  {
    id: 'departure',
    label: 'Departure',
  },
  {
    id: 'arriving',
    label: 'Arriving',
  },
  {
    id: 'flight_no',
    label: 'Flight No',
  },
  {
    id: 'captain',
    label: 'Captain',
  },
  {
    id: 'fo',
    label: 'FO',
  },
  {
    id: 'Engineer',
    label: 'engineer',
  },
];

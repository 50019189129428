import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  HeaderName, StyledDelete, StyledPencil,
} from '../../../components/EditableTable/styles';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import LinkButton from '../../../components/LinkButton/Button';

export const columns = ({
  handleFilter,
  filters,
  handleOpenDeleteModal,
}) => ([
  {
    flex: 0.3,
    field: 'caa_name',
    minWidth: 330,
    headerName: 'Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.caa_name || ''}
          value="caa_name"
          placeholder="Name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{ textTransform: 'capitalize' }}
      >
        {row.caa_name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'caa_country',
    minWidth: 170,
    headerName: 'Country',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Country</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.caa_country || ''}
          value="caa_country"
          placeholder="Country"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{ textTransform: 'capitalize' }}
      >
        {row.caa_country}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'caa_tel',
    minWidth: 200,
    headerName: 'Tel',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Tel</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.caa_tel || ''}
          value="caa_tel"
          placeholder="Tel"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{ textTransform: 'capitalize' }}
      >
        {row.caa_tel}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'caa_email',
    minWidth: 230,
    headerName: 'Email',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Email</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.caa_email || ''}
          value="caa_email"
          placeholder="Email"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
      >
        {row.caa_email}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'caa_sita',
    minWidth: 100,
    headerName: 'Sita',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Sita</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.caa_sita || ''}
          value="caa_sita"
          placeholder="Sita"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
      >
        {row.caa_sita}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'caa_aftn',
    minWidth: 120,
    headerName: 'AFTN',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>AFTN</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.caa_aftn || ''}
          value="caa_aftn"
          placeholder="AFTN"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
      >
        {row.caa_aftn}
      </Typography>
    ),
  },
  {
    width: 130,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <LinkButton path={`/directories/caa/${row.caa_id}`}>
          <StyledPencil />
        </LinkButton>
        {' '}
        <StyledDelete onClick={() => { handleOpenDeleteModal(row.caa_id); }} />
        {' '}
      </>
    ),
  },
]);

import React from 'react';
import { Card, Divider } from '@mui/material';
import Search from '../../../components/SearchStatistics/Search';
import { useGetStatisticBySectorMutation } from '../../../store/session';

import Table from './Table/Table';

const BySector = () => (
  <Card sx={{ mt: 3 }}>
    <Search
      fixedCacheKey="get-statistic-by-sector"
      useHook={useGetStatisticBySectorMutation}
      clearPath="/statistics/by-sector"
    />
    <Divider />
    <Table />
  </Card>
);

export default BySector;

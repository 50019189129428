import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Typography, useMediaQuery } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { v4 } from 'uuid';
import { TableStyle } from '../../../../components/EditableTable/styles';
import Upload from '../../../../components/Upload';
import ViewTable from '../../../../components/ViewTable';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import Select from '../../../../form/components/Select';
import {
  useCreateOdsActionMutation, useGetCrewListWithParamsQuery, useGetOdsActionFilesMutation, useGetOdsAtaQuery, useGetOdsDefectsQuery, useGetPortalFilesMutation, useUploadOdsActionFilesMutation, useUploadPortalFilesMutation
} from '../../../../store/session';
import { OdsActionDocumentModel, setFilesName } from '../../../../utils/constants/portalFiles';
import { getOptions, getOptionsWithKeyValue } from '../../../../utils/getOptions';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import { file, fileName } from '../../../DocumentsAndInvoices/Documents/DocumentsForm/style';
import { label } from '../../../DocumentsAndInvoices/Invoices/InvoicesForm/style';
import CreatePartsPairDrawer from '../../../Maintenance/WorkOrder/WorkOrderTaskForm/PartOffPartOn/CreatePartsPairDrawer';
import FileDrawer from '../../../TrainingModule/FileDrawer/FileDrawer';
import TypeCard from '../TypeCard';
import { columns } from './columns';
import { LC1, LC2 } from './constants';
import { columns as partsColumns } from './PartOffPartOnDrawer/columns';
import PartOffPartOnDrawer from './PartOffPartOnDrawer/PartOffPartOnDrawer';
import { defaultValues, schema } from './schema';
import {
  buttonSx,
  titlesSx,
  typeBtnActive
} from './styles';

const Actions = ({
  control, isPanel, getMainValues, setValue: setParentValue, hasPermissionsOdsEdit, isOffline, savedData
}) => {
  const {
    control: subFormControl,
    trigger,
    setValue,
    handleSubmit,
    reset,
    getValues,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const [nameFile, setFileName] = useState('');
  const [partOffTableState, setPartOffTableState] = useState([]);
  const [odsAta, setOdsAta] = useState(JSON.parse(localStorage.getItem('odsAta')) || {});
  const [odsDefects, setOdsDefects] = useState(JSON.parse(localStorage.getItem('odsDefects')) || {});
  const [crewList, setCrewList] = useState(JSON.parse(localStorage.getItem('odsAta')) || []);
  const [fileDrawerState, setFileDrawerState] = useState({
    isOpen: false,
    type: null,
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });
  const [partOffDrawerState, setPartOffDrawerState] = useState({
    isOpen: false,
    action_id: null,
    actionName: '',
    currentActionParts: {
      part_off: '',
      part_on: ''
    }
  });
  const [tablePartState, setTablePartState] = useState({
    isOpen: false
  });
  const [currentParts, setCurrentParts] = useState(null);
  const aircraft_id = getMainValues('aircraft_id');
  const ods_id = getMainValues('id');
  const filesPermission = usePermissions('ods_action_upload_file');
  const uploadPermission = getMainValues('upload_action_file') || false;
  const handleError = useHandleQueryFormError();
  const { data: odsAtaData } = useGetOdsAtaQuery();
  const { data: odsDefectsData, refetch } = useGetOdsDefectsQuery({ aircraft_id, ods_id, }, { skip: !aircraft_id });
  const { data: crewListData = [] } = useGetCrewListWithParamsQuery({ crew_line_check: 1 });
  const [create] = useCreateOdsActionMutation();
  const [getFiles, { isLoading: isLoadingCrewFiles }] = useGetPortalFilesMutation();
  const [uploadFiles, { isLoading: isAttachLoading }] = useUploadPortalFilesMutation();
  const [uploadOdsActionFiles, { isLoading: isOdsActionAttachLoading }] = useUploadOdsActionFilesMutation();
  const [getFilesLC, { isLoading: isLoadingFilesLC }] = useGetOdsActionFilesMutation();
  const {
    fields,
  } = useFieldArray({
    name: 'actions',
    keyName: 'customId',
    control,
  });

  const optionsCrewList = (crewListData || [])?.map((item) => ({
    label: item.crew_name,
    value: item.id
  }));

  const onSubmit = async (values) => {
    if (isOffline) {
      toast.error('Need to have internet connection');

      return;
    }

    const toastId = toast.loading('Loading...');
    const files = values?.upload_files[0];
    const formData = new FormData();

    Object.entries({
      ...values,
      re_performed_date: values.re_performed_date ? moment(values.re_performed_date).format('YYYY-MM-DD HH:mm:ss') : null,
      performed: values.performed ? moment(values.performed).format('YYYY-MM-DD HH:mm:ss') : null,
      ods_id,
      performed_crew_id: typeof values.performed_crew_id === 'object' ? null : values.performed_crew_id,
      re_performed_crew_id: typeof values.performed_crew_id === 'object' ? null : values.re_performed_crew_id,
      attached_parts: partOffTableState
    }).forEach(([key, value]) => {
      if (key === 'upload_files' || key === 'permission') return;

      if ((key === 'attached_parts') && value?.length) {
        formData.append('attached_parts', JSON.stringify(value));

        return;
      }
      formData.append(key, value);
    });

    if (files) {
      Object.keys(files)?.forEach((key) => {
        formData.append('upload_files[]', files[key]);
      });
    }
    const res = await create(formData);

    if (res.error) {
      handleError(res?.error, setError, getValues, toastId);

      return;
    }

    toast.success('Action was added!', {
      id: toastId
    });

    if (savedData) {
      localStorage.setItem(`${savedData.index}`, JSON.stringify({ ...savedData, actions: res.data }));
    }

    setParentValue('actions', res.data);

    reset();
    setPartOffTableState([]);
    refetch();

    setFileName('');
    setValue('performed_crew_id', {});
    setValue('re_performed_crew_id', {});
    setValue('upload_files', []);
    setValue('permission', filesPermission);
  };

  const getFileList = async (id, callback) => {
    let res;

    if (uploadPermission) {
      res = await getFilesLC(id);
    } else if (filesPermission) {
      res = await getFiles({
        model_id: id,
        model: OdsActionDocumentModel.name,
      });
    } else {
      callback([]);

      return;
    };

    if (res?.error) {
      if (res?.error?.status === 403) {
        callback([]);

        return;
      }
      toast.error('Unable to get file list.');

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const updateFiles = async (id, currFile, _, callback) => {
    if (!currFile.target.files[0]) return;

    let res;
    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    if (!uploadPermission) {
      formData.append('model_id', id);
      formData.append('model', OdsActionDocumentModel.name);
    }

    Object.keys(currFile.target.files)?.forEach((key) => {
      formData.append('upload_files[]', currFile.target.files[key]);
    });

    if (uploadPermission) {
      res = await uploadOdsActionFiles({ data: formData, id });
    } else {
      res = await uploadFiles(formData);
    }

    if (res?.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    callback();
  };

  const handleFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    if (id) {
      getFileList(id, (list) => setFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: setFilesName(list),
        name,
        currentName
      })));
    }

    setFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
  };

  const handleSetType = (value) => {
    setValue('action', value);
    trigger('action');
  };

  const handleSetDrawerState = (isOpen = false, action_id = null, actionName = '', currentActionParts = {}) => {
    setPartOffDrawerState(prev => ({
      ...prev,
      isOpen,
      action_id,
      actionName,
      currentActionParts
    }));
  };

  const handleAddPartToTable = (part, isUpdate) => {
    const partId = v4();

    if (isUpdate) {
      setPartOffTableState(prev => {
        const newTableState = prev?.map(item => {
          if (item?.part_off_number === part?.part_off_number) {
            return {
              ...item,
              ...part
            };
          }

          return item;
        });

        return newTableState;
      });
    } else {
      setPartOffTableState(prev => ([
        ...prev,
        { ...part, id: partId }
      ]));
    }
  };

  const handleRemovePartInTable = (id) => {
    setPartOffTableState(prev => {
      const newParts = prev?.filter(item => (item?.id !== id));

      return newParts;
    });
  };

  const handleOpenPartTableDrawer = (data) => {
    if (!data) {
      setCurrentParts(null);
    }

    setTablePartState(prev => ({
      ...prev,
      isOpen: data
    }));
  };

  const odsAtaOptions = getOptionsWithKeyValue(odsAta);
  const odsDefectsOptions = getOptions(odsDefects);
  const action = getValues('action');

  const handleOpenUpdateDrawer = (item) => {
    setCurrentParts(item);
    handleOpenPartTableDrawer(true);
  };

  useEffect(() => {
    if (!isOffline && crewListData && odsAtaData && odsDefectsData) {
      localStorage.setItem('crewList', JSON.stringify(crewListData));
      localStorage.setItem('odsAta', JSON.stringify(odsAtaData));
      localStorage.setItem('odsDefects', JSON.stringify(odsDefectsData));

      setCrewList(crewListData);
      setOdsDefects(odsDefectsData);
      setOdsAta(odsAtaData);
    }
  }, [isOffline, crewListData, odsDefectsData, odsAtaData]);

  useEffect(() => {
    setValue('permission', filesPermission);
  }, [filesPermission]);

  return (
    <TypeCard title="ACTIONS" variant="red" isPanel={isPanel}>
      <Grid container spacing={7} rowSpacing={6} sx={{ flexGrow: 1 }}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ width: '100%', overflow: 'auto', }}>
            <ViewTable
              list={fields}
              columns={columns({
                odsAtaOptions, odsDefectsOptions, handleFileDrawer, handleSetDrawerState
              })}
            />
          </Box>
          <FileDrawer
            open={fileDrawerState.isOpen}
            onClose={() => handleFileDrawer(false)}
            title={fileDrawerState.name}
            itemName={fileDrawerState.currentName}
            itemsList={fileDrawerState.fileList}
            uploadFile={(currFile) => updateFiles(fileDrawerState.currentId, currFile, fileDrawerState.type, () => handleFileDrawer(true, fileDrawerState.currentId, fileDrawerState.type, fileDrawerState.name, fileDrawerState.currentName))}
            isAttachLoading={isAttachLoading || isOdsActionAttachLoading}
            hasPermissions={filesPermission || uploadPermission}
            isLoadingFiles={isLoadingCrewFiles || isLoadingFilesLC}
          />

          <PartOffPartOnDrawer
            open={partOffDrawerState.isOpen}
            onClose={() => handleSetDrawerState(false, null, '')}
            id={partOffDrawerState.action_id}
            actionName={partOffDrawerState.actionName}
            currentActionParts={partOffDrawerState.currentActionParts}
            ods={getMainValues() || {}}
            optionsCrewList={optionsCrewList}
          />
        </Grid>

        <Grid item xs={12} sm={12} sx={titlesSx}>
          ADD ACTION PERFORMED
        </Grid>

        <Grid item xs={12} lg={4}>
          <Button
            sx={{ ...buttonSx, ...(action === '' && typeBtnActive ) }}
            onClick={() => handleSetType('')}
          >
            Action
          </Button>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Button
            sx={{ ...buttonSx, ...(action === LC1 && typeBtnActive ) }}
            onClick={() => handleSetType(LC1)}
          >
            LC1
          </Button>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Button
            sx={{ ...buttonSx, ...(action === LC2 && typeBtnActive ) }}
            onClick={() => handleSetType(LC2)}
          >
            LC2
          </Button>
        </Grid>

        <Grid item xs={6}>
          <FormGroup label="ACTION" required hasError={!!errors.action}>
            <Controller
              name="action"
              control={subFormControl}
              render={({ field }) => (
                <Input
                  {...field}
                />
              )}
            />

            <FieldError error={errors.action} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormGroup label="ATA" hasError={!!errors.ata_id}>
            <Controller
              name="ata_id"
              control={subFormControl}
              render={({ field }) => (
                <Select
                  options={odsAtaOptions}
                  {...field}
                />
              )}
            />

            <FieldError error={errors.ata_id} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormGroup label="DEFECT" hasError={!!errors.defect_id}>
            <Controller
              name="defect_id"
              control={subFormControl}
              render={({ field }) => <Select options={odsDefectsOptions} {...field} />}
            />

            <FieldError error={errors.defect_id} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormGroup label="NOTE" hasError={!!errors.note}>
            <Controller
              name="note"
              control={subFormControl}
              render={({ field }) => <Input {...field} />}
            />

            <FieldError error={errors.note} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormGroup label="PERFORMED BY" hasError={!!errors.performed_crew_id}>
            <Controller
              name="performed_crew_id"
              control={subFormControl}
              render={({ field }) => (
                <Autocomplete
                  freeSolo
                  value={field.value ? crewList?.filter((AirportItem) => AirportItem.id === field.value)[0] : null}
                  options={crewList}
                  getOptionLabel={(option) => `${option.crew_shortname} ${option.crew_name}` || null}
                  isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputRef={field.ref}
                    />
                  )}
                  onChange={(e, data) => {
                    field.onChange(data?.id);
                  }}
                />
              )}
            />

            <FieldError error={errors.performed_crew_id} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormGroup label="DATE" hasError={!!errors.performed}>
            <Controller
              name="performed"
              control={subFormControl}
              render={({ field }) => (
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm"
                  showMonthDropdown
                  showYearDropdown
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 'performed');
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
            />

            <FieldError error={errors.performed} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormGroup label="RE-INSPECTED BY" hasError={!!errors.re_performed_crew_id}>
            <Controller
              name="re_performed_crew_id"
              control={subFormControl}
              render={({ field }) => (
                <Autocomplete
                  freeSolo
                  value={field.value ? crewList?.filter((AirportItem) => AirportItem.id === field.value)[0] : null}
                  options={crewList}
                  getOptionLabel={(option) => `${option.crew_shortname} ${option.crew_name}` || ''}
                  isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputRef={field.ref}
                    />
                  )}
                  onChange={(e, data) => {
                    field.onChange(data?.id);
                  }}
                />
              )}
            />

            <FieldError error={errors.re_performed_crew_id} />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormGroup label="RE-INSPECTED DATE" hasError={!!errors.re_performed_date}>
            <Controller
              name="re_performed_date"
              control={subFormControl}
              render={({ field }) => (
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm"
                  showMonthDropdown
                  showYearDropdown
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 're_performed_date');
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
            />

            <FieldError error={errors.re_performed_date} />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '1rem 0' }}>
            <Typography fontWeight={600} variant="h5">Part Off & Part On Table</Typography>
            <Button sx={{ color: '#fff', padding: '6px 2rem' }} onClick={() => handleOpenPartTableDrawer(true)}>+ Add</Button>
          </Box>
          <DataGrid
            rows={partOffTableState}
            columns={partsColumns({
              handleRemovePartInTable,
              optionsCrewList,
              hasPermissionsWoEdit: true,
              handleOpenUpdateDrawer
            })}
            height={110}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            hideFooterPagination
            getRowId={(row) => row?.id}
            getRowHeight={() => 'auto'}
            disableColumnSorting
            disableColumnFilter
            rowCount={partOffTableState?.length}
            sx={{
              ...TableStyle,
              '& .MuiDataGrid-iconButtonContainer': {
                width: '30px',
                position: 'absolute',
                right: '0',
                top: '-5px',
                zIndex: '100000',
                visibility: 'hidden!important',
              }
            }}
          />
          <CreatePartsPairDrawer
            open={tablePartState.isOpen}
            onClose={() => handleOpenPartTableDrawer(false)}
            addPartCallback={handleAddPartToTable}
            partOffTableState={partOffTableState}
            currentParts={currentParts}
            isToOds
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={maxWidth800px ? {
            display: 'flex',
            flexDirection: 'column-reverse',
            gap: 2
          } : {
            display: 'grid', gridTemplateColumns: '12rem 1fr', columnGap: '2rem', alignItems: 'center', overflow: 'hidden'
          }}
        >
          <Box>
            <Button disabled={!hasPermissionsOdsEdit} sx={{ ...buttonSx, maxHeight: '3rem' }} type="submit" onClick={handleSubmit(onSubmit)}>Add Action</Button>
          </Box>

          <Box sx={file}>
            <FormGroup sx={label} required label="File" hasError={!!errors.upload_files}>
              <Controller
                name="upload_files"
                control={control}
                render={({ field }) => (
                  <Upload
                    {...field}
                    accept=".doc,.docx,image/*,.pdf"
                    disabled={(!uploadPermission && !filesPermission) || isAttachLoading}
                    handleUpload={(e) => {
                      if (!e.target.files[0]) return;
                      setValue('upload_files', [e.target.files]);
                      field.onChange(e);
                      setFileName(e.target.files[0].name);
                      trigger('upload_files');
                    }}
                    title="Upload file"
                  />
                )}
              />
              <FieldError error={errors.file} />
            </FormGroup>
            <Typography sx={{
              ...fileName, overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap'
            }}
            >
              {nameFile}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </TypeCard>
  ); };

export default Actions;

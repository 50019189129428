import { Chip } from '@mui/material';
import React from 'react';
import TextEdit from '../../components/EditableTable/TextEdit';
import { TYPE_SELECT } from '../../components/EditableTable/constants';

export const INCOME_STATUS = [
  { label: 'Paid', value: 'paid' },
  { label: 'Unpaid', value: 'unpaid' },
  { label: 'Canceled', value: 'canceled' }
];

export const INVOICE_TYPE = [
  { label: 'Proforma', value: 'proforma' },
  { label: 'Electronic Invoice', value: 'e_invoice' },
  { label: 'Final Invoice', value: 'final_invoice' },
];

export const PAYMENT_METHOD = [
  { label: 'Bank Transfer', value: 'bank_transfer' },
  { label: 'Cash', value: 'cash' },
];

export const FILE_FILTER = [
  { label: 'Files', value: 'with' },
  { label: 'No Files', value: 'without' },
  { value: '', label: '-' }
];

export const handleStatusChip = (status, row, handleOpen, hasPermission) => {
  // eslint-disable-next-line default-case
  switch (status) {
    case INCOME_STATUS[0].value:
      // eslint-disable-next-line react/react-in-jsx-scope
      return (
        <Chip
          label={(
            <TextEdit
              handleEdit={(...params) => handleOpen(...params, 'status', row)}
              text={INCOME_STATUS[0].value.toLocaleUpperCase()}
              labelInput="Status"
              options={INCOME_STATUS}
              activeSelect={INCOME_STATUS[0].value}
              disabled={!hasPermission}
              type={TYPE_SELECT}
              style={{ color: '#fff', textDecoration: 'none!important' }}
            />
)}
          color="success"
          sx={{ fontWeight: 'bolder' }}
        />
      );
    case INCOME_STATUS[1].value:
      return (
        <Chip
          label={(
            <TextEdit
              handleEdit={(...params) => handleOpen(...params, 'status', row)}
              text={INCOME_STATUS[1].value.toLocaleUpperCase()}
              labelInput="Status"
              options={INCOME_STATUS}
              activeSelect={INCOME_STATUS[1].value}
              disabled={!hasPermission}
              type={TYPE_SELECT}
              style={{ color: '#fff', textDecoration: 'none!important' }}
            />
          )}
          color="warning"
          sx={{ fontWeight: 'bolder', }}
        />
      );

    default:
      return '';
  }
};

export const TRANSACTION_TYPE_FILTER = [
  { label: 'Invoice', value: 'Invoice' },
  { label: 'Proforma', value: 'Proforma' },
  { label: 'Document', value: 'Document' },
  { value: '', label: '-' },
];

export const TRANSACTION_STATUS_FILTER = [
  { label: 'Open', value: 'Open' },
  { label: 'Closed', value: 'Closed' },
  { value: '', label: '-' },
];

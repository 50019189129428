import React from 'react';
import MuiMenu from '@mui/material/Menu';
import { menuSx } from './styles';

const DropDownMenu = ({
  children, sx, anchorOrigin = {}, transformOrigin = {}, handleClose, ...props
}) => (
  <MuiMenu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
      ...anchorOrigin,
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
      ...transformOrigin,
    }}
    sx={{ ...menuSx, ...sx }}
    onClose={handleClose}
    {...props}
  >
    {children}
  </MuiMenu>
);

export default DropDownMenu;

import React, { useState } from 'react';
import {
  Box, Card, Divider, Typography, CardContent, Autocomplete, TextField
} from '@mui/material';
import Drawer from '../../components/Drawer';
import LinkButton from '../../components/LinkButton';
import Button from '../../components/Button';
import Table from './Table/Table';
import toast from 'react-hot-toast';
import { style, styleMobile } from './styles';
import {
  useGetDailyAricraftDataQuery,
  useUpdateDailyAricraftDataMutation,
  useSendDailyEmailMutation,
} from '../../store/session';

const DailyAvailability = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [emails, setEmails] = useState([]);

  const [sendEmails, { loading: sendEmailsLoading }] = useSendDailyEmailMutation();

  const toogleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleEmailsChange = (event, values) => {
    setEmails(values);
  };

  const handleSendEmails = async () => {
    const toastId = toast.loading('Loading...');
    const res = await sendEmails({ emails });

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    toogleDrawer();
  };

  return (
    <Card sx={{
      mt: 3,
      padding: '1rem',
    }}
    >
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h3">Daily Availability</Typography>
          <LinkButton path="/daily_availability/mails">
            <Button sx={{ width: '100%' }} title="Edit Emails" />
          </LinkButton>
        </Box>
      </Box>
      <Divider sx={{ mt: 5 }} />
      <Box>
        <Table useGetHook={useGetDailyAricraftDataQuery} useUpdateHook={useUpdateDailyAricraftDataMutation} onClickSendRooster={toogleDrawer} useSendHook={useSendDailyEmailMutation} />
      </Box>
      <Drawer
        open={isOpen}
        onClose={() => toogleDrawer()}
      >
        <Card sx={{
          mt: 3,
          padding: '1rem'
        }}
        >
          <Box>
            <Typography variant="h3">Enter Emails</Typography>
          </Box>
          <CardContent sx={{ mt: 5 }}>
            <Autocomplete
              multiple
              id="email-autocomplete"
              freeSolo
              options={emails}
              value={emails}
              onChange={handleEmailsChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Emails"
                  placeholder="Enter email addresses"
                />
              )}
            />
            {' '}
            <Button loading={sendEmailsLoading} sx={{ mt: '1rem' }} title="Send" onClick={() => handleSendEmails()} />
          </CardContent>
        </Card>
      </Drawer>
    </Card>
  ); };
export default DailyAvailability;

import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import ButtonIcon from '../../../../../components/ButtonIcon';
import TextEdit from '../../../../../components/EditableTable/TextEdit';
import { TYPE_SELECT } from '../../../../../components/EditableTable/constants';
import { StyledDelete, StyledPencil } from '../../../../../components/EditableTable/styles';
import { TextWrap } from '../../../../Logistics/style';

export const columns = ({
  optionsCrewList,
  handleOpenDeleteModal,
  handleOpen,
  hasPermissionsWoEdit,
  handleOpenUpdateDrawer = () => {},
}) => ([
  {
    flex: 0.1,
    field: 'part_aircraft_body_id',
    minWidth: 140,
    headerName: 'Aircraft Body Part ID',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography>Aircraft Body Part ID</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        <Chip label={row?.part_aircraft_body_id ?? 'N/A'} color="secondary" variant="outlined" />
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'part_id',
    minWidth: 100,
    headerName: 'Part ID',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography>Part ID</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        <Chip label={row?.part_id ?? 'N/A'} color="secondary" variant="outlined" />
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'part_name',
    minWidth: 140,
    headerName: 'Part Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography>Part Name</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ ...TextWrap, textTransform: 'capitalize' }}>
        {row?.part_name ? row?.part_name : <Chip label={row?.part_name ?? 'N/A'} color="secondary" variant="outlined" />}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'storage',
    minWidth: 150,
    headerName: 'Storage',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography>Storage</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        <Chip label={row?.storage ?? 'N/A'} color="secondary" variant="outlined" />
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'part_off_number',
    minWidth: 180,
    headerName: 'Part Off Number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography>Part Off Number</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.part_off_number}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'serial_off_number',
    minWidth: 180,
    headerName: 'Serial Off Number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography>Serial Off Number</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.serial_off_number}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'part_off_text',
    minWidth: 180,
    headerName: 'Part Off Text',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography>Part Off Text</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.part_off_text}</Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'part_on_number',
    minWidth: 150,
    headerName: 'Part On Number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography>Part On Number</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ ...TextWrap, textTransform: 'capitalize' }}>
        {row?.part_on_number ? row?.part_on_number : <Chip label={row?.part_on_number ?? 'N/A'} color="secondary" variant="outlined" />}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'serial_on_number',
    minWidth: 150,
    headerName: 'Serial On Number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography>Serial On Number</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      row?.serial_on_number ? (
        <Box>
          <Typography sx={TextWrap}>{row?.serial_on_number}</Typography>
        </Box>
      ) : (
        <Chip label="N/A" color="secondary" variant="outlined" />
      )
    ),
  },
  {
    flex: 0.2,
    field: 'part_on_text',
    minWidth: 140,
    headerName: 'Part On Text',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography>Part On Text</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={TextWrap}>
        {row?.part_on_text ? row?.part_on_text : <Chip label={row?.part_on_text ?? 'N/A'} color="secondary" variant="outlined" />}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'performed_by',
    minWidth: 180,
    headerName: 'Performed by',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography>Performed by</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'performed_by', row)}
        text={row.performed_by_name}
        labelInput="Enter Performed by"
        options={optionsCrewList}
        disabled={!hasPermissionsWoEdit}
        activeItem={row.performed_by}
        type={TYPE_SELECT}
      />
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography>Actions</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <>
        {(!row?.part_id) && (
        <ButtonIcon onClick={() => handleOpenUpdateDrawer(row)}>
          <StyledPencil />
        </ButtonIcon>
        )}
        <ButtonIcon disabled={!hasPermissionsWoEdit} onClick={() => handleOpenDeleteModal(row.id)}>
          <StyledDelete />
        </ButtonIcon>
      </>
    ),
  },
]);

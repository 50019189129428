import { HeaderName } from '../../../components/EditableTable/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import React from 'react';
import { headerCellContainer, StyledDelete, StyledPencil } from '../../Flights/list/styles';
import LinkButton from '../../../components/LinkButton';

export const columns = ({
  handleFilter,
  handleOpenDeleteModal,
  filters,
}) => ([
  {
    flex: 0.2,
    minWidth: 250,
    field: 'name',
    headerName: 'Group name',
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography style={HeaderName}>Group name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.name || ''}
          value="name"
          placeholder="Group name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
        {row?.name}
      </Typography>
    ),
  },
  {
    field: 'actions',
    maxWidth: 350,
    headerName: 'Actions',
    sortable: false,
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography sx={{ ...HeaderName, mb: '12px' }}>Actions</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <>
        <LinkButton path={`/manage-users-groups/${row.id}`}>
          <StyledPencil />
        </LinkButton>
        {' '}
        <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
      </>
    ),
  },
]);

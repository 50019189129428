import { Card, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import {
  useCopyFleetMutation,
  useCreateFlightFromFleetMutation,
  useDeleteQuotationMutation,
  useGetQuotationsListQuery
} from '../../../store/session';
import { columns } from './columns';
import {
  StyledLink
} from './styles';

const QuotationTable = ({ title }) => {
  const navigate = useNavigate();
  const [refetchTable, setRefetchTable] = useState(0);
  const [createFlight] = useCreateFlightFromFleetMutation();
  const [copyFleet] = useCopyFleetMutation();

  const handleRefetchTable = () => {
    setRefetchTable(prev => prev + 1);
  };

  const handleFlight = (id) => {
    const request = createFlight(id);

    toast.promise(request, {
      loading: 'Loading',
      success: 'Success',
      error: 'Something went wrong',
    }).then((result) => {
      navigate(`/flights/${result.data.id}`);
    });
  };

  const handleCopyFleet = (id) => {
    const request = copyFleet(id);

    toast.promise(request, {
      loading: 'Loading',
      success: 'Success',
      error: 'Something went wrong',
    }).then((result) => {
      window.open(`${process.env.REACT_APP_URL}quotations/update/${result.data.id}`);
      handleRefetchTable();
    });
  };

  return (
    <Grid>
      <Grid>
        <Card>
          <Box
            sx={{
              p: 5,
              pb: 3,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="h3"
              noWrap
              sx={{ textTransform: 'capitalize' }}
            >
              {title}
            </Typography>
            <Link style={StyledLink} to="/quotations/create">
              <Button title="Add Quotation" />
            </Link>
          </Box>
          <EditableTable
            getRowId={(row) => row.fleet_id}
            useHook={useGetQuotationsListQuery}
            useHookDelete={useDeleteQuotationMutation}
            handleCopyFleet={handleCopyFleet}
            handleFlight={handleFlight}
            getRowHeight={() => 'auto'}
            sortByType="DESC"
            sortByActive="fleet_id"
            columns={columns}
            refetchTable={refetchTable}
          />

        </Card>
      </Grid>
    </Grid>
  );
};

export default QuotationTable;

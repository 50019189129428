import moment from 'moment';

export const useIsDeprecated = (dueDate) => {
  const dueDateMoment = moment(dueDate);
  const now = moment();

  if (dueDateMoment.isBefore(now)) {
    return true;
  }

  const daysDifference = dueDateMoment.diff(now, 'days');

  return daysDifference <= 3;
};

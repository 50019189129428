import React from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableBodyBase from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import {
  COLORS,
  DATE_FORMAT,
  DAY_FORMAT, DAY_IN_WEEK, MONTH_FORMAT, WEEK
} from '../constants';
import moment from 'moment';
import {
  boldBorderLeft, boldBorderRight, borderLeft, borderRight, cellSx, dataCell, dateCell
} from '../styles';
import {
  subTitleSx, textSx, titleSx
} from './styles';

const TableBody = ({
  data, date, index, firstDayInMonth, daysInMonth
}) => (
  <TableBodyBase>
    {index === 0 && Array.from({ length: WEEK[firstDayInMonth] }).map(() => (
      <TableRow>
        <TableCell />
        <TableCell />

        {data?.map(item => (
          item.items.length ? item?.items?.map((subItem, i) => (
            <TableCell
              align="center"
              sx={{
                ...cellSx,
                ...borderRight,
                ...(i === 0 && boldBorderLeft ),
                ...(i === item.items.length - 1 && boldBorderRight)
              }}
            />
          )) : <TableCell />
        ))}
      </TableRow>
    ))}

    {Array.from({ length: index === 0 ? DAY_IN_WEEK - WEEK[firstDayInMonth] : DAY_IN_WEEK }).map((_, i) => {
      const dayNumber = i + (index * (DAY_IN_WEEK - WEEK[firstDayInMonth])) + (index > 1 ? WEEK[firstDayInMonth] * (index - 1) : 0);
      const cellDate = moment(date, 'MMM-DD-YYYY');
      cellDate.add(dayNumber, 'days');
      cellDate.add(2, 'hours');

      return (
        dayNumber < daysInMonth && (
        <TableRow>
          <TableCell sx={{ ...cellSx, ...dateCell }} align="center">
            {`${dayNumber + 1} ${moment(date, 'MMM-DD-YYYY').format(MONTH_FORMAT)}`}
          </TableCell>

          <TableCell
            align="center"
            sx={{ ...cellSx, ...dateCell, ...borderLeft }}
          >
            {cellDate.format(DAY_FORMAT)}
          </TableCell>

          {data?.map(item => (
            item.items.length ? item?.items?.map((subItem, subIndex) => {
              const currentItem = subItem.values[cellDate.format(DATE_FORMAT)];
              const values = currentItem && currentItem[Object.keys(currentItem)[0]];

              return (
                <TableCell
                  align="center"
                  sx={{
                    ...dataCell,
                    ...(subIndex === 0 && boldBorderLeft),
                    ...(subIndex === item.items.length - 1 && boldBorderRight),
                    ...((values) && { backgroundColor: COLORS[subIndex % item.items.length] })
                  }}
                >
                  {currentItem && Object.values(currentItem).map((element) => {
                    const {
                      title, sub_title, hops, flight_id
                    } = element || {};

                    return (
                      <Box
                        sx={{}}
                      >
                        {title && (
                        <Link
                          to={`/flights/${flight_id}`}
                          style={titleSx}
                        >
                          {title.replace('#', '')}
                        </Link>
                        )}

                        {sub_title && <Typography sx={subTitleSx}>{sub_title}</Typography>}

                        {hops?.map(({ arr, dep }) => (
                          <>
                            <Typography sx={textSx}>{dep}</Typography>
                            <Typography sx={textSx}>{arr}</Typography>
                          </>
                        ))}
                      </Box>
                    );
                  })}
                </TableCell>

              ); }) : <TableCell sx={dataCell} />
          ))}
        </TableRow>
        )
      ); })}
  </TableBodyBase>
);

export default TableBody;

import {
  Autocomplete,
  Checkbox,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import DropDownMenu from '../../DropDownMenu';
import Input from '../../Input/Input';
import SelectDate from '../SelectDate';
import SelectDuration from '../SelectDuration';
import {
  TYPE_CHECK_BOX,
  TYPE_INPUT,
  TYPE_MULTI_INPUT,
  TYPE_SELECT,
  TYPE_SELECT_DATE
} from '../constants';
import {
  BlockIcons,
  BlockIconsCheckBox,
  CheckboxStyle,
  ContainerInput,
  InputStyle,
  StyledCheck,
  StyledClear,
  datePickerContainer,
  dropdownMenuSx,
  listContainer,
} from './styles';

const DropDownInput = ({
  open,
  onClose,
  anchorEl,
  text,
  type,
  options,
  activeSelect,
  selectedItem,
  column,
  updateCrew,
  refetch,
  dateProps,
}) => {
  const [values, setValues] = useState({});
  const [checked, setChecked] = useState(false);
  const [checkedDate, setCheckedDate] = useState(false);
  const [autocompleteValues, setAutocompleteValues] = useState(
    activeSelect || []
  );

  useEffect(() => {
    if (type === 'TRAINING_AUTOCOMPLETE') {
      setAutocompleteValues(activeSelect);
    }
  }, [activeSelect]);

  const handleChangeItem = (value) => {
    if (
      column === 'crew_expires'
      || column === 'due_payment_date'
      || column === 'payment_date'
      || column === 'training_date'
      || column === 'review_date'
    ) {
      setValues({
        [column]: value ? moment(value).format('YYYY-MM-DD') : null,
      });
    } else if (column === 'expiry_date') {
      if (value.target?.checked) {
        setValues({ [column]: '9999-09-09' });
      } else {
        setValues({
          [column]: value ? moment(value).format('YYYY-MM-DD') : null,
        });
      }
    } else if (column === 'performed') {
      setValues({
        [column]: value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null,
      });
    } else if (column === 'training_start' || column === 'training_end') {
      const [dateType, date] = Object.entries(value)[0];

      const object = {};

      if (dateType === 'training_start') {
        const trainingEnd = value?.training_end
          || values?.training_end
          || selectedItem?.training_duration?.training_end;
        object.training_start = date
          ? moment(date).format('YYYY-MM-DD HH:mm:ss')
          : null;
        object.training_end = trainingEnd
          ? moment(trainingEnd).format('YYYY-MM-DD HH:mm:ss')
          : null;
      } else if (dateType === 'training_end') {
        const trainingStart = value?.training_start
          || values?.training_start
          || selectedItem?.training_duration?.training_start;
        object.training_end = date
          ? moment(date).format('YYYY-MM-DD HH:mm:ss')
          : null;
        object.training_start = trainingStart
          ? moment(trainingStart).format('YYYY-MM-DD HH:mm:ss')
          : null;
      }

      setValues({ ...object });
    } else {
      setValues({ [column]: value });
    }
  };

  const handleChange = async (event) => {
    setChecked(event.target.checked);
    handleChangeItem(event.target.checked ? 1 : 0);
    refetch();
  };

  const handleSave = async () => {
    if (Object.keys(values).length) {
      onClose();

      let res;

      if (column === 'training_start' || column === 'training_end') {
        res = await updateCrew({
          data: { ...values },
          id: selectedItem.id || selectedItem.action_id,
        });
      } else {
        res = await updateCrew({
          data: { ...values },
          id: selectedItem.id || selectedItem.action_id,
        });
      }

      if (res.error) {
        toast.error(res.error.data.message || 'Something went wrong');

        return;
      }

      setTimeout(() => {
        setCheckedDate(false);
      }, 500);

      toast.success('Successfully updated!');
      refetch();
      setValues({});
    }
  };

  const handleClose = () => {
    onClose();
    setValues({});
    setTimeout(() => {
      setCheckedDate(false);
    }, 500);
  };

  const handleChangeSelect = async (e) => {
    onClose();
    const res = await updateCrew({
      data: { [column]: e.target.value },
      id: selectedItem.id || selectedItem.action_id,
    });

    if (res.error) {
      toast.error(res.error.data.message || 'Something went wrong');

      return;
    }
    toast.success('Successfully updated!');

    refetch();
  };

  const handleChangeAutocomplete = async (value) => {
    onClose();
    const res = await updateCrew({
      data: { [column]: value },
      id: selectedItem.id || selectedItem.action_id,
    });
    if (res.error) {
      toast.error(res.error.data.message || 'Something went wrong');

      return;
    }
    toast.success('Successfully updated!');

    refetch();
  };

  useEffect(() => {
    setChecked(!!text);
    if (type === 'DATE_SELECT_OR_N/A' && text === 'N/A') {
      setCheckedDate(true);
    }
  }, [text]);

  return (
    <DropDownMenu
      sx={dropdownMenuSx}
      open={open}
      handleClose={handleClose}
      anchorEl={anchorEl}
    >
      <Box>
        <Box sx={listContainer}>
          {type === TYPE_CHECK_BOX && (
            <Box sx={ContainerInput}>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={CheckboxStyle}
              />
              Yes
              <Box sx={BlockIconsCheckBox}>
                <StyledCheck onClick={handleSave} />
                <StyledClear onClick={handleClose} />
              </Box>
            </Box>
          )}

          {type === 'TRAINING_AUTOCOMPLETE' && (
            <Box sx={{ ...ContainerInput }}>
              <Autocomplete
                style={{ width: 500 }}
                multiple
                options={options}
                getOptionLabel={(option) => {
                  const selectedOption = options.find(
                    (opt) => opt?.crew_id === option?.crew_id
                  );

                  return selectedOption ? selectedOption?.label : '';
                }}
                value={autocompleteValues}
                onChange={(_, newValue) => {
                  setAutocompleteValues(
                    newValue.map((e) => ({ crew_id: e.crew_id }))
                  );
                }}
                isOptionEqualToValue={(option, value) => option?.crew_id === value?.crew_id}
                renderInput={(params) => (
                  <TextField {...params} label="Choose options" />
                )}
                id="participants"
                disableCloseOnSelect
              />

              <Box sx={BlockIcons}>
                <StyledCheck
                  onClick={() => handleChangeAutocomplete(autocompleteValues)}
                />
                <StyledClear onClick={handleClose} />
              </Box>
            </Box>
          )}

          {(type === TYPE_INPUT || type === TYPE_MULTI_INPUT) && (
            <Box sx={ContainerInput}>
              <Input
                size="small"
                defaultValue={text || text === 0 ? text : ''}
                sx={InputStyle}
                {...(type === TYPE_MULTI_INPUT && { multiline: true, rows: 4 })}
                onChange={(e) => {
                  handleChangeItem(e.target.value);
                }}
              />
              <Box sx={BlockIcons}>
                <StyledCheck onClick={handleSave} />
                <StyledClear onClick={handleClose} />
              </Box>
            </Box>
          )}

          {type === TYPE_SELECT && (
            <Box sx={ContainerInput}>
              <Select
                value={activeSelect || 0}
                size="small"
                onChange={handleChangeSelect}
              >
                {options?.map((item) => (
                  <MenuItem key={item.id || item.value} value={item.value || 0}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>

              <StyledClear onClick={handleClose} />
            </Box>
          )}

          {type === 'DATE_SELECT_OR_N/A' && (
            <Box sx={{ ...ContainerInput, ...datePickerContainer }}>
              <SelectDate
                disabled={checkedDate}
                value={
                  values[column]
                  || (text === 'N/A' ? '9999-09-09' : text)
                  || moment().toDate()
                }
                onChange={handleChangeItem}
                {...dateProps?.[column]}
              />
              <FormControlLabel
                checked={checkedDate}
                onChange={(e) => {
                  setCheckedDate(e.target.checked);
                  setTimeout(() => {
                    handleChangeItem(e);
                  }, 0);
                }}
                control={<Switch color="primary" />}
                label="N/A"
                labelPlacement="bottom"
              />
              <Box sx={BlockIcons}>
                <StyledCheck onClick={handleSave} />
                <StyledClear onClick={handleClose} />
              </Box>
            </Box>
          )}

          {type === 'DURATION_INPUT' && (
            <Box sx={{
              ...ContainerInput,
              ...datePickerContainer,
              '& .react-datepicker-popper': {
                transform: 'translate(-115px, -10px)!important;',
                width: '377px !important',
                zIndex: '-1!important'
              }
            }}
            >
              <SelectDuration
                value={{
                  key: column,
                  data:
                    values?.[column]
                    || selectedItem?.training_duration?.[column]
                    || null,
                }}
                onChange={handleChangeItem}
                {...dateProps?.[column]}
              />

              <Box sx={BlockIcons}>
                <StyledCheck onClick={handleSave} />
                <StyledClear onClick={handleClose} />
              </Box>
            </Box>
          )}

          {type === TYPE_SELECT_DATE && (
            <Box sx={{
              ...ContainerInput,
              ...datePickerContainer,
              ...((column === 'training_date') && {
                '& .react-datepicker-popper': {
                  transform: 'translate(245px, 80px)!important'
                }
              })
            }}
            >
              <SelectDate
                value={values[column] || text || moment().toDate()}
                onChange={handleChangeItem}
                {...dateProps?.[column]}
              />

              <Box sx={BlockIcons}>
                <StyledCheck onClick={handleSave} />
                <StyledClear onClick={handleClose} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </DropDownMenu>
  );
};

export default DropDownInput;

import React from 'react';
import {
  TableCell, TableContainer, TableHead, TableBody, TableRow, Table
} from '@mui/material';
import { columns } from './constants';
import { useCrewForm } from './useCrewForm';
import { header, body } from './style';

const TimeForm = () => {
  const {
    flightData,
  } = useCrewForm();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <TableCell sx={header}>
                {item.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {flightData.map((item, key) => (
            <TableRow key={key}>
              <TableCell sx={body}>
                {item.departure}
              </TableCell>
              <TableCell sx={body}>
                {item.arriving}
              </TableCell>
              <TableCell sx={body}>
                {item.flightNo}
              </TableCell>
              <TableCell sx={body}>
                {item.captain}
              </TableCell>
              <TableCell sx={body}>
                {item.fo}
              </TableCell>
              <TableCell sx={body}>
                {item.engineer}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

    </TableContainer>
  );
};

export default TimeForm;

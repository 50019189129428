import { RefreshRounded } from '@mui/icons-material';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import {
  Box,
  Card, Grid, Typography, useMediaQuery
} from '@mui/material';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import { BoxIcon } from '../../../components/Icons';
import UserIcon from '../../../components/Navigation/UserIcon';
import { useGetSuppliersQuery, useRemoveSupplierMutation } from '../../../store/slices/logisticsSlice';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { btnS } from '../../Invoices/style';
import {
  GreenButton,
  TextWithIcon,
  YellowButton
} from '../style';
import NewSupplier from './NewSupplier';
import { columns } from './columns';

const Suppliers = () => {
  const [openSupplierDrawer, setOpenSupplierDrawer] = useState(false);
  const [refresh, setRefresh] = useState(0);
  
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const hasWritePermission = usePermissions('logistics_suppliers_write');

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleCloseSupplierDrawer = () => {
    setOpenSupplierDrawer(false);
  };

  const handleAccessError = () => {
    toast.error('You do not have access!');
  };

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={maxWidth800px ? {
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            padding: '1rem',
            mt: 3
          } : {
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '1rem', mt: 3
          }}
          >
            <Box>
              <Typography noWrap variant="h3" sx={TextWithIcon}>
                <UserIcon
                  icon={StoreMallDirectoryIcon}
                  componentType="horizontal-menu"
                  iconProps={{ sx: { fontSize: '3rem' } }}
                />
                Vendors
              </Typography>
            </Box>
            <Box sx={maxWidth800px ? {
              display: 'flex', gap: '10px', flexDirection: 'column'
            } : { display: 'flex', gap: '10px' }}
            >
              <Button
                startIcon={<RefreshRounded />}
                title="Refresh"
                sx={{ ...(!maxWidth800px && { ml: 2 }), ...(maxWidth800px && btnS), ...YellowButton }}
                onClick={handleRefresh}
              />
              <Button
                sx={GreenButton}
                startIcon={<BoxIcon />}
                disabled={!hasWritePermission}
                onClick={() => {
                  if (!hasWritePermission) handleAccessError();
                  else setOpenSupplierDrawer(true); }}
                title="Create"
              />
            </Box>
          </Card>

          <Card sx={{
            mt: 3,
            padding: '1rem'
          }}
          >
            <EditableTable
              useHook={useGetSuppliersQuery}
              useHookDelete={useRemoveSupplierMutation}
              columns={columns}
              sortByType="DESC"
              sortByActive="id"
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.id}
              tableParams={{
                refresh
              }}
              hasWritePermission={hasWritePermission}
              handleAccessError={handleAccessError}
            />
          </Card>

        </Grid>
      </Grid>

      <NewSupplier open={openSupplierDrawer} onClose={handleCloseSupplierDrawer} handleRefresh={handleRefresh} />
    </>

  );
};

export default Suppliers;

import { Typography } from '@mui/material';
import { StyledDelete, StyledPencil } from '../../../components/EditableTable/styles';

export const columns = ({
  navigate,
  handleOpenDeleteModal
}) => ([
  {
    flex: 0.2,
    minWidth: 70,
    field: 'id',
    headerName: 'ID',
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        #
        {' '}
        {row?.id ?? ''}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 250,
    field: ' is_active',
    headerName: 'Is Active',
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
        {row?.is_active ?? 'No Name'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    minWidth: 250,
    field: 'aircraft_registration',
    headerName: 'Registration',
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
        {row?.aircraft_registration ?? 'No Name'}
      </Typography>
    ),
  },
  {
    field: 'actions',
    maxWidth: 350,
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <StyledPencil onClick={() => {
          navigate(`/aircraft/profiles/${row.id}`, { replace: false });
        }}
        />
        {' '}
        <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
      </>
    ),
  },
]);
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Grid, Typography } from '@mui/material';
import { omitBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { INVOICE_STATUS } from '../../constans/Statistics';
import DatePicker from '../../form/components/DatePicker';
import FormGroup from '../../form/components/FormGroup';
import Select from '../../form/components/Select';
import { useGetAirListMutation, useGetAirTypeListQuery } from '../../store/session';
import { parseLocationSearch, stringifyLocationSearch } from '../../utils/locationSearch';
import { selectOptionFormat } from '../../utils/number';
import { setDateValue, validateDatePickerValue } from '../../utils/setDateValue';
import Button from '../Button';
import { defaultValues, schema } from './schema';

export const defaultOptions = [{ value: 0, label: 'All' }];

const Search = ({
  useHook = () => [], fixedCacheKey, clearPath, searchName
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [filters, setFilters] = useState(parseLocationSearch(location.search));
  const [aircraftListOptions, setAircraftListOptions] = useState([]);
  const [startDate, setStartDate] = useState(parseLocationSearch(location.search));

  const {
    handleSubmit, control, setValue, reset, formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const { data: aircraftTypeList } = useGetAirTypeListQuery();

  const [getAirList, { data: aircraftList, isLoading: isAircraftListLoading }] = useGetAirListMutation();
  const [getStatistic, { reset: hookReset }] = useHook({
    fixedCacheKey
  });

  const aircraftTypeListOptions = [
    ...defaultOptions,
    ...selectOptionFormat(aircraftTypeList, 'aircraft_type_name'),
  ];

  const onSubmit = async (data) => {
    const newFilters = {
      ...data,
      aircraft_type: data?.aircraft_type === '0' ? '' : data?.aircraft_type,
      aircraft_id: data?.aircraft_id === '0' ? '' : data?.aircraft_id
    };

    const result = omitBy(newFilters, (v) => v === null || v === undefined || v === '' || v === 0);

    if (result) {
      setFilters(result);

      const locationSearch = stringifyLocationSearch(result);

      navigate(location.pathname + locationSearch);
    }

    await getStatistic(stringifyLocationSearch(result));
  };

  const handleSelectType = async (typeId) => {
    if (typeId) {
      await getAirList(typeId);
    }

    if (!typeId) {
      setAircraftListOptions(defaultOptions);
      setValue('aircraft_id', 0);
    }
  };

  const handleClear = async () => {
    reset({ aircraft_id: '', aircraft_type: '' });
    navigate(clearPath, { replace: true });
    hookReset();
  };

  useEffect(() => {
    if (!isAircraftListLoading) { 
      setAircraftListOptions([
        ...defaultOptions,
        ...selectOptionFormat(aircraftList, 'aircraft_registration'),
      ]); 
    }
  }, [isAircraftListLoading]);

  useEffect(() => {
    if (Object.values(filters).length) {
      handleSelectType(filters.aircraft_type);
      Object.entries(filters).forEach(
        ([name, value]) => setValue(name, value),
      );
    }
  }, [filters]);

  return (
    <Card sx={{ mt: 3, px: '15px', pb: '10px' }}>
      <Typography variant="h4" sx={{ mb: '10px' }}>
        {`Search by ${searchName}`}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={5}
          rowSpacing={1}
          sx={{ flexGrow: 1 }}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup label="AC Type">
              <Controller
                name="aircraft_type"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={aircraftTypeListOptions}
                    defaultValues={0}
                    placeholder="AC Type"
                    onChange={(e) => {
                      field.onChange(e);
                      handleSelectType(e.target.value);
                    }}
                  />
                )}
              />

            </FormGroup>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup label="AC">
              <Controller
                name="aircraft_id"
                control={control}
                render={({ field }) => (
                  <Select
                    options={aircraftListOptions}
                    // defaultValues="0"
                    disabled={isAircraftListLoading}
                    placeholder="AC"
                    {...field}
                  />
                )}
              />

            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup label="From" required hasError={!!errors.date_from}>
              <Controller
                name="date_from"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="YYYY-MM-DD "
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'date_from', 'YYYY-MM-DD');
                      setStartDate(e);
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup label="Invoice status">
              <Controller
                name="invoice_status"
                control={control}
                render={({ field }) => (
                  <Select
                    options={INVOICE_STATUS}
                    placeholder="Invoice status"
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup label="To" required hasError={!!errors.date_to}>
              <Controller
                name="date_to"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'date_to', 'YYYY-MM-DD');
                    }}
                    value={validateDatePickerValue(field.value)}
                    minDate={startDate || null}
                  />
                )}
              />

            </FormGroup>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}
          >
            <Button type="submit" title="Submit" sx={{ flex: '1', mt: '23px' }} />
            <Button onClick={handleClear} title="Clear" sx={{ flex: '1', mt: '23px' }} />
          </Grid>
        </Grid>

      </form>
    </Card>
  );
};

export default Search;

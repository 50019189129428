import {
  number, object, string
} from 'yup';

export const defaultValues = {
  part_id: '',
  comments: '',
};

export const schema = object().shape({
  part_id: number()
    .required('${label} cannot be blank')
    .label('Part'),
  comments: string()
    .nullable()
    .label('Comments'),
});

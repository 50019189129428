import React, { createContext, useState } from 'react';
import {
  removeAdminToken,
  setAdminToken,
  setUser,
  useLoginMutation
} from '../store/session';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { HOME_PAGES } from '../constans/constants';

const defaultProvider = {
  user: null,
  checkAccess: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
};

const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }) => {
  const [admin, setAdmin] = useState(defaultProvider.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [login] = useLoginMutation();

  const handleLogin = async (params) => {
    const toastId = toast.loading('Loading...');

    await login(params).then((res) => {
      if (!res?.error) {
        dispatch(setUser({ user: res?.data?.user }));
        setAdmin(res?.data?.user);

        const navigateUrl = location.state
          ? location.state
          : HOME_PAGES[res?.data?.user?.group.home_page];

        if (res.data.token) {
          toast.success('Success!', {
            id: toastId
          });
          dispatch(setAdminToken({ token: res.data.token }));

          navigate(navigateUrl, { replace: true });
        }
      } else {
        toast.error('Incorrect Username or Password. Try again.', {
          id: toastId
        });
      }
    });
  };

  const handleLogout = () => {
    setAdmin(null);
    dispatch(removeAdminToken());
  };

  const handleCheckAccess = (user, access) => {
    if (user) {
      if (user.role === 'admin') {
        return true;
      }

      if (user?.group) {
        if (access === 'directories_access') {
          if (user?.group?.airport_access
            || user?.group?.services_supplier_access
            || user?.group?.company_access
            || user?.group?.caa_access
            || user?.group?.crew_access
            || user?.group?.pax_profile_access
            || user?.group?.ods_access
          ) {
            return true;
          }
        }

        if (user?.group[access]) {
          return true;
        }
      }
    }

    return false;
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = {
    user: admin,
    checkAccess: handleCheckAccess,
    login: handleLogin,
    logout: handleLogout,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };

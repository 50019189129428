import EditIcon from '@mui/icons-material/Edit';
import {
  Autocomplete,
  Badge,
  Box, Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Tab, Tabs,
  TextField,
  Typography
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import EditableTable from '../../../../components/EditableTable';
import LinkButton from '../../../../components/LinkButton';
import Loader from '../../../../components/Loader/ContentPageLoader';
import { CAPTAIN, CO_PILOTS } from '../../../../constans/crew';
import {
  useCreateTrainingPilotDocsMutation,
  useDeleteTrainingPilotDocsTableMutation,
  useGetTrainingPilotDocsTableQuery,
  useUpdateTrainingPilotDocsTableMutation,
  useUpdateTrainingPilotTagsMutation
} from '../../../../store/session';
import { setFilesName } from '../../../../utils/constants/portalFiles';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import FileDrawer from '../../FileDrawer/FileDrawer';
import DocumentForm from './DocumentForm/DocumentForm';
import { columns } from './columns';
import {
  PilotContainer,
  TitleContainer,
  tableContainer,
} from './styles';
import { usePilotPage } from './usePilotPage';

const crewPost = {
  [CAPTAIN]: 'Captain',
  [CO_PILOTS]: 'First Officer'
};

export const getRowStyle = (row) => {
  let res = '';
  const validityDate = row?.expiry_date || row?.review_date;
  const reviewDate = moment(validityDate);

  if (!row?.file_exist && (row?.threshold_days?.toString() === '0')) {
    res = 'gray';

    return res;
  }

  if (row?.file_exist && (row?.threshold_days?.toString() === '0')) {
    res = 'green';

    return res;
  } 

  if (row?.file_exist) {
    const now = moment();

    if (!validityDate || (validityDate && reviewDate.isSameOrBefore(now))) {
      res = 'red';
    } else if (validityDate && reviewDate.subtract(row?.threshold_days, 'days').isSameOrBefore(now)) {
      res = 'yellow';
    } else {
      res = 'green';
    }
  } else {
    res = 'gray';
  }

  return res;
};

export const hightlightTab = (block) => {
  let color = '';
  if (block.docs_expired) {
    color = 'error';
  } else if (block.docs_threshold) {
    color = 'warning';
  }

  return color.length ? (
    <Badge
      color={color}
      variant="dot"
    >
      {block.block_name}
      {(color === 'error' && block.docs_threshold) ? (
        <span
          style={{ 
            right: '-9px',
            top: '-4px',
            backgroundColor: '#FDB528',
            position: 'absolute',
            borderRadius: '50%',
            height: '8px',
            width: '8px'
          }}
        />
      ) : <></>}
    </Badge>
  ) : block.block_name;
};

const PilotPage = () => {
  const {
    pilotId,
    useGetTrainingPilotDataQuery,
    refetch,
    useRefetch,
    toast,
    hasPermissionsOdsCreate,

    getFileList,
    updateFiles,
    isAttachLoading,
    isLoadingCrewFiles
  } = usePilotPage();

  const { data: pilotData, refetch: refetchPilotData, isLoading } = useGetTrainingPilotDataQuery(pilotId);
  const [updateTagsMutation, { isLoading: isTagsLoading }] = useUpdateTrainingPilotTagsMutation();

  const [currentBlock, setCurrentBlock] = useState(null);
  const [openDocumentDrawer, setOpenDocumentDrawer] = useState(false);
  const [openTagsDrawer, setOpenTagsDrawer] = useState(false);
  const [fileDrawerState, setFileDrawerState] = useState({
    isOpen: false,
    type: null,
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });
  const [tags, setTags] = useState([]);
  const [tagsAutocomplete, setTagsAutocomplete] = useState([]);

  useEffect(() => {
    if (pilotData && pilotData?.block_ids?.length && !currentBlock) {
      setCurrentBlock(pilotData.block_ids[0].id);
    }
    if (pilotData) {
      setTags(pilotData.tags);
      setTagsAutocomplete(pilotData.tags);
    }
  }, [isLoading, pilotData]);

  const hasPermissions = usePermissions('training_module_write');

  const handleChangeBlock = (_, newValue) => {
    setCurrentBlock(newValue);
  };

  const handleTagChange = (newValue) => {
    const newTags = newValue.map(tag => {
      const oldTag = tagsAutocomplete.find(e => e.tag === tag);
      if (oldTag) return oldTag;

      return { tag };
    });
    setTagsAutocomplete(newTags);
  };

  const handleUpdateTags = async () => {
    const toastId = toast.loading('Loading...');

    const requestObject = {
      id: pilotId,
      data: { tags: tagsAutocomplete }
    };
    const { data: newTags } = await updateTagsMutation(requestObject);
    if (!newTags) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }
    toast.success('Success!', {
      id: toastId,
    });
    setTags(newTags);
    setTagsAutocomplete(newTags);
    setOpenTagsDrawer(false);
  };

  const [createDocument] = useCreateTrainingPilotDocsMutation();

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const formData = new FormData();

    formData.append('document_name', data.document_name);
    formData.append('expiry_date', data.expiry_date ? moment(data.expiry_date).format('YYYY-MM-DD') : '');
    formData.append('threshold_days', data.threshold_days || (data?.threshold_days?.toString() === '0' ? '0' : ''));
    formData.append('feedback', data.feedback);
    formData.append('file', data.file);
    formData.append('crew_id', pilotId);
    formData.append('block_id', currentBlock);

    const res = await createDocument(formData);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }
    useRefetch();
    toast.success('Success!', {
      id: toastId,
    });
    setOpenDocumentDrawer(false);
  };

  if ( isLoading || !pilotData || (!currentBlock && pilotData.block_ids.length)) return <Loader />;

  const handleFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    if (id) {
      getFileList(id, type, (list) => setFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: setFilesName(list),
        name,
        currentName
      })));
    }

    setFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
  };

  return (
    <>
      <LinkButton path="/training-module/flight-crew">
        <Button title="Back"></Button>
      </LinkButton>
      <Card sx={{ padding: '1rem', mt: 3, }}>

        <Box sx={TitleContainer}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
            <Box sx={PilotContainer}>

              <Typography variant="h3" sx={{ display: 'block' }}>
                {pilotData.pilot_name}
              </Typography>
            </Box>
            {hasPermissions && (<Button loading={isTagsLoading} title="Edit Tags" onClick={() => setOpenTagsDrawer(true)} />)}
          </Box>

          <Box sx={{
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            pb: '15px'
          }}
          >
            <Chip label={crewPost[pilotData.crew_post].toUpperCase()} variant="outlined" color="primary" />
            <Chip label={pilotData.crew_acft_type} variant="outlined" color="primary" />
            {tags.map(tag => (<Chip label={tag.tag} variant="outlined" color="primary" key={tag.tag} />))}
          </Box>
        </Box>

        <Divider sx={{ mt: 5 }} />
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          gap: 20,
          padding: 3,
          pb: 0
        }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'baseline',
            gap: 0,
          }}
          >
            {pilotData?.block_ids.length ? (
              <Tabs
                value={currentBlock}
                onChange={handleChangeBlock}
                variant="scrollable"
                scrollButtons="auto"
              >
                {pilotData.block_ids.map((block) => (
                  <Tab
                    label={hightlightTab(block)}
                    key={block.id}
                    value={block.id}
                  />
                ))}
              </Tabs>
            ) : null}
            <Box>
              {hasPermissions ? (
                <LinkButton path="/training-module/flight-crew/default-documents">
                  <Button size="small" startIcon={<EditIcon />} title="Manage Blocks" />
                </LinkButton>
              ) : (
                <Button disabled size="small" startIcon={<EditIcon />} title="Manage Blocks"></Button>
              )}
            </Box>

          </Box>

          <Button sx={{ mr: '15px' }} disabled={!currentBlock || !hasPermissions} title="New Document" onClick={() => setOpenDocumentDrawer(true)} />
        </Box>

        <Box sx={tableContainer}>

          <EditableTable
            getRowId={(row) => row.id}
            useHook={currentBlock ? useGetTrainingPilotDocsTableQuery : undefined}
            useHookUpdate={useUpdateTrainingPilotDocsTableMutation}
            useHookDelete={useDeleteTrainingPilotDocsTableMutation}
            tableParams={{
              crew_id: pilotId,
              block_id: currentBlock,
            }}
            getRowHeight={() => 'auto'}
            sortByType="ASC"
            sortByActive="document_name"
            getRowClassName={({ row }) => `row-${getRowStyle(row)}`}
            columns={columns}
            hasPermissions={hasPermissions}
            experimentalFeatures={{ columnGrouping: true }}
            handleFileDrawer={handleFileDrawer}
            refetchTable={refetch}
            refetchCallback={refetchPilotData}
          />

        </Box>

      </Card>

      <FileDrawer 
        open={fileDrawerState.isOpen}
        onClose={() => handleFileDrawer(false)}
        title={fileDrawerState.name} 
        itemName={fileDrawerState.currentName}
        itemsList={fileDrawerState.fileList} 
        uploadFile={(file) => updateFiles(fileDrawerState.currentId, file, fileDrawerState.type, () => handleFileDrawer(true, fileDrawerState.currentId, fileDrawerState.type, fileDrawerState.name, fileDrawerState.currentName))}
        isAttachLoading={isAttachLoading}
        hasPermissions={hasPermissionsOdsCreate || hasPermissions}
        isLoadingFiles={isLoadingCrewFiles}
        deleteFileCallback={useRefetch}
      />

      <Drawer
        open={openDocumentDrawer}
        onClose={() => setOpenDocumentDrawer(false)}
      >
        <DocumentForm onSubmit={onSubmit} />
      </Drawer>

      <Drawer
        open={openTagsDrawer}
        onClose={() => setOpenTagsDrawer(false)}
      >
        <Card sx={{
          mt: 3,
          padding: '1rem'
        }}
        >
          <Box>
            <Typography variant="h3">Edit Tags</Typography>
          </Box>
          <CardContent sx={{ mt: 5 }}>
            <Autocomplete
              sx={{ width: 500 }}
              id="pilot-tags"
              multiple
              freeSolo
              value={tagsAutocomplete?.map(e => e.tag)}
              onChange={(_, newValue) => {
                handleTagChange(newValue);
              }}
              disabled={!hasPermissions}
              options={[]}
              renderInput={(params) => (isTagsLoading ? <CircularProgress />
                : (
                  <TextField
                    {...params}
                    label="Select Tags"
                  />
                ))}
            />
            <Button loading={isTagsLoading} sx={{ mt: '1rem' }} title="Edit" onClick={() => handleUpdateTags()} />
          </CardContent>
        </Card>
      </Drawer>
    </>
  );
};

export default PilotPage;

import { Grid, InputAdornment, TextField } from '@mui/material';
import { Counter, Rename } from 'mdi-material-ui';
import React from 'react';
import { Controller } from 'react-hook-form';

const GridFormGroup = (props) => {
  const {
    xs = 6,
    type = 'text',
    control,
    required = true,
    item: {
      name = '',
      label = '',
      error = false
    }
  } = props;

  return (
    <Grid item xs={xs}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            type
            placeholder={`Enter - ${label}.`}
            required={required}
            error={!!error}
            label={label}
            sx={{
              width: '90%',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {type === 'text' && <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />}
                  {type === 'number' && <Counter sx={{ ...(field?.value && { color: '#626477' }) }} />}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Grid>
  );
};

export default GridFormGroup;
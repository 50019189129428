export const ASSIGNMENTS = [
  { value: null, label: 'N/A' },
  { value: 'Handling', label: 'Handling' },
  { value: 'Taxi', label: 'Taxi' },
  { value: 'Fuel', label: 'Fuel' },
  { value: 'Logistic Saab', label: 'Logistic Saab' },
  { value: 'Logistic ATR', label: 'Logistic ATR' },
  { value: 'Logistic HOF', label: 'Logistic HOF' },
  { value: 'Salary', label: 'Salary' },
  { value: 'Hotel', label: 'Hotel' },
  { value: 'Crew Expenses', label: 'Crew Expenses' },
  { value: 'Taxes', label: 'Taxes' },
  { value: 'Office Expenses', label: 'Office Expenses' },
  { value: 'Refund', label: 'Refund' },
  { value: 'Income', label: 'Income' },
  { value: 'Navigation', label: 'Navigation' },
  { value: 'Transfer Own Account', label: 'Transfer Own Account' },
  { value: 'Business Trip', label: 'Business Trip' },
  { value: 'Bank services', label: 'Bank services' },
  { value: 'Logistic', label: 'Logistic' },
  { value: 'Airport fees', label: 'Airport fees' },
  { value: 'Navigation', label: 'Navigation' },
  { value: 'Cash withdrawal', label: 'Cash withdrawal' },
  { value: 'Professional services', label: 'Professional services' },
  { value: 'IT assistance', label: 'IT assistance' },
  { value: 'Maintenance', label: 'Maintenance' },
  { value: 'Account service fee', label: 'Account service fee' },
];

export const Types = [
  { value: 'Invoice', label: 'Invoice' },
  { value: 'Proforma', label: 'Proforma' },
  { value: 'Document', label: 'Document' },
];

export const Statuses = [
  { value: 'Open', label: 'Open' },
  { value: 'Closed', label: 'Closed' }
];

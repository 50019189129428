import { useGetCreditNoteFilesMutation, useUploadCreditNoteFileMutation } from '../../../../store/slices/logisticsSlice';
import { useState } from 'react';
import { setFilesName } from '../../../../utils/constants/portalFiles';
import toast from 'react-hot-toast';

export const useCreditNoteFiles = () => {
  const [fileDrawerState, setFileDrawerState] = useState({
    isOpen: false,
    type: '',
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });

  const [getFiles, { isLoading: isLoadingFiles }] = useGetCreditNoteFilesMutation();
  const [uploadFiles, { isLoading: isAttachLoading }] = useUploadCreditNoteFileMutation();

  const getFileList = async (id, callback) => {
    const res = await getFiles({ id });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.');
      } else {
        toast.error('Unable to get file list.');
      }

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const updateFiles = async (id, file, type, callback) => {
    if (!file.target.files[0]) return;

    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    Object.keys(file.target.files)?.forEach((key) => {
      formData.append('upload_files[]', file.target.files[key]);
    });

    const res = await uploadFiles({ id, data: formData });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.', {
          id: toastId,
        });
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    callback();
  };

  const handleFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    if (id) {
      getFileList(id, (list) => setFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: setFilesName(list),
        name,
        currentName
      })));
    }

    setFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
  };

  return {
    fileDrawerState,
    setFileDrawerState,
    isAttachLoading,
    isLoadingFiles,
    handleFileDrawer,
    updateFiles
  };
};

export const dropdownMenuSx = {
  '& .MuiPaper-root': {
    p: 0,
    mt: '-70px',
  }
};

export const itemSx = {
  p: '5px 20px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f5f5f5'
  }
};

export const containerSx = {
  a: {
    color: 'rgba(76, 78, 100, 0.87);',
    textDecoration: 'none',
  }
};

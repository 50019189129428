import React from 'react';
import { Link } from 'react-router-dom';
import { StyledLink } from './style';

const LinkButton = ({
  path, children, style, state, target,
}) => (
  <Link
    style={{
      ...StyledLink,
      ...style
    }}
    target={target}
    to={path}
    state={state}
  >
    {children}
  </Link>
);

export default LinkButton;

export const ContainerFiltration = {
  mt: 3,
  mb: '50px',
  display: 'flex',
};

export const calendarWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
};

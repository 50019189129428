import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useGenerateFinanceInvoiceMutation, useUpdatePricesGenerateFinanceInvoiceMutation } from '../../../../store/slices/financeSlice';
import {
  defaultValues,
  schema
} from './schema';

export const useNewOrderForm = ({ refresh, onClose, currentInvoice }) => {
  const [send, isLoading] = useGenerateFinanceInvoiceMutation();
  const [updateItems] = useUpdatePricesGenerateFinanceInvoiceMutation();
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const invoiceData = useFieldArray({
    name: 'invoice_data',
    control: formMethods.control
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    delete data.currentInvoice;

    const { error } = await send({ data });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    onClose();
    refresh();
    formMethods.reset(defaultValues);

    toast.success('Successfully!', {
      id: toastId
    });
  };

  const onUpdate = async (data) => {
    const toastId = toast.loading('Loading...');

    const { error } = await updateItems({ data: JSON.stringify(data?.invoice_data), id: currentInvoice?.id, currency_id: data?.currency_id });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    onClose();
    refresh();
    formMethods.reset(defaultValues);

    toast.success('Successfully!', {
      id: toastId
    });
  };

  return {
    formMethods,
    invoiceData,
    defaultValues,
    handleSubmit: formMethods.handleSubmit,
    onSubmit,
    isLoading,
    onUpdate
  };
};

import { styled } from '@mui/material/styles';
import Delete from 'mdi-material-ui/Delete';

export const ButtonStyle = {
  backgroundColor: 'rgba(0, 70, 136, 0.8)',
  padding: '7px 22px',
  fontWeight: '500',
  borderRadius: '8px',
  color: '#fff',
  height: 'unset',
};

export const TitleContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const StyledDelete = styled(Delete)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));
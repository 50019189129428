import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { HeaderName } from '../../../components/EditableTable/styles';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import ActionMenu from './ActionMenu';
import { InputFilter } from './InputFilter';
import { Link } from 'react-router-dom';
import FiltrationDate from "../../../components/EditableTable/FiltrationDate";

export const columns = ({
  handleFilter, filters, odsRecalculate, openMenu
}) => ([
  {
    flex: 0.2,
    field: 'actions',
    minWidth: 150,
    renderHeader: () => '',
    renderCell: (item) => <ActionMenu openMenu={openMenu} odsRecalculate={odsRecalculate} item={item} />
  },
  {
    flex: 0.2,
    field: 'flights_planing_index',
    minWidth: 90,
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.flights_planing_index || ''}
        title="Flight"
        value="flights_planing_index"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>
        <Link style={{ 'textDecoration': 'none' }} to={`/flights/${row.flight_id}`} target="_blank">{row.flights_planing_index}</Link>
      </Typography>
    )
  },
  {
    flex: 0.2,
    field: 'type',
    minWidth: 90,
    renderHeader: () => 'Type',
    renderCell: ({ row }) => (
      <Typography>{row.type}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'ods_status',
    minWidth: 100,
    renderHeader: () => 'Status',
    renderCell: ({ row }) => (
      <Typography>{row.ods_status}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'departure',
    minWidth: 180,
    headerName: 'Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Date</Typography>
        <FiltrationDate
          onChange={(e) => handleFilter(e, 'departure', true)}
          value={filters.departure || null}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.departure}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'index',
    minWidth: 150,
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.index || ''}
        title="Ods NO"
        value="index"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>{row.index}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'flight_number',
    minWidth: 150,
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.flight_number || ''}
        value="flight_number"
        title="FLT/NR"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>{row.flight_number}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'ods_from',
    minWidth: 150,
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.ods_from || ''}
        value="ods_from"
        title="From"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>{row.ods_from}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'ods_to',
    minWidth: 150,
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.ods_to || ''}
        value="ods_to"
        title="To"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>{row.ods_to}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'block_off',
    minWidth: 120,
    renderHeader: () => 'Block Off',
    renderCell: ({ row }) => (
      <Typography>{row.block_off}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'take_off',
    minWidth: 110,
    renderHeader: () => 'Take Off',
    renderCell: ({ row }) => (
      <Typography>{row.take_off}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'take_on',
    renderHeader: () => 'Land',
    minWidth: 90,
    renderCell: ({ row }) => (
      <Typography>{row.take_on}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'block_on',
    minWidth: 110,
    renderHeader: () => 'Block On',
    renderCell: ({ row }) => (
      <Typography>{row.block_on}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'flight_time',
    minWidth: 90,
    renderHeader: () => 'Flight',
    renderCell: ({ row }) => (
      <Typography>{row.flight_time}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'flight_block',
    minWidth: 100,
    renderHeader: () => 'Block',
    renderCell: ({ row }) => (
      <Typography>{row.flight_block}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'current_air',
    minWidth: 120,
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.current_air || ''}
        title="TSN"
        value="current_air"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>{row.current_air}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'current_ldg',
    minWidth: 90,
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.current_ldg || ''}
        title="CSN"
        value="current_ldg"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>{row.current_ldg}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'ods_crew_pic',
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.ods_crew_pic || ''}
        value="ods_crew_pic"
        title="PIC"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>{row.ods_crew_pic}</Typography>
    ),
    minWidth: 150,
  },
  {
    flex: 0.2,
    field: 'ods_crew_fo',
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.ods_crew_fo || ''}
        value="ods_crew_fo"
        title="F/O"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>{row.ods_crew_fo}</Typography>
    ),
    minWidth: 150,
  },
  {
    flex: 0.2,
    field: 'ods_crew_engineer',
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.ods_crew_engineer || ''}
        value="ods_crew_engineer"
        title="F/E"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>{row.ods_crew_engineer}</Typography>
    ),
    minWidth: 150,
  },
  {
    flex: 0.2,
    field: 'crew_cca',
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.crew_cca || ''}
        value="crew_cca"
        title="CCM"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>{row.crew_cca}</Typography>
    ),
    minWidth: 150,
  },
  {
    flex: 0.2,
    field: 'load_cargo',
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.load_cargo || ''}
        value="load_cargo"
        title="WOC kg"
      />
    ),
    minWidth: 150,
    renderCell: ({ row }) => (
      <Typography>{row.load_cargo}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'load_pax',
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.load_pax || ''}
        value="load_pax"
        title="PAX"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>{row.load_pax}</Typography>
    ),
    minWidth: 150,
  },
  {
    flex: 0.2,
    field: 'weight_tow',
    minWidth: 90,
    renderHeader: () => 'TOW',
    renderCell: ({ row }) => (
      <Typography>{row.weight_tow}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'weight_lw',
    minWidth: 90,
    renderHeader: () => 'LW',
    renderCell: ({ row }) => (
      <Typography>{row.weight_lw}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'toc',
    minWidth: 400,
    renderHeader: () => (
      <InputFilter
        onChange={handleFilter}
        defaultValue={filters.toc || ''}
        value="toc"
        title="TOC"
      />
    ),
    renderCell: ({ row }) => (
      <Typography>{row.toc}</Typography>
    ),
  },
]);

export const columnsOther = ({
  aircrafts, aircraftTypeT, odsRecalculate, openMenu, odsStatuses, handleFilter, filters,
}) => ([
  {
    flex: 0.2,
    field: 'actions',
    minWidth: 150,
    renderHeader: () => '',
    renderCell: (item) => <ActionMenu openMenu={openMenu} odsRecalculate={odsRecalculate} item={item} />
  },
  {
    flex: 0.2,
    field: 'type',
    minWidth: 90,
    renderHeader: () => 'Type',
    renderCell: ({ row }) => (
      <Typography>{row.type}</Typography>
    )
  },
  {
    flex: 0.2,
    field: 'ods_status',
    minWidth: 100,
    renderHeader: () => 'Status',
    renderCell: ({ row }) => <Typography>{odsStatuses?.[row.ods_status]}</Typography>
  },
  {
    flex: 0.2,
    field: 'aircraft_type',
    minWidth: 80,
    renderHeader: () => 'Aircraft Type',
    renderCell: ({ row }) => {
      const type = aircrafts?.find((item) => item?.id === +row.aircraft_id)?.aircraft_registration;
      const typeT = aircraftTypeT?.data?.find((item) => item?.id === +row.aircraft_type)?.aircraft_type_name;

      return (
        <Typography>
          {row.type === 'T' ? typeT : type}
        </Typography>
      ); }
  },
  {
    flex: 0.2,
    field: 'crew_shortname',
    minWidth: 120,
    headerName: 'Shortname',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Shortname</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.crew_shortname || ''}
          value="crew_shortname"
          placeholder="Shortname"
        />
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'start',
    minWidth: 150,
    renderHeader: () => 'Start',
    renderCell: ({ row }) => (
      <Typography>{moment(row.start).format('YYYY-MM-DD H:mm')}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'finish',
    minWidth: 150,
    renderHeader: () => 'Finish',
    renderCell: ({ row }) => (
      <Typography>{moment(row.finish).format('YYYY-MM-DD H:mm')}</Typography>
    ),
  },
]);

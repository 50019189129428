import { Box, Card, useMediaQuery } from '@mui/material';
import React from 'react';
import Search from '../../../components/SearchStatisticsV2/Search';
import Chart from './Chart/index';

import { useGetStatisticByProfitMutation } from '../../../store/session';
import Table from './Table/Table';

const ByProfit = () => {
  const maxWidth1080px = useMediaQuery('(max-width:1080px)');

  return (
    <>
      <Box sx={{
        display: 'flex', gap: '10px', ...(maxWidth1080px ? { flexDirection: 'column' } : { height: '370px' })
      }}
      >
        <Card sx={{ ...(!maxWidth1080px && { width: '40%' }) }}>
          <Search
            searchName="profit"
            fixedCacheKey="get-statistic-by-profit"
            useHook={useGetStatisticByProfitMutation}
            clearPath="/statistics/by-profit"
          />
        </Card>
        <Card sx={{ width: '100%', p: '10px' }}>
          <Chart></Chart>
        </Card>
      </Box>
      <Card sx={{ mt: 3 }}>
        <Table />
      </Card>
    </>
  );
};

export default ByProfit;

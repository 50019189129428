import React from 'react';
import Box from '@mui/material/Box';
import ModalBase from '@mui/material/Modal';
import {
  closeIcon, headerSx, modalSx, titleSx, cancel, blockBtn, text,
} from './styles';
import Button from '../Button';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const InfoModal = ({
  open, onClose, reasonText
}) => (
  <ModalBase
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={modalSx}>
      <Box sx={headerSx}>
        <Typography sx={titleSx}>
          Your invoice was rejected for this reason:
        </Typography>
        <CloseIcon onClick={onClose} sx={closeIcon} />
      </Box>
      <Box sx={text}>
        {reasonText}
      </Box>
      <Box sx={blockBtn}>
        <Button onClick={onClose} sx={cancel} title="Close" />
      </Box>
    </Box>
  </ModalBase>
);

export default InfoModal;

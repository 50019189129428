import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import {
  Box, TextField, Switch, Stack, IconButton, Collapse, FormControlLabel, Divider, Autocomplete
} from '@mui/material';
import { useGetAirportListQuery } from '../../../store/session';
import DatePicker from '../../../form/components/DatePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '../../../components/Button';
import EmailIcon from '@mui/icons-material/Email';
import toast from 'react-hot-toast';
import { useHandleQueryFormError } from '../../../utils/hooks/useHandleQueryFormError';
import {
  BtnActive,
  DefaultBtn,
  ButtonContainer,
  AllMonths,
  ButtonContainerX,
  BtnGroup,
  BtnGroupX,
  DefaultBtnX,
  BtnGroupXs,
  DefaultBtnXs,
  BtnSend,
  ButtonContainerXs,
  DefaultBtnMounth,
  DefaultBtnMounthX,
} from './styles';

function compareAircraftData(originalData, userData) {
  const changes = [];

  if (!originalData || !userData) {
    return changes;
  }

  userData.forEach((userGroup, groupIndex) => {
    userGroup.items.forEach((userItem, itemIndex) => {
      const originalGroup = originalData.find(
        (group) => group.aircraft_type === userGroup.aircraft_type
      );

      if (originalGroup) {
        const originalItemIndex = originalGroup.items.findIndex(
          (item) => item.aircraft_name === userItem.aircraft_name
        );

        if (originalItemIndex !== -1) {
          const originalItem = originalGroup.items[originalItemIndex];
          const changedProperties = {};

          if (originalItem.aircraft_avail !== userItem.aircraft_avail) {
            changedProperties.aircraft_avail = true;
          }

          if (originalItem.aircraft_new !== userItem.aircraft_new) {
            changedProperties.aircraft_new = true;
          }

          if (
            (originalItem.avail_airport_id !== null && userItem.avail_airport_id === null)
            || (originalItem.avail_airport_id === null && userItem.avail_airport_id !== null)
            || originalItem.avail_airport_id !== userItem.avail_airport_id
          ) {
            changedProperties.avail_airport_id = true;
          }

          if (originalItem.avail_time !== userItem.avail_time) {
            changedProperties.avail_time = true;
          }

          if (originalItem.avail_next_time !== userItem.avail_next_time) {
            changedProperties.avail_next_time = true;
          }

          if (Object.keys(changedProperties).length > 0) {
            changes.push(userItem,);
          }
        }
      }
    });
  });

  return changes;
}

const AircraftSwitch = ({ label, value, onChange }) => (
  <FormControlLabel
    control={(
      <Switch
        checked={value}
        onChange={onChange}
        color="primary"
      />
    )}
    label={label}
  />
);

const AircraftForm = ({
  AirportList, aircraft, index, onChange
}) => {
  const keyObject = { aircraftType: aircraft.aircraft_type, itemIndex: index };

  return (
    <Box sx={{ padding: '0.5rem 1rem' }}>
      <Box sx={{ fontWeight: 'bold' }}>
        {aircraft.aircraft_name}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <AircraftSwitch
          value={!!aircraft.aircraft_avail}
          label="Aircraft Availability"
          onChange={() => onChange(keyObject, 'aircraft_avail', aircraft.aircraft_avail ? 0 : 1)}
        />
        <AircraftSwitch
          value={!!aircraft.aircraft_new}
          label="Aircraft New"
          onChange={() => onChange(keyObject, 'aircraft_new', aircraft.aircraft_new ? 0 : 1)}
        />
        {aircraft.aircraft_avail ? (
          <>
            <Autocomplete
              value={(() => {
                const arr = AirportList?.filter((AirportItem) => AirportItem.id === aircraft.avail_airport_id);

                return arr?.[0] || null;
              })()}
              options={AirportList}
              getOptionLabel={(option) => `${option.airport_iata}/${option.airport_icao} (${option.airport_name})` || ''}
              isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    width: '14rem',
                  }}
                  helperText={!aircraft.avail_airport_id ? 'Required' : ''}
                  error={!aircraft.avail_airport_id}
                  label="Airport"
                />
              )}
              onChange={(e, value) => onChange(keyObject, 'avail_airport_id', (value?.id || null) )}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <DatePicker
                value={aircraft.avail_time ? moment(aircraft.avail_time, 'HH:mm').toDate() : null}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={1}
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                onChange={(e) => onChange(keyObject, 'avail_time', e ? moment(e).format('HH:mm') : null )}
                customInput={(
                  <TextField
                    label="Availability Time"
                    size="small"
                    helperText={!aircraft.avail_time ? 'Required' : ''}
                    error={!aircraft.avail_time}
                  />
                )}
              />
            </Box>
          </>
        ) : (
          <DatePicker
            label="Next Availability"
            dateFormat="dd MMM"
            value={aircraft.avail_next_time ? moment(aircraft.avail_next_time, 'DD MMM').toDate() : null}
            onChange={(e) => onChange(keyObject, 'avail_next_time', e ? moment(e).format('DD MMM') : null )}
          />
        )}
      </Box>
    </Box>
  );
};

const AircraftGroup = ({
  AirportList, aircraft_type, items, onChange
}) => {
  const [open, setOpen] = useState(true);

  return (
    <Box>
      <Box sx={{
        backgroundColor: '#1a4671', color: 'white', borderRadius: '4px 4px 0 0', ...(!open && { borderRadius: '4px' })
      }}
      >
        <IconButton
          sx={{ color: 'white' }}
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        {aircraft_type}
      </Box>
      <Collapse sx={{ ...(open && { border: '2px solid #1a4671', borderRadius: '0 0 4px 4px' }) }} in={open} timeout="auto" unmountOnExit>
        {items?.map((item, index) => (
          <>
            <AircraftForm aircraft={item} AirportList={AirportList} index={index} key={item.id} onChange={onChange} />
            {index === items.length - 1 ? null : <Divider /> }
          </>
        ))}
      </Collapse>
    </Box>
  );
};
const groupData = (data) => data?.aircraft.reduce((result, current) => {
  const { aircraft_type } = current;
  const existingGroup = result.find(group => group.aircraft_type === aircraft_type);
  if (existingGroup) {
    existingGroup.items.push(current);
  } else {
    result.push({
      aircraft_type,
      items: [current],
    });
  }

  return result;
}, []);

const TrainingTable = ({
  useGetHook, useUpdateHook, onClickSendRooster
},) => {
  const { data } = useGetHook();
  const [stateData, setStateData] = useState([]);
  const { data: AirportList } = useGetAirportListQuery();
  const [update, { isLoading: isUpdating }] = useUpdateHook();
  const [resetState, setResetState] = useState(false); // State to trigger reset
  const [originalData, setOriginalData] = useState([]);

  useEffect(() => {
    if (data) {
      setOriginalData(JSON.parse(JSON.stringify(groupData(data))));
      setStateData(JSON.parse(JSON.stringify(groupData(data))));
    }
  }, data);

  const changes = useMemo(() => compareAircraftData(originalData, stateData), [originalData, stateData, resetState]);

  const handleItemChange = ({ aircraftType, itemIndex }, propertyName, newValue, ) => {
    const stateDataCopy = JSON.parse(JSON.stringify(stateData));
    const groupIndex = stateDataCopy.findIndex(group => group.aircraft_type === aircraftType);
    stateDataCopy[groupIndex].items[itemIndex][propertyName] = newValue;
    setStateData(stateDataCopy);
  };

  const handleReset = () => {
    setStateData(JSON.parse(JSON.stringify(originalData)));
  };

  const handleUpdate = async () => {
    const toastId = toast.loading('Loading...');
    const requirestBody = {
      aircraft: changes.map(({
        aircraft_type, airport_iata, aircraft_name, ...rest
      }) => rest)
    };
    const res = await update(requirestBody);
    if (res.error) {
      toast.error(res.error.data.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }
    setOriginalData(groupData(res.data));
    setStateData(JSON.parse(JSON.stringify(groupData(res.data))));
    setResetState(prevResetState => !prevResetState);

    toast.success('Successfully Updated!', {
      id: toastId,
    });
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Button
            type="submit"
            sx={DefaultBtn}
            variant="contained"
            onClick={handleReset}
            title="Reset"
            size="large"
            disabled={!changes.length}

          />
          <Button
            type="submit"
            sx={DefaultBtn}
            variant="contained"
            loading={isUpdating}
            onClick={handleUpdate}
            title="Update"
            size="large"
            disabled={!changes.length}
          />
        </Box>
        <Button
          type="submit"
          sx={{ ...DefaultBtn, ...BtnSend }}
          variant="contained"
          size="large"
          disabled={changes.length}
          onClick={onClickSendRooster}
        >
          <EmailIcon />
          SEND MAIL ONCE
        </Button>

      </Box>
      <Divider />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {stateData?.map((group) => <AircraftGroup AirportList={AirportList} aircraft_type={group.aircraft_type} items={group.items} onChange={handleItemChange} key={group.aircraft_type} />)}
      </Box>
    </>
  );
};

export default TrainingTable;

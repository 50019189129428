import React from 'react';
import { Typography } from '@mui/material';
import LinkButton from '../../../../components/LinkButton/Button';

export const columns = [
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 120,
    flex: 0.2
  },
  {
    field: 'flt_num',
    headerName: 'Flt num',
    minWidth: 100,
    flex: 0.2
  },
  {
    field: 'from',
    headerName: 'From',
    minWidth: 120,
    flex: 0.2
  },
  {
    field: 'to',
    headerName: 'To',
    minWidth: 120,
    flex: 0.2
  },
  {
    field: 'atd',
    headerName: 'ATD',
    minWidth: 100,
    flex: 0.2
  },
  {
    field: 'ata',
    headerName: 'ATA',
    minWidth: 100,
    flex: 0.2
  },
  {
    field: 'flt_time',
    headerName: 'Flight time',
    minWidth: 120,
    flex: 0.2
  },
  {
    field: 'total_price',
    headerName: 'Total Price',
    minWidth: 120,
    flex: 0.2
  },
  {
    field: 'acmi',
    headerName: 'ACMI',
    minWidth: 100,
    flex: 0.2
  },
  {
    field: 'fuel',
    headerName: 'Fuel',
    minWidth: 100,
    flex: 0.2
  },
  {
    field: 'inv',
    headerName: 'Inv',
    minWidth: 100,
    flex: 0.2
  },
  {
    field: 'nav',
    headerName: 'Nav',
    minWidth: 100,
    flex: 0.2
  },
  {
    field: 'total_expenses',
    headerName: 'Total expenses',
    minWidth: 150,
    flex: 0.2
  },
  {
    field: 'profit',
    headerName: 'profit',
    minWidth: 110,
    flex: 0.2
  },
  {
    field: 'profit_percent',
    headerName: 'Profit percent',
    minWidth: 150,
    renderCell: ({ row }) => (
      <Typography>
        {row.profit_percent ? `${row.profit_percent}%` : '' }
      </Typography>
    ),
    flex: 0.2
  },
  {
    field: 'flights_count',
    headerName: 'Flights',
    minWidth: 130,
    renderCell: (row) => {
      if (row.row.aircraft_name) {
        return (
          <LinkButton target="_blank" path={`/flights/${row.row.flight_id}`} style={{ textDecoration: 'underline !important', }}>
            Flight#
            {' '}
            {row.row.flight_id}
          </LinkButton>
        );
      }

      return (
        <Typography>{row.row.flights_count}</Typography>
      );
    },
    flex: 0.2
  },
];
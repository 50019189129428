import {
  object,
  string,
} from 'yup';

export const defaultValues = {
  email: '',
  emails: [],
};

export const schema = object().shape({
  email: string()
    .nullable()
    .label('Email'),
});

import {
  Box, Grid, Typography, useMediaQuery
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Button from '../../../../../components/Button';
import AutoSizeTextarea from '../../../../../form/components/AutoSizeTextarea';
import FieldError from '../../../../../form/components/FieldError';
import FormGroup from '../../../../../form/components/FormGroup';
import Input from '../../../../../form/components/Input';
import Select from '../../../../../form/components/Select';
import TimeInput from '../../../../../form/components/TimeInput';
import { useGetOdsAtaQuery } from '../../../../../store/session';
import { getOptionsWithKeyValue } from '../../../../../utils/getOptions';
import TypeCard from '../../../../Ods/OdsForm/TypeCard';

const General = (props) => {
  const { taskData = null, handleFileDrawer } = props;
  const { control, formState: { errors } } = useFormContext();

  const { data: odsAta } = useGetOdsAtaQuery();

  const odsAtaOptions = getOptionsWithKeyValue(odsAta);

  const maxWidth768px = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    if (odsAtaOptions) {
      odsAtaOptions?.push({ value: '', label: '-' });
    }
  }, [odsAtaOptions]);

  return (
    <TypeCard title="GENERAL" variant="yellow" isPanel>
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="NAME" required hasError={!!errors.name}>
            <Controller
              render={({ field } ) => (
                <Input
                  {...field}
                />
              )}
              control={control}
              name="name"
            />

            <FieldError error={errors.name} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="DESCRIPTION" required hasError={!!errors.description}>
            <Controller
              render={({ field } ) => (
                <Input
                  multiline
                  {...field}
                />
              )}
              control={control}
              name="description"
            />

            <FieldError error={errors.description} />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="TITLE" hasError={!!errors.title}>
            <Controller
              render={({ field } ) => (
                <Input
                  {...field}
                />
              )}
              control={control}
              name="title"
            />

            <FieldError error={errors.title} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="TASK" hasError={!!errors.task}>
            <Controller
              render={({ field } ) => (
                <Input
                  {...field}
                />
              )}
              control={control}
              name="task"
            />

            <FieldError error={errors.task} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup
            label={(
              <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Typography>REF</Typography>
                {taskData?.exist_files && (
                <Button
                  title="Open Ref files"
                  onClick={() => handleFileDrawer(true, taskData?.maint_id, '', 'Ref', '')}
                  sx={{
                    padding: '0px 4px',
                    fontSize: '.7rem',
                    lineHeight: '1.5',
                    textTransform: 'capitalize',
                    height: '20px',
                    borderRadius: '6px'
                  }}
                />
                )}
              </Box>
            )}
            hasError={!!errors.ref}
          >
            <Controller
              render={({ field } ) => (
                <Input
                  {...field}
                />
              )}
              control={control}
              name="ref"
            />

            <FieldError error={errors.ref} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="INTERVAL" hasError={!!errors.interval}>
            <Controller
              render={({ field } ) => (
                <Input
                  {...field}
                />
              )}
              control={control}
              name="interval"
            />

            <FieldError error={errors.interval} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="ZONE" hasError={!!errors.zone}>
            <Controller
              render={({ field } ) => (
                <Input
                  {...field}
                />
              )}
              control={control}
              name="zone"
            />

            <FieldError error={errors.zone} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="ACCESS" hasError={!!errors.access}>
            <Controller
              render={({ field } ) => (
                <Input
                  {...field}
                />
              )}
              control={control}
              name="access"
            />

            <FieldError error={errors.access} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="ATA" hasError={!!errors.ata}>
            <Controller
              render={({ field } ) => (
                <Select
                  options={odsAtaOptions}
                  {...field}
                  value={+field.value}
                />

              )}
              control={control}
              name="ata"
            />

            <FieldError error={errors.ata} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="TASK GROUP" hasError={!!errors.task_group}>
            <Controller
              render={({ field } ) => (
                <Input
                  {...field}
                />
              )}
              control={control}
              name="task_group"
            />

            <FieldError error={errors.task_group} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="PN" hasError={!!errors.pn}>
            <Controller
              render={({ field } ) => (
                <Input
                  {...field}
                />
              )}
              control={control}
              name="pn"
            />

            <FieldError error={errors.pn} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="SN" hasError={!!errors.sn}>
            <Controller
              render={({ field } ) => (
                <Input
                  {...field}
                />
              )}
              control={control}
              name="sn"
            />

            <FieldError error={errors.sn} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="Part name" hasError={!!errors.part_name}>
            <Controller
              render={({ field } ) => (
                <Input
                  {...field}
                />
              )}
              control={control}
              name="part_name"
            />

            <FieldError error={errors.part_name} />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <FormGroup label="ACTION TAKEN" hasError={!!errors.action_taken}>
            <Controller
              render={({ field } ) => (
                <AutoSizeTextarea
                  placeholder="Action taken"
                  rows={5}
                  {...field}
                />
              )}
              control={control}
              name="action_taken"
            />

            <FieldError error={errors.action_taken} />
          </FormGroup>
        </Grid>

        <Grid item xs={maxWidth768px ? 12 : 2}>
          <FormGroup label="Man Hour Real Job" hasError={!!errors.man_hour_real_job}>
            <Controller
              render={({ field } ) => (
                <TimeInput
                  {...field}
                  variant="standard"
                  helperText="Approximate completion time in hours"
                />
              )}
              control={control}
              name="man_hour_real_job"
            />

            <FieldError error={errors.man_hour_real_job} />
          </FormGroup>
        </Grid>

      </Grid>
    </TypeCard>
  );
};

export default General;

import React from 'react';
import TypeCard from '../../../../Ods/OdsForm/TypeCard';
import LinkButton from '../../../../../components/LinkButton/Button';

const Task = ({ workOrderTask }) => (
  <TypeCard title="TASK" variant="yellow" isPanel>
    <LinkButton style={{ textDecoration: 'underline' }} target="_blank" path={`/maintenance/update/${workOrderTask?.maint_id}`}>
      {workOrderTask?.task}
    </LinkButton>
  </TypeCard>
);

export default Task;

import { TextField } from '@mui/material';
import React, { forwardRef } from 'react';
import { useHasError } from '../FormGroup';
import './styles.css';

const Input = forwardRef(
  (props, ref) => {
    const hasError = useHasError();

    return <TextField fullWidth inputRef={ref} error={hasError} size="small" {...props} />;
  },
);

export default Input;

import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import { usePilotLogBook } from './usePilotLogBook';
import { LogBookTable } from './LogBookTable';
import {
  cardContainerSx,
} from './styles';
import { SendToModal } from './SendToModal';

const LogBook = () => {
  const {
    list,
    isLoading,
    isPdf,
    isModalOpen,
    crew,
    data,
    months,
    year,
    handleOpen,
    handleClose,
    handlePrint,
  } = usePilotLogBook();

  return (
    <Card sx={cardContainerSx}>
      {!isPdf && (
        <>
          <h1>
            PILOT LOGBOOK
          </h1>

          <Box display="flex" gap="10px">
            <Button
              variant="contained"
              startIcon={<EmailIcon />}
              onClick={handleOpen}
            >
              SEND LOGBOOK
            </Button>
            <Button onClick={handlePrint} variant="contained">GENERATE PDF</Button>
          </Box>
        </>
      )}
      <LogBookTable
        list={list}
        isLoading={isLoading}
        isPdf={isPdf}
        data={data}
      />

      <SendToModal
        open={isModalOpen}
        onClose={handleClose}
        email={crew?.crew_email}
        months={months}
        year={year}
      />
    </Card>
  ); };

export default LogBook;

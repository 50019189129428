import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Dialog, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Button from '../../../../../../../../components/Button';
import { GreenButton } from '../../../../../../style';
import SupplierInvoiceForm from './SupplierInvoiceForm';
import { defaultValues, deliveryServices, schema } from './useSupplierInvoice';

const RegenerateInvoice = (props) => {
  const {
    open = false,
    onClose = () => {},
    invoice = {},
    handlePreviewInvoice = () => {},
    onRegenerate = () => {},
    order = {}
  } = props;
 
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
    reset
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    fields: partsArray,
    append,
    remove
  } = useFieldArray({
    name: 'parts',
    control
  });

  const handleAppendNewParts = () => {
    const currency = getValues('parts.0.price_each')?.split(' ')?.[0] || '';

    append({
      ...defaultValues.parts[0],
      price_each: `${currency || ''} `,
      amount: `${currency || ''} `,
    });
  };

  const handleRemoveParts = (index) => {
    remove(index);
  };

  useEffect(() => {
    if (invoice) {
      reset();
      Object.entries(invoice).forEach(([key, value]) => setValue(key, value));
    }
  }, [open, invoice]);

  return (
    <Dialog
      // fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >
      <Grid container spacing={6} sx={{ padding: '24px' }}>
        <SupplierInvoiceForm 
          errors={errors}
          control={control}
          deliveryServices={deliveryServices}
          partsArray={partsArray}
          handleAppendNewParts={handleAppendNewParts}
          handleRemoveParts={handleRemoveParts}
          isRegenerate
          order={order}
          setValue={setValue}
          watch={watch}
        />
      </Grid>
      <Grid container spacing={6} sx={{ padding: '24px' }}>
        <Grid item xs={6} sx={{ display: 'flex', gap: 5 }}>
          <Button
            title="Regenerate"
            sx={GreenButton}
            onClick={() => onRegenerate({ 
              parent_invoice_number: invoice?.invoice_number,
              to: getValues('to'),
              email: getValues('email'),
              ship_to: getValues('ship_to'),
              parts: getValues('parts'),
              ship_via: getValues('ship_via'),
              // invoice_number: createInvoiceNumber(),
              order_id: invoice?.order_id,
            }, onClose)}
          />
          <Button
            title="Preview Invoice"
            // disabled={!supplier || previewLoading}
            onClick={() => handlePreviewInvoice(null, getValues())}
            // startIcon={previewLoading && <CircularProgress size={15} />}
          />
          
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button title="Close" onClick={onClose} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default RegenerateInvoice;
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './slices';
import { sessionApi } from './session';

const persistConfig = {
  key: 'root',
  blacklist: ['sessionApi'],
  storage,
};

const prReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: prReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(sessionApi.middleware),
});

export const persister = persistStore(store);
setupListeners(store.dispatch);

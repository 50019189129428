import { Box, InputLabel } from '@mui/material';
import React, {
  createContext, useContext,
} from 'react';

const ErrorContext = createContext(false);

export const useHasError = () => useContext(ErrorContext);

const FormGroup = ({
  children, label, required = false, hasError = false, sx, ...divProps
}) => (
  <Box sx={{
    marginBottom: '10px',
    ...sx
  }}
  >
    <div {...divProps}>
      {label && (
      <InputLabel required={required} error={hasError} sx={{ fontWeight: 500, color: '#626477' }}>
        {label}
      </InputLabel>
      )}

      <ErrorContext.Provider value={hasError}>{children}</ErrorContext.Provider>
    </div>
  </Box>
);

export default FormGroup;

import {
  Box, Card, CardContent, Typography
} from '@mui/material';
import React from 'react';
import FileSaver from 'file-saver';
import { toast } from 'react-hot-toast';
import EditableTable from '../../../../../components/EditableTable';
import { cardContent } from '../../../../../components/EditableTable/styles';
import {
  useDeleteWorkOrderTaskFileMutation, useDownloadWorkOrderTaskFileMutation, useGetWorkOrderTaskFileQuery, useUploadWorkOrderTaskFileMutation
} from '../../../../../store/session';
import { columns } from './columns';
import { useParams } from 'react-router-dom';
import Upload from '../../../../../components/Upload';

export const AttachedFilesTable = ({ hasPermissionsWoEdit }) => {
  const params = useParams();

  const [downloadFile] = useDownloadWorkOrderTaskFileMutation();
  const [uploadFile, { isLoading }] = useUploadWorkOrderTaskFileMutation();

  const tableParams = { wot_id: params?.id };

  const handleDownload = async({ id, name }) => {
    const toastId = toast.loading('Loading...');

    const res = await downloadFile(id);

    const fileName = name.replace( /\\/g, '/' ).replace( /.*\//, '' );

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    FileSaver.saveAs(res.data, fileName);
    toast.success('Document download started on your computer', {
      id: toastId,
    });
  };

  const handleUpload = async (e) => {
    const toastId = toast.loading('Loading...');

    const formData = new FormData();

    formData.append('wot_id', params.id);
    formData.append('file', e.target.files[0]);

    const res = await uploadFile(formData);

    e.target.value = null;

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    toast.success('File was uploaded!', {
      id: toastId,
    });
  };

  return (
    <Box>
      <Card sx={{ marginTop: '35px' }}>
        <CardContent sx={cardContent}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">Attached Files</Typography>
            <Upload disabled={isLoading || !hasPermissionsWoEdit} handleUpload={handleUpload} title="Upload File" />
          </Box>

          <EditableTable
            handleDownload={handleDownload}
            useHook={useGetWorkOrderTaskFileQuery}
            useHookDelete={useDeleteWorkOrderTaskFileMutation}
            columns={columns}
            hasPermissionsWoEdit={hasPermissionsWoEdit}
            tableParams={tableParams}
            sortByType="DESC"
            sortByActive="created_at"
          />
        </CardContent>
      </Card>
    </Box>
  );
};

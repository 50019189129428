export const defaultValues = {
  to: '',
  cc: '',
  subject: '',
  description: '',
  send_contract: true,
  send_invoice: true,
  raiffeisen_bank: true,
  otp_bank: false,
  prepayment_before: false,
  prepayment_in: false,
  isDue_date: false,
  due_date: null
};

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { HeaderName, StyledDelete } from '../../../../../components/EditableTable/styles';
import { TYPE_INPUT } from '../../../../../components/EditableTable/constants';
import TextEdit from '../../../../../components/EditableTable/TextEdit';
import ButtonIcon from '../../../../../components/ButtonIcon';

export const columns = ({
  handleOpenDeleteModal,
  handleOpen,
  hasPermissionsWoEdit,
}) => ([
  {
    flex: 0.2,
    field: 'wo_item_text',
    minWidth: 250,
    headerName: 'Wo item text',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Wo item text</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'wo_item_text', row)}
        text={row.wo_item_text}
        labelInput="Wo item text"
        disabled={!hasPermissionsWoEdit}
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'quantity',
    minWidth: 180,
    headerName: 'QTY',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'QTY',
        }}
      >
        <Typography style={HeaderName}>QTY</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'quantity', row)}
        text={row.quantity}
        labelInput="QTY"
        disabled={!hasPermissionsWoEdit}
        type={TYPE_INPUT}
      />
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <ButtonIcon disabled={!hasPermissionsWoEdit} onClick={() => handleOpenDeleteModal(row.id)}>
        <StyledDelete />
      </ButtonIcon>
    ),
  },
]);

import React, { useState } from 'react';
import {
  Box, Card, Divider, Typography
} from '@mui/material';
import EditableTable from '../../../components/EditableTable';
import { useGetDepartmentTableQuery, useDeleteDepartmentMutation, useUpdateDepartmentMutation } from '../../../store/session';
import { columns } from './columns';
import Button from '../../../components/Button';
import CreateDepartment from './CreateDepartment/CreateDepartment';
import { usePermissions } from '../../../utils/hooks/usePermissions';

export const Department = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const hasPermissionsEdit = usePermissions(['invoice_edit', 'invoice_su']);

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  return (
    <Card sx={{
      mt: 3,
      padding: '1rem',
    }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}
      >
        <Typography variant="h3">Department</Typography>
        <Button disabled={!hasPermissionsEdit} onClick={handleOpen} title="Create" />
      </Box>

      <Divider />
      <EditableTable
        useHook={useGetDepartmentTableQuery}
        useHookDelete={useDeleteDepartmentMutation}
        useHookUpdate={useUpdateDepartmentMutation}
        columns={columns}
        sortByType="DESC"
        hasPermissions={hasPermissionsEdit}
        sortByActive="department_name"
        getRowHeight={() => 'auto'}
        getRowId={(row) => row.id}
        fixedCacheKey="department-table"
      />

      <CreateDepartment
        onClose={handleClose}
        open={openDrawer}
      />

    </Card>
  );
};

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import {
  HeaderName,
} from '../../../components/EditableTable/styles';
import LinkButton from '../../../components/LinkButton';

const styles = {
  color: '#337ab7',
  fontWeight: 'bold',
  fontSize: '14px',
  textDecorationLine: 'underline',
  cursor: 'pointer',
  wordBreak: 'break-word'
};

export const columnsCommanders = ({
  handleFilter,
  filters,
  hasPermissions,
}) => [
  {
    field: 'index',
    minWidth: 30,
    headerName: '№',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>№</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.index}</Typography>
    ),
  }, // index
  {
    flex: 0.2,
    field: 'pilot_name',
    minWidth: 180,
    headerName: 'Commanders',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Commanders</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.pilot_name || ''}
          handleFilter={handleFilter}
          placeholder="Commander name"
          value="pilot_name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <LinkButton path={`/training-module/flight-crew/${row.id}`}>
        <Typography sx={styles}>{row.pilot_name}</Typography>
      </LinkButton>
    ),
  }, // index
];

export const columnsFO = ({
  handleFilter,
  filters,
  hasPermissions,
}) => [
  {
    field: 'index',
    minWidth: 30,
    headerName: '№',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>№</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.index}</Typography>
    ),
  }, // index
  {
    flex: 0.2,
    field: 'pilot_name',
    minWidth: 150,
    headerName: 'First Officers',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>First Officers</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.pilot_name || ''}
          handleFilter={handleFilter}
          placeholder="FO name"
          value="pilot_name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <LinkButton path={`/training-module/flight-crew/${row.id}`}>
        <Typography sx={styles}>{row.pilot_name}</Typography>
      </LinkButton>
    ),
  }, // index
];

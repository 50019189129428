export default {
  MuiBreadcrumbs: {
    styleOverrides: {
      li: {
        '& > .MuiLink-root': {
          textDecoration: 'none',
        },
      },
    },
  },
};

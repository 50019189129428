import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FiltrationInput from '../../../../components/EditableTable/FiltrationInput';
import LinkButton from '../../../../components/LinkButton';
import {
  HeaderName, StyledDelete, StyledPencil,
} from '../../../../components/EditableTable/styles';
import ButtonIcon from '../../../../components/ButtonIcon';

export const columns = ({
  handleFilter,
  filters,
  hasPermissions,
  handleOpenDeleteModal,
}) => ([
  {
    flex: 0.2,
    field: 'block_name',
    minWidth: 200,
    headerName: 'Block Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Block Name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.block_name || ''}
          value="block_name"
          placeholder="Block Name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
      >
        {row.block_name}
      </Typography>
    ),
  },
  {
    width: 120,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => hasPermissions
      && (
      <>
        <LinkButton path={`/training-module/flight-crew/update-block/${row.id}`}>
          <StyledPencil />
        </LinkButton>
        {' '}
        <ButtonIcon onClick={() => handleOpenDeleteModal(row.id)}>
          <StyledDelete />
        </ButtonIcon>
      </>
      ),
  },
]);

import React from 'react';
import Drawer from '../../../../components/Drawer';
import Grid from '@mui/material/Grid';
import FormGroup from '../../../../form/components/FormGroup';
import { Controller, useForm } from 'react-hook-form';
import { cancelSx, footerSx } from '../../../Quotations/CreateQuotation/CharterDrawer/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { defaultValues } from './schema';
import { useParams } from 'react-router-dom';
import DatePicker from '../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import FieldError from '../../../../form/components/FieldError';
import { usePrintAllOdsMutation } from '../../../../store/session';
import toast from 'react-hot-toast';
import { ArrowRight } from 'mdi-material-ui';

const PrintDrawer = ({
  open,
  handleDrawerClose,
  aircraftName
}) => {
  const params = useParams();
  const [print, { isLoading: printLoading }] = usePrintAllOdsMutation();

  const {
    control,
    formState: { errors },
    setValue,
    reset,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const handleClose = () => {
    reset(defaultValues);
    handleDrawerClose();
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading. This will take some time.');
    if (params?.id) {
      data.aircraft_id = params.id;

      const result = await print(data);

      if (!result?.error) {
        toast.success(result?.data?.message, {
          id: toastId
        });

        handleClose();
      } else {
        toast.error(result.error.data?.message || result.error?.error, {
          id: toastId
        });
      }
    }
  };

  return (
    <Drawer
      title={`Print all ODS for ${aircraftName} Aircraft`}
      subTitle="If a date is not specified, the deadline will be set."
      open={open}
      onClose={handleClose}
    >
      <form>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          sx={{ flexGrow: 1, p: '30px' }}
        >
          <Grid item xs={5}>
            <FormGroup label="Date From (00:00:00)" hasError={!!errors.from}>
              <Controller
                name="from"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Year - Month - Day"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'from', 'YYYY-MM-DD');
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />

              <FieldError error={errors.from} />
            </FormGroup>
          </Grid>

          <Grid item xs={2} sx={{ alignSelf: 'center', textAlign: 'center' }}>
            <ArrowRight />
          </Grid>

          <Grid item xs={5}>
            <FormGroup label="Date To (23:59:59)" hasError={!!errors.to}>
              <Controller
                name="to"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Year - Month - Day"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'to', 'YYYY-MM-DD');
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />

              <FieldError error={errors.to} />
            </FormGroup>
          </Grid>

          <Box sx={footerSx}>
            <Button
              disabled={printLoading}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              Generate
            </Button>
            <Button
              sx={cancelSx}
              onClick={handleClose}
              disabled={printLoading}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </form>
    </Drawer>
  );
};

export default PrintDrawer;

import {
  Card, Chip, Grid, Typography
} from '@mui/material';
import React from 'react';
import { handleStatusChip } from '../../../../../constans/logistics';
import { changeKey } from '../../../../../utils/getOptions';
import { TextWithIcon, TextWrap } from '../../../style';
import { checkOrderStatus } from '../constants';

const MainInfo = (props) => {
  const { main = {}, orderInfo } = props;

  return (
    <Card
      sx={{ padding: '0 1rem 1rem 1rem' }}
    >
      <Grid container spacing={6}>
        {Object.entries(main).map(([key, value]) => {
          if (key === 'order_number') return null;
          if (key === 'description') return null;

          return (
            <Grid item xs={6} key={key}>
              <Typography sx={TextWithIcon}>
                <b style={{ textTransform: 'capitalize' }}>
                  {changeKey(key)}
                  :
                </b>
                {(key === 'status') ? (handleStatusChip(checkOrderStatus(value), '', orderInfo?.onTheWay?.ttn_code || '', (value === 'Installed'))) : (<Chip label={value} variant="outlined" sx={{ ...((key === 'part_type') && { textTransform: 'capitalize' } ) }} />)}
              </Typography>
            </Grid>
          );
        })}
        {main?.description && (
          <Grid item xs={12}>
            <Typography sx={{ ...TextWithIcon, alignItems: 'start' }}>
              <b style={{ textTransform: 'capitalize' }}>
                Description:
              </b>
              <Typography sx={TextWrap}>{main?.description}</Typography>
            </Typography>
          </Grid>
        )}
        {orderInfo?.parts && orderInfo.parts.map((part) => (
          <>
            <Grid item xs={6} key={`name${part.id}`}>
              <Typography sx={TextWithIcon}>
                <b style={{ textTransform: 'capitalize' }}>
                  Part #
                  {part.id}
                  {' '}
                  name:
                </b>
                <Chip label={part.name} variant="outlined" />
              </Typography>
            </Grid>
            <Grid item xs={6} key={`quantity${part.id}`}>
              <Typography sx={TextWithIcon}>
                <b style={{ textTransform: 'capitalize' }}>
                  Part #
                  {part.id}
                  {' '}
                  quantity:
                </b>
                <Chip label={part.quantity} variant="outlined" />
              </Typography>
            </Grid>
            <Grid item xs={6} key={`type${part.id}`}>
              <Typography sx={TextWithIcon}>
                <b style={{ textTransform: 'capitalize' }}>
                  Part #
                  {part.id}
                  {' '}
                  type:
                </b>
                <Chip label={part.type} variant="outlined" />
              </Typography>
            </Grid>
          </>
        ))}
      </Grid>
    </Card>
  );
};

export default MainInfo;

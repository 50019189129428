import { array, object, string } from 'yup';

export const defaultValues = {
  payment_departments: [],
  salary_date: '',
};

export const schema = object().shape({
  payment_departments: array()
    .required()
    .label('Departments'),
  salary_date: string()
    .required()
    .label('Salary Date'),
});

import React, { useState } from 'react';
import {
  Box, TableContainer, Table, TableRow, TableBody, TableCell, IconButton, Collapse
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Loader from '../../../../components/Loader/ContentPageLoader';
import {
  header,
} from './styles';
import { Link } from 'react-router-dom';

const Row = ({ row, variant }) => {
  const [open, setOpen] = useState(true);
  const bgColor = 'rgba(241,214,15,0.2)';

  if (variant === 'nested') {
    return (
      <>
        <TableRow>
          <TableCell sx={header}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            { row.doc_name }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases" sx={{ backgroundColor: bgColor }}>
                  <TableBody>
                    {row.docs.map((docRow) => (
                      <TableRow key={docRow.id}>
                        <TableCell sx={{ width: '300px', color: 'black', fontWeight: 'bold' }}>
                          <Link to={`/training-module/flight-crew/${docRow.crew_id}`} target="_blank">
                            {docRow.pilot_name}
                          </Link>
                        </TableCell>
                        <TableCell sx={{ color: 'black', fontWeight: 'bold' }}>{docRow.valid_till}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <TableRow sx={{ backgroundColor: bgColor, padding: '1rem' }}>
      <TableCell sx={{
        paddingBottom: '3px !important',
        paddingTop: '3px !important',
        width: '300px',
        color: 'black',
        fontWeight: 'bold'
      }}
      >
        <Link to="/training-module/training-programs" target="_blank">
          {row.program_name}
        </Link>
      </TableCell>
      <TableCell sx={{
        paddingBottom: '3px !important', paddingTop: '3px !important', color: 'black', fontWeight: 'bold'
      }}
      >
        {row.valid_till}
      </TableCell>
    </TableRow>
  );
};

const TrainingTable = ({ useGetHook, variant = 'flat' },) => {
  const [getTrainings, { data, isLoading: isTrainingLoading }] = useGetHook();

  if (!data && !isTrainingLoading) {
    getTrainings();
  }
  if (!data || isTrainingLoading) {
    return <Loader />;
  }

  return (
    <TableContainer sx={{ mb: 5, ...(variant === 'flat' && { padding: '0 1.5rem' }) }}>
      <Table>
        <TableBody>
          {data.map((row) => (
            <Row key={row.id} row={row} variant={variant} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TrainingTable;

import { RefreshRounded } from '@mui/icons-material';
import AirlinesIcon from '@mui/icons-material/Airlines';
import {
  Box, Card, Grid,
  Tab,
  Tabs,
  Typography, useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import ApproveModal from '../../../components/ApproveModal';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import { useAppDispatch } from '../../../hooks/toolkitHooks';
import { useGetAircraftBodyTableQuery, useGetAircraftListQuery, useReturnToStoragePartMutation } from '../../../store/session';
import { fetchCurrencies, useGetCertificatesForDrawerMutation, useOpenCertificateMutation } from '../../../store/slices/logisticsSlice';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { btnS, headerBtn, headerBtnS } from '../../Invoices/style';
import { TextWithIcon, YellowButton } from '../../Logistics/style';
import FileDrawer from '../../TrainingModule/FileDrawer/FileDrawer';
import {
  activeTypeSx, boxAircraft, boxAircraftSx, containerAircraft, containerAircraftSx, typeSx
} from '../MaintenanceACFT/styles';
import { columns } from './columns';

const AircraftBody = () => {
  const { currencies } = useSelector((state) => state.logistics);
  const dispatch = useAppDispatch();
  const maxWidth1000px = useMediaQuery('(max-width:1000px)');
  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const maxWidth768px = useMediaQuery('(max-width:840px)');
  const [refetchTable, setRefetchTable] = useState(0);
  const [activeAircraft, setActiveAircraft] = useState(null);
  const [approveModalState, setApproveModalState] = useState({
    isOpen: false,
    itemId: null
  });
  const [fileDrawerState, setFileDrawerState] = useState({
    isOpen: false,
    type: null,
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });
  const { data: AircraftList } = useGetAircraftListQuery();
  const [getFiles, { isLoading: isLoadingFiles }] = useGetCertificatesForDrawerMutation();
  const [openFile] = useOpenCertificateMutation();
  const [returnToStorage, { isLoading: isLoadingReturnToStorage }] = useReturnToStoragePartMutation();
  const [tabValue, setTabValue] = useState('aircraft');

  const aircraftBodyReturnToStoragePermission = usePermissions('aircraft_body_return_to_storage');

  const handleRefreshTable = () => {
    setRefetchTable(refetchTable + 1);
  };

  const aircraftOptions = [...(AircraftList || [])?.map((aircraft) => ({
    label: aircraft.aircraft_registration,
    value: aircraft.id
  })), { value: '', label: '-' }];

  const handleOpenTask = (taskId) => {
    window.open(`/maintenance/work-order-task/update/${taskId}`, '_blank', 'noreferrer');
  };

  const getFileList = async ({ part_id, order_id }, callback) => {
    const res = await getFiles({ part_id, order_id });

    if (res?.error) {
      toast.error('Unable to get file list.');

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const handleFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    if (id) {
      getFileList(id, (list) => setFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: list,
        name,
        currentName
      })));
    }

    setFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
  };

  const handleOpenFile = async (id) => {
    const toastId = toast.loading('Opening...');

    await openFile(id).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleChangeTab = (_, value) => {
    setTabValue(value);
  };

  const handleSetActiveType = (value) => {
    setActiveAircraft(prev => ((prev === value) ? null : value));
  };

  const handleReturnToStorage = async (id) => {
    const toastId = toast.loading('Returning...');

    const res = await returnToStorage({ id });

    if (res?.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    handleRefreshTable();
    
    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleOpenApproveReturn = (isOpen = false, itemId = null) => {
    setApproveModalState(prev => ({
      ...prev,
      isOpen,
      itemId
    }));
  };

  useEffect(() => {
    dispatch(fetchCurrencies());
  }, []);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card sx={{
          mt: 3,
          padding: '1rem'
        }}
        >
          <Box
            sx={{ ...headerBtn, ...(maxWidth1000px && headerBtnS) }}
          >
            <Typography noWrap variant="h3" sx={{ ...TextWithIcon, ...(maxWidth1000px && { alignSelf: 'start' }) }}>
              <AirlinesIcon sx={{ fontSize: '2rem' }} />
              Aircraft Body
            </Typography>

            <Box>
              <Tabs
                variant="fullWidth"
                centered
                value={tabValue}
                aria-label="basic tabs example"
                onChange={handleChangeTab}
                sx={{ mt: 0, pt: 0 }}
              >
                <Tab label="Parts" sx={{ mr: 5 }} value="aircraft" />
                <Tab label="Consumable" value="consumable" />
              </Tabs>
            </Box>
            <Box />
          </Box>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card sx={{
          padding: '1rem'
        }}
        >
          <Box sx={maxWidth1000px ? {
            display: 'flex',
            flexDirection: 'column',
            gap: 4
          } : {
            display: 'grid',
            gridTemplateColumns: '1fr 9rem',
            alignItems: 'center',
            mb: 3
          }}
          >
            <Box sx={{ ...(containerAircraft), ...(maxWidth768px && containerAircraftSx) }}>
              <Box sx={{ ...(boxAircraft), ...(maxWidth768px && boxAircraftSx) }}>
                {AircraftList?.map(type => (
                  <Button
                    title={type.aircraft_registration}
                    sx={{ ...typeSx, ...((type.id === activeAircraft) && activeTypeSx) }}
                    key={type.id}
                    onClick={() => handleSetActiveType(type.id)}
                  />
                ))}
              </Box>
            </Box>
            <Box sx={{
              display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 2, justifySelf: 'end'
            }}
            >
              <Button
                startIcon={<RefreshRounded />}
                sx={{ ml: 2, ...(maxWidth800px && btnS), ...YellowButton }}
                title="Refresh"
                onClick={handleRefreshTable}
              />
            </Box>
          </Box>
          <EditableTable
            useHook={useGetAircraftBodyTableQuery}
          // useHookDelete={useDeleteStorageOrderMutation}
            columns={columns}
            sortByType="DESC"
            sortByActive="id"
            getRowHeight={() => 'auto'}
            refetchTable={refetchTable}
            getRowId={(row) => row.id}
            currencies={currencies}
            handleOpenTask={handleOpenTask}
            handleFileDrawer={handleFileDrawer}
            aircraftOptions={aircraftOptions}
            isLoadingReturnToStorage={isLoadingReturnToStorage}
            aircraftBodyReturnToStoragePermission={aircraftBodyReturnToStoragePermission}
            handleOpenApproveReturn={handleOpenApproveReturn}
            tableParams={{
              part_type: tabValue === 'aircraft' ? 'aircraft_body_all_parts' : tabValue,
              ...(activeAircraft && { aircraft_body_id: activeAircraft })
            }}
            style={{
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: 0,
                padding: '10px 0',
                boxSizing: 'content-box'
              }
            }}
          />
        </Card>

        <ApproveModal
          open={approveModalState.isOpen}
          data={{
            text: 'Are you sure you want to return the spare part?',
            data: approveModalState,
            successButton: 'Send'
          }}
          onClose={() => handleOpenApproveReturn()}
          onApprove={({ data: { itemId } }) => {
            handleOpenApproveReturn();
            handleReturnToStorage(itemId);
          }}
        />

        <FileDrawer
          open={fileDrawerState.isOpen}
          onClose={() => handleFileDrawer(false)}
          title={fileDrawerState.name}
          itemName={fileDrawerState.currentName}
          itemsList={fileDrawerState.fileList}
          // uploadFile={(file) => updateFiles(fileDrawerState.currentId, file, fileDrawerState.type, () => handleFileDrawer(true, fileDrawerState.currentId, fileDrawerState.type, fileDrawerState.name, fileDrawerState.currentName))}
          // deleteFileCallback={handleRefetchTable}
          // isAttachLoading={isAttachLoading}
          currentOpenFile={handleOpenFile}
          hasPermissions={false}
          isLoadingFiles={isLoadingFiles}
          hasUploadButton={false}
        />
      </Grid>
    </Grid>
  );
};

export default AircraftBody;

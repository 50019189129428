export const WIDTH = 690;

export const pdfItem = {
  border: '1px solid #000',
  p: '2px',
  width: '100%',
  borderBottom: 'none',
  borderLeft: 'none'
};

export const imgItem = {
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  // minWidth: '290px',
  borderBottom: 'none',
  borderLeft: 'none'
};

export const pdfItemFullWidth = {
  border: '1px solid #000',
  width: '100%',
  p: '2px',
};

export const itemSx = {
  border: '1px solid #000',
  width: '100%',
  borderRight: 'none'
};

export const itemPaddingSx = {
  border: '1px solid #000',
  p: '2px',
  borderLeft: 'none',
  borderRight: 'none'
};

export const itemPaddingXs = {
  border: '1px solid #000',
  p: '2px',
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: 'none'
};

export const subItemSx = {
  borderBottom: '1px solid #000',
  minHeight: '35px',
  p: '2px',
  borderRight: 'none',
  borderLeft: 'none',

};

export const uploadedSign = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  img: {
    maxWidth: '45px',
    maxHeight: '45px',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
};

export const itemPadding = {
  border: '1px solid #000',
  p: '2px',
  borderLeft: 'none',
  borderBottom: 'none'
};

export const row = {
  '-webkit-flex-wrap': 'initial',
  borderLeft: '1px solid #000',
  width: '100%',
};

export const style = {
  backgroundColor: '#fff',
  padding: '16px',
  fontSize: '12px',
  lineHeight: '1',
  '& td, th': {
    padding: 0,
  },

  '& table': {
    borderSpacing: 0,
    borderCollapse: 'collapse',
    '& img': {
      width: '50px'
    },
  },

  '& .table-wrap .table': {
    margin: '0px',
    border: '1px solid #e7ecf1',
    borderCollapse: 'collapse',
    width: ' 100%',
  },

  '& .table-wrap': {
    width: ' 100%',
    maxWidth: '100%',
    margin: '0px',

    // border: '1px solid #e7ecf1',
    // borderCollapse: 'collapse',

  },

  '& .table > tbody > tr > td': {
    lineHeight: '1',
    paddingLeft: '2px',
    paddingTop: '1px',
    paddingBottom: '1px',
    fontSize: '12.6px',
    border: '1px solid #e7ecf1',
    // width: ' 100%',
    borderCollapse: 'collapse',
    verticalAlign: 'top',
  }
};

export const dropdownMenuSx = {
  '& .MuiPaper-root': {
    p: 0,
    mt: '-50px',
  }
};

export const itemSx = {
  p: '5px 20px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f5f5f5'
  }
};

export const TitleContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const typesContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  flexWrap: 'wrap',
  maxHeight: '158px',
  overflowY: 'auto',
  backgroundColor: '#F5F5F7',
  padding: '15px',
  borderRadius: '8px',
};

export const downloadBtn = {
  backgroundColor: '#659be0',
};

export const headerSx = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '15px',
  // flexDirection: { xs: 'column', lg: 'row' }
};

export const otherBtn = {
  backgroundColor: '#a4926c',
  borderRadius: '6px',
};

export const headerRow = {
  minWidth: '350px',
  // alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  // maxHeight: '153px',
  overflowY: 'auto',
  backgroundColor: '#F5F5F7',
  padding: '15px',
  borderRadius: '8px',
};

export const typeSx = {
  color: '#fff',
  backgroundColor: '#ACB5C3',
  borderRadius: '25px',
  whiteSpace: 'nowrap',
  height: '40px',
  p: '6px 12px',
  textTransform: 'capitalize',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: '#8e9bae !important'
  }
};

export const activeTypeSx = {
  backgroundColor: '#36918b',
  '&:hover': {
    backgroundColor: '#36918b !important'
  }
};

export const tableContainer = {
  mt: '50px',
  '& .row-m': {
    backgroundColor: '#F0FFF0',
    '&:hover': {
      backgroundColor: '#F0FFF0',
    }
  },
  '& .row-f': {
    backgroundColor: '#F0FFFF',
    '&:hover': {
      backgroundColor: '#F0FFFF',
    }
  },
  '& .row-cnld': {
    backgroundColor: '#ec7063',
    '&:hover': {
      backgroundColor: '#ec7063 !important',
    }
  }
};

export const types = {
  flexDirection: 'column',
};

export const header = {
  display: 'flex',
  flexDirection: 'column',
};

export const typesSmall = {
  minWidth: '100%'
};

export const actionPerformed = {
  padding: '8px 16px',
  height: 'unset',
};

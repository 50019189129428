import {
  object, string,
} from 'yup';

export const schema = object().shape({
  name: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Name'),
  description: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Description'),
  title: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Title'),
  task: string()
    .nullable()
    .label('Task'),
  ref: string()
    .nullable()
    .label('Ref'),
  interval: string()
    .nullable()
    .label('Interval'),
  zone: string()
    .nullable()
    .label('Zone'),
  access: string()
    .nullable()
    .label('Access'),
  action_taken: string()
    .nullable()
    .label('Action taken'),
  task_group: string()
    .nullable()
    .label('Task group'),
  ata: string()
    .nullable()
    .label('Ata'),
});

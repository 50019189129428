import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BalanceIcon from '@mui/icons-material/Balance';
import { Grid, InputAdornment } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FieldError from '../../../form/components/FieldError';
import FormGroup from '../../../form/components/FormGroup';
import Input from '../../../form/components/Input';
import Select from '../../../form/components/Select';
import { useAppDispatch } from '../../../hooks/toolkitHooks';
import { fetchCurrencies, useGetUnitsListQuery } from '../../../store/slices/logisticsSlice';
import { getOptions } from '../../../utils/getOptions';

const PriceInput = ({ control, errors, sm = 6 }) => {
  const { data: units } = useGetUnitsListQuery({ type: 'mass' });
  const dispatch = useAppDispatch();

  const { currencies = [] } = useSelector((state) => state.logistics);

  useEffect(() => {
    if (!Array.isArray(currencies)) dispatch(fetchCurrencies());
  }, [currencies]);

  return (
    <>
      <Grid item xs={12} sm={sm}>
        <FormGroup required label="Price" hasError={!!errors.ap_fuel_price}>
          <Controller
            name="ap_fuel_price"
            control={control}
            render={({ field }) => (
              <Input
                placeholder="Price"
                {...field}
              />
            )}
          />
          <FieldError error={errors.ap_fuel_price} />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={sm}>
        <FormGroup required label="Currency" hasError={!!errors.currency}>
          <Controller
            name="currency"
            control={control}
            render={({ field }) => (
              <Select
                placeholder="Select Currency"
                {...field}
                options={(Array.isArray(currencies) ? currencies : []).map((item) => ({ value: item.label, label: item.label }))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon sx={{ ...(field?.value && { color: '#626477' }) }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <FieldError error={errors.currency} />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={sm}>
        <FormGroup required label="Unit" hasError={!!errors.unit_id}>
          <Controller
            name="unit_id"
            control={control}
            render={({ field }) => (
              <Select
                placeholder="Select Unit"
                {...field}
                options={getOptions(units)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BalanceIcon sx={{ ...(field?.value && { color: '#626477' }) }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <FieldError error={errors.unit_id} />
        </FormGroup>
      </Grid>
    </>
  );
};

export default PriceInput;

import { object, string } from 'yup';

export const defaultValues = {
  to: '',
  cc: 'ops@fleetair.eu',
  subject: '',
  description: '',
};

export const schema = object().shape({
  to: string()
    .required('${label} cannot be blank.')
    .label('To'),
  description: string()
    .required('${label} cannot be blank.')
    .label('Message'),
});

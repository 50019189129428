import React, { useState } from 'react';
import { Box } from '@mui/material';
import EditableTable from '../../../../../components/EditableTable';
import { columns } from './columns';
import Typography from '@mui/material/Typography';
import {
  useDownloadUserInvoiceMutation,
  useGetUserInvoiceRequestsQuery,
  useDeleteUserInvoiceMutation,
  useGetProofOfPaymentMutation,
} from '../../../../../store/session';
import { STATUSES } from '../../../../../constans/invoiceRequest';
import { UserInvoicesContainer } from './style';
import toast from 'react-hot-toast';
import FileSaver from 'file-saver';
import InfoModal from '../../../../../components/InfoModal';

const InvoiceList = () => {
  const [downloadInvoice] = useDownloadUserInvoiceMutation();
  const [isModalReasonOpen, setIsModalReasonOpen] = useState(false);
  const [reasonText, setReasonText] = useState('');
  const [getProofOfPayment, { isLoading: FileLoading }] = useGetProofOfPaymentMutation();

  const handlePreviewPoP = async (id) => {
    const toastId = toast.loading('Loading...');

    await getProofOfPayment(id).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleDownloadInvoice = async ({ id, fileName }) => {
    const toastId = toast.loading('Downloading...');
    await downloadInvoice(id).then((res) => {
      if (res.error) {
        toast.error(res.error.data?.message, {
          id: toastId,
        });

        return;
      }

      FileSaver.saveAs(res.data, fileName);
      toast.success('Success!', {
        id: toastId,
      });
    });
  };

  const handleOpenReasonModal = (text) => {
    setIsModalReasonOpen(true);
    setReasonText(text);
  };

  return (
    <Box sx={UserInvoicesContainer}>
      <Typography
        variant="subtitle1"
        noWrap
        sx={{
          textTransform: 'capitalize', display: 'flex', mb: 2, fontWeight: 600
        }}
      >
        Your Invoice Requests:
      </Typography>

      <EditableTable
        useHook={useGetUserInvoiceRequestsQuery}
        useHookDelete={useDeleteUserInvoiceMutation}
        getRowClassName={(params) => {
          const status = STATUSES.find(item => item.value === params.row.payment_status);

          return `row-${status?.tag}`;
        }}
        columns={columns}
        sortByType="DESC"
        getRowHeight={() => 'auto'}
        sortByActive="created_at"
        getRowId={(row) => row.id}
        onOpenReasonModal={handleOpenReasonModal}
        fixedCacheKey="invoice-requests-table"
        statusOptions={STATUSES}
        openProofOfPayment={handlePreviewPoP}
        handleDownloadInvoice={handleDownloadInvoice}
      />

      <InfoModal open={isModalReasonOpen} onClose={() => { setIsModalReasonOpen(false); }} reasonText={reasonText} />
    </Box>
  );
};

export default InvoiceList;

import React from 'react';
import { Card, CardContent } from '@mui/material';
import SupplierTable from './SupplierTable';

const Supplier = () => (
  <Card sx={{ mt: 3 }}>
    <CardContent>
      <SupplierTable title="Manage Suppliers" />
    </CardContent>
  </Card>
);

export default Supplier;

import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from '../../../../../form/components/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '../../../../../components/Button';
import moment from 'moment';
import { Controller, useFieldArray } from 'react-hook-form';
import { useGetCountryListQuery, useGetServiceSuppliersListMutation } from '../../../../../store/session';
import { OPTIONS_TYPE } from '../../../../../constans/flights';
import { DATE_FORMAT } from '../../../../../constans/formats';
import {
  additionalValuesContainer, controllerContainer, deleteIcon, serviceContainer
} from './styles';
import { getOptions } from '../../../../../utils/getOptions';
import { useParams } from 'react-router-dom';
import PermitOrderDrawer from './PermitForm/PermitOrderDrawer';

const PermitsForm = ({ control, getValues, watch }) => {
  const [suppliers, setSuppliers] = useState({});
  const [isSuppliersLoaded, setIsSuppliersLoaded] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [permitData, setPermitData] = useState({});
  const params = useParams();
  const {
    fields,
    update,
    append,
    remove,
  } = useFieldArray({
    name: 'planing_permit',
    control,
  });

  const { data: countries } = useGetCountryListQuery();
  const [suppliersList] = useGetServiceSuppliersListMutation();

  const countryOptions = countries?.map(country => ({
    label: country.country_name,
    value: country.id
  }));

  const handleAddFirstServiceValues = (id) => {
    append({
      pl_perm_country: '',
      pl_perm_flight_id: id,
      pl_perm_hop: [{}],
      pl_perm_service: 0,
      pl_perm_supplier: '',
    });
  };

  const hopOptions = getValues()?.planing_hop?.map((item) => ({
    label: `${moment(item.pl_hop_departure).format(DATE_FORMAT)} ${item.pl_hop_prefix} ${item.pl_hop_from.split('/')[0]} - ${item.pl_hop_to.split('/')[0]}`,
    value: item.pl_hop_id,
  }));

  const handleAddHope = (index, data) => {
    update(
      index,
      {
        ...data,
        pl_perm_hop: [...(data.pl_perm_hop || []), { id: Date.now().toString() }]
      }
    );
  };

  const handleChangeCountry = async (e, index, id) => {
    const planingPermit = watch(`planing_permit.${index}`);

    const service_id = getValues(`planing_permit.${index}.pl_perm_service`);
    const res = await suppliersList({ country_id: e.target.value, service_id: service_id || 0 });

    update(index, {
      ...planingPermit,
      pl_perm_country: e.target.value
    });

    setSuppliers((prevData) => ({ ...prevData, [id]: getOptions(res.data) }));
  };

  const handleChangeCAA = async (e, index) => {
    const planingPermit = watch(`planing_permit.${index}`);

    update(index, {
      ...planingPermit,
      pl_perm_supplier: e.target.value
    });
  };

  const handleChangeType = async (e, onChange, index, id) => {
    const planingPermit = watch(`planing_permit.${index}`);

    onChange(e);

    update(index, {
      ...planingPermit,
      pl_perm_service: e.target.value
    });

    const country_id = getValues(`planing_permit.${index}.pl_perm_country`);
    const res = await suppliersList({ country_id, service_id: e.target.value });

    setSuppliers((prevData) => ({ ...prevData, [id]: getOptions(res.data) }));
  };

  const handleDelete = (index, i) => {
    const hops = watch(`planing_permit.${index}`);

    update(index, {
      ...hops,
      pl_perm_hop: hops.pl_perm_hop.filter((item, itemIndex) => itemIndex !== i)
    });
  };

  const handleCloseDrawer = () => {
    setPermitData({});
    setOpenDrawer(false);
  };

  const handleOpenDrawer = (item) => {
    setPermitData(item);
    setOpenDrawer(true);
  };

  useEffect(() => {
    if (fields.length && !isSuppliersLoaded) {
      setIsSuppliersLoaded(true);
      fields.forEach(async(item) => {
        if (item.pl_perm_country) {
          const res = await suppliersList({ country_id: item.pl_perm_country, service_id: item.pl_perm_service || 0 });
          const { data } = res;

          if (data) {
            setSuppliers((prevSuppliers) => ({
              ...prevSuppliers,
              [item.itemId]: getOptions(data)
            }));
          }
        }
      });
    }
  }, [isSuppliersLoaded, fields]);

  return (
    <Box sx={serviceContainer}>
      {fields.map((permit, index) => (
        <Grid
          container
          spacing={5}
          key={permit.itemId || permit.id}
          sx={{
            display: 'flex', flexWrap: 'nowrap', gap: '10px', marginTop: 0, marginLeft: 0,
          }}
        >
          <Grid item xs={9} lg={9}>
            <Box sx={additionalValuesContainer}>
              <Box sx={controllerContainer}>
                <Controller
                  name={`planing_permit.${index}.pl_perm_country`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={countryOptions}
                      placeholder="Country"
                      label="Country"
                      onChange={(e) => {
                        field.onChange(e);
                        const id = permit.itemId || index;

                        handleChangeCountry(e, index, id); }}
                    />
                  )}
                />
                <Controller
                  name={`planing_permit.${index}.pl_perm_service`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={OPTIONS_TYPE}
                      defaultValue={0}
                      label="Type"
                      placeholder="Type"
                      onChange={(e) => {
                        const id = permit.itemId || index;
                        handleChangeType(e, field.onChange, index, id); }}
                    />
                  )}
                />
                <Box sx={{
                  width: '100%', display: 'flex', flexDirection: 'column', gap: '10px'
                }}
                >
                  {permit.pl_perm_hop?.map((item, subIndex) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }} key={item.id}>
                      <Controller
                        key={subIndex}
                        name={`planing_permit.${index}.pl_perm_hop.${subIndex}.pl_hop_id`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={hopOptions}
                            defaultValue={item.pl_hop_id}
                            label="Hops"
                            placeholder="Hops"
                          />
                        )}
                      />

                      {permit.pl_perm_hop.length > 1 && (
                      <Box sx={{ ...controllerContainer, ...{ display: 'flex', justifyContent: 'flex-end' } }}>
                        <DeleteIcon sx={deleteIcon} onClick={() => handleDelete(index, subIndex)} />
                      </Box>
                      )}
                    </Box>
                  ))}

                  <Button title="Add hops" onClick={() => handleAddHope(index, permit)} />
                </Box>
                <Controller
                  name={`planing_permit.${index}.pl_perm_supplier`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={suppliers[permit.itemId] || suppliers[index] || []}
                      label="CAA"
                      placeholder="CAA"
                      onChange={(e) => {
                        field.onChange(e);
                        handleChangeCAA(e, index);
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2} lg={2}>
            <Box sx={{ ...controllerContainer, ...{ display: 'flex', justifyContent: 'flex-end' } }}>
              <Button
                sx={{ color: '#fff !important', minWidth: '207px' }}
                title="Permit Request"
                onClick={() => handleOpenDrawer(permit)}
              />
            </Box>
          </Grid>
          <Grid item xs={1} lg={1}>
            <Box sx={{ ...controllerContainer, ...{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' } }}>
              <DeleteIcon sx={deleteIcon} onClick={() => remove(permit.id)} />
            </Box>
          </Grid>
        </Grid>
      ))}
      <Box xs={{ maxWidth: '150px' }}>
        <Button title="ADD PERMIT" onClick={() => handleAddFirstServiceValues(+params.id)} />
      </Box>
      <PermitOrderDrawer open={openDrawer} onClose={handleCloseDrawer} permitData={permitData?.mailData} />
    </Box>
  );
};

export default PermitsForm;

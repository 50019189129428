import React from 'react';
import Box from '@mui/material/Box';
import DropDownMenu from '../../../components/DropDownMenu';
import {
  listContainer, header, dropdownMenuSx
} from './styles';
import LinkButton from '../../../components/LinkButton/Button';

const DropDown = ({
  open,
  onClose,
  anchorEl,
}) => (
  <DropDownMenu
    sx={dropdownMenuSx}
    transformOrigin={{
      vertical: 'right',
      horizontal: 'center',
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    open={open}
    onClose={onClose}
    anchorEl={anchorEl}
  >
    <Box>
      <Box sx={header}>
        Actions
      </Box>

      <Box sx={listContainer}>
        <LinkButton target="_blank" path="/directories/invoices/department">
          Department
        </LinkButton>
        <LinkButton target="_blank" path="/directories/invoices/currency">
          Currency
        </LinkButton>
      </Box>
    </Box>
  </DropDownMenu>
);

export default DropDown;

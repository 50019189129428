import { styled } from '@mui/material/styles';
import Pencil from 'mdi-material-ui/Pencil';
import Delete from 'mdi-material-ui/Delete';
import Copy from 'mdi-material-ui/ContentCopy';

export const StyledPencil = styled(Pencil)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const StyledDelete = styled(Delete)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));

export const StyledCopy = styled(Copy)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const headerCellContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  width: '100%',
};

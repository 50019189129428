export const FILTER_SIGNATURES_STATUSES = [
  { label: 'Signed', value: 'signed' },
  { label: 'Unsigned', value: 'unsigned' },
];

export const POSITIONS = [
  { label: 'FCM', value: 'FCM' },
  { label: 'OCC', value: 'OCC' },
  { label: 'Other', value: 'Other' },
];

export const YES_NO = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const FIRST_REPORT_TYPE = 'first_report';
export const SECOND_REPORT_TYPE = 'second_report';

export const REPORT_TYPES = [
  FIRST_REPORT_TYPE,
  SECOND_REPORT_TYPE,
];

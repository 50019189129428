import { Box } from '@mui/material';
import React from 'react';

/**
 * An `error` object for a form field always contains data for only one validation error,
 * even if the field has multiple errors.
 * The priority is determined by the validation scheme.
 * For example:
 * the `name` field has the validation scheme: `name: string().min(5).required()`
 * If the value of this field is an empty string, then the field actually has two validation
 * errors that match the `min` and `required` rules.
 * However, the `error` object will only contain data for the `min` rule,
 * because it appears earlier in the validation schema.
 */

const FieldError = React.memo(({ error, sx = {} }) => {
  if (!error) {
    return null;
  }

  const { message } = error;

  return (
    <Box
      sx={{
        ...{
          position: 'absolute',
          color: '#FF4D49',
          fontWeight: 500,
          fontSize: '12px',
        },
        ...sx 
      }}
    >
      {message}
    </Box>
  );
});

export default FieldError;

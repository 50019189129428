import React, { useState } from 'react';
import {
  Grid, Badge, ThemeProvider
} from '@mui/material';
import { DateCalendar, PickersDay, DayCalendarSkeleton } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { theme } from './styles';
import { useEventsCalendar } from './useCalendar';

const CustomDays = (props) => {
  const { day, outsideCurrentMonth } = props;
  const { eventsData, ...dayProps } = props;
  const [focused, setFocused] = useState(false);

  if (outsideCurrentMonth) {
    return <PickersDay {...dayProps} />;
  }

  const dayData = eventsData.find((item) => moment(item.training_date, 'DD-MM-YYYY').isSame(day, 'day'));

  let badgeContent = 0;
  let variant = '';

  if (dayData) {
    badgeContent = dayData.count;
    if (day.isSame(moment(), 'day')) {
      variant = 'today';
    }
    if (day.isBefore(moment(), 'day')) {
      variant = 'past';
    }
    if (day.isAfter(moment(), 'day')) {
      variant = 'future';
    }
  }

  return (
    <Badge badgeContent={badgeContent} color="primary" overlap="circular">
      <PickersDay
        variant={variant}
        onMouseEnter={() => setFocused(true)}
        onMouseLeave={() => setFocused(false)}
        {...dayProps}
      />
    </Badge>
  );
};

const CustomCalendar = ({
  PREV_MONTH, CURR_MONTH, NEXT_MONTH, eventsData, isLoading
}) => (
  <>
    <DateCalendar
      readOnly
      views={['day']}
      slots={{
        leftArrowIcon: () => null,
        rightArrowIcon: () => null,
        day: (dayProps) => <CustomDays {...dayProps} eventsData={eventsData} />
      }}
      slotProps={{
        previousIconButton: { disabled: true },
        nextIconButton: { disabled: true },
      }}
      defaultCalendarMonth={PREV_MONTH}
      loading={isLoading}
      renderLoading={() => <DayCalendarSkeleton />}
    />
    <DateCalendar
      readOnly
      views={['day']}
      slots={{
        leftArrowIcon: () => null,
        rightArrowIcon: () => null,
        day: (dayProps) => <CustomDays {...dayProps} eventsData={eventsData} />
      }}
      slotProps={{
        previousIconButton: { disabled: true },
        nextIconButton: { disabled: true },
      }}
      defaultCalendarMonth={CURR_MONTH}
      loading={isLoading}
      renderLoading={() => <DayCalendarSkeleton />}
    />
    <DateCalendar
      readOnly
      views={['day']}
      slots={{
        leftArrowIcon: () => null,
        rightArrowIcon: () => null,
        day: (dayProps) => <CustomDays {...dayProps} eventsData={eventsData} />
      }}
      slotProps={{
        previousIconButton: { disabled: true },
        nextIconButton: { disabled: true },
      }}
      defaultCalendarMonth={NEXT_MONTH}
      loading={isLoading}
      renderLoading={() => <DayCalendarSkeleton />}
    />
  </>
);

const EventsCalendar = (props) => {
  const { eventsData } = props;
  const {
    events,
    isLoading,
    PREV_MONTH,
    CURR_MONTH,
    NEXT_MONTH,
  } = useEventsCalendar(eventsData);

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        backgroundColor: '#F5F5F7',
        padding: '15px',
        borderRadius: '8px',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <CustomCalendar
            eventsData={events}
            isLoading={isLoading}
            PREV_MONTH={PREV_MONTH}
            CURR_MONTH={CURR_MONTH}
            NEXT_MONTH={NEXT_MONTH}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </Grid>
  );
};

export default EventsCalendar;

import React, { useEffect, useRef } from 'react';
import Drawer from '../../../../../../components/Drawer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormGroup from '../../../../../../form/components/FormGroup';
import FieldError from '../../../../../../form/components/FieldError';
import Input from '../../../../../../form/components/Input';
import { Controller, useForm } from 'react-hook-form';
import {
  cancelSx, footerSx, numberInputSx,
} from './styles';
import { defaultValues } from './shema';
import {
  useGetServiceSupplierQuery,
  useSendServiceMutation
} from '../../../../../../store/session';
import { useSelector } from 'react-redux';
import { SRVSUP_SLOT_ID } from '../../../../../../constans/flight';
import toast from 'react-hot-toast';

const ServiceFormDrawer = ({
  open: {
    id: supplierId,
    allServices,
    serviceLabels,
    additionalData
  }, onClose
}) => {
  const initValues = useRef();
  const {
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const [sendService, { isLoading: updating }] = useSendServiceMutation();

  const { data: supplierData, isLoading: supplierDataLoading, isFetching: supplierDataFetching } = useGetServiceSupplierQuery({ id: supplierId }, {
    refetchOnMountOrArgChange: true,
    skip: !supplierId || supplierId === SRVSUP_SLOT_ID
  });

  const isloading = supplierDataLoading || supplierDataFetching;

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const mailData = {
      ...data,
      to: data.to.split(' ').join('').split(','),
      cc: data.cc.split(' ').join('').split(','),
      message: data.description
    };

    await sendService(mailData).then((res) => {
      if (!res?.error) {
        toast.success('Email was sent successfully!', {
          id: toastId
        });
      } else {
        toast.error('Something went wrong.', {
          id: toastId
        });
      }
    });

    handleClose();
  };

  const {
    user
  } = useSelector((state) => state?.session);

  const ALFABET = 'A,B,C,D,E,F,G,H,I,J,K'.split(',');

  const setEmail = () => {
    if (supplierData && !isloading) {
      let a = 0;
      setValue('to', supplierData?.srvsup_email ?? '');
      setValue('cc', additionalData?.cc ?? 'ops@fleetair.eu');

      // Subject
      const airportLabel = additionalData?.pl_airport_label ?? '-';
      const aircraftLabel = additionalData?.pl_aircraft_label ?? '-';
      const fleetFirstDate = additionalData?.first_date ?? '-';
      const flightIndex = additionalData?.flight_index ?? '-';
      setValue('subject', `HANDLING REQ AT ${airportLabel} / ${aircraftLabel} / ${fleetFirstDate} (${flightIndex})`);

      // Message
      let arrangeServices = '';
      allServices?.forEach((srv) => {
        const serviceName = serviceLabels.find(srvLabel => parseInt(srvLabel.value, 10) === srv.pl_serv_service)?.label ?? '';
        arrangeServices += !arrangeServices.length ? serviceName.toUpperCase() : ` AND ${serviceName.toUpperCase()}`;
      });
      const companyName = supplierData?.srvsup_name;
      const currentUsername = user.name ?? '';
      setValue('description', `ATTN: ${companyName.toUpperCase()}

DEAR OPS,

PLEASE BE SO KIND TO ARRANGE ${arrangeServices} FOR ${additionalData?.inboundOutbound ?? ''} ${additionalData?.planning_hop_status ?? ''} FLIGHT FROM ${additionalData?.from ?? ''} TO ${additionalData?.to ?? ''} ${additionalData?.fuel_stop ? `WITH FUEL STOP AT ${additionalData?.fuel_stop}` : ''} AS FOLLOWS:

${ALFABET[a++]} OPERATOR (${additionalData?.operator?.icao_code} ICAO CODE): ${additionalData?.operator?.company_name}
LEGAL ADDRESS: ${additionalData?.operator?.address}

${ALFABET[a++]} TYPE A/C: ${additionalData?.aircraft?.type?.aircraft_type_name}
MTOW: ${additionalData?.aircraft?.aircraft_mtow}
REG: ${additionalData?.aircraft?.aircraft_registration}

${ALFABET[a++]} SCHEDULE (ALL TIME UTC):
${additionalData?.schedule}

${ALFABET[a++]} ${additionalData?.short_schedule}
${additionalData?.last_hop_text ? `${additionalData.last_hop_text} \n` : ''}${additionalData?.planning_hop_status === 'CARGO/CHARTER' ? `${ALFABET[a++]} CARGO TYPE: ${additionalData?.cargo} \nDIMENSION: ${additionalData?.dimensions} \nWEIGHT: ${additionalData?.weight} KG\n` : ''}
${ALFABET[a++]} CREW DETAILS ${additionalData?.capitanInfo ? `${additionalData.capitanInfo} \n` : ''}${additionalData?.foInfo ? `${additionalData.foInfo} \n` : ''}${additionalData?.engineerInfo ? `${additionalData.engineerInfo} \n` : ''}
${ALFABET[a++]} PAYMENT FOR LANDING /${arrangeServices}/ - BY MASTERCARD OR BY INVOICE.
PLEASE ISSUE AN INVOICE FOR MASTERCARD PAYMENT TO THE FOLLOWING COMPANY:
FLEET AIR INTERNATIONAL KFT
2220, VECSES, FO UT 218, HUNGARY
VAT: 13819822-2-42

${ALFABET[a++]} REFUELING ${additionalData?.fuel ?? 'TBA'}

${ALFABET[a++]} PLS ARRANGE GPU FOR START UP ENGINE

BEST REGARDS,
${currentUsername}
FLEET AIR OPS
TEL: +36 1 4453776 (24H)
FAX: +36 1 5772378
SITA: BUDFA7X AFTN: KAIRFRFX
EMAIL: OPS@FLEETAIR.EU
      `);

      initValues.current = getValues();
    }
  };

  const setSlotEmail = () => {
    setValue('to', additionalData?.slot?.email ?? '');
    setValue('subject', additionalData?.slot?.subject ?? '');
    setValue('description', additionalData?.slot?.message ?? '');

    initValues.current = getValues();
  };

  useEffect(() => {
    if (supplierId === SRVSUP_SLOT_ID) {
      setSlotEmail();
    } else {
      setEmail();
    }
  }, [supplierId, isloading]);

  return (
    <Drawer
      title="Mailer"
      open={!!supplierId}
      onClose={handleClose}
    >
      <form>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          sx={{ flexGrow: 1, p: '30px' }}
        >
          <Grid item xs={12}>
            <FormGroup label="To" required hasError={!!errors.doc_name}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="To"
                    {...field}
                  />
                )}
                control={control}
                name="to"
              />

              <FieldError error={errors.doc_name} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="CC" required hasError={!!errors.expired_treshold}>
              <Controller
                render={({ field } ) => (
                  <Input
                    sx={numberInputSx}
                    placeholder="CC"
                    {...field}
                  />
                )}
                control={control}
                name="cc"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="Subject" required hasError={!!errors.expired_treshold}>
              <Controller
                render={({ field } ) => (
                  <Input
                    sx={numberInputSx}
                    placeholder="Subject"
                    {...field}
                  />
                )}
                control={control}
                name="subject"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="Message">
              <Controller
                render={({ field } ) => (
                  <Input
                    multiline
                    rows={35}
                    sx={numberInputSx}
                    placeholder="Message"
                    {...field}
                  />
                )}
                control={control}
                name="description"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Box sx={footerSx}>
            <Button variant="contained" disabled={updating} type="submit" onClick={handleSubmit(onSubmit)}>
              Send
            </Button>
            <Button
              sx={cancelSx}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </form>
    </Drawer>
  ); };

export default ServiceFormDrawer;

import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormGroup from '../../../../../form/components/FormGroup';
import Input from '../../../../../form/components/Input';
import TypeCard from '../../../../Ods/OdsForm/TypeCard';

const Remaining = () => {
  const { control } = useFormContext();

  const maxWidth768px = useMediaQuery('(max-width:768px)');

  return (
    <TypeCard title="REMAINING" variant="yellow">
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="REMAINING DAYS">
            <Controller
              render={({ field } ) => (
                <Input
                  InputProps={{
                    disabled: true
                  }}
                  placeholder="0"
                  type="number"
                  {...field}
                />
              )}
              control={control}
              name="remaining_days"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="REMAINING FH">
            <Controller
              render={({ field } ) => (
                <Input
                  InputProps={{
                    disabled: true
                  }}
                  placeholder="0"
                  {...field}
                  type="number"
                />
              )}
              control={control}
              name="remaining_fh"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup label="REMAINING FC">
            <Controller
              render={({ field } ) => (
                <Input
                  InputProps={{
                    disabled: true
                  }}
                  placeholder="0"
                  type="number"
                  {...field}
                />
              )}
              control={control}
              name="remaining_fc"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </TypeCard>
  );
};

export default Remaining;

import {
  Box, Grid, Card, CardHeader, CardContent, Autocomplete, TextField,
} from '@mui/material';
import React from 'react';
import Select from '../../../../../../../form/components/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '../../../../../../../components/Button';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  useGetAirportListQuery, useGetServiceTypeListQuery
} from '../../../../../../../store/session';
import { getOptions } from '../../../../../../../utils/getOptions';
import {
  container,
  controllerContainer, deleteIcon, serviceContainer
} from './style';
import CurrencyInput from '../../../../../../../form/components/CurrencyInput';
import { useParams } from 'react-router-dom';

const OtherInvoicesForm = () => {
  const {
    control, Controller, setValue,
  } = useFormContext();
  const { id } = useParams();
  const { data: AirportList } = useGetAirportListQuery({ flightId: id });
  const { data: typeList } = useGetServiceTypeListQuery();
  const typeListOptions = getOptions(typeList);

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    name: 'actualValServices',
    control,
  });

  const handleAddRow = () => {
    append({
      itemId: Date.now(),
      av_serv_ap: null,
      av_serv_description: '',
      av_serv_plid: null,
      av_serv_price: '',
      av_serv_type: null,
    });
  };

  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader title="Other Invoices" />
      <CardContent sx={{ mt: 5, overflowX: 'scroll' }}>
        <Box sx={serviceContainer}>
          {fields.map((item, index) => (
            <Grid
              sx={container}
              key={item.itemId || item.id}
            >
              <Grid xs={3} lg={3}>
                <Controller
                  name={`actualValServices.${index}.av_serv_ap`}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      freeSolo
                      value={AirportList?.filter((AirportItem) => AirportItem.id === field.value)[0] || null}
                      options={AirportList}
                      getOptionLabel={(option) => `${option.airport_iata}/${option.airport_icao} (${option.airport_name})` || ''}
                      isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          inputRef={field.ref}
                          label="Airport"
                        />
                      )}
                      onChange={(e, value) => setValue(`actualValServices.${index}.av_serv_ap`, value.id)}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3} lg={3}>
                <Controller
                  name={`actualValServices.${index}.av_serv_type`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={typeListOptions}
                      label="Type"
                      placeholder="Type"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3} lg={3}>
                <Controller
                  name={`actualValServices.${index}.av_serv_description`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      row={4}
                      multiline
                      size="small"
                      label="Description"
                      placeholder="Description"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3} lg={2.5}>
                <Controller
                  name={`actualValServices.${index}.av_serv_price`}
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      placeholder="Price"
                      label="Price"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid tem xs={0.5} lg={0.5}>
                <Box sx={{
                  ...controllerContainer,
                  ...{
                    display: 'flex', justifyContent: 'flex-end', paddingRight: '20px', mt: '7px'
                  },
                }}
                >
                  <DeleteIcon sx={deleteIcon} onClick={() => remove(index)} />
                </Box>
              </Grid>
            </Grid>
          ))}

          <Box xs={{ ...{ maxWidth: '150px' } }}>
            <Button title="ADD ROW" onClick={handleAddRow} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OtherInvoicesForm;

import {
  string, object
} from 'yup';

export const defaultValues = {
  ttm_code: '',
  delivery_operator_id: '',
};

export const schema = object().shape({
  ttm_code: string()
    .required()
    .label('TTM Code'),
  delivery_operator_id: string()
    .required()
    .label('Delivery operator')
});

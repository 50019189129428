import React from 'react';
import { Card, CardContent } from '@mui/material';
import CaaTable from './CaaTable';

const Caa = () => (
  <Card sx={{ mt: 3 }}>
    <CardContent>
      <CaaTable title="Manage CAA" />
    </CardContent>
  </Card>
);

export default Caa;

export const header = {
  color: '#616161',
  fontWeight: '600 !important',
  whiteSpace: 'nowrap',
  minWidth: '125px',
  textTransform: 'capitalize !important',
};

export const body = {
  minWidth: '125px',
  fontSize: '13px !important',
  'input': {
    fontSize: '13px !important',
  }
};
import React from 'react';
import TypeCard from '../TypeCard';
import Grid from '@mui/material/Grid';
import Select from '../../../../../form/components/Select';
import FormGroup from '../../../../../form/components/FormGroup';
import DatePicker from '../../../../../form/components/DatePicker';
import { Controller } from 'react-hook-form';
import {
  useGetOdsStatusesQuery, useGetOdsPositionTypesQuery, useGetAircraftListQuery
} from '../../../../../store/session';
import { ODS_POST_OPTIONS } from '../../../../../constans/crew';
import moment from 'moment';
import { getOptions } from '../../../../../utils/getOptions';
import { selectOptionFormat } from '../../../../../utils/number';
import Input from '../../../../../form/components/Input';
import { getTimeDiffHm } from '../../../../../utils/getTime';
import { validateDatePickerValue } from '../../../../../utils/setDateValue';

export const getTimeDiff = (start, finish) => {
  if (start && finish) {
    let res = `${moment(finish).diff(moment(start), 'hour')}:${moment(finish).diff(moment(start), 'minute')}`;
    const dayDiff = moment(finish).diff(moment(start), 'day');
    const monthsDiff = moment(finish).diff(moment(start), 'month');
    const yearDiff = moment(finish).diff(moment(start), 'year');

    if (dayDiff > 0) res = `${dayDiff} Day${dayDiff > 1 ? 's' : ''}`;
    if (monthsDiff > 0) res = `${monthsDiff} Month${dayDiff > 1 ? 's' : ''}`;
    if (yearDiff > 0) res = `${yearDiff} Year${dayDiff > 1 ? 's' : ''}`;

    return res;
  }

  return '';
};

const Flight = ({
  control, pilotNameOptions, trigger, setCrewPost, setValue, watch,
}) => {
  const { data: odsStatuses } = useGetOdsStatusesQuery();
  const { data: odsPosition } = useGetOdsPositionTypesQuery();
  const { data: AircraftList } = useGetAircraftListQuery();

  const odsStatusesOptions = getOptions(odsStatuses);
  const odsPositionOptions = getOptions(odsPosition);
  const formattedAircraftList = selectOptionFormat(AircraftList, 'aircraft_registration');

  const start = watch('start');
  const finish = watch('finish');

  const filterPassedTime = (time) => {
    const startDate = moment(start).toDate();
    const endDate = moment(time).toDate();

    return startDate.getTime() < endDate.getTime();
  };

  return (
    <TypeCard title="POSITIONING ODS" variant="sky">
      <Grid container spacing={7} rowSpacing={2}>

        <Grid item xs={3} lg={3}>
          <FormGroup label="POSITION">
            <Controller
              render={({ field }) => (
                <Select
                  options={ODS_POST_OPTIONS}
                  placeholder="POSITION"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setCrewPost(e.target.value);
                    setValue('crew_id', '');
                    trigger('crew_id');
                  }}
                />
              )}
              control={control}
              name="crew_post"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={3} lg={3}>
          <FormGroup label="PILOT NAME">
            <Controller
              render={({ field }) => (
                <Select
                  options={pilotNameOptions}
                  disabled={!pilotNameOptions?.length}
                  placeholder="PILOT NAME"
                  {...field}
                />
              )}
              control={control}
              name="crew_id"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={3} lg={3}>
          <FormGroup label="AIRCRAFT">
            <Controller
              render={({ field }) => (
                <Select
                  options={formattedAircraftList}
                  placeholder="AIRCRAFT"
                  {...field}
                />
              )}
              control={control}
              name="aircraft_id"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={3} lg={3}>
          <FormGroup label="STATUS">
            <Controller
              render={({ field }) => (
                <Select
                  options={odsStatusesOptions}
                  placeholder="STATUS"
                  {...field}
                />
              )}
              control={control}
              name="ods_status"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={3} lg={3}>
          <FormGroup label="TYPE OF POSITIONING">
            <Controller
              render={({ field }) => (
                <Select
                  options={odsPositionOptions}
                  placeholder="STATUS"
                  {...field}
                />
              )}
              control={control}
              name="position_type"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={3} lg={3}>
          <FormGroup label="POSITIONING START">
            <Controller
              render={({ field: { onChange, ...req } }) => (
                <DatePicker
                  {...req}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD H:M"
                  dateFormat="yyyy-MM-dd HH:mm"
                  maxDate={finish ? moment(finish).toDate() : null}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => {
                    onChange(e);
                    trigger('start');
                  }}
                  value={validateDatePickerValue(req.value)}
                />
              )}
              control={control}
              name="start"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={3} lg={3}>
          <FormGroup label="POSITIONING FINISH">
            <Controller
              render={({ field: { onChange, ...req } }) => (
                <DatePicker
                  {...req}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD H:M"
                  dateFormat="yyyy-MM-dd HH:mm"
                  minDate={start ? moment(start).toDate() : null}
                  filterTime={filterPassedTime}
                  showMonthDropdown
                  showYearDropdown
                  onChange={onChange}
                  value={validateDatePickerValue(req.value)}
                />
              )}
              control={control}
              name="finish"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={3} lg={3}>
          <FormGroup label="TOTAL TRAINING TIME">
            <Controller
              render={({ field }) => (
                <Input
                  placeholder="TOTAL TRAINING TIME"
                  InputProps={{
                    readOnly: true,
                  }}
                  {...field}
                  value={getTimeDiffHm(start, finish)}
                  defaultValue={getTimeDiffHm(start, finish)}
                />
              )}
              control={control}
              name="total_positioning_time"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </TypeCard>
  );
};

export default Flight;

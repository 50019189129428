import React, { useEffect, useRef } from 'react';
import Drawer from '../../../../components/Drawer';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormGroup from '../../../../form/components/FormGroup';
import FieldError from '../../../../form/components/FieldError';
import Input from '../../../../form/components/Input';
import { Controller, useForm } from 'react-hook-form';
import {
  cancelSx, footerSx, numberInputSx,
} from './styles';
import { defaultValues } from './shema';
import moment from 'moment';
import { sumStrTime } from '../../../../utils/fdp';
import { useSelector } from 'react-redux';
import { useSendQuotationMutation } from '../../../../store/session';
import toast from 'react-hot-toast';

const CharterDrawer = ({
  open,
  onClose,
  PersonsList,
  hops,
  date,
  values,
  CompaniesList,
  AircraftTypesList,
  AircraftList,
  CargoList
}) => {
  const initValues = useRef();
  const [sendFleetMail] = useSendQuotationMutation();

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    reset,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const handleClose = () => {
    reset(initValues.current);
    onClose();
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const mailData = {
      ...data,
      to: data.to.split(' ').join('').split(','),
      cc: data.cc.split(' ').join('').split(','),
      message: data.description
    };

    await sendFleetMail(mailData).then((res) => {
      if (!res?.error) {
        toast.success('Quotation was sent successfully!', {
          id: toastId
        });
      } else {
        toast.error('Something went wrong.', {
          id: toastId
        });
      }
    });

    handleClose();
  };

  const {
    user
  } = useSelector((state) => state?.session);

  const setMail = () => {
    const currentCompany = CompaniesList.find(i => i.id === values('fleet_company'));
    const currentPerson = PersonsList?.find(i => i.id === values('fleet_company_person'));
    const currentAircraftType = AircraftTypesList?.find(i => i.id === values('fleet_typeofac'));
    const currentAircraft = AircraftList?.find(i => i.id === values('fleet_regofac'));
    const currentCargo = CargoList?.find(i => i.id === values('fleet_cargo'));
    const currentPayload = values('fleet_payload') ? `${values('fleet_payload')} KG` : 'TBA';
    const currentDimensions = values('fleet_dimensions') ?? 'TBA';
    const currentDateOfFlight = moment(values('fleet_date')).format('DD MMM YYYY');
    const currentPrice = values('fleet_price') ?? '';
    const currentExcluding = values('fleet_excluding') ?? '';
    const currentSubjectTo = values('fleet_subjectto') ?? '';
    const currentUsername = user.name ?? '';

    // To
    if (currentCompany?.company_email) {
      setValue('to', currentCompany?.company_email);

      if (currentPerson?.person_email) {
        setValue('to', `${currentCompany.company_email}, ${currentPerson.person_email}`);
      }
    }

    // Subject && Message
    if (hops) {
      const allHops = values('hops');
      let sumTime = '00:00';
      let posTime = '00:00';
      hops.forEach((notEmptyHop) => {
        if (notEmptyHop?.hop_flttime) {
          sumTime = sumStrTime(sumTime, notEmptyHop.hop_flttime);
        }
      });

      allHops.every((hop) => {
        if (hop.hop_empty === 0 || hop.hop_empty === false) {
          return false;
        }
        posTime = sumStrTime(posTime, hop.hop_flttime);

        return true;
      });

      const from = `${hops[0]?.hop_start?.split('/')?.[0] || ''}`;
      const end = `${hops[hops.length - 1]?.hop_end?.split('/')?.[0] || ''}`;
      setValue('subject', `${from} - ${end} / ${moment(date).format('DD MMM YYYY')} / ${currentCompany?.company_name}`);

      const mailBody = `Company: ${currentCompany?.company_name ?? ''}
ATTN: ${currentPerson?.person_name ?? ''}
Type of A/C: ${currentAircraftType?.aircraft_type_name}
A/C REG: ${currentAircraft?.aircraft_registration ?? ''} or subst
Route: ${from} - ${end}
POS.FLIGHT TO ${from}: ${posTime}
FLT.TIME (${from}-${end}): ${sumTime}
Cargo: ${currentCargo?.cargo_name}
Payload: ${currentPayload}
Dimensions: ${currentDimensions}
Date of Flight: ${currentDateOfFlight}
Price: ${currentPrice} EUR
Including: ALL INCLUDED (ramp & cargo handling assistance, screening, fuel, airport fee, accommodation, etc)
Excluding: ${currentExcluding}
Subject to: ${currentSubjectTo}

Estimated schedule (ALL TIME UTC):
${allHops.map((hop) => {
    const departureHopDate = moment(hop?.hop_departure).format('DD MMM YYYY');
    const fromHop = `${hop?.hop_start?.split('/')?.[1] || ''}`;
    const toHop = `${hop?.hop_end?.split('/')?.[1] || ''}`;
    const fromHopTime = moment(hop?.hop_departure).format('HHmm');
    const toHopTime = moment(hop?.hop_arriving).format('HHmm');
    const hopType = (hop?.hop_empty === 0 || hop?.hop_empty === false )
      ? 'CARGO FLIGHT'
      : 'POS FLIGHT';

    return `${departureHopDate} ${fromHop.substring(0, 4)}/${fromHopTime} - ${toHop.substring(0, 4)}/${toHopTime} - ${hopType}  \n`;
  }).join('')}
BEST REGARDS,
${currentUsername}
FLEET AIR OPS
TEL: +36 1 4453979 (24H)
FAX: +36 1 5772378
SITA: BUDFA7X
AFTN: KAIRFRFX
EMAIL: SALES@FLEETAIR.EU
`;

      setValue('description', mailBody);
    }
  };

  useEffect(() => {
    setMail();
    initValues.current = getValues();
  }, [date, values, hops]);

  return (
    <Drawer
      title="Mailer"
      open={open}
      onClose={handleClose}
    >
      <form>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          sx={{ flexGrow: 1, p: '30px' }}
        >
          <Grid item xs={12}>
            <FormGroup label="To" required hasError={!!errors.doc_name}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="To"
                    {...field}
                  />
                )}
                control={control}
                name="to"
              />

              <FieldError error={errors.doc_name} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="CC" required hasError={!!errors.expired_treshold}>
              <Controller
                render={({ field } ) => (
                  <Input
                    sx={numberInputSx}
                    placeholder="CC"
                    {...field}
                  />
                )}
                control={control}
                name="cc"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="Subject" required hasError={!!errors.expired_treshold}>
              <Controller
                render={({ field } ) => (
                  <Input
                    sx={numberInputSx}
                    placeholder="Subject"
                    {...field}
                  />
                )}
                control={control}
                name="subject"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <Controller
                render={({ field } ) => (
                  <Input
                    multiline
                    rows={35}
                    sx={numberInputSx}
                    placeholder="Description"
                    {...field}
                  />
                )}
                control={control}
                name="description"
              />

              <FieldError error={errors.expired_treshold} />
            </FormGroup>
          </Grid>

          <Box sx={footerSx}>
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
              Send
            </Button>
            <Button
              sx={cancelSx}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </form>
    </Drawer>
  ); };

export default CharterDrawer;

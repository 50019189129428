
import {
  object, string,
} from 'yup';

export const defaultValues = {
  instructor_name: '',
};

export const schema = object().shape({
  instructor_name: string()
    .required('${label} cannot be blank.')
    .label('Training Name'),
});

import { SvgIcon } from '@mui/material';
import React from 'react';

const StorageIcon = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path fill="currentColor" d="M16 4L7.94 0L0 4v1h1v11h2V7h10v9h2V5h1V4zM4 6V5h2v1H4zm3 0V5h2v1H7zm3 0V5h2v1h-2z" />
    <path fill="currentColor" d="M6 9H5V8H4v3h3V8H6v1zm0 4H5v-1H4v3h3v-3H6v1zm4 0H9v-1H8v3h3v-3h-1v1z" />
  </SvgIcon>
);

export default StorageIcon;

import { styled } from '@mui/material/styles';
import Delete from 'mdi-material-ui/Delete';

export const formControlLabel = {
  ml: '0'
};

export const footerSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  pt: '20px'
};

export const cancelSx = {
  background: '#e1e5ec',
  color: 'rgba(0, 70, 136, 0.8)',
  ':hover': {
    color: '#fff',
  }
};

export const addSx = {
  // minWidth: '300px',
  mt: '10px',
  whiteSpace: 'nowrap'
};

export const selectSx = {
  '& .MuiSelect-select': {
    textAlign: 'left'
  }
};

export const numberInputSx = {
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  }
};

export const StyledDelete = styled(Delete)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));

export const footerPrices = {
  pl: '10px',
};

export const noData = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: '10px',
  pt: '1.25rem',
};

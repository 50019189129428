import { Box, Typography } from '@mui/material';
import { HeaderName, StyledDelete, StyledPencil } from '../../../components/EditableTable/styles';
import Input from '../../../components/Input/Input';

export const columns = ({
  filters,
  handleFilter,
  handleOpenDeleteModal,
  handleUpdate
}) => ([
  {
    flex: 0.2,
    field: 'icao_code',
    minWidth: 120,
    headerName: 'ICAO code',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>ICAO Code</Typography>
        <Input
          size="small"
          placeholder="Icao code"
          defaultValue={filters.icao_code || ''}
          onChange={(e) => {
            handleFilter(e.target.value, 'icao_code');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        #
        {' '}
        {row.icao_code}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'company_name',
    minWidth: 230,
    headerName: 'Company',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Company</Typography>
        <Input
          size="small"
          placeholder="Company"
          defaultValue={filters.company_name || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'company_name');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
        }}
      >
        {row.company_name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'address',
    minWidth: 230,
    headerName: 'Address',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Address</Typography>
        <Input
          size="small"
          placeholder="Address"
          defaultValue={filters.company_address || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'company_address');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{ textTransform: 'capitalize' }}
      >
        {row.address}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'phone',
    minWidth: 230,
    headerName: 'Phone',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Phone</Typography>
        <Input
          size="small"
          placeholder="Phone"
          defaultValue={filters.company_phone || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'company_phone');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.phone}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'email',
    minWidth: 230,
    headerName: 'email',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Email</Typography>
        <Input
          size="small"
          placeholder="Email"
          defaultValue={filters.company_email || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'company_email');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
      >
        {row.email}
      </Typography>
    ),
  },
  {
    width: 130,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <StyledPencil onClick={() => { handleUpdate(row.id); }} />
        {' '}
        <StyledDelete onClick={() => { handleOpenDeleteModal(row.id); }} />
        {' '}
      </>
    ),
  },
]);
import { number, object, string } from 'yup';

export const defaultValues = {
  // aircraft_id: null,
  // storage: '',
  // wo_job_id: null,
  part_number: '',
  part_name: '',
  part_type: 'part',
  part_count: '',
  priority: 2,
  unit_id: null,
};

export const schema = object().shape({
  // aircraft_id: string()
  //   .nullable()
  //   .label('Aircraft'),
  // storage: string()
  //   .required('${label} cannot be blank.')
  //   .label('Storage'),
  // wo_job_id: string()
  //   .nullable()
  //   .label('WO Job'),
  part_number: string().required('${label} cannot be blank.').label('Part Number'),
  part_name: string().required('${label} cannot be blank.').label('Part Name'),
  part_type: string().required('${label} cannot be blank.').nullable().label('Part Type'),
  part_count: string().required('${label} cannot be blank.').label('Quantity'),
  priority: string().required('${label} cannot be blank.').label('Priority'),
  unit_id: number()
    .nullable()
    .when('part_type', {
      is: (val) => val === 'consumable',
      then: number().required('${label} cannot be blank if Training Finish selected.').integer(),
    })
});
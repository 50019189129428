import { Card, Typography } from '@mui/material';
import React from 'react';
import Button from '../../../components/Button';
import Drawer from '../../../components/Drawer';
import EditableTable from '../../../components/EditableTable';
import { OPTIONS_STATE } from '../../../constans/workOrder';
import { useGetAtrMaintenanceWoHistoryQuery } from '../../../store/session';
import { GreenButton } from '../../Logistics/style';

const columns = ({
  openWorkOrder
}) => ([
  {
    flex: 0.8,
    field: 'name',
    minWidth: 160,
    headerName: 'Name',
    sortable: false,
    renderCell: ({ row }) => (
      <Typography>
        {row?.name}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'state',
    minWidth: 140,
    headerName: 'State',
    sortable: false,
    renderCell: ({ row }) => {
      const text = OPTIONS_STATE.find(item => (
        +item.value === row.state
      ));

      return (
        <Typography>{row?.deleted ? 'DELETED' : text.label}</Typography>
      );
    },
  },
  {
    flex: 0.1,
    field: 'actions',
    minWidth: 140,
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <Button
        sx={{ ...GreenButton, fontSize: '0.75rem', padding: '0.4rem' }}
        onClick={() => openWorkOrder(row?.id)}
      >
        Open
      </Button>
    ),
  },
]);

const WOHistoryDrawer = (props) => {
  const {
    open,
    onClose,
    maintenance_id,
  } = props;

  const openWorkOrder = (id) => {
    window.open(`/maintenance/work-order/update/${id}`, '_blank');
  };

  return (
    <Drawer
      title="WO Task History"
      open={open}
      onClose={onClose}
    >
      <Card sx={{ mt: 5, padding: 4 }}>
        <EditableTable
          useHook={useGetAtrMaintenanceWoHistoryQuery}
          columns={columns}
          sortByType="DESC"
          sortByActive="id"
          tableParams={{
            maintenance_id
          }}
          getRowHeight={() => 'auto'}
          openWorkOrder={openWorkOrder}
          getRowId={(row) => row.id}
        />
      </Card>
    </Drawer>
  );
};

export default WOHistoryDrawer;

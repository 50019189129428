import {
  Box, Divider, Grid, TextField, Typography
} from '@mui/material';
import { Cog } from 'mdi-material-ui';
import React from 'react';
import { Controller } from 'react-hook-form';
import Button from '../../../../../components/Button';
import { CloseIcon } from '../../../../../components/Icons';
import FieldError from '../../../../../form/components/FieldError';
import FormGroup from '../../../../../form/components/FormGroup';
import { headerBtn } from '../../../../Invoices/style';
import { RedButton, TextWithIcon } from '../../../style';
import { content } from '../../NewOrder/components/style';
import { labels } from './constants';

const OrderItems = (props) => {
  const {
    label = 'Parts',
    items = [],
    handleRemoveItem = () => {},
    control,
    typeItems = 'current_part',
    errors = {},
    watch = () => {},
    xs = 6,
    currentPart
  } = props;

  return (
    <Grid sx={{ padding: '0 24px' }}>
      <Box sx={content}>
        <Box>
          <Typography noWrap variant="h5" sx={TextWithIcon}>
            <Cog />
            {label}
          </Typography>
        </Box>
              
        <Divider />
        {items.map((item, index) => (
          <Grid
            container
            key={item?.id}
            sx={{
              backgroundColor: 'white',
              padding: '15px',
              borderRadius: '8px'
            }}
          >
            <Grid item xs={12} lg={12}>
              <Box
                sx={{ ...headerBtn, display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography noWrap variant="h5">
                    #
                    {item?.id}
                  </Typography>
                </Box>

                <Button
                  sx={{ ...(RedButton), fontWeight: 600 }}
                  endIcon={<CloseIcon />}
                  size="small"
                  title="Delete"
                  onClick={() => handleRemoveItem(item?.id)}
                />

              </Box>
            </Grid>

            <Grid container spacing={6}>
              {Object.keys(item).filter(key => key !== 'id').map((key) => (
                <Grid item xs={xs}>
                  <FormGroup label={labels[key]} hasError={!!errors?.[typeItems]?.[index]?.[key]}>
                    <Controller
                      control={control}
                      name={`${typeItems}.[${index}].${key}`}
                      render={({ field }) => (
                        <>
                          {((typeItems === 'current_part') && (key === 'quantity')) && (
                          <TextField
                            {...field}
                            type="number"
                            placeholder="Quantity"
                            size="small"
                            disabled={watch(`${typeItems}.[${index}].serial_number`) !== 'N/A'}
                            onChange={(e) => {
                              if ((+e.target.value > (+currentPart?.data?.quantity || +currentPart?.[index]?.data?.quantity))) return;

                              field.onChange(e.target.value);
                            }}
                            sx={{ width: '100%' }}
                          />
                          )}
                          {!((typeItems === 'current_part') && (key === 'quantity')) && (
                          <TextField
                            {...field}
                            placeholder={labels[key]}
                            size="small"
                            disabled
                            sx={{ width: '100%' }}
                          />
                          )}
                        </>
                      )}
                    />
                    {((typeItems === 'current_part') && (key === 'quantity')) && <FieldError error={{ message: `Min 1 - Max ${currentPart?.data?.quantity || currentPart?.[index]?.data?.quantity}` }} sx={{ color: 'rgba(76, 78, 100, 0.87)', }} />}
                  </FormGroup>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}   
      </Box>
    </Grid>
  );
};

export default OrderItems;
export const checkOrderStatus = (status) => {
  switch (status) {
    case 'Sent':
      return 0;
    case 'Pending approve':
      return 1;
    case 'Approved':
      return 2;
    case 'On the way':
      return 3;
    case 'Delivered':
      return 4;
    case 'Declined':
      return 5;
    case 'Installed':
      return true;
    case 'Partially installed':
      return 7;
    default:
      return status;
  }
};

export const handleGetName = (path = '', index = 5) => {
  const name = path.split('/')?.[index];

  return name || '';
};
import {
  Box, Card, Divider,
  Typography, useMediaQuery
} from '@mui/material';
import FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../components/Button';
import ButtonSelect from '../../components/ButtonSelect';
import EditableTable from '../../components/EditableTable';
import {
  useCopyInvoiceMutation,
  useDeleteInvoiceMutation,
  useGetAirListMutation,
  useGetAirTypeListQuery,
  useGetBankAccountNumbersListMutation,
  useGetBankInvoicesListQuery,
  useGetCardsListMutation,
  useGetCurrencyListQuery,
  useGetDepartmentsListQuery,
  useGetFlightsQuery,
  useGetInvoiceExcelMutation,
  useGetInvoiceTableQuery,
} from '../../store/session';
import { getOptions } from '../../utils/getOptions';
import { useMenuToggle } from '../../utils/hooks/useMenuToggle';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { parseLocationSearch, stringifyLocationSearch } from '../../utils/locationSearch';
import { selectOptionFormat } from '../../utils/number';
import { MONTH_SHORT } from '../MainDeckSchedule/constants';
import { DEFAULT_MONTH, DEFAULT_YEAR } from '../SabCrewRoster/constants';
import { columns } from './columns';
import DropDown from './DropDown';
import {
  btnS, headerBtn, headerBtnS, headerBtnXs
} from './style';

export const Invoices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const hasPermissionsSU = usePermissions('invoice_su');
  const hasPermissionsEdit = usePermissions(['invoice_edit', 'invoice_su']);

  const [year, setYear] = useState(DEFAULT_YEAR);
  const [months, setMonths] = useState(DEFAULT_MONTH);

  const [downloadInvoice] = useGetInvoiceExcelMutation();
  const [copyInvoice] = useCopyInvoiceMutation();
  const { data: banksList, isLoading: isBanksListLoading } = useGetBankInvoicesListQuery();
  const [getBankAccountNumbers, { data: bankAccountNumbers, isLoading: isGetBankAccountNumbersLoading }] = useGetBankAccountNumbersListMutation();
  const [getCards, { data: cards, isLoading: isGetCardsLoading }] = useGetCardsListMutation();
  const { data: currencyList, isLoading: isCurrencyLoading } = useGetCurrencyListQuery();
  const { data: departmentsList, isLoading: isDepartmentsListLoading } = useGetDepartmentsListQuery();
  const { data: aircraftTypeList, isLoading: isAircraftTypeListLoading } = useGetAirTypeListQuery();
  const [getAir, { data: aircraftList, isLoading: isAircraftListLoading }] = useGetAirListMutation();
  const { data: flightsList, isLoading: isFlightsListLoading } = useGetFlightsQuery();

  const {
    open, openMenu, closeMenu, anchorEl,
  } = useMenuToggle();

  const banksListOptions = getOptions(banksList);
  const bankAccountNumbersOptions = getOptions(bankAccountNumbers);
  const cardsOptions = getOptions(cards);
  const currencyOptions = getOptions(currencyList);
  const flightsOptions = getOptions(flightsList);
  const departmentsOptions = getOptions(departmentsList);
  const aircraftTypeOptions = selectOptionFormat(aircraftTypeList, 'aircraft_type_name');
  const aircraftOptions = selectOptionFormat(aircraftList, 'aircraft_registration');

  const filters = parseLocationSearch(location.search);

  const maxWidth1000px = useMediaQuery('(max-width:1000px)');
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const isLoading = isBanksListLoading
  || isGetBankAccountNumbersLoading
  || isGetCardsLoading
  || isCurrencyLoading
  || isDepartmentsListLoading
  || isAircraftListLoading
  || isFlightsListLoading
  || isAircraftTypeListLoading;

  const onCustomAddParams = (result, filterBy) => {
    if (filterBy === 'bank') {
      delete result.account_number_card;
      delete result.bank_account_number;
    }

    if (filterBy === 'aircraft_type') {
      delete result.aircraft;
    }

    const locationSearch = stringifyLocationSearch(result);
    navigate(location.pathname + locationSearch);
  };

  const numberMonths = MONTH_SHORT.reduce((total, amount, index) => {
    const monthIndex = [...total, months.includes(amount) && index + 1];

    return monthIndex.filter((e) => e);
  }, []);

  const handleDownload = async () => {
    const toastId = toast.loading('Downloading...');
    const res = await downloadInvoice({ year, months: numberMonths.join(',') });
    const fileName = `invoice-${year}.xlsx`;

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    FileSaver.saveAs(res.data, fileName);
    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleCopyInvoice = (id) => {
    const res = copyInvoice(id);

    toast.promise(res, {
      loading: 'Loading...',
      success: 'Invoice copied successfully!',
      error: 'Error when copied.',
    }).then((result) => {
      navigate(`/directories/invoices/update/${result.data.id}`);
    });
  };

  useEffect(() => {
    if (filters.bank) {
      getBankAccountNumbers(filters.bank);
    }
    if (filters.bank_account_number) {
      getCards(filters.bank_account_number);
    }

    getAir(filters.aircraft);
  }, []);

  useEffect(() => {
    if (banksListOptions) {
      banksListOptions?.push({ value: '', label: '-' });
    }
    if (bankAccountNumbersOptions) {
      bankAccountNumbersOptions?.push({ value: '', label: '-' });
    }
    if (cardsOptions) {
      cardsOptions?.push({ value: '', label: '-' });
    }

    if (currencyOptions) {
      currencyOptions?.push({ value: '', label: '-' });
    }

    if (flightsOptions) {
      flightsOptions?.push({ value: '', label: '-' });
    }
    if (departmentsOptions) {
      departmentsOptions?.push({ value: '', label: '-' });
    }

    if (aircraftTypeOptions) {
      aircraftTypeOptions?.push({ value: '', label: '-' });
    }

    if (aircraftOptions) {
      aircraftOptions?.push({ value: '', label: '-' });
    }
  }, [banksListOptions,
    bankAccountNumbersOptions,
    cardsOptions,
    currencyOptions,
    flightsOptions,
    departmentsOptions,
    aircraftTypeOptions,
    aircraftOptions
  ]);

  return (
    <Card sx={{
      mt: 3,
      padding: '1rem',
    }}
    >
      <Box
        sx={{
          ...headerBtn, ...(maxWidth1000px && headerBtnS), display: 'flex', justifyContent: 'space-between' 
        }}
      >
        <Box>
          <Typography variant="h3">Invoices</Typography>
        </Box>
        <Box sx={{ ...(maxWidth800px && { ...headerBtnXs, width: '100%' }) }}>
          {hasPermissionsSU && <Button sx={{ ...(!maxWidth800px && { ml: 2 }), ...(maxWidth800px && btnS) }} onClick={() => navigate('/directories/delete/confirmation')} title="Delete Confirmation" />}

          {hasPermissionsEdit && <Button sx={{ ...(!maxWidth800px && { ml: 2 }), ...(maxWidth800px && btnS) }} onClick={() => navigate('/directories/invoices/create')} title="Create Invoices" />}

          <Button sx={{ ...(!maxWidth800px && { ml: 2 }), ...(maxWidth800px && btnS) }} title="Download Excel" onClick={() => handleDownload()} />

          <Button sx={{ ...(!maxWidth800px && { ml: 2 }), ...(maxWidth800px && btnS) }} onClick={openMenu} title="Actions" />
        </Box>
      </Box>

      <Divider sx={{ mt: 0, mb: 3 }} />

      <ButtonSelect
        year={year}
        months={months}
        setYear={setYear}
        setMonths={setMonths}
        disabledSendRoster={false}
      />

      <Divider />

      <EditableTable
        useHook={useGetInvoiceTableQuery}
        useHookDelete={useDeleteInvoiceMutation}
        columns={columns}
        tableParams={{
          year,
          months: numberMonths,
        }}
        sortByType="DESC"
        sortByActive="created_at"
        getRowHeight={() => 'auto'}
        hasPermissions={hasPermissionsEdit}
        getRowId={(row) => row.id}
        banksListOptions={banksListOptions}
        bankAccountNumbers={bankAccountNumbersOptions}
        cardsOptions={cardsOptions}
        customIsLoading={isLoading}
        getBankAccountNumbers={getBankAccountNumbers}
        getCards={getCards}
        getAir={getAir}
        aircraftTypeOptions={aircraftTypeOptions}
        currencyOptions={currencyOptions}
        departmentsOptions={departmentsOptions}
        aircraftOptions={aircraftOptions}
        flightsList={flightsList}
        flightsOptions={flightsOptions}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onCustomAddParams={onCustomAddParams}
        handleCopyInvoice={handleCopyInvoice}
      />

      <DropDown
        open={open}
        onClose={closeMenu}
        anchorEl={anchorEl}
      />
    </Card>
  );
};

import { array, object, string } from 'yup';

export const defaultValues = {
  'bank_id': '',
  'bank_account_number_id': null,
  'account_number_card_id': null,
  'payment': 0,
  'payment_date': '',
  'payment_amount': '',
  'payment_currency': '',
  'invoice_type': 0,
  'counterparty_name': '',
  'invoice_number': '',
  'invoice_amount': '',
  'invoice_date': '',
  'invoice_currency': '',
  'tmts': '',
  'department_id': null,
  'flights': [],
  'invoice_sender_department': '',
  'document_url': '',
  'comment': '',
  'executor': '',
  'year': '',
  'month': '',
  'payment_order_number': '',
  'incoming_document_number': '',
};

export const schema = object().shape({
  year: string()
    .required('${label} cannot be blank.')
    .label('Year'),
  month: string()
    .required('${label} cannot be blank.')
    .label('Month'),
  bank_id: string()
    .required('${label} cannot be blank.')
    .label('Bank'),
  'bank_account_number_id': string()
    .nullable()
    .label('Bank account number'),
  'account_number_card_id': string()
    .nullable()
    .label('Account number card'),
  'payment': string()
    .nullable()
    .label('Payment'),
  'payment_date': string()
    .nullable()
    .label('Payment date'),
  'payment_amount': string()
    .nullable()
    .label('Payment amount'),
  'payment_currency': string()
    .nullable()
    .label('Payment currency'),
  'invoice_type': string()
    .nullable()
    .label('Invoice type'),
  'counterparty_name': string()
    .nullable()
    .label('Counterparty name'),
  'invoice_number': string()
    .nullable()
    .label('Invoice number'),
  'invoice_amount': string()
    .nullable()
    .label('Invoice amount'),
  'invoice_date': string()
    .nullable()
    .label('Invoice date'),
  'invoice_currency': string()
    .nullable()
    .label('Invoice currency'),
  'tmts': string()
    .nullable()
    .label('TMTS'),
  'department_id': string()
    .nullable()
    .label('Department'),
  'flights': array()
    .nullable()
    .label('Flights'),
  'invoice_sender_department': string()
    .nullable()
    .label('Invoice sender department'),
  'document_url': string()
    .nullable()
    .label('Document'),
  'comment': string()
    .nullable()
    .label('Comment'),
  'executor': string()
    .nullable()
    .label('Executor'),
  'payment_order_number': string()
    .nullable()
    .label('Payment order number'),
  'incoming_document_number': string()
    .nullable()
    .label('Incoming document number'),
});

import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import CloseIcon from '@mui/icons-material/Close';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Dialog,
  Tab
} from '@mui/material';
import React, { useState } from 'react';
import { CloseIconStyle } from '../../../../Logistics/OrdersDashboard/styles';
import Monthly from './monthly';
import Weekly from './weekly';

const TransactionImport = ({
  open,
  onClose,
  bankList
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTab = (event, tab) => {
    setActiveTab(tab);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xll"
    >
      <TabContext value={activeTab}>
        <TabList
          onChange={handleTab}
          aria-label="tabs"
          sx={{ bgcolor: 'whitesmoke', minHeight: '96px' }}
          variant="fullWidth"
        >
          <Tab value={0} label="Weekly" icon={<CalendarViewDayIcon />} />
          <Tab value={1} label="Monthly" icon={<ViewDayIcon />} />
          <CloseIcon
            sx={CloseIconStyle}
            onClick={onClose}
          />
        </TabList>
        <TabPanel value={0}>
          <Weekly bankList={bankList} open={open} />
        </TabPanel>
        <TabPanel value={1}>
          <Monthly bankList={bankList} />
        </TabPanel>
      </TabContext>
    </Dialog>
  );
};

export default TransactionImport;

export const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  gap: '10px',
  padding: '15px 0',
};

export const styleMobile = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'space-around',
  gap: '20px',
  padding: '15px 0',
};
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHeadBase from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableHeadSx } from '../styles';

const TableHead = () => (
  <TableHeadBase sx={tableHeadSx}>
    <TableRow>
      <TableCell>
        1
      </TableCell>
      <TableCell colSpan={2}>
        2
      </TableCell>
      <TableCell colSpan={2}>
        3
      </TableCell>
      <TableCell colSpan={2}>
        4
      </TableCell>
      <TableCell colSpan={3}>
        5
      </TableCell>
      <TableCell>
        6
      </TableCell>
      <TableCell>
        7
      </TableCell>
      <TableCell colSpan={4}>
        8
      </TableCell>
      <TableCell colSpan={2}>
        9
      </TableCell>
      <TableCell colSpan={5}>
        10
      </TableCell>
      <TableCell colSpan={3}>
        11
      </TableCell>
      <TableCell>
        12
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell rowSpan={2}>
        DATE (dd/mm/yy)
      </TableCell>
      <TableCell colSpan={2}>
        DEPARTURE
      </TableCell>
      <TableCell colSpan={2}>
        ARRIVAL
      </TableCell>
      <TableCell colSpan={2}>
        AIRCRAFT
      </TableCell>
      <TableCell colSpan={2}>
        SINGLE PILOT
      </TableCell>
      <TableCell rowSpan={2}>
        MULTI-PILOT
      </TableCell>
      <TableCell rowSpan={2}>
        TOTAL TIME OF FLIGHT
      </TableCell>
      <TableCell rowSpan={2}>
        NAME
      </TableCell>
      <TableCell colSpan={2}>
        TAKEOFFS
      </TableCell>
      <TableCell colSpan={2}>
        LANDINGS
      </TableCell>
      <TableCell colSpan={2}>
        OPERATIONAL CONDITION TIME
      </TableCell>
      <TableCell colSpan={5}>
        PILOT FUNCTION TIME
      </TableCell>
      <TableCell colSpan={3}>
        SYNTHETIC TRAINING DEVICES SESSION
      </TableCell>
      <TableCell rowSpan={2}>
        REMARKS AND ENDORSEMENTS
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell>
        PLACE
      </TableCell>
      <TableCell>
        TIME
      </TableCell>
      <TableCell>
        PLACE
      </TableCell>
      <TableCell>
        TIME
      </TableCell>
      <TableCell>
        MAKE, MODEL, VARIANT
      </TableCell>
      <TableCell>
        REGISTRATION
      </TableCell>
      <TableCell>
        SE
      </TableCell>
      <TableCell>
        ME
      </TableCell>
      <TableCell>
        DAY
      </TableCell>
      <TableCell>
        NIGHT
      </TableCell>
      <TableCell>
        DAY
      </TableCell>
      <TableCell>
        NIGHT
      </TableCell>
      <TableCell>
        NIGHT
      </TableCell>
      <TableCell>
        IFR
      </TableCell>
      <TableCell>
        PILOT IN COMMAND [PL]
      </TableCell>
      <TableCell>
        PILOT IN COMMAND [PL/US]
      </TableCell>
      <TableCell>
        CO PILOT
      </TableCell>
      <TableCell>
        DUAL
      </TableCell>
      <TableCell>
        INSTRUCTOR
      </TableCell>
      <TableCell>
        DATE (dd/mm/yy)
      </TableCell>
      <TableCell>
        TYPE
      </TableCell>
      <TableCell>
        TOTAL TIME OF SESSION
      </TableCell>
    </TableRow>
  </TableHeadBase>
);

export default TableHead;

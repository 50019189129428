import { object, string } from 'yup';

export const defaultValues = {
  payment_id: '',
  salary_date: '',
  payment_department_id: '',
};

export const schema = object().shape({
  payment_id: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Patment ID'),
  salary_date: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Salary Date'),
  payment_department_id: string()
    .nullable()
    .label('Department ID'),
});

export const ClickToEdit = {
  fontStyle: 'italic',
  color: '#DD1144',
  borderBottom: 'dashed 1px #0088cc',
  cursor: 'pointer',
};

export const DefaultText = {
  color: '#337ab7',
  fontSize: '14px',
  textDecorationLine: 'underline',
  cursor: 'pointer',
};

export const headerSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  mb: '15px',
};

export const footerSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'flex-end',
  mt: '15px',
};

export const cancelButton = {
  backgroundColor: 'transparent'
};

export const title = {
  fontWeight: 'bold'
};

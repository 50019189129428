import moment from 'moment';

export const setDateValue = (e, setValue, field, format) => {
  setValue(field, e ? moment(e).format(format || 'YYYY-MM-DD HH:mm') : '', { shouldDirty: true });
};

export const setDateValueUnix = (e, setValue, field) => {
  setValue(field, e ? moment(e).unix() : '', { shouldDirty: true });
};

export const validateDatePickerValue = (value) => {
  if (value && moment(value).isValid()) {
    return moment(value).toDate();
  }

  return null;
};

export const validateDatePickerValueCopy = (value) => {
  if (value && moment(value, 'DD-MM-YYYY').isValid()) {
    return moment(value, 'DD-MM-YYYY').toDate();
  }

  return null;
};

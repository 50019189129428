import { number, object, string } from 'yup';

export const defaultValues = {
  account_number_id: null,
  bank_name: '',
  amount: 0,
  value_date: '',
  name_of_address: '',
  note: '',
  details: '',
  beneficiary_account_number: ''
};

export const schema = object().shape({
  account_number_id: number()
    .required('${label} cannot be blank.')
    .label('Account Number ID'),
  amount: number()
    .required('${label} cannot be blank.')
    .label('Amount'),
  bank_name: string()
    .required('${label} cannot be blank.')
    .label('Bank Name'),
  value_date: string()
    .required('${label} cannot be blank')
    .label('Value Date'),
  note: string()
    .nullable(),
  details: string()
    .nullable(),
  beneficiary_account_number: string()
    .nullable(),
  name_of_address: string()
    .nullable(),
});

import React from 'react';
import Box from '@mui/material/Box';
import ModalBase from '@mui/material/Modal';
import { modalSx } from './styles';

const Modal = ({ open, onClose, children }) => (
  <ModalBase
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={modalSx}>
      {children}
    </Box>
  </ModalBase>
);

export default Modal;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { TYPE_CHECK_BOX } from '../constants';
import {
  ClicktoEdit,
  DefaultText,
} from './styles';

const TextEdit = ({
  handleEdit, text, labelInput, isLoading, type, options, activeItem, typeField, disabled, style = {}
}) => {
  const handleClick = (e) => {
    if (!isLoading && !disabled) {
      handleEdit(e, text, labelInput, type, options, activeItem);
    }
  };

  return (
    <Box>
      {type === TYPE_CHECK_BOX ? (
        text ? (
          <Typography
            sx={DefaultText}
            onClick={(e) => handleClick(e)}
          >
            {text === 1 ? 'yes' : text}
          </Typography>
        ) : (
          <Typography
            sx={ClicktoEdit}
            onClick={(e) => handleClick(e)}
          >
            {text === 0 ? 'no' : 'Click to edit'}
          </Typography>
        )
      ) : (
        <Typography
          sx={(text || text === 0) ? { ...DefaultText, ...style } : ClicktoEdit}
          onClick={(e) => handleClick(e)}
        >
          {(text || text === 0) ? text : 'Click to edit'}
        </Typography>
      )}
    </Box>
  ); };

export default TextEdit;

export const drawerSx = {
  '& .MuiPaper-root': {
    width: '800px',
  },
  '.MuiBackdrop-root': {
    opacity: '0.2 !important'
  }
};

export const headerSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  p: '20px 30px 0 30px',
};

export const closeIcon = {
  cursor: 'pointer',
};

export const titleSx = {
  fontWeight: '600',
  fontSize: '22px',
};

export const subTitleSx = {
  fontWeight: '550',
  fontSize: '16px',
};


import {
  object, string, number,
} from 'yup';

export const defaultValues = {
  aircraft_registration: '',
  is_active: 1,
  aircraft_type: 1,
  aircraft_operator: '',
  aircraft_atyp: '',
  aircraft_atypiata: '',
  aircraft_default_flight_number: '',
  aircraft_default_flight_number_cargo: '',
  aircraft_default_flight_number_tech: '',
  aircraft_mtow: '',
  aircraft_oew: '',
  aircraft_pew: '',
  aircraft_max_cargo: '',
  aircraft_max_fuel: '',
  aircraft_taxi_fuel: '',
  aircraft_first_hour_fuel: '',
  aircraft_subsequent_fuel: '',
  aircraft_speed: '',
  aircraft_acmi: '',
  aircraft_nav: '',
  aircraft_fuel_price: '',
  aircraft_departure_airport_fee: 0,
  aircraft_arrival_airport_fee: 0,
  aircraft_departure_airport_fee_loaded: 0,
  aircraft_arrival_airport_fee_loaded: 0,
  aircraft_phone_number: '',
  aircraft_email: '',
  notification_active: false,
  consumption: '',
};

export const schema = object().shape({
  aircraft_registration: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Aircraft Registration'),
  is_active: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Is Active'),
  aircraft_type: string()
    .required('${label} cannot be blank.')
    .label('Aircraft Type'),
  aircraft_operator: string()
    .required('${label} cannot be blank.')
    .label('Aircraft Operator'),
  aircraft_atyp: string()
    .required('${label} cannot be blank.')
    .label('ATYP'),
  aircraft_serial_number: string().nullable().label('Aircraft Serial Number'),
  aircraft_home_base: string().nullable().label('Aircraft Home Base'),
  aircraft_atypiata: string().required('${label} cannot be blank.').label('ATYP IATA'),
  aircraft_default_flight_number: string().required('${label} cannot be blank.').label('Aircraft Default Flight Number'),
  aircraft_default_flight_number_cargo: string().required('${label} cannot be blank.').label('Aircraft Default Flight Number Cargo'),
  aircraft_default_flight_number_tech: string().nullable().label('Aircraft Default Flight Number Tech'),
  aircraft_mtow: string().required('${label} cannot be blank.').label('Aircraft MTOW'),
  aircraft_oew: string().required('${label} cannot be blank.').label('Aircraft OEW'),
  aircraft_pew: string().required('${label} cannot be blank.').label('Aircraft PEW'),
  aircraft_max_cargo: string().required('${label} cannot be blank.').label('Aircraft Max Cargo'),
  aircraft_max_fuel: string().required('${label} cannot be blank.').label('Aircraft Max Fuel'),
  aircraft_taxi_fuel: string().required('${label} cannot be blank.').label('Aircraft Taxi Fuel'),
  aircraft_first_hour_fuel: string().required('${label} cannot be blank.').label('Aircraft First Hour Fuel'),
  aircraft_subsequent_fuel: number().nullable().label('Aircraft Subsequent Fuel'),
  aircraft_speed: number().required('${label} cannot be blank.').label('Aircraft Speed'),
  aircraft_acmi: string().required('${label} cannot be blank.').label('Aircraft Acmi'),
  aircraft_nav: number().required('${label} cannot be blank.').label('Aircraft Nav|1nm'),
  aircraft_fuel_price: number().required('${label} cannot be blank.').label('Fuel, eur/T'),
  aircraft_departure_airport_fee: number()
    .nullable()
    .label('Aircraft DEP A|P fees empty'),
  aircraft_arrival_airport_fee: number()
    .nullable()
    .label('Aircraft ARR A|P fees empty'),
  aircraft_departure_airport_fee_loaded: number()
    .nullable()
    .label('Aircraft DEP A|P fees loaded'),
  aircraft_arrival_airport_fee_loaded: number()
    .nullable()
    .label('Aircraft ARR A|P fees loaded'),
  aircraft_phone_number: string().nullable().label('Aircraft Phone Number\n'),
  aircraft_email: string().email().required('${label} cannot be blank.').label('Email'),
  consumption: number().required('${label} cannot be blank.').label('Consumption'),
});

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import Copy from 'mdi-material-ui/ContentCopy';
import Delete from 'mdi-material-ui/Delete';
import Pencil from 'mdi-material-ui/Pencil';

export const StyledPencil = styled(Pencil)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const StyledDelete = styled(Delete)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));

export const linkSx = {
  color: 'rgb(108, 108, 241)',
  cursor: 'pointer',
};

export const cardContent = {
  mt: 5, display: 'flex', gap: '20px', flexDirection: 'column'
};

export const StyledClear = styled(ClearIcon)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));

export const StyledCheck = styled(CheckIcon)(() => ({
  '&:hover': {
    color: 'green',
    cursor: 'pointer',
  },
}));

export const StyledHeader = styled(Copy)(() => ({
  '.MuiDataGrid-columnHeaders': {
    minHeight: '300px',
  },
}));

export const HeaderName = {
  fontSize: '0.75rem',
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontWeight: '500',
  letterSpacing: '0.17px',
};

export const ClicktoEdit = {
  fontStyle: 'italic',
  color: '#DD1144',
  borderBottom: 'dashed 1px #0088cc',
  cursor: 'pointer',
};

export const DefaultText = {
  borderBottom: 'dashed 1px #0088cc',
  color: '#337ab7',
  fontSize: '14px',
  cursor: 'pointer'
};

export const TableStyle = {
  '& .MuiDataGrid-main': {
    overflow: 'visible',
  },
  '& .MuiDataGrid-columnHeaders': {
    minHeight: '80px !important'
  },
  '& .MuiDataGrid-iconButtonContainer': {
    width: '30px',
    position: 'absolute',
    right: '0',
    top: '-5px',
    zIndex: '100000',
    visibility: 'visible!important',
  },
  '& .MuiDataGrid-sortIcon': {
    opacity: '1!important',
  },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0px !important',
    height: 'calc(100% - 80px) important'
  },
  '& .MuiDataGrid-columnHeader': {
    display: 'flex !important',
    flexDirection: 'column !important',
    height: 'fit-content !important',
    padding: '.5rem!important'
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    width: 'calc(100% - 30px)'
  },
  '& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
    width: '100%'
  },
};
export const TableStyleColumnGrouped = {
  '& .MuiDataGrid-main': {
    overflow: 'visible',
  },
  '& .MuiDataGrid-columnHeaders': {
    minHeight: '200px !important',
  },
  '& .MuiDataGrid-iconButtonContainer': {
    width: '30px',
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0px !important',
    height: 'calc(100% - 80px) important'
  },
  '& .MuiDataGrid-columnHeader': {
    display: 'flex !important',
    flexDirection: 'column !important',
    height: 'fit-content !important',
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    width: '100%'
  },
  '& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
    width: '100%'
  },
};

export const odsId = {
  color: '#337ab7',
  fontSize: '14px',
  textDecorationLine: 'underline',
};

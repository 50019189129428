import React from 'react';
import {
  Card, CardHeader, CardContent,
} from '@mui/material';
import Table from './Table';

const FullCost = () => (
  <Card sx={{ mt: 3 }}>
    <CardHeader title="Full Cost" />
    <CardContent sx={{ mt: 5 }}>
      <Table />
    </CardContent>
  </Card>
);

export default FullCost;

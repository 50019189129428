import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import {
  HeaderName, StyledDelete,
} from '../../../components/EditableTable/styles';
import ButtonIcon from '../../../components/ButtonIcon';
import TextEdit from '../../../components/EditableTable/TextEdit';
import { TYPE_INPUT } from '../../../components/EditableTable/constants';

export const columns = ({
  handleFilter,
  filters,
  handleOpenDeleteModal,
  handleOpen,
  hasPermissions,
}) => ([
  {
    flex: 0.2,
    field: 'email',
    minWidth: 200,
    headerName: 'Email',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Email</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.email || ''}
          value="email"
          placeholder="Email"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'email', row)}
        text={row.email}
        labelInput="Email"
        // disabled={!hasPermissions}
        type={TYPE_INPUT}
      />
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <ButtonIcon onClick={() => handleOpenDeleteModal(row.id)}>
        <StyledDelete />
      </ButtonIcon>
    ),
  },
]);


import {
  object, string,
} from 'yup';

export const defaultValues = {
  icao_code: '',
  company_name: '',
  address: '',
  phone: '',
  email: '',
};

export const schema = object().shape({
  icao_code: string()
    .nullable()
    .required('${label} cannot be blank.')
    .max(5)
    .label('Icao Code'),
  company_name: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Company Name'),
  address: string()
    .required('${label} cannot be blank.')
    .label('Address'),
  phone: string()
    .required('${label} cannot be blank.')
    .label('Phone'),
  email: string()
    .required('${label} cannot be blank.')
    .label('Email'),
});

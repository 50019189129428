import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import themeConfig from '../../themes/themeConfig';
import { usePermissions } from '../../utils/hooks/usePermissions';
import UserIcon from './UserIcon';
import {
  // eslint-disable-next-line import/named
  ListItem,
} from './styles';

const HorizontalNavLink = (props) => {
  // ** Props
  const {
    item, hasParent, isMobile = false, onCloseDrawer = () => {} 
  } = props;
  const { navSubItemIcon, menuTextTruncate } = themeConfig;
  const IconTag = item.icon ? item.icon : navSubItemIcon;
  const Wrapper = !hasParent ? List : Fragment;
  const navigate = useNavigate();

  const hasPermissions = usePermissions(item.permissions);

  const location = useLocation();
  const currentURL = !hasParent ? `/${location.pathname.split('/')[1]}` : location.pathname;

  const isNavLinkActive = () => currentURL === item.path;

  return (
    <Wrapper {...(!hasParent ? { component: 'div', sx: { py: 2.75, width: isMobile ? '100%' : 'auto', backgroundColor: (isMobile && isNavLinkActive()) ? (theme) => theme.palette.customColors.textPrimary : '' } } : {})}>
      {hasPermissions && (
        <ListItem
          component="a"
          disabled={item.disabled}
          className={clsx({ active: isNavLinkActive() })}
          href={item.path}
          target="_blank"
          onClick={(e) => {
            if (e.button === 0) e.preventDefault();
            if (item.path === undefined) {
              e.preventDefault();
              e.stopPropagation();
            } else {
              navigate(item.path, { replace: true });
            }

            onCloseDrawer();
          }}
          sx={{
            ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
            ...(!hasParent
              ? {
                borderRadius: isMobile ? '0' : '8px',
                '& .MuiTypography-root, & .MuiListItemIcon-root': {
                  color: 'common.white',
                },
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.action.menuHover,
                },
                '&.active, &.active:hover': {
                  backgroundColor: (theme) => theme.palette.customColors.textPrimary,
                  '& .MuiTypography-root, & .MuiListItemIcon-root': {
                    color: 'common.white',
                  },
                },
              }
              : {}),
          }}
        >
          <Box sx={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
          }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                ...(menuTextTruncate && { overflow: 'hidden' }),
              }}
            >
              <ListItemIcon sx={{ mr: hasParent ? 3 : 2.5, color: 'text.primary' }}>
                <UserIcon
                  icon={IconTag}
                  componentType="horizontal-menu"
                  iconProps={{ sx: IconTag === navSubItemIcon ? { fontSize: '0.5rem' } : {} }}
                />
              </ListItemIcon>
              <Typography {...(menuTextTruncate && { noWrap: true })}>
                {item.title}
              </Typography>
            </Box>
            {item.badgeContent ? (
              <Chip
                size="small"
                label={item.badgeContent}
                color={item.badgeColor || 'primary'}
                sx={{ ml: 1.5, '& .MuiChip-label': { px: 2.5, lineHeight: 1.385, textTransform: 'capitalize' } }}
              />
            ) : null}
          </Box>
        </ListItem>
      )}
    </Wrapper>
  );
};

export default HorizontalNavLink;

import React from 'react';
import TypeCard from '../TypeCard';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { Controller } from 'react-hook-form';
import FieldError from '../../../../form/components/FieldError';

const Load = ({ control, isPanel, errors = {} }) => (
  <TypeCard title="LOAD" variant="sky" isPanel={isPanel}>
    <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1 }}>
      <Grid item xs={12} lg={4}>
        <FormGroup label="CARGO" required={isPanel} hasError={!!errors.load_cargo}>
          <Controller
            name="load_cargo"
            control={control}
            render={({ field }) => (
              <Input
                InputProps={{
                  endAdornment: <InputAdornment position="end">KG</InputAdornment>,
                }}
                {...field}
              />
            )}
          />
          <FieldError error={errors.load_cargo} />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={4}>
        <FormGroup label="PAX" required={isPanel} hasError={!!errors.load_pax}>
          <Controller
            name="load_pax"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
          <FieldError error={errors.load_pax} />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={4}>
        <FormGroup label="DRG" required={isPanel} hasError={!!errors.load_drg}>
          <Controller
            name="load_drg"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
          <FieldError error={errors.load_drg} />
        </FormGroup>
      </Grid>
    </Grid>
  </TypeCard>
);

export default Load;

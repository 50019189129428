import { yupResolver } from '@hookform/resolvers/yup';
import {
  Card, CardContent, Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';
import Button from '../../../components/Button';
import { TableStyle } from '../../../components/EditableTable/styles';
import { defaultOptions } from '../../../components/SearchStatisticsV2/Search';
import DatePicker from '../../../form/components/DatePicker';
import FormGroup from '../../../form/components/FormGroup';
import {
  useExportToExcelUtilizationReportMutation, useGetAirListMutation, useGetAirTypeListQuery, useGetUtilizationReportMutation
} from '../../../store/session';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import { selectOptionFormat } from '../../../utils/number';
import { setDateValue, validateDatePickerValue } from '../../../utils/setDateValue';
import { GreenButton } from '../../Logistics/style';
import { columns } from './columns';
import { defaultValues, schema } from './schema';
import { MenuProps } from './style';

const UtilizationReports = () => {
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const [aircraftListOptions, setAircraftListOptions] = useState(['0']);
  const [startDate, setStartDate] = useState(null);
  const [selectACType, setSelectACType] = useState(['0']);
  const [selectAC, setSelectAC] = useState(['0']);
  const [getAirList, { data: aircraftList, isLoading: isAircraftListLoading }] = useGetAirListMutation();
  const [tableArray, setTableArray] = useState([]);
  const [getReport, { isLoading: isUtilizationReportLoading }] = useGetUtilizationReportMutation();
  const [exportData, { isLoading: isExportDataLoading }] = useExportToExcelUtilizationReportMutation();
  const { data: aircraftTypeList = [] } = useGetAirTypeListQuery();
  const {
    handleSubmit, control, setValue, reset, getValues, formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const aircraftTypeListOptions = [
    ...defaultOptions,
    ...selectOptionFormat(aircraftTypeList || [], 'aircraft_type_name'),
  ].map(({ value, label }) => ({ value: value.toString(), label }));

  const handleSelectType = async (typeId, typeSelect, callback) => {
    const lastItem = typeId?.[typeId.length - 1];
    const isAll = typeId.includes('0');
    let currentValue;

    if (isAll) {
      const newValue = typeId.filter(item => item !== '0');

      currentValue = newValue;
    }

    if (lastItem === '0') {
      currentValue = ['0'];
    } 

    if (!isAll && lastItem !== '0') {
      currentValue = typeof typeId === 'string' ? typeId.split(',') : typeId;
    }

    callback(currentValue);
    if (typeSelect === 'ac_type') {
      setSelectAC(['0']);
      setValue('aircraft_id', ['0']);
      await getAirList(currentValue.length === 1 ? currentValue[0] : currentValue.join(','));
    }
  };

  useEffect(() => {
    getAirList('0');
  }, []);

  useEffect(() => {
    if (!isAircraftListLoading) { 
      setAircraftListOptions([
        ...defaultOptions,
        ...selectOptionFormat(aircraftList, 'aircraft_registration'),
      ]); 
    }
  }, [isAircraftListLoading]);

  const submitRequest = async () => {
    const data = getValues();
    
    if (data.aircraft_id === 0) {
      delete data.aircraft_id;
    }

    if (data.aircraft_type === 0) {
      delete data.aircraft_type;
    }
    const toastId = toast.loading('Loading...');
    const res = await getReport(stringifyLocationSearch(data));

    if (res?.error) {
      toast.error(res.error.data.message || 'Something went wrong', {
        id: toastId
      });
    } else {
      toast.success('Success!', {
        id: toastId
      });
      setTableArray((res?.data || [])?.map(item => ({ ...item, id: uuid() })));
    }
  };

  const submitExportToExcel = async () => {
    const data = getValues();

    if (data.aircraft_id === 0) {
      delete data.aircraft_id;
    }

    if (data.aircraft_type === 0) {
      delete data.aircraft_type;
    }
    
    const res = await exportData(stringifyLocationSearch(data));

    FileSaver.saveAs(res?.data, 'utilization_report');
  };

  return (
    <>
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>Utilization Report</Typography>

          <Grid
            sx={{
              mt: 3,
              display: 'flex',
              '& .MuiFormLabel-root': {
                color: 'rgb(76 78 100)'
              } 
            }}
            container
            spacing={5}
          >
            <Grid item xs={12} sm={6} md={3}>
              <FormGroup label="AC Type" required hasError={!!errors.aircraft_type}>
                <Controller
                  name="aircraft_type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      multiple
                      value={selectACType}
                      defaultValue="0"
                      onChange={(e) => {
                        field.onChange(e);
                        handleSelectType(e.target.value, 'ac_type', (value) => {
                          setSelectACType(value);
                          setValue('aircraft_type', value);
                        });
                      }}
                      MenuProps={MenuProps}
                      sx={{
                        width: '100%',
                        height: '2.5rem'
                      }}
                    >
                      {(aircraftTypeListOptions || []).map(({ label, value }) => (
                        <MenuItem
                          key={value}
                          value={value}
                          sx={{ fontWeight: 'bold' }}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormGroup label="AC" required hasError={!!errors.aircraft_id}>
                <Controller
                  name="aircraft_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      multiple
                      disabled={isAircraftListLoading}
                      value={selectAC}
                      defaultValue="0"
                      onChange={(e) => {
                        field.onChange(e);
                        handleSelectType(e.target.value, 'ac', (value) => {
                          setSelectAC(value);
                          setValue('aircraft_id', value);
                        });
                      }}
                      MenuProps={MenuProps}
                      sx={{
                        width: '100%',
                        height: '2.5rem'
                      }}
                    >
                      {(aircraftListOptions?.map(({ value, label }) => ({ value: value?.toString(), label })) || []).map(({ label, value }) => (
                        <MenuItem
                          key={value}
                          value={value}
                          sx={{ fontWeight: 'bold' }}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormGroup label="From" required hasError={!!errors.date_from}>
                <Controller
                  name="date_from"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="YYYY-MM-DD "
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setDateValue(e, setValue, 'date_from', 'YYYY-MM-DD');
                        setStartDate(e);
                      }}
                      value={validateDatePickerValue(field.value)}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormGroup label="To" required hasError={!!errors.date_to}>
                <Controller
                  name="date_to"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="YYYY-MM-DD "
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                      minDate={startDate || null}
                      onChange={(e) => {
                        field.onChange(e);
                        setDateValue(e, setValue, 'date_to', 'YYYY-MM-DD');
                      }}
                      value={validateDatePickerValue(field.value)}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sx={maxWidth800px ? {
                display: 'flex',
                flexDirection: 'column',
                gap: 4
              } : {}}
            >
              <Button
                onClick={handleSubmit(submitRequest)}
                title="Send Request"
                disabled={isUtilizationReportLoading}
              />
              <Button
                disabled={isExportDataLoading}
                sx={{ ...GreenButton, ...(!maxWidth800px && { ml: 4 }) }}
                onClick={handleSubmit(submitExportToExcel)}
                title="Export to Excel"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 5 }}>
        <DataGrid
          rows={tableArray}
          columns={columns()}
          autoHeight
          disableColumnMenu
          disableSelectionOnClick
          getRowHeight={() => 'auto'}
          getRowId={(row) => row?.id}
          loading={isUtilizationReportLoading}
          disableColumnFilter
          rowCount={tableArray?.length || 0}
          hideFooterPagination
          sx={{
            ...TableStyle,
            color: 'rgb(76 78 100); !important',
            '& .MuiTypography-root': {
              color: 'rgb(76 78 100) !important'
            }
          }}
        />
      </Card>
    </>
  );
};

export default UtilizationReports;
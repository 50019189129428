import {
  object, string
} from 'yup';

export const defaultValues = {
  aircraft_status: 1,
  maint_from: null,
  maint_to: null,
  maint_reason: null,
  aircraft_data_date: null,
  aircraft_data_fh: 0,
  aircraft_data_cyc: 0,
  aircraft_engine_lh_data_date: null,
  aircraft_engine_lh_data_fh: 0,
  aircraft_engine_lh_data_cyc: 0,
  aircraft_engine_lh_desc: '',
  aircraft_engine_rh_data_date: null,
  aircraft_engine_rh_data_fh: 0,
  aircraft_engine_rh_data_cyc: 0,
  aircraft_engine_rh_desc: '',
};

export const schema = object().shape({
  aircraft_status: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Aircraft status'),
  maint_from: string()
    .nullable()
    .label('Maintenance from'),
  maint_to: string()
    .nullable()
    .label('Maintenance to'),
  maint_reason: string()
    .nullable()
    .label('Maintenance Reason'),
  aircraft_data_date: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Aircraft Date'),
  aircraft_data_fh: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Aircraft Data FH'),
  aircraft_data_cyc: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Aircraft Data CYC'),
  aircraft_engine_lh_data_date: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Engine LH Data Date'),
  aircraft_engine_lh_data_fh: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Engine LH Data FH'),
  aircraft_engine_lh_data_cyc: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Engine LH Data CYC'),
  aircraft_engine_lh_desc: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Engine LH Description'),
  aircraft_engine_rh_data_date: string()
    .required('${label} cannot be blank.')
    .label('Engine RH Data Date'),
  aircraft_engine_rh_data_fh: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Engine RH FH'),
  aircraft_engine_rh_data_cyc: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Engine RH CYC'),
  aircraft_engine_rh_desc: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Engine RH Description'),

});

export const operationsList = [
  {
    label: 'MAINTENANCE',
    value: 0
  },
  {
    label: 'OPERATED',
    value: 1
  },
];

import {
  Autocomplete,
  Button,
  Checkbox,
  Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField,
} from '@mui/material';
import { ArrowRight, Plus, Weight } from 'mdi-material-ui';
import moment from 'moment';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import DatePickerWrapper from '../../../../../components/Datepicker/DatePickerWrapper';
import LinkButton from '../../../../../components/LinkButton';
import { useStoreOdsFromHopMutation } from '../../../../../store/session';
import { calculateFlightTime } from '../../../../../utils/fdp';
import { StyledDelete } from '../../../list/styles';

const HopsForm = (props) => {
  const {
    setIsDataHasChanged,
    setIsServiceHasAdded,
    handleSetModalState,
    update,
    hopFields,
    Controller,
    control,
    watch,
    hasPermissionsOdsCreate,
    AircraftList,
    setValue,
    AirportList,
    CrewList,
    CrewListEng,
    hopRemove,
    hopAppend,
    defaultValues,
    errors,
    trigger,
  } = props;

  const [storeOdsFromHop] = useStoreOdsFromHopMutation();

  const handleCreateOdsByHop = (hop_id, index) => {
    if (!hop_id) {
      toast.error('You must save the Cargo Flight before sending the ODS.');

      return;
    }
    const req = storeOdsFromHop(hop_id);

    toast.promise(req, {
      loading: 'Loading',
      success: 'ODS successfully created!',
      error: 'Something went wrong',
    }).then((res) => {
      setValue(`planing_hop.${index}.pl_hop_ods_id`, res?.data?.id);
      setValue(`planing_hop.${index}.pl_hop_ods_index`, res?.data?.index);
    });
  };

  const getPrefix = async (id, empty) => {
    if (id === '' || id === null) {
      return '';
    }
    const pref = await AircraftList?.filter((item) => item.id === id)[0]?.aircraft_default_flight_number;
    if (empty) {
      return pref;
    }

    return `${pref.substring(0, 5)}1`;
  };

  const getAirportsCoordinates = async (idFrom, idTo) => {
    const airportFrom = await AirportList?.filter((item) => item.id === idFrom)[0];
    const airportTo = await AirportList?.filter((item) => item.id === idTo)[0];

    return {
      lat1: airportFrom?.airport_lat,
      lon1: airportFrom?.airport_lon,
      lat2: airportTo?.airport_lat,
      lon2: airportTo?.airport_lon,
    };
  };

  useEffect(() => {
    watch((value, { name, type }) => {
      const currField = name.split('.')[2];
      const currIndex = +name.split('.')[1];

      if ((currField === 'pl_hop_from_id' && type === 'change')
        || (currField === 'pl_hop_departure' && type === 'change')
        || (currField === 'pl_hop_to_id' && type === 'change')
      ) {
        if ((value.planing_hop[currIndex].pl_hop_from_id !== '')
          && (value.planing_hop[currIndex].pl_hop_from_id !== undefined)
          && (value.planing_hop[currIndex].pl_hop_to_id !== '')
          && (value.planing_hop[currIndex].pl_hop_to_id !== undefined)
          && (value.planing_hop[currIndex].pl_hop_departure !== '')
          && (value.planing_hop[currIndex].pl_hop_departure !== undefined)
          && (value.planing_hop[currIndex].pl_hop_departure !== null)
        ) {
          if (value.planing_hop[currIndex].pl_hop_from_id !== value.planing_hop[currIndex].pl_hop_to_id) {
            getAirportsCoordinates(value.planing_hop[currIndex].pl_hop_from_id, value.planing_hop[currIndex].pl_hop_to_id).then(({
              lat1, lon1, lat2, lon2,
            }) => {
              const departureTime = value.planing_hop[currIndex].pl_hop_departure;
              const flightTime = calculateFlightTime(lat1, lon1, lat2, lon2, departureTime);
              setValue(`planing_hop.${currIndex}.pl_hop_arriving`, flightTime);
            });
          }
        }
      }

      if (name === 'planing_reg' && type === 'change') {
        if (value.planing_reg !== '' && value.planing_reg !== null) {
          // eslint-disable-next-line array-callback-return
          value.planing_hop.map((item, index) => {
            getPrefix(value.planing_reg, value.planing_hop[index].pl_hop_empty).then((prefix) => {
              if (item.pl_hop_prefix === '' || value.planing_hop[index].pl_hop_prefix !== prefix) {
                setValue(`planing_hop.${index}.pl_hop_prefix`, prefix);
              }
            });
          });
        }
      }

      if (currField === 'pl_hop_empty' && type === 'change') {
        if (value.planing_reg !== '' && value.planing_reg !== null) {
          const empty = value.planing_hop[currIndex].pl_hop_empty;

          getPrefix(value.planing_reg, empty).then((prefix) => {
            if (value.planing_hop[currIndex].pl_hop_prefix !== prefix) {
              setValue(`planing_hop.${currIndex}.pl_hop_prefix`, prefix);
            }
          });
        }
      }

      if (currField === 'pl_hop_to_id' && type === 'change') {
        const childIndex = currIndex + 1;

        if (childIndex in value.planing_hop) {
          setValue(`planing_hop.${childIndex}.pl_hop_from`, value.planing_hop[currIndex].pl_hop_to);
          setValue(`planing_hop.${childIndex}.pl_hop_from_id`, value.planing_hop[currIndex].pl_hop_to_id);
        }
      }
    });
  }, [watch]);

  const addNewHop = async () => {
    const planingId = watch('planing_reg');
    const hops = watch('planing_hop');
    const index = hops.length - 1;
    if ((hops[index].pl_hop_arriving !== null) && (hops[index].pl_hop_to_id)) {
      getPrefix(planingId, true).then((prefix) => {
        defaultValues.planing_hop[0].pl_hop_prefix = prefix;
        defaultValues.planing_hop[0].pl_hop_from_id = hops[index].pl_hop_to_id;
        defaultValues.planing_hop[0].pl_hop_from = hops[index].pl_hop_to;
        defaultValues.planing_hop[0].pl_hop_departure = moment(hops[index].pl_hop_arriving).add(1, 'hours');
        defaultValues.planing_hop[0].pl_hop_to = '';
        defaultValues.planing_hop[0].pl_hop_to_id = '';
        hopAppend(defaultValues.planing_hop[0]);
      });
    } else {
      await trigger(`planing_hop.${index}.pl_hop_to_id`);
      await trigger(`planing_hop.${index}.pl_hop_arriving`);
    }
  };

  const handleChangeAirport = (name, data, onChange, _, prevValue, name_id) => {
    setValue(name, data ? `${data?.airport_iata}/${data?.airport_icao} ${data?.airport_name}` : null);
    onChange(data?.id);
    trigger(name_id);

    if (prevValue) {
      setIsDataHasChanged('planing_services', true);
    }
  };

  const handleDelete = (index) => {
    if ((index !== 0) && (index !== watch('planing_hop').length - 1)) {
      const prevHop = watch(`planing_hop.${index - 1}`);
      // const nextHop = watch(`planing_hop.${index + 1}`);

      // if (!!prevHop?.pl_hop_to_id && nextHop?.pl_hop_to_id && (prevHop?.pl_hop_to_id === nextHop?.pl_hop_to_id)) {
      //   setValue(`planing_hop.${index + 1}.pl_hop_to_id`, null);
      //   setValue(`planing_hop.${index + 1}.pl_hop_to`, null);
      //   setValue(`planing_hop.${index + 1}.pl_hop_arriving`, null);
      // }
      setValue(`planing_hop.${index + 1}.pl_hop_from_id`, prevHop.pl_hop_to_id);
      setValue(`planing_hop.${index + 1}.pl_hop_from`, prevHop.pl_hop_to);
      trigger(`planing_hop.${index + 1}.pl_hop_from_id`);
    }

    if ((index !== 0) || (watch('planing_hop').length > 1)) {
      const airports = [watch(`planing_hop.${index}.pl_hop_to_id`)];

      if (index === 0) {
        airports.push(watch(`planing_hop.${index}.pl_hop_from_id`));
      }

      setValue('planing_services', watch('planing_services')?.filter(service => !airports.includes(service?.pl_serv_airport)));
      trigger('planing_services');
      hopRemove(index);
    }
  };

  const handleAddHop = () => {
    addNewHop();
    setIsServiceHasAdded(true);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {hopFields.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell align="center">
                    <Controller
                      name={`planing_hop.${index}.pl_hop_empty`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          checked={!value}
                          checkedIcon={(
                            <Weight sx={{
                              color: (theme) => theme.palette.primary.main,
                            }}
                            />
                          )}
                          onChange={(e, data) => {
                            onChange(!data);
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Controller
                      name={`planing_hop.${index}.pl_hop_prefix`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          value={value}
                          label="Flight No."
                          onChange={onChange}
                          size="small"
                          sx={{
                            maxWidth: '6rem',
                            minWidth: '100px'
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Controller
                      name={`planing_hop.${index}.pl_hop_from_id`}
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: {
                          ref,
                          onChange,
                          value,
                        },
                      }) => (
                        index === 0
                          ? (
                            <Autocomplete
                              freeSolo
                              value={AirportList?.filter((AirportItem) => AirportItem.id === value)[0] || null}
                              options={AirportList}
                              getOptionLabel={(option) => `${option.airport_iata}/${option.airport_icao} (${option.airport_name})` || ''}
                              isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  inputRef={ref}
                                  error={Boolean(errors.planing_hop?.[index]?.pl_hop_from_id)}
                                  label="Airport From"
                                  sx={{
                                    width: '14rem',
                                  }}
                                />
                              )}
                              onChange={(e, data) => handleChangeAirport(`planing_hop.${index}.pl_hop_from`, data, onChange, item, value)}
                            />
                          )
                          : (
                            <TextField
                              disabled
                              value={watch(`planing_hop.${index}.pl_hop_from`) || ''}
                              size="small"
                              inputRef={ref}
                              label="Airport From"
                              sx={{
                                width: '14rem',
                              }}
                            />
                          )
                      )}
                    />
                    <Controller
                      name={`planing_hop.${index}.pl_hop_departure`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange } }) => (
                        <DatePickerWrapper>
                          <DatePicker
                            selected={value !== null ? moment(value).toDate() : null}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            showYearDropdown
                            showMonthDropdown
                            onChange={onChange}
                            placeholderText="YYYY-MM-DD H:m"
                            dateFormat="yyyy-MM-dd HH:mm"
                            customInput={(
                              <TextField
                                size="small"
                                value={value}
                                error={Boolean(errors.planing_hop?.[index]?.pl_hop_departure)}
                                onChange={onChange}
                                label="Date of Departure"
                                aria-describedby="validation-basic-dob"
                                sx={{
                                  marginTop: '1rem',
                                  width: '14rem',
                                }}
                              />
                            )}
                          />
                        </DatePickerWrapper>
                      )}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRight />
                  </TableCell>
                  <TableCell align="center">
                    <Controller
                      name={`planing_hop.${index}.pl_hop_to_id`}
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: {
                          ref,
                          onChange,
                          value,
                        },
                      }) => (
                        <Autocomplete
                          freeSolo
                          value={AirportList?.filter((AirportItem) => AirportItem.id === value)[0] || null}
                          options={AirportList}
                          getOptionLabel={(option) => `${option.airport_iata}/${option.airport_icao} (${option.airport_name})` || ''}
                          isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                          id="autocomplete-outlined"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors.planing_hop?.[index]?.pl_hop_to_id)}
                              inputRef={ref}
                              label="Airport To"
                              sx={{
                                width: '14rem',
                              }}
                            />
                          )}
                          onChange={(e, data) => handleChangeAirport(`planing_hop.${index}.pl_hop_to`, data, onChange, item, value, `planing_hop.${index}.pl_hop_to_id`)}
                        />
                      )}
                    />
                    <Controller
                      name={`planing_hop.${index}.pl_hop_arriving`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange } }) => (
                        <DatePickerWrapper>
                          <DatePicker
                            selected={value !== null ? moment(value).toDate() : null}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            showYearDropdown
                            showMonthDropdown
                            onChange={(e) => onChange(e)}
                            placeholderText="YYYY-MM-DD H:m"
                            dateFormat="yyyy-MM-dd HH:mm"
                            customInput={(
                              <TextField
                                sx={{
                                  marginTop: '1rem',
                                  width: '14rem',
                                }}
                                error={Boolean(errors.planing_hop?.[index]?.pl_hop_arriving)}
                                size="small"
                                value={value}
                                onChange={onChange}
                                label="Date of Arriving"
                                aria-describedby="validation-basic-dob"
                              />
                            )}
                          />
                        </DatePickerWrapper>
                      )}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Controller
                      name={`planing_hop.${index}.pl_hop_capitane`}
                      control={control}
                      render={({
                        field: {
                          ref,
                          onChange,
                          value,
                        },
                      }) => (
                        <Autocomplete
                          freeSolo
                          value={CrewList?.filter((CrewItem) => CrewItem.id === value)[0] || null}
                          options={CrewList}
                          getOptionLabel={(option) => option.crew_shortname || ''}
                          isOptionEqualToValue={(option, crewItem) => option.id === crewItem.id}
                          id="autocomplete-outlined"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              inputRef={ref}
                              label="PIC"
                            />
                          )}
                          onChange={(e, data) => {
                            if (data) onChange(data?.id);
                            else onChange(null);
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Controller
                      name={`planing_hop.${index}.pl_hop_fo`}
                      control={control}
                      render={({
                        field: {
                          ref,
                          onChange,
                          value,
                        },
                      }) => (
                        <Autocomplete
                          freeSolo
                          value={CrewList?.filter((CrewItem) => CrewItem.id === value)[0] || null}
                          options={CrewList}
                          getOptionLabel={(option) => option.crew_shortname || ''}
                          isOptionEqualToValue={(option, crewItem) => option.id === crewItem.id}
                          id="autocomplete-outlined"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              inputRef={ref}
                              label="FO"
                            />
                          )}
                          onChange={(e, data) => {
                            if (data) onChange(data?.id);
                            else onChange(null);
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Controller
                      name={`planing_hop.${index}.pl_hop_engineer`}
                      control={control}
                      render={({
                        field: {
                          ref,
                          onChange,
                          value,
                        },
                      }) => (
                        <Autocomplete
                          freeSolo
                          value={CrewListEng?.filter((CrewItem) => CrewItem.id === value)[0] || null}
                          options={CrewListEng}
                          getOptionLabel={(option) => option.crew_shortname || ''}
                          isOptionEqualToValue={(option, crewItem) => option.id === crewItem.id}
                          id="autocomplete-outlined"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              inputRef={ref}
                              label="ENG"
                              sx={{ minWidth: '60px' }}
                            />
                          )}
                          onChange={(e, data) => {
                            if (data) onChange(data?.id);
                            else onChange(null);
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  {!watch(`planing_hop.${index}.pl_hop_ods_id`) ? (
                    (!update || watch(`planing_hop.${index}.pl_hop_id`)) ? (
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          size="small"
                          disabled={(!hasPermissionsOdsCreate || ((index !== 0) && (!watch(`planing_hop.${index - 1}.pl_hop_ods_id`))))}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSetModalState({
                              text: 'Are you sure you want to generate and send the ODS?',
                              callback: () => handleCreateOdsByHop(item.pl_hop_id, index)
                            });
                          }}
                        >
                          SEND ODS
                        </Button>
                      </TableCell>
                    ) : <TableCell />)
                    : (
                      <TableCell align="center">
                        <LinkButton
                          style={{ color: '#3f6ba0', padding: '5px', fontWeight: 550 }}
                          path={`/directories/ods/update/${watch(`planing_hop.${index}.pl_hop_ods_id`)}`}
                          target="_blank"
                        >
                          {`#${watch(`planing_hop.${index}.pl_hop_ods_index`)}`}
                        </LinkButton>
                      </TableCell>
                    )}
                  <TableCell align="center" sx={{ ml: 'auto' }}>
                    <StyledDelete
                      onClick={() => handleDelete(index)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Button
            variant="contained"
            size="small"
            sx={{ margin: '2rem 0.75rem 1rem 0.75rem', padding: '0.75rem' }}
            onClick={handleAddHop}
          >
            <Plus sx={{ mr: 1 }} />
            Add Fleet
          </Button>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default HopsForm;

export const titleContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const boxAircraft = {
  display: 'flex',
  alignItems: 'center',
  overflow: 'auto',
  flexWrap: 'wrap',
  backgroundColor: '#F5F5F7',
  padding: '15px',
  borderRadius: '8px',
  maxHeight: '170px',
  mb: '20px',
  gap: '10px'
};

export const boxIcon = {
  display: 'flex',
  alignItems: 'center',
  gap: '5px'
};

export const containerAircraft = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '25px',
  mb: 5
};

export const containerAircraftSx = {
  flexDirection: 'column',
};

export const boxAircraftSx = {
  width: '100%'
};

export const typeSx = {
  color: '#fff',
  backgroundColor: '#ACB5C3',
  borderRadius: '25px',
  whiteSpace: 'nowrap',
  height: '40px',
  p: '6px 12px',
  textTransform: 'capitalize',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: '#8e9bae !important'
  }
};

export const activeTypeSx = {
  backgroundColor: '#36918b',
  '&:hover': {
    backgroundColor: '#36918b !important'
  }
};

export const actionStyles = { display: 'flex', gap: '30px' };
import React from 'react';
import HorizontalNavLink from './HorizontalNavLink';
// eslint-disable-next-line import/no-cycle
import HorizontalNavGroup from './HorizontalNavGroup';

const resolveComponent = (item) => {
  if (item.children) return HorizontalNavGroup;

  return HorizontalNavLink;
};

const HorizontalNavItems = (props) => {
  // eslint-disable-next-line react/destructuring-assignment,array-callback-return,consistent-return
  const RenderMenuItems = props?.navList?.map((item, index) => {
    const TagName = resolveComponent(item);

    // eslint-disable-next-line react/no-array-index-key
    return <TagName {...props} key={index} item={item} />;
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{RenderMenuItems}</>;
};

export default HorizontalNavItems;

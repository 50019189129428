import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useCreateOdsMutation, useGetCrewListByPostQuery, useUpdateOdsMutation } from '../../../../store/session';
import { defaultValues } from './schema';

export const useOdsPositionForm = ({
  data, isSubmit, setIsSubmit, isOffline
}) => {
  const [crewPost, setCrewPost] = useState(null);
  const {
    control,
    setValue,
    handleSubmit,
    trigger,
    watch,
    getValues,
    formState: { dirtyFields }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const savedData = JSON.parse(localStorage.getItem(`${data.index}`));

  const [update] = useUpdateOdsMutation();
  const [create] = useCreateOdsMutation();
  const { data: pilotName } = useGetCrewListByPostQuery(
    crewPost,
    { skip: !crewPost, refetchOnMountOrArgChange: true }
  );

  const pilotNameOptions = pilotName?.map((e) => ({
    label: `${e.crew_name} ${e.crew_name}`,
    value: e.id
  }));

  const handleUpdate = async (values) => {
    if (isOffline) {
      localStorage.setItem(`${values.index}`, JSON.stringify(values));

      toast.error('The data was saved locally, to send the data to server internet connection is needed. Please, connect to the internet and try again...');

      return;
    }

    const toastId = toast.loading('Loading...');

    const formattedData = Object.keys(dirtyFields).reduce((res, key) => ({ ...res, [key]: values[key] }), {});
    if (Object.keys(dirtyFields).includes('start')) {
      formattedData.start = moment(formattedData.start).format('YYYY-MM-DD hh:mm');
    }
    if (Object.keys(dirtyFields).includes('finish')) {
      formattedData.finish = moment(formattedData.finish).format('YYYY-MM-DD hh:mm');
    }

    const res = await update({ data: formattedData, id: values.id });

    if (!res.error) {
      toast.success('Form was saved', {
        id: toastId,
      });

      localStorage.removeItem(`${values.index}`);

      return;
    }

    toast.error(res.error.data?.message, {
      id: toastId,
    });
  };

  const handleCreate = async (values) => {
    if (isOffline) {
      localStorage.setItem(`${values.index}`, JSON.stringify(values));

      toast.error('The data was saved locally, to send the data to server internet connection is needed. Please, connect to the internet and try again...');

      return;
    }

    const toastId = toast.loading('Loading...');

    const res = await create({
      ...values,
      start: moment(values.start).format('YYYY-MM-DD HH:mm'),
      finish: moment(values.finish).format('YYYY-MM-DD HH:mm'),
    });

    if (!res.error) {
      toast.success('Form was saved', {
        id: toastId,
      });

      localStorage.removeItem(`${values.index}`);

      return;
    }

    toast.error(res.error.data.message, {
      id: toastId,
    });
  };

  const onSubmit = (values) => {
    if (values.id) {
      handleUpdate(values);
    } else {
      handleCreate(values);
    }
  };

  useEffect(() => {
    if (savedData) {
      Object.entries(savedData).forEach(
        ([name, value]) => setValue(name, value),
      );

      setCrewPost(savedData.crew_post);
    } else if (data) {
      localStorage.setItem(`${data.index}`, JSON.stringify(data));

      Object.entries(data).forEach(
        ([name, value]) => setValue(name, value),
      );

      setCrewPost(data.crew_post);
    }
  }, [data]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit(onSubmit)();
      setIsSubmit(false);
    }
  }, [isSubmit]);

  return {
    control,
    pilotNameOptions,
    getValues,
    watch,
    trigger,
    setValue,
    setCrewPost
  };
};

export const defaultValues = {
  crew_id: '',
  crew_post: '',
  aircraft_id: '',
  ods_status: '',
  position_type: '',
  type: 4,
  start: null,
  finish: null,
};

import React from 'react';
import {
  Box, Card, Divider, Typography
} from '@mui/material';
import EditableTable from '../../../../components/EditableTable';
import { columns } from './columns';
import { useGetTrainingFlightCrewDefaultDocumentsTableQuery, useDeleteTrainingFlightCrewDefaultDocumentsTableMutation } from '../../../../store/session';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button';
import LinkButton from '../../../../components/LinkButton';
import { usePermissions } from '../../../../utils/hooks/usePermissions';

const DefaultDocuments = () => {
  const navigate = useNavigate();

  const hasPermissions = usePermissions('training_module_write');

  return (
    <>
      <LinkButton path="/training-module/flight-crew">
        <Button title="Back"></Button>
      </LinkButton>
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3">Document Blocks</Typography>
          <Button disabled={!hasPermissions} onClick={() => navigate('/training-module/flight-crew/create-block')} title="Add" />
        </Box>

        <Divider />

        <EditableTable
          useHook={useGetTrainingFlightCrewDefaultDocumentsTableQuery}
          useHookDelete={useDeleteTrainingFlightCrewDefaultDocumentsTableMutation}
          columns={columns}
          sortByType="DESC"
          sortByActive="block_name"
          hasPermissions={hasPermissions}
          getRowId={(row) => row.id}
        />
      </Card>
    </>
  );
};

export default DefaultDocuments;

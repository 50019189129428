import { Grid } from '@mui/material';
import React from 'react';
import Table from './list/Table';

const FuelPayments = () => {
  const title = 'Manage Fuel Payments';

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Table title={title} sx={{ backgroundColor: 'red' }} />
      </Grid>
    </Grid>
  );
};

export default FuelPayments;

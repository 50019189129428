export const Container = {
  flexGrow: 1,
  p: '30px',
  pt: 0,
};

export const ContainerSx = {
  marginTop: '30px',
};

export const ContainerSmall = {
  marginTop: '20px',
  padding: '0'
};

export const formControlLabel = {
  ml: '0'
};

export const linkButtonSx = {
  width: '100%',
  backgroundColor: 'rgba(0, 70, 136, 0.8)',
  color: '#fff',
  display: 'block',
  padding: '5px',
  height: '35px',
  textAlign: 'center',
};

import { styled } from '@mui/material/styles';
import Pencil from 'mdi-material-ui/Pencil';
import Delete from 'mdi-material-ui/Delete';
import { Airplane } from 'mdi-material-ui';
import Copy from 'mdi-material-ui/ContentCopy';

export const StyledPencil = styled(Pencil)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const StyledDelete = styled(Delete)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));

export const StyledCopy = styled(Copy)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const StyledAirplane = styled(Airplane)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const StyledHeader = styled(Copy)(() => ({
  '.MuiDataGrid-columnHeaders': {
    minHeight: '300px',
  },
}));

export const HeaderName = {
  fontSize: ' 0.75rem',
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontWeight: '500',
  letterSpacing: '0.17px',
};

import { yupResolver } from '@hookform/resolvers/yup';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Accordion, AccordionSummary,
  Button,
  Card,
  CardContent,
  Grid
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import timezones from 'timezones-list';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import Autocomplete from '../../../form/components/Autocomplete/Autocomplete';
import Checkbox from '../../../form/components/Checkbox';
import FieldError from '../../../form/components/FieldError';
import FormGroup from '../../../form/components/FormGroup';
import Input from '../../../form/components/Input';
import {
  useGetAirportMutation,
  useStoreAirportMutation,
  useUpdateAirportMutation,
} from '../../../store/session';
import { useHandleQueryFormError } from '../../../utils/hooks/useHandleQueryFormError';
import FuelSuppliersForm from './form/FuelSuppliersForm';
import SuppliersForm from './form/SuppliersForm';
import { defaultValues, schema } from './schema';

const ExpandMoreIcon = () => null;

const AirportForm = () => {
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const update = id !== 'create';
  const [updateAirport] = useUpdateAirportMutation();
  const [slotsVisibility, setSlotsVisibility] = useState(defaultValues.airport_SlotsRequired);
  const [storeAirport] = useStoreAirportMutation();
  const handleError = useHandleQueryFormError();
  const [getAirport] = useGetAirportMutation();
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    watch,
    setError,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });
  const {
    fields: supplierFields,
  } = useFieldArray({
    name: 'service_suppliers',
    control,
  });

  const allTimezonesOptions = timezones.map(({ tzCode }) => ({ value: tzCode, label: tzCode }));

  const {
    fields: fuelSupplierFields,
  } = useFieldArray({
    name: 'fuel_suppliers',
    control,
  });

  const supplierRepeaterProps = {
    supplierFields,
  };
  const fuelSupplierRepeaterProps = {
    fuelSupplierFields,
  };

  const handleAirportData = async () => {
    setLoading(true);
    const { data: result } = await getAirport({ id });

    return result;
  };

  const formProps = {
    setValue, control, Controller, errors, trigger, getValues, watch,
  };

  useEffect(() => {
    if (update) {
      handleAirportData().then((result) => {
        if (result) {
          Object.entries(result).forEach(
            ([name, value]) => {
              if (name === 'airport_SlotsRequired') setSlotsVisibility(value);
              if ((name === 'airport_RwyLength' && value === '')
                || (name === 'airport_RwyWidth' && value === '')
                || (name === 'airport_TimeZone2' && value === '')
                || (name === 'airport_lat' && (value === '' || value === null))
                || (name === 'airport_lon' && (value === '' || value === null))
              ) setValue(name, 0);
              else setValue(name, value);
              if (name === 'airport_fees_e_data') {
                setValue('fees_e_atr42', value?.atr42);
                setValue('fees_e_atr72', value?.atr72);
              }
              if (name === 'airport_fees_f_data') {
                setValue('fees_f_atr42', value?.atr42);
                setValue('fees_f_atr72', value?.atr72);
              }
            }
          );
        }
        setLoading(false);
      });
    }
  }, [update]);

  if (isLoading) {
    return <ContentPageLoader />;
  }

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      airport_fees_e_data: {
        atr42: data.fees_e_atr42,
        atr72: data.fees_e_atr72
      },
      airport_fees_f_data: {
        atr42: data.fees_f_atr42,
        atr72: data.fees_f_atr72
      }
    };
    if (update) {
      const res = await updateAirport({
        ...formData,
      });
      if (!res?.error) {
        toast.success('Airport updated!');
      } else {
        handleError(res.error, setError, getValues, toast);
        // toast.error('Something went wrong');
      }
    } else {
      const res = await storeAirport({
        ...formData,
      });
      if (!res?.error) {
        toast.success('Airport created!');
        navigate('/directories/airport');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const AirportDataErrors = !!errors.airport_name || !!errors.airport_country || !!errors.airport_icao || !!errors.airport_iata || !!errors.airport_SITA || !!errors.airport_lat || !!errors.airport_lon || !!errors.airport_email || !!errors.airport_Type || !!errors.airport_timezone;

  const AirportFeesErrors = !!errors.airport_fees_e_data || !!errors.airport_fees_f_data || !!errors.airport_fees_p;

  const AirportSlotErrors = !!errors.airport_slot_tel || !!errors.airport_slot_fax || !!errors.airport_slot_email || !!errors.airport_slot_sita || !!errors.airport_slot_aftn;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Grid container spacing={3} rowSpacing={2} sx={{ flexGrow: 1 }}>
            <Grid item xs={12} sm={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{ color: AirportDataErrors ? '#FF4D49' : 'rgba(76, 78, 100, 0.87)' }}>AIRPORT DATA</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CardContent sx={{ mt: 5 }}>
                    <Grid container spacing={5}>

                      <Grid item xs={12} sm={6}>
                        <FormGroup label="Name" required hasError={!!errors.airport_name}>
                          <Controller
                            name="airport_name"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Airport Name"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_name} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="Country" required hasError={!!errors.airport_country}>
                          <Controller
                            name="airport_country"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Airport Country"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_country} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="Nearest City" hasError={!!errors.airport_NearestCity}>
                          <Controller
                            name="airport_NearestCity"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Nearest City"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_NearestCity} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="ICAO" required hasError={!!errors.airport_icao}>
                          <Controller
                            name="airport_icao"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Icao"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_icao} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="IATA" required hasError={!!errors.airport_iata}>
                          <Controller
                            name="airport_iata"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="airport_iata"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_iata} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="AFS" hasError={!!errors.airport_AFS}>
                          <Controller
                            name="airport_AFS"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="AFS"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_AFS} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="SITA" required hasError={!!errors.airport_SITA}>
                          <Controller
                            name="airport_SITA"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Sita"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_SITA} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="Lat" required hasError={!!errors.airport_lat}>
                          <Controller
                            name="airport_lat"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Lat"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_lat} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="Lon" required hasError={!!errors.airport_lon}>
                          <Controller
                            name="airport_lon"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Lon"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_lon} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="Web" hasError={!!errors.airport_Web}>
                          <Controller
                            name="airport_Web"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Web"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_Web} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="Email" required hasError={!!errors.airport_email}>
                          <Controller
                            name="airport_email"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Email"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_email} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="Type" required hasError={!!errors.airport_Type}>
                          <Controller
                            name="airport_Type"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Type"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_Type} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="Category" hasError={!!errors.airport_cat}>
                          <Controller
                            name="airport_cat"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Category"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_cat} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup label="Entry" hasError={!!errors.airport_Entry}>
                          <Controller
                            name="airport_Entry"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Entry"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_Entry} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormGroup label="Customs Available" hasError={!!errors.airport_CustomsAvailable}>
                          <Controller
                            name="airport_CustomsAvailable"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Customs Available"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_CustomsAvailable} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormGroup label="Slots required" hasError={!!errors.airport_SlotsRequired}>
                          <Controller
                            name="airport_SlotsRequired"
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                                onChange={(el) => {
                                  setSlotsVisibility(el.target.checked);
                                  field.onChange(el);
                                }}
                              />
                            )}
                          />
                          <FieldError error={errors.airport_SlotsRequired} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormGroup label="Extensions possible" hasError={!!errors.airport_extensions_possible}>
                          <Controller
                            name="airport_extensions_possible"
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                              />
                            )}
                          />
                          <FieldError error={errors.airport_extensions_possible} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormGroup label="Fuel Available" hasError={!!errors.airport_FuelAvailable}>
                          <Controller
                            name="airport_FuelAvailable"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Fuel Available"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_FuelAvailable} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormGroup label="Fire Category" hasError={!!errors.airport_FireCategory}>
                          <Controller
                            name="airport_FireCategory"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Fire Category"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_FireCategory} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormGroup label="Hours" hasError={!!errors.airport_AirportHours}>
                          <Controller
                            name="airport_AirportHours"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Hours"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_AirportHours} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormGroup label="Tower Hours" hasError={!!errors.airport_TowerHours}>
                          <Controller
                            name="airport_TowerHours"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Tower Hours"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_TowerHours} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormGroup label="Time Zone" required hasError={!!errors.airport_timezone}>
                          <Controller
                            name="airport_timezone"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                value={field.value}
                                options={allTimezonesOptions || []}
                                size="small"
                                placeholder="Please select a Time Zone"
                                onChange={(e, value) => {
                                  field.onChange(e);
                                  setValue('airport_timezone', value?.value || '');
                                }}
                              />
                            )}
                          />
                          <FieldError error={errors.airport_timezone} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormGroup label="Time Zone2" hasError={!!errors.airport_TimeZone2}>
                          <Controller
                            name="airport_TimeZone2"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Time Zone2"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_TimeZone2} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormGroup label="Dst" hasError={!!errors.airport_DST}>
                          <Controller
                            name="airport_DST"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Dst"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_DST} />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </CardContent>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>TECHNICAL DATA</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CardContent sx={{ mt: 5 }}>
                    <Grid container spacing={5}>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="Max Size Ap" hasError={!!errors.airport_MaxSizeAP}>
                          <Controller
                            name="airport_MaxSizeAP"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Max Size Ap"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_MaxSizeAP} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="Airport Elevation" hasError={!!errors.airport_Elevation}>
                          <Controller
                            name="airport_Elevation"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Airport Elevation"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_Elevation} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="IFR/VFR" hasError={!!errors.airport_IFRorVFR}>
                          <Controller
                            name="airport_IFRorVFR"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="IFR/VFR"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_IFRorVFR} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="Primary Runway" hasError={!!errors.airport_PrimaryRunwayID}>
                          <Controller
                            name="airport_PrimaryRunwayID"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Primary Runway"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_PrimaryRunwayID} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="Surface" hasError={!!errors.airport_Surface}>
                          <Controller
                            name="airport_Surface"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Surface"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_Surface} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="Rwy Length" hasError={!!errors.airport_RwyLength}>
                          <Controller
                            name="airport_RwyLength"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Rwy Length"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_RwyLength} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="Rwy Width" hasError={!!errors.airport_RwyWidth}>
                          <Controller
                            name="airport_RwyWidth"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Rwy Width"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_RwyWidth} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="Pcn" hasError={!!errors.airport_PCN}>
                          <Controller
                            name="airport_PCN"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Pcn"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_PCN} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="Intensity" hasError={!!errors.airport_Intensity}>
                          <Controller
                            name="airport_Intensity"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Intensity"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_Intensity} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="Centreline" hasError={!!errors.airport_Centreline}>
                          <Controller
                            name="airport_Centreline"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Centreline"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_Centreline} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="Pal or Pcl" hasError={!!errors.airport_PALorPCL}>
                          <Controller
                            name="airport_PALorPCL"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Pal or Pcl"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_PALorPCL} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="Pal frequency" hasError={!!errors.airport_PALFrequency}>
                          <Controller
                            name="airport_PALFrequency"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Pal frequency"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_PALFrequency} />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </CardContent>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {slotsVisibility ? (
              <Grid item xs={12} sm={6}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ color: AirportSlotErrors ? '#FF4D49' : 'rgba(76, 78, 100, 0.87)' }}>SLOT COORDINATES</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CardContent sx={{ mt: 5 }}>
                      <Grid container spacing={5}>

                        <Grid item xs={12} sm={12}>
                          <FormGroup label="SLOT Tel" required hasError={!!errors.airport_slot_tel}>
                            <Controller
                              name="airport_slot_tel"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  placeholder="Tel"
                                  {...field}
                                />
                              )}
                            />

                            <FieldError error={errors.airport_slot_tel} />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormGroup label="SLOT Fax" required hasError={!!errors.airport_slot_fax}>
                            <Controller
                              name="airport_slot_fax"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  placeholder="Fax"
                                  {...field}
                                />
                              )}
                            />

                            <FieldError error={errors.airport_slot_fax} />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormGroup label="SLOT Email" required hasError={!!errors.airport_slot_email}>
                            <Controller
                              name="airport_slot_email"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  placeholder="Email"
                                  {...field}
                                />
                              )}
                            />

                            <FieldError error={errors.airport_slot_email} />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormGroup label="SLOT SITA" required hasError={!!errors.airport_slot_sita}>
                            <Controller
                              name="airport_slot_sita"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  placeholder="SITA"
                                  {...field}
                                />
                              )}
                            />

                            <FieldError error={errors.airport_slot_sita} />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormGroup label="SLOT AFTN" required hasError={!!errors.airport_slot_aftn}>
                            <Controller
                              name="airport_slot_aftn"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  placeholder="AFTN"
                                  {...field}
                                />
                              )}
                            />

                            <FieldError error={errors.airport_slot_aftn} />
                          </FormGroup>
                        </Grid>
                      </Grid>

                    </CardContent>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ) : <></>}

            <Grid item xs={12} sm={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{ color: AirportFeesErrors ? '#FF4D49' : 'rgba(76, 78, 100, 0.87)' }}>AIRPORT FEES</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CardContent sx={{ mt: 5 }}>
                    <Grid container spacing={5}>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="ATR-42 Empty" required hasError={!!errors.fees_e_atr42}>
                          <Controller
                            name="fees_e_atr42"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Fees ATR-42 Empty"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.fees_e_atr42} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="ATR-72 Empty" required hasError={!!errors.fees_e_atr72}>
                          <Controller
                            name="fees_e_atr72"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Fees ATR-72 Empty"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.fees_e_atr72} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="ATR-42 Loaded" required hasError={!!errors.fees_f_atr42}>
                          <Controller
                            name="fees_f_atr42"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Fees ATR-42 Loaded"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.fees_f_atr42} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="ATR-72 Loaded" required hasError={!!errors.fees_f_atr72}>
                          <Controller
                            name="fees_f_atr72"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Fees ATR-72 Loaded"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.fees_f_atr72} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormGroup label="Fees Pax" required hasError={!!errors.airport_fees_p}>
                          <Controller
                            name="airport_fees_p"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Fees Pax"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_fees_p} />
                        </FormGroup>
                      </Grid>

                    </Grid>

                  </CardContent>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>INVOICE DEPARTMENT</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CardContent sx={{ mt: 5 }}>
                    <Grid container spacing={5}>

                      <Grid item xs={12} sm={12} md={4}>
                        <FormGroup label="Tel" hasError={!!errors.airport_invoice_tel}>
                          <Controller
                            name="airport_invoice_tel"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Tel"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_invoice_tel} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <FormGroup label="Fax" hasError={!!errors.airport_invoice_fax}>
                          <Controller
                            name="airport_invoice_fax"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Fax"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_invoice_fax} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <FormGroup label="Email" hasError={!!errors.airport_invoice_email}>
                          <Controller
                            name="airport_invoice_email"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Email"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_invoice_email} />
                        </FormGroup>
                      </Grid>

                    </Grid>

                  </CardContent>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>TOWER INFO</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CardContent sx={{ mt: 5 }}>
                    <Grid container spacing={5}>

                      <Grid item xs={12} sm={12} md={4}>
                        <FormGroup label="Tel" hasError={!!errors.airport_tower_tel}>
                          <Controller
                            name="airport_tower_tel"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Tel"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_tower_tel} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <FormGroup label="Fax" hasError={!!errors.airport_tower_fax}>
                          <Controller
                            name="airport_tower_fax"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Fax"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_tower_fax} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <FormGroup label="Email" hasError={!!errors.airport_tower_email}>
                          <Controller
                            name="airport_tower_email"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Email"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_tower_email} />
                        </FormGroup>
                      </Grid>

                    </Grid>

                  </CardContent>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} sm={6}>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>NOTES</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CardContent sx={{ mt: 5 }}>
                    <Grid container spacing={5}>

                      <Grid item xs={12} sm={12}>
                        <FormGroup label="Notes" hasError={!!errors.airport_notes}>
                          <Controller
                            name="airport_notes"
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Notes"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors.airport_notes} />
                        </FormGroup>
                      </Grid>

                    </Grid>

                  </CardContent>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} sm={12}>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Suppliers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CardContent sx={{ mt: 5 }}>
                    <Grid container spacing={5}>

                      <Grid item xs={12}>
                        <Card sx={{ mt: '1.25rem' }}>
                          <TabContext value="1">
                            <CardContent sx={{ padding: '0rem' }}>
                              <TabPanel value="1" sx={{ padding: '0rem' }}>
                                <SuppliersForm {...formProps} {...supplierRepeaterProps} />
                              </TabPanel>
                            </CardContent>
                          </TabContext>
                        </Card>
                      </Grid>

                    </Grid>

                  </CardContent>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} sm={12}>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Fuel</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CardContent sx={{ mt: 5 }}>
                    <Grid container spacing={5}>

                      <Grid item xs={12}>
                        <Card sx={{ mt: '1.25rem' }}>
                          <TabContext value="1">
                            <CardContent sx={{ padding: '0rem' }}>
                              <TabPanel value="1" sx={{ padding: '0rem' }}>
                                <FuelSuppliersForm {...formProps} {...fuelSupplierRepeaterProps} />
                              </TabPanel>
                            </CardContent>
                          </TabContext>
                        </Card>
                      </Grid>

                    </Grid>

                  </CardContent>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" size="large">
                {update ? 'Update' : 'Save' }
              </Button>
            </Grid>
          </Grid>

        </CardContent>
      </Card>
    </form>
  );
};

export default AirportForm;

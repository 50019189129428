import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';

const PdfViewer = ({ s3Link }) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    if ((s3Link.includes('.doc'))) {
      handleLoad();
    }
  }, [s3Link]);

  return (
    <Box position="relative" width="100%" height="100%">
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={10}
          bgcolor="rgba(255, 255, 255, 0.8)"
        >
          <CircularProgress />
        </Box>
      )}

      {/* PDF iframe */}
      {(!s3Link.includes('.doc')) && (
      <iframe
        src={s3Link}
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        title="PDF Viewer"
        onLoad={handleLoad}
      />
      )}

      {/* WORD iframe */}
      {(!!s3Link.includes('.doc')) && (
        <Box sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' 
        }}
        >
          <Typography variant="h4">Files with (.docx) extension are not supported.</Typography>
        </Box>
      )}
    </Box>
  );
};

export default PdfViewer;

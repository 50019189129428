import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FolderIcon from '@mui/icons-material/Folder';
import Card from './Card';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  containerSx, flexContainer, header, settingsBtn, cardsContainer, ContainerSmall, ContainerSmallXS
} from './styles';
import { useDownloadCrewDocMutation, useUploadCrewDocMutation } from '../../../store/session';
import { useMediaQuery } from '@mui/material';
import { toast } from 'react-hot-toast';
import FileSaver from 'file-saver';

const getDaysLeft = (dayDiff, document) => {
  if (document.alert_treshold <= 0) {
    return null;
  }

  if (dayDiff <= 0 && Math.abs(dayDiff) <= document.alert_treshold) {
    return `- [DAYS LEFT: ${Math.abs(dayDiff) || 'TODAY'}]`;
  }

  if (dayDiff > 0) {
    return '- [DOCUMENT WAS EXPIRED]';
  }

  return null;
};

const Documentation = ({ refetch }) => {
  const params = useParams();
  const [selectedId, setSelectedId] = useState({ });

  const {
    control, setValue, getValues, trigger,
  } = useFormContext();

  const {
    fields,
  } = useFieldArray({
    name: 'documents',
    control,
  });

  const [uploadFile, { isLoading }] = useUploadCrewDocMutation();
  const [downloadCrewDoc] = useDownloadCrewDocMutation();

  const maxWidth590px = useMediaQuery('(max-width:590px)');
  const maxWidth768px = useMediaQuery('(max-width:768px)');

  const handleChangeExpired = (date, index) => {
    const formattedDate = date ? moment(date).format('yyyy-MM-DD') : null;
    setValue(`documents.${index}.date_expired`, formattedDate, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };

  const handleDownload = async(document_id, name) => {
    const toastId = toast.loading('Loading...');

    const res = await downloadCrewDoc({ crew_id: params.id, document_id });

    const fileName = name.replace( /\\/g, '/' ).replace( /.*\//, '' );

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    FileSaver.saveAs(res.data, fileName);

    toast.success('Document download started on your computer', {
      id: toastId,
    });
  };

  const handleUpload = async (e, item, index) => {
    if (!e.target.files[0]) return;

    const formData = new FormData();

    formData.append('crew_doc', e.target.files[0]);
    formData.append('id', item.item_id);

    setSelectedId(item.item_id);

    const res = await uploadFile(formData);

    e.target.value = null;

    if (res.error) {
      toast.error('Something went wrong');

      return;
    }

    toast.success('Successfully upload file!');

    refetch();

    const docUrl = res?.data?.crew_doc;

    if (docUrl) {
      setValue(`documents.${index}.instance_path_loc`, docUrl, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
      trigger('documents');
    }
  };

  return (
    <Box sx={{ ...containerSx, ...(maxWidth768px && ContainerSmallXS), ...(maxWidth590px && ContainerSmall), }}>
      <Box sx={header}>
        <Box sx={flexContainer}>
          <FolderIcon />
          CREW DOCS
        </Box>

        <Link to="/directories/crewdoc">
          <Button variant="contained" sx={settingsBtn}>
            Settings
          </Button>
        </Link>
      </Box>

      <Box sx={cardsContainer}>
        {fields.map((document, index) => {
          const dateExpired = getValues(`documents.${index}.date_expired`);
          const dayDiff = moment().startOf('d').diff(moment(dateExpired).startOf('d'), 'day');

          return (
            <Card
              key={document.id}
              dayLefts={getDaysLeft(dayDiff, document)}
              handleChangeExpired={(date) => handleChangeExpired(date, index)}
              handleUpload={(e) => handleUpload(e, document, index)}
              dateExpired={dateExpired}
              handleDownload={handleDownload}
              selectedId={selectedId}
              isLoading={isLoading}
              {...document}
            />
          ); })}
      </Box>
    </Box>
  ); };

export default Documentation;

import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, } from 'react-router-dom';
import { HISTORY_GO_BACK } from '../../../constans/constants';
import { useCreateOdsMutation, useGetCrewListByPostQuery, useUpdateOdsMutation } from '../../../store/session';
import { getTimeDiffHm } from '../../../utils/getTime';
import { defaultValues, schema } from './schema';
import { usePermissions } from '../../../utils/hooks/usePermissions';

export const useOdsPositionForm = (data) => {
  const navigate = useNavigate();
  const [crewPost, setCrewPost] = useState(null);
  const {
    control,
    setValue,
    handleSubmit,
    trigger,
    setError,
    watch,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [update] = useUpdateOdsMutation();
  const [create] = useCreateOdsMutation();
  const { data: pilotName } = useGetCrewListByPostQuery(
    crewPost,
    { skip: !crewPost, refetchOnMountOrArgChange: true }
  );
  const hasPermissionsOdsEdit = usePermissions('ods_edit');

  const handleBack = () => {
    navigate(HISTORY_GO_BACK);
  };

  const pilotNameOptions = pilotName?.map((e) => ({
    label: e.crew_name,
    value: e.id
  }));

  const handleCreate = async ({ total_positioning_time, ...values }) => {
    if (total_positioning_time) {
      const time = total_positioning_time.split(':');

      if (time[0] < 0 || time[1] < 0) {
        setError('total_positioning_time', { type: 'custom', message: 'TOTAL TRAINING TIME cannot be less then 0.' });

        return;
      }
    }

    const toastId = toast.loading('Loading...');

    const formattedData = Object.keys(dirtyFields).reduce((res, key) => ({ ...res, [key]: values[key] }), {});

    if (Object.keys(dirtyFields).includes('start')) {
      formattedData.start = moment(formattedData.start).format('YYYY-MM-DD HH:mm');
    }

    if (Object.keys(dirtyFields).includes('finish')) {
      formattedData.finish = moment(formattedData.finish).format('YYYY-MM-DD HH:mm');
    }

    const res = await update({ data: formattedData, id: values.id });

    if (!res.error) {
      toast.success('Form was saved', {
        id: toastId,
      });

      navigate('/directories/ods/other');

      return;
    }

    toast.error(res.error.data.message, {
      id: toastId,
    });
  };

  const handleUpdate = async ({ total_positioning_time, ...values }) => {
    if (total_positioning_time) {
      const time = total_positioning_time.split(':');

      if (time[0] < 0 || time[1] < 0) {
        setError('total_positioning_time', { type: 'custom', message: 'TOTAL TRAINING TIME cannot be less then 0.' });

        return;
      }
    }

    const toastId = toast.loading('Loading...');

    const res = await create({
      ...values,
      start: moment(values.start).format('YYYY-MM-DD HH:mm'),
      finish: moment(values.finish).format('YYYY-MM-DD HH:mm'),
    });

    if (!res.error) {
      toast.success('Form was saved', {
        id: toastId,
      });

      navigate('/directories/ods/other');

      return;
    }

    toast.error(res.error.data.message, {
      id: toastId,
    });
  };

  const onSubmit = (values) => {
    if (values.id) {
      handleCreate(values);
    } else {
      handleUpdate(values);
    }
  };

  useEffect(() => {
    if (data) {
      Object.entries(data).forEach(
        ([name, value]) => setValue(name, value),
      );
      setCrewPost(data.crew_post);
      setValue('total_positioning_time', getTimeDiffHm(data.start, data.finish));
    }
  }, [data]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'start' && value.finish && type === 'change') {
        setValue('total_positioning_time', getTimeDiffHm(value.start, value.finish));
      }

      if (name === 'finish' && value.start && type === 'change') {
        setValue('total_positioning_time', getTimeDiffHm(value.start, value.finish));
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  return {
    control,
    pilotNameOptions,
    watch,
    errors,
    getValues,
    setCrewPost,
    trigger,
    setValue,
    onSubmit,
    handleSubmit,
    handleBack,
    hasPermissionsOdsEdit
  };
};

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Card, CardContent, CardHeader, Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input/Input';
import Upload from '../../../../components/Upload';
import Autocomplete from '../../../../form/components/Autocomplete/Autocomplete';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import { useGetUserDocumentsQuery, useSendUserDocumentsMutation } from '../../../../store/session';
import { defaultValues, schema } from './schema';
import {
  block, button, documentForm, file, fileName, groupTitleSx, label
} from './style';

const DocumentsForm = () => {
  const [nameFile, setNameFile] = useState();
  const { data: userDocuments, refetch: refetchUserList } = useGetUserDocumentsQuery({
    refetchOnMountOrArgChange: true
  });
  const [sendDocuments] = useSendUserDocumentsMutation();
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const userOptions = userDocuments?.reduce((res, item) => (
    [...res, ...item.users.map(user => ({ group_name: item.group_name, value: user.id, label: user.name }))]
  ), []) || [];

  useEffect(() => {
    refetchUserList();
  }, []);

  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const uploadFile = (e) => {
    setNameFile(e.target.files[0].name);

    setValue('file', e.target.files[0]);
  };

  const handleSelectAll = (params) => {
    const users = getValues('users');
    const groupUsersLength = userOptions.filter(option => option.group_name === params.group).length;
    const selectedGroupLength = users.filter(option => option.group_name === params.group).length;
    const userIds = users.map(user => user.value);

    setValue('users', groupUsersLength !== selectedGroupLength ? [
      ...users,
      ...userOptions.filter(option => option.group_name === params.group && !userIds.includes(option.value))
    ] : users.filter(user => user.group_name !== params.group));
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const users = data.users.map(item => item.value);
    const formData = new FormData();

    formData.append('document_description', data.description);
    formData.append('file', data.file);
    users.forEach(user => formData.append('users[]', user));

    const res = await sendDocuments(formData);

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    toast.success('The document has been sent!', {
      id: toastId,
    });

    reset();
    document.getElementById('file-upload').value = '';
    setNameFile(null);
  };

  return (
    <Card sx={{ width: maxWidth800px ? '100%' : '65%', mt: 5 }}>
      <CardHeader title="Send file to users Form:" sx={{ paddingLeft: '0', paddingRight: '0' }} />
      <CardContent sx={{ paddingLeft: '0', paddingRight: '0', mt: 5 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ ...documentForm, ...(maxWidth800px && { width: '100%' }) }}>

            <Box sx={block}>
              <FormGroup sx={label} required label="Users" hasError={!!errors.users}>
                <Controller
                  name="users"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      options={userOptions}
                      groupBy={(option) => option.group_name}
                      size="small"
                      placeholder="Please select a User"
                      onChange={(e, value ) => {
                        field.onChange(e);
                        setValue('users', value);
                        trigger('users', value);
                      }}
                      renderGroup={(params) => (
                        <Box
                          component="li"
                          sx={groupTitleSx}
                          onClick={() => handleSelectAll(params)}
                        >
                          <Typography sx={{ fontWeight: 'bold' }}>{params.group}</Typography>
                          <Typography onClick={stopPropagation}>{params.children}</Typography>
                        </Box>
                      )}
                    />
                  )}
                />
                <FieldError error={errors.users} />
              </FormGroup>
            </Box>

            <Box sx={file}>
              <FormGroup sx={label} required label="File" hasError={!!errors.file}>
                <Controller
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <Upload
                      {...field}
                      handleUpload={(e) => {
                        field.onChange(e);
                        uploadFile(e);
                      }}
                      title="Upload file"
                    />
                  )}
                />
                <FieldError error={errors.file} />
              </FormGroup>
              <Typography sx={fileName}>{nameFile}</Typography>
            </Box>

            <Box sx={block}>
              <FormGroup sx={label} label="Document description" required hasError={!!errors.description}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      rows={4}
                      multiline
                      variant="filled"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
                <FieldError error={errors.description} />
              </FormGroup>
            </Box>

            <Button sx={button} title="Send" type="submit" />
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default DocumentsForm;

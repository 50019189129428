import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import toast from 'react-hot-toast';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import {
  useUpdateSupplierMutation,
  useGetSupplierMutation,
  useStoreSupplierMutation,
  useGetAirportListQuery,
  useGetServiceListQuery,
} from '../../../store/session';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, defaultValues } from './schema';
import FormGroup from '../../../form/components/FormGroup';
import Input from '../../../form/components/Input';
import FieldError from '../../../form/components/FieldError';
import Checkbox from '../../../form/components/Checkbox';

const SupplierForm = () => {
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const update = id !== 'create';
  const [updateSupplier] = useUpdateSupplierMutation();
  const [storeSupplier] = useStoreSupplierMutation();
  const [getSupplier] = useGetSupplierMutation();
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { data: AirportList, isLoading: airportListLoad } = useGetAirportListQuery();
  const { data: ServiceList, isLoading: serviceListLoad } = useGetServiceListQuery();

  const services = getValues('services');

  const handleSupplierData = async () => {
    setLoading(true);
    const { data: result } = await getSupplier({ id });

    return result;
  };

  useEffect(() => {
    if (ServiceList) {
      if (update) {
        handleSupplierData().then((result) => {
          if (result) {
            Object.entries(result).forEach(([name, value]) => {
              if (name === 'services') {
                const idsFromValue = value.map(i => i.id);
                const clearArray = ServiceList.filter(i => !idsFromValue.includes(i.id));
                const supplierServices = clearArray.concat(value).sort((a, b) => a.id - b.id);

                setValue('services', supplierServices);

                return;
              }

              setValue(name, value);
            }); }

          setLoading(false);
        });
      } else {
        setValue('services', ServiceList);
      }
    }
  }, [update, ServiceList]);

  if (isLoading || airportListLoad || serviceListLoad) {
    return <ContentPageLoader />;
  }

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    if (update) {
      const idsUpdatedServices = dirtyFields.services ? Object.keys(dirtyFields.services).map(i => +i + 1) : [];

      const updatedServices = data.services.filter(i => idsUpdatedServices.includes(i.id));

      const res = await updateSupplier({
        id,
        data: {
          ...data,
          services: updatedServices || [],
        }
      });

      if (!res?.error) {
        toast.success('Supplier updated!', {
          id: toastId
        });
        navigate(`/directories/supplier/${id}`);
      } else {
        toast.error('Something went wrong', {
          id: toastId
        });
      }
    } else {
      const res = await storeSupplier({
        ...data,
        services: data.services.filter(i => !!i.active),
      });
      if (!res?.error) {
        toast.success('Supplier  created!', {
          id: toastId
        });
        navigate('/directories/supplier');
      } else {
        toast.error('Something went wrong', {
          id: toastId
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4} rowSpacing={2}>
        <Grid item xs={12} sm={8}>
          <Card sx={{ mt: 3 }}>
            <CardHeader title="Create Supplier" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={3} rowSpacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={12} sm={6}>
                  <FormGroup label="Airport" required hasError={!!errors.srvsup_ap}>
                    <Controller
                      name="srvsup_ap"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { ref, onChange, value } }) => (
                        <Autocomplete
                          freeSolo
                          value={
                            AirportList?.filter(
                              (AirportItem) => AirportItem.id === value
                            )[0] || null
                          }
                          options={AirportList}
                          getOptionLabel={(option) => `${option.airport_iata}/${option.airport_icao} (${option.airport_name})`
                            || ''}
                          isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              inputRef={ref}
                              error={Boolean(errors.srvsup_ap)}
                            />
                          )}
                          onChange={(e, data) => {
                            setValue(
                              'srvsup_ap',
                              `${data?.airport_iata}/${data?.airport_icao} ${data?.airport_name}`
                            );
                            onChange(data?.id);
                          }}
                        />
                      )}
                    />

                    <FieldError error={errors.srvsup_ap} />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormGroup label="Name" required hasError={!!errors.srvsup_name}>
                    <Controller
                      name="srvsup_name"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Name" {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_name} />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormGroup label="Phone" required hasError={!!errors.srvsup_phone}>
                    <Controller
                      name="srvsup_phone"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Phone" {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_phone} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup label="Fax" hasError={!!errors.srvsup_fax}>
                    <Controller
                      name="srvsup_fax"
                      control={control}
                      render={({ field }) => <Input placeholder="Fax" {...field} />}
                    />

                    <FieldError error={errors.srvsup_fax} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup label="VHF" hasError={!!errors.srvsup_vhf}>
                    <Controller
                      name="srvsup_vhf"
                      control={control}
                      render={({ field }) => <Input placeholder="VHF" {...field} />}
                    />

                    <FieldError error={errors.srvsup_vhf} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup label="Email" hasError={!!errors.srvsup_email}>
                    <Controller
                      name="srvsup_email"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Email" {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_email} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup
                    label="Mobile Tel"
                    hasError={!!errors.srvsup_MobileTel}
                  >
                    <Controller
                      name="srvsup_MobileTel"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Mobile Tel" {...field} />
                      )}
                    />

                    <FieldError error={errors.company_website} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup
                    label="After Hours Tel"
                    hasError={!!errors.srvsup_AfterHoursTel}
                  >
                    <Controller
                      name="srvsup_AfterHoursTel"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="After Hours Tel" {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_AfterHoursTel} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup label="Skype" hasError={!!errors.srvsup_Skype}>
                    <Controller
                      name="srvsup_Skype"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Skype" {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_Skype} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup label="Sita" hasError={!!errors.srvsup_sita}>
                    <Controller
                      name="srvsup_sita"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Sita" {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_sita} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup label="AFTN" hasError={!!errors.srvsup_aftn}>
                    <Controller
                      name="srvsup_aftn"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="AFTN" multiline rows={4} {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_aftn} />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormGroup label="Open" hasError={!!errors.srvsup_open}>
                    <Controller
                      name="srvsup_open"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Open" multiline rows={4} {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_open} />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormGroup label="Website" hasError={!!errors.srvsup_website}>
                    <Controller
                      name="srvsup_website"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Website" {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_website} />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormGroup
                    label="Tel of Billing"
                    hasError={!!errors.srvsup_TelInvoice}
                  >
                    <Controller
                      name="srvsup_TelInvoice"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Tel of Billing" {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_TelInvoice} />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormGroup
                    label="Email of Billing"
                    hasError={!!errors.srvsup_EmailInvoice}
                  >
                    <Controller
                      name="srvsup_EmailInvoice"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Email of Billing" {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_EmailInvoice} />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormGroup label="Notes" hasError={!!errors.srvsup_notes}>
                    <Controller
                      name="srvsup_notes"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Notes" multiline rows={4} {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_notes} />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" size="large">
                  {update ? 'Update' : 'Save'}
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ flexGrow: 1 }}>
          <Card sx={{ mt: 3 }}>
            <CardHeader title="Physical Location" />
            <CardContent sx={{ mt: 5 }}>

              <Grid item xs={12} sm={12}></Grid>

              <FormGroup
                label="Physical Location"
                hasError={!!errors.srvsup_address}
              >
                <Controller
                  name="srvsup_address"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Physical Location"
                      multiline
                      rows={4}
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.srvsup_address} />
              </FormGroup>

            </CardContent>
          </Card>
          <Card sx={{ mt: 3 }}>
            <CardHeader title="Services" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={3} rowSpacing={2} sx={{ flexGrow: 1 }}>

                {services?.map((item, index) => (
                  <Grid item xs={12} sm={12} key={item.id}>
                    <FormGroup>
                      <Grid item xs={12} sm={12}>
                        <FormGroup>
                          <Controller
                            name={`services.${index}.active`}
                            control={control}
                            render={({ field }) => (
                              <FormControlLabel
                                {...field}
                                control={<Checkbox />}
                                label={item.services_name}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                    </FormGroup>
                  </Grid>
                ))}

              </Grid>

            </CardContent>
          </Card>
          <Card sx={{ mt: 3 }}>
            <CardHeader title="HANDLING FEES" />
            <CardContent sx={{ mt: 5 }}>
              <Grid container spacing={3} rowSpacing={2} sx={{ flexGrow: 1 }}>

                <Grid item xs={12} sm={12}>
                  <FormGroup
                    label="Ramp Handling"
                    hasError={!!errors.srvsup_h_ramp}
                  >
                    <Controller
                      name="srvsup_h_ramp"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Ramp Handling" {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_h_ramp} />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormGroup
                    label="Cargo Handling"
                    hasError={!!errors.srvsup_h_cargo}
                  >
                    <Controller
                      name="srvsup_h_cargo"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Cargo Handling" {...field} />
                      )}
                    />

                    <FieldError error={errors.srvsup_h_cargo} />
                  </FormGroup>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default SupplierForm;

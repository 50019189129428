import toast from 'react-hot-toast';
import {
  useDeleteCertificateMutation,
  useGetCertificatesQuery,
  useOpenCertificateMutation,
  useUploadCertificateMutation
} from '../../../../../../../../store/slices/logisticsSlice';
import { usePermissions } from '../../../../../../../../utils/hooks/usePermissions';

export const useCertificates = ({ order, part }) => {
  const canEdit = usePermissions(['logistics_storage_master', 'logistics_storage_write', 'internal_logistics_storage_master']);
  const [openCertificate, { isLoading: certificateLoading }] = useOpenCertificateMutation();
  const [deleteCertificate, { isLoading: deleteLoading }] = useDeleteCertificateMutation();
  const [uploadCertificate, { isLoading: uploadLoading }] = useUploadCertificateMutation();
  const { data: certificates, isLoading: certificatesLoading, refetch } = useGetCertificatesQuery(
    { order_id: !part ? order?.id : null, part_id: part ? order?.id : null },
    { refetchOnMountOrArgChange: true }
  );

  const loading = uploadLoading || certificatesLoading || certificateLoading || deleteLoading;

  const uploadCert = async (e) => {
    if (!e.target.files[0]) return;
    const toastId = toast.loading('Uploading...');

    const formData = new FormData();
    if (part) {
      formData.append('part_id', order.id);
    } else {
      formData.append('order_id', order.id);
    }

    formData.append('certificate', e.target.files[0]);

    const res = await uploadCertificate(formData);
    e.target.value = null;

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    await refetch();
    toast.success('Successfully upload certificate!', {
      id: toastId
    });
  };

  const handleOpenCertificate = async (cert_id, download = false) => {
    const toastId = toast.loading('Opening...');

    await openCertificate(cert_id).then((res) => {
      if (!res?.errros) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);

        if (download) {
          const downloadLink = document.createElement('a');
          downloadLink.href = fileUrl;
          downloadLink.download = `${order.part_name}_${order.part_number}_${order.serial_number}.pdf`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          window.open(fileUrl, '_blank', 'noreferrer');
        }
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleDeleteCertificate = async (cert_id) => {
    const toastId = toast.loading('Deleting...');

    await deleteCertificate(cert_id).then((res) => {
      if (!res?.errros) {
        toast.success('Success!', {
          id: toastId,
        });
        refetch();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  return {
    uploadCert,
    loading,
    certificates,
    canEdit,
    handleOpenCertificate,
    certificateLoading,
    handleDeleteCertificate,
    deleteLoading
  };
};

import EmailIcon from '@mui/icons-material/Email';
import { Box, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { MONTH_SHORT } from '../../pages/MainDeckSchedule/constants';
import { usePermissions } from '../../utils/hooks/usePermissions';
import SendRoster from './SendRoster/index';
import {
  BtnActive,
  BtnGroup,
  BtnGroupX,
  BtnGroupXs,
  BtnSend,
  ButtonContainer,
  ButtonContainerX,
  ButtonContainerXs,
  DefaultBtn,
  DefaultBtnMounth,
  DefaultBtnMounthX,
  DefaultBtnX,
  DefaultBtnXs,
} from './styles';

export const MAX_YEAR = +moment().add( 1, 'years').format('YYYY');

const ButtonSelect = ({
  year, months, setYear, setMonths, disabledSendRoster = true, options, acftType, sx
}) => {
  const hasPermissions = usePermissions('roster_edit');

  const [initialMonths, setInitialMonths] = useState(months);
  const urlParams = new URLSearchParams(window.location.search);
  const selectedYear = `01-01-${Math.min(+(urlParams.get('year') || moment().format('YYYY')), MAX_YEAR - 1)}`;
  const defaultYears = [
    moment(selectedYear, 'MM-DD-YYYY').subtract(1, 'years').format('YYYY'),
    moment(selectedYear, 'MM-DD-YYYY').format('YYYY'),
    moment(selectedYear, 'MM-DD-YYYY').add(1, 'years').format('YYYY'),
  ];
  const [openDrawer, setOpenDrawer] = useState(false);
  const [years, setYears] = useState(defaultYears);
  const maxWidth1040px = useMediaQuery('(max-width:1225px)');
  const maxWidth845px = useMediaQuery('(max-width:845px)');
  const maxWidth500px = useMediaQuery('(max-width:500px)');

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleDebounceMonths = useCallback(debounce(setMonths, 500), []);

  const handleAddMonths = (month) => {
    if (initialMonths.includes(month) && initialMonths.length > 1) {
      const res = initialMonths.filter((item) => item !== month);
      setInitialMonths(res);
      handleDebounceMonths(res);
    } else {
      const res = [...initialMonths, month];
      setInitialMonths(res);
      handleDebounceMonths(res);
    }
  };

  const handleAllMonths = () => {
    if (initialMonths.length !== 12 ) {
      setInitialMonths(MONTH_SHORT);
      setMonths(MONTH_SHORT);
    } else {
      setInitialMonths([moment().format('MMM')]);
      setMonths([moment().format('MMM')]);
    }
  };

  const handleSetYear = (item) => {
    setYear(item);
    if (+item === Math.min(...years)) {
      setYears((prevValue) => prevValue.map(value => `${+value - 1}`));
    }
    if (+item === Math.max(...years) && +item < MAX_YEAR) {
      setYears((prevValue) => prevValue.map(value => `${+value + 1}`));
    }
  };

  return (
    <Box
      sx={{
        ...ButtonContainer,
        ...(maxWidth1040px && ButtonContainerX),
        ...(maxWidth500px && ButtonContainerXs),
        pb: 5,
        ...(sx)
      }}
    >
      <Box>
        {disabledSendRoster && (
          <Button
            disabled={!hasPermissions}
            sx={{ ...DefaultBtn, ...BtnSend }}
            onClick={handleOpen}
          >
            <EmailIcon />
            SEND ROSTER
          </Button>
        )}
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          sx={{ ...BtnGroup, ...(maxWidth500px && BtnGroupXs) }}
        >
          {years.map((item, id) => (
            <Button
              onClick={() => handleSetYear(item)}
              sx={{
                ...DefaultBtn,
                ...(maxWidth500px && DefaultBtnXs),
                ...(year.includes(item) && BtnActive)
              }}
              key={id}
            >
              {item}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        sx={{
          ...BtnGroup,
          ...(maxWidth845px && BtnGroupX),
          ...(maxWidth500px && BtnGroupXs)
        }}
      >
        <Button
          sx={{
            ...DefaultBtnMounth,
            ...(maxWidth845px && DefaultBtnMounthX),
          }}
          onClick={handleAllMonths}
        >
          ALL MONTHS
        </Button>
        {MONTH_SHORT.map((month, id) => (
          <Button
            onClick={() => {
              if (initialMonths.length === 1) {
                if (initialMonths[0] !== month) handleAddMonths(month);
              } else {
                handleAddMonths(month);
              }
            }}
            sx={{
              ...DefaultBtn,
              ...(maxWidth845px && DefaultBtnX),
              ...(maxWidth500px && DefaultBtnXs),
              ...(initialMonths.includes(month) && BtnActive)
            }}
            key={id}
          >
            {month}
          </Button>
        ))}
      </ButtonGroup>
      <SendRoster onClose={handleClose} open={openDrawer} options={options} year={year} months={initialMonths} acftType={acftType} />
    </Box>
  );
};

export default ButtonSelect;

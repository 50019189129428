import moment from 'moment';
import { useEffect, useState } from 'react';
import { useGetCrewNamesQuery, useCrewFlightTimeMutation } from '../../store/session';

const defaultOptions = [{ value: 0, label: 'All' }];

export const useDutyFlightTime = ({ setMonths, setData }) => {
  const [post, setPost] = useState(0);
  const [name, setName] = useState(0);
  const [namesOption, setNamesOption] = useState(defaultOptions);
  const [dateFromValue, setDateFromValue] = useState(null);
  const [dateToValue, setDateToValue] = useState(null);

  const { data: crewNames } = useGetCrewNamesQuery(post);
  const [crewFlightTime] = useCrewFlightTimeMutation();

  const onChangePost = (event) => {
    setPost(event.target.value);
    if (event.target.value === 0) {
      setNamesOption(defaultOptions);
      setName(event.target.value);
    }
  };

  const onChangeName = (event, item) => {
    setName(item.value);
  };

  const crewNamesOption = crewNames?.map((item) => ({
    label: item.crew_name,
    value: item.id
  }));

  const dateFrom = (value) => {
    setDateFromValue(moment(value).format('YYYY-MM-DD'));
    if (!dateToValue) {
      setDateToValue(moment().format('YYYY-MM-DD'));
    }
  };

  const dateTo = (value) => {
    setDateToValue(moment(value).format('YYYY-MM-DD'));
  };

  const submitRequest = async () => {
    const formData = new FormData();
    formData.append('crew_post', post);
    formData.append('crew_id', name);
    formData.append('from', dateFromValue);
    formData.append('to', dateToValue);
    const res = await crewFlightTime({
      crew_post: post,
      crew_id: name,
      from: dateFromValue,
      to: dateToValue,
    });
    const months = [];
    Object.keys(res?.data || {}).forEach(month => {
      const date = moment(month).startOf('month');
      const formattedDate = date.format('YYYY-MM-DD');
      if (!months.includes(formattedDate)) months.push(formattedDate);
    });

    setMonths(months);
    setData(res?.data || {});
  };

  useEffect(() => {
    setNamesOption([...defaultOptions, ...crewNamesOption || []]);
  }, [crewNames]);

  return {
    namesOption,
    dateFromValue,
    dateToValue,
    name,
    dateFrom,
    dateTo,
    onChangePost,
    onChangeName,
    submitRequest,
  };
};

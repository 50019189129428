import { object, string } from 'yup';

export const defaultValues = {
  transaction_id: '',
};

export const schema = object().shape({
  day_duty_quantity: string()
    .nullable()
    .label('Transaction ID'),
});

import React from 'react';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { FormProvider } from 'react-hook-form';
import TabPanel from './TabPanel';
import Documentation from './Documentation';
import Settings from './Settings';
import Upload from '../../components/Upload';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import ImageContainer from './ImageContainer';
import Signatures from './Signatures';
import ContentPageLoader from '../../components/Loader/ContentPageLoader';
import General from './General';
import { useCrewForm } from './useCrewForm';
import {
  cancelSx,
  cardContainerSx,
  footerContainer,
  tabsContainer,
  tabContainer,
  tabsSx,
  tabPanelContainer,
  uploadPhoto,
  uploadContainer,
  tabContainerSx,
  tabsContainerSx,
  TabBlocksSx,
  tabContainerSmall,
  uploadContainerSmall,
  TabBlocksSmall,
  tabsSmall,
  uploadPhotoSmall,
  footerContainerSmall,
} from './styles';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const SabCrewRoster = () => {
  const navigate = useNavigate();

  const {
    tab,
    methods,
    data,
    isLoading,
    maxWidth768px,
    maxWidth590px,
    handleChange,
    crewPhoto,
    isCrewLoading,
    onSubmit,
    handleSubmit,
    uploadFile,
    refetch,
    userList
  } = useCrewForm();

  if (isLoading) {
    return <ContentPageLoader />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card sx={cardContainerSx}>
          <h1>
            {data?.crew_name}
            {' '}
            PROFILE
          </h1>

          <Box sx={{ ...tabsContainer, ...(maxWidth768px && tabsContainerSx) }}>
            <Box sx={{
              ...tabContainer,
              ...(maxWidth768px && tabContainerSx),
              ...(maxWidth590px && tabContainerSmall)
            }}
            >
              <Box sx={{
                ...(maxWidth768px && uploadContainer),
                ...(maxWidth590px && uploadContainerSmall)
              }}
              >
                <ImageContainer img={crewPhoto} />
                <Upload handleUpload={uploadFile} title="Upload photo" sx={{ ...uploadPhoto, ...(maxWidth590px && uploadPhotoSmall) }} />
              </Box>
              <Box sx={{ ...(maxWidth768px && TabBlocksSx), ...(maxWidth590px && TabBlocksSmall) }}>
                <Tabs
                  orientation="vertical"
                  value={tab}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ ...tabsSx, ...(maxWidth590px && tabsSmall) }}
                >
                  <Tab label="General" {...a11yProps(0)} />
                  <Tab label="Documentation" {...a11yProps(1)} />
                  <Tab label="Settings" {...a11yProps(2)} />
                  <Tab label="Signatures" {...a11yProps(3)} />
                </Tabs>

                <Box sx={{ ...footerContainer, ...(maxWidth590px && footerContainerSmall) }}>
                  <Button disabled={isCrewLoading} variant="contained" type="submit" title="Save" />
                  <Button
                    title="Cancel"
                    sx={cancelSx}
                    onClick={() => {
                      navigate('/directories/crew');
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box sx={tabPanelContainer}>
              <TabPanel value={tab} index={0}>
                <General post={data?.crew_post} userList={userList} setValue={methods.setValue} data={data} />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Documentation refetch={refetch} />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <Settings />
              </TabPanel>
              <TabPanel sx={{ overflow: 'auto' }} value={tab} index={3}>
                <Signatures />
              </TabPanel>
            </Box>
          </Box>
        </Card>
      </form>
    </FormProvider>
  ); };

export default SabCrewRoster;

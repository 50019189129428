import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import Table from './Table';

const FuelForm = () => (
  <Card sx={{ mt: 3 }}>
    <CardHeader title="Fuel" />
    <CardContent sx={{ mt: 5 }}>
      <Table />
    </CardContent>
  </Card>
);

export default FuelForm;

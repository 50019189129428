import {
  object, string,
} from 'yup';

export const schema = object().shape({
  maint_id: object({
    label: string().required('Task cannot be blank.'),
    value: string().required('Task cannot be blank.')
  })
    .nullable()
    .required('${label} cannot be blank.')
    .label('Task'),
});

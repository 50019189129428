import { useState } from 'react';
import { useGetFlightsScheduleQuery } from '../../store/session';
import { stringifyLocationFiltration } from '../../utils/locationSearch';
import { DEFAULT_MONTH, DEFAULT_YEAR, MONTH_SHORT } from './constants';

export const useMainDeckSchedule = () => {
  const [year, setYear] = useState(DEFAULT_YEAR);
  const [months, setMonths] = useState(DEFAULT_MONTH);

  const numberMonths = MONTH_SHORT.reduce((total, amount, index) => {
    const monthIndex = [...total, months.includes(amount) && index + 1];
    const res = monthIndex.filter((e) => e);

    return res;
  }, []);

  const { data } = useGetFlightsScheduleQuery(
    stringifyLocationFiltration({
      year,
      months: numberMonths,
    }),
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return {
    year,
    months,
    data,
    setYear,
    setMonths,
  };
};

import { yupResolver } from '@hookform/resolvers/yup';
import HelpIcon from '@mui/icons-material/Help';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Tab,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import {
  AirplaneClock,
  CodeNotEqual,
  Fuel,
  ToolboxOutline,
} from 'mdi-material-ui';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import ApproveModal from '../../../components/ApproveModal';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import { FLIGHT_CARGO, FLIGHT_TECH } from '../../../constans/constants';
import { CARGO, TECH } from '../../../constans/flight';
import {
  useGetAircraftListQuery,
  useGetAirportListQuery,
  useGetCargoListQuery,
  useGetCompaniesListQuery,
  useGetCrewListQuery,
  useGetFdpMutation,
  useGetFlightMutation,
  useStoreFlightMutation,
  useUpdateFlightMutation,
} from '../../../store/session';
import { getTimeDiffHm } from '../../../utils/getTime';
import { useHandleQueryFormError } from '../../../utils/hooks/useHandleQueryFormError';
import { useMenuToggle } from '../../../utils/hooks/useMenuToggle';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import ActionDropDown from './components/ActionDropDown';
import CharterDrawer from './components/CharterDrawer';
import CloseForm from './components/CloseForm';
import ContractInvoiceDrawer from './components/ContractInvoiceDrawer';
import GenerateDropDown from './components/GenerateDropDown';
import FdpCard from './components/card/FdpCard';
import AircraftForm from './components/form/AircraftForm';
import CargoTypeForm from './components/form/CargoTypeForm';
import ClientForm from './components/form/ClientForm';
import FlightNotesForm from './components/form/FlightNotesForm';
import FuelForm from './components/form/FuelForm/FuelForm';
import HopsForm from './components/form/HopsForm';
import PermitForm from './components/form/PermitsForm';
import ServicesForm from './components/form/ServicesForm';
import StatusForm from './components/form/StatusForm';
import FlightFormHeader from './components/form/header/FlightFormHeader';
import { schema } from './components/form/schema';
import {
  flightBtnContainer, flightBtnContainerS,
  flightTabContainer, flightTabIcon,
  flightTabIconWarning,
  updateBtn
} from './styles';

const defaultValues = {
  planing_type: 1,
  planing_reg: '',
  planing_cln_name: '',
  planing_cln_person: '',
  planing_cln_phone: '',
  planing_cln_price: '',
  planing_crg_type: 1,
  planing_crg_dimenssions: '',
  planing_crg_weight: 0,
  planing_awb: '',
  planing_crg_reference: '',
  planing_notes: '',
  planing_permission_needed: 0,
  planing_consignor: '',
  planing_consignee: '',
  planing_hop: [
    {
      pl_hop_id: '',
      pl_hop_empty: 1,
      pl_hop_prefix: '',
      pl_hop_from: '',
      pl_hop_from_id: '',
      pl_hop_departure: null,
      pl_hop_arriving: null,
      pl_hop_to: '',
      pl_hop_to_id: '',
      pl_hop_capitane: '',
      pl_hop_fo: '',
      pl_hop_engineer: '',
      pl_hop_blockoff: null,
      pl_hop_blockon: null,
      pl_hop_ods_id: null,
      pl_hop_ods_index: null,
    },
  ],
  planing_other_invoices: [],
};

const FlightForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isDataHasChanged, setIsDataHasChanged] = useState(false);
  const [isServiceHasAdded, setIsServiceHasAdded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isOpenContractDrawer, setOpenContractDrawer] = useState(false);
  const [isOpenCharterDrawer, setOpenCharterDrawer] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [servicesIsLoading, setServicesIsLoading] = useState(false);
  const [tab, setTab] = useState('1');
  const [type, setType] = useState(id === CARGO ? FLIGHT_CARGO : null);
  const [modalState, setModalState] = useState({
    isOpen: false,
    modalText: '',
    modalData: null,
    callback: () => {}
  });

  const hasPermissions = usePermissions('flights_access');
  const hasPermissionsOdsCreate = usePermissions('ods_creator');
  const handleError = useHandleQueryFormError();

  const update = ![CARGO, TECH].includes(id);
  const [getFlight, flightData] = useGetFlightMutation({
    fixedCacheKey: 'get-flight-data',
  });
  const [storeFlight] = useStoreFlightMutation();
  const [updateFlight] = useUpdateFlightMutation();
  const [fdp, { data: fdpResult }] = useGetFdpMutation({
    fixedCacheKey: 'get-fdp-data',
  });
  const { data: AircraftList, isLoading: aircraftListLoad } = useGetAircraftListQuery();
  const { data: CompaniesList, isLoading: companiesListLoad } = useGetCompaniesListQuery();
  const { data: cargoList, isLoading: cargoListLoad } = useGetCargoListQuery();
  const { data: AirportList, isLoading: airportListLoad } = useGetAirportListQuery();
  const { data: CrewList, isLoading: crewListLoad } = useGetCrewListQuery(stringifyLocationSearch({
    post: '1,2'
  }));
  const { data: CrewListEng, isLoading: crewListEngLoad } = useGetCrewListQuery(stringifyLocationSearch({
    post: 3
  }));

  const {
    open, openMenu, closeMenu, anchorEl,
  } = useMenuToggle();
  const {
    open: openAction, openMenu: openMenuAction, closeMenu: closeMenuAction, anchorEl: anchorElAction,
  } = useMenuToggle();

  const {
    handleSubmit,
    setValue,
    setError,
    getValues,
    control,
    watch,
    resetField,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  const {
    fields: hopFields,
    append: hopAppend,
    remove: hopRemove,
    replace: hopUpdate,
  } = useFieldArray({
    name: 'planing_hop',
    control,
  });

  const hopRepeaterProps = {
    hopFields,
    hopAppend,
    hopRemove,
    hopUpdate,
  };

  const formProps = {
    defaultValues,
    trigger,
    setValue,
    resetField,
    watch,
    update,
    getValues,
    control,
    Controller,
    errors,
  };

  const maxWidth650px = useMediaQuery('(max-width:650px)');

  const handleSetModalState = (data) => {
    setModalState({
      isOpen: true,
      modalText: data?.text,
      modalData: data?.data || null,
      callback: data?.callback
    });
  };

  const handleCloseModal = () => {
    setModalState({
      isOpen: false,
      modalText: '',
      modalData: null,
      callback: () => {}
    });
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleOpenContractDrawer = () => {
    setOpenContractDrawer(true);
  };

  const handleCloseContractDrawer = () => {
    setOpenContractDrawer(false);
  };

  const handleOpenCharterDrawer = () => {
    setOpenCharterDrawer(true);
  };

  const handleCloseCharterDrawer = () => {
    setOpenCharterDrawer(false);
  };

  const handleSetService = (data, index) => {
    setValue(`planing_services.${index}.services.${0}`, {
      pl_serv_airport: data.pl_hop_to_id,
      pl_serv_description: '',
      pl_serv_fleet_id: data.pl_hop_fleet_id,
      pl_serv_hop_id: data.pl_hop_id,
      pl_serv_mail: '',
      pl_serv_payment_method: '',
      pl_serv_real_price: '',
      pl_serv_service: '',
      pl_serv_status: '',
      pl_serv_supplier: '',
    });
  };

  const handleSetSupplier = (data) => {
    const fuel = watch('planing_fuel');

    setValue(`planing_fuel.${fuel?.length || 0}`, {
      plfuel_amount: null,
      plfuel_ap_id: data.pl_hop_from_id,
      plfuel_fleet_id: data.pl_hop_fleet_id,
      plfuel_fuel_needed: '',
      plfuel_fullcost: null,
      plfuel_hop_id: data.pl_hop_id,
      plfuel_invoice_num: null,
      plfuel_mail: null,
      plfuel_payment_date: null,
      plfuel_payment_method: null,
      plfuel_price: '',
      plfuel_real_amount: 0,
      plfuel_real_fullcost: 0,
      plfuel_status: null,
      plfuel_suplier: null,
    });
  };

  const handleUpdateValues = (result) => {
    setType(result.planing_type);
    Object.entries(result).forEach(
      ([name, value]) => {
        if ((result.planing_type === 3) && (name === 'planing_cln_price')) {
          setValue(name, value || '');
        } else if (['planing_permit', 'planing_services', 'planing_fuel', 'additionalInvoices'].includes(name)) {
          setValue(name, value.map((item) => ({ ...item, itemId: item.id })));
          if (name === 'planing_services') {
            result.planing_services.forEach((service, index) => {
              if (!service.services.length) {
                const hop = result.planing_hop.find(item => item.pl_hop_id === service.planning_hop_id);

                handleSetService({ ...hop, pl_hop_to_id: index === 0 ? hop.pl_hop_from_id : hop.pl_hop_to_id }, index);
              }
            });
          } else if (name === 'planing_fuel') {
            const hopIds = result.planing_fuel.map(fuel => fuel.plfuel_hop_id); ;

            result.planing_hop.forEach((hop, i) => {
              if (!hopIds.includes(hop.pl_hop_id)) {
                handleSetSupplier(hop);
              }
            });
          }
        } else if (name === 'planing_hop' && result.planing_status) {
          const hopData = value?.map((i, index) => {
            const blockOff = Number.isInteger(i.pl_hop_blockoff) ? new Date(i.pl_hop_blockoff).getTime() * 1000 : i.pl_hop_blockoff;
            const blockOn = Number.isInteger(i.pl_hop_blockon) ? new Date(i.pl_hop_blockon).getTime() * 1000 : i.pl_hop_blockon;

            const takeOff = Number.isInteger(i.pl_hop_takeoff) ? new Date(i.pl_hop_takeoff).getTime() * 1000 : i.pl_hop_takeoff;
            const takeOn = Number.isInteger(i.pl_hop_takeon) ? new Date(i.pl_hop_takeon).getTime() * 1000 : i.pl_hop_takeon;

            return {
              ...i,
              pl_hop_blocktime: getTimeDiffHm(blockOff, blockOn),
              pl_hop_taketime: getTimeDiffHm(takeOff, takeOn)
            };
          });

          setValue(name, hopData);
        } else {
          setValue(name, value);
        }
      }
    );
  };

  const handleFlightData = async () => {
    setLoading(true);
    const { data: result } = await getFlight({ id });

    if (result) {
      handleUpdateValues(result);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (update) {
      handleFlightData();
    }
  }, [update]);

  if (isLoading || aircraftListLoad || companiesListLoad || cargoListLoad || crewListLoad || airportListLoad || crewListEngLoad) {
    return <ContentPageLoader />;
  }

  const onSubmit = async (data) => {
    const toastId = toast.loading('Flight update in progress...');
    setUpdating(true);
    Object.entries(data.planing_hop).forEach(([index, hop]) => {
      data.planing_hop[index].pl_hop_departure = moment(hop.pl_hop_departure).format('YYYY-MM-DD HH:mm');
      data.planing_hop[index].pl_hop_arriving = moment(hop.pl_hop_arriving).format('YYYY-MM-DD HH:mm');
    });

    data.planing_services?.forEach((service, index) => {
      service.services?.forEach((serviceData, subIndex) => {
        const { itemId, ...serviceValues } = data.planing_services[index].services[subIndex];
        let curentService = serviceData;

        if (typeof serviceData.id === 'string') curentService = { ...serviceValues, id: itemId };
        if (Object.keys(curentService).includes('itemId')) delete curentService.itemId;

        data.planing_services[index].services[subIndex] = curentService;
      });
    });

    data?.planing_permit?.forEach((permit, index) => {
      if (typeof permit.id === 'string') delete data?.planing_permit[index].id;
      if (typeof permit.itemId === 'number') {
        data.planing_permit[index].id = permit.itemId;
      }
      delete data.planing_permit[index].itemId;
      permit.pl_perm_hop?.forEach((perHop, subIndex) => {
        if (typeof perHop.id === 'string') delete data.planing_permit[index].pl_perm_hop[subIndex].id;
      });
    });

    data?.actualValServices?.forEach((item, index) => {
      if (item.itemId) {
        delete data.actualValServices[index].itemId;
      }
    });

    data?.additionalInvoices?.forEach((item, index) => {
      if (typeof item.id === 'string') delete data?.additionalInvoices[index].id;
      if (typeof item.itemId === 'number') {
        data.additionalInvoices[index].id = item.itemId;
      }

      delete data.additionalInvoices[index].itemId;

      item.invoice_data?.forEach((perHop, subIndex) => {
        if (typeof perHop.id === 'string') delete data.additionalInvoices[index].invoice_data[subIndex].id;
        if (perHop.description === '') delete data.additionalInvoices[index].invoice_data[subIndex];
        if (perHop.price === '') delete data.additionalInvoices[index].invoice_data[subIndex];
      });
    });

    data.planing_fuel?.forEach((fuel, index) => {
      const { itemId, ...fuelValues } = data.planing_fuel[index];
      let currentFuel = fuelValues;

      if (typeof fuel.id === 'string') currentFuel = { ...fuelValues, id: itemId };
      if (Object.keys(currentFuel).includes('itemId')) delete currentFuel.itemId;

      data.planing_fuel[index] = currentFuel;
    });

    if (!update) {
      const res = await storeFlight({ ...data, planing_type: type === FLIGHT_CARGO ? FLIGHT_CARGO : FLIGHT_TECH });

      if (res?.error) {
        setUpdating(false);
        handleError(res.error, setError, getValues, toastId);

        return;
      }

      setUpdating(false);
      toast.success('Flight successfully created!', {
        id: toastId
      });

      navigate(`/flights/${res.data.id}`);
    } else {
      const res = await updateFlight({ data, id });

      if (res?.error) {
        setUpdating(false);
        handleError(res.error, setError, getValues, toastId);

        return;
      }

      await handleFlightData();
      setUpdating(false);
      toast.success('Fight successfully updated!', {
        id: toastId
      });
      setIsDataHasChanged(false);
      setIsServiceHasAdded(false);
    }
  };

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4} rowSpacing={2}>
          <Grid item xs={12}>
            <FlightFormHeader update={update} getValues={getValues} type={type} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <StatusForm handleUpdateValues={handleUpdateValues} setLoading={setLoading} getFlight={getFlight} update={update} getValues={getValues} setValue={setValue} trigger={trigger} handleChangeTab={handleChangeTab} />
            <AircraftForm {...formProps} AircraftList={AircraftList} />
            <ClientForm {...formProps} CompaniesList={CompaniesList} type={type} />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ flexGrow: 1 }}>
            {type === FLIGHT_CARGO ? (
              <>
                <CargoTypeForm {...formProps} cargoList={cargoList} />
                <FlightNotesForm {...formProps} />
                <FdpCard {...formProps} hopFields={hopFields} />
              </>
            ) : (
              <Grid item xs={12}>
                <FlightNotesForm {...formProps} />
              </Grid>
            )}
          </Grid>

          {getValues('planing_status') ? (
            <CloseForm {...formProps} flightData={flightData} handleFlightData={handleFlightData} />
          ) : (
            <Grid item xs={12}>
              <Divider />
              {fdpResult?.some(i => i.currentFdp > i.maxFdp) && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity="error">CREW REST NEEDED!</Alert>
                </Stack>
              )}
              <Card sx={{ mt: '1.25rem' }}>
                <TabContext value={tab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="tabs" xs={6} sx={{ padding: '0rem' }}>
                      <Tab
                        value="1"
                        label={(
                          <Box sx={flightTabContainer}>
                            Flights
                            {isServiceHasAdded && !isDataHasChanged && (
                              <Tooltip title="The Data in the service and fuel tab are incorrect. Please update flight before go to Services or Fuel">
                                <HelpIcon sx={flightTabIconWarning} />
                              </Tooltip>
                            )}
                            {isDataHasChanged && (
                              <Tooltip title="Please update flight before go to Services or Fuel">
                                <HelpIcon sx={flightTabIcon} />
                              </Tooltip>
                            )}
                          </Box>
                        )}
                        iconPosition="start"
                        icon={<AirplaneClock />}
                      />
                      <Tab value="2" label="Services" iconPosition="start" icon={<ToolboxOutline />} disabled={!update || isDataHasChanged} />
                      <Tab value="3" label="Fuel" iconPosition="start" icon={<Fuel />} disabled={!update || isDataHasChanged} />
                      <Tab
                        value="4"
                        label="Permits"
                        iconPosition="start"
                        icon={<CodeNotEqual />}
                        disabled={!watch('planing_permission_needed') || isDataHasChanged}
                      />
                    </TabList>
                  </Box>
                  <CardContent sx={{ padding: '0rem', overflowX: 'scroll', overflowY: 'hidden' }}>
                    <TabPanel value="1" sx={{ padding: '0rem' }}>
                      <HopsForm
                        {...formProps}
                        {...hopRepeaterProps}
                        setIsDataHasChanged={setIsDataHasChanged}
                        setIsServiceHasAdded={setIsServiceHasAdded}
                        handleSetModalState={handleSetModalState}
                        AircraftList={AircraftList}
                        AirportList={AirportList}
                        CrewList={CrewList}
                        CrewListEng={CrewListEng}
                        update={update}
                        hasPermissionsOdsCreate={hasPermissionsOdsCreate}
                      />
                    </TabPanel>
                    <TabPanel sx={{ minWidth: '1200px', }} value="2">
                      <Box sx={{ minHeight: '6px' }}>
                        {servicesIsLoading && (
                          <LinearProgress />
                        )}
                      </Box>
                      <ServicesForm {...formProps} setServicesIsLoading={setServicesIsLoading} />
                    </TabPanel>
                    <TabPanel value="3">
                      <FuelForm {...formProps} getValues={getValues} setValue={setValue} handleFlightData={handleFlightData} />
                    </TabPanel>
                    <TabPanel value="4">
                      <PermitForm {...formProps} getValues={getValues} />
                    </TabPanel>
                  </CardContent>
                </TabContext>
              </Card>
              <Divider />
            </Grid>
          )}
          <Grid item xs={12} sx={{ ...flightBtnContainer, ...(maxWidth650px && flightBtnContainerS) }}>
            {hasPermissions
              && <Button type="submit" variant="contained" disabled={updating} size="large">{update ? 'Update' : 'Save' }</Button>}
            {update && (
              <>
                <Button sx={updateBtn} size="large" onClick={openMenu}>
                  Generate
                </Button>
                <Button sx={updateBtn} size="large" onClick={handleOpenContractDrawer}>
                  Contract & Invoice
                </Button>
                  {(type === FLIGHT_CARGO) && (
                  <Button sx={updateBtn} size="large" onClick={handleOpenCharterDrawer}>
                    Charter Confirmation
                  </Button>
                  )}
                <Button sx={updateBtn} size="large" onClick={openMenuAction}>
                  Action
                </Button>

                <GenerateDropDown
                  handleFlightData={handleFlightData}
                  hasPermissionsOdsCreate={hasPermissionsOdsCreate}
                  handleSetModalState={handleSetModalState}
                  open={open}
                  onClose={closeMenu}
                  anchorEl={anchorEl}
                  id={id}
                />

                <ActionDropDown
                  open={openAction}
                  onClose={closeMenuAction}
                  anchorEl={anchorElAction}
                />

                <ContractInvoiceDrawer
                  open={isOpenContractDrawer}
                  onClose={handleCloseContractDrawer}
                  flightData={flightData?.data}
                  flightId={id}
                />
                <CharterDrawer
                  open={isOpenCharterDrawer}
                  onClose={handleCloseCharterDrawer}
                  flightData={flightData?.data}
                />
              </>
            )}
            <ApproveModal
              open={modalState.isOpen}
              data={{
                text: modalState.modalText,
                successButton: 'Yes, I am sure',
                cancelButton: 'Cancel',
                changeStyle: true
              }}
              onClose={handleCloseModal}
              onApprove={() => {
                modalState.callback();
                handleCloseModal();
              }}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default FlightForm;

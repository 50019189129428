
import {
  object, string,
} from 'yup';

export const defaultValues = {
  crew_id: '',
  crew_post: '',
  aircraft_id: '',
  ods_status: '',
  position_type: '',
  total_positioning_time: null,
  type: 4,
  start: null,
  finish: null,
};

export const schema = object().shape({
  crew_post: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('POSITION'),
  crew_id: string()
    .required('${label} cannot be blank.')
    .label('PILOT NAME'),
  aircraft_id: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('AIRCRAFT'),
  ods_status: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('STATUS'),
  position_type: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('TYPE OF POSITIONING'),
  type: string()
    .nullable(),
  total_positioning_time: string()
    .nullable(),
  start: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('POSITIONING START'),
  finish: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('POSITIONING FINISH'),
});

import {
  Grid
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import FormGroup from '../../../../../../../form/components/FormGroup';
import Input from '../../../../../../../form/components/Input';

const ManualPartOffForm = (props) => {
  const {
    control,
    errors,
    currentPartIDOff,
    currentPartNameOff,
    currentPartSNOff,
    currentParts
  } = props;

  return (
    <Grid container spacing={6} sx={{ padding: '0px  24px' }}>
      <Grid item xs={4}>
        <FormGroup label="Part Name" required hasError={!!errors?.part_off_name}>
          <Controller
            control={control}
            name="part_off_name"
            render={({ field }) => (
              <Input
                {...field}
                disabled={!!currentPartIDOff || !!currentPartNameOff || !!currentPartSNOff || !!currentParts}
                placeholder="Enter Part Name"
                sx={{ width: '100%' }}
              />
            )}
          />
        </FormGroup>
      </Grid>
      
      <Grid item xs={4}>
        <FormGroup label="Part Number" required hasError={!!errors?.part_off_number}>
          <Controller
            control={control}
            name="part_off_number"
            render={({ field }) => (
              <Input
                {...field}
                disabled={!!currentPartIDOff || !!currentPartNameOff || !!currentPartSNOff || !!currentParts}
                placeholder="Enter Part Number"
                sx={{ width: '100%' }}
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={4}>
        <FormGroup label="Serial Number" required hasError={!!errors?.serial_off_number}>
          <Controller
            control={control}
            name="serial_off_number"
            render={({ field }) => (
              <Input
                {...field}
                disabled={!!currentPartIDOff || !!currentPartNameOff || !!currentPartSNOff || !!currentParts}
                placeholder="Enter Serial Number"
                sx={{ width: '100%' }}
              />
            )}
          />
        </FormGroup>
      </Grid>

    </Grid>
  );
};

export default ManualPartOffForm;
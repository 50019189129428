import React from 'react';
import {
  Box, Card, Divider, Typography, useMediaQuery
} from '@mui/material';
import EventsCalendar from './EventsCalendar/EventsCalendar';
import Button from '../../../components/Button';
import LinkButton from '../../../components/LinkButton';
import Table from './Table/Table';
import { style, styleMobile } from './styles';
import { useGetTrainingAheadTableMutation, useGetProgramsReviewedTableMutation } from '../../../store/session';

const TrainingModule = () => {
  const maxWidth590px = useMediaQuery('(max-width:590px)');

  return (
    <Card sx={{
      mt: 3,
      padding: '1rem',
    }}
    >
      <Box>
        <Box>
          <Typography variant="h3">Flight Crew Training Module</Typography>
        </Box>
        <Box
          sx={{ ...(maxWidth590px ? styleMobile : style) }}
        >
          <LinkButton path="/training-module/flight-crew">
            <Button sx={{ width: '100%' }} title="Flight Crew" />
          </LinkButton>
          <LinkButton path="/training-module/training-programs">
            <Button sx={{ width: '100%' }} title="Training Programs" />
          </LinkButton>
          <LinkButton path="/training-module/training-personnel">
            <Button sx={{ width: '100%' }} title="Training Personnel" />
          </LinkButton>
          <LinkButton path="/training-module/training-schedule">
            <Button sx={{ width: '100%' }} title="Training Planning" />
          </LinkButton>
          <LinkButton path="/training-module/training-reports">
            <Button sx={{ width: '100%' }} title="Training reports" />
          </LinkButton>
        </Box>
      </Box>
      <Divider sx={{ mt: 5, mb: 10 }} />
      <Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textTransform: 'capitalize', mb: 2, fontWeight: 600, display: 'block', margin: '0 auto'
            }}
          >
            Flight Crew Training Events Calendar
          </Typography>
        </Box>
        <EventsCalendar />
      </Box>
      <Divider sx={{ mt: 10, mb: 5 }} />
      <Box>
        <Box>
          <Typography variant="h4">TRAININGS AHEAD</Typography>
        </Box>
        <Table useGetHook={useGetTrainingAheadTableMutation} variant="nested" />
        <Box sx={{ mb: 3 }}>
          <Typography variant="h4">PROGRAMS TO BE REVIEWED</Typography>
        </Box>
        <Table useGetHook={useGetProgramsReviewedTableMutation} />
      </Box>
    </Card>
  ); };
export default TrainingModule;

import { array, object } from 'yup';

export const defaultValues = {
  emails: [],
  invoices: []
};

export const schema = object().shape({
  emails: array()
    .required('${label} cannot be blank')
    .label('Emails'),
  invoices: array()
    .required('${label} cannot be blank')
    .label('Invoices'),
});
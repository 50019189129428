import moment from 'moment';

export const DEFAULT_YEAR = moment().format('YYYY');
export const DEFAULT_MONTH = [moment().format('MMM')];
export const MONTH_SHORT = moment.monthsShort();
export const TITLE = 'FlEETAIR FLIGHTS SCHEDULE';
export const YEARS = [
  moment().subtract(1, 'years').format('YYYY'),
  moment().format('YYYY'),
  moment().add(1, 'years').format('YYYY'),
];

import React from 'react';
import Box from '@mui/material/Box';
import ModalBase from '@mui/material/Modal';
import {
  closeIcon, headerSx, modalSx, titleSx, cancel, blockBtn, text,
} from './styles';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '../Button';

const DeleteModalConfirmation = ({
  open, onClose, data, onDelete, refetch,
}) => {
  const handleDelete = () => {
    onDelete({ data: data.data, id: data.id, refetch });
  };

  return (
    <ModalBase
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalSx}>
        <Box sx={headerSx}>
          <Typography sx={titleSx}>
            Delete
          </Typography>
          <CloseIcon onClick={onClose} sx={closeIcon} />
        </Box>
        <Box sx={text}>
          {data?.text}
        </Box>
        <Box sx={blockBtn}>
          <Button onClick={handleDelete} title={`${data?.successButton || 'Delete'}`} />
          <Button onClick={onClose} sx={cancel} title={`${data?.cancelButton || 'Cancel'}`} />
        </Box>
      </Box>
    </ModalBase>
  ); };

export default DeleteModalConfirmation;

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import React from 'react';
import ButtonSelect from '../../../components/ButtonSelect';
import { FtlTable } from './FtlTable';
import { columns } from './columns';
import { cardContainerSx } from './styles';
import { useFlightTimeLimitations } from './useFlightTimeLimitations';

const MAX_DATA_ROWS = 36;

const FlightTimeLimitations = () => {
  const {
    year,
    months,
    crew,
    list,
    isLoading,
    getWrongAvail,
    isPdf,
    handlePrint,
    handleLogBook,
    handleDoc,
    setYear,
    setMonths,
  } = useFlightTimeLimitations();

  return (
    <Card sx={cardContainerSx}>
      {!isPdf && (
        <>
          <h1>
            FLIGHT TIME LIMITATIONS
            {' '}
            {crew?.crew_name}
          </h1>

          <ButtonSelect
            year={year}
            months={months}
            setYear={setYear}
            setMonths={setMonths}
            disabledSendRoster={false}
          />

          <Box display="flex" gap="10px" sx={{ justifyContent: 'end' }}>
            <Button onClick={handlePrint} variant="contained">{`GENERATE ${crew?.crew_name} PDF`}</Button>
            <Button onClick={handleDoc} variant="contained">{`GENERATE ${crew?.crew_name} EXCEL`}</Button>
            <Button onClick={handleLogBook} variant="contained">{`GENERATE ${crew?.crew_name} LOGBOOK`}</Button>
          </Box>
        </>
      )}
      {isPdf ? (
        Array.from({ length: Math.ceil(list.length / MAX_DATA_ROWS) }).map((_, i) => (
          <FtlTable
            crewName={crew?.crew_name}
            list={list.slice((MAX_DATA_ROWS * i), (MAX_DATA_ROWS * i) + MAX_DATA_ROWS)}
            columns={columns({ isPdf })}
            isLoading={isLoading}
            getWrongAvail={getWrongAvail}
            isPdf={isPdf}
          />
        ))
      ) : (
        <FtlTable
          list={list}
          columns={columns({ isPdf })}
          getWrongAvail={getWrongAvail}
          isLoading={isLoading}
          isPdf={isPdf}
        />
      )}
    </Card>
  ); };

export default FlightTimeLimitations;

export const cellSx = {
  p: '0 !important'
};

export const dateCell = {
  width: '60px',
  minWidth: '60px',
  maxWidth: '60px',
};

export const dataCell = {
  width: '200px',
  minWidth: '200px',
  maxWidth: '200px',
  p: '2px 4px !important',
  verticalAlign: 'top',
  borderRight: '1px solid rgba(76, 78, 100, 0.12)',
};

export const TableContainerSX = {
  height: 'calc(100vh - 100px)',
};

export const tableSx = {
  border: '1.5px solid #F2F2FF',
  background: 'white'
};

export const boldBorderLeft = {
  borderLeft: '3px solid #696969',
};

export const boldBorderRight = {
  borderRight: '3px solid #696969'
};

export const borderRight = {
  borderRight: '1px solid rgba(76, 78, 100, 0.12)',
};

export const borderLeft = {
  borderLeft: '1px solid rgba(76, 78, 100, 0.12)'
};
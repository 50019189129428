import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import ButtonIcon from '../../../../components/ButtonIcon';
import FiltrationDate from '../../../../components/EditableTable/FiltrationDate';
import FiltrationInput from '../../../../components/EditableTable/FiltrationInput';
import FiltrationSelect from '../../../../components/EditableTable/FiltrationSelect';
import TextEdit from '../../../../components/EditableTable/TextEdit';
import { TYPE_INPUT } from '../../../../components/EditableTable/constants';
import {
  HeaderName, StyledPencil,
} from '../../../../components/EditableTable/styles';
import { CONFIRM_DELETE, CONFIRM_DELETE_CANCEL } from '../../../../constans/deleteConfirmation';
import { OPTIONS_STATE } from '../../../../constans/workOrder';
import {actionStyles} from "../styles";
import LinkButton from "../../../../components/LinkButton";

export const columns = ({
  handleFilter,
  filters,
  handleOpenDeleteModal,
  deletedWOPermission,
  handleOpen,
  refetch
}) => ([
  {
    flex: 0.1,
    field: 'wo_tasks_count',
    minWidth: 75,
    headerName: 'Task Count',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Tasks</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.wo_tasks_count}</Typography>
    ),
  },
  {
    flex: 0.1,
    maxWidth: 60,
    field: 'edit',
    headerName: '',
    sortable: false,
    renderCell: ({ row }) => (
      <Box sx={actionStyles}>
        <LinkButton target="_blank" path={`/maintenance/work-order/update/${row.id}`}>
          <StyledPencil />
        </LinkButton>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'name',
    minWidth: 250,
    headerName: 'Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Name</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.name || ''}
          handleFilter={handleFilter}
          placeholder="Name"
          value="name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'name', row)}
        text={row.name}
        labelInput="Name"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'designation',
    minWidth: 180,
    headerName: 'Designation',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Designation</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.designation || ''}
          handleFilter={handleFilter}
          placeholder="Designation"
          value="designation"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'designation', row)}
        text={row.designation}
        labelInput="Designation"
        type={TYPE_INPUT}
      />
    ),
  },
  {
    flex: 0.2,
    field: 'wo_date',
    minWidth: 180,
    headerName: 'WO Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>WO Date</Typography>
        <FiltrationDate
          onChange={(e) => handleFilter(e, 'wo_date', true)}
          value={filters.wo_date || null}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.wo_date}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'due_date',
    minWidth: 180,
    headerName: 'Due Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Due Date</Typography>
        <FiltrationDate
          onChange={(e) => handleFilter(e, 'due_date', true)}
          value={filters.due_date || null}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.due_date}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'state',
    minWidth: 180,
    headerName: 'State',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>State</Typography>
        <FiltrationSelect
          options={OPTIONS_STATE}
          defaultValue={filters.state || ''}
          handleFilter={handleFilter}
          placeholder="State"
          value="state"
        />
      </Box>
    ),
    renderCell: ({ row }) => {
      const text = OPTIONS_STATE.find(item => (
        +item.value === row.state
      ));

      return (
        <Typography>{text.label}</Typography>
      );
    },
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <ButtonIcon
          sx={{
            '&:hover': {
              color: '#6ddf95 !important'
            },
          }}
          onClick={() => handleOpenDeleteModal({
            id: row.id, confirm: true, text: CONFIRM_DELETE, refetch
          })}
          disabled={!deletedWOPermission}
        >
          <CheckIcon />
        </ButtonIcon>
        {' '}
        <ButtonIcon
          sx={{
            ml: 2,
            '&:hover': {
              color: '#f35d5d !important'
            },
          }}
          onClick={() => handleOpenDeleteModal({
            id: row.id, approve: true, data: row, text: CONFIRM_DELETE_CANCEL, refetch, successButton: 'Yes', cancelButton: 'No'
          })}
          disabled={!deletedWOPermission}
        >
          <CloseIcon />
        </ButtonIcon>
      </>
    ),
  },
]);

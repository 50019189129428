import { Accordion } from '@mui/material';
import MuiListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import { hexToRGBA } from '../../utils/hex-to-rgba';

export const ListItem = styled(MuiListItem)(({ theme }) => ({
  width: 'auto',
  backgroundColor: 'transparent',
  paddingTop: theme.spacing(2.25),
  color: theme.palette.text.primary,
  paddingBottom: theme.spacing(2.25),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.active, &.active:hover': {
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08),
  },
  '&.active .MuiTypography-root, &.active .MuiListItemIcon-root': {
    color: theme.palette.primary.main,
  },
}));

export const AccordionStyled = styled(Accordion)(() => ({
  backgroundColor: 'transparent',
  padding: '0 0 0 0',
  boxShadow: 'none!important',
  borderRadius: '0!important',
}));
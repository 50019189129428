import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { ListStatus } from 'mdi-material-ui';
import React from 'react';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import { HeaderName, StyledDelete } from '../../../components/EditableTable/styles';
import LinkButton from '../../../components/LinkButton/Button';
import { PRIORITY, handleStatusChip } from '../../../constans/logistics';
import { formatDate } from '../../../utils/getTime';
import { StyledPencil } from '../../Flights/list/styles';
import { TextWrap } from '../style';

export const internalColumns = ({
  handleOpenDeleteModal, aircraftDropDownState, setCurrentRowTable, aircraftReservationCreator, handleOpenAllInfo
}) => ([
  {
    flex: 0.1,
    field: 'all_info',
    minWidth: 100,
    headerName: 'Information',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}></Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Button
        title="Info"
        onClick={() => handleOpenAllInfo(row?.id)}
      />
    ),
  },
  {
    flex: 0.1,
    field: 'id',
    minWidth: 120,
    headerName: '#',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>#</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        #
        {row.id}
        D
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'priority',
    minWidth: 120,
    headerName: 'Priority',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>
          <PriorityHighIcon />
        </Typography>
      </Box>
    ),
    renderCell: ({ row }) => PRIORITY.find(i => i.value === row.priority).icon
  },
  {
    flex: 0.1,
    field: 'status',
    minWidth: 120,
    headerName: 'Status',
    align: 'left',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>
          <ListStatus />
        </Typography>
      </Box>
    ),
    renderCell: ({ row }) => handleStatusChip(
      row.status,
      row?.decline_reason,
      row?.ttm_code,
      (row.parts_history_count && row.parts_history_count === row.installed_parts_history_count),
      (row.parts_history_count && row.installed_parts_history_count)
    )
  },
  {
    flex: 0.2,
    field: 'aircraft_reservation',
    minWidth: 180,
    headerName: 'Aircraft Reservation',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Aircraft Reservation</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', cursor: (row?.part_type === 'Return') ? 'no-drop' : 'pointer' }}
        onClick={(event) => {
          event.stopPropagation();
          if (row?.part_type === 'Return') return;
          aircraftDropDownState?.openMenu(event);
          setCurrentRowTable(row);
        }}
        
      >
        <Chip
          label={aircraftReservationCreator ? (
            <Box sx={{ display: 'flex' }}>
              <Typography>{row?.aircraft_reservation ?? 'N/A'}</Typography>
              <KeyboardArrowDownIcon />
            </Box>
          ) : (row?.aircraft_reservation ?? 'N/A')}
          color="secondary"
          variant="outlined"
          disabled={(row?.part_type === 'Return')}
        />
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'part_type',
    minWidth: 180,
    headerName: 'Part Type',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Part Type</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        <Chip label={row?.part_type ?? 'N/A'} color="secondary" variant="outlined" />
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'quantity',
    minWidth: 180,
    headerName: 'Quantity',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Quantity</Typography>
      </Box>
    ),
    renderCell: ({ row }) => {
      const isArr = Array.isArray(row?.quantity);
      let currQuantity = 0;

      if (isArr) {
        row?.quantity.forEach((item) => {
          if (typeof item === 'object') {
            currQuantity += +item?.count;

            return;
          }
          if ((typeof item === 'number') || (typeof item === 'string')) {
            currQuantity += +item;
          }
        });
      }

      return (
        <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
          <Chip label={isArr ? currQuantity : (row?.quantity || 'N/A')} color="secondary" variant="outlined" />
        </Typography>
      );
    },
  },
  {
    flex: 0.2,
    field: 'created_by',
    minWidth: 220,
    headerName: 'Created by',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Created by</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={TextWrap}
      >
        {row?.creator?.name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'created_at',
    minWidth: 240,
    headerName: 'Created at / Update at',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Created at / Update at</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={TextWrap}
      >
        <span>
          Created:
          {' '}
          {formatDate(row?.created_at)}
        </span>
        <br />
        {(row?.updated_at && (row?.updated_at !== row?.created_at)) && (
        <span>
          Updated:
          {' '}
          {formatDate(row?.updated_at)}
        </span>
        )}
      </Typography>
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    // eslint-disable-next-line consistent-return
    renderCell: ({ row }) => (
      <>
        <LinkButton disabled={row?.status !== 0} path={row?.status === 0 ? `/logistics/my-orders/delivery/update/${row.id}` : null}>
          <StyledPencil />
        </LinkButton>
        <ButtonIcon onClick={() => handleOpenDeleteModal(row.id)}>
          <StyledDelete />
        </ButtonIcon>
      </>

    )
  },
]);

import moment from 'moment';

export const getTimeDiffHm = (start, finish) => {
  if (start && finish) {
    let hours = moment(finish).diff(moment(start), 'hour');
    let minutes = Math.round(moment(finish).diff(moment(start), 'minute', true) % 60);

    // !TODO: Recheck in the future best solution
    if (minutes === 60) {
      hours += 1;
      minutes = 0;
    }
    if (hours.toString().length < 2) hours = `0${hours}`;
    if (minutes.toString().length < 2) minutes = `0${minutes}`;

    return `${hours}:${minutes}`;
  }

  return '00:00';
};

export const formatDate = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();
  let hours = `${d.getHours()}`;
  let minutes = `${d.getMinutes()}`;

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  if (hours.length < 2) hours = `0${hours}`;
  if (minutes.length < 2) minutes = `0${minutes}`;

  return `${[year, month, day].join('-')} ${[hours, minutes].join(':')}`;
};

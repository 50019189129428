import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card, Divider,
  Grid
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import FieldError from '../../form/components/FieldError';
import FormGroup from '../../form/components/FormGroup';
import ServerAutocomplete from '../../form/components/ServerAutocomplete';
import {
  useDeleteTransactionFileMutation,
  useOpenTransactionFileMutation,
} from '../../store/session';
import Button from '../Button';
import Drawer from '../Drawer';
import { defaultValues, schema } from './schema';

const TransactionDrawer = ({
  open,
  onClose,
  itemData,
  itemName = '',
  itemsList = [],
  isAttachLoading = false,
  hasPermissions = false,
  uploadFile = () => {},
  isLoadingFiles,
  handleRefresh = () => {},
  deleteFileCallback = () => {},
  currentOpenFile = null,
  hasUploadButton = true,
  dragAndDrop = null,
  useUpdateMutation,
  useSearchTransaction,
  useOpenFileMutation = useOpenTransactionFileMutation,
  useDeleteFileMutation = useDeleteTransactionFileMutation,
}) => {
  const [currentTransaction, setCurrentTransaction] = useState({ value: null, label: '' });
  const [updateItem] = useUpdateMutation();

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const handleSelectTransaction = (selection) => {
    const option = selection || { value: null, label: '' };

    setCurrentTransaction(option);
    setValue('transaction_id', option?.value || null);
  };

  const [dragState, setDragState] = useState({
    isFileDrag: false
  });
  const [drawerState, setDrawerState] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [openTrainingFiles, { isLoading: fileOpenLoading }] = useOpenFileMutation();
  const [deleteTrainingFiles, { isLoading: isDeleteLoading }] = useDeleteFileMutation();

  const handleOpenFile = async (id) => {
    const toastId = toast.loading('Loading...');
    setCurrentFile(id);
    const res = await openTrainingFiles({ id });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.', {
          id: toastId,
        });
      } else {
        toast.error('Unable to open file.', {
          id: toastId,
        });
      }

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });
    setCurrentFile(null);
    const fileUrl = URL.createObjectURL(res.data);
    window.open(fileUrl, '_blank', 'noreferrer');
  };

  const handleDeleteFile = async (id) => {
    const toastId = toast.loading('Loading...');
    setCurrentFile(id);
    const res = await deleteTrainingFiles({ id });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.', {
          id: toastId,
        });
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });
    setCurrentFile(null);
    setDrawerState(prev => ([...prev.filter(item => item.id !== id)]));
    deleteFileCallback();
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const updateData = {
      id: itemData.item_id,
      data: {
        transaction_id: data.transaction_id,
      }
    };

    await updateItem(updateData).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });

        handleRefresh();
        reset();
        onClose();
        setCurrentTransaction(null);
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  // useEffect(() => {
  //   if (open) {
  //     setDrawerState(itemsList);
  //   }
  // }, [open, itemsList]);

  useEffect(() => {
    if (open) {
      setCurrentTransaction({ value: itemData?.transaction_id ?? null, label: itemData?.transaction_id ?? '' });
      setValue('transaction_id', itemData?.transaction_id ?? null);
    }
  }, [open]);
  
  return (
    <Drawer
      title="Transaction"
      open={open}
      onClose={onClose}
      sx={{
        '.MuiTypography-root': {
          margin: '0 auto'
        }
      }}
    >
      <Card sx={{ padding: 4 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} rowSpacing={2} sx={{ padding: '0 24px' }}>
            <Grid item xs={12}>
              <FormGroup label="Transaction (Internal ID)" hasError={!!errors.transaction_id}>
                <Controller
                  name="transaction_id"
                  control={control}
                  render={({ field }) => (
                    <ServerAutocomplete
                      field={field}
                      withData
                      value={currentTransaction}
                      placeholder="Search Transactions"
                      searchQueryFn={useSearchTransaction}
                      queryParams={itemData?.created_date ? {
                        created_date: itemData?.created_date
                      } : {}}
                      handleSelect={handleSelectTransaction}
                      getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.label}`) || ''}
                    />
                  )}
                />

                <FieldError error={errors.transaction_id} />
              </FormGroup>
            </Grid>
            {itemData?.transaction_id && (
              <Grid item xs={12}>
                <Link to={`/finance/transactions?search_transaction=${itemData?.transaction_id}`} target="_blank">
                  <Box>
                    View Transaction
                  </Box>
                </Link>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button type="submit" title="Link Transaction" />
            </Grid>
          </Grid>
        </form>

        <Divider />

        {/* {itemData?.transaction_id && (*/}
        {/*  <Card sx={{ padding: 4 }}>*/}
        {/*    <Card sx={{*/}
        {/*      gap: '15px',*/}
        {/*      backgroundColor: '#e9f0fe',*/}
        {/*      padding: '10px',*/}
        {/*      borderRadius: '8px'*/}
        {/*    }}*/}
        {/*    >*/}
        {/*      <Box>*/}
        {/*        <Box>*/}
        {/*          <Typography variant="h5" noWrap sx={TextWithIcon}>*/}
        {/*            <DocumentScannerIcon />*/}
        {/*            {`${itemName} Files`}*/}
        {/*          </Typography>*/}
        {/*          <Typography variant="subtitle2" noWrap>*/}
        {/*            You can upload files*/}
        {/*            {itemName?.length ? ` to ${itemName}` : ''}*/}
        {/*            .*/}
        {/*          </Typography>*/}
        {/*        </Box>*/}
        {/*      </Box>*/}

        {/*      <Divider />*/}

        {/*      {isLoadingFiles ? (*/}
        {/*        <ContentPageLoader containerSx={{*/}
        {/*          width: '100%',*/}
        {/*          height: '100%',*/}
        {/*          display: 'flex',*/}
        {/*          alignItems: 'center',*/}
        {/*          justifyItems: 'center',*/}
        {/*          '& > .MuiGrid-item': {*/}
        {/*            margin: '0'*/}
        {/*          }*/}
        {/*        }}*/}
        {/*        />*/}
        {/*      ) : (*/}
        {/*        <Grid container spacing={2}>*/}
        {/*          <Grid item xs={12}>*/}
        {/*            {drawerState?.length ? (*/}
        {/*              <Grid*/}
        {/*                container*/}
        {/*                spacing={2}*/}
        {/*                sx={{*/}
        {/*                  overflowY: 'auto',*/}
        {/*                  maxHeight: '70vh'*/}
        {/*                }}*/}
        {/*              >*/}
        {/*                {drawerState.map((file, index) => (*/}
        {/*                  <Grid item xs={12} key={file.id} sx={{ backgroundColor: 'white', margin: '10px', borderRadius: '8px' }}>*/}
        {/*                    <Box sx={StatusHeader}>*/}
        {/*                      <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '70%' }}>*/}
        {/*                        {`#${index + 1}`}*/}

        {/*                        <Chip label={file?.name || file?.file_name} sx={{ ...TextWrap, ml: 2 }} />*/}
        {/*                      </Box>*/}

        {/*                      <Box sx={{ margin: '0.2rem' }}>*/}
        {/*                        <Button*/}
        {/*                          key={file.id}*/}
        {/*                          startIcon={(fileOpenLoading && (currentFile === file?.id)) ? <CircularProgress size={15} color="inherit" /> : <OpenInNewIcon />}*/}
        {/*                          disabled={fileOpenLoading && currentFile === file?.id}*/}
        {/*                          title="Open"*/}
        {/*                          size="small"*/}
        {/*                          onClick={() => {*/}
        {/*                            if (currentOpenFile) {*/}
        {/*                              currentOpenFile(file?.id);*/}
        {/*                            } else {*/}
        {/*                              handleOpenFile(file.id);*/}
        {/*                            }*/}
        {/*                          }}*/}
        {/*                        />*/}
        {/*                        <Button*/}
        {/*                          startIcon={(isDeleteLoading && (currentFile === file?.id)) ? <CircularProgress size={15} color="inherit" /> : <DeleteIcon />}*/}
        {/*                          sx={{ marginLeft: '0.5rem', ...(RedButton) }}*/}
        {/*                          onClick={() => handleDeleteFile(file.id)}*/}
        {/*                          disabled={!hasPermissions || (isDeleteLoading && currentFile === file?.id)}*/}
        {/*                          title="Delete"*/}
        {/*                          size="small"*/}
        {/*                        />*/}
        {/*                      </Box>*/}
        {/*                    </Box>*/}
        {/*                    <Box sx={{*/}
        {/*                      display: 'flex', justifyContent: file?.uploaded_by ? 'space-between' : 'flex-end', margin: '0.5rem'*/}
        {/*                    }}*/}
        {/*                    >*/}
        {/*                      {(file?.uploaded_by) && (*/}
        {/*                        <Typography*/}
        {/*                          variant="subtitle2"*/}
        {/*                          noWrap*/}
        {/*                          sx={{*/}
        {/*                            margin: '0!important'*/}
        {/*                          }}*/}
        {/*                        >*/}
        {/*                          {`Uploaded by: ${file?.uploaded_by}`}*/}
        {/*                        </Typography>*/}
        {/*                      )}*/}
        {/*                      {(file?.updated_at || file?.created_at || file?.uploaded_at) && (*/}
        {/*                        <Typography*/}
        {/*                          noWrap*/}
        {/*                          variant="subtitle2"*/}
        {/*                          sx={{*/}
        {/*                            margin: '0!important'*/}
        {/*                          }}*/}
        {/*                        >*/}
        {/*                          {`Uploaded at: ${moment(file?.updated_at || file?.created_at || file?.uploaded_at).format('YYYY-MM-DD hh:mm:ss')}`}*/}
        {/*                        </Typography>*/}
        {/*                      )}*/}
        {/*                    </Box>*/}
        {/*                  </Grid>*/}
        {/*                ))}*/}
        {/*              </Grid>*/}
        {/*            ) : (*/}
        {/*              <Typography variant="subtitle1" bold noWrap sx={{ textAlignLast: 'center' }}>*/}
        {/*                No files uploaded yet.*/}
        {/*              </Typography>*/}
        {/*            )}*/}
        {/*          </Grid>*/}

        {/*          {hasUploadButton && (*/}
        {/*            <Grid item xs={12} sx={{ textAlignLast: 'center' }}>*/}
        {/*              <Upload*/}
        {/*                sx={{ marginTop: '1rem' }}*/}
        {/*                disabled={!hasPermissions || isAttachLoading}*/}
        {/*                handleUpload={uploadFile}*/}
        {/*                title="Upload New File"*/}
        {/*                multiple*/}
        {/*              />*/}
        {/*            </Grid>*/}
        {/*          )}*/}
        {/*        </Grid>*/}
        {/*      )}*/}
        {/*      {(dragAndDrop) && (*/}
        {/*        <Box*/}
        {/*          onDragLeave={(e) => {*/}
        {/*            e.preventDefault();*/}
        {/*          }}*/}
        {/*          onDragEnter={(e) => {*/}
        {/*            e.preventDefault();*/}
        {/*            setDragState(prev => ({*/}
        {/*              ...prev,*/}
        {/*              isFileDrag: true*/}
        {/*            }));*/}
        {/*          }}*/}
        {/*          onDragOver={(e) => {*/}
        {/*            e.preventDefault();*/}
        {/*          }}*/}
        {/*          onDrop={(e) => {*/}
        {/*            e.stopPropagation();*/}
        {/*            e.preventDefault();*/}

        {/*            uploadFile({ target: { files: e.dataTransfer.files } });*/}
        {/*            setDragState(prev => ({*/}
        {/*              ...prev,*/}
        {/*              isFileDrag: false*/}
        {/*            }));*/}
        {/*          }}*/}
        {/*          sx={{*/}
        {/*            position: 'absolute',*/}
        {/*            top: '10px',*/}
        {/*            right: '10px',*/}
        {/*            bottom: '10px',*/}
        {/*            left: '10px',*/}
        {/*            backgroundColor: 'rgba(0, 52, 103, 0.4)',*/}
        {/*            boxShadow: '0 0 20px 9px #fff inset',*/}
        {/*            display: 'flex',*/}
        {/*            justifyContent: 'center',*/}
        {/*            alignItems: 'center',*/}
        {/*            color: '#fff',*/}
        {/*            opacity: 0,*/}
        {/*            zIndex: '-1',*/}

        {/*            ...(dragState.isFileDrag && {*/}
        {/*              opacity: 1,*/}
        {/*              zIndex: '999'*/}
        {/*            }),*/}

        {/*            transition: 'all .3s linear',*/}
        {/*          }}*/}
        {/*          className="dragAndDropAnimation"*/}
        {/*        >*/}
        {/*          <Typography variant="h4" sx={{ color: '#fff' }}>*/}
        {/*            Drag file to this drop zone!*/}
        {/*          </Typography>*/}
        {/*        </Box>*/}
        {/*      )}*/}
        {/*    </Card>*/}
        {/*  </Card>*/}
        {/* )}*/}
      </Card>
    </Drawer>
  );
};

export default TransactionDrawer;

import {
  mixed, object, string
} from 'yup';

export const defaultValues = {
  proof_of_payment_file: '',
  payment_date: null,
};

export const schema = object().shape({
  proof_of_payment_file: mixed().test('isEmpty', 'File cannot be blank', (value) => !!value ).nullable(),
  payment_date: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Payment date'),
});

export const containerSx = {
  backgroundColor: '#fff',
  p: '25px',
  mt: '30px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
};

export const tableSx = {
  minWidth: '100%',
};

export const footerSx = {
  mt: '30px',
  paddingTop: '15px',
  borderTop: '1px solid #e9edef'
};

import React from 'react';
import Accordion from '../Accordion';
import Grid from '@mui/material/Grid';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { useTheme } from '@mui/material/styles';
import { Controller } from 'react-hook-form';
import { titleSx } from '../TotalHrs/styles';
import { useMediaQuery } from '@mui/material';

const OilUplift = ({ control, isPanel }) => {
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Accordion title="OIL UPLIFT" isPanel={isPanel}>
      <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={12} sm={6} sx={titleSx}>
          LH ENG
        </Grid>
        {!matchesLg && (
          <Grid item xs={12} sm={6} sx={titleSx}>
            LH PGB
          </Grid>
        )}
        <Grid item xs={12} lg={2}>
          <FormGroup label="ARRIVAL">
            <Controller
              name="oil_lh_eng_arrival"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormGroup label="UPLIFT">
            <Controller
              name="oil_lh_eng_uplift"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormGroup label="DEPARTURE">
            <Controller
              name="oil_lh_eng_departure"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>

        {matchesLg && (
          <Grid item xs={12} sm={6} sx={titleSx}>
            LH PGB
          </Grid>
        )}

        <Grid item xs={12} lg={2}>
          <FormGroup label="ARRIVAL">
            <Controller
              name="oil_lh_pgb_arrival"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormGroup label="UPLIFT">
            <Controller
              name="oil_lh_pgb_uplift"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormGroup label="DEPARTURE">
            <Controller
              name="oil_lh_pgb_departure"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} sm={6} sx={titleSx}>
          RH ENG
        </Grid>

        {!matchesLg && (
          <Grid item xs={12} sm={6} sx={titleSx}>
            RH PGB
          </Grid>
        )}

        <Grid item xs={12} lg={2}>
          <FormGroup label="ARRIVAL">
            <Controller
              name="oil_rh_eng_arrival"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormGroup label="UPLIFT">
            <Controller
              name="oil_rh_eng_uplift"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormGroup label="DEPARTURE">
            <Controller
              name="oil_rh_eng_departure"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>

        {matchesLg && (
          <Grid item xs={12} sm={6} sx={titleSx}>
            RH PGB
          </Grid>
        )}

        <Grid item xs={12} lg={2}>
          <FormGroup label="ARRIVAL">
            <Controller
              name="oil_rh_pgb_arrival"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormGroup label="UPLIFT">
            <Controller
              name="oil_rh_pgb_uplift"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={2}>
          <FormGroup label="DEPARTURE">
            <Controller
              name="oil_rh_pgb_departure"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Accordion>
  ); };

export default OilUplift;

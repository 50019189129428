import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IconButton, InputAdornment,
} from '@mui/material';
import {
  WrapLoginPage,
  CustomForm,
  CustomLabel,
  ErrorMessage,
  ButtonContainerLogin,
  SignInButton,
  LoginInput,
  Logo,
} from './styles';
import logoWhite from '../../assets/img/fleet-logo-font.png';
import { useAuth } from '../../hooks/useAuth';

const LoginPage = () => {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState('');
  const { adminToken } = useSelector((state) => state.session);
  const navigate = useNavigate();
  const auth = useAuth();

  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (!data.username || !data.password) return;

    await auth.login({
      username: data.username,
      password: data.password,
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!adminToken) {
      if (location.state) {
        navigate('/login', { state: location.state }, { replace: true });
      } else {
        navigate('/login', { replace: true });
      }
    }
  }, [adminToken]);

  return (
    <WrapLoginPage>
      <CustomForm onSubmit={handleSubmit(onSubmit)}>
        <img src={logoWhite} style={Logo} alt="logoWhite" />
        <CustomLabel>Username</CustomLabel>
        <LoginInput {...register('username', { required: true })} />
        {errors?.username?.type === 'required' && <ErrorMessage>This field is required</ErrorMessage>}
        {errors?.username?.type === 'pattern' && <ErrorMessage>Not valid username</ErrorMessage>}

        <CustomLabel>Password</CustomLabel>
        <LoginInput
          type={showPassword ? 'text' : 'password'}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                sx={{
                  color: 'primary.contrastText',
                }}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            )}
          {...register('password', { required: true })}
        />
        {errors?.username?.type === 'required' && <ErrorMessage>This field is required</ErrorMessage>}

        <ButtonContainerLogin>
          <SignInButton type="submit" className="loginButton">Sign In</SignInButton>
        </ButtonContainerLogin>
      </CustomForm>
    </WrapLoginPage>
  );
};

export default LoginPage;

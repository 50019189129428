import React from 'react';
import Box from '@mui/material/Box';
import { cardContainer, typeSx } from './styles';

const types = {
  sky: {
    backgroundColor: '#659be0',
    color: '#fff',
  },
};

const TypeCard = ({ title, variant, children }) => (
  <Box sx={cardContainer}>
    <Box sx={{ ...typeSx, ...types[variant] }}>
      {title}
    </Box>

    {children}
  </Box>
);

export default TypeCard;

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Button from '../../../../../../components/Button';
import Drawer from '../../../../../../components/Drawer';
import FieldError from '../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import { useCreateConsumableMutation } from '../../../../../../store/session';
import { useHandleQueryFormError } from '../../../../../../utils/hooks/useHandleQueryFormError';
import { defaultValues, schema } from './schema';
import { cancelSx, drawer, footerSx } from './styles';

const CreateConsumableDrawer = ({ open, onClose }) => {
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const params = useParams();
  const handleError = useHandleQueryFormError();

  const [createConsumable, { isLoading }] = useCreateConsumableMutation();

  const {
    formState: { errors },
    control,
    getValues,
    setError,
    reset,
    handleSubmit
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const reqData = {
      ...data,
      wo_task_id: params?.id,
    };

    const res = await createConsumable(reqData);

    if (res.error) {
      handleError(res.error, setError, getValues, toastId);

      return;
    }

    onClose();
    reset();

    toast.success('Successfully created!', {
      id: toastId,
    });
  };

  return (
    <Drawer
      title="Task"
      open={open}
      onClose={onClose}
      sx={{ ...(maxWidth800px && drawer) }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={5}
          rowSpacing={2}
          sx={{ p: '30px' }}
        >
          <Grid item xs={12}>
            <FormGroup label="Wo item text" required hasError={!!errors.wo_item_text}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="Wo item text"
                    {...field}
                  />
                )}
                control={control}
                name="wo_item_text"
              />

              <FieldError error={errors.wo_item_text} />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="QTY" required hasError={!!errors.quantity}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="QTY"
                    {...field}
                  />
                )}
                control={control}
                name="quantity"
              />

              <FieldError error={errors.quantity} />
            </FormGroup>
          </Grid>
        </Grid>

        <Box sx={footerSx}>
          <Button disabled={isLoading} type="submit" variant="contained" title="Create" />
          <Button sx={cancelSx} title="Close" onClick={onClose} />
        </Box>
      </form>
    </Drawer>
  );
};

export default CreateConsumableDrawer;

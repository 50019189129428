export const cardContainer = (isPanel) => ({
  background: '#fff',
  borderRadius: '4px',
  boxShadow: '0 0 20px 8px #d0d0d0',
  p: '60px 25px 25px 25px',
  position: 'relative',
  ...(isPanel && {
    boxShadow: 'none',
    border: '1px solid rgba(76, 78, 100, 0.22)'
  })
});

export const typeSx = {
  position: 'absolute',
  top: '15px',
  left: '-2px',
  padding: '7px 14px',
  zIndex: '2',
};
import React from 'react';
import { Card, Divider } from '@mui/material';
import Search from '../../../components/SearchStatistics/Search';
import { useGetStatisticByDepartureMutation } from '../../../store/session';

import Table from './Table/Table';

const ByDeparture = () => (
  <Card sx={{ mt: 3 }}>
    <Search
      fixedCacheKey="get-statistic-by-departure"
      useHook={useGetStatisticByDepartureMutation}
      clearPath="/statistics/by-departure"
    />
    <Divider />
    <Table />
  </Card>
);

export default ByDeparture;

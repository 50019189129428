
export const container = (img) => ({
  backgroundColor: '#d1d1d1',
  borderRadius: '8px',
  width: '100%',
  maxWidth: '259px',
  marginLeft: '25px',
  height: '280px',
  p: '30px',
  mb: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  ...(img && (
    {
      p: 0,
      img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }
    }
  ))
});

export const accountIcon = {
  width: '80px',
  height: '80px'
};

export const profilePhoto = {
  fontSize: '32px',
};

export const ContainerSmall = {
  maxWidth: 'unset',
  marginLeft: 'unset'
};

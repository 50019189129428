import { Card, Grid, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import {
  useDeleteSupplierMutation,
  useGetSupplierTableListQuery,
} from '../../../store/session';
import { columns } from './columns';

const SupplierTable = ({ title }) => {
  const navigate = useNavigate();
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  return (
    <Grid>
      <Grid>
        <Card>
          <Box
            sx={maxWidth800px ? {
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              mb: 4
            } : {
              pb: 3,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="h3"
              sx={{ textTransform: 'capitalize' }}
            >
              {title}
            </Typography>

            <Button title="Add Supplier" onClick={() => { navigate('/directories/supplier/create'); }} />
          </Box>
          <EditableTable
            useHook={useGetSupplierTableListQuery}
            useHookDelete={useDeleteSupplierMutation}
            columns={columns}
            sortByType="DESC"
            sortByActive="srvsup_name"
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.id}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default SupplierTable;

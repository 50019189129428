import {
  Box, Card, CircularProgress, Typography, Tabs, Tab
} from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import LinkButton from '../../../../components/LinkButton';
import { useGetSupplierInfoQuery } from '../../../../store/slices/logisticsSlice';
import { headerBtn } from '../../../Invoices/style';
import { RedButton, TextWithIcon } from '../../style';
import DrawerForm from '../NewSupplier/DrawerForm';
import CreditNotes from "../CreditNotes";

const SupplierPage = () => {
  const params = useParams();
  const partId = params.id;
  const [tabIndex, setTabIndex] = useState(0);

  const { data: supplier, isLoading } = useGetSupplierInfoQuery({ id: partId }, {
    refetchOnMountOrArgChange: true
  });

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <Box
          sx={{ ...headerBtn, display: 'flex', justifyContent: 'space-between' }}
        >
          <Box>
            <Typography noWrap variant="h4" sx={TextWithIcon}>
              Vendor #
              {partId}
              {' - '}
              {supplier?.name}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <LinkButton path="/logistics/vendors">
              <Button title="Back" sx={RedButton}></Button>
            </LinkButton>
          </Box>
        </Box>
      </Card>

      <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mt: 3 }}>
        <Tab label="Vendor Data" />
        <Tab label="Vendor Balances" disabled={!supplier?.currency_id} />
      </Tabs>

      {tabIndex === 0 && (
        isLoading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Card sx={{ mt: 3, padding: '1rem' }}>
            <DrawerForm type="update" drawerClose={() => {}} handleRefresh={() => {}} supplier={supplier} />
          </Card>
        )
      )}

      {tabIndex === 1 && (
        <CreditNotes type="create" drawerClose={() => {}} handleRefresh={() => {}} supplier={supplier} />
      )}
    </>
  );
};

export default SupplierPage;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import Button from '../../../../components/Button';
import FiltrationDate from '../../../../components/EditableTable/FiltrationDate';
import FiltrationInput from '../../../../components/EditableTable/FiltrationInput';
import TextEdit from '../../../../components/EditableTable/TextEdit';
import { TYPE_INPUT, TYPE_MULTI_INPUT } from '../../../../components/EditableTable/constants';
import {
  HeaderName, StyledDelete
} from '../../../../components/EditableTable/styles';
import { CrewTrainingDocumentModel } from '../../../../utils/constants/portalFiles';
import { GreenButton } from '../../../Logistics/style';

export const columns = ({
  handleFilter,
  filters,
  handleOpen,
  handleOpenDeleteModal,
  handleOpenDropDownTRG,
  handleOpenDropDownFDBK,
  hasPermissions,
  handleFileDrawer,
  handlePreviewFDBK,
}) => ([
  {
    flex: 0.2,
    field: 'document_name',
    minWidth: 180,
    headerName: 'Document Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Document Name</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.document_name || ''}
          handleFilter={handleFilter}
          placeholder="Document Name"
          value="document_name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'document_name', row)}
        text={row.document_name}
        disabled={!hasPermissions}
        labelInput="Enter Document Name"
        type={TYPE_INPUT}
      />
    ),
  }, // document_name
  {
    flex: 0.2,
    field: 'expiry_date',
    minWidth: 180,
    headerName: 'Expired Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Expired Date</Typography>
        <FiltrationDate
          value={filters.expiry_date || null}
          onChange={(e) => {
            handleFilter(e, 'expiry_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'expiry_date', row)}
        text={row.expiry_date === '9999-09-09' ? 'N/A' : row.expiry_date}
        disabled={!hasPermissions}
        labelInput="Enter Review Date"
        type="DATE_SELECT_OR_N/A"
      />
    ),
  }, // expiry_date
  {
    flex: 0.1,
    field: 'threshold_days',
    minWidth: 180,
    headerName: 'Threshold Days',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Threshold Days</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.threshold_days || ''}
          handleFilter={handleFilter}
          placeholder="Threshold Days"
          value="threshold_days"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'threshold_days', row)}
        text={row.threshold_days}
        disabled={!hasPermissions}
        labelInput="Enter Threshold Days"
        type={TYPE_INPUT}
      />
    ),
  }, // threshold_days
  {
    flex: 0.1,
    field: 'trg_file_exist',
    minWidth: 120,
    headerName: 'Files',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Files</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Button sx={GreenButton} size="small" onClick={() => handleFileDrawer(true, row?.id, CrewTrainingDocumentModel.types.training.id, '', row?.program_name)}>Files</Button>
      </Box>
    )
  }, // TRG file
  {
    flex: 0.2,
    field: 'feedback',
    minWidth: 180,
    headerName: 'Remarks',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Remarks</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.feedback || ''}
          handleFilter={handleFilter}
          placeholder="Remarks"
          value="feedback"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'feedback', row)}
        text={row.feedback}
        labelInput="Feedback"
        disabled={!hasPermissions}
        type={TYPE_MULTI_INPUT}
      />
    ),
  }, // feedback
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => hasPermissions && (
      <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
    ),
  }, // actions
]);

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import FieldError from '../../../../form/components/FieldError'
import FormGroup from '../../../../form/components/FormGroup'
import Input from '../../../../form/components/Input'
import {
  defaultValues, schema
} from './schema'

const InstructorForm = ({ onSubmit, maxWidth800px }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  return (
    <form>
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box>
          <Typography variant="h3">Create Instructor</Typography>
        </Box>

        <CardContent sx={{ mt: 5, ...(maxWidth800px && {paddingLeft: '0'})  }}>
          <Grid container spacing={5}>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Instructor Name" required hasError={!!errors.instructor_name}>
                <Controller
                  name="instructor_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Instructor Name"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.instructor_name} />
              </FormGroup>
            </Grid>

          </Grid>
          <Grid item xs={12} sx={{ mt: 5 }}>
            <Button type="submit" variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default InstructorForm;
import React from 'react';
import { LoadingButton } from '@mui/lab';

const Button = ({
  children, title, variant = 'contained', ...props 
}) => (
  <LoadingButton
    variant={variant}
    {...props}
  >
    {title}
    {children}
  </LoadingButton>
);

export default Button;

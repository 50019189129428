import React from 'react';
import { Box, Card, useMediaQuery } from '@mui/material';
import { ButtonStyle, TitleContainer } from '../../Crew/styles';
import LinkButton from '../../../components/LinkButton';
import EditableTable from '../../../components/EditableTable';
import { useDeleteUserGroupMutation, useGetUsersGroupsTableQuery } from '../../../store/session';
import { columns } from './columns';
import { titleContainerS } from './styles';

const UsersGroups = () => {
  const maxWidth810px = useMediaQuery('(max-width:810px)');

  return (
    <Card sx={{ padding: '1rem' }}>
      <Box
        sx={{ ...TitleContainer, ...(maxWidth810px && titleContainerS) }}
      >
        <h1>Manage Users Access Groups</h1>
        <LinkButton style={ButtonStyle} path="/manage-users-groups/create">Create new Access Group</LinkButton>
      </Box>

      <EditableTable
        useHook={useGetUsersGroupsTableQuery}
        useHookDelete={useDeleteUserGroupMutation}
        columns={columns}
        sortByType="ASC"
        sortByActive="name"
        getRowHeight={() => 'auto'}
      />
    </Card>
  );
};

export default UsersGroups;

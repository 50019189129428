import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetAircraftListQuery,
  useGetOdsQuery,
  useGetOdsRecalculateMutation,
  useGetOdsOtherQuery,
  useGetOdsStatusesQuery,
  useGetAllAircraftTypeQuery,
  useGetOdsExportExcelMutation,
} from '../../../store/session';
import { useMenuToggle } from '../../../utils/hooks/useMenuToggle';
import FileSaver from 'file-saver';
import { usePermissions } from '../../../utils/hooks/usePermissions';

export const useOds = () => {
  const params = useParams();
  const [activeType, setActiveType] = useState(+params.id);
  const [idRow, setIdRow] = useState(null);
  const { data: odsStatuses } = useGetOdsStatusesQuery();

  const tableId = params.id;

  const hasPermissionsOdsCreate = usePermissions('ods_creator');

  const {
    open, openMenu, closeMenu, anchorEl,
  } = useMenuToggle();

  const {
    open: openCreteForm,
    openMenu: openMenuCreteForm,
    closeMenu: closeMenuCreteForm,
    anchorEl: anchorElCreteForm,
  } = useMenuToggle();

  const maxWidth1000px = useMediaQuery('(max-width:1275px)');
  const maxWidth733px = useMediaQuery('(max-width:733px)');

  const { data: aircraft } = useGetAircraftListQuery();
  const { data: aircraftTypeT } = useGetAllAircraftTypeQuery();

  const [odsRecalculate, { isLoading }] = useGetOdsRecalculateMutation();
  const tableParams = { aircraft_id: params.id };

  const navigate = useNavigate();

  const handleSetActiveType = (id) => {
    if (id === 'other') {
      setActiveType('other');
      navigate('/directories/ods/other');

      return;
    }

    setActiveType(id);
    navigate(`/directories/ods/${id}`);
  };
  const [exportExcel] = useGetOdsExportExcelMutation();

  const handleDoc = async () => {
    const res = await exportExcel({ aircraft_id: params.id });

    FileSaver.saveAs(res.data, 'ods_report');
  };

  const handleOpen = (e, values) => {
    setIdRow(values);
    openMenu(e);
  };

  return {
    activeType,
    aircraftTypeT,
    aircraft,
    maxWidth1000px,
    maxWidth733px,
    tableParams,
    isLoading,
    open,
    tableId,
    anchorEl,
    odsStatuses,
    idRow,
    hasPermissionsOdsCreate,
    openCreteForm,
    anchorElCreteForm,
    openMenuCreteForm,
    closeMenuCreteForm,
    handleOpen,
    closeMenu,
    handleDoc,
    odsRecalculate,
    handleSetActiveType,
    useGetOdsOtherQuery,
    useGetOdsQuery,
  };
};

import { styled } from '@mui/material/styles';
import { FormControl } from '@mui/material';

export const CustomForm = styled(FormControl)(() => ({
  paddingBottom: '1rem',
}));

export const controllerContainer = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '20px',
};

export const serviceContainer = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '1200px',
  gap: '35px',
  p: '15px',
  width: '100%',
};

export const additionalValuesContainer = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  flexWrap: 'nowrap',
};

export const deleteIcon = {
  cursor: 'pointer',
};

export const loader = {
  width: '24px !important',
  height: '24px !important',
};

export const supplierButton = {
  overflow: 'hidden',
  maxWidth: '247px',
  color: '#fff !important',
  minWidth: '157px'
};

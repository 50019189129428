import { STATUSES } from '../../../../../constans/invoiceRequest';

const statusStyle = {};
// eslint-disable-next-line no-return-assign
STATUSES.map((status) => (
  statusStyle[`& .row-${status.tag}`] = {
    backgroundColor: status.color,
    '&:hover': {
      backgroundColor: `${status.color} !important`
    },
    '&.Mui-selected': {
      backgroundColor: `${status.color} !important`
    }
  }
));

export const UserInvoicesContainer = {
  width: '100%',
  padding: '15px',
  ...statusStyle
};

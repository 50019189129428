import React from 'react';
import { Card, CardContent } from '@mui/material';
import AirportTable from './AirportTable';

const Airport = () => (
  <Card sx={{ mt: 3 }}>
    <CardContent>
      <AirportTable title="Manage Airports" />
    </CardContent>
  </Card>
);

export default Airport;

import { STATUSES } from '../../constans/invoiceRequest';

export const input = {
  '& input': {
    cursor: 'pointer',
  },
  '& .Mui-disabled': { color: '#616161', '-webkit-text-fill-color': '#616161 !important' },
  '& .MuiOutlinedInput-notchedOutline': { borderColor: '#616161 !important' }
};

export const topContent = {
  display: 'flex',
  width: '100%',
  gap: '20px'
};

export const cardContent = {
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
  backgroundColor: '#FFF',
  padding: '0',
  mt: '0'
};

export const cardProfile = {
  display: 'flex',
  gap: '20px',
  mt: '15px !important',
  flexDirection: 'column',
  backgroundColor: '#FFF',
};

export const content = {
  display: 'flex',
  flexDirection: 'column',
};

export const header = {
  color: '#616161',
  fontWeight: '600 !important',
  whiteSpace: 'nowrap',
  minWidth: '125px',
  textTransform: 'capitalize !important',
};

export const body = {
  minWidth: '125px',
  fontSize: '13px !important',
  'input': {
    fontSize: '13px !important',
  }
};

export const block = {
  width: '50%',
};

export const linkSx = {
  color: 'rgb(108, 108, 241)',
  cursor: 'pointer',
};

export const blockSm = {
  width: '100%',
};

const statusStyle = {};
// eslint-disable-next-line no-return-assign
STATUSES.map((status) => (
  statusStyle[`& .row-${status.tag}`] = {
    backgroundColor: status.color,
    '&:hover': {
      backgroundColor: `${status.color} !important`
    },
    '&.Mui-selected': {
      backgroundColor: `${status.color} !important`
    }
  }
));

export const UserInvoicesContainer = {
  width: '100%',
  padding: '15px',
  ...statusStyle
};

import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import InvoicesForm from './InvoicesForm';
import { asterisk, headerTitle, headerTitleS } from './style';

export const InvoiceBoxStyle = {
  backgroundColor: 'white',
  padding: '1rem',
  borderRadius: '10px'
};

const Invoices = ({ hasPermissions }) => {
  const navigate = useNavigate();

  const maxWidth800px = useMediaQuery('(max-width:800px)');

  return (
    <Box sx={InvoiceBoxStyle}>
      <Box sx={{ ...headerTitle, ...(maxWidth800px && headerTitleS) }}>
        <Box>
          <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
            Create Invoice Request
          </Typography>
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              textTransform: 'capitalize', display: 'flex', mb: 2, fontWeight: 600
            }}
          >
            Fields with
            {' '}
            <Typography sx={asterisk}>*</Typography>
            {' '}
            are required.
          </Typography>
        </Box>

        <Box>
          <Button
            disabled={!hasPermissions}
            title="Create Payment Purposes"
            onClick={() => {
              navigate('/directories/payment-purposes');
            }}
          />
        </Box>
      </Box>
      <InvoicesForm />
    </Box>
  );
};

export default Invoices;

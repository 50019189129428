import Box from '@mui/material/Box';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import DropDownMenu from '../../../../components/DropDownMenu';
import { useCopyFleetMutation, useCreateFlightFromFleetMutation } from '../../../../store/session';
import { dropdownMenuSx, itemSx } from './styles';

const ActionDropDown = ({
  open,
  onClose,
  anchorEl,
  id,
  handleSetNewestHops
}) => {
  const [createFlight] = useCreateFlightFromFleetMutation();
  const [copyFleet] = useCopyFleetMutation();
  const navigate = useNavigate();

  const handleCopyFleet = () => {
    const request = copyFleet(id);

    toast.promise(request, {
      loading: 'Loading',
      success: 'Success',
      error: 'Something went wrong',
    }).then((result) => {
      window.open(`${process.env.REACT_APP_URL}quotations/update/${result.data.id}`);
    });

    onClose();
  };

  const handleFlight = () => {
    const request = createFlight(id);

    toast.promise(request, {
      loading: 'Loading',
      success: 'Success',
      error: 'Something went wrong',
    }).then((result) => {
      navigate(`/flights/${result.data.id}`);
    });

    onClose();
  };

  return (
    <DropDownMenu
      sx={dropdownMenuSx}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
    >
      <Box>
        <Box sx={itemSx} onClick={handleFlight}>Create Flight</Box>
        <Box sx={itemSx} onClick={handleCopyFleet}>Copy Quotation</Box>
        <Box sx={itemSx} onClick={() => handleSetNewestHops(true)}>Set Newest Airports</Box>
      </Box>
    </DropDownMenu>
  ); };

export default ActionDropDown;

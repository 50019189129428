import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Divider,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Person, Home, Phone, Email, Fax
} from '@mui/icons-material';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import FormGroup from '../../../../form/components/FormGroup';
import {
  fetchCurrencies,
  useCreateSupplierMutation,
  useUpdateSupplierInfoMutation
} from '../../../../store/slices/logisticsSlice';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { GreenButton } from '../../style';
import { button, buttonBlock } from '../style';
import { defaultValues, schema } from './schema';
import Select from '../../../../form/components/Select';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../hooks/toolkitHooks';
import FieldError from '../../../../form/components/FieldError';

const DrawerForm = ({
  type, drawerClose, handleRefresh, supplier = null
}) => {
  const dispatch = useAppDispatch();
  const update = type === 'update';
  const hasWritePermission = usePermissions('logistics_suppliers_write');
  const {
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: update ? supplier : defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const [sendSupplier] = useCreateSupplierMutation();
  const [updateSupplier] = useUpdateSupplierInfoMutation();
  const handleError = useHandleQueryFormError();

  const onSubmit = async (data) => {
    if (!hasWritePermission) {
      toast.error('You do not have access!');

      return;
    }
    delete data?.currency
    const toastId = toast.loading('Loading...');
    const res = update ? await updateSupplier({ id: supplier?.id, data }) : await sendSupplier({ data });

    if (res.error) {
      handleError(res.error, setError, getValues, toastId);

      return;
    }

    toast.success(`The Supplier was ${update ? 'updated' : 'created'}!`, { id: toastId });

    if (!update) {
      drawerClose();
      reset();
    }

    handleRefresh();
  };

  useEffect(() => {
    if (type === 'update') {
      Object.keys(defaultValues).forEach((key) => setValue(key, supplier?.[key]));
    }
    setValue('type', type);
  }, [supplier]);

  const { currencies } = useSelector((state) => state.logistics);
  useEffect(() => {
    dispatch(fetchCurrencies());
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
        {update ? 'Update Vendor' : 'Create Vendor'}
      </Typography>

      <Divider sx={{ mb: 5 }} />

      <Grid container spacing={3} sx={{ padding: '0 1rem', mt: 2 }}>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                label="Name"
                fullWidth
                size="small"
                placeholder="Enter Name"
                {...field}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormGroup required hasError={!!errors.currency_id}>
            <Controller
              name="currency_id"
              control={control}
              render={({ field }) => (
                <Select
                  label="Vendor Currency"
                  placeholder="."
                  {...field}
                  options={currencies}
                />
              )}
            />

            <FieldError error={errors.currency_id} />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Addresses
          </Typography>
          <Divider sx={{ mb: 5 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Controller
                name="legal_address"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Legal Address"
                    fullWidth
                    size="small"
                    placeholder="Enter Legal Address"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Home />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.legal_address}
                    helperText={errors.legal_address?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Controller
                name="actual_address"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Actual Address"
                    fullWidth
                    size="small"
                    placeholder="Enter Actual Address"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Home />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.actual_address}
                    helperText={errors.actual_address?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Controller
                name="delivery_address"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Delivery Address"
                    fullWidth
                    size="small"
                    placeholder="Enter Delivery Address"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Home />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.delivery_address}
                    helperText={errors.delivery_address?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Contact Information
          </Typography>
          <Divider sx={{ mb: 5 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Phone"
                    fullWidth
                    size="small"
                    placeholder="Enter Phone Number"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Email"
                    fullWidth
                    size="small"
                    placeholder="Enter Email"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Controller
                name="fax"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Fax"
                    fullWidth
                    size="small"
                    placeholder="Enter Fax"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Fax />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.fax}
                    helperText={errors.fax?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{
        ...buttonBlock, textAlignLast: 'center', padding: '0 1rem', mt: 4
      }}
      >
        <Button sx={{ ...button, ...(update && GreenButton) }} title={update ? 'Save' : 'Create'} type="submit" />
      </Box>
    </form>
  );
};

export default DrawerForm;

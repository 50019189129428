export const footerSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  p: '20px'
};

export const cancelSx = {
  background: '#e1e5ec',
  color: '#003467',
  '&:hover': {
    backgroundColor: 'rgba(0, 52, 103, 0.08)',
  }
};

export const drawer = {
  '& .MuiPaper-root': {
    width: '100%'
  }
};

import React from 'react';
import TypeCard from '../TypeCard';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormGroup from '../../../../form/components/FormGroup';
import DatePicker from '../../../../form/components/DatePicker';
import { Controller } from 'react-hook-form';
import FieldError from '../../../../form/components/FieldError';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';

const Arrival = ({
  control, isPanel, setValue, errors = {}, airportList,
}) => (
  <TypeCard title="ARRIVAL" variant="blue" isPanel={isPanel}>
    <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1 }}>
      <Grid item sm={12} lg={4}>
        <FormGroup label="TO">
          <Controller
            name="to_id"
            control={control}
            render={({ field }) => (
              <Autocomplete
                freeSolo
                value={airportList?.filter((AirportItem) => AirportItem.id === field.value)[0] || null}
                options={airportList}
                getOptionLabel={(option) => `${option.airport_iata}/${option.airport_icao} (${option.airport_name})` || ''}
                isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    inputRef={field.ref}
                  />
                )}
                onChange={(e, data) => {
                  field.onChange(data?.id);
                }}
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item sm={12} lg={4}>
        <FormGroup label="LAND" required={isPanel} hasError={!!errors.take_on}>
          <Controller
            name="take_on"
            control={control}
            render={({ field }) => (
              <DatePicker
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                placeholderText="YYYY-MM-DD HH:mm"
                dateFormat="yyyy-MM-dd HH:mm"
                showMonthDropdown
                showYearDropdown
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  setDateValue(e, setValue, 'take_on');
                }}
                value={validateDatePickerValue(field.value)}
              />
            )}
          />
          <FieldError error={errors.take_on} />
        </FormGroup>
      </Grid>

      <Grid item sm={12} lg={4}>
        <FormGroup label="BLOCK ON" required={isPanel} hasError={!!errors.block_on}>
          <Controller
            name="block_on"
            control={control}
            render={({ field }) => (
              <DatePicker
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                placeholderText="YYYY-MM-DD HH:mm"
                dateFormat="yyyy-MM-dd HH:mm"
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  setDateValue(e, setValue, 'block_on');
                }}
                value={validateDatePickerValue(field.value)}
              />
            )}
          />

          <FieldError error={errors.block_on} />
        </FormGroup>
      </Grid>
    </Grid>
  </TypeCard>
);

export default Arrival;

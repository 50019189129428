import React from 'react';
import {
  TableCell, TableContainer, TableHead, TableBody, TableRow, Table
} from '@mui/material';
import { columns } from './constants';
import {
  header, body
} from './style';
import { useFormContext } from 'react-hook-form';

const TimeForm = () => {
  const { getValues } = useFormContext();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <TableCell sx={header}>
                {item.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[getValues()?.fullCost].map((item, key) => (
            <TableRow key={key}>
              <TableCell sx={body}>
                {item.charter_price}
              </TableCell>
              <TableCell sx={body}>
                {item.add_invoice_eur}
              </TableCell>
              <TableCell sx={body}>
                {item.acmi}
              </TableCell>
              <TableCell sx={body}>
                {item.fuel}
              </TableCell>
              <TableCell sx={body}>
                {item.inv}
              </TableCell>
              <TableCell sx={body}>
                {item.nav}
              </TableCell>
              <TableCell sx={body}>
                {item.total_expenses}
              </TableCell>
              <TableCell sx={body}>
                {item.profit_eur}
              </TableCell>
              <TableCell sx={body}>
                {item.profit_percent}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

    </TableContainer>
  );
};

export default TimeForm;

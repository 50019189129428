import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCrewListQuery, useGetInstructorTableQuery } from '../../../../store/session';
import {
  useGetLastReportDataQuery, useGetReportDataQuery, usePreviewReportMutation,
  useSendReportMutation,
  useUpdateReportMutation
} from '../../../../store/slices/TrainingModule/trainingReportSlice';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { stringifyLocationSearch } from '../../../../utils/locationSearch';
import { SECOND_REPORT_TYPE } from '../constants';
import { defaultValues, schema } from './schema';

export const useSecondTrainingReportForm = () => {
  const { id } = useParams();
  const isUpdate = id !== 'create';
  const [sendReport] = useSendReportMutation();
  const [updateReport] = useUpdateReportMutation();
  const handleError = useHandleQueryFormError();
  const { data: Instructors } = useGetInstructorTableQuery(
    stringifyLocationSearch({
      sortDir: 'ASC',
      sortField: 'id',
      pageSize: 100,
      page: 1,
    })
  );
  const getInstructorOptions = (items = {}) => items && items?.data?.map(item => ({ value: item.id, label: item.instructor_name }));
  const instructorsOptions = getInstructorOptions(Instructors);
  const { data: LastReportData } = useGetLastReportDataQuery(stringifyLocationSearch({ report_type: 'second_report' }), {
    skip: isUpdate,
    refetchOnMountOrArgChange: true
  });
  const { data: ReportData } = useGetReportDataQuery(id, {
    skip: !isUpdate,
    refetchOnMountOrArgChange: true
  });
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.session);

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const generalItems = useFieldArray({
    name: 'general_items',
    control: form.control,
  });

  const abnormalAndEmergencyProcedures = useFieldArray({
    name: 'abnormal_and_emergency_procedures',
    control: form.control,
  });

  const epsCheck = useFieldArray({
    name: 'eps_check',
    control: form.control,
  });

  const extraItemsChecked = useFieldArray({
    name: 'extra_items_checked',
    control: form.control,
  });

  const markers_1 = useFieldArray({
    name: 'markers_n1',
    control: form.control,
  });

  const markers_2 = useFieldArray({
    name: 'markers_n2',
    control: form.control,
  });

  const markers_3 = useFieldArray({
    name: 'markers_n3',
    control: form.control,
  });

  const markers_4 = useFieldArray({
    name: 'markers_n4',
    control: form.control,
  });

  /**
   * Autocomplete
   */
  useEffect(() => {
    if (isUpdate && ReportData) {
      // if (
      //   user?.role !== 'admin'
      //   && user.id !== ReportData.crew_member_id
      //   && user.id !== ReportData.training_member_id
      //   && user.id !== ReportData.observation_member_id
      // ) {
      //   navigate('/');
      // }
      Object.entries(ReportData).forEach(
        ([name, value]) => form.setValue(name, value),
      );
    }
  }, [ReportData]);

  useEffect(() => {
    if (LastReportData) {
      form.setValue('issue_number', LastReportData?.report_data?.issueNumber);
      form.setValue('revision_number', LastReportData?.report_data?.revisionNumber);
    }
  }, [LastReportData]);

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    data.report_type = SECOND_REPORT_TYPE;
    data.issue_date = data.issue_date ? moment(data.issue_date).format('YYYY-MM-DD') : '';
    data.revision_date = data.revision_date ? moment(data.revision_date).format('YYYY-MM-DD') : '';
    data.training_date = data.training_date ? moment(data.training_date).format('YYYY-MM-DD') : '';
    data.current_check_expires = data.current_check_expires ? moment(data.current_check_expires).format('YYYY-MM-DD') : '';
    data.new_validity_date = data.new_validity_date ? moment(data.new_validity_date).format('YYYY-MM-DD') : '';

    let res;
    if (!isUpdate) {
      res = await sendReport(data);
    } else {
      res = await updateReport({ id, data });
    }

    if (res?.error) {
      handleError(res?.error, form?.setError, form?.getValues, toastId);

      return;
    }

    if (!isUpdate) navigate('/training-module/training-reports');
    toast.success('Success!', {
      id: toastId,
    });
  };

  const [getPreview] = usePreviewReportMutation();

  const onPreview = async (data) => {
    const toastId = toast.loading('Loading...');

    data.report_type = SECOND_REPORT_TYPE;
    data.issue_date = data.issue_date ? moment(data.issue_date).format('YYYY-MM-DD') : '';
    data.revision_date = data.revision_date ? moment(data.revision_date).format('YYYY-MM-DD') : '';
    data.training_date = data.training_date ? moment(data.training_date).format('YYYY-MM-DD') : '';
    data.current_check_expires = data.current_check_expires ? moment(data.current_check_expires).format('YYYY-MM-DD') : '';
    data.new_validity_date = data.new_validity_date ? moment(data.new_validity_date).format('YYYY-MM-DD') : '';

    const res = await getPreview(data);

    if (res.error) {
      toast.error(res?.error?.data?.message || 'Something went wrong.', {
        id: toastId,
      });

      Object.keys(res?.error?.data?.errors || {}).forEach(el => {
        form.setError(el);
      });

      return;
    }

    const fileUrl = URL.createObjectURL(res.data);
    window.open(fileUrl, '_blank', 'noreferrer');

    toast.success('Success!', {
      id: toastId,
    });
  };

  const { data: CrewList } = useGetCrewListQuery(stringifyLocationSearch({
    post: '1,2,5',
    crew_active: 1
  }));

  const PilotsList = CrewList?.filter((crew) => crew.crew_post === 1 || crew.crew_post === 2);
  const TrainingPersonnelList = CrewList?.filter((crew) => crew.crew_post === 5);

  return {
    form,
    isUpdate,
    onPreview,
    onSubmit,
    PilotsList,
    TrainingPersonnelList,
    generalItems,
    abnormalAndEmergencyProcedures,
    epsCheck,
    extraItemsChecked,
    markers_1,
    markers_2,
    markers_3,
    markers_4,
    CrewList,
    instructorsOptions
  };
};

import * as React from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useLocation } from 'react-router';
import { v4 as uuid } from 'uuid';
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useGetStatisticByCompanyMutation, useGetStatisticDetailsByCompanyMutation } from '../../../../store/session';
import { columns } from './columns';
import Checkbox from '../../../../form/components/Checkbox';
import { parseLocationSearch, stringifyLocationSearch } from '../../../../utils/locationSearch';

const Table = () => {
  const location = useLocation();
  const [rowData, setRowsData] = useState([]);
  const [rowDataPlaning, setDataPlaning] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [idRows, setIdRows] = useState([]);

  const xpath = "//div[text()='MUI X Missing license key']";
  const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

  if (matchingElement) {
    matchingElement.remove();
  }

  const [getDetails, { isLoading: isDetails }] = useGetStatisticDetailsByCompanyMutation();
  const [getStatistic, { data, isLoading: isStatistic }] = useGetStatisticByCompanyMutation({
    fixedCacheKey: 'get-statistic-by-company',
  });

  const handleRowClick = async(row) => {
    if (
      row.row.hierarchy.length === 1
      && !idRows.includes(row.id)
      && row.id !== 0
      && row.row.hierarchy[0] !== 'Total'
      && row.row.hierarchy[0] !== 'Total '
    ) {
      const resRows = await getDetails(stringifyLocationSearch({ company_id: row.id, ...parseLocationSearch(location.search) }));
      const aircraftName = resRows.data?.map((item) => ({
        ...item,
        ...({ flights_count: item.flight_id }),
        ...({ hierarchy: [item.company_name, item.aircraft_name, item.planing_id] }),
        ...({ id: item.planing_id })
      }));

      const planing = resRows.data?.map((item) => ({
        ...item,
        ...({ hierarchy: [item.company_name, item.planing_id] }),
        ...({ id: item.planing_id })
      }));

      setRowsData([...rowData, ...aircraftName]);
      setDataPlaning([...rowDataPlaning, ...planing]);
      setIdRows([...idRows, row.id]);
    }
  };

  const dataTable = showTable ? rowData : rowDataPlaning;

  const getTreeDataPath = (row) => row.hierarchy;

  const isLoading = isStatistic || isDetails;

  useEffect(() => {
    if (data?.data && data?.overall) {
      const rows = data.data.map((item) => ({
        hierarchy: [item.company_name],
        flt_time: item.flight_time,
        total_price: item.total_price,
        acmi: item.acmi,
        total_expenses: item.total_expenses,
        profit: item.profit,
        flights_count: item.flights_count,
        profit_percent: item.profit_percent,
        id: item.company_id,
      }));

      const total = [data?.overall].map((item) => ({
        hierarchy: ['Total'],
        flt_time: item.overall_flight_time,
        total_price: item.overall_price,
        acmi: item.overall_acmi,
        total_expenses: item.overall_expenses,
        flights_count: item.overall_flights,
        profit_percent: item.overall_profit_percent,
        id: uuid(),
      }));

      const total1 = [data?.overall].map((item) => ({
        hierarchy: ['Total '],
        flt_time: item.overall_flight_time,
        total_price: item.overall_price,
        acmi: item.overall_acmi,
        total_expenses: item.overall_expenses,
        flights_count: item.overall_flights,
        profit_percent: item.overall_profit_percent,
        id: uuid(),
      }));

      setDataPlaning([...total, ...rows, ...total1]);
      setRowsData([...total, ...rows, ...total1]);
    }
  }, [data]);

  useEffect(() => {
    if (isStatistic) {
      setIdRows([]);
    }
  }, [isStatistic]);

  useEffect(() => {
    if (!location.search) {
      setRowsData([]);
      setDataPlaning([]);
    }
  }, [location.search]);

  return (
    <Box sx={{
      mt: '30px', width: '100%', p: '0px 15px', height: '65vh'
    }}
    >
      <Checkbox
        label="Group flights of company by Aircraft"
        value={showTable}
        onChange={(e) => setShowTable(e.target.checked)}
      />
      <DataGridPremium
        treeData
        getRowId={(row) => row.id}
        rows={dataTable || []}
        columns={columns}
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        height={110}
        onRowClick={handleRowClick}
        getRowHeight={() => 'auto'}
        getTreeDataPath={getTreeDataPath}
        loading={isLoading}
        getRowClassName={({ row }) => `${((
          row?.hierarchy?.length === 1
            && row.id !== 0
            && !idRows.includes(row.id)
        ) ? 'row-custom' : '')}
          ${row?.hierarchy?.length === 1
            && (row?.hierarchy[0] === 'Total'
            || row?.hierarchy[0] === 'Total '
              ? 'row-total'
              : '')}`}
        groupingColDef={{
          width: 250,
          headerName: 'Company',
        }}
        sx={{
          '& .MuiDataGrid-root': {
            maxHeight: 900,
            overflow: 'auto !important'
          },
          '& .MuiDataGrid-virtualScroller': {
            marginTop: '0px !important',
          },
          '& .row-custom': {
            cursor: 'pointer'
          },
          '& .row-total': {
            backgroundColor: 'rgb(240, 255, 240)',
            cursor: 'auto',
          },
          '& .row-total:hover': {
            backgroundColor: 'rgb(240, 255, 240)',
            cursor: 'auto',
          },
          '& .MuiDataGrid-cellContent': {
            wordWrap: 'break-word',
          }
        }}
      />
    </Box>
  ); };

export default Table;

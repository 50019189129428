import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import ContentPageLoader from '../../../../components/Loader/ContentPageLoader';
import PdfLogo from '../../../../components/PdfLogo';
import {
  loaderSx, tableContainer, tableSx, tableHeadSx, tableBodySx, noDataSx
} from './styles';

export const FtlTable = ({
  isLoading, columns, list, isPdf, crewName, getWrongAvail,
}) => (
  <TableContainer sx={tableContainer}>
    {isLoading && <ContentPageLoader containerSx={loaderSx} />}
    <Box id="forPdfFTL" sx={{ width: isPdf && 'fit-content', p: '30px' }}>
      {isPdf && <PdfLogo title={`FLIGHT TIME LIMITATIONS ${crewName}`} /> }
      <Table
        sx={tableSx(isPdf)}
        aria-label="sticky table"
      >
        <TableHead sx={tableHeadSx}>
          <TableRow>
            <TableCell rowSpan={2}>
              Date
            </TableCell>
            <TableCell rowSpan={2}>
              On Duty
            </TableCell>
            <TableCell rowSpan={2}>
              Off Duty
            </TableCell>
            <TableCell rowSpan={2}>
              Total Duty
            </TableCell>
            <TableCell rowSpan={2}>
              FDP
            </TableCell>
            <TableCell rowSpan={2}>
              MAX FDP
            </TableCell>
            <TableCell rowSpan={2}>
              Flight time
            </TableCell>
            <TableCell rowSpan={2}>
              Next Avail
            </TableCell>
            <TableCell colSpan={4}>
              Duty
            </TableCell>
            <TableCell colSpan={3}>
              Flight
            </TableCell>
            <TableCell rowSpan={2} colSpan={2}>
              ODS
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ maxWidth: '80px' }}>
              Last 7 Days (Limit: 60)
            </TableCell>
            <TableCell sx={{ maxWidth: '86px' }}>
              Last 14 Days (Limit: 110)
            </TableCell>
            <TableCell sx={{ maxWidth: '80px' }}>
              Last 28 Days (Limit: 190)
            </TableCell>
            <TableCell sx={{ maxWidth: '80px' }}>
              Last 12 Months (Limit: 1800)
            </TableCell>
            <TableCell sx={{ maxWidth: '80px' }}>
              Last 28 Days (Limit: 100)
            </TableCell>
            <TableCell sx={{ maxWidth: '80px' }}>
              Last Calendar Year (Limit: 900)
            </TableCell>
            <TableCell sx={{ maxWidth: '80px' }}>
              Last 12 Months (Limit: 1000)
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody sx={tableBodySx}>
          {list.length ? list.map((row, i) => (
            <TableRow key={i}>
              {columns.map(({ field, cell }) => (
                <TableCell key={field}>
                  {cell ? cell({ ...row, bg: getWrongAvail.includes(i) && '#e1e176' }) : <Box sx={{ p: '5px' }}>{row[field]}</Box>}
                </TableCell>
              ))}
            </TableRow>
          ) ) : (
            <TableRow>
              <TableCell colSpan={16}>
                <Box sx={noDataSx}>
                  NO DATA
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  </TableContainer>
);

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Card, CardContent, CardHeader, Switch,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import ApproveModal from '../../../../../../../components/ApproveModal';
import Button from '../../../../../../../components/Button';
import ContentPageLoader from '../../../../../../../components/Loader/ContentPageLoader';
import Upload from '../../../../../../../components/Upload';
import Autocomplete from '../../../../../../../form/components/Autocomplete/Autocomplete';
import DatePicker from '../../../../../../../form/components/DatePicker';
import FieldError from '../../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../../form/components/FormGroup';
import Input from '../../../../../../../form/components/Input';
import ServerAutocomplete from '../../../../../../../form/components/ServerAutocomplete';
import {
  useGetCurrencyQuery,
} from '../../../../../../../store/session';
import {
  useGetExternalOrdersListQuery,
  useGetInternalOrdersListQuery,
  useGetProformaListQuery
} from '../../../../../../../store/slices/invoiceManagementSlice';
import { useGetSupplierInfoQuery, usePostPaymentRequestMutation, useSearchSupplierQuery } from '../../../../../../../store/slices/logisticsSlice';
import { getOptions } from '../../../../../../../utils/getOptions';
import { setDateValue, validateDatePickerValue } from '../../../../../../../utils/setDateValue';
import { defaultValues, schema } from './schema';
import {
  button, buttonBlock,
  block as currentBlock,
  documentForm, file, fileName, label
} from './style';

export const orderMathRoundSum = (count) => {
  if (count < 1) return 1;

  return Math.round(count);
};

export const countInvoiceSum = (array = []) => {
  let sum = 0;

  array.forEach(({ part_count, part_price }) => {
    const count = orderMathRoundSum(part_count);
    const orderSum = count * +part_price;

    sum += orderSum;
  });

  return sum;
};

export const defaultOrder = (od, typeBoard) => ({
  currency_id: od?.prices?.[0]?.currency_id,
  label: `Order #${(typeBoard === 'orders') ? od?.id : `${od?.id}D`}`,
  part_count: orderMathRoundSum(od?.part_count),
  part_price: od?.prices?.[0]?.amount,
  value: od?.id
});

const InvoicesForm = ({
  order, variant, drawerClose, dashboardType = 'orders'
}) => {
  const [nameFile, setNameFile] = useState();
  const [supplier, setSupplier] = useState(null);
  const refForm = useRef(null);
  const [approveModalState, setApproveModalState] = useState({
    isOpen: false,
    callback: () => {}
  });
  const [vendorNameFile, setVendorNameFile] = useState(null);
  const [disabledOrdersAutocomplete, setDisabledOrdersAutocomplete] = useState(false);
  const [firstAttachedOrder, setFirstAttachedOrder] = useState(null);

  const [selectOrders, setSelectOrders] = useState(order ? [defaultOrder(order, dashboardType)] : []);
  const { data: currency } = useGetCurrencyQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  const { data: currentOrdersList, isLoading: isLoadingCurrentOrders } = useGetExternalOrdersListQuery({ invoice_type: variant, supplier_id: order?.supplier_id ? order.supplier_id : '' }, {
    refetchOnMountOrArgChange: true,
    skip: dashboardType === 'delivery'
  });

  const { data: internalOrdersList, isLoading: isLoadingInternalOrders } = useGetInternalOrdersListQuery({ invoice_type: variant, get_all: order ? 1 : 0 }, {
    refetchOnMountOrArgChange: true,
    skip: dashboardType === 'orders'
  });

  const { data: getSupplier, isLoading } = useGetSupplierInfoQuery({ id: (variant !== 'delivery') ? selectOrders?.[0]?.supplier_id : selectOrders?.[0]?.delivery_operator_id }, {
    refetchOnMountOrArgChange: true,
    skip: !!order || ((variant !== 'delivery') ? !selectOrders?.[0]?.supplier_id : !selectOrders?.[0]?.delivery_operator_id)
  });

  const { data: proforma } = useGetProformaListQuery({ type: dashboardType === 'orders' ? 'external' : 'internal' }, {
    refetchOnMountOrArgChange: true
  });

  const isLoadingOrders = (dashboardType === 'orders') ? isLoadingCurrentOrders : isLoadingInternalOrders;
  const ordersList = (dashboardType === 'orders') ? currentOrdersList : internalOrdersList;
  const checkCurrentOrder = (ordersList || []).some(item => item?.value === order?.id);
  const [sendInvoice] = usePostPaymentRequestMutation();
  const block = order ? currentBlock : { ...currentBlock, mb: '15px' };
  const currencyOptions = getOptions(currency);
  const proformaOptions = proforma?.map(item => ({ value: item.id, label: item.id, orders: (dashboardType === 'orders') ? item.orders : item.internal_orders }));

  const {
    handleSubmit,
    setValue,
    trigger,
    reset,
    getValues,
    control,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const uploadFile = (e) => {
    setNameFile(e.target.files[0].name);

    setValue('file', e.target.files[0]);
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const ordersIds = data.orders.map(({ value }) => value);
    const formData = new FormData();

    formData.append('description', data.description);
    formData.append('invoice_number', data.invoice_number);
    formData.append('invoice_name', data.invoice_name);
    formData.append('file', data.file);
    if (data.vendor_upload_files) {
      Object.keys(data.vendor_upload_files)?.forEach((key) => {
        formData.append(`vendor_upload_files[${key}]`, data.vendor_upload_files[key]);
      });
    }
    formData.append('invoice_sum', data.invoice_sum);
    formData.append('currency_id', data.currency_id);
    formData.append('payment_type', variant);
    formData.append('due_payment_date', data.due_payment_date);
    formData.append('orders', JSON.stringify(ordersIds));
    if (supplier) formData.append('supplier_id', supplier?.data?.id);
    if (variant !== 'delivery') {
      formData.append('is_proforma', data.is_proforma ? 1 : 0);
    }

    if (dashboardType !== 'orders') {
      formData.append('is_internal', true);
    } else if (data.vendor_payout_amount) {
      formData.append('vendor_payout_amount', data.vendor_payout_amount);
      formData.append('vendor_payout_description', data.vendor_payout_description);
    }

    if (data.parent_id?.value && !data.is_proforma) {
      formData.append('parent_id', data.parent_id?.value);
    }

    const res = await sendInvoice({ data: formData });

    if (res.error) {
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    toast.success('The invoice has been sent!', {
      id: toastId,
    });

    reset();

    setValue('payment_purpose', {});
    setValue('currency', {});
    setValue('parent_id', {});
    setValue('payment_purpose_id', {});
    setValue('currency_id', {});
    setValue('orders', (order && checkCurrentOrder) ? [defaultOrder(order, dashboardType)] : []);
    document.getElementById('file-upload').value = '';
    setNameFile(null);

    if (!order) drawerClose();
  };

  const handleOpenApproveModal = (isOpen = false, callback = () => {}) => {
    setApproveModalState(prev => ({
      ...prev,
      isOpen,
      callback
    }));
  };

  const changeDescription = (checkedOrders = []) => {
    let decr = 'Order ID: ';

    checkedOrders.forEach((item) => {
      decr += ` #${(dashboardType === 'orders') ? item.value : `${item.value}D`},`;
    });

    return decr;
  };

  const generateDescription = () => `Order ID:  ${order ? `#${(dashboardType === 'orders') ? order?.id : `${order?.id}D`},` : ''}\n`;

  useEffect(() => {
    if (currency) {
      if (order && variant === 'part') {
        setValue('invoice_sum', ((order?.prices?.[0]?.amount || 0) * orderMathRoundSum(order?.part_count)));
        setValue('currency', currencyOptions?.filter((item) => item.value == order?.prices?.[0]?.currency_id)[0] || '');
        setValue('currency_id', order?.prices?.[0]?.currency_id);
      }

      if (order) setValue('orders', (order && checkCurrentOrder) ? [defaultOrder(order, dashboardType)] : []);
      setValue('description', generateDescription());
    }
    if (ordersList && !checkCurrentOrder) setSelectOrders([]);
  }, [currency, ordersList, checkCurrentOrder, dashboardType, order]);

  useEffect(() => {
    if (order?.delivery_operator?.id && (variant === 'delivery')) {
      setValue('invoice_name', order?.delivery_operator?.name);
      setSupplier({ value: order?.delivery_operator?.id, label: order?.delivery_operator?.name, data: order?.delivery_operator });
    }
    if ((variant !== 'delivery') && (order?.prices?.[0]?.supplier_data)) {
      setValue('invoice_name', order?.prices?.[0]?.supplier_data?.name);
      setSupplier({ value: order?.prices?.[0]?.supplier_data?.id, label: order?.prices?.[0]?.supplier_data?.name, data: order?.prices?.[0]?.supplier_data });
    }
    if (order?.address_to_supplier?.id && (
      variant === 'overhaul' || variant === 'repair' || variant === 'exchange'
    )) {
      setValue('invoice_name', order?.address_to_supplier?.name);
      setSupplier({ value: order?.address_to_supplier?.id, label: order?.address_to_supplier?.name, data: order?.address_to_supplier });
    }
  }, [order]);

  useEffect(() => {
    if (getSupplier && !order) {
      setSupplier({
        value: getSupplier?.id,
        label: getSupplier?.name,
        data: getSupplier
      });
      setValue('invoice_name', getSupplier?.name);
    }
  }, [getSupplier]);

  useEffect(() => {
    if (!order) {
      reset();
      setNameFile(null);
      setSupplier(null);
      setSelectOrders(order ? [defaultOrder(order, dashboardType)] : []);
      setFirstAttachedOrder(null);
    }
  }, [variant]);

  const changeOrders = (value, field = null) => {
    if (field) {
      field.onChange(value);
    } else {
      setDisabledOrdersAutocomplete(true);
    }
    setValue('orders', value);
    setValue('description', changeDescription(value));
    if (variant === 'part') setValue('invoice_sum', countInvoiceSum(value));
    trigger('orders', value);
    setSelectOrders(value);
  };

  if (isLoadingOrders) {
    return (
      <Card>
        <ContentPageLoader />
      </Card>
    );
  }

  const handleChangeOrderList = (attachedOrders) => {
    if (order) {
      return;
    }
    if (attachedOrders.length && !firstAttachedOrder) {
      setFirstAttachedOrder(attachedOrders[0]);
    }
    if (!attachedOrders.length) {
      setFirstAttachedOrder(null);
    }
  };

  const vendorUploadFile = (e) => {
    const countFiles = e.target.files.length;
    setVendorNameFile(countFiles > 1 ? `${countFiles} files selected` : e.target.files[0].name);
    setValue('vendor_upload_files', e.target.files);
  };

  return (
    <Card sx={{ mt: order ? 5 : 0, border: order ? '1px solid whitesmoke' : 'none' }}>
      {!!order && <CardHeader title={`Create New ${variant} Invoice Request`} sx={{ textTransform: 'capitalize' }} />}
      <CardContent sx={{ mt: order ? 5 : 0 }}>
        <form onSubmit={handleSubmit(onSubmit)} ref={refForm}>
          <Box sx={documentForm}>

            <Box sx={{ ...file, position: 'relative' }}>
              <FormGroup sx={label} required label="File" hasError={!!errors.file}>
                <Controller
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <Upload
                      {...field}
                      accept=".doc,.docx,image/*,.pdf"
                      handleUpload={(e) => {
                        field.onChange(e);
                        uploadFile(e);
                      }}
                      title="Upload file"
                    />
                  )}
                />
                <FieldError error={errors.file} />
              </FormGroup>
              <Typography sx={fileName}>{nameFile}</Typography>
            </Box>

            {variant !== 'delivery' && (
              <Box sx={{ ...block, position: 'relative' }}>
                <FormGroup label="Proforma" hasError={!!errors?.is_proforma}>
                  <Controller
                    name="is_proforma"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        onChange={() => {
                          setValue('is_proforma', !watch('is_proforma'));
                          setValue('parent_id', null);
                          setDisabledOrdersAutocomplete(false);
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors?.is_proforma} />
                </FormGroup>
              </Box>
            )}

            <Box sx={{ ...block, position: 'relative' }}>
              <FormGroup sx={label} label="Description" required hasError={!!errors.description}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      rows={8}
                      multiline
                      variant="filled"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
                <FieldError error={errors.description} />
              </FormGroup>
            </Box>

            <Box sx={{ ...block, position: 'relative' }}>
              <FormGroup sx={label} label="Invoice Number" required hasError={!!errors.invoice_number}>
                <Controller
                  name="invoice_number"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
                <FieldError error={errors.invoice_number} />
              </FormGroup>
            </Box>

            <Box sx={{ ...block, position: 'relative' }}>
              <FormGroup sx={label} label="Supplier" required hasError={!!errors.invoice_name}>
                <Controller
                  name="invoice_name"
                  control={control}
                  render={({ field }) => (
                    <ServerAutocomplete
                      value={supplier}
                      withData
                      placeholder="Search suppliers by name or email"
                      searchQueryFn={useSearchSupplierQuery}
                      handleSelect={(value) => {
                        field.onChange(value?.label);
                        setSupplier(value);
                      }}
                    />
                  )}
                />
                <FieldError error={errors.invoice_name} />
              </FormGroup>
            </Box>

            <Box sx={{ ...block, position: 'relative' }}>
              <FormGroup sx={label} label="Deadline Payment" required hasError={!!errors.due_payment_date}>
                <Controller
                  name="due_payment_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="YYYY-MM-DD"
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setDateValue(e, setValue, 'due_payment_date', 'YYYY-MM-DD');
                      }}
                      value={validateDatePickerValue(field.value)}
                    />
                  )}
                />
                <FieldError error={errors.due_payment_date} />
              </FormGroup>
            </Box>

            <Box sx={{ ...block, position: 'relative' }}>
              <FormGroup sx={label} label="SUM" required hasError={!!errors.invoice_sum}>
                <Controller
                  name="invoice_sum"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
                <FieldError error={errors.invoice_sum} />
              </FormGroup>
            </Box>

            <Box sx={{ ...block, position: 'relative' }}>
              <FormGroup sx={label} required label="Currency" hasError={!!errors.currency}>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={field.value ? currencyOptions?.filter((item) => item.value === field?.value?.value)[0] : null}
                      options={currencyOptions}
                      size="small"
                      placeholder="Please select a currency"
                      onChange={(e, value ) => {
                        field.onChange(value);
                        setValue('currency', value || null);
                        setValue('currency_id', value?.value || null);
                        trigger('currency', value);
                      }}
                    />
                  )}
                />
                <FieldError error={errors.currency} />
              </FormGroup>
            </Box>

            {(!watch('is_proforma') && variant !== 'delivery') && (
              <Box sx={{ ...block, position: 'relative' }}>
                <FormGroup sx={label} label="Proforma" hasError={!!errors.parent_id}>
                  <Controller
                    name="parent_id"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        value={field.value ? proformaOptions?.filter((item) => item.value === field?.value?.value)[0] : null}
                        options={proformaOptions}
                        size="small"
                        placeholder="Select a proforma"
                        onChange={(e, value ) => {
                          field.onChange(e);
                          setValue('parent_id', value);
                          const findProforma = proformaOptions?.find(item => item.value === value?.value);
                          if (findProforma && findProforma.orders) {
                            changeOrders(findProforma.orders);
                          } else {
                            setDisabledOrdersAutocomplete(false);
                          }
                        }}
                      />
                    )}
                  />
                  <FieldError error={errors.parent_id} />
                </FormGroup>
              </Box>
            )}

            <Box sx={{ ...block, position: 'relative' }}>
              <FormGroup sx={label} label="Attach other orders" hasError={!!errors.orders}>
                <Controller
                  name="orders"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={selectOrders}
                      options={firstAttachedOrder ? ordersList.filter((item) => firstAttachedOrder.supplier_id === item.supplier_id) : [...(ordersList || [])]}
                      size="small"
                      multiple
                      placeholder=""
                      disabled={disabledOrdersAutocomplete}
                      onChange={(e, value ) => {
                        if (disabledOrdersAutocomplete) {
                          return;
                        }
                        handleChangeOrderList(value);
                        const isSome = order && value.some(od => od?.value
                          === order?.id);

                        if (isSome || !order) {
                          changeOrders(value, field);
                        } else if (!isSome) {
                          const newValue = checkCurrentOrder ? [defaultOrder(order, dashboardType), ...value] : value;
                          changeOrders(newValue, field);
                        }
                      }}
                    />
                  )}
                />
                <FieldError error={errors.orders} />
              </FormGroup>
            </Box>

            {(!watch('is_proforma') && variant === 'part') && (
              <Box sx={{ ...block, position: 'relative' }}>
                <FormGroup sx={label} label={`Vendor Payout Amount${order?.prices?.[0]?.supplier?.currency?.currency ? ` - ${order?.prices?.[0]?.supplier?.currency?.currency}` : ''}`} hasError={!!errors.vendor_payout_amount}>
                  <Controller
                    name="vendor_payout_amount"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                  <FieldError error={errors.vendor_payout_amount} />
                </FormGroup>
              </Box>
            )}

            {(!watch('is_proforma') && variant === 'part' && watch('vendor_payout_amount')) && (
              <>
                <Box sx={{ ...block, position: 'relative' }}>
                  <FormGroup sx={label} label="Vendor Payout Description" hasError={!!errors.vendor_payout_description}>
                    <Controller
                      name="vendor_payout_description"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                    <FieldError error={errors.vendor_payout_description} />
                  </FormGroup>
                </Box>
                <Box sx={file}>
                  <FormGroup sx={label} label="Vendor Files" hasError={!!errors.vendor_upload_files}>
                    <Controller
                      name="vendor_upload_files"
                      control={control}
                      render={({ field }) => (
                        <Upload
                          {...field}
                          accept=".doc,.docx,image/*,.pdf"
                          multiple
                          handleUpload={(e) => {
                            field.onChange(e);
                            vendorUploadFile(e);
                          }}
                          title="Upload file"
                        />
                      )}
                    />
                    <FieldError error={errors.vendor_upload_files} />
                  </FormGroup>
                  <Typography sx={fileName}>{vendorNameFile}</Typography>
                </Box>
              </>
            )}

            <Box sx={{ ...buttonBlock, textAlignLast: order ? 'center' : 'start' }}>
              <Button
                sx={{ ...button, ...(!order && { mt: 2 }) }}
                title={order ? 'Send' : 'Create'}
                type={(watch('orders')?.length < 2) ? 'submit' : 'button'}
                onClick={(watch('orders')?.length > 1) ? () => handleOpenApproveModal(true) : () => {}}
              />
            </Box>
          </Box>
          <ApproveModal
            open={approveModalState.isOpen}
            onClose={handleOpenApproveModal}
            data={{
              text: 'Check the value in the ‘SUM’ field!',
              successButton: 'Create'
            }}
            onApprove={() => {
              if (refForm) {
                refForm?.current?.requestSubmit();
              }
              handleOpenApproveModal();
            }}
            refetch={() => {}}
            sxBlockBtn={{ display: 'flex', justifyContent: 'space-between' }}
          />
        </form>
      </CardContent>

    </Card>
  );
};

export default InvoicesForm;

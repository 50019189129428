export const SimpleList = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 0
};

export const FormHeader = {
  ...(SimpleList),
  alignContent: 'flex-center',
  flexDirection: 'column'
};

export const SwitchContent = {
  ...(SimpleList),
  alignItems: 'baseline',
  justifyContent: 'space-between',
};

import React from 'react';
import {
  Card,
  Grid,
  CardContent,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useAddCrewMutation, useGetAircraftListQuery } from '../../../store/session';
import { schema, defaultValues } from './schema';
import FieldError from '../../../form/components/FieldError';
import FormGroup from '../../../form/components/FormGroup';
import Input from '../../../form/components/Input';
import Checkbox from '../../../form/components/Checkbox/Checkbox';
import Select from '../../../form/components/Select';
import DatePicker from '../../../form/components/DatePicker';
import Button from '../../../components/Button';
import { selectOptionFormat } from '../../../utils/number';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { AIRCRAFT_TYPE_LIST, CREW_POST_OPTIONS } from '../../../constans/crew';

const AddCrew = () => {
  const navigate = useNavigate();

  const { data: AircraftList, isLoading: aircraftListLoad } = useGetAircraftListQuery();
  const [addCrew] = useAddCrewMutation();

  const formattedAircraftList = selectOptionFormat(AircraftList, 'aircraft_registration');

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    await addCrew({
      ...data,
      crew_birth: moment(data.crew_birth).format('YYYY-MM-DD'),
      crew_expires: data.crew_expires ? moment(data.crew_expires).format('YYYY-MM-DD') : ''
    }).then((res) => {
      if (!res?.error) {
        toast.success('Crew was added!', {
          id: toastId
        });
        navigate(`/directories/crew/update/${res.data?.id}`);
      } else {
        toast.error(res.error.data?.message, {
          id: toastId
        });
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Grid container spacing={3} rowSpacing={2} sx={{ flexGrow: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormGroup label="Name" required hasError={!!errors.crew_name}>
                <Controller
                  name="crew_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Name"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.crew_name} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormGroup label="ROSTER ACTIVE">
                <Controller
                  name="crew_active"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormGroup label="Reg">
                <Controller
                  name="crew_aircraft_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={formattedAircraftList}
                      placeholder="Reg"
                      disabled={aircraftListLoad}
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormGroup label="Post" required hasError={!!errors.crew_post}>
                <Controller
                  name="crew_post"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={CREW_POST_OPTIONS}
                      placeholder="Post"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.crew_post} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormGroup label="Shortname" required hasError={!!errors.crew_shortname}>
                <Controller
                  name="crew_shortname"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Shortname"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.crew_shortname} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormGroup label="Birthday" required hasError={!!errors.crew_birth}>
                <Controller
                  name="crew_birth"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="YYYY-MM-DD"
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.crew_birth} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormGroup label="Passport" required hasError={!!errors.crew_pasport}>
                <Controller
                  name="crew_pasport"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Passport"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.crew_pasport} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormGroup label="Expires">
                <Controller
                  name="crew_expires"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="YYYY-MM-DD"
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormGroup label="Nationality">
                <Controller
                  name="crew_nationality"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Nationality"
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormGroup label="Home base">
                <Controller
                  name="crew_home_base"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Home base"
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormGroup required label="Email" hasError={!!errors.crew_email}>
                <Controller
                  name="crew_email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Email"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.crew_email} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormGroup label="Licence">
                <Controller
                  name="crew_licence"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Licence"
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormGroup label="Aircraft type">
                <Controller
                  name="crew_acft_type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={AIRCRAFT_TYPE_LIST}
                      placeholder="Aircraft type"
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="LC">
                <Controller
                  name="crew_line_check"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="WO Can Perform">
                <Controller
                  name="crew_wo_can_perform"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="WO Can Close">
                <Controller
                  name="crew_wo_can_close"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid
              item
              sx={{
                display: 'flex',
                gap: '9px',
              }}
              xs={12}
            >

              <Button type="submit" variant="contained" title="Create" size="large" />

              <Button
                title="Cancel"
                size="large"
                onClick={() => {
                  navigate('/directories/crew');
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default AddCrew;

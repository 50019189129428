import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import React from 'react';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import {
  HeaderName,
  StyledDelete,
  StyledPencil,
} from '../../../components/EditableTable/styles';
import LinkButton from '../../../components/LinkButton';

export const columns = ({
  handleOpenDeleteModal,
  handleFilter,
  filters,
  hasWritePermission,
  handleAccessError
}) => ([
  {
    flex: 0.3,
    field: 'id',
    minWidth: 160,
    headerName: 'Supplier ID',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Supplier ID</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.id || ''}
          value="id"
          placeholder="Supplier ID"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize', fontWeight: '600' }}
      >
        {`#${row.id}`}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'name',
    minWidth: 180,
    headerName: 'Name',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters?.name || ''}
          value="name"
          placeholder="Name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
      >
        {row?.name ?? 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.4,
    minWidth: 180,
    field: 'email',
    headerName: 'Email',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Email</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.email || ''}
          value="email"
          placeholder="Email"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
      >
        {row?.email ?? 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'actual_address',
    minWidth: 180,
    headerName: 'Actual Address',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Actual Address</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          value="actual_address"
          defaultValue={filters?.actual_address || ''}
          placeholder="Actual Address"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize', whiteSpace: 'break-spaces', }}>
        <Chip label={row?.actual_address ?? 'N/A'} variant="outlined" />
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'delivery_address',
    minWidth: 180,
    headerName: 'Delivery Address',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Delivery Address</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.delivery_address || ''}
          value="delivery_address"
          placeholder="Delivery Address"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
      >
        {row?.delivery_address}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'vendor_balance',
    minWidth: 180,
    headerName: 'Vendor Balance',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Vendor Balance</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.vendor_balance || ''}
          value="vendor_balance"
          placeholder="Vendor Balance"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
      >
        {`${row?.vendor_balance ?? 0} ${row?.currency ?? ''}`}
      </Typography>
    ),
  },
  {
    flex: 0.4,
    minWidth: 180,
    field: 'phone',
    headerName: 'Phone',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Phone</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.phone || ''}
          value="phone"
          placeholder="Phone"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
      >
        {row?.phone ?? 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'fax',
    minWidth: 180,
    headerName: 'Fax',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Fax</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.fax || ''}
          value="fax"
          placeholder="Fax"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.fax ?? 'N/A'}
      </Typography>
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <LinkButton path={`/logistics/vendors/${row.id}`}>
          <StyledPencil />
        </LinkButton>
        {'  '}
        <StyledDelete onClick={() => {
          if (!hasWritePermission) handleAccessError();
          else handleOpenDeleteModal(row.id);
        }}
        />
      </>
    ),
  }, // actions
]);

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { HeaderName, StyledDelete } from '../../../../../components/EditableTable/styles';
import { STATES } from './constants';

export const columns = ({
  handleOpenDeleteModal,
  handleOpenFile
}) => ([
  {
    flex: 0.1,
    field: 'id',
    minWidth: 130,
    headerName: 'File ID',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Typography style={HeaderName}>File ID</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{`#${row.id}`}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'file_name',
    minWidth: 130,
    headerName: 'File Name',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Typography style={HeaderName}>File Name</Typography>
      </Box>
    ),
    renderCell: ({ row }) => {
      if (row.state === 'generated') {
        return (<Typography sx={{ cursor: 'pointer', fontWeight: 'bold', textDecorationLine: 'underline' }} onClick={() => handleOpenFile(row?.id)}>{`${row.file_name}`}</Typography>);
      }

      return (<Typography>{`${row.file_name}`}</Typography>);
    }
  },
  {
    flex: 0.2,
    field: 'state',
    minWidth: 130,
    headerName: 'Status',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Typography style={HeaderName}>Status</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{STATES.find((item) => item.value === row?.state)?.icon}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'generated_by',
    minWidth: 130,
    headerName: 'Generated By',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Typography style={HeaderName}>Generated By</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{`${row.generated_by}`}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'created_at',
    minWidth: 130,
    headerName: 'Generated By',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Typography style={HeaderName}>Created at</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{`${row.created_at}`}</Typography>
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
      </Box>
    ),
  }, // actions
]);

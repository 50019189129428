import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  FormControl, FormHelperText, InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

const AircraftForm = (props) => {
  const {
    control,
    Controller,
    errors,
    AircraftList,
  } = props;

  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader title="Aircraft" />
      <CardContent sx={{ mt: 5 }}>
        <FormControl fullWidth>
          <InputLabel
            id="validation-basic-select"
            error={Boolean(errors.planing_reg)}
            htmlFor="validation-basic-select"
          >
            Registration Name
          </InputLabel>
          <Controller
            name="planing_reg"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label="Registration Name"
                onChange={onChange}
                error={Boolean(errors.planing_reg)}
                labelId="validation-basic-select"
                aria-describedby="validation-basic-select"
              >
                {AircraftList?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.aircraft_registration}</MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.planing_reg && (
            <FormHelperText sx={{ color: 'error.main' }} id="validation-basic-select">
              This field is required
            </FormHelperText>
          )}
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default AircraftForm;

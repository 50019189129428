
import {
  object, string, number, array, boolean
} from 'yup';

export const defaultValues = {
  company_name: '',
  company_address: '',
  company_biladdress: '',
  company_vat: '',
  company_registration: '',
  company_shortname: '',
  company_phone: '',
  company_fax: '',
  company_email: '',
  company_inv_email: '',
  company_website: '',
  persons: [
    {
      'person_name': '',
      'person_phone': '',
      'person_email': '',
      'person_skype': '',
    },
  ]
};

export const schema = object().shape({
  company_name: string()
    .nullable()
    .required('${label} cannot be blank.')
    .max(255)
    .min(3)
    .label('Company name'),
  company_address: string()
    .nullable()
    .label('Company address'),
  company_biladdress: string()
    .nullable()
    .label('Company biladdress'),
  company_vat: string()
    .nullable()
    .label('Company VAT'),
  company_registration: string()
    .nullable()
    .label('Company Registration'),
  company_shortname: string()
    .nullable()
    .label('Company shortname'),
  company_phone: string()
    .nullable()
    .label('Phone'),
  company_fax: string()
    .nullable()
    .label('Fax'),
  company_email: string()
    .nullable()
    .label('Company Email'),
  company_inv_email: string()
    .nullable()
    .label('Company Invoice Email'),
  company_website: string()
    .nullable()
    .label('Company website'),
  persons: array().of(object().shape({
    person_name: string().nullable().required('${label} cannot be blank.').label('Person name'),
    person_phone: string().nullable().required('${label} cannot be blank.').label('Person phone'),
    person_email: string().nullable(),
    person_skype: string().nullable(),
  }))
});

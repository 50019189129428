import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetFlightMutation } from '../../../../../../../store/session';
import { getTimeDiffHm } from '../../../../../../../utils/getTime';

const BLHTimeVariables = ['pl_hop_blockoff', 'pl_hop_blockon'];
const FLTimeVariables = ['pl_hop_blockoff', 'pl_hop_blockon'];

export const useTimeForm = () => {
  const [_, { data: flightData }] = useGetFlightMutation({
    fixedCacheKey: 'get-flight-data',
  });

  const {
    control, Controller, setValue, watch,
  } = useFormContext();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const field = name.split('.');

      if (BLHTimeVariables.includes(field[2]) && type === 'change') {
        const blockOff = Number.isInteger(value.planing_hop[field[1]].pl_hop_blockoff) ? new Date(value.planing_hop[field[1]].pl_hop_blockoff).getTime() * 1000 : value.planing_hop[field[1]].pl_hop_blockoff;
        const blockOn = Number.isInteger(value.planing_hop[field[1]].pl_hop_blockon) ? new Date(value.planing_hop[field[1]].pl_hop_blockon).getTime() * 1000 : value.planing_hop[field[1]].pl_hop_blockon;

        setValue(`planing_hop.${field[1]}.pl_hop_blocktime`, getTimeDiffHm(blockOff, blockOn));
      }

      if (FLTimeVariables.includes(field[2]) && type === 'change') {
        const takeOff = Number.isInteger(value.planing_hop[field[1]].pl_hop_takeoff) ? new Date(value.planing_hop[field[1]].pl_hop_takeoff).getTime() * 1000 : value.planing_hop[field[1]].pl_hop_takeoff;
        const takeOn = Number.isInteger(value.planing_hop[field[1]].pl_hop_takeon) ? new Date(value.planing_hop[field[1]].pl_hop_takeon).getTime() * 1000 : value.planing_hop[field[1]].pl_hop_takeon;

        setValue(`planing_hop.${field[1]}.pl_hop_taketime`, getTimeDiffHm(takeOff, takeOn));
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  return {
    flightData,
    setValue,
    Controller,
    control,
  };
};

import {
  object, string,
} from 'yup';

export const defaultValues = {
  email: ''
};

export const schema = object().shape({
  email: string().email().required('${label} cannot be blank.').label('Email').nullable(),
});

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Button from '../../../../../../components/Button';
import Drawer from '../../../../../../components/Drawer';
import FieldError from '../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../form/components/FormGroup';
import ServerAutocomplete from '../../../../../../form/components/ServerAutocomplete';
import {
  useAssignWOTaskMutation,
  useSearchWOTaskQuery,
} from '../../../../../../store/session';
import { schema } from './schema';
import { cancelSx, drawer, footerSx } from './styles';

const TaskDrawer = ({
  open, onClose, taskListOpt, aircraft_id 
}) => {
  const params = useParams();

  const [assignWoTask, { isLoading }] = useAssignWOTaskMutation();
  const {
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
    trigger,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      maint_id: { value: null, label: '' }
    },
  });

  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const handleSelectJob = (selection) => {
    const option = selection || { value: null, label: '' };

    setValue('maint_id', option || null);
    if (option?.value) trigger('maint_id');
  };

  const handleAssignTask = async (data) => {
    const toastId = toast.loading('Loading...');

    const res = await assignWoTask({
      maint_id: data?.maint_id?.value,
      wo_id: params.id,
    });

    if (res?.error) {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    reset();
    onClose();

    toast.success('Successfully!', {
      id: toastId,
    });
  };

  return (
    <Drawer
      title="Assign WO Task"
      open={open}
      onClose={onClose}
      sx={{ ...(maxWidth800px && drawer) }}
    >
      <form onSubmit={handleSubmit(handleAssignTask)}>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          sx={{ p: '20px 20px 0 30px' }}
        >
          <Grid item xs={12}>
            <FormGroup required label="Task" hasError={!!errors.maint_id}>
              <Controller
                name="maint_id"
                control={control}
                render={({ field }) => (
                  <ServerAutocomplete
                    fields={field}
                    placeholder="Search Task"
                    disabled={!aircraft_id}
                    searchQueryFn={useSearchWOTaskQuery}
                    queryParams={{ aircraft_id }}
                    handleSelect={handleSelectJob}
                    savedOptions={false}
                  />
                )}
              />
              <FieldError error={errors.maint_id} />
            </FormGroup>
          </Grid>
        </Grid>

        <Box sx={footerSx}>
          <Button
            disabled={isLoading}
            variant="contained"
            title="Assign Task"
            type="submit"
          />
          <Button sx={cancelSx} title="Close" onClick={onClose} />
        </Box>
      </form>
    </Drawer>
  );
};

export default TaskDrawer;

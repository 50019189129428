import React from 'react';
import Flight from './Flight';
import { useOdsPositionForm } from './useOdsPositionForm';

const OdsPositionForm = ({
  isOffline, data, isSubmit, setIsSubmit
}) => {
  const {
    control,
    pilotNameOptions,
    getValues,
    watch,
    trigger,
    setValue,
    setCrewPost
  } = useOdsPositionForm({
    data, isSubmit, setIsSubmit, isOffline
  });

  return (
    <Flight
      control={control}
      pilotNameOptions={pilotNameOptions}
      setValue={setValue}
      trigger={trigger}
      setCrewPost={setCrewPost}
      getValues={getValues}
      watch={watch}
    />
  );
};

export default OdsPositionForm;

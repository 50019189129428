import React, { useState } from 'react';
import moment from 'moment';
import {
  Box, Card, Divider, useMediaQuery, Typography
} from '@mui/material';
import Button from '../../../components/Button';
import LinkButton from '../../../components/LinkButton';
import ButtonSelect from '../../../components/ButtonSelect';
import EditableTable from '../../../components/EditableTable';
import { DEFAULT_MONTH, DEFAULT_YEAR } from '../../SabCrewRoster/constants';
import { MONTH_SHORT } from '../../MainDeckSchedule/constants';
import { CAPTAIN, CO_PILOTS } from '../../../constans/crew';
import Drawer from '../../../components/Drawer';
import { columns } from './columns';
import {
  useGetTrainingScheduleQuery,
  useUpdateTrainingScheduleMutation,
  useCreateTrainingScheduleMutation,
  useDeleteTrainingScheduleMutation,
  useGetCrewListByPostQuery,
  useGetCrewListWithParametersQuery
} from '../../../store/session';
import toast from 'react-hot-toast';
import TrainingForm from './TrainingForm/TrainingForm';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import {
  headerBtn,
  headerBtnS,
  headerBtnXs,
  btnS,
  tableContainer
} from './styles';

const getDateState = (stringDate) => {
  const dateMoment = moment(stringDate);
  let res = '';
  if (dateMoment.isBefore(moment(), 'day')) {
    res = 'prev';
  } else if (dateMoment.isSame(moment(), 'day')) {
    res = 'current';
  } else {
    res = 'next';
  }

  return res;
};

const TrainingSchedule = () => {
  const maxWidth1000px = useMediaQuery('(max-width:1275px)');
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const [year, setYear] = useState(DEFAULT_YEAR);
  const [months, setMonths] = useState(DEFAULT_MONTH);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [createTraining, { isSuccess }] = useCreateTrainingScheduleMutation();
  const [refetch, setRefetch] = useState(0);

  const refetcher = () => {
    setRefetch(refetch + 1);
  };

  const { data: crewList, isLoading } = useGetCrewListWithParametersQuery(`?post=${CAPTAIN},${CO_PILOTS}&crew_active=all`);

  const PARTICIPANTS_OPTIONS = isLoading ? [] : crewList.map((item) => ({ label: `${item.crew_shortname} ${item.crew_name}`, crew_id: item.id }));

  const hasPermissions = usePermissions('training_module_write');

  const onSubmit = async (values) => {
    const toastId = toast.loading('Loading...');

    const mappedValues = {
      ...values,
      training_date: moment(values.training_date).format('YYYY-MM-DD'),
      training_start: values.training_start ? moment(values.training_start).format('YYYY-MM-DD HH:mm:ss') : '',
      training_end: values.training_end ? moment(values.training_end).format('YYYY-MM-DD HH:mm:ss') : '',
      participants: values.participants?.map((item) => ({ crew_id: item.value }))
    };

    const res = await createTraining(mappedValues);
    refetcher();
    if (res.error) {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }
    toast.success('Training was created!', {
      id: toastId
    });
    setOpenDrawer(false);
  };

  const numberMonths = MONTH_SHORT.reduce((total, amount, index) => {
    const monthIndex = [...total, months.includes(amount) && index + 1];

    return monthIndex.filter((e) => e);
  }, []);

  if (isLoading) return <ContentPageLoader />;

  return (
    <>
      <LinkButton path="/training-module">
        <Button title="Back"></Button>
      </LinkButton>
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box
          sx={{ ...headerBtn, ...(maxWidth1000px && headerBtnS) }}
        >
          <Box>
            <Typography variant="h3">Training Schedule</Typography>
          </Box>

          <Box sx={{ ...(maxWidth800px && headerBtnXs) }}>
            <Button sx={{ ml: 2, ...(maxWidth800px && btnS) }} disabled={!hasPermissions} title="Create New" onClick={() => setOpenDrawer(true)} />
          </Box>
        </Box>

        <Divider sx={{ mt: 5, mb: 3 }} />
        <ButtonSelect
          year={year}
          months={months}
          setYear={setYear}
          setMonths={setMonths}
          disabledSendRoster={false}
        />
        <Divider />

        <Box sx={tableContainer}>
          <EditableTable
            useHook={useGetTrainingScheduleQuery}
            useHookUpdate={useUpdateTrainingScheduleMutation}
            useHookDelete={useDeleteTrainingScheduleMutation}
            getRowId={(row) => row.id}
            getRowClassName={(params) => `row-${getDateState(params.row.training_date)}`}
            columns={columns}
            hasPermissions={hasPermissions}
            sortByType="DESC"
            sortByActive="training_date"
            getRowHeight={() => 'auto'}
            tableParams={{
              year,
              months: numberMonths
            }}
            customIsLoading={isLoading}
            participantsOptions={PARTICIPANTS_OPTIONS}
            refetchTable={refetch}
          />
        </Box>
      </Card>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <TrainingForm
          options={PARTICIPANTS_OPTIONS}
          onSubmit={onSubmit}
        />
      </Drawer>
    </>

  );
};

export default TrainingSchedule;

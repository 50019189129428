import React from 'react';
import { Box, Card, useMediaQuery } from '@mui/material';
import { ButtonStyle, TitleContainer } from '../../Crew/styles';
import LinkButton from '../../../components/LinkButton';
import EditableTable from '../../../components/EditableTable';
import { useGetUsersTableQuery, useDeleteUserMutation } from '../../../store/session';
import { columns } from './columns';
import { titleContainerS } from './styles';

const Users = () => {
  const maxWidth525px = useMediaQuery('(max-width:525px)');

  return (
    <Card sx={{ padding: '1rem' }}>
      <Box
        sx={{ ...TitleContainer, ...(maxWidth525px && titleContainerS) }}
      >
        <h1>Manage Users</h1>
        <LinkButton style={ButtonStyle} path="/manage-users/create">Create New User</LinkButton>
      </Box>
      <EditableTable
        useHook={useGetUsersTableQuery}
        useHookDelete={useDeleteUserMutation}
        columns={columns}
        sortByType="ASC"
        sortByActive="username"
        getRowHeight={() => 'auto'}
      />
    </Card>

  );
};

export default Users;

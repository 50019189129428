export const header = {
  backgroundColor: '#f7f7f7',
  p: '8px 14px',
  color: '#34495e',
  borderBottom: '1px solid #ebebeb',
};

export const listContainer = {
  p: '4px',
  gap: '1px',
  display: 'flex',
  flexDirection: 'column',
};

export const listItem = {
  userSelect: 'none',
  cursor: 'pointer',
  width: '132px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#000',
  '&:hover': {
    color: '#23527c'
  }
};

export const closeSx = {
  color: '#fff',
  background: '#000',
};

export const dropdownMenuSx = {
  '& .MuiPaper-root': {
    p: 0
  }
};
import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import { HeaderName, StyledDelete, StyledPencil } from '../../../components/EditableTable/styles';
import Input from '../../../components/Input/Input';
import { StyledCopy } from '../../Flights/list/styles';
import { StyledAirplane, StyledLink } from './styles';

export const columns = ({
  filters,
  handleFilter,
  handleCopyFleet,
  handleFlight,
  handleOpenDeleteModal
}) => ([
  {
    flex: 0.1,
    field: 'fleet_id',
    minWidth: 100,
    headerName: 'Flight',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Flight</Typography>
        <Input
          size="small"
          placeholder="Flight"
          defaultValue={filters.fleet_id || ''}
          onChange={(e) => {
            handleFilter(e.target.value, 'fleet_id');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        #
        {' '}
        {row.fleet_id}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'company_name',
    minWidth: 250,
    headerName: 'Company',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Company</Typography>
        <Input
          size="small"
          placeholder="Company"
          defaultValue={filters.company_name || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'company_name');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
      >
        {row.company_name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'sector',
    minWidth: 190,
    headerName: 'Flight',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Flight</Typography>
        <Input
          size="small"
          placeholder="Flight"
          defaultValue={filters.sector || ''}
          onChange={(e) => {
            handleFilter(e.target.value, 'sector');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        <Link style={{ 'textDecoration': 'none' }} to={`/quotations/update/${row.fleet_id}`}>{row.sector}</Link>
      </Typography>
    ),
  },
  {
    minWidth: 150,
    flex: 0.2,
    field: 'fleet_date',
    headerName: 'Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Date</Typography>
        <FiltrationDate
          onChange={(e) => handleFilter(e, 'fleet_date', true)}
          value={filters.fleet_date || null}
          showMonthDropdown
          showYearDropdown
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.fleet_date}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    minWidth: 100,
    field: 'fleet_price',
    headerName: 'Price',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Price</Typography>
        <Input
          size="small"
          placeholder="Price"
          defaultValue={filters.fleet_price || ''}
          onChange={(e) => {
            handleFilter(e.target.value, 'fleet_price');
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.fleet_price}
      </Typography>
    ),
  },
  {
    minWidth: 200,
    flex: 0.2,
    field: 'created_at',
    headerName: 'Created At',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Created At</Typography>
        <Input
          size="small"
          placeholder="Created At"
          defaultValue={filters.created_at || ''}
          onChange={(e) => {
            handleFilter(e.target.value, 'created_at');
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.created_at}
      </Typography>
    ),
  },
  {
    minWidth: 200,
    flex: 0.2,
    field: 'created_by',
    headerName: 'Creator',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Creator</Typography>
        <Input
          size="small"
          placeholder="Creator"
          defaultValue={filters.created_by || ''}
          onChange={(e) => {
            handleFilter(e.target.value, 'created_by');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.created_by}
      </Typography>
    ),
  },
  {
    width: 130,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <Link style={StyledLink} to={`/quotations/update/${row.fleet_id}`}>
          <StyledPencil />
        </Link>
        {' '}
        <StyledDelete onClick={() => { handleOpenDeleteModal(row.fleet_id); }} />
        {' '}
        <StyledCopy onClick={() => { handleCopyFleet(row.fleet_id); }} />
        {' '}
        <StyledAirplane onClick={() => { handleFlight(row.fleet_id); }} />
      </>
    ),
  },
]);

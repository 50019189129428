import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import Button from '../../../components/Button';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import TextEdit from '../../../components/EditableTable/TextEdit';
import { TYPE_MULTI_INPUT, TYPE_SELECT } from '../../../components/EditableTable/constants';
import { HeaderName } from '../../../components/EditableTable/styles';
import { TransactionModel } from '../../../utils/constants/portalFiles';
import { GreenButton, RedButton } from '../../Logistics/style';
import { FILE_FILTER } from '../constants';
import { ASSIGNMENTS, Types } from './constants';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';

export const columns = ({
  handleFilter,
  filters,
  handleOpen,
  handleOpenDetails,
  handleToggleData,
  handleFileDrawer,
  currentBank,
  taxAccess,
  financiersAccess,
  hasPermission
}) => {
  const bankName = currentBank?.label;

  return ([
    {
      flex: 0.1,
      minWidth: 140,
      headerName: 'Actions',
      renderCell: (row) => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Button
          title="Details"
          onClick={() => handleOpenDetails(row.id)}
          size="small"
        />
      )
    },
    {
      flex: 0.1,
      field: 'id',
      minWidth: 140,
      headerName: 'Internal ID',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Internal ID</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.id || ''}
            value="id"
            placeholder="Internal ID"
          />
        </Box>
      ),
      renderCell: (row) => (row.id),
    },
    {
      flex: 0.1,
      field: 'amount',
      minWidth: 140,
      headerName: 'Amount',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Amount</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.amount || ''}
            value="amount"
            placeholder="Amount"
          />
        </Box>
      ),
      renderCell: ({ row }) => (`${row.amount} ${row.currency}`),
    },
    {
      flex: 0.1,
      field: 'value_date',
      minWidth: 140,
      headerName: 'Value Date',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Value Date</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (row?.data?.value_date),
    },
    {
      flex: 0.2,
      field: 'comments_internal',
      minWidth: 140,
      headerName: 'Comments from FleetAir',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Comments from FleetAir</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'comments_internal', row)}
          text={row.comments_internal}
          disabled={taxAccess && !hasPermission && !financiersAccess}
          labelInput="Comments from FleetAir"
          type={TYPE_MULTI_INPUT}
        />
      ),
    },
    {
      flex: 0.2,
      field: 'comments_external',
      minWidth: 140,
      headerName: 'Comments from Tax Office',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Comments from Tax Office</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.comments_external || ''}
            value="comments_external"
            placeholder="Comments"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'comments_external', row)}
          text={row.comments_external}
          labelInput="Comments from Tax Office"
          disabled={!taxAccess && !hasPermission}
          type={TYPE_MULTI_INPUT}
        />
      ),
    },
    {
      flex: 0.15,
      field: 'files_exist',
      minWidth: 120,
      headerName: 'Files',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Files</Typography>
          <FiltrationSelect
            options={FILE_FILTER}
            handleFilter={handleFilter}
            defaultValue={filters.files_exist || ''}
            value="file_filter"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Box>
          <Button
            sx={row.files_exist === 0 ? RedButton : GreenButton}
            size="small"
            onClick={() => handleFileDrawer(true, row?.id, TransactionModel.types.action.id, 'Transaction', row?.id)}
          >
            Files
          </Button>
        </Box>
      ),
    }
  ]);
};

import React from 'react';
import { Card, CardContent } from '@mui/material';
import OperatorTable from './OperatorTable';

const Operators = () => (
  <Card sx={{ mt: 3 }}>
    <CardContent>
      <OperatorTable title="Aircraft Operator List" />
    </CardContent>
  </Card>
);

export default Operators;

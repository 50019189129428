import { useFormContext } from 'react-hook-form';

export const useGeneral = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return {
    errors,
    control
  };
};

import {
  Card,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CreditNoteForm from '../../../../../Logistics/Suppliers/CreditNotes/CreditNoteForm';
import { IssueCardStyle } from '../../../styles';
import ApproveButton from '../ApproveButton';
import CreditNotesTable from './CreditNotesTable';

const IssueForm = ({ reason, onSubmit, invoice = null }) => {
  const [supplier, setSupplier] = useState(null);
  const [creditNotes, setCreditNotes] = useState([]);

  useEffect(() => {
    let _supplier = invoice?.orders?.[0]?.prices?.[0]?.supplier ?? invoice?.orders?.[0]?.prices?.[0]?.supplier_data;
    if (!_supplier) {
      _supplier = invoice?.proforma?.orders?.[0]?.prices?.[0]?.supplier ?? invoice?.proforma?.orders?.[0]?.prices?.[0]?.supplier_data;
    }

    if (_supplier) {
      setSupplier(_supplier);
    }

    const combinedCreditNotes = [
      ...(invoice?.credit_notes || []),
      ...(invoice?.proforma?.credit_notes || []),
    ];

    setCreditNotes(combinedCreditNotes);
  }, [invoice]);

  return (
    <Card sx={IssueCardStyle}>
      {!!supplier && (
        <Typography variant="h6">
          Order Vendor Name:
          {` ${supplier?.name}`}
        </Typography>
      )}

      <CreditNoteForm
        type="create"
        drawerClose={() => {}}
        handleRefresh={() => {}}
        reason={reason}
        supplier={supplier}
        invoice_id={invoice?.id}
      />

      {!!creditNotes.length && (
        <>
          <CreditNotesTable creditNotes={creditNotes} />
          <ApproveButton invoiceId={invoice?.id} onSubmit={onSubmit} resolve />
        </>
      )}
    </Card>
  );
};

export default IssueForm;

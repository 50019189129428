import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import Content from './Content';

const AdditionalInvoicesForm = () => (
  <Card sx={{ mt: 3 }}>
    <CardHeader title="Additional Invoices" />
    <CardContent sx={{ mt: 5, overflowX: 'scroll' }}>
      <Content />
    </CardContent>
  </Card>
);

export default AdditionalInvoicesForm;

export const DAY_IN_WEEK = 7;

export const WEEK = {
  'Mon': 0,
  'Tue': 1,
  'Wed': 2,
  'Thu': 3,
  'Fri': 4,
  'Sat': 5,
  'Sun': 6,
};

export const WEEK_COLORS = {
  'Sat': '#ffd6cc',
  'Sun': '#ffd6cc',
};
export const WORK_DAY_COLOR = '#F2F2FF';
export const COLORS = ['#6D9EEB', '#8E7CC3', '#D5A6BD', '#6AA84F'];
export const MONTH_FORMAT = 'MMM';
export const DAY_FORMAT = 'ddd';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const tableIndexes = {
  0: 0, 1: 0, 2: 1, 3: 2, 4: 3 
};
import {
  Box, Grid, TextField, Typography
} from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import Upload from '../../../../../../../components/Upload';
import FieldError from '../../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../../form/components/FormGroup';
import Select from '../../../../../../../form/components/Select';
import { useGetUnitsListQuery } from '../../../../../../../store/slices/logisticsSlice';
import { getOptions } from '../../../../../../../utils/getOptions';
import { file, fileName, label } from '../../../../../../DocumentsAndInvoices/Documents/DocumentsForm/style';

const ManualForm = (props) => {
  const {
    control,
    errors,
    setValue,
    watch
  } = props;
  const { data: units, isLoading: isUnitsLoading } = useGetUnitsListQuery();
  const unitsOptions = getOptions(units);
  const [nameFile, setNameFile] = useState('');
  const optionsSelectStorage = [
    { value: 'hof', label: 'Hof' },
    { value: 'budapest', label: 'Budapest' }
  ];

  return (
    <Grid container spacing={6} sx={{ padding: '0px  24px' }}>
      
      <Grid item xs={4}>
        <FormGroup label="Part Name" required hasError={!!errors?.part_name}>
          <Controller
            control={control}
            name="part_name"
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Enter Part Name"
                size="small"
                sx={{ width: '100%' }}
              />
            )}
          />
        </FormGroup>
      </Grid>
      
      <Grid item xs={4}>
        <FormGroup label="Part Number" required hasError={!!errors?.part_on_number}>
          <Controller
            control={control}
            name="part_on_number"
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Enter Part Number"
                size="small"
                sx={{ width: '100%' }}
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={4}>
        <FormGroup label="Serial Number" hasError={!!errors?.serial_on_number}>
          <Controller
            control={control}
            name="serial_on_number"
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Enter Serial Number"
                disabled={watch('quantity') > 1}
                size="small"
                sx={{ width: '100%' }}
                onChange={(e) => {
                  if (e.target.value !== '') {
                    setValue('quantity', 1);
                  }
                  field.onChange(e);
                }}
              />
            )}
          />
        </FormGroup>
      </Grid>
                      
      <Grid item xs={4}>
        <FormGroup label="Storage" required hasError={!!errors.storage}>
          <Controller
            control={control}
            name="storage"
            render={({ field }) => (
              <Select
                options={optionsSelectStorage}
                placeholder="Storage"
                {...field}
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={4}>
        <FormGroup label="Quantity" required hasError={!!errors.quantity}>
          <Controller
            control={control}
            name="quantity"
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                placeholder="Quantity"
                disabled={(watch('serial_on_number') !== '')}
                size="small"
                sx={{ width: '100%' }}
                onChange={(e) => {
                  if (e.target.value > 1) {
                    setValue('serial_on_number', '');
                  }
                  field.onChange(e);
                }}
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={4}>
        <FormGroup label="Unit" required hasError={errors?.unit_id}>
          <Controller
            name="unit_id"
            control={control}
            render={({ field }) => (
              <Select
                options={unitsOptions}
                placeholder="Units"
                disabled={isUnitsLoading}
                {...field}
              />
            )}
          />
        </FormGroup>
      </Grid>
      <Grid
        item
        xs={12}
      >
          
        <Box sx={file}>
          <FormGroup sx={label} required label="File" hasError={!!errors.certificate}>
            <Controller
              name="certificate"
              control={control}
              render={({ field }) => (
                <Upload
                  {...field}
                  accept=".pdf"
                  handleUpload={(e) => {
                    if (!e.target.files[0]) return;
                    if (!e.target.files[0]?.name?.includes('.pdf')) return;
                    setValue('certificate', e.target.files?.[0]);
                    setNameFile(e.target.files[0].name);
                  }}
                  title="Upload file"
                />
              )}
            />
            <FieldError error={errors.certificate} />
          </FormGroup>
          <Typography sx={{
            ...(fileName), overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap' 
          }}
          >
            {nameFile}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ManualForm;
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { HeaderName } from '../../../components/EditableTable/styles';
import { TextWrap } from '../../Logistics/style';

export const columns = () => ([
  {
    flex: 0.2,
    field: 'period',
    maxWidth: 200,
    headerName: 'Period',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Period</Typography>
      </Box>
    ),
    renderCell: ({ row }) => {
      const [from = '', to = ''] = row?.period?.split(' ');

      return (
        <Box>
          From:
          <Typography sx={TextWrap}>
            {from}
          </Typography>
          To:
          <Typography sx={TextWrap}>
            {to}
          </Typography>
        </Box>
      );
    },
  }, // Period
  {
    flex: 0.2,
    field: 'aircraft_serial_number',
    minWidth: 80,
    headerName: 'MSN',
    renderHeader: () => (
      <Box>
        <Typography style={HeaderName}>MSN</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.aircraft_serial_number}</Typography>
      </Box>
    ),
  }, // Serial Number
  {
    flex: 0.2,
    field: 'aircraft_registration',
    minWidth: 160,
    headerName: 'A/C Registration',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>A/C Registration</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.aircraft_registration}</Typography>
      </Box>
    ),
  }, // Aircraft Registration
  {
    flex: 0.2,
    field: 'sum_fh',
    minWidth: 100,
    headerName: 'Sum FH',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Sum FH</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.sum_fh}</Typography>
      </Box>
    ),
  }, // Sum FH
  {
    flex: 0.2,
    field: 'sum_fc',
    minWidth: 100,
    headerName: 'Sum FC',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Sum FC</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.sum_fc}</Typography>
      </Box>
    )
  }, // Sum FC
  {
    flex: 0.2,
    field: 'tah',
    minWidth: 100,
    headerName: 'TAH',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>TAH</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.tah}</Typography>
      </Box>
    ),
  }, // TAH
  {
    flex: 0.2,
    field: 'tac',
    minWidth: 100,
    headerName: 'TAC',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>TAC</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.tac}</Typography>
      </Box>
    ),
  }, // TAC
  {
    flex: 0.2,
    field: 'sum_service_days',
    minWidth: 100,
    headerName: 'Sum service days',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Sum service days</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.sum_service_days}</Typography>
      </Box>
    )
  }, // Sum service days
  {
    flex: 0.2,
    field: 'record_id',
    minWidth: 140,
    headerName: 'Record ID',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Record ID</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.record_id}</Typography>
      </Box>
    )
  }, // Record ID
]);

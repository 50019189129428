import { Box } from '@mui/material';
import React from 'react';
import HorizontalNavItems from './HorizontalNavItems';

const Navigation = (props) => {
  const { isMobile = false } = props;

  return (
    <Box
      className="menu-content"
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        flexWrap: 'wrap',
        alignItems: isMobile ? 'start' : 'center',
        '& > *': {
          '&:not(:last-child)': { mr: isMobile ? 0 : 2 },
          maxWidth: isMobile ? 'none' : 220,
        },
      }}
    >
      <HorizontalNavItems {...props} />
    </Box>
  );
};

export default Navigation;

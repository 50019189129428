import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import ButtonIcon from '../../components/ButtonIcon';
import FiltrationInput from '../../components/EditableTable/FiltrationInput';
import {
  HeaderName, StyledDelete, StyledPencil,
} from '../../components/EditableTable/styles';
import LinkButton from '../../components/LinkButton';

export const columns = ({
  handleFilter,
  filters,
  hasPermissions,
  handleOpenDeleteModal,
}) => ([
  {
    flex: 0.2,
    field: 'bank_name',
    minWidth: 200,
    headerName: 'Bank name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Bank name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.bank_name || ''}
          value="bank_name"
          placeholder="Bank name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{ textTransform: 'capitalize' }}
      >
        {row.bank_name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'notes',
    minWidth: 200,
    headerName: 'Notes',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Notes</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.notes || ''}
          value="notes"
          placeholder="Notes"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        sx={{ textTransform: 'capitalize' }}
      >
        {row.notes}
      </Typography>
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <LinkButton path={`/finance/banks/update/${row.id}`}>
          <StyledPencil />
        </LinkButton>
        {' '}
        <ButtonIcon disabled={!hasPermissions} onClick={() => handleOpenDeleteModal(row.id)}>
          <StyledDelete />
        </ButtonIcon>
      </>
    ),
  },
]);

export const documentForm = {
  padding: '15px'
};

export const block = {
  width: '100%',
  mb: '20px',
};

export const file = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  mb: '10px',
};

export const label = {
  '& label': {
    marginBottom: '5px ',
  }
};

export const button = {
  '& label': {
    marginBottom: '0px !important',
  },
  width: '10rem'
};

export const fileName = {
  marginTop: '14px',
  marginLeft: '10px',
};

export const buttonBlock = {
  width: '100%',
  mb: '20px',
  textAlignLast: 'center'
};

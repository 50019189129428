import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Divider,
  Grid,
  TextField, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import Upload from '../../../../components/Upload';
import Autocomplete from '../../../../form/components/Autocomplete/Autocomplete';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import ServerAutocomplete from '../../../../form/components/ServerAutocomplete';
import {
  useCreateCreditNoteMutation, useGetCreditNotePartAutocompleteQuery,
  useSearchSupplierQuery,
  useUpdateCreditNoteMutation,
} from '../../../../store/slices/logisticsSlice';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import { WhiteInput } from '../../../Finance/InvoiceManagement/styles';
import { file, fileName, label } from '../../OrdersDashboard/components/forms/approved/invoices/style';
import { GreenButton } from '../../style';
import { buttonBlock } from '../style';
import { defaultValues, schema } from './creditNoteSchema';

const CreditNoteForm = ({
  type,
  drawerClose,
  handleRefresh,
  creditNote = null,
  supplier = null,
  reason = null,
  invoice_id = null
}) => {
  const [currentPart, setCurrentPart] = useState([]);
  const [supplierM, setSupplierM] = useState({});
  const [nameFile, setNameFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const update = type === 'update';

  const reasonOptions = [
    { label: 'Product not delivered but paid', value: 'Product not delivered but paid' },
    { label: 'Incorrect or faulty product supplied', value: 'Incorrect or faulty product supplied' },
    { label: 'Overbilling or invoice error', value: 'Overbilling or invoice error' },
  ];

  const {
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    watch,
    control,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: update ? creditNote : defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const [sendCreditNote] = useCreateCreditNoteMutation();
  const [updateCreditNote] = useUpdateCreditNoteMutation();
  const handleError = useHandleQueryFormError();

  const uploadFile = (e) => {
    const countFiles = e.target.files.length;
    setNameFile(countFiles > 1 ? `${countFiles} files selected` : e.target.files[0].name);
    setValue('upload_files', e.target.files);
  };

  const onSubmit = async (data) => {
    if (!data) {
      toast.error(`The Credit Note was not ${update ? 'updated' : 'created'}!`, {
        duration: 3000
      });

      return;
    }
    setLoading(true);
    const toastId = toast.loading('Loading...');
    if (invoice_id) {
      data.invoice_id = invoice_id;
    }
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'upload_files' || key === 'parts') {
        if (value) {
          Object.keys(value)?.forEach((valueKey) => {
            if (key === 'parts') {
              formData.append(`${key}[${valueKey}][id]`, value[valueKey]?.id);
            } else {
              formData.append(`${key}[${valueKey}]`, value[valueKey]);
            }
          });
        }
      } else if (value) {
        formData.append(key, value || null);
      }
    });
    const res = update ? await updateCreditNote({ id: creditNote?.id, data }) : await sendCreditNote({ data: formData });

    if (res.error) {
      handleError(res.error, setError, getValues, toastId);
      setLoading(false);

      return;
    }

    toast.success(`The Credit Note was ${update ? 'updated' : 'created'}!`, {
      id: toastId,
    });

    if (!update) {
      drawerClose();
      reset();
      setCurrentPart([]);
      setNameFile(null);
      if (supplier) {
        setValue('logistic_supplier_id', supplier?.id);
        setSupplierM(supplier);
      }
    }

    handleRefresh();
    setLoading(false);
  };

  useEffect(() => {
    if (type === 'update') {
      Object.keys(defaultValues).forEach((key) => setValue(key, creditNote?.[key]));
    }
    setValue('type', type);

    if (supplier) {
      setValue('logistic_supplier_id', supplier?.id);
      setSupplierM(supplier);
    }
  }, [creditNote, supplier]);

  useEffect(() => {
    if (reason) {
      setValue('reason', reason);
    }
  }, [reason]);

  const handleSelectPart = (item) => {
    setCurrentPart(
      Array.isArray(item)
        ? (item.length
          ? [...item]
          : [])
        : (item || null)
    );

    if (!Array.isArray(item)) {
      const current_part = item ? {
        ...item?.data,
      } : null;

      setValue('parts', item ? [{
        id: current_part?.id,
      }] : [{
        id: 0,
      }]);
      setValue('parts', item?.value || null);
      trigger('parts');
    } else {
      const parts = item.map(({ data }) => ({
        id: data?.id
      }));

      setValue('parts', parts || defaultValues.parts);
      trigger('parts');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} sx={{ padding: '0 1rem', mt: 5 }}>
        <Grid item xs={12} md={12}>
          <Box sx={{
            textAlign: 'center'
          }}
          >
            <Typography variant="h6">
              Vendor Balance:
              {' '}
              {`${supplierM?.vendor_balance ?? supplierM?.data?.vendor_balance ?? 0} ${supplierM?.currency?.currency ?? supplierM?.data?.currency ?? ''}`}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Divider sx={{ mt: 3, mb: 3 }} />
        </Grid>

        {!supplierM?.id && (
          <Grid item xs={12} md={6}>
            <FormGroup label="Supplier">
              <Controller
                name="logistic_supplier_id"
                control={control}
                render={({ field }) => (
                  <ServerAutocomplete
                    sx={WhiteInput}
                    field={field}
                    value={supplierM || ''}
                    withData
                    placeholder="Search suppliers by name or email"
                    searchQueryFn={useSearchSupplierQuery}
                    queryParams={{ type: 'service' }}
                    handleSelect={(value) => {
                      field.onChange(value?.value);
                      setSupplierM(value);
                      setValue('logistic_supplier_id', value?.value);
                      setCurrentPart([]);
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <FormGroup label="Parts" hasError={!!errors.part_id}>
            <Controller
              name="part_id"
              control={control}
              render={({ field }) => (
                <ServerAutocomplete
                  sx={WhiteInput}
                  disabled={!watch('logistic_supplier_id')}
                  field={field}
                  value={currentPart || []}
                  withData
                  multiple
                  limitTags={1}
                  id="multiple-limit-tags"
                  placeholder="Search Parts"
                  searchQueryFn={useGetCreditNotePartAutocompleteQuery}
                  queryParams={{
                    vendor_id: watch('logistic_supplier_id'),
                  }}
                  blurOnSelect="touch"
                  handleSelect={handleSelectPart}
                  getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.value} - ${option?.label}`) || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Parts"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup
            label="Amount"
            required
            hasError={!!errors.value}
          >
            <Controller
              name="value"
              control={control}
              render={({ field }) => (
                <TextField
                  sx={WhiteInput}
                  {...field}
                  variant="outlined"
                  fullWidth
                  type="number"
                  size="small"
                  placeholder="Enter Amount"
                  InputProps={{
                    inputProps: { min: 0, step: '0.01' },
                  }}
                  error={!!errors.value}
                  helperText={errors.value ? errors.value.message : ''}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup
            label="Date"
            required
            hasError={!!errors.date}
          >
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  sx={WhiteInput}
                  {...field}
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-dd"
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 'date', 'YYYY-MM-DD');
                  }}
                  selected={validateDatePickerValue(field.value)}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <FormGroup
            label="Description"
            hasError={!!errors.description}
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  sx={WhiteInput}
                  {...field}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Description"
                  error={!!errors.description}
                  helperText={errors.description ? errors.description.message : ''}
                />
              )}
            />
          </FormGroup>
        </Grid>

        {!reason && (
          <Grid item xs={12}>
            <FormGroup
              label="Reason"
              hasError={!!errors.reason}
            >
              <Controller
                name="reason"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    sx={WhiteInput}
                    {...field}
                    value={reasonOptions.find((option) => option.value === field.value) || null}
                    options={reasonOptions}
                    size="small"
                    placeholder="Select Reason"
                    onChange={(e, value) => {
                      field.onChange(value?.value);
                      setValue('reason', value?.value || null);
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        )}
        <Grid item xs={12} sx={file}>
          <FormGroup sx={label} label="Files" hasError={!!errors.upload_files}>
            <Controller
              name="upload_files"
              control={control}
              render={({ field }) => (
                <Upload
                  {...field}
                  accept=".doc,.docx,image/*,.pdf"
                  multiple
                  handleUpload={(e) => {
                    field.onChange(e);
                    uploadFile(e);
                  }}
                  title="Upload file"
                />
              )}
            />
            <FieldError error={errors.upload_files} />
          </FormGroup>
          <Typography sx={fileName}>{nameFile}</Typography>
        </Grid>

      </Grid>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Box sx={{
        ...buttonBlock, textAlignLast: 'start', padding: '0 1rem', mt: 3
      }}
      >
        <Button sx={GreenButton} title={update ? 'Save' : 'Create'} disabled={isLoading} type="submit" />
      </Box>
    </form>
  );
};

export default CreditNoteForm;

// ** React Imports
import React from 'react';

// ** MUI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

// ** ProfileImage

const profileImage = require('../../assets/img/blank-profile.png');

const ProfilePicture = styled('img')(({ theme }) => ({
  width: 120,
  height: 120,
  borderRadius: theme.shape.borderRadius,
  border: `5px solid ${theme.palette.common.white}`,
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  }
}));

const UserProfileHeader = ({ data }) => (data !== null ? (
  <Card>
    <CardContent
      sx={{
        pt: 0,
        mt: 5,
        display: 'flex',
        alignItems: 'flex-end',
        flexWrap: { xs: 'wrap', md: 'nowrap' },
        justifyContent: { xs: 'center', md: 'flex-start' }
      }}
    >
      <ProfilePicture src={data.profile_image || profileImage} alt="profile-picture" />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          ml: { xs: 0, md: 6 },
          alignItems: 'flex-end',
          flexWrap: ['wrap', 'nowrap'],
          justifyContent: ['center', 'space-between']
        }}
      >
        <Box sx={{
          mb: [6, 0], display: 'flex', flexDirection: 'column', alignItems: ['center', 'flex-start']
        }}
        >
          <Typography variant="h5" sx={{ mb: 4, fontSize: '1.375rem' }}>
            {data.name}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
) : null);
export default UserProfileHeader;

import { createSlice } from '@reduxjs/toolkit';
import { useProvideTags } from '../../utils/hooks/useProvideTags';
import { sessionApi } from '../session';

// State and actions
export const paymentDataSlice = createSlice({
  name: 'payment',
  initialState: {},
});

// Logistics Endpoints
sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    getDepartmentInfo: builder.query({
      query: ({ id }) => `/payment_data/departments/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getDepartments: builder.query({
      query: () => '/payment_data/departments',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getTableDepartments: builder.query({
      query: (data) => `/payment_data/departments_table${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createDepartmentData: builder.mutation({
      query: ({ data }) => ({
        url: '/payment_data/departments',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteDepartments: builder.mutation({
      query: (id) => ({
        url: `/payment_data/departments/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateDepartments: builder.mutation({
      query: ({ id, data }) => ({
        url: `/payment_data/departments/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getPaymentDataTable: builder.query({
      query: (data) => `/payment_data/payments${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getPaymentData: builder.query({
      query: ({ userId, payment_department_id }) => `/payment_data/payments/${userId}?department_id=${payment_department_id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createPaymentData: builder.mutation({
      query: (data) => ({
        url: '/payment_data/payments',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updatePaymentData: builder.mutation({
      query: ({ id, data }) => ({
        url: `/payment_data/payments/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deletePaymentData: builder.mutation({
      query: (id) => ({
        url: `/payment_data/payments/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFilePayment: builder.mutation({
      query: (id) => ({
        url: `/payment_data/files/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    attachFilePayment: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/payment_data/files/${id}`,
        method: 'POST',
        body: formData,
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteFilePayment: builder.mutation({
      query: (id) => ({
        url: `/payment_data/files/${id}`,
        method: 'DELETE',
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    paymentDataExportExcel: builder.mutation({
      query: (data) => ({
        url: `/payment_data/department_export${data}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
  }),
});

export const {
  useGetDepartmentInfoQuery,
  useGetDepartmentsQuery,
  useGetTableDepartmentsQuery,
  useCreateDepartmentDataMutation,
  useDeleteDepartmentsMutation,
  useUpdateDepartmentsMutation,
  useGetPaymentDataQuery,
  useGetPaymentDataTableQuery,
  useCreatePaymentDataMutation,
  useUpdatePaymentDataMutation,
  useDeletePaymentDataMutation,
  useGetFilePaymentMutation,
  useAttachFilePaymentMutation,
  useDeleteFilePaymentMutation,
  usePaymentDataExportExcelMutation
} = sessionApi;

export default paymentDataSlice.reducer;

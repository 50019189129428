import React from 'react';
import Input from '../../Input/Input';

const FiltrationInput = ({
  handleFilter, value, defaultValue, placeholder, ...props
}) => (
  <Input
    {...props}
    size="small"
    placeholder={placeholder}
    defaultValue={defaultValue}
    onChange={(e) => {
      e.stopPropagation();
      handleFilter(e.target.value, value);
    }}
    onClick={(e) => {
      e.stopPropagation();
    }}
  />
);

export default FiltrationInput;

import React from 'react';
import { Divider, Typography } from '@mui/material';

const FleetFormHeader = (props) => {
  const { getValues, id } = props;

  const subtitle1 = `Created by ${getValues('created_by') ?? ''} at ${getValues('created_at') ?? ''} UTC ${(getValues('updated_by') && getValues('updated_at')) ? `| Updated by ${getValues('updated_by')} at ${getValues('updated_at')} UTC` : ''}`;
  const h5 = `${getValues('company_name')} [${getValues('sector')}]`;

  return (
    <>
      <Typography variant="h3" noWrap sx={{ textTransform: 'capitalize' }}>
        {id ? `Update Quotation #${id}` : 'Create Quotation'}
        {id && (<Typography variant="subtitle1" noWrap sx={{ fontWeight: 'bold' }}>{subtitle1}</Typography>)}
      </Typography>
      <Divider />
      {id && (
        <Typography variant="h5" noWrap sx={{ textTransform: 'capitalize' }}>
          {h5}
        </Typography>
      )}
    </>
  );
};

export default FleetFormHeader;

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Table,
  TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from '../../components/Button';
import LinkButton from '../../components/LinkButton/Button';
import { DATE_FORMAT } from '../../constans/formats';
import { OPTIONS_SUPPLIER } from '../../constans/fuel';
import DatePicker from '../../form/components/DatePicker';
import Select from '../../form/components/Select';
import { useFuelBalanceMutation, useGetFuelBalanceQuery } from '../../store/session';
import { columns } from './columns';
import {
  blockTitle,
  blue,
  header,
  price,
  tableCell,
  title,
  titleSx,
  white,
  yellow
} from './style';

const FuelBalance = () => {
  const [balance, setBalance] = useState([]);
  const {
    handleSubmit, control, getValues, watch,
  } = useForm({
    mode: 'onChange',
  });

  const { data: fuel } = useGetFuelBalanceQuery();
  const [fuelBalance, { isLoading }] = useFuelBalanceMutation();

  const maxWidth490px = useMediaQuery('(max-width:490px)');
  const maxWidth768px = useMediaQuery('(max-width:768px)');

  const onSubmit = async (data) => {
    const res = await fuelBalance({
      supplier_id: data.supplier_id,
      date_from: moment(data.date_from).format(DATE_FORMAT),
      date_to: moment(data.date_to).format(DATE_FORMAT),
    });

    if (res.error) {
      toast.error('Something went wrong');

      return;
    }

    setBalance(res.data);
    toast.success('Successfully!');
  };

  const start = watch('date_from');

  return (
    <Card sx={{ padding: '1rem' }}>
      <Typography variant="h3" sx={{ marginBottom: 3 }}>Fuel Balance</Typography>
      <Box xs={4} sx={{ ...title, ...(maxWidth490px && titleSx) }}>
        <Box sx={blockTitle}>
          <Typography variant="h6">AIR BP</Typography>
          <Typography variant="h6" sx={price}>
            {fuel?.air}
            {' '}
            USD
          </Typography>
        </Box>
        <Box sx={blockTitle}>
          <Typography variant="h6">WFS</Typography>
          <Typography variant="h6" sx={price}>
            {fuel?.wps}
            {' '}
            USD
          </Typography>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid xs={maxWidth768px ? 12 : 11} container sx={{ display: 'flex', gap: '20px', marginBottom: '30px' }}>
          <Grid xs={maxWidth768px ? 12 : 3}>
            <Controller
              name="supplier_id"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Select supplier"
                  options={OPTIONS_SUPPLIER}
                />
              )}
            />
          </Grid>
          <Grid xs={maxWidth768px ? 12 : 3}>
            <Controller
              name="date_from"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-dd"
                  label="Date from"
                  showMonthDropdown
                  showYearDropdown
                />
              )}
            />
          </Grid>
          <Grid xs={maxWidth768px ? 12 : 3}>
            <Controller
              name="date_to"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-dd"
                  label="Date to"
                  showMonthDropdown
                  showYearDropdown
                  minDate={start ? getValues().date_from : null}
                />
              )}
            />
          </Grid>
          <Grid xs={maxWidth768px ? 12 : 1}>
            <Button title="Submit" type="submit" />
          </Grid>
        </Grid>
      </form>
      {isLoading
        ? <CircularProgress sx={{ width: '100%', display: 'flex', justifyContent: 'center' }} />
        : !!balance?.length && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((item) => (
                    <TableCell sx={header}>
                      {item.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {balance.map((item, index) => (
                  <TableRow
                    sx={{
                      ...(item.color === 'white' && white),
                      ...(item.color === 'blue' && blue),
                      ...(item.color === 'yellow' && yellow),
                    }}
                    key={index}
                  >
                    <TableCell sx={tableCell}>{item?.date}</TableCell>
                    <TableCell sx={tableCell}>{item?.aircraft}</TableCell>
                    <TableCell sx={tableCell}>{item?.airport}</TableCell>
                    <TableCell sx={tableCell}>{item?.price}</TableCell>
                    <TableCell sx={tableCell}>{item?.amount_l}</TableCell>
                    <TableCell sx={tableCell}>{item?.amount_g}</TableCell>
                    <TableCell sx={tableCell}>{item?.calc_sum}</TableCell>
                    <TableCell sx={tableCell}>{item?.sum}</TableCell>
                    <TableCell sx={tableCell}>{item?.invoice}</TableCell>
                    <TableCell sx={tableCell}>{item?.payment_date}</TableCell>
                    <TableCell sx={tableCell}>{item?.balance}</TableCell>
                    <TableCell>
                      {item.fuel_id
                        && (
                        <LinkButton
                          path={`/fuel/uplift?fuel_id=${item.fuel_id}`}
                          target="_blank"
                        >
                          <SearchIcon sx={{ cursor: 'pointer' }} />
                        </LinkButton>
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </Card>
  );
};

export default FuelBalance;

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React from 'react';

import { headerBtn } from '../../../Invoices/style';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from '../../../../components/Button';
import Autocomplete from '../../../../form/components/Autocomplete';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import { useUpdateIncomeItemMutation } from '../../../../store/slices/financeSlice';
import { setDateValue, validateDatePickerValueCopy } from '../../../../utils/setDateValue';
import {
  button,
  label
} from '../../../Logistics/OrdersDashboard/components/forms/approved/invoices/style';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';
import { INCOME_STATUS, schema } from './schema';
import PriceInput from '../../../../form/components/PriceInput';

const UpdatePaidForm = ({
  open, onClose, currentIncome, onUpdated
}) => {
  const [updateIncome] = useUpdateIncomeItemMutation();
  const statusOptions = INCOME_STATUS;
  const {
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      paid: currentIncome?.paid,
      day_of_payment: currentIncome?.day_of_payment,
      status_value: currentIncome?.status,
      status: currentIncome?.status ? statusOptions.find(item => item.value === currentIncome.status) : null,
    },
    resolver: yupResolver(schema),
    shouldFocusError: false,
  }, [currentIncome]);

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const res = await updateIncome({
      id: currentIncome?.id,
      data: {
        payed: data.paid ?? '',
        invoice_status: data.status_value,
        invoice_payment_date: data.day_of_payment ?? '',
      }
    });

    if (res.error) {
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    toast.success('The income status has been updated!', {
      id: toastId,
    });

    reset();
    onUpdated();
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DialogContent>
            <Card
              sx={{ padding: '1rem' }}
            >
              <Box sx={headerBtn}>
                <Box>
                  <Typography variant="h5" noWrap>
                    {`Invoice: ${currentIncome?.invoice_number}`}
                  </Typography>
                </Box>
                <CloseIcon
                  sx={CloseIconStyle}
                  onClick={onClose}
                />
              </Box>

              <Divider />

              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>

                  <Grid item xs={12}>
                    <FormGroup sx={label} label="Day of payment" hasError={!!errors.day_of_payment}>
                      <Controller
                        name="day_of_payment"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            value={validateDatePickerValueCopy(field.value)}
                            placeholderText="Set day of Payment"
                            dateFormat="dd.MM.yyyy"
                            showMonthDropdown
                            showYearDropdown
                            sx={{
                              '& > .react-datepicker__tab-loop': {
                                position: 'fixed',
                                zIndex: 2000
                              }
                            }}
                            onChange={(e) => {
                              field.onChange(e);
                              setDateValue(e, setValue, 'day_of_payment', 'DD.MM.YYYY');
                            }}
                          />
                        )}
                      />
                      <FieldError error={errors.day_of_payment} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup sx={label} label="Paid" required hasError={!!errors.paid}>
                      <Controller
                        name="paid"
                        control={control}
                        render={({ field }) => (
                          <PriceInput
                            size="small"
                            sx={{ width: '100%' }}
                            {...field}
                          />
                        )}
                      />
                      <FieldError error={errors.paid} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup sx={label} required label="Status" hasError={!!errors.status}>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            value={field.value ? statusOptions?.filter((item) => item.value === field?.value?.value)[0] : null}
                            options={statusOptions}
                            size="small"
                            placeholder="Please select a status"
                            onChange={(e, value) => {
                              field.onChange(value);
                              setValue('status', value || null);
                              setValue('status_value', value?.value || null);
                            }}
                          />
                        )}
                      />
                      <FieldError error={errors.status} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ textAlignLast: 'center' }}>
                      <Button sx={button} title="Update" type="submit" />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default UpdatePaidForm;

import DeleteIcon from '@mui/icons-material/Delete';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box, Card,
  Chip, CircularProgress,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import React from 'react';
import Button from '../../../../../../../../components/Button/Button';
import Upload from '../../../../../../../../components/Upload';
import { headerBtn } from '../../../../../../../Invoices/style';
import { RedButton, StatusHeader, TextWithIcon, GreenButton } from '../../../../../../style';
import { useCertificates } from './useCerfificates';

const Certificates = ({
  order, part = false, permission = true, uploadDeliveryCertificates = () => {}, dashboardType = 'orders'
}) => {
  const {
    uploadCert,
    loading,
    certificates,
    canEdit,
    handleOpenCertificate,
    certificateLoading,
    deleteLoading,
    handleDeleteCertificate
  } = useCertificates({ order, part });

  return (
    <Card sx={{
      gap: '15px',
      backgroundColor: '#e9f0fe',
      padding: '10px',
      borderRadius: '8px'
    }}
    >
      <Box sx={{ ...headerBtn }}>
        <Box>
          <Typography variant="h5" noWrap sx={TextWithIcon}>
            <DocumentScannerIcon />
            Certificates
          </Typography>
          <Typography variant="subtitle2" noWrap>
            You can upload few certificates related to this order.
          </Typography>
        </Box>
      </Box>

      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {certificates?.length ? (
            <Grid container spacing={2}>
              {certificates.map((cert, index) => (
                <Grid item xs={12} key={cert.id} sx={{ backgroundColor: 'white', margin: '10px', borderRadius: '8px' }}>
                  <Box sx={StatusHeader}>
                    {`#${index + 1}`}

                    <Chip label={cert?.file_name} />

                    <Box sx={{ margin: '0.2rem' }}>
                      <Button
                        key={cert.id}
                        startIcon={!certificateLoading ? <DownloadIcon /> : <CircularProgress size={15} color="inherit" />}
                        sx={{ ...(GreenButton) }}
                        title="Download"
                        size="small"
                        disabled={certificateLoading}
                        onClick={() => handleOpenCertificate(cert.id, true)}
                      />
                      <Button
                        key={cert.id}
                        startIcon={!certificateLoading ? <OpenInNewIcon /> : <CircularProgress size={15} color="inherit" />}
                        sx={{ marginLeft: '0.5rem'}}
                        title="Preview"
                        size="small"
                        disabled={certificateLoading}
                        onClick={() => handleOpenCertificate(cert.id)}
                      />
                      <Button
                        startIcon={<DeleteIcon />}
                        sx={{ marginLeft: '0.5rem', ...(RedButton) }}
                        disabled={!canEdit || deleteLoading}
                        title="Delete"
                        size="small"
                        onClick={() => handleDeleteCertificate(cert.id)}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '0.5rem' }}>
                    <Typography variant="subtitle2" noWrap>
                      {`Uploaded by: ${cert?.uploaded_by ?? ''}`}
                    </Typography>
                    <Typography variant="subtitle2" noWrap>
                      {`Uploaded at: ${cert?.uploaded_at}`}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="subtitle1" bold noWrap sx={{ textAlignLast: 'center' }}>
              No certificates uploaded yet.
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sx={{ textAlignLast: 'center' }}>
          <Upload
            sx={{ marginTop: '1rem' }}
            accept=".pdf"
            handleUpload={(dashboardType === 'orders') ? uploadCert : uploadDeliveryCertificates}
            title="Upload PDF"
            disabled={!permission || loading || !canEdit}
          />
        </Grid>
      </Grid>

    </Card>
  );
};

export default Certificates;

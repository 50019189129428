import React from 'react';
import { Grid } from '@mui/material';
import Select from '../../form/components/Select';
import DatePicker from '../../form/components/DatePicker';
import Autocomplete from '../../form/components/Autocomplete';
import Button from '../../components/Button';
import { CREW_POST } from '../../constans/crew';
import { useDutyFlightTime } from './useDutyFlightTime';
import {
  ContainerFiltration
} from './style';
import { validateDatePickerValue } from '../../utils/setDateValue';

const Filtration = ({ setMonths, setData }) => {
  const {
    namesOption,
    dateFromValue,
    dateToValue,
    dateFrom,
    dateTo,
    onChangePost,
    onChangeName,
    submitRequest,
  } = useDutyFlightTime({ setMonths, setData });

  return (
    <Grid sx={ContainerFiltration} container spacing={5}>
      <Grid item xs={12} sm={6} md={3}>
        <Select
          label="Crew Post"
          options={CREW_POST}
          onChange={onChangePost}
          defaultValue={0}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Autocomplete
          freeSolo
          options={namesOption}
          getOptionLabel={(option) => option.label || ''}
          isOptionEqualToValue={(option, crewItem) => option.value === crewItem.value}
          fullWidth
          label="Name"
          placeholder="Name"
          id="autocomplete-outlined"
          onChange={onChangeName}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DatePicker
          placeholderText="yyyy-MM-dd"
          dateFormat="yyyy-MM-dd"
          label="From"
          showMonthDropdown
          showYearDropdown
          value={validateDatePickerValue(dateFromValue)}
          onChange={dateFrom}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DatePicker
          placeholderText="yyyy-MM-dd"
          dateFormat="yyyy-MM-dd"
          label="To"
          showMonthDropdown
          showYearDropdown
          value={validateDatePickerValue(dateToValue)}
          onChange={dateTo}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={submitRequest}
          title="Send Request"
        />
      </Grid>
    </Grid>
  );
};

export default Filtration;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persister, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';

const element = (
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persister} loading={null}><App /></PersistGate>
    </Provider>
  </BrowserRouter>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(element);

import {
  object, string, boolean,
} from 'yup';

export const defaultValues = {
  crew_name: '',
  crew_aircraft_id: '',
  crew_post: '',
  crew_active: 0,
  crew_shortname: '',
  crew_birth: '',
  crew_pasport: '',
  crew_expires: '',
  crew_nationality: '',
  crew_home_base: '',
  crew_email: '',
  crew_licence: '',
  crew_acft_type: 'NO TYPE',
  crew_line_check: 0,
  crew_wo_can_perform: 0,
  crew_wo_can_close: 0,
  crew_user_id: null
};

export const schema = object().shape({
  crew_user_id: string()
    .nullable()
    .label('User Account'),
  crew_name: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Name'),
  crew_aircraft_id: string(),
  crew_post: string()
    .required('${label} cannot be blank.')
    .label('Post'),
  crew_active: 0,
  crew_shortname: string()
    .required('${label} cannot be blank.')
    .label('Crew Shortname'),
  crew_birth: string()
    .required('${label} cannot be blank.')
    .label('Birthday'),
  crew_pasport: string().required('${label} cannot be blank.').label('Passport'),
  crew_expires: string(),
  crew_nationality: string(),
  crew_email: string().required('${label} cannot be blank.').label('Email'),
  crew_licence: string(),
  crew_acft_type: string(),
  crew_line_check: boolean(),
  crew_home_base: string().nullable(),
  crew_wo_can_perform: boolean(),
  crew_wo_can_close: boolean(),
});

import React, { useEffect, useState } from 'react';
import { Box, Card } from '@mui/material';
import { columns } from './columns';
import EditableTable from '../../components/EditableTable';
import Button from '@mui/material/Button';
import {
  useDeleteCrewDocMutation, useGetAircraftListQuery, useGetCrewDocQuery, useUpdateCrewDocMutation
} from '../../store/session';
import { ButtonStyle, TitleContainer } from './styles';
import CreateDoc from './CreateDoc';

const CrewDoc = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { data: AircraftList, refetch } = useGetAircraftListQuery();

  const aircraftOptions = AircraftList?.map((item) => ({
    value: item.id,
    label: item.aircraft_registration
  }));

  useEffect(() => {
    if (aircraftOptions) {
      aircraftOptions?.push({ value: '', label: '-' });
    }
  }, [aircraftOptions]);

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Card sx={{ padding: '1rem' }}>
      <Box
        sx={TitleContainer}
      >
        <h1>CREW</h1>
        <Button style={ButtonStyle} onClick={handleOpen}>Add</Button>
      </Box>

      <EditableTable
        useHook={useGetCrewDocQuery}
        useHookUpdate={useUpdateCrewDocMutation}
        useHookDelete={useDeleteCrewDocMutation}
        columns={columns}
        sortByType="DESC"
        sortByActive="doc_name"
        aircraftOptions={aircraftOptions}
      />

      <CreateDoc onClose={handleClose} open={openDrawer} refetch={refetch} />
    </Card>
  );
};

export default CrewDoc;

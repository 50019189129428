export const cardContainer = (isPanel) => ({
  background: '#fff',
  borderRadius: '20px',
  boxShadow: '0 0 20px 8px #d0d0d0',

  p: '60px 25px 25px 25px',
  position: 'relative',
  ...(isPanel && {
    boxShadow: 'none',
    border: '1px solid rgba(76, 78, 100, 0.22)'
  })
});

export const typeSx = {
  backgroundColor: '#bac3d0',
  color: '#337ab7',
  position: 'absolute',
  top: '15px',
  left: '-2px',
  padding: '7px 14px',
  zIndex: '2',
  cursor: 'pointer',
  userSelect: 'none',
};

export const summarySx = {
  display: 'none',
};

export const accordionSx = {
  boxShadow: 'none !important',
  '& .MuiAccordionDetails-root': {
    p: 0,
  },
  '&::before': {
    display: 'none',
  }
};
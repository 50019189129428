import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import {
  HeaderName, StyledDelete, StyledPencil
} from '../../../components/EditableTable/styles';
import TextEdit from '../../../components/EditableTable/TextEdit';
import LinkButton from '../../../components/LinkButton';
import { TYPE_INPUT, } from '../../../components/EditableTable/constants';
import { Launch } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export const columns = ({
  handleOpen,
  handleFilter,
  filters,
  handleOpenDeleteModal,
  hasPermissions,
}) => ([
  {
    field: 'index',
    width: 120,
    headerName: '№',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>№</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.id || ''}
          handleFilter={handleFilter}
          placeholder="id"
          value="id"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">
        {row.id}
      </Typography>
    ),
  }, // number
  {
    flex: 0.2,
    field: 'instructor_name',
    minWidth: 180,
    headerName: 'Instructors',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Instructors</Typography>
        <FiltrationInput
          options={[]}
          defaultValue={filters.instructor_name || ''}
          handleFilter={handleFilter}
          placeholder="Instructor"
          value="instructor_name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <TextEdit
        handleEdit={(...params) => handleOpen(...params, 'instructor_name', row)}
        text={row.instructor_name}
        disabled={!hasPermissions}
        labelInput="Enter Instructor Name"
        type={TYPE_INPUT}
      />
    ),
  }, // instructor
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (hasPermissions ? (
      <>
        <LinkButton path={`/training-module/training-personnel/${row.id}`}>
          <StyledPencil />
        </LinkButton>
        {' '}
        <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
        {' '}
      </>
    ) : (
      <LinkButton path={`/training-module/training-personnel/${row.id}`}>
        <Tooltip title="Open">
          <Launch />
        </Tooltip>
      </LinkButton>
    )),
  }, // actions
]);

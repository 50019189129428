export const tableContainer = {
  position: 'relative',
  minWidth: '1200px',
};

export const tableCell = {
  border: 'none',
  '&.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium': {
    fontWeight: 'bold',
    backgroundColor: '#e9edef',
    fontSize: '14px',
    '&:first-of-type': {
    },
    '&:Last-of-type': {
      borderRight: 'none'
    },
  },
};

export const tableSx = {
  borderCollapse: 'collapse',
  width: '100%',
  border: 'none !important',
};

export const tableLoader = {
  width: '100%',
  height: '100%',
  backgroundColor: 'text.white',
  position: 'absolute',
};

export const boxTh = {
  display: 'flex',
  alignItems: 'center',
};

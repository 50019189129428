import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box, Card, Divider, Grid, Typography
} from '@mui/material';
import React from 'react';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import { StatusHeader } from '../../../Logistics/OrdersDashboard/styles';
import { TextWithIcon, TextWrap } from '../../../Logistics/style';

const InvoiceFileDrawer = (props) => {
  const {
    invoiceProps: {
      handlePreviewPoP, handleOpenInvoice, invoiceFileDrawerState: { isOpen, config }, handleOpenInvoiceFileDrawer 
    } 
  } = props;

  const handleCloseDrawer = () => {
    handleOpenInvoiceFileDrawer(false, {
      invoiceFile: null,
      invoicePoP: null,
      proformFile: null,
      proformPoP: null
    });
  };

  return (
    <Drawer
      open={isOpen}
      onClose={handleCloseDrawer}
      title="Invoice Files"
    >
      <Card sx={{ padding: 4 }}>
        <Card sx={{
          gap: '15px',
          backgroundColor: '#e9f0fe',
          padding: '10px',
          borderRadius: '8px'
        }}
        >
          <Box>
            <Box>
              <Typography variant="h5" noWrap sx={TextWithIcon}>
                <DocumentScannerIcon />
                {`${config?.invoiceFile?.id} Files`}
              </Typography>
            </Box>
          </Box>

          <Divider />

          <Grid container spacing={2}>
            {Object.entries(config).filter(([_, value]) => !!value).map(([key, value], index) => (
              <Grid item xs={12} key={value?.id}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    overflowY: 'auto',
                    maxHeight: '70vh'
                  }}
                >
                  <Grid item xs={12} sx={{ backgroundColor: 'white', margin: '10px', borderRadius: '8px' }}>
                    <Box sx={{
                      ...StatusHeader, alignItems: 'start', display: 'grid', gridTemplateColumns: '8fr 2fr', gap: '.5rem'
                    }}
                    >
                      <Box sx={{
                        display: 'grid', alignItems: 'start', width: '100%', gridTemplateColumns: '2rem 1fr'
                      }}
                      >
                        <Typography sx={{ mr: 2 }}>
                          #
                          {index + 1}
                        </Typography>
                        <Typography sx={{ ...TextWrap, fontWeight: '700' }}>{value?.name}</Typography>
                      </Box>

                      <Box sx={{
                        margin: '0.2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                      >
                        <Button
                          startIcon={<OpenInNewIcon />}
                        // disabled={fileOpenLoading && currentFile === file?.id}
                          title="Open"
                          size="small"
                          sx={{ margin: '0', width: '100%' }}
                          onClick={() => {
                            if ((key === 'invoiceFile') || (key === 'proformFile')) {
                              handleOpenInvoice(value?.id);
                            }
                            if ((key === 'invoicePoP') || (key === 'proformPoP')) {
                              handlePreviewPoP(value?.id);
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))} 
          </Grid>
        </Card>
      </Card>
    </Drawer>
  );
};

export default InvoiceFileDrawer;
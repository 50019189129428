import React from 'react';
import { CircularProgress, Grid } from '@mui/material';

const ContentPageLoader = ({ containerSx }) => (
  <Grid container spacing={12} sx={containerSx}>
    <Grid
      item
      xs={12}
      sx={{
        textAlignLast: 'center',
        marginTop: '20%',
      }}
    >
      <CircularProgress />
    </Grid>
  </Grid>
);

export default ContentPageLoader;

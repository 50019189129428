import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetFuelPriceMutation,
  useGetFuelSuppliersListQuery,
  useGetAirportListQuery,
  useUpdateFuelPriceMutation,
  useStoreFuelPriceMutation
} from '../../../store/session';
import {
  Button,
  Card, CardContent,
  Divider,
  Grid,
  Typography,
  Autocomplete,
  TextField,

} from '@mui/material';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { schema } from './schema';
import FormGroup from '../../../form/components/FormGroup';
import DatePicker from '../../../form/components/DatePicker';
import FieldError from '../../../form/components/FieldError';
import moment from 'moment/moment';
import { selectOptionFormat } from '../../../utils/number';
import Select from '../../../form/components/Select';
import toast from 'react-hot-toast';
import PriceInput from '../components/PriceInput';

const defaultValues = {
  ap_fuel_spid: '',
  ap_fuel_apid: '',
  ap_fuel_price: '',
  currency: '',
  unit_id: '',
  ap_fuel_to: ''
};

const FuelPriceForm = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const update = id !== 'create';
  const [getFuelPrice] = useGetFuelPriceMutation();
  const [updateFuelPrice] = useUpdateFuelPriceMutation();
  const [storeFuelPrice] = useStoreFuelPriceMutation();
  const { data: SupplierList, isLoading: supplierListLoad } = useGetFuelSuppliersListQuery();
  const { data: AirportList, isLoading: airportListLoad } = useGetAirportListQuery();

  const formattedSupplierList = selectOptionFormat(SupplierList, 'fuel_suplier');

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const setFuelPriceValues = async () => {
    setLoading(true);
    const { data: result } = await getFuelPrice(id);

    return result;
  };

  useEffect(() => {
    if (update) {
      setFuelPriceValues().then((result) => {
        if (result) {
          setLoading(false);
          setValue('ap_fuel_to', moment(result.ap_fuel_to).toDate());
          setValue('ap_fuel_spid', result.ap_fuel_spid);
          setValue('ap_fuel_apid', result.ap_fuel_apid);
          setValue('ap_fuel_price', result.ap_fuel_price);
          setValue('currency', result.currency);
          setValue('unit_id', result.unit_id);
        }
      });
    }
  }, [update]);

  const onSubmit = async (data) => {
    if (data.ap_fuel_price <= 0) {
      toast.error('The price cannot be below zero');

      return;
    }
    const toastId = toast.loading('Loading...');
    const updateValues = {
      ...data,
      ap_fuel_to: moment(data.ap_fuel_to).format('YYYY-MM-DD')
    };

    if (update) {
      const result = await updateFuelPrice({ data: updateValues, id });
      if (result?.error) {
        toast.error(result.error.data?.message || 'Something went wrong', {
          id: toastId,
        });

        return;
      }
    } else {
      const result = await storeFuelPrice(updateValues);
      if (result?.error) {
        toast.error(result.error.data?.message || 'Something went wrong', {
          id: toastId,
        });

        return;
      }
    }

    toast.success(update ? 'Successfully updated!' : 'Fuel Price created!', {
      id: toastId,
    });
    navigate('/fuel/price');
  };

  if (isLoading || supplierListLoad || airportListLoad ) {
    return <ContentPageLoader />;
  }

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h3" noWrap sx={{ textTransform: 'capitalize' }}>
                {update ? 'Update Fuel Price' : 'Create Fuel Price'}
              </Typography>
              <Divider />

              <Grid item xs={12} sm={6}>
                <FormGroup label="Airport" required hasError={!!errors.ap_fuel_apid}>
                  <Controller
                    name="ap_fuel_apid"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value } }) => (
                      <Autocomplete
                        freeSolo
                        value={
                            AirportList?.filter(
                              (AirportItem) => AirportItem.id === value
                            )[0] || null
                          }
                        options={AirportList}
                        getOptionLabel={(option) => `${option.airport_iata}/${option.airport_icao} (${option.airport_name})`
                            || ''}
                        isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            inputRef={ref}
                            error={Boolean(errors.ap_fuel_apid)}
                          />
                        )}
                        onChange={(e, data) => {
                          setValue(
                            'ap_fuel_apid',
                            `${data?.airport_iata}/${data?.airport_icao} ${data?.airport_name}`
                          );
                          onChange(data?.id);
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors.ap_fuel_apid} />
                </FormGroup>

              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup label="Supplier" required hasError={!!errors.ap_fuel_spid}>
                  <Controller
                    name="ap_fuel_spid"
                    control={control}
                    render={({ field: { onChange, ...req } }) => (
                      <Select
                        options={formattedSupplierList}
                        placeholder="Supplier"
                        {...req}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        disabled={supplierListLoad}
                      />
                    )}
                  />

                  <FieldError error={errors.supplier_id} />
                </FormGroup>
              </Grid>

              <PriceInput control={control} errors={errors} />
              <Grid item xs={12} sm={6}>
                <FormGroup label="Valid to" required hasError={!!errors.ap_fuel_to}>
                  <Controller
                    name="ap_fuel_to"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="YYYY-MM-DD"
                        dateFormat="yyyy-MM-dd"
                        showMonthDropdown
                        showYearDropdown
                        {...field}
                      />
                    )}
                  />
                  <FieldError error={errors.ap_fuel_to} />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" size="large">{update ? 'Update' : 'Save' }</Button>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default FuelPriceForm;

import { useState } from 'react';
import toast from 'react-hot-toast';
import {
  useDeleteCrewRosterDutyMutation, useGetCrewRosterQuery, useGetDutyListQuery, useUpdateDutyListMutation
} from '../../store/session';
import { stringifyLocationFiltration } from '../../utils/locationSearch';
import { DEFAULT_MONTH, DEFAULT_YEAR, MONTH_SHORT } from './constants';
import { CREW_ENGINEER, CREW_SAAB } from '../../constans/crew';
import { getMultiselectOptions } from '../../utils/getOptions';

export const useTechniciansCrewRoster = () => {
  const [year, setYear] = useState(DEFAULT_YEAR);
  const [months, setMonths] = useState(DEFAULT_MONTH);

  const numberMonths = MONTH_SHORT.reduce((total, amount, index) => {
    const monthIndex = [...total, months.includes(amount) && index + 1];
    const res = monthIndex.filter((e) => e);

    return res;
  }, []);

  const [updateDutyList, { isLoading: isLoadingUpdate }] = useUpdateDutyListMutation();
  const [deleteDutyList, { isLoading: isLoadingDelete }] = useDeleteCrewRosterDutyMutation();
  const { data: list } = useGetDutyListQuery(CREW_ENGINEER);
  const { data, refetch: refetchCrewData, isLoading } = useGetCrewRosterQuery(
    {
      data: stringifyLocationFiltration({
        year,
        months: numberMonths,
      }),
      type: CREW_ENGINEER,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const rostersOptions = getMultiselectOptions(data);

  const handleDelete = async (id) => {
    const res = await deleteDutyList(id);
    const error = res?.error?.data?.message;

    if (error) {
      toast.error(error);
    }

    refetchCrewData();
  };

  const handleUpdateDutyList = async (values) => {
    const res = await updateDutyList({ ...values, roster_type: CREW_ENGINEER });
    const error = res?.error?.data?.message;

    if (error) {
      toast.error(error);
    }

    refetchCrewData();
  };

  return {
    list,
    year,
    months,
    data,
    rostersOptions,
    isLoading: isLoading || isLoadingDelete || isLoadingUpdate,
    handleDelete,
    handleUpdateDutyList,
    setYear,
    setMonths,
  };
};

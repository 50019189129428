import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import FiltrationDate from '../../../../components/EditableTable/FiltrationDate';
import FiltrationInput from '../../../../components/EditableTable/FiltrationInput';
import { HeaderName, StyledPencil } from '../../../../components/EditableTable/styles';
import LinkButton from '../../../../components/LinkButton';

/**
 * @param handleFilter
 * @param filters
 * @returns {[{headerName: string, field: string, renderHeader: (function(): *), flex: number, minWidth: number, renderCell: (function({row: *}): *)}]}
 */
export const columns = ({
  handleFilter,
  filters
}) => ([
  {
    width: 100,
    field: 'actions',
    headerName: '',
    sortable: false,
    renderCell: ({ row }) => (
      <LinkButton path={`/maintenance-aircraft/update/${row.id}`}>
        <StyledPencil />
      </LinkButton>
    ),
  },
  {
    flex: 0.2,
    field: 'aircraft_registration',
    minWidth: 180,
    headerName: 'Registration',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Registration</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.aircraft_registration || ''}
          value="aircraft_registration"
          placeholder="Registration"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.aircraft_registration}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'date',
    minWidth: 200,
    headerName: 'Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          '& .MuiPickersCalendarHeader-root': {
            display: 'none'
          }
        }}
      >
        <Typography style={HeaderName}>Date</Typography>
        <FiltrationDate
          value={filters.date || null}
          onChange={(e) => {
            handleFilter(e, 'date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        {row.date}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'aircraft_data_fh',
    minWidth: 180,
    headerName: 'FH',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>FH</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.aircraft_data_fh || ''}
          value="aircraft_data_fh"
          placeholder="FH"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.aircraft_data_fh}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'aircraft_data_cyc',
    minWidth: 180,
    headerName: 'CYC',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>CYC</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.aircraft_data_cyc || ''}
          value="aircraft_data_cyc"
          placeholder="CYC"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.aircraft_data_cyc}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'aircraft_engine_lh_data_fh',
    minWidth: 180,
    headerName: 'LH FH',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>LH FH</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.aircraft_engine_lh_data_fh || ''}
          value="aircraft_engine_lh_data_fh"
          placeholder="LH FH"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.aircraft_engine_lh_data_fh}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'aircraft_engine_lh_data_cyc',
    minWidth: 180,
    headerName: 'LH CYC',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>LH CYC</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.aircraft_engine_lh_data_cyc || ''}
          value="aircraft_engine_lh_data_cyc"
          placeholder="LH CYC"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.aircraft_engine_lh_data_cyc}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'aircraft_engine_rh_data_fh',
    minWidth: 180,
    headerName: 'RH FH',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>RH FH</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.aircraft_engine_rh_data_fh || ''}
          value="aircraft_engine_rh_data_fh"
          placeholder="RH FH"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.aircraft_engine_rh_data_fh}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'aircraft_engine_rh_data_cyc',
    minWidth: 180,
    headerName: 'RH CYC',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>RH CYC</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.aircraft_engine_rh_data_cyc || ''}
          value="aircraft_engine_rh_data_cyc"
          placeholder="RH CYC"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{row.aircraft_engine_rh_data_cyc}</Typography>
    ),
  }
]);

import Typography from '@mui/material/Typography';
import moment from 'moment';
import React from 'react';
import ButtonIcon from '../../../../../components/ButtonIcon';
import { linkSx, StyledDelete } from '../../../../../components/EditableTable/styles';

export const columns = ({ handleDownload, handleOpenDeleteModal, hasPermissionsWoEdit }) => ([
  {
    flex: 0.2,
    field: 'name',
    minWidth: 180,
    headerName: 'Name',
    renderCell: ({ row: { name, id, } }) => (
      <Typography sx={linkSx} onClick={() => handleDownload({ name, id })}>{name}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'created_at',
    minWidth: 180,
    headerName: 'Upload Date',
    renderCell: ({ row }) => {
      const month = moment(row.created_at).month();
      const day = row.created_at.split(' ')[1].split('').filter(item => item !== ',').join('');
      const year = moment(row.created_at).year();

      return <Typography>{`${moment(`${+month + 1}-${day}-${year}`).format('MMMM')} ${day}, ${year}`}</Typography>;
    },
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <ButtonIcon disabled={!hasPermissionsWoEdit} onClick={() => handleOpenDeleteModal(row.id)}>
        <StyledDelete />
      </ButtonIcon>
    ),
  },
]);

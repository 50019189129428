import React from 'react';
import {
  Card, CardContent
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '../../../components/Avatar/Avatar';
import EuroIcon from '@mui/icons-material/Euro';
import { TextWithIcon } from '../../Logistics/style';

const CardStatistics = props => {
  const {
    title, icon, stats, color = 'success',
  } = props;

  return (
    <Card>
      <CardContent sx={{ py: theme => `${theme.spacing(4)} !important` }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar skin="light" color={color} variant="rounded">
            {icon}
          </Avatar>
          <Box sx={{ display: 'flex', flexDirection: 'column', pl: '8px' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <Typography variant="h6" sx={TextWithIcon}>
                <EuroIcon fontSize="small" />
                {stats}
              </Typography>
            </Box>
            <Typography variant="caption">{title}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardStatistics;

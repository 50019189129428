export const BtnActive = {
  backgroundColor: ' #004688'
};

export const DefaultBtn = {
  color: '#fff',
  textTransform: 'capitalize',
  padding: '8px 13px'
};

export const DefaultBtnMounth = {
  color: '#fff',
  textTransform: 'capitalize',
  padding: '8px 13px',
  width: '100%',
  '&.MuiButtonGroup-grouped': {
    minWidth: 'unset',
    width: 'fit-content',
    padding: '7px 22px',
  }
};

export const DefaultBtnMounthX = {
  borderColor: 'unset !important',
  borderRadius: '8px !important',
  '&.MuiButtonGroup-grouped': {
    width: '100%',
  }
};

export const ButtonContainer = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const ButtonContainerX = {
  flexDirection: 'column',
  width: '100%',
  gap: 10
};

export const BtnGroupX = {
  boxShadow: 'unset',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: '15px'
};

export const AllMonths = {
  whiteSpace: 'nowrap',
  minWidth: '125px',
};

export const BtnGroup = {
  width: 'fit-content',
  whiteSpace: 'nowrap',
};

export const DefaultBtnX = {
  width: 'calc(50% - 15px)',
  borderColor: 'unset !important',
  borderRadius: '8px !important',
};

export const BtnGroupXs = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '10px',
  boxShadow: 'unset',
  marginTop: '25px'
};

export const ButtonContainerXs = {
  width: '100%',
};

export const DefaultBtnXs = {
  borderColor: 'unset !important',
  borderRadius: '8px !important',
  width: '100%',
};

export const BtnSend = {
  gap: 2,
  '&.Mui-disabled': {
    backgroundColor: 'rgb(96 86 86 / 26%)'
  },
  '&:hover': {
    backgroundColor: '#004688 !important'
  }
};

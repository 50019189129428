import React from 'react';
import TypeCard from '../TypeCard';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Select from '../../../../form/components/Select';
import FormGroup from '../../../../form/components/FormGroup';
import DatePicker from '../../../../form/components/DatePicker';
import Input from '../../../../form/components/Input';
import { Controller } from 'react-hook-form';
import {
  boldSx,
  flightTitle,
  flightTitleXs,
  containerSx
} from './styles';
import FieldError from '../../../../form/components/FieldError';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import { useMediaQuery } from '@mui/material';

const Flight = ({
  control, isTypeF, getValues, isPanel, setValue, errors = {}, sx, odsTocOptions, odsStatusesOptions,
}) => {
  const maxWidth1510px = useMediaQuery('(max-width:1510px');
  const { aircraft_registration, aircraft_type_name, index } = getValues();

  return (
    <TypeCard title={isTypeF ? 'FLIGHT' : 'MAINTENANCE IN '} variant="sky" isPanel={isPanel} sx={sx}>
      <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1, ...containerSx(isPanel) }}>
        <Grid item xs={12} lg={isPanel ? 4 : 3}>
          <Typography sx={{ ...flightTitle, ...(maxWidth1510px && flightTitleXs) }}>
            AC/REG
            <Typography component="span" sx={boldSx}>
              {` ${aircraft_registration}`}
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} lg={isPanel ? 4 : 3}>
          <Typography sx={{ ...flightTitle, ...(maxWidth1510px && flightTitleXs) }}>
            AC TYPE
            <Typography component="span" sx={boldSx}>
              {` ${aircraft_type_name}`}
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} lg={isPanel ? 4 : 3}>
          <Typography sx={{ ...flightTitle, ...(maxWidth1510px && flightTitleXs) }}>
            SHEET NO.
            <Typography component="span" sx={boldSx}>
              {` ${index}`}
            </Typography>
          </Typography>
        </Grid>

        {!isPanel && (
          <Grid item xs={12} lg={3}>
            <FormGroup label="STATUS">
              <Controller
                name="ods_status"
                control={control}
                render={({ field }) => (
                  <Select
                    options={odsStatusesOptions}
                    placeholder="STATUS"
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        )}

        <Grid item xs={12} sm={12} lg={4}>
          <FormGroup label="AC TAKEN OVER AT">
            <Controller
              name="ac_taken_at"
              control={control}
              render={({ field }) => (
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm"
                  showMonthDropdown
                  showYearDropdown
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 'ac_taken_at');
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} sm={12} lg={4}>
          <FormGroup label="AC HANDED OVER AT">
            <Controller
              name="ac_handed_over_at"
              control={control}
              render={({ field }) => (
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm"
                  showMonthDropdown
                  showYearDropdown
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 'ac_handed_over_at');
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} sm={12} lg={4}>
          <FormGroup label={isTypeF ? 'SECURITY CHECK AT' : 'ODS DATE'}>
            <Controller
              name={isTypeF ? 'security_check_at' : 'departureDate'}
              control={control}
              render={({ field }) => (
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  placeholderText="YYYY-MM-DD HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm"
                  showMonthDropdown
                  showYearDropdown
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, isTypeF ? 'security_check_at' : 'departureDate');
                  }}
                  value={validateDatePickerValue(field.value)}
                />
              )}
            />
          </FormGroup>
        </Grid>

        {isTypeF && (
        <>
          <Grid item xs={12} sm={12} lg={4}>
            <FormGroup label="FLT/NR">
              <Controller
                name="flight_number"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="FLT/NR"
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={12} lg={8}>

            <FormGroup label="TOC" required={isPanel} hasError={!!errors.toc}>
              <Controller
                name="toc"
                control={control}
                render={({ field }) => (
                  <Select
                    options={odsTocOptions}
                    placeholder="TOC"
                    {...field}
                  />
                )}
              />
              <FieldError error={errors.toc} />
            </FormGroup>
          </Grid>
        </>
        )}
      </Grid>
    </TypeCard>
  );
};

export default Flight;

import React from 'react';
import TypeCard from '../TypeCard';
import Grid from '@mui/material/Grid';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { Controller } from 'react-hook-form';

const PreviousHrs = ({ control, isPanel }) => (
  <TypeCard title="PREVIOUS HRS/LDGS" variant="yellow" isPanel={isPanel}>
    <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1 }}>
      <Grid item xs={12} lg={4}>
        <FormGroup label="PREVIOUS BLOCK">
          <Controller
            name="previous_block"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={4}>
        <FormGroup label="PREVIOUS AIR">
          <Controller
            name="previous_air"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={4}>
        <FormGroup label="PREVIOUS LDG">
          <Controller
            name="previous_ldg"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>
    </Grid>
  </TypeCard>
);

export default PreviousHrs;

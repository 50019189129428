import Box from '@mui/material/Box';
import React from 'react';
import Flight from './Flight';
import { useOdsPositionForm } from './useOdsPositionForm';

const OdsTrainingForm = ({ data, isSubmit, isOffline }) => {
  const {
    control,
    pilotNameOptions,
    getValues,
    watch,
    trigger,
    setValue,
    setCrewPost
  } = useOdsPositionForm({ data, isSubmit, isOffline });

  return (
    <Box>
      <Flight
        control={control}
        pilotNameOptions={pilotNameOptions}
        setValue={setValue}
        trigger={trigger}
        setCrewPost={setCrewPost}
        getValues={getValues}
        watch={watch}
        isPanel
      />
    </Box>
  );
};

export default OdsTrainingForm;

import React from 'react';
// ** MUI Components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import BadgeIcon from '@mui/icons-material/Badge';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupIcon from '@mui/icons-material/Group';
// ** Icon Imports
const renderList = arr => {
  if (arr && arr.length) {
    return arr.map((item, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          '&:not(:last-of-type)': { mb: 4 },
          '& svg': { color: 'text.secondary' }
        }}
      >
        {item.icon}

        <Box sx={{
          columnGap: 2, display: 'flex', flexWrap: 'wrap', alignItems: 'center'
        }}
        >
          <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
            {`${item.property.charAt(0).toUpperCase() + item.property.slice(1)}:`}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {item.value.charAt(0).toUpperCase() + item.value.slice(1)}
          </Typography>
        </Box>
      </Box>
    ));
  }

  return null;
};

const renderTeams = arr => {
  if (arr && arr.length) {
    return arr.map((item, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          alignItems: 'center',
          '&:not(:last-of-type)': { mb: 4 },
          // '& svg': { color: `${item.color}.main` }
        }}
      >
        <Typography sx={{ mx: 2, fontWeight: 600, color: 'text.secondary' }}>
          {item.charAt(0).toUpperCase() + item.slice(1)}
        </Typography>
      </Box>
    ));
  }

  return null;
};

const AboutOverivew = ({ user }) => {
  const teams = user.positions.map(position => position.position_name);
  const about = [
    {
      icon: <PersonIcon sx={{ mr: 4 }} />,
      property: 'Full Name',
      value: user.name,
    },
    {
      icon: <EmailIcon sx={{ mr: 4 }} />,
      property: 'Email',
      value: user.email,
    },
    {
      icon: <BadgeIcon sx={{ mr: 4 }} />,
      property: 'Site Username',
      value: user.username,
    },
    {
      icon: <AdminPanelSettingsIcon sx={{ mr: 4 }} />,
      property: 'Role',
      value: user.role,
    },
  ];

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box sx={{ mb: 7 }}>
              <Typography variant="body2" sx={{ mb: 4, color: 'text.disabled', textTransform: 'uppercase' }}>
                About
              </Typography>
              {renderList(about)}
            </Box>
            {teams.length ? (
              <Box>
                <Typography variant="body2" sx={{ mb: 4, color: 'text.disabled', textTransform: 'uppercase' }}>
                  Positions
                </Typography>
                {renderTeams(teams)}
              </Box>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AboutOverivew;

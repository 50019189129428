import React from 'react';
import Box from '@mui/material/Box';
import { FPD_COLORS } from '../constants';

export const getLimit = (time = '') => +time.split(':')[0];

export const ColumnCell = ({
  value, limit, isPdf, fdp
}) => (getLimit(value) > limit ? (
  <Box sx={{
    backgroundColor: !isPdf && '#e1e176',
    height: '32px',
    p: '2px 5px',
    m: '0 auto',
    lineHeight: '28px',
  }}
  >
    {value}
  </Box>
) : (
  <Box sx={{ backgroundColor: FPD_COLORS[fdp], height: '32px' }}>
    {value}
  </Box>
)
);

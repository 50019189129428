export const changeCurrentPartsData = (partStr = '', tableData = []) => {
  if (!partStr) {
    return null;
  }
  
  let partStrToArray = partStr.split(';').filter(el => !!el);

  tableData.forEach(({
    part_off_number, serial_off_number, part_on_number, serial_on_number 
  }) => {  
    partStrToArray = partStrToArray.filter((item) => !(
      ((!!part_off_number && item.includes(part_off_number))
      && (!!serial_off_number && item.includes(serial_off_number)))
      || ((!!part_on_number && item.includes(part_on_number))
      && (!!serial_on_number && item.includes(serial_on_number)))
    ));
  });

  const partWithFilter = partStrToArray.join(';');

  return partWithFilter;
};
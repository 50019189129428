import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Card, Divider, Grid, Typography
} from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../../../components/Button';
import DatePicker from '../../../../../../form/components/DatePicker';
import { useUpdateExTypeOrderMutation } from '../../../../../../store/slices/logisticsSlice';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import { headerBtn } from '../../../../../Invoices/style';
import { Filters, GreenButton } from '../../../../style';
import { defaultValues, schema } from './schema';

const ExchangeDueDate = (props) => {
  const {
    orderId = '',
    exchangeDueDate = '',
    refetchOrder = () => {}
  } = props;

  const [updateExchange] = useUpdateExTypeOrderMutation();

  const {
    handleSubmit,
    setValue,
    control
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const res = await updateExchange({ id: orderId, data });

    if (res?.error) {
      toast.error(res?.error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    refetchOrder();
    toast.success('Successfully!', {
      id: toastId
    });
  };

  useEffect(() => {
    setValue('exchange_due_date', moment(exchangeDueDate).format('YYYY-MM-DD'));
  }, [exchangeDueDate]);
  
  return (
    <Box sx={{ mt: 6 }}>
      <form>
        <Card>
          <Box sx={{ ...headerBtn, mb: 4 }}>
            <Box>
              <Typography variant="h5" noWrap>
                Enter Exchange Due Date.
              </Typography>
            </Box>
          </Box>

          <Grid container>
            <Grid item xs={4}>
              <Controller
                name="exchange_due_date"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <DatePicker
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'exchange_due_date', 'YYYY-MM-DD');
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{ mt: 4 }}>
              <Divider />
              <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
                <Box>
                  <Button
                    sx={{ mt: 2, ...(GreenButton) }}
                    variant="contained"
                    size="large"
                    // disabled={!isLoading}
                    onClick={handleSubmit(onSubmit)}
                    title="Save"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Box>
  );
};

export default ExchangeDueDate;
import {
  number, object, string
} from 'yup';

export const defaultValues = {
  price_id: 0,
  approver_notice: ''
};

export const schema = object().shape({
  price_id: number()
    .required()
    .label('Price'),
  approver_notice: string()
    .label('Price Notice')
});

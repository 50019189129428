export const defaultValues = {
  to: 'bridges.ops.centre@bridgesww.com',
  cc: 'sales@fleetair.eu',
  subject: 'FLIGHT CONFIRMATION NULL-NULL / HA-TAB / NULL (4582)',
  description: `TO: BRIDGES WORLDWIDE (NETWORK SOLUTIONS) LTD
  ATTN: NULL

  WE KINDLY CONFIRM YOU A CARGO/CHARTER FLIGHT NULL - NULL AS FOLLOWS:

  A/C TYPE: SAAB340A
  A/C REG: HA-TAB

  PRICE:  EUR

  SCHEDULE (ALL TIME UTC):
  29 DEC 2022 FRF100 LIME ETD/13:21 - 29 DEC 2022 EGBB ETA/16:20 UTC - FERRY

  CARGO TYPE: GENERAL
  DIMENSION:
  WEIGHT: NULL KG
  AWB:
  PLS ADV BRIDGES WORLDWIDE (NETWORK SOLUTIONS) LTD REFERENCE NUMBER OF THE FLIGHT

  HANDLING AGENTS FOR NULL:

  HANDLING AGENTS FOR NULL:

  BEST REGARDS,
  TYMOFIEIEV DENYS
  FLEET AIR OPS
  TEL: +36 1 4453979 (24H)
  FAX: +36 1 5772378
  SITA: BUDFA7X AFTN: KAIRFRFX
  EMAIL: SALES@FLEETAIR.EU`
};

import React from 'react';
import { Grid } from '@mui/material';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { usePermissions } from '../../utils/hooks/usePermissions';

const buttons = [
  {
    title: 'FLIGHTS SCHEDULE',
    link: 'maindeck/schedule',
    permissions: 'flights_schedule_access'
  },
  {
    title: 'SAAB CREW ROSTER',
    permissions: 'roster_saab_access',
    link: 'roster/saab',
  },
  {
    title: 'ATR CREW ROSTER',
    permissions: 'roster_atr_access',
    link: 'roster/atr',
  },
  {
    title: 'TECHNICIAN CREW ROSTER',
    permissions: 'roster_tech_access',
    link: 'roster/technicians',
  },
  {
    title: 'OPS CREW ROSTER',
    permissions: 'roster_ops_access',
    link: 'roster/ops',
  },
  {
    title: 'DAILY AVAILABILITY',
    permissions: 'daily_availability_access',
    link: '/daily_availability',
  },
];

const ButtonsGroup = () => {
  const maxWidth685px = useMediaQuery('(max-width:685px)');
  const navigator = useNavigate();

  const hasPermissions = (permission) => usePermissions(permission);

  const handleClick = (rout) => {
    navigator(rout);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        ...(maxWidth685px && { display: 'flex', flexDirection: 'column' }),
      }}
    >
      {buttons.map((i, index) => (
        <Grid
          key={index}
          item
          spacing={2}
          sx={{
            ...(maxWidth685px && { width: '100%' }),
          }}
        >
          <Button
            title={i.title}
            disabled={i.permissions ? !hasPermissions(i.permissions) : false}
            onClick={() => handleClick(i.link)}
            sx={{
              ...(maxWidth685px && { width: '100%' }),
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ButtonsGroup;

import { Box, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import FieldError from '../../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../../form/components/FormGroup';
import Input from '../../../../../../../form/components/Input';
import ServerAutocomplete from '../../../../../../../form/components/ServerAutocomplete';
import { useGetInternalAutocompletePartsQuery } from '../../../../../../../store/slices/internalLogisticsSlice';

const AutocompleteForm = (props) => {
  const {
    errors,
    control,
    handleSelectPart,
    currentPart,
    currentPartNumber,
    currentPartSerial,
    setValue,
    handleChangePartOnType
  } = props;

  const [serialNumberValue, setSerialNumberValue] = useState('');

  const handleAddNewPart = () => {
    setValue('serial_on_number', serialNumberValue);
    handleChangePartOnType('manual');
  };

  return (
    <>
      <Grid item xs={6}>
        <FormGroup label="Storage Part ID" hasError={errors?.part_id}>
          <Controller
            name="part_id"
            control={control}
            render={({ field }) => (
              <ServerAutocomplete
                field={field}
                value={currentPart}
                withData
                placeholder="Search Storage Parts"
                searchQueryFn={useGetInternalAutocompletePartsQuery}
                handleSelect={(item) => handleSelectPart(item, 'id')}
                getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.value} - ${option?.label}`) || ''}
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={6}>
        <FormGroup label="Storage Part ID - Serial Number" hasError={errors?.part_id}>
          <Controller
            name="part_id"
            control={control}
            render={({ field }) => (
              <ServerAutocomplete
                field={field}
                value={currentPartSerial}
                withData
                placeholder="Search Serial Number"
                searchQueryFn={useGetInternalAutocompletePartsQuery}
                handleSelect={(item) => handleSelectPart(item, 'serial')}
                queryParams={{
                  search_row: 'serial_number',
                  is_part_on: true
                }}
                getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.data?.serial_number} - ${option?.label}`) || ''}
                noOptionsText={(
                  <Box sx={{ width: '100%', cursor: 'pointer' }} onClick={handleAddNewPart}>
                    + Add
                    {' '}
                    {serialNumberValue && `"${serialNumberValue}"`}
                  </Box>
                )}
                onInputChangeCallback={(_, value, type) => {
                  if (type === 'reset') return;
                  setSerialNumberValue(value);
                }}
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={6}>
        <FormGroup label="Storage Part ID - Part Number" hasError={errors?.part_id}>
          <Controller
            name="part_id"
            control={control}
            render={({ field }) => (
              <ServerAutocomplete
                field={field}
                value={currentPartNumber}
                withData
                placeholder="Search Part Number"
                searchQueryFn={useGetInternalAutocompletePartsQuery}
                handleSelect={(item) => handleSelectPart(item, 'number')}
                queryParams={{
                  search_row: 'part_number'
                }}
                getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.data?.part_number} - ${option?.data?.storage}`) || ''}
              />
            )}
          />
        </FormGroup>
      </Grid>

      {(currentPart || currentPartSerial || currentPartNumber) && (
      <Grid container spacing={6} sx={{ padding: '0px  24px' }}>
        <Grid item xs={4}>
          <FormGroup label="Part Number" hasError={!!errors?.part_on_number}>
            <Controller
              control={control}
              name="part_on_number"
              render={({ field }) => (
                <Input
                  {...field}
                  disabled
                  sx={{ width: '100%' }}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={4}>
          <FormGroup label="Serial Number" hasError={!!errors?.serial_on_number}>
            <Controller
              control={control}
              name="serial_on_number"
              render={({ field }) => (
                <Input
                  {...field}
                  disabled
                  sx={{ width: '100%' }}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={4}>
          <FormGroup label="Part Name" hasError={!!errors?.part_name}>
            <Controller
              control={control}
              name="part_name"
              render={({ field }) => (
                <Input
                  {...field}
                  disabled
                  sx={{ width: '100%' }}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={4}>
          <FormGroup label="Storage" hasError={!!errors.storage}>
            <Controller
              control={control}
              name="storage"
              render={({ field }) => (
                <Input
                  {...field}
                  disabled
                  sx={{ width: '100%' }}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={4}>
          <FormGroup label="Quantity" hasError={!!errors.quantity}>
            <Controller
              control={control}
              name="quantity"
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  placeholder="Quantity"
                  size="small"
                  onChange={(e) => {
                    if ((e.target.value > currentPart?.data?.quantity)
                        || (e.target.value > currentPartSerial?.data?.quantity)
                        || (e.target.value > currentPartNumber?.data?.quantity)
                    ) return;
                    field.onChange(e.target.value);
                  }}
                />
              )}
            />
            <FieldError error={{ message: `Min 1 - Max ${currentPart?.data?.quantity || currentPartSerial?.data?.quantity || currentPartNumber?.data?.quantity}` }} sx={{ color: 'rgba(76, 78, 100, 0.87)', }} />
          </FormGroup>
        </Grid>
      </Grid>
      )}
    </>
  );
};

export default AutocompleteForm;

import React, { useState } from 'react';
import {
  Box, Button, Divider, IconButton, Paper, Slide, TextField
} from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { GreenButton, RedButton, TextWithIcon } from '../../pages/Logistics/style';
import CalculateIcon from '@mui/icons-material/Calculate';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/named
import { setTimeCalculator } from '../../store/session';
import { useAppDispatch } from '../../hooks/toolkitHooks';

export const TimeCalculator = () => {
  const dispatch = useAppDispatch();
  const [time1, setTime1] = useState('');
  const [time2, setTime2] = useState('');
  const [result, setResult] = useState('');
  const { openTimeCalculator } = useSelector((state) => state.session);
  const [collapsed, setCollapsed] = useState(false);

  // Convert hh:mm format to seconds
  const convertToSeconds = (time) => {
    const [hours, minutes] = time.split(':').map(Number);

    return (parseInt(hours || 0, 10) * 3600) + (parseInt(minutes || 0, 10) * 60);
  };

  // Convert seconds to hhhh:mm format
  const convertFromSeconds = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  const isValidTimeFormat = (time) => /^\d+:[0-5]\d$/.test(time);

  const addTime = () => {
    if (isValidTimeFormat(time1) && isValidTimeFormat(time2)) {
      const totalSeconds = convertToSeconds(time1) + convertToSeconds(time2);
      setResult(convertFromSeconds(totalSeconds));
    } else {
      setResult('Invalid format.');
    }
  };

  const subtractTime = () => {
    if (isValidTimeFormat(time1) && isValidTimeFormat(time2)) {
      const totalSeconds = Math.abs(convertToSeconds(time1) - convertToSeconds(time2));
      setResult(convertFromSeconds(totalSeconds));
    } else {
      setResult('Invalid format.');
    }
  };

  const clearAll = () => {
    setTime1('');
    setTime2('');
    setResult('');
  };

  return (
    <Slide
      direction="up"
      in={openTimeCalculator}
      mountOnEnter
      unmountOnExit
    >
      <Paper
        elevation={4}
        sx={{
          position: 'fixed',
          bottom: 10,
          right: 10,
          maxWidth: 300,
          borderRadius: '8px',
          p: 4,
          boxShadow: 4,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box sx={TextWithIcon}>
            <CalculateIcon sx={{ marginRight: 2 }} />
            <Typography variant="h6">Time Calculator</Typography>
          </Box>

          <Box>
            <IconButton onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
            <IconButton onClick={() => dispatch(setTimeCalculator(false))}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        {!collapsed && (
          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <TextField
              label="Time 1"
              type="text"
              value={time1}
              onChange={(e) => setTime1(e.target.value)}
              size="small"
              placeholder="0000:00"
              autoComplete="off"
              helperText="Format: hhhh:mm"
            />
            <TextField
              label="Time 2"
              type="text"
              value={time2}
              onChange={(e) => setTime2(e.target.value)}
              size="small"
              placeholder="0000:00"
              autoComplete="off"
              helperText="Format: hhhh:mm"
            />

            <Box display="flex" gap={1} justifyContent="center" mt={2}>
              <Button variant="contained" onClick={addTime} size="small" sx={GreenButton}>
                <AddIcon />
              </Button>
              <Button variant="contained" onClick={subtractTime} size="small" sx={RedButton}>
                <RemoveIcon />
              </Button>
              <Button variant="contained" onClick={clearAll} size="small">
                AC
              </Button>
            </Box>

            <Divider />

            <Box mt={2} sx={{ minHeight: '32px' }}>
              <Typography variant="h6" sx={{ textAlign: 'center' }}>
                {result}
              </Typography>
            </Box>
          </Box>
        )}
      </Paper>
    </Slide>
  );
};

import React from 'react';
import { useTrainingReportForm } from './useTrainingReportForm';
import {
  Card, CardContent, Divider, Grid, InputLabel, Switch, TextField
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '../../../../components/Button/Button';
import { GreenButton, RedButton } from '../../../Logistics/style';
import SendIcon from '@mui/icons-material/Send';
import { FormHeader, SwitchContent } from './styles';
import logoBlack from '../../../../assets/img/fleet-logo-black.png';
import { Logo } from '../../../Login/styles';
import Typography from '@mui/material/Typography';
import { Controller } from 'react-hook-form';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { content } from '../../../Banks/style';
import { btnS, headerBtn } from '../../../Invoices/style';
import Select from '../../../../form/components/Select';
import { POSITIONS, YES_NO } from '../constants';
import Autocomplete from '@mui/material/Autocomplete';
import { Plus } from 'mdi-material-ui';
import { defaultValues } from './schema';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import UpgradeIcon from '@mui/icons-material/Upgrade';

export const TrainingReportForm = () => {
  const {
    onSubmit,
    CrewList,
    isUpdate,
    form: {
      watch,
      handleSubmit,
      setValue,
      control,
      formState: { errors }
    },
    trainees: {
      fields,
      append,
      remove
    },
    trainingTitles: {
      fields: trainingFields,
      append: trainingAppend,
      remove: trainingRemove
    },
    instructorsOptions,
    onPreview,
    handleCrewListOptions
  } = useTrainingReportForm();

  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
        <Card>
          <CardContent>
            <Grid container spacing={6}>
              <Grid item xs={7}>
                <Box sx={{
                  ...(FormHeader),
                  backgroundColor: 'whitesmoke',
                  padding: 5,
                  radius: '8px',
                  height: '100%',
                  justifyContent: 'center'
                }}
                >
                  <img src={logoBlack} style={Logo} alt="logoWhite" />
                  <Typography variant="subtitle1">
                    OPERATIONS MANUAL PART D
                  </Typography>
                  <Typography variant="subtitle1">
                    APPENDIX
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={SwitchContent}>
                      <InputLabel>
                        Issue Number:
                      </InputLabel>
                      <FormGroup hasError={!!errors.issue_number}>
                        <Controller
                          name="issue_number"
                          control={control}
                          render={({ field }) => (
                            <Input
                              placeholder="Issue Number"
                              {...field}
                            />
                          )}
                        />

                        <FieldError error={errors.issue_number} />
                      </FormGroup>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={SwitchContent}>
                      <InputLabel>
                        Issue Date:
                      </InputLabel>
                      <FormGroup hasError={!!errors.issue_date}>
                        <Controller
                          name="issue_date"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              value={validateDatePickerValue(field.value)}
                              onChange={(e) => {
                                field.onChange(e);
                                setDateValue(e, setValue, 'issue_date');
                              }}
                              placeholderText="Issue Date"
                              dateFormat="yyyy-MM-dd"
                              placeholder="yyyy-MM-dd"
                              showMonthDropdown
                              showYearDropdown

                            />
                          )}
                        />
                        <FieldError error={errors.issue_date} />
                      </FormGroup>
                    </Box>

                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={SwitchContent}>
                      <InputLabel>
                        Revision Number:
                      </InputLabel>
                      <FormGroup hasError={!!errors.revision_number}>
                        <Controller
                          name="revision_number"
                          control={control}
                          render={({ field }) => (
                            <Input
                              placeholder="Revision Number"
                              {...field}
                            />
                          )}
                        />

                        <FieldError error={errors.revision_number} />
                      </FormGroup>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={SwitchContent}>
                      <InputLabel>
                        Revision Date:
                      </InputLabel>
                      <FormGroup hasError={!!errors.revision_date}>
                        <Controller
                          name="revision_date"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              value={validateDatePickerValue(field.value)}
                              onChange={(e) => {
                                field.onChange(e);
                                setDateValue(e, setValue, 'revision_date');
                              }}
                              placeholderText="Revision Date"
                              dateFormat="yyyy-MM-dd"
                              placeholder="2023-01-01"
                              showMonthDropdown
                              showYearDropdown
                            />
                          )}
                        />
                        <FieldError error={errors.revision_date} />
                      </FormGroup>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={1} sx={{ mt: 3, mb: 5 }}>
              <Grid item xs={12}>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <FormGroup label="Training Place" hasError={!!errors.training_place}>
                      <Controller
                        name="training_place"
                        control={control}
                        render={({ field }) => (
                          <Input
                            placeholder="Training Place"
                            {...field}
                          />
                        )}
                      />

                      <FieldError error={errors.training_place} />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={6}>
                    <FormGroup label="Training Date" hasError={!!errors.training_date}>
                      <Controller
                        name="training_date"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            value={validateDatePickerValue(field.value)}
                            onChange={(e) => {
                              field.onChange(e);
                              setDateValue(e, setValue, 'training_date');
                            }}
                            dateFormat="yyyy-MM-dd"
                            placeholder="2023-01-01"
                            showMonthDropdown
                            showYearDropdown
                          />
                        )}
                      />

                      <FieldError error={errors.training_date} />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={6}>
                    <FormGroup label="Training Title" hasError={!!errors.training_title}>
                      <Controller
                        name="training_title"
                        control={control}
                        render={({ field }) => (
                          <Input
                            placeholder="Training Title"
                            {...field}
                          />
                        )}
                      />

                      <FieldError error={errors.training_title} />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={6}>
                    <FormGroup label="Training Reference" hasError={!!errors.training_reference}>
                      <Controller
                        name="training_reference"
                        control={control}
                        render={({ field }) => (
                          <Input
                            placeholder="Training Reference"
                            {...field}
                          />
                        )}
                      />

                      <FieldError error={errors.training_reference} />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={1} sx={{ mt: 3, mb: 5 }}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  TRAINEES:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box sx={content}>
                  {fields?.map((item, index) => (
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                        backgroundColor: 'whitesmoke',
                        padding: '5px',
                        borderRadius: '8px',
                        justifyContent: 'flex-start'
                      }}
                      key={item.id}
                    >
                      <Grid item xs={12} lg={12}>
                        <Box sx={{ ...(headerBtn), justifyContent: 'space-between' }}>
                          <Typography noWrap variant="h6">
                            #
                            {' '}
                            {index + 1}
                          </Typography>

                          {index !== 0 && (
                            <Button
                              sx={{ ...(RedButton), fontWeight: 600 }}
                              endIcon={<CloseIcon />}
                              size="small"
                              title="Delete"
                              onClick={() => {
                                remove(index);
                              }}
                            />
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Position" hasError={!!errors?.trainees?.[index]?.position}>
                          <Controller
                            name={`trainees.${[index]}.position`}
                            control={control}
                            render={({ field }) => (
                              <Select
                                options={POSITIONS}
                                placeholder="Position"
                                sx={{ width: '80%' }}
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors?.trainees?.[index]?.position} />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Passed Satisfactory?" hasError={!!errors?.trainees?.[index]?.passed_satisfactory}>
                          <Controller
                            name={`trainees.${[index]}.passed_satisfactory`}
                            control={control}
                            render={({ field }) => (
                              <Select
                                options={YES_NO}
                                placeholder="Passed Satisfactory?"
                                sx={{ width: '80%' }}
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors?.trainees?.[index]?.passed_satisfactory} />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Crew" hasError={!!errors?.trainees?.[index]?.crew_id}>
                          <Controller
                            name={`trainees.${[index]}.crew_id`}
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                freeSolo
                                value={field.value ? CrewList?.filter((CrewItem) => CrewItem.id === field.value)[0] : null}
                                options={handleCrewListOptions(CrewList, watch(`trainees.${[index]}.position`))}
                                getOptionLabel={(option) => `${option.crew_shortname} ${option.crew_name}` || ''}
                                isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    inputRef={field.ref}
                                  />
                                )}
                                onChange={(e, data) => {
                                  field.onChange(data?.id);
                                }}
                              />
                            )}
                          />

                          <FieldError error={errors?.trainees?.[index]?.crew_id} />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid container sx={{ mt: 2.75, mb: 2.75, justifyContent: 'center' }}>
                    <Grid item xs={4} sx={{ px: 0 }}>
                      <Button
                        title="Add"
                        startIcon={<Plus />}
                        sx={{ ...btnS, ...GreenButton }}
                        size="small"
                        onClick={() => append({
                          ...defaultValues.trainees[0],
                        })}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={1} sx={{ mt: 3, mb: 5 }}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  INSTRUCTOR:
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <FormGroup hasError={!!errors?.instructor_id}>
                  <Controller
                    name="instructor_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={instructorsOptions}
                        placeholder="Instructor"
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors?.instructor_id} />
                </FormGroup>
              </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={3} sx={{ mt: 3, mb: 5 }}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  TRAINING OBSERVATION/EVALUATION:
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <FormGroup label="Name Surname" hasError={!!errors?.training_crew_id}>
                  <Controller
                    name="training_crew_id"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        freeSolo
                        value={field.value ? CrewList?.filter((AirportItem) => AirportItem.id === field.value)[0] : null}
                        options={CrewList}
                        getOptionLabel={(option) => `${option.crew_shortname} ${option.crew_name}` || ''}
                        isOptionEqualToValue={(option, AirportListItem) => option.id === AirportListItem.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            inputRef={field.ref}
                          />
                        )}
                        onChange={(e, data) => {
                          field.onChange(data?.id);
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors?.training_crew_id} />
                </FormGroup>
              </Grid>

              <Grid item xs={4}>
                <FormGroup label="OBSERVER (INSTRUCTOR TRAINEE)" hasError={!!errors?.observer}>
                  <Controller
                    name="observer"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        onChange={() => {
                          setValue('observer', !watch('observer'));
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors?.observer} />
                </FormGroup>
              </Grid>

              <Grid item xs={4}>
                <FormGroup label="EVALUATOR" hasError={!!errors?.evaluator}>
                  <Controller
                    name="evaluator"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        onChange={() => {
                          setValue('evaluator', !watch('evaluator'));
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors?.evaluator} />
                </FormGroup>
              </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={3} sx={{ mt: 3, mb: 5 }}>
              <Grid item xs={12}>
                <FormGroup label="Instructor Comments" hasError={!!errors.instructor_comments}>
                  <Controller
                    name="instructor_comments"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Instructor Comments"
                        multiline
                        rows={4}
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.instructor_comments} />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="TRAINING MANAGER" hasError={!!errors.manager_decision}>
                  <Controller
                    name="manager_decision"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="TRAINING MANAGER"
                        multiline
                        rows={4}
                        {...field}
                      />
                    )}
                  />

                  <FieldError error={errors.manager_decision} />
                </FormGroup>
              </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={6} sx={{ mt: 3, mb: 5 }}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  TRAINING TITLES:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box sx={content}>
                  {trainingFields?.map((item, index) => (
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                        backgroundColor: 'whitesmoke',
                        padding: '5px',
                        borderRadius: '8px',
                        justifyContent: 'flex-start'
                      }}
                      key={item.id}
                      spacing={6}
                    >
                      <Grid item xs={12} lg={12}>
                        <Box sx={{ ...(headerBtn), justifyContent: 'space-between' }}>
                          <Typography noWrap variant="h6">
                            #
                            {' '}
                            {index + 1}
                          </Typography>

                          {index !== 0 && (
                            <Button
                              sx={{ ...(RedButton), fontWeight: 600 }}
                              endIcon={<CloseIcon />}
                              size="small"
                              title="Delete"
                              onClick={() => {
                                trainingRemove(index);
                              }}
                            />
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Abbreviation" hasError={!!errors?.training_titles?.[index]?.abbreviation}>
                          <Controller
                            name={`training_titles.${[index]}.abbreviation`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Abbreviation"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors?.training_titles?.[index]?.abbreviation} />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Training" hasError={!!errors?.training_titles?.[index]?.training}>
                          <Controller
                            name={`training_titles.${[index]}.training`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Training"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors?.training_titles?.[index]?.training} />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Reference" hasError={!!errors?.training_titles?.[index]?.reference}>
                          <Controller
                            name={`training_titles.${[index]}.reference`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                placeholder="Reference"
                                {...field}
                              />
                            )}
                          />

                          <FieldError error={errors?.training_titles?.[index]?.reference} />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid container sx={{ mt: 2.75, mb: 2.75, justifyContent: 'center' }}>
                    <Grid item xs={4} sx={{ px: 0 }}>
                      <Button
                        title="Add"
                        startIcon={<Plus />}
                        sx={{ ...btnS, ...GreenButton }}
                        size="small"
                        onClick={() => trainingAppend({
                          ...defaultValues.training_titles[0],
                        })}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'grid', gap: 3 }}>
              <Button
                title={!isUpdate ? 'Send Report' : 'Update'}
                sx={GreenButton}
                startIcon={!isUpdate ? <SendIcon /> : <UpgradeIcon />}
                onClick={handleSubmit(onSubmit)}
              />
              <Button
                title="Preview"
                startIcon={<VisibilityIcon />}
                onClick={handleSubmit(onPreview)}
              />
            </Box>
          </CardContent>
        </Card>

        {!isUpdate && (
          <Box sx={{ ...(SwitchContent), gap: '8px', margin: 3 }}>
            <InputLabel>
              Silent Sign
            </InputLabel>
            <Switch
              checked={watch('silent_sign')}
              onChange={() => {
                setValue('silent_sign', !watch('silent_sign'));
                if (watch('silent_sign')) {
                  setValue('allow_to_fill', false);
                }
              }}
            />
          </Box>
        )}
        <Box sx={{ ...(SwitchContent), gap: '8px', margin: 3 }}>
          <InputLabel>
            Allow to fill
          </InputLabel>
          <Switch
            checked={watch('allow_to_fill')}
            disabled={isUpdate && watch('silent_sign')}
            onChange={() => {
              setValue('allow_to_fill', !watch('allow_to_fill'));
              if (watch('allow_to_fill') && !isUpdate) {
                setValue('silent_sign', false);
              }
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TrainingReportForm;


import {
  object, string, number
} from 'yup';

export const defaultValues = {
  caa_name: '',
  caa_tel: '',
  caa_fax: '',
  caa_email: '',
  caa_web: '',
  caa_sita: '',
  caa_aftn: '',
  caa_address: '',
  caa_country: '',
  caa_application_format: '',
  caa_notice_period: '',
  caa_remarks: ''
};

export const schema = object().shape({
  caa_name: string()
    .nullable()
    .required('${label} cannot be blank.')
    .max(255)
    .min(3)
    .label('Caa name'),
  caa_tel: string()
    .nullable()
    .label('Tel'),
  caa_fax: string()
    .nullable()
    .label('Fax'),
  caa_email: string()
    .nullable()
    .label('Email'),
  caa_sita: string()
    .nullable()
    .label('Sita'),
  caa_aftn: string()
    .nullable()
    .label('Aftn'),
  caa_address: string()
    .nullable()
    .label('Address'),
  caa_country: number()
    .label('Country'),
  caa_application_format: string()
    .nullable()
    .label('Caa application format'),
  caa_notice_period: string()
    .nullable()
    .label('Caa notice period'),
  caa_remarks: string()
    .nullable()
    .label('Caa remarks'),
});

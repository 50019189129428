import {
  Button,
  Card,
  CardContent
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import Drawer from '../../../../components/Drawer';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Select from '../../../../form/components/Select';
import ServerAutocomplete from '../../../../form/components/ServerAutocomplete';
import { useTaskListSearchQuery } from '../../../../store/session';
import { useGetOrderQuery } from '../../../../store/slices/logisticsSlice';
import { Filters, GreenButton, RedButton } from '../../style';
import PartsRepeater from './components/PartsRepeater';
import { useOrderForm } from './useOrderForm';

/**
 * @param open
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const NewOrder = ({
  open, onClose, aircraftOptions = [], createAgain: { isCreate = false, orderId = null } 
}) => {
  const { data: order } = useGetOrderQuery(
    { orderId },
    { 
      refetchOnMountOrArgChange: true,
      skip: !open || !isCreate || !orderId
    }
  );
  const {
    methods,
    onSubmit,
    handleClose,
    handleSelectJob,
    isLoading
  } = useOrderForm({
    onClose
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
    reset
  } = methods;

  const optionsSelectStorage = [
    { value: 'hof', label: 'Hof' },
    { value: 'budapest', label: 'Budapest' },
  ];

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  useEffect(() => {
    if (order) {
      const currentAircraft = aircraftOptions.find(({ label }) => label === order?.aircraft);

      setValue('aircraft_id', currentAircraft?.value);
      setValue('wo_job_id', order?.job_name);
      setValue('storage', order?.storage);
      setValue('parts', [
        {
          part_number: order?.part_number,
          part_name: order?.part_name,
          part_type: order?.part_type,
          part_count: order?.part_count,
          priority: order?.priority,
          unit_id: order?.unit_id,
        }
      ]);
    }
  }, [order]);

  return (
    <Drawer
      title="Create New External Order"
      open={open}
      onClose={() => handleClose(false)}
    >
      <FormProvider {...methods}>
        <form>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
          >
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={6}>
                    <Grid item xs={3}>
                      <FormGroup label="Aircraft Reservation" hasError={!!errors.aircraft_id}>
                        <Controller
                          control={control}
                          name="aircraft_id"
                          render={({ field }) => (
                            <Select
                              options={aircraftOptions}
                              placeholder="Aircraft Reservation"
                              {...field}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={4}>
                      <FormGroup label="Storage" required hasError={!!errors.storage}>
                        <Controller
                          name="storage"
                          control={control}
                          render={({ field }) => (
                            <Select
                              options={optionsSelectStorage}
                              placeholder="Storage"
                              {...field}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={5}>
                      <FormGroup label="Work Order Job">
                        <Controller
                          name="wo_job_id"
                          control={control}
                          render={({ fields }) => (
                            <ServerAutocomplete
                              fields={fields}
                              placeholder="Search Active Jobs"
                              disabled={!watch('aircraft_id')}
                              searchQueryFn={useTaskListSearchQuery}
                              queryParams={{ aircraft_id: watch('aircraft_id') }}
                              handleSelect={handleSelectJob}
                            />
                          )}
                        />

                        <FieldError error={errors?.wo_job_id} />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <PartsRepeater />
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{ ...(Filters), paddingTop: '0px' }}>
                        <Box>
                          <Button
                            sx={{ mt: 5, ...(GreenButton) }}
                            variant="contained"
                            size="large"
                            disabled={!isLoading}
                            onClick={handleSubmit(onSubmit)}
                          >
                            Send
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            sx={{ ml: 5, mt: 5, ...(RedButton) }}
                            variant="contained"
                            size="large"
                            onClick={() => handleClose(onClose, true)}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default NewOrder;

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Grid, Tab, useMediaQuery } from '@mui/material';
import FileSaver from 'file-saver';
import Box from 'mdi-material-ui/PackageVariantClosed';
import Wrench from 'mdi-material-ui/Wrench';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { CARGO, TECH } from '../../constans/flight';
import { useExportFlightsToExcelMutation } from '../../store/session';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { parseLocationSearch, stringifyLocationSearch } from '../../utils/locationSearch';
import { btnS } from '../Invoices/style';
import { GreenButton } from '../Logistics/style';
import DateModal from './components/DateModal';
import Table from './list/Table';

const Flights = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const [value, setValue] = useState('1');
  const [modalState, setModalState] = useState({
    isOpen: false
  });
  const [exportState, setExportState] = useState({
    sortField: 'planing_index',
    sortDir: 'DESC',
    type: CARGO,
    date_from: moment().format('YYYY-MM-DD'),
    date_to: moment().add(10, 'days').format('YYYY-MM-DD')
  });
  const hasPermissions = usePermissions('flights_access');
  const [exportData, { isLoading: isExportLoading }] = useExportFlightsToExcelMutation();

  const handleChange = (_, newValue) => {
    setValue(newValue);
    setExportState(prev => ({
      ...prev,
      type: (newValue === '1') ? CARGO : TECH
    }));
  };

  const handleExport = async () => {
    const res = await exportData(stringifyLocationSearch(exportState));

    FileSaver.saveAs(res.data, `Flights ${exportState?.date_from} - ${exportState?.date_to}`);
  };

  const handleSetSortExport = (sortField, sortDir = '') => {
    setExportState(prev => ({
      ...prev,
      sortField,
      sortDir: sortDir?.toUpperCase()
    }));
  };

  const handleOpenModal = (vlv) => {
    setModalState(prev => ({
      ...prev,
      isOpen: vlv,
      date_from: moment().format('YYYY-MM-DD'),
      date_to: moment().add(10, 'days').format('YYYY-MM-DD')
    }));
  };

  const handleSetExportDate = (type, date) => {
    setExportState(prev => ({
      ...prev,
      [type]: date
    }));
  };

  useEffect(() => {
    setExportState(prev => ({
      sortField: prev?.sortField,
      sortDir: prev?.sortDir,
      type: prev?.type,
      date_from: prev?.date_from,
      date_to: prev?.date_to,
      ...parseLocationSearch(location.search)
    }));
  }, [location]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <TabContext value={value}>
          <Grid container xs={12} sx={{ justifyContent: 'space-between', padding: '0 0.75rem' }}>
            <TabList
              onChange={handleChange}
              aria-label="tabs"
              item
              xs={maxWidth800px ? 12 : 6}
              sx={maxWidth800px ? { width: '100%' } : {}}
              centered={maxWidth800px}
            >
              <Tab value="1" label="Cargo" icon={<Box />} />
              <Tab value="2" label="Technical" icon={<Wrench />} />
            </TabList>
            <Grid
              item
              xs={maxWidth800px ? 12 : 6}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                flexDirection: maxWidth800px ? 'column' : 'row',
                alignItems: 'center',
                gap: 4
              }}
            >
              <Button
                title="Export to Excel"
                sx={{ ...(maxWidth800px && btnS), ...GreenButton }}
                disabled={isExportLoading}
                onClick={() => handleOpenModal(true)}
              />
              <Button
                title={value === '1' ? 'Add Cargo Flight' : 'Add Technical Flight'}
                sx={{ ...(maxWidth800px && btnS) }}
                onClick={() => {
                  navigate(`/flights/${value === '1' ? CARGO : TECH}`, { replace: false });
                }}
              />
            </Grid>
          </Grid>
          
          <TabPanel value="1">
            <Table title="Cargo Flights" type={CARGO} handleSetSortExport={handleSetSortExport} />
          </TabPanel>
          {hasPermissions && (
            <TabPanel value="2">
              <Table title="Technical Flights" type={TECH} handleSetSortExport={handleSetSortExport} />
            </TabPanel>
          )}
        </TabContext>

        <DateModal 
          open={modalState.isOpen}
          onClose={() => handleOpenModal(false)}
          submit={() => {
            handleExport();
            handleOpenModal(false);
          }}
          dateFrom={exportState?.date_from}
          dateTo={exportState?.date_to}
          setExportDate={handleSetExportDate}
        />
      </Grid>
    </Grid>
  );
};

export default Flights;

import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormGroup from '../../../../../form/components/FormGroup';
import Input from '../../../../../form/components/Input';
import TypeCard from '../../../../Ods/OdsForm/TypeCard';
import Checkbox from '../../../../../form/components/Checkbox';

const Interval = ({ disabled }) => {
  const { control } = useFormContext();
  
  const maxWidth768px = useMediaQuery('(max-width:768px)');

  return (
    <TypeCard title="INTERVAL" variant="yellow">
      <Grid xs={12} sx={{ flexGrow: 1, '& label': { display: 'flex' } }}>
        <Controller
          render={({ field } ) => (
            <Checkbox
              label="CALC BY INTERVAL"
              disabled={disabled}
              {...field}
            />
          )}
          control={control}
          name="use_interval"
        />
      </Grid>
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="INTERVAL">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="0"
                  // type="number"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="interval"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="INTERVAL DAYS">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="0"
                  type="number"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="interval_days"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="INTERVAL FH">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="0"
                  disabled={disabled}
                  {...field}
                  type="number"
                />
              )}
              control={control}
              name="interval_fh"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="INTERVAL FC">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="0"
                  type="number"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="interval_fc"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </TypeCard>
  );
};

export default Interval;

import React, { useEffect, useState } from 'react';
import {
  Card, CardContent, CardHeader, FormHelperText, TextField, Autocomplete, InputLabel, Select, MenuItem, InputAdornment,
} from '@mui/material';
import { Phone } from 'mdi-material-ui';
import { MuiTelInput } from 'mui-tel-input';
import { CustomForm } from './styles';
import { useGetPersonsListQuery } from '../../../../../store/session';
import { FLIGHT_CARGO } from '../../../../../constans/constants';
import PriceInput from '../../../../../form/components/PriceInput';
import FieldError from '../../../../../form/components/FieldError';
import FormGroup from '../../../../../form/components/FormGroup';

const ClientForm = (props) => {
  const [skip, setSkip] = useState(true);
  const {
    setValue, resetField, watch, update, control, Controller, errors, CompaniesList, type,
  } = props;

  const companyId = watch('planing_cln_name');

  const { data: Persons, isFetching, isLoading } = useGetPersonsListQuery(companyId, { skip: !companyId });
  const personId = watch('planing_cln_person');
  useEffect(() => {
    if (companyId !== '' && companyId !== null && companyId !== undefined) {
      setSkip(false);
    } else {
      resetField('planing_cln_person');
      setSkip(true);
    }

    if (personId !== '' && personId !== null && companyId !== undefined) {
      setValue('planing_cln_phone', Persons?.filter((item) => item.id === personId)[0]?.person_phone);
    } else {
      resetField('planing_cln_phone');
    }
  }, [companyId, personId]);

  useEffect(() => {
    if (Persons instanceof Array && !Persons.find((personItem) => personItem.id === personId)) {
      resetField('planing_cln_person');
      resetField('planing_cln_phone');
    }
  }, [Persons]);

  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader title="Client" />
      <CardContent sx={{ mt: 5 }}>
        <CustomForm fullWidth>
          <Controller
            name="planing_cln_name"
            control={control}
            rules={{ required: true }}
            render={({
              field: {
                ref,
                onChange,
                value,
                ...field
              },
            }) => (
              <Autocomplete
                freeSolo
                defaultValue={update ? CompaniesList?.filter((item) => item.id === value)[0] : null}
                options={CompaniesList}
                getOptionLabel={(option) => option.company_name || ''}
                isOptionEqualToValue={(option, item) => option.id === item.id}
                id="autocomplete-outlined"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    inputRef={ref}
                    error={Boolean(errors.planing_cln_name)}
                    label="Company"
                  />
                )}
                onChange={(e, data) => {
                  onChange(data?.id);
                }}
              />
            )}
          />
          {errors.planing_cln_name && (
            <FormHelperText sx={{ color: 'error.main' }} id="validation-basic-select">
              This field is required
            </FormHelperText>
          )}
        </CustomForm>

        {(type === FLIGHT_CARGO) && (
          <>
            <CustomForm fullWidth>
              <InputLabel
                disabled={!Persons || isFetching || isLoading}
                id="validation-basic-select"
                htmlFor="validation-basic-select"
              >
                Person
              </InputLabel>
              <Controller
                name="planing_cln_person"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    defaultValue=""
                    value={value || ''}
                    label="Person"
                    disabled={!Persons || isFetching || isLoading}
                    onChange={onChange}
                    labelId="validation-basic-select"
                    aria-describedby="validation-basic-select"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    {Persons?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>{item.person_name}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </CustomForm>
            <CustomForm fullWidth>
              <Controller
                name="planing_cln_phone"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MuiTelInput
                    value={value}
                    label="Phone"
                    disabled={!personId}
                    onChange={onChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </CustomForm>
            <CustomForm fullWidth>
              <FormGroup hasError={!!errors.planing_cln_price}>
                <Controller
                  name="planing_cln_price"
                  control={control}
                  render={({ field }) => (
                    <PriceInput
                      sx={{ width: '100%' }}
                      label="Price"
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.planing_cln_price} />
              </FormGroup>
            </CustomForm>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ClientForm;

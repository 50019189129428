import { object, number } from 'yup';

export const defaultValues = {
  fees_e_atr42: '',
  fees_e_atr72: '',
  fees_f_atr42: '',
  fees_f_atr72: '',
};

export const schema = object().shape({
  fees_e_atr42: number()
    .nullable()
    .label('ATR-42'),
  fees_e_atr72: number()
    .nullable()
    .label('ATR-72'),
  fees_f_atr42: number()
    .nullable()
    .label('ATR-42'),
  fees_f_atr72: number()
    .nullable()
    .label('ATR-72'),
});

import React, { useState } from 'react';
import {
  Box, Card, Divider, Typography
} from '@mui/material';
import EditableTable from '../../../components/EditableTable';
import { useGetCurrencyTableQuery, useDeleteCurrencyMutation, useUpdateCurrencyMutation } from '../../../store/session';
import { columns } from './columns';
import Button from '../../../components/Button';
import CreateCurrency from './CreateCurrency/CreateCurrency';
import { usePermissions } from '../../../utils/hooks/usePermissions';

export const Currency = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const hasPermissionsEdit = usePermissions(['invoice_edit', 'invoice_su']);

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  return (
    <Card sx={{
      mt: 3,
      padding: '1rem',
    }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}
      >
        <Typography variant="h3">Currency</Typography>
        <Button disabled={!hasPermissionsEdit} onClick={handleOpen} title="Create" />
      </Box>

      <Divider />
      <EditableTable
        useHook={useGetCurrencyTableQuery}
        useHookDelete={useDeleteCurrencyMutation}
        useHookUpdate={useUpdateCurrencyMutation}
        columns={columns}
        sortByType="DESC"
        hasPermissions={hasPermissionsEdit}
        sortByActive="currency"
        getRowHeight={() => 'auto'}
        getRowId={(row) => row.id}
        fixedCacheKey="currency-table"
      />

      <CreateCurrency
        onClose={handleClose}
        open={openDrawer}
      />

    </Card>
  );
};

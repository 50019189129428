import React, { useMemo } from 'react';
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { headerBtn } from '../../../Invoices/style';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';
import { useGetTransactionDetailsQuery } from '../../../../store/slices/financeSlice';
import SkeletonGrid from '../../../../components/Skeleton/SkeletonGrid';
import { TextWithIcon } from '../../../Logistics/style';

const TransactionDetails = ({
  open,
  onClose,
  transactionID
}) => {
  const { data: transaction, isLoading } = useGetTransactionDetailsQuery(transactionID, {
    refetchOnMountOrArgChange: true,
    skip: !transactionID
  });

  const keyMap = {
    transaction_id: 'Transaction ID',
    amount: 'Amount',
    transaction_date: 'Transaction Date',
    notes_1: 'Notes 1',
    notes_2: 'Notes 2',
    notes_3: 'Notes 3',
    notes_4: 'Notes 4',
    value_date: 'Value Date',
    note: 'Note',
    title: 'Title',
    customer_id: 'Customer ID',
    orderer_name: 'Order Name',
    announcement_1: 'Announcement 1',
    announcement_2: 'Announcement 2',
    name_of_address: 'Name of address',
    original_amount: 'Original amount',
    regular_customer_id: 'Regular customer ID',
    debit_account_number: 'Debit account number',
    invoice_or_receipt_id: 'Invoice or receipt ID',
    nav_verification_code: 'NAV verification code',
    trade_unit_identifier: 'Trade unit identifier',
    beneficiary_account_no: 'Beneficiary account NO',
    trade_device_identifier: 'Trade device identifier',
    original_reference_number: 'Original reference number',
    payment_status_identifier_1: 'Payment status identifier 1',
    payment_status_identifier_2: 'Payment status identifier 2',
    discounted_transaction_identifier: 'Discounted transaction identifier',
    type_of_transaction: 'Type of transaction',
    opposite_account_number: 'Opposite account number',
    opposite_name: 'Opposite name',
    notice: 'Notice',
    details: 'Details',
    beneficiary_account_number: 'Beneficiary account number'
    // Add more keys as needed
  };

  const parseData = useMemo(() => {
    const elements = [];

    if (!transaction) return elements;

    const addElement = (label, value) => {
      elements.push(
        <Grid item xs={12} sx={{ mt: 2 }} key={label}>
          <Typography sx={TextWithIcon}>
            <Typography sx={{ fontWeight: 'bold' }}>
              {label}
              :
            </Typography>
            {value}
          </Typography>

          <Divider />
        </Grid>
      );
    };

    const parseNestedData = (obj, prefix = '') => {
      for (const [key, value] of Object.entries(obj)) {
        const label = keyMap[key] || key;
        const nestedKey = prefix ? `${label}` : label;

        if (typeof value === 'object' && value !== null) {
          parseNestedData(value, nestedKey);
        } else if (value) {
          addElement(nestedKey, value);
        }
      }
    };

    parseNestedData(transaction);

    return elements;
  }, [transaction]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <DialogContent>
          <Card sx={{ padding: '1rem' }}>
            <Box sx={headerBtn}>
              <Box>
                <Typography variant="h5" noWrap>
                  {`Internal ID: ${transactionID}`}
                </Typography>
              </Box>
              <CloseIcon
                sx={CloseIconStyle}
                onClick={onClose}
              />
            </Box>

            <Divider />

            <Grid container spacing={4}>
              {isLoading ? (
                <SkeletonGrid itemCount={12} />
              ) : (
                parseData?.map((element) => element)
              )}
            </Grid>
          </Card>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default TransactionDetails;

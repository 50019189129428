import React from 'react';
import { MarkerF } from '@react-google-maps/api';
import { airportMarkerOptions } from '../googleMapOptions';

const AirportMarker = ({
  airportData,
  handleMarkerClick,
  clusterer
}) => {
  const getFontSize = (labelText) => {
    // Calculate the font size based on the length or content of the label text
    // You can implement your own logic here based on your requirements
    if (labelText.length <= 4) {
      return '19px';
    } if (labelText.length <= 6) {
      return '14px';
    }

    return '12px';
  };

  return (
    <MarkerF
      options={{
        ...airportMarkerOptions,
        label: {
          text: `${airportData.loadedCount}`,
          color: '#ffffff',
          fontSize: getFontSize(airportData.loadedCount),
          fontWeight: '600'
        },
        airportData
      }}
      position={{ lat: airportData.lat, lng: airportData.lon }}
      onClick={() => handleMarkerClick(airportData)}
      clusterer={clusterer}
    />
  );
};

export default AirportMarker;

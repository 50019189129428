import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Card, Divider, useMediaQuery
} from '@mui/material';
import Typography from '@mui/material/Typography';
import FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import {
  blockBtn, cancel, closeIcon, headerSx, text, titleSx
} from '../../../components/ApproveModal/styles';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import Loader from '../../../components/Loader/ContentPageLoader';
import Modal from '../../../components/Modal';
import {
  useChangeAtrVersionMutation,
  useDeleteArtMaintenanceMutation,
  useGetAircraftListQuery,
  useGetAtrMaintenanceQuery, useGetAtrVersionByStatusQuery,
  useMaintenanceDownloadMutation
} from '../../../store/session';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { GreenButton } from '../../Logistics/style';
import Info from './Info';
import WOHistoryDrawer from './WOHistoryDrawer';
import WoForm from './WoForm/WoForm';
import { columns } from './columns';
import {
  activeTypeSx,
  boxAircraft,
  boxAircraftSx,
  boxPopover,
  boxPopoverInfo,
  btnX,
  containerAircraft,
  containerAircraftSx,
  headerBtn,
  headerRow,
  headerRowSx,
  mobileInfo,
  titleContainer,
  typeSx,
} from './styles';

const MaintenanceACFT = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openWOHistoryDrawer, setOpenWOHistoryDrawer] = useState({
    isOpen: false,
    maintenance_id: null
  });

  const params = useParams();
  const navigate = useNavigate();

  const [activeType, setActiveType] = useState(+params.id);
  const [aircraftType, setAircraftType] = useState([]);
  const [showInfo, setShowInfo] = useState(false);

  const maxWidth768px = useMediaQuery('(max-width:840px)');

  const hasPermissionsMaintenance = usePermissions('maintenance_edit');
  const hasPermissionsWo = usePermissions('wo_creator');
  const deletePermission = usePermissions('atr_maintenance_delete_access');
  const hasPermissionChangeVersion = usePermissions('atr_maintenance_change_version');

  const { data: aircraft } = useGetAircraftListQuery('?with_current_ods=true');
  const [maintenanceDownload] = useMaintenanceDownloadMutation();
  const { data: atrVersionNumber, refetch: refetchVersion } = useGetAtrVersionByStatusQuery(
    { version: params.version, aircraft_id: params.id },
    { refetchOnMountOrArgChange: true }
  );

  const [changeVersion] = useChangeAtrVersionMutation();

  const handleChangeVersion = async () => {
    const toastId = toast.loading('Please wait.');
    setOpenModal(false);

    await changeVersion({ aircraft_id: params.id }).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId
        });

        refetchVersion();
      } else {
        toast.error(res?.error?.data?.message, {
          id: toastId
        });
      }
    });
  };

  const handleWOHistoryDrawer = ({ isOpen, id }) => {
    setOpenWOHistoryDrawer({
      isOpen,
      maintenance_id: id
    });
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSetActiveType = (data) => {
    setActiveType(data.id);
    setAircraftType(data);
    navigate(`/maintenance/${params.version}/aircraft/${data.id}`);
  };

  const handleInfo = () => {
    setShowInfo(!showInfo);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  const handleDoc = async () => {
    const res = await maintenanceDownload({ id: params.id, version: params.version });

    FileSaver.saveAs(res.data, 'maintenance_report');
  };

  const tableParams = { aircraft_id: params.id, version: params.version };

  const handleAddMaintenance = () => {
    window.open(`/maintenance/${params.version}/create/${params.id}`, '_blank', 'noreferrer');
  };

  useEffect(() => {
    const res = aircraft?.find(item => item.id === +params.id);

    if (res) {
      setActiveType(res.id);
      setAircraftType(res);
    }
  }, [aircraft, params.version]);

  if (!aircraft) return <Loader />;

  return (
    <>
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box sx={{ ...titleContainer, pb: 3 }}>
          <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
            Aircraft Maintenance -
            {' '}
            {params.version.charAt(0).toUpperCase() + params.version.slice(1)}
            {' '}
            {`v.${atrVersionNumber}`}
          </Typography>
        </Box>
        <Box sx={{ ...containerAircraft, ...(maxWidth768px && containerAircraftSx) }}>
          <Box sx={{ ...boxAircraft, ...(maxWidth768px && boxAircraftSx) }}>
            {aircraft?.map(type => (
              <Button
                title={type.aircraft_registration}
                sx={{ ...typeSx, ...(type.id === activeType && activeTypeSx) }}
                key={type.id}
                onClick={() => handleSetActiveType(type)}
              />
            ))}
          </Box>

          <Box sx={{ ...headerBtn, ...(maxWidth768px && headerRowSx) }}>
            {params.version === 'current' && (
              <Button
                disabled={!hasPermissionsWo}
                endIconLoadingEnd
                sx={{ ...(maxWidth768px && btnX), ...GreenButton }}
                title="Create WO"
                onClick={handleOpen}
              />
            )}

            <Button sx={{ ...(maxWidth768px && btnX) }} title="Download" onClick={handleDoc} />

            {params.version === 'current' && (
              <Button
                disabled={!hasPermissionChangeVersion}
                sx={{ ...(maxWidth768px && btnX) }}
                title="Change Version"
                onClick={handleOpenModal}
              />
            )}
          </Box>
        </Box>

        <Box sx={{ ...headerRow, ...(maxWidth768px && headerRowSx) }}>
          <Button sx={{ ...(maxWidth768px && btnX) }} title={showInfo ? 'Hide Info' : 'Show info'} onClick={handleInfo} />

          {(params.version === 'development' || params.version === 'current') && (
            <Button
              disabled={!hasPermissionsMaintenance}
              onClick={handleAddMaintenance}
              sx={{ ...(maxWidth768px && btnX) }}
              title="Add Maintenance"
            />
          )}
        </Box>

        <Divider />

        {!maxWidth768px ? (
          <Box sx={{ ...boxPopover, ...(showInfo && boxPopoverInfo) }}>
            {showInfo && <Info aircraft={aircraftType} />}
          </Box>
        ) : (
          showInfo && (
            <Box sx={{ ...(maxWidth768px && mobileInfo) }}>
              <Info aircraft={aircraftType} />
            </Box>
          )
        )}

        <WoForm
          onClose={handleClose}
          open={openDrawer}
          aircraft={aircraft}
        />

        <EditableTable
          useHook={useGetAtrMaintenanceQuery}
          useHookDelete={useDeleteArtMaintenanceMutation}
          columns={columns}
          sortByType="DESC"
          sortByActive="remaining_calc_date"
          tableParams={tableParams}
          getRowHeight={() => 'auto'}
          hasPermissions={hasPermissionsMaintenance}
          handleWOHistoryDrawer={handleWOHistoryDrawer}
          deletePermission={(((params.version === 'current') ? deletePermission : hasPermissionsMaintenance))}
          getRowId={(row) => row.id}
        />
      </Card>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={headerSx}>
          <Typography sx={titleSx}>
            Are you sure you want to change the
            {' '}
            {(aircraft || []).find((item) => item?.id === activeType)?.aircraft_registration }
            {' '}
            version to
            {' '}
            {`v.${+atrVersionNumber + 1}`}
            {' '}
            of “ACFT Maintenance” ?
          </Typography>
          <CloseIcon onClick={handleCloseModal} sx={closeIcon} />
        </Box>
        <Box sx={text} />
        <Box sx={blockBtn}>
          <Button onClick={handleChangeVersion} title="Yes, I am sure" />
          <Button onClick={handleCloseModal} sx={cancel} title="No" />
        </Box>
      </Modal>

      <WOHistoryDrawer open={openWOHistoryDrawer?.isOpen} onClose={() => handleWOHistoryDrawer({ isOpen: false, id: null })} maintenance_id={openWOHistoryDrawer.maintenance_id} />
    </>
  );
};

export default MaintenanceACFT;

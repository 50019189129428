import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import Flight from './Flight';
import { useOdsPositionForm } from './useOdsPositionForm';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { backIcon, headerSx } from './styles';

const OdsPositionForm = ({ data, isPanel }) => {
  const {
    control,
    pilotNameOptions,
    getValues,
    watch,
    trigger,
    handleBack,
    handleSubmit,
    onSubmit,
    setValue,
    setCrewPost,
    errors,
    hasPermissionsOdsEdit
  } = useOdsPositionForm(data);

  return (
    <Box>
      {!isPanel && (
      <Box sx={headerSx}>
        <ChevronLeftIcon sx={backIcon} onClick={handleBack} />
      </Box>
      )}
      <Flight
        errors={errors}
        control={control}
        pilotNameOptions={pilotNameOptions}
        setValue={setValue}
        trigger={trigger}
        setCrewPost={setCrewPost}
        getValues={getValues}
        watch={watch}
      />

      {!isPanel && (
      <Button
        onClick={handleSubmit(onSubmit)}
        disabled={!hasPermissionsOdsEdit}
        variant="contained"
        type="submit"
        sx={{ mt: '25px' }}
      >
        Save
      </Button>
      )}
    </Box>
  );
};

export default OdsPositionForm;

import { useMenuToggle } from '../../../../utils/hooks/useMenuToggle';
import { useState } from 'react';
import {
  useUploadProofOfPaymentMutation, useGetProofOfPaymentMutation,
} from '../../../../store/session';
import toast from 'react-hot-toast';

export const useDropDown = () => {
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    open: openFile,
    openMenu: openMenuFile,
    closeMenu: closeMenuFile,
    anchorEl: anchorElFile,
  } = useMenuToggle();

  const [getFile, { isLoading: FileLoading }] = useGetProofOfPaymentMutation();
  const [onUpdateFile, { isSuccess: FileSuccess }] = useUploadProofOfPaymentMutation();

  const [refetch, setRefetch] = useState(0);

  const useRefetch = () => {
    setRefetch(refetch + 1);
  };

  const handlePreviewFile = async (id) => {
    const toastId = toast.loading('Loading...');

    await getFile(id).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleUpdateFile = async (id, file) => {
    const toastId = toast.loading('Loading...');
    const formData = new FormData();
    formData.append('file', file);

    const requestObj = { id, data: formData };
    await onUpdateFile(requestObj).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        useRefetch();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleOpenDropDownFile = (e, values) => {
    setSelectedRow(values);
    openMenuFile(e);
  };

  return {
    openFile,
    closeMenuFile,
    anchorElFile,
    selectedRow,
    handlePreviewFile,
    handleUpdateFile,
    refetch,
    useRefetch,
    handleOpenDropDownFile,
  };
};

import {
  object, string
} from 'yup';

export const defaultValues = {
  issue_number: '',
  issue_date: '',
  revision_number: '',
  revision_date: '',
  report_type: '',
  allow_to_fill: false,
  silent_sign: false,
  crew_member_id: null,
  aircraft_type: '',
  ffs_reg_nr: '',
  training_date: '',
  conditions: '',
  from_code: '',
  to_code: '',
  off_block: '',
  on_block: '',
  total_block: '',
  training_cycle: '',
  general_items: [
    {
      item_name: 'Cockpit Preparation',
      comments: false,
      attempt: null
    },
    {
      item_name: 'Ground procedures',
      comments: false,
      attempt: null
    },
    {
      item_name: 'In-flight procedures',
      comments: false,
      attempt: null
    },
    {
      item_name: 'Aircraft handling',
      comments: false,
      attempt: null
    },
    {
      item_name: 'Aircraft systems management',
      comments: false,
      attempt: null
    },
    {
      item_name: 'Adherence to regulations and Company OM',
      comments: false,
      attempt: null
    },
    {
      item_name: 'EFB related procedures (if applicable)',
      comments: false,
      attempt: null
    },
    {
      item_name: 'Threat and error management',
      comments: false,
      attempt: null
    }
  ],
  abnormal_and_emergency_procedures: [
    {
      item_name: 'Rejected take-off',
      comments: false,
      attempt: null
    },
    {
      item_name: 'Take-off with engine failure between V1 and V2',
      comments: false,
      attempt: null
    },
    {
      item_name: '3D approach, ILS / PBN',
      comments: false,
      attempt: null
    },
    {
      item_name: '2D approach',
      comments: false,
      attempt: null
    },
    {
      item_name: 'Go-around from minima with one engine inoperative',
      comments: false,
      attempt: null
    },
    {
      item_name: 'Landing with one engine inoperative',
      comments: false,
      attempt: null
    },
    {
      item_name: 'Non-precision approach',
      comments: false,
      attempt: null
    },
    {
      item_name: 'System failures (according OPC cycle)',
      comments: false,
      attempt: null
    }
  ],
  eps_check: [
    {
      item_name: 'An engine failure during take-off',
      comments: false,
      attempt: null
    },
    {
      item_name: 'One engine inoperative approach and go-around',
      comments: false,
      attempt: null
    },
    {
      item_name: 'One engine inoperative landing',
      comments: false,
      attempt: null
    }
  ],
  extra_items_checked: [
    {
      item_name: '',
      comments: null,
      attempt: null
    }
  ],
  recommendations_comments: '',
  markers_n1: [
    {
      marker_name: 'Co-operation',
      comments: false,
      score: null
    },
    {
      marker_name: 'Team building and maintaining',
      comments: false
    },
    {
      marker_name: 'Consideration of others',
      comments: false
    },
    {
      marker_name: 'Support of others',
      comments: false
    },
    {
      marker_name: 'Conflict solving',
      comments: false
    }
  ],
  markers_n2: [
    {
      marker_name: 'Leadership and/or Managerial Skills',
      comments: false,
      score: null
    },
    {
      marker_name: 'Use of authority and assertiveness',
      comments: false
    },
    {
      marker_name: 'Providing and maintaining standards',
      comments: false
    },
    {
      marker_name: 'Planning and co-ordination',
      comments: false
    },
    {
      marker_name: 'Workload management',
      comments: false
    }
  ],
  markers_n3: [
    {
      marker_name: 'Situation Awareness',
      comments: false,
      score: null
    },
    {
      marker_name: 'Awareness of aircraft systems',
      comments: false
    },
    {
      marker_name: 'Awareness of external environment',
      comments: false
    },
    {
      marker_name: 'Awareness of time',
      comments: false
    }
  ],
  markers_n4: [
    {
      marker_name: 'Decision Making',
      comments: false,
      score: null
    },
    {
      marker_name: 'Problem definition and diagnosis',
      comments: false
    },
    {
      marker_name: 'Option generation',
      comments: false
    },
    {
      marker_name: 'Risk assessment and option selection',
      comments: false
    },
    {
      marker_name: 'Outcome review',
      comments: false
    }
  ],
  recommendations_comments_2: '',
  opc_is: false,
  observation_member_id: null,
  observer: '',
  recommendations_fail: '',
  decision_fail: '',
  training_member_id: null,
  current_check_expires: '',
  new_validity_date: ''
};

export const schema = object().shape({
  crew_member_id: string()
    .required('${label} cannot be blank.')
    .label('CREW MEMBER'),
});

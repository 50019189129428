import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Card, Divider
} from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import EditableTable from '../../../../components/EditableTable';
import Autocomplete from '../../../../form/components/Autocomplete/Autocomplete';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { useCreateDepartmentDataMutation, useDeleteDepartmentsMutation, useGetTableDepartmentsQuery } from '../../../../store/slices/paymentDataSlice';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { GreenButton } from '../../../Logistics/style';
import { columns } from './columns';
import { defaultValues, schema } from './schema';

const CreateDepartment = ({
  refetchDepartmentsList, setUpdateDepartment, userOptions, hasPermissions
}) => {
  const [sendDepartment] = useCreateDepartmentDataMutation();
  const handleError = useHandleQueryFormError();
  const [refresh, setRefresh] = useState(false);

  const {
    handleSubmit,
    setError,
    getValues,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const newData = {
      ...data,
      leaders: data.leaders.map(item => ({ user_id: item?.value }))
    };

    const res = await sendDepartment({ data: newData });

    if (res.error) {
      handleError(res.error, setError, getValues, toastId);

      return;
    }

    toast.success('Department successfully created!', {
      id: toastId,
    });

    refetchDepartmentsList();
    handleRefresh();
    reset();
  };

  return (
    <>
      <Card sx={{ padding: 4, overflow: 'initial' }}>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <FormGroup
                label="Name"
                required
                hasError={!!errors.name}
              >
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input placeholder="Name" {...field} />
                  )}
                />
              </FormGroup>

              <FormGroup
                label="Leaders"
                required
                hasError={!!errors.leaders}
              >
                <Controller
                  name="leaders"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={userOptions}
                      size="small"
                      multiple
                      placeholder="Enter Leaders"
                      onChange={(e, value ) => {
                        field.onChange(value);
                      }}
                    />
                  )}
                />
              </FormGroup>
            </Box>

            <Button sx={GreenButton} type="submit" disabled={!hasPermissions}>Create</Button>
          </form>
        </Box>
      </Card>
      <Divider sx={{ mb: 4 }} />
      <Card sx={{ padding: 4, overflowY: 'auto', maxHeight: '65%' }}>
        <EditableTable
          useHook={useGetTableDepartmentsQuery}
          useHookDelete={useDeleteDepartmentsMutation}
          columns={columns}
          sortByType="DESC"
          sortByActive="name"
          getRowId={(row) => row.id}
          tableParams={{
            refresh
          }}
          setUpdateDepartment={setUpdateDepartment}
          hasPermissions={hasPermissions}
        />
      </Card>
    </>
  );
};

export default CreateDepartment;

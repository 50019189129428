import React from 'react';
import TypeCard from '../TypeCard';
import Grid from '@mui/material/Grid';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { Controller } from 'react-hook-form';

const PaxInformation = ({ control, isPanel }) => (
  <TypeCard title="PAX INFORMATION" variant="sky" isPanel={isPanel}>
    <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1 }}>
      <Grid item xs={12} lg={4}>
        <FormGroup label="PAX ADULTS">
          <Controller
            name="pax_adults"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={4}>
        <FormGroup label="PAX CHILDREN">
          <Controller
            name="pax_children"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={4}>
        <FormGroup label="PAX INFANTS">
          <Controller
            name="pax_infants"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>
    </Grid>
  </TypeCard>
);

export default PaxInformation;

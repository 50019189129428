import React from 'react';
import Accordion from '../Accordion';
import Grid from '@mui/material/Grid';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { Controller } from 'react-hook-form';
import { titleSx } from '../TotalHrs/styles';

const InstrumentsRegardings = ({ control, isPanel }) => (
  <Accordion title="INSTRUMENT REGARDINGS" isPanel={isPanel}>
    <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1 }}>
      <Grid item xs={12} sm={12} sx={titleSx}>
        LH
      </Grid>
      <Grid item xs={12} lg={1.5}>
        <FormGroup label="ENG RPM %">
          <Controller
            name="regardings_lh_eng_rpm"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="ENG TEMP">
          <Controller
            name="regardings_lh_eng_temp"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="ENG OIL TEMP">
          <Controller
            name="regardings_lh_eng_oil_temp"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="PROP RPM">
          <Controller
            name="regardings_lh_prop_rpm"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="PROP OIL TEMP">
          <Controller
            name="regardings_lh_prop_oil_temp"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="TRQ">
          <Controller
            name="regardings_lh_trq"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="FUEL FLOW">
          <Controller
            name="regardings_lh_fuel_flow"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="START TIME">
          <Controller
            name="regardings_lh_start_time"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} sm={12} sx={titleSx}>
        RH
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="ENG RPM %">
          <Controller
            name="regardings_rh_eng_rpm"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="ENG TEMP">
          <Controller
            name="regardings_rh_eng_temp"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="ENG OIL TEMP">
          <Controller
            name="regardings_rh_eng_oil_temp"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="PROP RPM">
          <Controller
            name="regardings_rh_prop_rpm"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="PROP OIL TEMP">
          <Controller
            name="regardings_rh_prop_oil_temp"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="TRQ">
          <Controller
            name="regardings_rh_trq"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="FUEL FLOW">
          <Controller
            name="regardings_rh_fuel_flow"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={1.5}>
        <FormGroup label="START TIME">
          <Controller
            name="regardings_rh_start_time"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} sm={12} sx={titleSx}>
        GENERAL
      </Grid>

      <Grid item xs={12} lg={3}>
        <FormGroup label="HYD QTY">
          <Controller
            name="regardings_hyd_qty"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={3}>
        <FormGroup label="IAS">
          <Controller
            name="regardings_ias"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={3}>
        <FormGroup label="O.A.T.">
          <Controller
            name="regardings_oat"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={3}>
        <FormGroup label="FLIGHT LEVEL">
          <Controller
            name="regardings_flight_level"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormGroup>
      </Grid>
    </Grid>
  </Accordion>
);

export default InstrumentsRegardings;

export const containerSx = {
  display: 'flex',
  flexWrap: 'wrap',
  minWidth: '1500px',
};

export const calendarBox = {
  width: 'calc(100% / 8)',
  minHeight: '100px',
  border: '1px solid #e7ecf1',
  p: '8px',
};

export const titleSx = {
  mb: '15px',
  fontSize: '20px',
  fontWeight: 'bold'
};

export const flexContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap'
};

export const calendarDataSx = {
  width: '33.33%',
  textAlign: 'center',
  fontSize: '14px',
};

export const wrapperSx = {
  overflowX: 'auto',
};

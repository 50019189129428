import { Box, Grid, Typography } from '@mui/material';
import ModalBase from '@mui/material/Modal';
import React from 'react';
import {
  blockBtn, cancel, closeIcon, headerSx, modalSx, text, titleSx
} from '../../../components/ApproveModal/styles';
import Button from '../../../components/Button';
import { CloseIcon } from '../../../components/Icons';
import DatePicker from '../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../utils/setDateValue';

const DateModal = (props) => {
  const {
    open = true,
    onClose = () => {},
    submit = () => {},
    dateFrom,
    dateTo,
    setExportDate = () => {}
  } = props;

  return (
    <ModalBase
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalSx}>
        <Box sx={headerSx}>
          <Typography sx={titleSx}>
            Set the date to export.
          </Typography>
          <CloseIcon onClick={onClose} sx={closeIcon} />
        </Box>
        <Grid container spacing={6} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Typography variant="h6">From:</Typography>
            <DatePicker 
              placeholderText="YYYY-MM-DD"
              dateFormat="yyyy-MM-dd"
              showMonthDropdown
              showYearDropdown
              onChange={(e) => {
                setDateValue(e, setExportDate, 'date_from', 'YYYY-MM-DD');
              }}
              value={validateDatePickerValue(dateFrom)}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">To:</Typography>
            <DatePicker 
              placeholderText="YYYY-MM-DD"
              dateFormat="yyyy-MM-dd"
              showMonthDropdown
              showYearDropdown
              onChange={(e) => {
                setDateValue(e, setExportDate, 'date_to', 'YYYY-MM-DD');
              }}
              value={validateDatePickerValue(dateTo)}
            />
          </Grid>
        </Grid>
        <Box sx={text}>
          
        </Box>
        <Box sx={blockBtn}>
          <Button onClick={submit} title="Export" />
          <Button onClick={onClose} sx={cancel} title="Cancel" />
        </Box>
      </Box>
    </ModalBase>
  );
};

export default DateModal;
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, TextField
} from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import DefaultButton from '../../../../components/Button';
import { useInternalDeclineOrderMutation } from '../../../../store/slices/internalLogisticsSlice';
import { useDeclineOrerMutation } from '../../../../store/slices/logisticsSlice';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { RedButton } from '../../style';

const DeclineOrder = ({ orderId, closeOrderCard, orderStatus }) => {
  const location = useLocation();
  const dashboardType = location.pathname.split('/')[3];
  const [declineCurrent] = useDeclineOrerMutation();
  const [declineInternal] = useInternalDeclineOrderMutation();
  const decline = dashboardType === 'orders' ? declineCurrent : declineInternal;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [decline_reason, setDecline_reason] = useState('');
  const [error, setError] = useState(false); // State for error handling

  const setUserPermission = (status, isInternal) => {
    switch (status) {
      case 0:
        return isInternal ? 'internal_logist' : 'logist';
      case 1:
        return isInternal ? 'internal_logistics_order_approve' : 'logistics_order_approve';
      case 2:
        return isInternal ? 'internal_logist' : 'logist';
      case 3:
        return isInternal ? 'internal_logistics_storage_master' : 'logistics_storage_master';
      default:
        return null;
    }
  };

  const userPermission = usePermissions(setUserPermission(orderStatus, dashboardType !== 'orders'));

  const handleOpen = () => {
    setConfirmOpen(true);
    setError(false); // Reset error state when dialog opens
  };

  const handleClose = () => {
    setConfirmOpen(false);
    setDecline_reason(''); // Reset the decline reason
    setError(false); // Reset error state when dialog closes
  };

  const handleDecline = async () => {
    if (!decline_reason.trim()) {
      setError(true); // Set error if decline reason is empty

      return;
    }
    const toastId = toast.loading('Loading...');
    const { error: serverError } = await decline({
      id: orderId,
      data: {
        decline_reason,
      }
    });

    if (serverError) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId
    });
    setConfirmOpen(false);
    setDecline_reason(''); // Reset the decline reason
    closeOrderCard();
  };

  const handledecline_reasonChange = (event) => {
    setDecline_reason(event.target.value);
    if (error) setError(false); // Reset error when user starts typing
  };

  return (
    <>
      <Button
        sx={RedButton}
        variant="contained"
        onClick={handleOpen}
        disabled={!userPermission}
      >
        Decline
      </Button>
      <Dialog
        open={confirmOpen}
        onClose={handleClose}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to decline this order? This action cannot be undone.
          </DialogContentText>
          <TextField
            sx={{ mt: '20px' }}
            autoFocus
            error={error}
            helperText={error ? 'Decline reason is required' : ''}
            id="decline-reason"
            label="Decline Reason"
            type="text"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            value={decline_reason}
            onChange={handledecline_reasonChange}
          />
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={handleClose}>
            Cancel
          </DefaultButton>
          <Button
            sx={RedButton}
            onClick={handleDecline}
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeclineOrder;

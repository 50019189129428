import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  useGetAirportListQuery, useGetServicesByAirportQuery, useGetSessionByAirportMutation
} from '../../../../../../../store/session';

export const useServicesForm = () => {
  const [suppliers, setSuppliers] = useState({});

  const { control, Controller } = useFormContext();

  const {
    fields,
  } = useFieldArray({
    name: 'planing_services',
    control,
  });

  const airports = fields?.map(item => item.airport_id)?.join(',');
  const { data: airportList } = useGetAirportListQuery();
  const { data: services } = useGetServicesByAirportQuery(airports, { skip: !fields?.length });
  const [getSuppliers] = useGetSessionByAirportMutation();

  useEffect(() => {
    if (fields.length) {
      fields.forEach((service) => {
        service.services.forEach(async(item) => {
          if (item.pl_serv_service) {
            const res = await getSuppliers({ airports: item.pl_serv_airport, service: item.pl_serv_service });
            const data = res.data?.suppliers;
            if (data) {
              setSuppliers((prevSuppliers) => ({
                ...prevSuppliers,
                [item.pl_serv_airport]: { ...(prevSuppliers[item.pl_serv_airport] || {}), [item.pl_serv_service]: data }
              }));
            }
          }
        });
      });
    }
  }, [fields]);

  return {
    Controller,
    control,
    suppliers,
    airportList,
    services,
    fields
  };
};

import React from 'react';
import InputMask from 'react-input-mask';
import Input from '../Input';

const TimeInput = ({
  value, onChange, onBlur, disabled, ...props
}) => (
  <InputMask mask="99:99" onChange={onChange} onBlur={onBlur} value={value} disabled={disabled}>
    {() => (
      <Input
        {...props}
      />
    )}
  </InputMask>
);

export default TimeInput;

import React from 'react';
import {
  Box,
  Card, Grid, useMediaQuery,
} from '@mui/material';
import FormGroup from '../../../../form/components/FormGroup';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../../../components/Button';
import Input from '../../../../form/components/Input';
import {
  cancelSx, card, footerSx, gridContainer, drawer
} from './style';
import Drawer from '../../../../components/Drawer';
import FieldError from '../../../../form/components/FieldError';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, defaultValues } from './schema';
import { toast } from 'react-hot-toast';
import { useCreateDailyEmailMutation } from '../../../../store/session';

const Email = ({ open, onClose }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  const [createEmail, { isLoading }] = useCreateDailyEmailMutation();

  const maxWidth900px = useMediaQuery('(max-width:900px)');

  const insideOnClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const res = await createEmail(data);

    if (res.error) {
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    insideOnClose();

    toast.success('Successfully created!', {
      id: toastId,
    });
  };

  return (
    <Drawer
      title="Create Email"
      open={open}
      onClose={insideOnClose}
      sx={{ ...(maxWidth900px && drawer ) }}
    >
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        sx={{ p: '20px' }}

      >
        <Card sx={card}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={5} rowSpacing={2} sx={gridContainer}>
              <Grid item xs={12}>

                <FormGroup label="Email" required hasError={!!errors.email}>
                  <Controller
                    render={({ field } ) => (
                      <Input
                        {...field}
                      />
                    )}
                    control={control}
                    name="email"
                  />
                  <FieldError error={errors.email} />
                </FormGroup>

              </Grid>
            </Grid>
            <Box sx={{ ...footerSx, }}>
              <Button type="submit" disabled={isLoading} title="Create Email" />
              <Button sx={cancelSx} onClick={onClose} title="Cancel" />
            </Box>
          </form>
        </Card>
      </Grid>
    </Drawer>
  );
};

export default Email;

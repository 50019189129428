import {
  boolean, object, string
} from 'yup';

export const roleList = [
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'User',
    value: 'user'
  },
  {
    label: 'Crew',
    value: 'crew'
  },
];

export const alertsListOptions = [
  {
    label: 'System',
    value: '_SYS_'
  },
  {
    label: 'OPS',
    value: '_OPS_'
  },
  {
    label: 'Flight OPS',
    value: '_FLTOPS_'
  },
  {
    label: 'SALES',
    value: '_SALES_'
  },
  {
    label: 'Maintenance',
    value: '_MAINT_'
  },
  {
    label: 'Aircraft Maintenance',
    value: '_ACFTMAINT_'
  },
  {
    label: 'Maintenance Engineer',
    value: '_ENGMAINT_'
  },
  {
    label: 'Administrators',
    value: '_ADMIN_'
  },
];

export const defaultValues = {
  status: 0,
  role: '',
  groups_id: '',
  username: '',
  name: '',
  email: '',
  alert_group: '',
  positions: null,
  password: '',
  password_confirmation: '',
  notify_user: true,
};

export const defaultValuesUpdate = {
  status: 0,
  role: '',
  groups_id: '',
  username: '',
  name: '',
  email: '',
  alert_group: '',
  positions: [],
};

export const schemaUpdate = object().shape({
  status: boolean()
    .required('${label} cannot be blank.')
    .label('Status'),
  role: string()
    .required('${label} cannot be blank.')
    .label('Role'),
  groups_id: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Access Group'),
  username: string()
    .required('${label} cannot be blank.')
    .label('Username'),
  name: string()
    .required('${label} cannot be blank.')
    .label('Name'),
  email: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Email'),
  alert_group: string()
    .required('${label} cannot be blank.')
    .label('Email'),
});

export const schema = object().shape({
  status: boolean()
    .required('${label} cannot be blank.')
    .label('Status'),
  role: string()
    .required('${label} cannot be blank.')
    .label('Role'),
  groups_id: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Access Group'),
  username: string()
    .required('${label} cannot be blank.')
    .label('Username'),
  name: string()
    .required('${label} cannot be blank.')
    .label('Name'),
  email: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('Email'),
  alert_group: string()
    .required('${label} cannot be blank.')
    .label('Email'),
  password: string()
    .required('${label} cannot be blank.')
    .label('Password'),
  password_confirmation: string()
    .required('${label} cannot be blank.')
    .label('Password Confirmation'),
});

export const button = {
  '& label': {
    marginBottom: '0px !important',
  },
  width: '10rem'
};

export const buttonBlock = {
  width: '100%',
  mb: '20px',
  textAlignLast: 'center'
};
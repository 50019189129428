import React from 'react';
import { Skeleton } from '@mui/lab';
import { Grid } from '@mui/material';

const SkeletonGrid = ({ itemCount, height = null }) => {
  const generateSkeletonItems = () => {
    const skeletonItems = [];
    for (let i = 0; i < itemCount; i++) {
      skeletonItems.push(
        <Grid item xs={6} sm={4} md={3} key={i}>
          <Skeleton variant="rounded" height={height ?? 40} />
        </Grid>
      );
    }

    return skeletonItems;
  };

  return <>{generateSkeletonItems()}</>;
};

export default SkeletonGrid;

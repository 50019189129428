import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Card, CardContent
} from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import ServerAutocomplete from '../../../../form/components/ServerAutocomplete';
import { useGetReportPartsAutocompleteQuery } from '../../../../store/session';
import { useReportDefectivePartMutation } from '../../../../store/slices/logisticsSlice';
import {
  block,
  button,
  buttonBlock,
  documentForm,
  label
} from '../../OrdersDashboard/components/forms/approved/invoices/style';
import { defaultValues, schema } from './schema';

const ReportDefectivePart = ({ open, onClose, refetch }) => {
  const [selectedPart, setSelectedPart] = useState(null);

  const [reportPart] = useReportDefectivePartMutation();

  const {
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    formData.append('comments', data.comments);
    formData.append('part_id', data.part_id);

    const res = await reportPart({ data: formData });

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });
    reset();
    onClose();
    refetch();
  };

  const handleSelectPart = (part) => {
    if (part?.value && part?.data) {
      setValue('part_id', part.value);
      setSelectedPart(part.data);
      setValue('part_number', part.data?.part_number);
      setValue('part_name', part.data?.part_name);
      setValue('serial_number', part.data?.serial_number);
    } else {
      setValue('part_id', null);
      setSelectedPart(null);
    }
    // trigger('part_number', 'part_name', 'serial_number');
  };

  return (
    <Drawer
      title="Report Defective Part"
      open={open}
      onClose={onClose}
    >
      <Card sx={{ mt: 0, border: 'none' }}>
        <CardContent sx={{ mt: 0 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={documentForm}>
              <Box sx={{ ...block, mb: '15px' }}>
                <FormGroup label="Search Part by ID, Serial Number, Part Name." required hasError={errors?.part_id}>
                  <Controller
                    name="part_id"
                    control={control}
                    render={({ field }) => (
                      <ServerAutocomplete
                        field={field}
                        withData
                        placeholder="Search Part"
                        searchQueryFn={useGetReportPartsAutocompleteQuery}
                        handleSelect={handleSelectPart}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.value} - ${option?.label}`) || ''}
                      />
                    )}
                  />
                </FormGroup>
              </Box>
              {selectedPart && (
                <>
                  <Box sx={{ ...block, mb: '15px' }}>
                    <FormGroup label="Part Name" hasError={!!errors?.part_name}>
                      <Controller
                        control={control}
                        name="part_name"
                        render={({ field }) => (
                          <Input
                            {...field}
                            disabled
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Box>
                  <Box sx={{ ...block, mb: '15px' }}>
                    <FormGroup label="Part Number" hasError={!!errors?.part_number}>
                      <Controller
                        control={control}
                        name="part_number"
                        render={({ field }) => (
                          <Input
                            {...field}
                            disabled
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Box>
                  <Box sx={{ ...block, mb: '15px' }}>
                    <FormGroup label="Serial Number" hasError={!!errors?.serial_number}>
                      <Controller
                        control={control}
                        name="serial_number"
                        render={({ field }) => (
                          <Input
                            {...field}
                            disabled
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Box>
                </>
              )}
              <Box sx={{ ...block, mb: '15px' }}>
                <FormGroup sx={label} label="Comments" required hasError={!!errors.comments}>
                  <Controller
                    name="comments"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        rows={8}
                        multiline
                        variant="filled"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                  <FieldError error={errors.comments} />
                </FormGroup>
              </Box>

              <Box sx={{ ...buttonBlock, textAlignLast: 'start' }}>
                <Button sx={{ ...button, mt: 2 }} title="Create" type="submit" />
              </Box>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Drawer>
  );
};
export default ReportDefectivePart;

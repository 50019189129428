import React from 'react';
import {
  TableCell, TableContainer, TableHead, TableBody, TableRow, Table
} from '@mui/material';
import { columns } from './constants';
import { useFuelForm } from './useFuelForm';
import Input from '../../../../../../../form/components/Input';
import DatePicker from '../../../../../../../form/components/DatePicker';
import { header, tableCell, input } from './style';
import { setDateValue, validateDatePickerValue } from '../../../../../../../utils/setDateValue';

const FuelForm = () => {
  const {
    fields,
    Controller,
    control,
    airportList,
    flightFuel,
    setValue,
  } = useFuelForm();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <TableCell sx={header}>
                {item.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields?.map((item, index) => {
            const airport = airportList.find(element => element.id === item.plfuel_ap_id) || {};
            const supplier = flightFuel?.airports[item.plfuel_ap_id]?.suppliers
              .find(element => element.id === item.plfuel_suplier) || [];

            return (
              (item.plfuel_suplier
                && (
                <TableRow key={item.id}>
                  <TableCell sx={tableCell}>{airport?.airport_name}</TableCell>
                  <TableCell sx={tableCell}>{supplier?.label}</TableCell>
                  <TableCell sx={tableCell}>
                    <Controller
                      name={`planing_fuel.${index}.plfuel_price`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          label="Price, USG/USD"
                          sx={input}
                          {...field}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={tableCell}>
                    <Controller
                      name={`planing_fuel.${index}.plfuel_real_amount`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          label="Uplift, L"
                          sx={input}
                          {...field}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={tableCell}>
                    <Controller
                      name={`planing_fuel.${index}.plfuel_real_fullcost`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          label="Cost, USD"
                          sx={input}
                          {...field}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={tableCell}>
                    <Controller
                      name={`planing_fuel.${index}.plfuel_invoice_num`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          label="Invoice Num"
                          sx={input}
                          {...field}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tableCell, minWidth: '160px' }}>
                    <Controller
                      name={`planing_fuel.${index}.plfuel_payment_date`}
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          timeIntervals={15}
                          placeholderText="YYYY-MM-DD"
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setDateValue(e, setValue, `planing_fuel.${index}.plfuel_payment_date`);
                          }}
                          value={validateDatePickerValue(field.value)}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>
                )
              )
            ); })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FuelForm;

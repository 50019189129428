import {
  Dialog,
  Grid, Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import Button from '../../../../components/Button';
import EditableTable from '../../../../components/EditableTable';
import { CloseIcon } from '../../../../components/Icons';
import Upload from '../../../../components/Upload';
import FormGroup from '../../../../form/components/FormGroup';
import Select from '../../../../form/components/Select';
import {
  useGetFuelPricesUploadErrorsTableQuery,
  useGetFuelPricesUploadInfoTableQuery,
  useUploadImportFuelPricesTableQuery
} from '../../../../store/session';
import { useDeleteTransactionsUploadMutation } from '../../../../store/slices/financeSlice';
import UploadErrors from '../../../Finance/Transactions/components/UploadErrors';
import { CloseIconStyle, TextWrap } from '../../../Logistics/style';
import UploadInfo from '../UploadInfo';
import { columns } from './columns';
import { useImportFuelPrices } from './useImportFuelPrices';

const ImportFuelPrices = (props) => {
  const {
    open = false,
    onClose = () => {},
  } = props;

  const {
    methods,
    onSubmit,
    formattedSupplierList,
    isLoadingFuelFile,
    supplierListLoad,
    fileName,
    handleDownloadFile,
    setFileName,
    refresh,
    handleCloseErrors,
    handleCloseInfo,
    handleOpenErrors,
    handleOpenInfo,
    openErrors,
    openInfo,
    currentUpload
  } = useImportFuelPrices({ onClose });

  const filteredFormattedSupplierList = formattedSupplierList.filter(item => (item.value <= 2));

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset
  } = methods;

  useEffect(() => {
    if (open) {
      reset();
      setFileName('');
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xll"
    >
      <FormProvider {...methods}>
        <form style={{ width: 'fit-content' }}>
          <Grid container spacing={2} sx={{ padding: '24px', margin: '0' }}>
            <Grid item xs={12}>
              <FormGroup label="Supplier" required hasError={!!errors.supplier_id}>
                <Controller
                  name="supplier_id"
                  control={control}
                  render={({ field: { onChange, ...req } }) => (
                    <Select
                      options={filteredFormattedSupplierList}
                      placeholder="Supplier"
                      {...req}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      disabled={supplierListLoad}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup label="File" required hasError={!!errors.upload_file}>
                <Controller
                  name="upload_file"
                  control={control}
                  render={() => (
                    <Upload
                      buttonSx={{ height: '100%' }}
                      disabled={isLoadingFuelFile}
                      handleUpload={(e) => {
                        if (!e.target.files[0]) {
                          setValue('upload_file', null);
                          setFileName('');
                        }

                        setValue('upload_file', e.target.files[0]);
                        setFileName(e.target.files[0]?.name || '');
                      }}
                      accept=".csv"
                      title="Import Fuel Prices"
                    />

                  )}
                />
              </FormGroup>
              <Typography sx={{ fontStyle: 'italic', ...(TextWrap) }}>{fileName}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Button title="Send" onClick={handleSubmit(onSubmit)} />
            </Grid>
          </Grid>

        </form>
      </FormProvider>

      <Grid container spacing={2} sx={{ padding: '24px' }}>
        <Grid item xs={12}>
          <EditableTable
            getRowId={(row) => row.id}
            useHook={useUploadImportFuelPricesTableQuery}
            useHookDelete={useDeleteTransactionsUploadMutation}
            columns={columns}
            getRowHeight={() => 'auto'}
            sortByType="DESC"
            sortByActive="id"
            tableParams={{ refresh }}
            handleDownloadFile={(upload_id) => handleDownloadFile(upload_id)}
            handleOpenErrors={(upload_id) => handleOpenErrors(upload_id)}
            handleOpenInfo={(upload_id) => handleOpenInfo(upload_id)}
          />
        </Grid>

        <UploadErrors open={openErrors} onClose={handleCloseErrors} upload_id={currentUpload} useGetHook={useGetFuelPricesUploadErrorsTableQuery} />

        <UploadInfo open={openInfo} onClose={handleCloseInfo} upload_id={currentUpload} useGetHook={useGetFuelPricesUploadInfoTableQuery} />
      </Grid>

      <CloseIcon
        sx={{
          position: 'absolute', top: '1rem', right: '1rem', ...CloseIconStyle 
        }}
        onClick={onClose}
      />
    </Dialog>
  );
};

export default ImportFuelPrices;

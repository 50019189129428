import { Box } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const ShowText = ({ text }) => (
  <Box>
    {text && <CloseIcon />}
  </Box>
);

export default ShowText;

import Typography from '@mui/material/Typography';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from '../../../constans/formats';
import { linkSx } from '../styles';
import { HeaderName } from '../../../components/EditableTable/styles';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import { Box, Tooltip } from '@mui/material';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import FiltrationAutocomplete from '../../../components/EditableTable/FiltrationAutocomplete';
import ButtonIcon from '../../../components/ButtonIcon';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { APPROVE_INVOICE } from '../../../constans/deleteConfirmation';
import { STATUSES, STATUSES_INVOICE_APPROVE_EXPENSIVE } from '../../../constans/invoiceRequest';

export const columnsInvoicesExp = ({
  currencyOptions, handleFilter, filters, handleDownload, handleOpenDeleteModal, purposeOptions, hasPermissionsInvoiceExpensive, onOpenReasonModal,
}) => ([
  {
    width: 180,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        {hasPermissionsInvoiceExpensive && STATUSES.find(i => i.value === row.payment_status)?.value === 1
        && (
        <ButtonIcon
          sx={{
            '&:hover': {
              color: '#6ddf95 !important'
            },
          }}
          onClick={() => handleOpenDeleteModal({
            id: row.id, text: APPROVE_INVOICE, successButton: 'Yes', cancelButton: 'No', approve: true,
          })}
        >
          <CheckIcon />
        </ButtonIcon>
        )}
        {' '}
        {STATUSES.find(i => i.value === row.payment_status)?.value !== 5 && (
          <ButtonIcon
            sx={{
              ml: 2,
              '&:hover': {
                color: '#f35d5d !important'
              },
            }}
            onClick={() => handleOpenDeleteModal({
              id: row.id, data: row, commentDelete: true,
            })}
          >
            <CloseIcon />
          </ButtonIcon>
        )}
      </>
    ),
  },
  {
    flex: 0.3,
    field: 'file_name',
    minWidth: 220,
    headerName: 'File',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>File</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.file_name || ''}
          value="file_name"
          placeholder="File"
        />
      </Box>
    ),
    renderCell: ({ row: { file_name, id, } }) => (
      <Typography
        sx={{
          ...linkSx,
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
        onClick={() => handleDownload({ file_name, id }, 'inv')}
      >
        {file_name}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'payment_status',
    minWidth: 300,
    headerName: 'Payment Status',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Payment Status</Typography>
        <FiltrationSelect
          options={STATUSES_INVOICE_APPROVE_EXPENSIVE || []}
          defaultValue={filters.payment_status || ''}
          handleFilter={handleFilter}
          placeholder="Payment Status"
          value="payment_status"
        />
      </Box>
    ),
    renderCell: ({ row }) => (+row.payment_status === 5 ? (
      <Tooltip title={row.decline_purpose || 'Decline purpose is empty'} placement="top-start">
        <Typography
          sx={{
            ...linkSx,
            textTransform: 'capitalize',
            whiteSpace: 'break-spaces',
            wordBreak: 'break-all'
          }}
          onClick={() => { onOpenReasonModal(row.decline_purpose); }}

        >
          {STATUSES_INVOICE_APPROVE_EXPENSIVE.find(i => i.value === row.payment_status)?.label || 'Empty'}
        </Typography>
      </Tooltip>
    ) : (
      <Typography sx={{
        textTransform: 'capitalize',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-all'
      }}
      >
        {STATUSES_INVOICE_APPROVE_EXPENSIVE.find(i => i.value === row.payment_status)?.label || 'Empty'}

      </Typography>
    )),
  },
  {
    flex: 0.2,
    field: 'upload_date',
    minWidth: 180,
    headerName: 'Upload Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          '& .MuiPickersCalendarHeader-root': {
            display: 'none'
          }
        }}
      >
        <Typography style={HeaderName}>Upload Date</Typography>
        <FiltrationDate
          value={filters.upload_date || null}
          onChange={(e) => {
            handleFilter(e, 'upload_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>{moment(row.upload_date).format(DATE_FORMAT)}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'sender',
    minWidth: 180,
    headerName: 'Sender',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Sender</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.sender || ''}
          value="sender"
          placeholder="Sender"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {row.sender}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'description',
    minWidth: 320,
    headerName: 'Description',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',

        }}
      >
        <Typography style={HeaderName}>Description</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.description || ''}
          value="description"
          placeholder="Description"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={{
        textTransform: 'capitalize',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-all'
      }}
      >
        {row.description}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'invoice_sum',
    minWidth: 180,
    headerName: 'Sum',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Sum</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.invoice_sum || ''}
          value="invoice_sum"
          placeholder="Sum"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {row.invoice_sum || '-'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'currency',
    minWidth: 220,
    headerName: 'Currency',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Currency</Typography>
        <FiltrationAutocomplete
          options={currencyOptions || []}
          handleFilter={handleFilter}
          defaultValue={filters.currency || ''}
          sx={{ width: '180px' }}
          value="currency"
          placeholder="Currency"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {row.currency}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'payment_purpose',
    minWidth: 220,
    headerName: 'Purpose Of Payment',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Purpose Of Payment</Typography>
        <FiltrationAutocomplete
          options={purposeOptions || []}
          handleFilter={handleFilter}
          defaultValue={filters.payment_purpose || ''}
          sx={{ width: '180px' }}
          value="payment_purpose"
          placeholder="Purpose Of Payment"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography>
        {row.payment_purpose}
      </Typography>
    ),
  },
]);

import { HeaderName } from '../../../components/EditableTable/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import React from 'react';
import { headerCellContainer, StyledDelete, StyledPencil } from '../../Flights/list/styles';
import LinkButton from '../../../components/LinkButton';

export const columns = ({
  handleFilter,
  handleOpenDeleteModal,
  filters,
}) => ([
  {
    flex: 0.2,
    field: 'position_name',
    headerName: 'Position name',
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography style={HeaderName}>Position name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.position_name || ''}
          value="position_name"
          placeholder="Position name"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        {row?.position_name}
      </Typography>
    ),
  },
  {
    field: 'actions',
    maxWidth: 350,
    headerName: 'Actions',
    sortable: false,
    renderHeader: () => (
      <Box sx={headerCellContainer}>
        <Typography sx={{ ...HeaderName, mb: '12px' }}>Actions</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <>
        <LinkButton path={`/manage-users-positions/${row.id}`}>
          <StyledPencil />
        </LinkButton>
        {' '}
        <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />
      </>
    ),
  },
]);

import { RefreshRounded } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box, Card,
  InputAdornment, TextField, useMediaQuery
} from '@mui/material';
import FileSaver from 'file-saver';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import Button from '../../../../components/Button';
import EditableTable from '../../../../components/EditableTable';
import TableFilterContainer from '../../../../components/EditableTable/TableFilterContainer';
import InfoModal from '../../../../components/InfoModal';
import { STATUSES } from '../../../../constans/invoiceRequest';
import {
  useDeleteUserInvoiceMutation,
  useDownloadUserInvoiceMutation,
  useGetUserInvoiceRequestsQuery
} from '../../../../store/session';
import { validateDatePickerValueCopy } from '../../../../utils/setDateValue';
import { btnS } from '../../../Invoices/style';
import { YellowButton } from '../../../Logistics/style';
import { columns } from './columns';

const MyInvoices = (props) => {
  const { getInvoiceTitle, handlePreviewPoP, handleOpenInvoiceFileDrawer } = props;
  const [downloadUserInvoice] = useDownloadUserInvoiceMutation();
  const [isModalReasonOpen, setIsModalReasonOpen] = useState(false);
  const [reasonText, setReasonText] = useState('');
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [visible, setVisible] = useState('all');
  const [refresh, setRefresh] = useState(0);
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const handleDownload = async({ id, document_patch, file_name }) => {
    const toastId = toast.loading('Downloading...');

    const res = await downloadUserInvoice(id);

    const fileName = (document_patch || file_name)?.replace( /\\/g, '/' ).replace( /.*\//, '' );

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    FileSaver.saveAs(res.data, fileName);

    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleOpenReasonModal = (text) => {
    setIsModalReasonOpen(true);
    setReasonText(text);
  };
  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const filterConfig = {
    afterDivider: false,
    items: [
      {
        value: (visible === 'my'),
        callback: (vlv) => setVisible(vlv),
        closeCallback: () => setVisible('all'),
        typeFilter: 'SwitchField',
        icon: null,
      },
      {
        value: status,
        callback: (e) => setStatus(e.target.value),
        closeCallback: () => setStatus(null),
        typeFilter: 'Select',
        icon: 'ListStatus',
        options: STATUSES,
        placeholder: 'Select Status'
      },
      {
        value: validateDatePickerValueCopy(createdAt),
        callback: (e) => setCreatedAt(e ? moment(e).format('DD-MM-YYYY') : ''),
        closeCallback: () => setCreatedAt(null),
        typeFilter: 'DatePicker',
        icon: 'CalendarMonthIcon',
        placeholder: 'Select Created At'
      }
    ]
  };

  return (
    <>
      <Card
        sx={{
          mt: 3,
          padding: '0 1rem 1rem 1rem'
        }}
      >
        <TableFilterContainer filterConfig={filterConfig} />
      </Card>
      <Card sx={{ mt: 3, padding: '1rem' }}>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          alignItems: 'center',
        }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <TextField
              size="small"
              sx={{
                mr: 6, mb: 2, minWidth: '150px', maxWidth: '150px'
              }}
              placeholder="Search..."
              onChange={debounce((e) => setSearch(e.target.value), 400)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box />
          <Box sx={{
            mb: 2, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifySelf: 'end'
          }}
          >
            <Button
              startIcon={<RefreshRounded />}
              sx={{ ml: 2, ...(maxWidth800px && btnS), ...YellowButton }}
              title="Refresh"
              onClick={handleRefresh}
            />
          </Box>
        </Box>
        <EditableTable
          useHook={useGetUserInvoiceRequestsQuery}
          useHookDelete={useDeleteUserInvoiceMutation}
          columns={columns}
          sortByType="DESC"
          sortByActive="created_at"
          getRowClassName={({ id }) => `row-m ${id}`}
          getRowHeight={() => 'auto'}
          getRowId={(row) => row.id}
          style={{
            '& .MuiDataGrid-columnHeaders': { borderRadius: 0 }
          }}
          getInvoiceTitle={getInvoiceTitle}
          handleDownload={handleDownload}
          onOpenReasonModal={handleOpenReasonModal}
          openProofOfPayment={handlePreviewPoP}
          handleOpenInvoiceFileDrawer={handleOpenInvoiceFileDrawer}
          tableParams={{
            payment_status: status,
            visible,
            created_at: createdAt,
            search,
            refresh,
          }}
        />
        <InfoModal open={isModalReasonOpen} onClose={() => { setIsModalReasonOpen(false); }} reasonText={reasonText} />
      </Card>
    </>
  );
};

export default MyInvoices;

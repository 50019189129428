import { yupResolver } from '@hookform/resolvers/yup';
import { Card, TextField, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import Select from '../../../../form/components/Select';
import { useCreatePaymentDataMutation } from '../../../../store/slices/paymentDataSlice';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import {
  RATE_TYPE_DUTY, RATE_TYPE_MONTH, RATE_TYPES, TYPE_OF_PAYMENTS
} from '../constants';
import { defaultValues, schema } from './schema';

const NewPayment = ({
  open, onClose, handleRefresh, currentBlock
}) => {
  const [sendPayment] = useCreatePaymentDataMutation();
  const handleError = useHandleQueryFormError();
  const hasPermissions = usePermissions(['payment_data_admin', 'payment_data_access']);
  const {
    handleSubmit,
    watch,
    setError,
    getValues,
    reset,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    data.rate_data = {
      day_rate: data?.day_rate,
      night_rate: data?.night_rate,
      month_rate: data?.month_rate,
    };

    const res = await sendPayment({ ...(data), payment_department_id: currentBlock });

    if (res.error) {
      if (res.error.status === 403) {
        toast.error('Access is denied', {
          id: toastId,
        });

        return;
      }
      handleError(res.error, setError, getValues, toastId);

      return;
    }

    toast.success('The Payment was add!', {
      id: toastId,
    });

    handleRefresh();
    reset();
    onClose();
  };

  useEffect(() => {
    setValue('payment_department_id', currentBlock);
  }, [currentBlock]);

  return (
    <Drawer
      title="Add New Payment Data"
      open={open}
      onClose={onClose}
      sx={{
        '.MuiTypography-root': {
          margin: '0 auto'
        }
      }}
    >
      <Card sx={{ padding: 4 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup
            label="Name"
            required
            hasError={!!errors.name}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input placeholder="Name" {...field} />
              )}
            />
          </FormGroup>
          <FormGroup
            label="Rate Type"
            required
            hasError={!!errors.rate}
          >
            <Controller
              name="rate_type"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="Rate Type"
                  options={RATE_TYPES}
                  {...field}
                />
              )}
            />
          </FormGroup>

          {(watch('rate_type') === RATE_TYPE_MONTH) && (
            <FormGroup
              label="Month Rate"
              required
              hasError={!!errors.month_rate}
            >
              <Controller
                name="month_rate"
                control={control}
                render={({ field }) => (
                  <Input type="number" placeholder="Rate" {...field} />
                )}
              />
            </FormGroup>
          )}

          {(watch('rate_type') === RATE_TYPE_DUTY) && (
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormGroup
                  label="Day Duty Rate"
                  required
                  hasError={!!errors.day_rate}
                >
                  <Controller
                    name="day_rate"
                    control={control}
                    render={({ field }) => (
                      <Input type="number" placeholder="Day Duty Rate" {...field} />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={6}>
                <FormGroup
                  label="Night Duty Rate"
                  required
                  hasError={!!errors.night_rate}
                >
                  <Controller
                    name="night_rate"
                    control={control}
                    render={({ field }) => (
                      <Input type="number" placeholder="Night Duty Rate" {...field} />
                    )}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          )}

          <FormGroup
            label="Type of Payment"
            required
            hasError={!!errors.type_of_payment}
          >
            <Controller
              name="type_of_payment"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="Type of Payment"
                  options={TYPE_OF_PAYMENTS}
                  {...field}
                />
              )}
            />
          </FormGroup>
          <FormGroup
            label="Payment Details"
            required
            hasError={!!errors.payment_details}
          >
            <Controller
              name="payment_details"
              control={control}
              render={({ field }) => (
                <TextField
                  id="outlined-multiline-static"
                  placeholder="Payment Details"
                  rows={6}
                  multiline
                  {...field}
                  sx={{ width: '100%', color: 'rgba(76, 78, 100, 0.87);' }}
                />
              )}
            />
          </FormGroup>
          <FormGroup
            label="Comments"
            required
            hasError={!!errors.comments}
          >
            <Controller
              name="comments"
              control={control}
              render={({ field }) => (
                <TextField
                  id="outlined-multiline-static"
                  placeholder="Comments"
                  rows={6}
                  multiline
                  {...field}
                  sx={{ width: '100%', color: 'rgba(76, 78, 100, 0.87);' }}
                />
              )}
            />
          </FormGroup>
          <Button type="submit" disabled={!hasPermissions} sx={{ mt: 4 }} title="Add" />
        </form>
      </Card>
    </Drawer>
  );
};

export default NewPayment;

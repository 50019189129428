import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  useDownloadCrewSignaturesFileMutation,
  useGetAircraftListQuery,
  useGetCrewSignatureQuery
} from '../../../store/session';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { parseLocationSearch, stringifyLocationSearch } from '../../../utils/locationSearch';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { debounce, omitBy } from 'lodash';
import { columns } from './columns';
import { useMediaQuery } from '@mui/material';
import {
  TableStyle, ContainerSinatures, ContainerSmall, ContainerSx
} from './styles';
import Button from '@mui/material/Button';
import { toast } from 'react-hot-toast';
import FileSaver from 'file-saver';

const Signatures = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [sortType, setSortType] = useState('desc');
  const [sortBy, setSortBy] = useState('date');
  const [filters, setFilters] = useState(parseLocationSearch(location.search));

  const [downloadFile] = useDownloadCrewSignaturesFileMutation();

  const {
    data,
    isLoading,
    refetch
  } = useGetCrewSignatureQuery({
    data: stringifyLocationSearch({
      sortDir: sortType.toLocaleUpperCase(),
      sortField: sortBy,
      pageSize,
      page,
      ...filters,
    }),
    index: params.id,
  }, { refetchOnMountOrArgChange: true });

  const { data: AircraftList } = useGetAircraftListQuery();

  const maxWidth590px = useMediaQuery('(max-width:590px)');
  const maxWidth768px = useMediaQuery('(max-width:768px)');

  const aircraftOptions = AircraftList?.map((item) => ({
    value: item.id,
    label: item.aircraft_registration
  }));

  const handleFilter = debounce((value, filterBy, isDate) => {
    let res = value;

    if (isDate) {
      res = value ? moment(value).format('YYYY-MM-DD') : null;
    }

    const newFilters = {
      ...filters,
      ...{ [filterBy]: res }
    };

    const result = omitBy(newFilters, (v) => v === null || v === undefined || v === '');
    if (result) {
      setFilters(result);

      const locationSearch = stringifyLocationSearch(result);

      navigate(location.pathname + locationSearch);
    }
  }, 300);

  const handleSort = (sortObj) => {
    if (sortObj.length) {
      const [{ field, sort }] = sortObj;
      setSortType(sort);
      setSortBy(field);

      return;
    }

    setSortType('asc');
    setSortBy('date');
  };

  const handleDownload = async () => {
    const toastId = toast.loading('Loading...');

    const res = await downloadFile(params.id);

    const fileName = 'signatures.xlsx';

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    FileSaver.saveAs(res.data, fileName);
    toast.success('Document download started on your computer', {
      id: toastId,
    });
  };

  useEffect(() => {
    if (aircraftOptions) {
      aircraftOptions?.push({ value: '', label: '-' });
    }
  }, [aircraftOptions]);

  return (
    <Box sx={{ ...ContainerSinatures, ...(maxWidth768px && ContainerSx), ...(maxWidth590px && ContainerSmall) }}>
      <Button sx={{ marginBottom: '14px' }} variant="contained" onClick={handleDownload}>
        Download EXCEL
      </Button>

      <DataGrid
        rows={data?.data || []}
        disableColumnFilter
        autoHeight
        pagination
        getRowId={(row) => row.maintenance}
        sortingOrder={['desc', 'asc']}
        sortingMode="server"
        rowsLoadingMode="server"
        rowCount={data?.total}
        page={page - 1}
        disableColumnMenu
        disableSelectionOnClick
        loading={isLoading}
        pageSize={pageSize}
        columns={columns({
          handleFilter, filters, refetch, aircraftOptions,
        })}
        paginationMode="server"
        onSortModelChange={(sortModel) => handleSort(sortModel)}
        pageSizeOptions={[15, 25, 50]}
        onPaginationModelChange={(model) => {
          setPage(model.page + 1);
          setPageSize(model.pageSize);
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 15 } },
        }}
        sx={TableStyle}
      />
    </Box>
  );
};

export default Signatures;

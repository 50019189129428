import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

export const StyledDownload = styled(DownloadIcon)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const StyledPreview = styled(VisibilityIcon)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const StyledNotification = styled(NotificationsActiveIcon)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

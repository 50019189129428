export const columns = [
  {
    name: 'CHARTER PRICE, EUR',
    id: 'charter_price',
  },
  {
    name: 'ADD.INVOICE, EUR',
    id: 'add_invoice',
  },
  {
    name: 'ACMI, EUR',
    id: 'acmi',
  },
  {
    name: 'FUEL, EUR',
    id: 'fuel',
  },
  {
    name: 'INV, EUR',
    id: 'inv',
  },
  {
    name: 'NAV, EUR',
    id: 'nav',
  },
  {
    name: 'TOTAL EXPENSES, EUR',
    id: 'total_expenses',
  },
  {
    name: 'PROFIT, EUR',
    id: 'profit_eur',
  },
  {
    name: 'PROFIT %',
    id: 'profit_percent',
  },
];
